import React, { useMemo } from "react";
import { ArrowsChevronRight } from "@/assets/ArrowChevronRight";
import { ReactComponent as Logo } from "@/assets/logo.svg";
import { getComponentForTag, getComponents, isHtmlTag } from "@/lib/parser/components";
import { DefaultCheatSheet } from './DefaultCheatSheet';
import check from "@/vendors/check";
import { cn } from "@/lib/utils";
import ucfirst from "ucfirst";
import { usageExamplesForTags } from "@/lib/parser/doc/examples/examples";

export function HTMLComponentDoc({ tag }: {tag: string}) {
    return (
        <iframe title={'component:'+tag} src={`https://mdn-proxy.vercel.app/en-US/docs/Web/HTML/Element/${tag}`} className="w-screen h-screen" />
    );
}

function SectionTitle({className, children}: { className?: string, children: React.ReactNode }) {
    return (
        <div
            className={cn(
                "[font-family:'Inter',Helvetica] font-medium text-variable-collection-indigo text-[16px] tracking-[0] leading-[22px] whitespace-nowrap",
                className,
            )}
        >
            {children}
        </div>
    )
}

export function ComponentDoc({ componentTag, pushSnippet }: {componentTag: string, pushSnippet: (snippet: any) => Promise<void> | undefined}) {
    let component = useMemo(() => getComponentForTag(componentTag), [componentTag]);

    const name = component?.name || ucfirst(componentTag.replace('/', ''));
    
    // Handle known HTML components
    if (!component && isHtmlTag(componentTag)) {
        // Return default MDN doc in this case
        return <HTMLComponentDoc tag={componentTag} />;
    }

    // Return the default cheat sheet if the component is not found
    if (!component) {
        return <DefaultCheatSheet />;
    }


    // Get examples 
    const examples = usageExamplesForTags(component.tags).filter(check.nonEmptyObject);
  return (
    <div className="flex flex-col items-start relative bg-[#ffffff] w-full min-h-screen pb-20">
      <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]">
        <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]">
          <div className="flex items-center gap-[10px] p-[16px] relative self-stretch w-full flex-[0_0_auto] border-b [border-bottom-style:solid] border-[#1717171f]">
            <div className="inline-flex items-center gap-[6px] relative flex-[0_0_auto]">
              <Logo
                className="relative w-[62.31px] h-[75.71px] mt-[-10.86px] mb-[-42.86px] ml-[-16.00px]"
              />
              <div className="relative w-fit mt-[-1.00px] [font-family:'Onest',Helvetica] font-semibold text-neutral-900 text-[16px] tracking-[0] leading-[22px] whitespace-nowrap">
                Layouts.dev
              </div>
            </div>
            <div className="inline-flex items-center justify-center gap-[10px] px-[8px] py-[4px] relative flex-[0_0_auto] bg-variable-collection-primary-BG-duplicate rounded-[6px]">
              <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-medium text-[#ed5f00] text-[12px] tracking-[0] leading-[17px] whitespace-nowrap">
                Private Beta
              </div>
            </div>
          </div>
          <div className="flex items-start gap-[4px] p-[16px] relative self-stretch w-full flex-[0_0_auto] border-b [border-bottom-style:solid] border-[#1717171f]">
            <div className="inline-flex items-start relative flex-[0_0_auto]">
              <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-medium text-[#1717175c] text-[12px] tracking-[0] leading-[17px] whitespace-nowrap">
                Component docs
              </div>
              <ArrowsChevronRight className="!relative !w-[16px] !h-[16px]" />
            </div>
            <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-medium text-[#5b5e66] text-[12px] tracking-[0] leading-[17px] whitespace-nowrap">
              {name}
            </div>
          </div>
          <div className="flex flex-col items-start gap-[6px] p-[16px] relative self-stretch w-full flex-[0_0_auto]">
            <div className="inline-flex flex-col items-start gap-[6px] relative flex-[0_0_auto]">
              <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-medium text-[#ed5f00] text-[12px] tracking-[0] leading-[17px] whitespace-nowrap">
                Component
              </div>
              <div className="relative w-fit [font-family:'Onest',Helvetica] font-semibold text-neutral-900 text-[18px] tracking-[0] leading-[24px] whitespace-nowrap">
                {name}
              </div>
            </div>
            <p className="[font-family:'Inter',Helvetica] font-normal text-variable-collection-grey text-[16px] tracking-[0] leading-[22px] relative self-stretch">
              {component.description || `The <${component.name}> component.`}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start gap-[24px] p-[16px] w-full flex-[0_0_auto] relative self-stretch">
            {check.nonEmptyString(component.refImplementation) && (
                <div className="flex flex-col items-start gap-[6px] relative self-stretch w-full flex-[0_0_auto]">
                    <SectionTitle >
                        Sample use case
                    </SectionTitle>
                    <div className="flex items-start gap-[10px] p-[12px] relative self-stretch w-full flex-[0_0_auto] bg-black rounded-[8px]">
                    <pre 
                      onClick={() => {
                        if (check.function(pushSnippet) && component && check.nonEmptyString(component.refImplementation)) {
                          pushSnippet({
                            time: new Date().toISOString(),
                            code: component.refImplementation
                          })
                        }
                      }}
                      className="cursor-pointer relative flex-1 mt-[-1.00px] [font-family:'JetBrains_Mono',Helvetica] font-normal text-white text-[12px] tracking-[0] leading-[17px]">
                        {component.refImplementation}
                    </pre>
                    </div>
                </div>
            )}
        </div>
      </div>
      {check.nonEmptyArray(component.variants) && (
        <div className="flex flex-col items-start p-[16px] relative self-stretch w-full flex-[0_0_auto]">
            <div className="flex flex-col items-start gap-[6px] relative self-stretch w-full flex-[0_0_auto] mb-[6px]">
                <div className="flex h-[17px] items-start justify-between relative self-stretch w-full">
                    <SectionTitle className="relative w-fit mt-[-1.00px] mb-[-4.00px]">
                        Available variants
                    </SectionTitle>
                    <div className="relative w-fit mt-[-1.00px] mb-[-4.00px] [font-family:'Inter',Helvetica] font-medium text-variable-collection-indigo text-[16px] tracking-[0] leading-[22px] whitespace-nowrap">
                        {check.nonEmptyObject(component?.doc?.variants) ? 'Description' : ''}
                    </div>
                </div>
            </div>
            {component.variants?.map(variant => (
                <div className="flex items-start justify-between relative self-stretch w-full flex-[0_0_auto] border-t border-t-solid border-[#171717] border-opacity-[12%] py-[6px]">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-medium text-[#2d41aa] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
                        {"%" + variant}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-variable-collection-grey text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
                        {component?.doc?.variants?.[variant] || ''}
                    </div>
                </div>
            ))}
        </div>
      )}
      {check.nonEmptyArray(examples) && (
        <div className="flex flex-col items-start p-[16px] relative self-stretch w-full flex-[0_0_auto]">
            <div className="flex h-[17px] items-start justify-between relative self-stretch w-full">
                <SectionTitle className="relative w-fit mt-[-1.00px] mb-[-4.00px]">
                    Detailed usage
                </SectionTitle>
                <div className="relative w-fit mt-[-1.00px] mb-[-4.00px] [font-family:'Inter',Helvetica] font-medium text-variable-collection-indigo text-[16px] tracking-[0] leading-[22px] whitespace-nowrap">
                    {check.nonEmptyObject(component?.doc?.variants) ? 'Description' : ''}
                </div>
            </div>
            <div className="flex flex-col items-start gap-[12px] pt-3 w-full flex-[0_0_auto] relative self-stretch">
              {examples.filter(e => check.nonEmptyObject(e) && check.nonEmptyString(e.code) && check.nonEmptyString(e.name)).map((example) => (
                <div className="flex flex-col items-start gap-[6px] relative self-stretch w-full flex-[0_0_auto]">
                    <SectionTitle className="text-sm font-thin pr-4 text-wrap">
                        {(example.works ? '' : '❌ ') + example.name}
                    </SectionTitle>
                    <div className="flex items-start gap-[10px] p-[12px] relative self-stretch w-full flex-[0_0_auto] bg-black rounded-[8px]">
                    <pre 
                      onClick={() => {
                        if (check.function(pushSnippet)) {
                          pushSnippet({
                            time: new Date().toISOString(),
                            code: example.code,
                          })
                        }
                      }}
                      className="relative flex-1 mt-[-1.00px] [font-family:'JetBrains_Mono',Helvetica] font-normal text-white text-[12px] tracking-[0] leading-[17px] cursor-pointer"
                    >
                        {example.code}
                    </pre>
                    </div>
                </div>
              ))}
            </div>
        </div>
      )}
    </div>
  );
};
