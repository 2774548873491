import { Progress, ProgressIndicator } from "@/components/shadcn/ui/progress"
import { ComponentTreeNode } from "../../component.type";
import { getSingleVarNameFromText } from "../../../util";
import { childrenParsers, ComponentAliasConfig, imports, radixComponentDoc, setup } from "../_utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { DataDisplay } from "@/vendors/data/data-v2";
import { toNumber } from "@/lib/type-casts";

const ALIASES : ComponentAliasConfig[] = [
    ['indicator', ProgressIndicator, 'ProgressIndicator', radixComponentDoc('Progress.Indicator')],
];

const ProgressComponentParser = {
    name: 'Progress bar',
    description: 'An indicator showing the completion progress of a task',
    tags: ['progress', 'progressbar', 'progress-bar'],
    refImplementation: `
    /progress 30 w-[60%]
    `.trim(),
    childrenParsers: childrenParsers(ALIASES, 'progress'),
    parseTree: (c: ComponentTreeNode) : ComponentTreeNode => {
        const { props = {}, text } = c;

        let component : React.ComponentType = Progress;
        let wrap = (w: ComponentTreeNode) => w;

        const textVar = getSingleVarNameFromText(text);
        if (textVar) {
            wrap = (w: ComponentTreeNode) => ({
                component: DataDisplay as any,
                htmlComponent: 'DataDisplay',
                props: { bind: textVar, type: 'integer' },
                classes: [],
                variant: null,
                text: null, 
                children: [w],
            });
        } else {
            props.value = toNumber(c.props.value || text);
        }

        // Only accept ProgressIndicator as unique child
        const children = (c.children || []).filter(c => (c.component === ProgressIndicator)).slice(0, 1);

        return wrap({ 
            ...c, 
            component, 
            htmlComponent: 'Progress',
            props,
            children,
        });
    },
    imports: imports(['Progress'], 'progress'),
    setup: setup('progress'),
    doc: {
        props: radixComponentDoc('Progress.Root').props,
    }
};

console.log('Progress children parsrs: ', ProgressComponentParser.childrenParsers);

registerShadcnComponent({
        Progress
    },
    'progress',
);

export default ProgressComponentParser;