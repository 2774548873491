import LoadingPage from "../LoadingPage";
import { usePagePermissions } from "./usePagePermissions";

export function PermissionsRouter({ pageId, readAccess, writeAccess, noAccess } 
    : { pageId?: string, readAccess: React.JSX.Element, writeAccess: React.JSX.Element, noAccess: React.JSX.Element}
) {
    const { canRead, canWrite, loading } = usePagePermissions(pageId);

    if (loading) {
        return <LoadingPage />;
    }

    if (canWrite) {
        return writeAccess;
    }

    if (canRead) {
        return readAccess;
    }

    return noAccess;
}