

import check from "@/vendors/check";
import { editor } from "monaco-editor";
import { Monaco } from "@monaco-editor/react";
import { RequiredActionFunctionToolCall } from "openai/resources/beta/threads/runs/runs";

type EditorInstance = { editor: editor.IStandaloneCodeEditor, monaco: Monaco };

// ////////////////////////////////////////////////////////////
// Editor functions
// ////////////////////////////////////////////////////////////
const findRangeOfText = (editor: editor.IStandaloneCodeEditor, args: { text: string, index: number }) => {
    const { text } = args;
    const i = check.number(args.index) ? args.index : 0;
    const model = editor.getModel();
    if (!model) {
      console.error('Aborting findRangeOfText: no model found');
      return null;
    }
    const fullText = model.getValue();
    if (!fullText) {
      console.error('Aborting findRangeOfText: no text found');
      return null;
    }
  
    // Get the start index of the nth occurence of the text
    const indexes = [];
    let index = fullText.indexOf(text);
    while (index !== -1) {
      indexes.push(index);
      index = fullText.indexOf(text, index + 1);
    }
    if (!check.nonEmptyArray(indexes)) {
      console.error('Aborting findRangeOfText: text not found within content');
      return null;
    }
  
    const finalIndex = indexes[Math.min(i, indexes.length - 1)];
    if (!check.number(finalIndex)) {
      console.error('Aborting findRangeOfText: text not found');
      return null;
    }
  
    const startPosition = model.getPositionAt(finalIndex);
    const endPosition = model.getPositionAt(finalIndex + text.length);
    
    return { startPosition, endPosition };
  };
  
  const insertTextAtCursor = (instance: EditorInstance, args: { text: string }) => {
    const { editor, monaco } = instance;
    const { text } = args;
  
    const position = editor.getPosition();
    if (!position) {
      console.error('Aborting insertTextAtCursor: no cursor position found');
      return { status: 'error', message: 'No cursor position found' };
    }
    const range = new monaco.Range(position.lineNumber, position.column, position.lineNumber, position.column);
    editor.executeEdits('', [{ range, text, forceMoveMarkers: true }]);
    return { status: 'success' };
  };
  
  // Replace text by 
  // - placing the cursor at the beginning of the text to remove
  // - removing the old text and adding the new
  function replaceText(instance: EditorInstance, args: { prevText: string, newText: string, index?: number }) {
    const { editor, monaco } = instance;
    const { prevText, newText } = args;
  
    const range = findRangeOfText(editor, {text: prevText, index: args.index || 0});
      
    if (range) {
      const { startPosition, endPosition } = range;
      const rangeToReplace = new monaco.Range(
        startPosition.lineNumber,
        startPosition.column,
        endPosition.lineNumber,
        endPosition.column
      );
      editor.executeEdits('', [{ range: rangeToReplace, text: newText, forceMoveMarkers: true }]);
      // Optionally, place the cursor at the end of the inserted text
      editor.setPosition({
        lineNumber: startPosition.lineNumber,
        column: startPosition.column + newText.length
      });
    }
  }
  
  
  const replaceTextInRange = (instance: EditorInstance, args: { text: string, startLineNumber: number, startColumn: number, endLineNumber: number, endColumn: number }) => {
    const { editor, monaco } = instance;
    const { text, startLineNumber, startColumn, endLineNumber, endColumn } = args;
    const range = new monaco.Range(startLineNumber, startColumn, endLineNumber, endColumn);
    editor.executeEdits('', [{ range, text, forceMoveMarkers: true }]);
    return { status: 'success' };
  };
  
  const getCurrentCode = (instance: EditorInstance) => {
    const { editor } = instance;
    return editor.getValue();
  };
  
  // Start entering code mode
  const startEnteringCode = (instance: EditorInstance, _: any, { editorModeActive, setEditorModeActive } : { editorModeActive: boolean, setEditorModeActive: (active: boolean) => void }) => {
    if (editorModeActive) {
      console.log('ERROR: Code mode was already active.');
    }
    
    setEditorModeActive(true);
    console.log('Code mode active');
    return '=== NOW EDITING CODE ===';
  };
  
  // Stop entering code mode
  const stopEnteringCode = (instance: EditorInstance,  _: any, { editorModeActive, setEditorModeActive } : { editorModeActive: boolean, setEditorModeActive: (active: boolean) => void }) => {
    if (!editorModeActive) {
      console.log('ERROR: Code mode was already active.');
    }
    
    const { editor } = instance;
    setEditorModeActive(false);
    console.log('Code mode disabled');
    return '=== STOPPED EDITING CODE ==='; // \nCODE IN EDITOR: ' + editor.getValue() + '\n=== SWITCHING TO TALK MODE ===';
  };
  
  // Get current caret position and surrounding text (line before, line with, and line after the caret)
  const displayCaretWhereabouts = (instance: EditorInstance) => {
    const { editor } = instance;
    const position = editor.getPosition();
    if (!position) {
      return { status: 'error', message: 'No cursor position found' };
    }
    
    const model = editor.getModel();
    if (!model) {
      return { status: 'error', message: 'No model found' };
    }
    const lineNumber = position.lineNumber;
    
    // Get the line before the caret
    const previousLineNumber = lineNumber > 1 ? lineNumber - 1 : null;
    const previousLineText = previousLineNumber ? model.getLineContent(previousLineNumber) : '';
  
    // Get the line with the caret
    const currentLineText = model.getLineContent(lineNumber);
    const caretColumn = position.column;
    const currentLineWithCaret = currentLineText.slice(0, caretColumn - 1) + '<|||>' + currentLineText.slice(caretColumn - 1);
  
    // Get the line after the caret
    const nextLineNumber = lineNumber < model.getLineCount() ? lineNumber + 1 : null;
    const nextLineText = nextLineNumber ? model.getLineContent(nextLineNumber) : '';
  
    return JSON.stringify({
      status: 'success',
      caretPosition: position,
    }) + '\n' + 'SURROUNDING TEXT:\n"""\n' + previousLineText + '\n' + currentLineWithCaret + '\n' + nextLineText + '\n"""';
  };
  
  
  // Select text
  const selectText = (instance: EditorInstance, args: { text: string, index?: number }) => {
    const { editor, monaco } = instance;
    const { text } = args;
    
    const range = findRangeOfText(editor, { text, index: args.index || 0 });
    if (range) {
      const { startPosition, endPosition } = range;
      editor.setSelection(new monaco.Range(
        startPosition.lineNumber,
        startPosition.column,
        endPosition.lineNumber,
        endPosition.column
      ));
      return { status: 'success' };
    } else {
      return { status: 'error', message: 'Text not found' };
    }
  };
  
  // Delete currently selected text
  const deleteSelection = (instance: EditorInstance) => {
    const { editor } = instance;
    const selection = editor.getSelection();
    if (!selection) {
      return { status: 'error', message: 'No text selected' };
    }
    
    editor.executeEdits('', [{ range: selection, text: '', forceMoveMarkers: true }]);
    return { status: 'success' };
  };
  
  // Place caret after specified text
  const placeCaretAfterText = (instance: EditorInstance, args: { text: string, index?: number }) => {
    const { editor } = instance;
    const { text } = args;
    
    const range = findRangeOfText(editor, { text, index: args.index || 0 });
    if (range) {
      const { endPosition } = range;
      editor.setPosition({
        lineNumber: endPosition.lineNumber,
        column: endPosition.column
      });
      return { status: 'success' };
    } else {
      return { status: 'error', message: 'Text not found' };
    }
  };
  
  // Place caret before specified text
  const placeCaretBeforeText = (instance: EditorInstance, args: { text: string, index?: number }) => {
    const { editor } = instance;
    const { text } = args;
    
    const range = findRangeOfText(editor, { text, index: args.index || 0});
    if (range) {
      const { startPosition } = range;
      editor.setPosition({
        lineNumber: startPosition.lineNumber,
        column: startPosition.column
      });
      return { status: 'success' };
    } else {
      return { status: 'error', message: 'Text not found' };
    }
  };
  
  // Remove specified text from the code editor
  const removeTextFromCode = (instance: EditorInstance, args: { text: string, index?:number }) => {
    const { editor, monaco } = instance;
    const { text } = args;
    
    const range = findRangeOfText(editor, { text, index: args.index || 0});
    if (range) {
      const { startPosition, endPosition } = range;
      const rangeToDelete = new monaco.Range(
        startPosition.lineNumber,
        startPosition.column,
        endPosition.lineNumber,
        endPosition.column
      );
      editor.executeEdits('', [{ range: rangeToDelete, text: '', forceMoveMarkers: true }]);
      return { status: 'success' };
    } else {
      return { status: 'error', message: 'Text not found' };
    }
  };
  
  // Place caret at specified position
  const placeCaretAt = (instance: EditorInstance, args: { startLineNumber: number, startColumn: number, endLineNumber: number, endColumn: number }) => {
    const { editor, monaco } = instance;
    const { startLineNumber, startColumn, endLineNumber, endColumn } = args;
    
    const position = new monaco.Position(startLineNumber, startColumn);
    editor.setPosition(position);
    
    const range = new monaco.Range(startLineNumber, startColumn, endLineNumber, endColumn);
    editor.revealRange(range);
    
    return { status: 'success' };
  };
  
  
  /*
   {
            "type": "function",
            "function": {
              "name": "clear",
              "description": "Delete all the editor content",
              "parameters": {
                "type": "object",
                "properties": {},
                "required": []
              }
            }
          },
          {
            "type": "function",
            "function": {
              "name": "placeCaretAtEnd",
              "description": "Place the caret at the end of the editor content",
              "parameters": {
                "type": "object",
                "properties": {},
                "required": []
              }
            }
          }
    */
   const clear = (instance: EditorInstance) => {
    const { editor } = instance;
    editor.setValue('');
    return { status: 'success' };
   };
  
   const placeCaretAtEnd = (instance: EditorInstance) => {
    const { editor } = instance;
    const model = editor.getModel();
    if (!model) {
      return { status: 'error', message: 'No model found' };
    }
    const lastLine = model.getLineCount();
    const lastColumn = model.getLineLength(lastLine);
    editor.setPosition({ lineNumber: lastLine, column: lastColumn });
    return { status: 'success' };
  }
  
  const replaceAll = (instance: EditorInstance, args: { prevText: string, newText: string }) => {
    const { editor } = instance;
    const { prevText, newText } = args;
    const model = editor.getModel();
    if (!model) {
      return { status: 'error', message: 'No model found' };
    }
    const fullText = model.getValue();
    const updatedText = fullText.replace(new RegExp(prevText, 'g'), newText);
    editor.setValue(updatedText);
    return { status: 'success' };
  }
  
  const replaceLine = (instance: EditorInstance, args: { lineNumber: number, newLineText: string }) => {
    const { editor, monaco } = instance;
    const { lineNumber, newLineText } = args;
    const model = editor.getModel();
    if (!model) {
      return { status: 'error', message: 'No model found' };
    }
    const lineCount = model.getLineCount();
    if (lineNumber < 1 || lineNumber > lineCount) {
      return { status: 'error', message: 'Invalid line number' };
    }
    const range = new monaco.Range(lineNumber, 1, lineNumber, model.getLineLength(lineNumber));
    const prevLine = model.getLineContent(lineNumber);
    const spacesPrefix = prevLine.match(/^\s*/)?.[0] || '';
    // Ensure the line always keeps the same indentation
    const newText = spacesPrefix + newLineText.trim();
    editor.executeEdits('', [{ range, text: newText, forceMoveMarkers: true }]);
    return { status: 'success' };
  }
  
  const prependLineBefore = (instance: EditorInstance, args: { lineNumber: number, newLineText: string }) => {
    const { editor, monaco } = instance;
    const { lineNumber, newLineText } = args;
    const model = editor.getModel();
    if (!model) {
      return { status: 'error', message: 'No model found' };
    }
    const lineCount = model.getLineCount();
    if (lineNumber < 1 || lineNumber > lineCount) {
      return { status: 'error', message: 'Invalid line number' };
    }
    const range = new monaco.Range(lineNumber, 1, lineNumber, 1);
    editor.executeEdits('', [{ range, text: newLineText + '\n', forceMoveMarkers: true }]);
    return { status: 'success' };
  }
  
  const appendLineAfter = (instance: EditorInstance, args: { lineNumber: number, newLineText: string }) => {
    const { editor, monaco } = instance;
    const { lineNumber, newLineText } = args;
    const model = editor.getModel();
    if (!model) {
      return { status: 'error', message: 'No model found' };
    }
    const lineCount = model.getLineCount();
    if (lineNumber < 1 || lineNumber > lineCount) {
      return { status: 'error', message: 'Invalid line number' };
    }
    const range = new monaco.Range(lineNumber + 1, 1, lineNumber + 1, 1);
    editor.executeEdits('', [{ range, text: newLineText + '\n', forceMoveMarkers: true }]);
    return { status: 'success' };
  }
  
  const deleteLine = (instance: EditorInstance, args: { lineNumber: number }) => {
    const { editor, monaco } = instance;
    const { lineNumber } = args;
    const model = editor.getModel();
    if (!model) {
      return { status: 'error', message: 'No model found' };
    }
    const lineCount = model.getLineCount();
    if (lineNumber < 1 || lineNumber > lineCount) {
      return { status: 'error', message: 'Invalid line number' };
    }
    const range = new monaco.Range(lineNumber, 1, lineNumber, model.getLineLength(lineNumber));
    editor.executeEdits('', [{ range, text: '', forceMoveMarkers: true }]);
    return { status: 'success' };
  }
  
  
  // ////////////////////////////////////////////////////////////
  const editorHandlers : { [key: string]: any } = {
    clear,
    // deleteSelection,
    // displayCaretWhereabouts,
    getCurrentCode,
    // placeCaretAfterText,
    // placeCaretAt,
    placeCaretAtEnd,
    // placeCaretBeforeText,
    // removeTextFromCode,
    // selectText,
    enterCodeMode: startEnteringCode,
    exitCodeMode: stopEnteringCode,
    replaceAll,
    replaceLine,
    prependLineBefore,
    appendLineAfter,
    deleteLine,
  };


  const createEditorFunctionsHandler = (
    {editorInstance, editorModeActive, setEditorModeActive} : {
    editorInstance: any,
    editorModeActive: boolean,
    setEditorModeActive: any,
  }) => (async (call: RequiredActionFunctionToolCall) => {

    const handlerName = call?.function?.name;

    console.log('[Function call] calling handler:', handlerName, 'with args:', call.function.arguments)


    if (!editorInstance) {
      console.error('[Function call] Editor not initialized');
      return JSON.stringify({ status: 'error', error: 'Editor not initialized'});
    }

    
    if (typeof handlerName !== "string" || !editorHandlers[handlerName]) {
      console.error('Invalid function call', call);
      return JSON.stringify({ status: 'error', error: 'Unknown function'});
    }

    console.log('[Function call] calling handler:', handlerName, 'with args:', call.function.arguments)

    let args = {}
    try {
      args = JSON.parse(call.function.arguments);
    } catch(err) {
      console.error('Error parsing arguments for function '+handlerName+'():', err);
    }
    const response = await editorHandlers[handlerName](editorInstance, args || {}, { editorModeActive, setEditorModeActive });
    return check.string(response) ? response : JSON.stringify(response || { status: 'success'});
  })



  export { editorHandlers, insertTextAtCursor, createEditorFunctionsHandler }
