// Code from https://github.com/ibelick/background-snippets/blob/main/app/components/background.tsx
// License TBD

import { Fragment } from "react";
import { ComponentTreeNode } from "../../component.type";
import { removeEmptyProps, selectVariant } from "../../../util";
import bgPatternComponents from "./bg-pattern-components.json";
import check from "@/vendors/check";
import { cn } from "@/lib/utils";

const BgLightGrid1 = () => {
    return (
      <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]" />
    );
  };
  
  const BgLightGrid2 = () => {
    return (
      <div className="relative h-full w-full bg-white">
        <div className="absolute h-full w-full bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_70%,transparent_100%)]" />
      </div>
    );
  };
  
  const BgLightGrid5 = () => {
    return (
      <div className="relative h-full w-full bg-white">
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_0%,#000_70%,transparent_110%)]" />
      </div>
    );
  };
  
  const BgLightGradient1 = () => {
    return (
      <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:6rem_4rem]">
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-[radial-gradient(circle_800px_at_100%_200px,#d5c5ff,transparent)]" />
      </div>
    );
  };
  
  const BgLightGrid3 = () => {
    return (
      <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px]" />
    );
  };
  
  const BgLightGridGradient1 = () => {
    return (
      <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px]">
        <div className="absolute left-0 right-0 top-0 -z-10 m-auto h-[310px] w-[310px] rounded-full bg-fuchsia-400 opacity-20 blur-[100px]" />
      </div>
    );
  };
  
  const BgDarkGradient1 = () => {
    return (
      <div className="absolute inset-0 -z-10 h-full w-full items-center px-5 py-24 [background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#63e_100%)]" />
    );
  };
  
  const BgLightGrid4 = () => {
    return (
      <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:6rem_4rem]" />
    );
  };
  
  const BgLightGradient2 = () => {
    return (
      <div className="absolute top-0 z-[-2] h-screen w-screen rotate-180 transform bg-white bg-[radial-gradient(60%_120%_at_50%_50%,hsla(0,0%,100%,0)_0,rgba(252,205,238,.5)_100%)]" />
    );
  };
  
  const BgDarkGradient2 = () => {
    return (
      <div className="absolute top-0 z-[-2] h-screen w-screen bg-neutral-950 bg-[radial-gradient(ellipse_80%_80%_at_50%_-20%,rgba(120,119,198,0.3),rgba(255,255,255,0))]" />
    );
  };
  
  const BgLightGradient3 = () => {
    return (
      <div className="absolute top-0 z-[-2] h-screen w-screen bg-white bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)]" />
    );
  };
  
  const BgLightGradient4 = () => {
    return (
      <div className="absolute top-0 -z-10 h-full w-full bg-white">
        <div className="absolute bottom-auto left-auto right-0 top-0 h-[500px] w-[500px] -translate-x-[30%] translate-y-[20%] rounded-full bg-[rgba(173,109,244,0.5)] opacity-50 blur-[80px]" />
      </div>
    );
  };
  
  const BgLightGradient5 = () => {
    return (
      <div className="absolute top-0 z-[-2] h-screen w-screen bg-white bg-[radial-gradient(ellipse_80%_80%_at_50%_-20%,rgba(120,119,198,0.3),rgba(255,255,255,0))]" />
    );
  };
  
  const BgLightGradient6 = () => {
    return (
      <div className="absolute inset-0 -z-10 h-full w-full bg-white [background:radial-gradient(125%_125%_at_50%_10%,#fff_40%,#63e_100%)]" />
    );
  };
  
  const BgDarkGrid1 = () => {
    return (
      <div className="absolute top-0 z-[-2] h-screen w-screen bg-[#000000] bg-[radial-gradient(#ffffff33_1px,#00091d_1px)] bg-[size:20px_20px]" />
    );
  };
  
  const BgDarkGradient3 = () => {
    return (
      <div className="relative h-full w-full bg-neutral-900">
        <div className="absolute inset-0 bg-fuchsia-400 bg-[size:20px_20px] opacity-20 blur-[100px]"></div>
      </div>
    );
  };
  
  const BgLightGridGradient2 = () => {
    return (
      <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:6rem_4rem]">
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-[radial-gradient(circle_500px_at_50%_200px,#C9EBFF,transparent)]" />
      </div>
    );
  };
  
  const BgDarkGradient4 = () => {
    return (
      <div className="relative h-full w-full bg-slate-950">
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-[radial-gradient(circle_500px_at_50%_200px,#3e3e3e,transparent)]" />
      </div>
    );
  };
  
  const BgDarkGradient5 = () => {
    return (
      <div className="relative h-full w-full bg-slate-950">
        <div className="absolute bottom-0 left-[-20%] right-0 top-[-10%] h-[500px] w-[500px] rounded-full bg-[radial-gradient(circle_farthest-side,rgba(255,0,182,.15),rgba(255,255,255,0))]" />
        <div className="absolute bottom-0 right-[-20%] top-[-10%] h-[500px] w-[500px] rounded-full bg-[radial-gradient(circle_farthest-side,rgba(255,0,182,.15),rgba(255,255,255,0))]" />
      </div>
    );
  };
  
  const BgDarkGrid2 = () => {
    return (
      <div className="relative h-full w-full bg-slate-950">
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]" />
      </div>
    );
  };
  
  const BgDarkGridGradient1 = () => {
    return (
      <div className="relative h-full w-full bg-black">
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px]" />
        <div className="absolute left-0 right-0 top-[-10%] h-[1000px] w-[1000px] rounded-full bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]" />
      </div>
    );
  };
  
  const BgDarkGrid3 = () => {
    return (
      <div className="relative h-full w-full bg-slate-950">
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)]" />
      </div>
    );
  };
  
  export const BACKGROUND_OPTIONS = [
    {
      name: 'Background Light Gradient 1',
      component: <BgLightGradient1 />,
      theme: 'light',
    },
    {
      name: 'Background Light Gradient 2',
      component: <BgLightGradient2 />,
      theme: 'light',
    },
    {
      name: 'Background Light Gradient 3',
      component: <BgLightGradient3 />,
      theme: 'light',
    },
    {
      name: 'Background Light Gradient 4',
      component: <BgLightGradient4 />,
      theme: 'light',
    },
    {
      name: 'Background Light Gradient 5',
      component: <BgLightGradient5 />,
      theme: 'light',
    },
    {
      name: 'Background Light Gradient 6',
      component: <BgLightGradient6 />,
      theme: 'light',
    },
    {
      name: 'Background Light Grid Gradient 1',
      component: <BgLightGridGradient1 />,
      theme: 'light',
    },
    {
      name: 'Background Light Grid Gradient 2',
      component: <BgLightGridGradient2 />,
      theme: 'light',
    },
    {
      name: 'Background Light Grid ',
      component: <BgLightGrid1 />,
      theme: 'light',
    },
    {
      name: 'Background Light Grid 2',
      component: <BgLightGrid2 />,
      theme: 'light',
    },
    {
      name: 'Background Light Grid 3',
      component: <BgLightGrid3 />,
      theme: 'light',
    },
    {
      name: 'Background Light Grid 4',
      component: <BgLightGrid4 />,
      theme: 'light',
    },
    {
      name: 'Background Light Grid 5',
      component: <BgLightGrid5 />,
      theme: 'light',
    },
    {
      name: 'Background Dark Gradient',
      component: <BgDarkGradient1 />,
      theme: 'dark',
    },
    {
      name: 'Background Dark Gradient 2',
      component: <BgDarkGradient2 />,
      theme: 'dark',
    },
    {
      name: 'Background Dark Gradient 3',
      component: <BgDarkGradient3 />,
      theme: 'dark',
    },
    {
      name: 'Background Dark Gradient 4',
      component: <BgDarkGradient4 />,
      theme: 'dark',
    },
    {
      name: 'Background Dark Gradient 5',
      component: <BgDarkGradient5 />,
      theme: 'dark',
    },
    {
      name: 'Background Dark Grid Gradient 1',
      component: <BgDarkGridGradient1 />,
      theme: 'dark',
    },
    {
      name: 'Background Dark Grid 1',
      component: <BgDarkGrid1 />,
      theme: 'dark',
    },
    {
      name: 'Background Dark Grid 2',
      component: <BgDarkGrid2 />,
      theme: 'dark',
    },
    {
      name: 'Background Dark Grid 3',
      component: <BgDarkGrid3 />,
      theme: 'dark',
    },
  ] as const;

  const VARIANTS = [
    'light-gradrient-1',
    'light-gradrient-2',
    'light-gradrient-3',
    'light-gradrient-4',
    'light-gradrient-5',
    'light-gradrient-6',
    'light-grid-gradient-1',
    'light-grid-gradient-2',
    'light-grid-1',
    'light-grid-2',
    'light-grid-3',
    'light-grid-4',
    'light-grid-5',
    'dark-gradrient-1',
    'dark-gradrient-2',
    'dark-gradrient-3',
    'dark-gradrient-4',
    'dark-gradrient-5',
    'dark-grid-gradient-1',
    'dark-grid-1',
    'dark-grid-2',
    'dark-grid-3',
  ];

  const variantsToComponent :any = {
    'light-gradrient-1': BgLightGradient1,
    'light-gradrient-2': BgLightGradient2,
    'light-gradrient-3': BgLightGradient3,
    'light-gradrient-4': BgLightGradient4,
    'light-gradrient-5': BgLightGradient5,
    'light-gradrient-6': BgLightGradient6,
    'light-grid-gradient-1': BgLightGridGradient1,
    'light-grid-gradient-2': BgLightGridGradient2,
    'light-grid-1': BgLightGrid1,
    'light-grid-2': BgLightGrid2,
    'light-grid-3': BgLightGrid3,
    'light-grid-4': BgLightGrid4,
    'light-grid-5': BgLightGrid5,
    'dark-gradrient-1': BgDarkGradient1,
    'dark-gradrient-2': BgDarkGradient2,
    'dark-gradrient-3': BgDarkGradient3,
    'dark-gradrient-4': BgDarkGradient4,
    'dark-gradrient-5': BgDarkGradient5,
    'dark-grid-gradient-1': BgDarkGridGradient1,
    'dark-grid-1': BgDarkGrid1,
    'dark-grid-2': BgDarkGrid2,
    'dark-grid-3': BgDarkGrid3,
  };

  const variantsToComponentKey :any = {
    'light-gradrient-1': 'BgLightGradient1',
    'light-gradrient-2': 'BgLightGradient2',
    'light-gradrient-3': 'BgLightGradient3',
    'light-gradrient-4': 'BgLightGradient4',
    'light-gradrient-5': 'BgLightGradient5',
    'light-gradrient-6': 'BgLightGradient6',
    'light-grid-gradient-1': 'BgLightGridGradient1',
    'light-grid-gradient-2': 'BgLightGridGradient2',
    'light-grid-1': 'BgLightGrid1',
    'light-grid-2': 'BgLightGrid2',
    'light-grid-3': 'BgLightGrid3',
    'light-grid-4': 'BgLightGrid4',
    'light-grid-5': 'BgLightGrid5',
    'dark-gradrient-1': 'BgDarkGradient1',
    'dark-gradrient-2': 'BgDarkGradient2',
    'dark-gradrient-3': 'BgDarkGradient3',
    'dark-gradrient-4': 'BgDarkGradient4',
    'dark-gradrient-5': 'BgDarkGradient5',
    'dark-grid-gradient-1': 'BgDarkGridGradient1',
    'dark-grid-1': 'BgDarkGrid1',
    'dark-grid-2': 'BgDarkGrid2',
    'dark-grid-3': 'BgDarkGrid3',
  };

  const wrappedInRelative = {
    'light-grid-2': true,
    'light-grid-5': true,
    'dark-gradrient-3': true,
    'dark-gradrient-4': true,
    'dark-gradrient-5': true,
    'dark-grid-2': true,
    'dark-grid-gradient-1': true,
    'dark-grid-3': true,
  } as any;


function bgPatternTreeNodeToComponentTreeNode(node: any) : ComponentTreeNode | null {
    if (!check.nonEmptyObject(node)) {
      return null;
    }

    return {
      component: node.component,
      htmlComponent: node.component,
      classes: cn(node.props.className).split(' ').filter(check.nonEmptyString),
      props: removeEmptyProps({
        ...node.props,
        className: cn(node.props.className),
      }),
      children: (node.children || []).map(bgPatternTreeNodeToComponentTreeNode),

      variant: null,
      text: null,
    } as ComponentTreeNode;
}

function getComponentTreeNodeFromVariant(variant: string) : ComponentTreeNode | null {
    const componentName = variantsToComponentKey[variant];
    if (!componentName) {
      return null;
    }
    const matchingKey = Object.keys(bgPatternComponents).find(k => k.toLowerCase().trim() === componentName.toLowerCase().trim());
    if (matchingKey && (bgPatternComponents as Record<string, any>)[matchingKey]) {
      let comp = bgPatternTreeNodeToComponentTreeNode((bgPatternComponents as Record<string, any>)[matchingKey]);
      if (!comp) {
        return null;
      }
      comp.comments = ['Background pattern by @ibelick (https://bg.ibelick.com/)'];

      return comp;
    }

    return null;
}

  

  const BgPatternComponentParser = {
    tags: ['bg-pattern'],
    name: 'Background Pattern',
    description: 'A background pattern to use for the page.',
    refImplementation: `
    /bg-pattern %light-gradrient-1
    `.trim(),
    wrapParent: (parent: ComponentTreeNode) => {
        const patterns = (parent.children || []).filter(comp => comp.component === 'bg-pattern');
        if (patterns.length === 0) {
          return parent;
        }

        let isDark = false;

        return {
          component: Fragment,
          htmlComponent: 'Fragment',
          classes: [],
          props: {
            className: '',
          },
          children: [
            {
              component: 'div',
              htmlComponent: 'div',
              classes: ['absolute size-full z-0'],
              props: {
                className: 'absolute size-full z-0',
              },
              children: [
                ...patterns.map((c: ComponentTreeNode) => {
                    let { variant } = c;
                    const selectedVariant = selectVariant(VARIANTS, variant);

                    if (selectedVariant.includes('dark')) {
                      isDark = true;
                    }

                    // Return ejected component tree instead of a React component
                    return getComponentTreeNodeFromVariant(selectedVariant);
                    
                    /*
                    const component = variantsToComponent[selectedVariant] || Fragment;
                    const htmlComponent = 'BgPattern';

                    if (selectedVariant.includes('dark')) {
                      isDark = true;
                    }

                    return {
                      component,
                      htmlComponent,
                      props: {},
                      classes: [],
                      children: [],
                    };
                    */
                }),
              ],
            },
            {
              component: 'div',
              htmlComponent: 'div',
              classes: ['relative size-full z-1' + (isDark ? ' dark' : '')],
              props: {
                className: 'relative size-full z-1' + (isDark ? ' dark' : ''),
              },
              children: (parent.children || []).filter((comp: ComponentTreeNode) => comp.component !== 'bg-pattern'),
            }
          ],
        };
    },
    variants: VARIANTS,
};

export default BgPatternComponentParser;