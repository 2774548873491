import { publicPageMarkdown } from "@/lib/dbRoutes";
import { useLocalOrRemoteValue } from "./useLocalOrRemoteValue";
import check from "@/vendors/check";
import uppserlower from 'upperlower';
import split from 'string-split-by';
import { parsePropValue } from "@/lib/utils";
import { useMemo } from "react";

export type PageInfo = {
    title: string,
    path: string,
};

export default function usePages(pageId?: string) : PageInfo[] {
    const { value: src } = useLocalOrRemoteValue(`//${pageId}/editor-code`, publicPageMarkdown(pageId), null, true);

    // Get pages
    // FIXME: We may want to use the parser for this in the future, to make things more resilient
    let pages : PageInfo[] = [];
    if (check.nonEmptyString(src)) {
        src.split('\n').filter(l => l.trim().startsWith('/page ')).forEach(pageLine => {
            const content = pageLine.trim().substring('/page '.length).trim();
            if (check.nonEmptyString(content) && content.includes('/')) {
                const path = content.split(' ').find(word => word.startsWith('/'));
                if (!check.nonEmptyString(path)) {
                    return;
                }

                let title = path;
                if (path === '/') {
                    title = 'Home';
                } else {
                    let pathTitleBase = path.replaceAll('s/:', '/:');
                    title = uppserlower(pathTitleBase.split('/').filter(w => !w.startsWith(':')).join(' ').trim(), 'pascalcase');
                }

                if (content.includes('@title=')) {
                    const parts = split(content, ' ');
                    const titlePart = parts.find((p: string) => p.startsWith('@title='));
                    if (check.nonEmptyString(titlePart)) {
                        let titleVal = parsePropValue(titlePart.substring('@title='.length));
                        if (check.nonEmptyString(titleVal)) {
                            title = titleVal;
                        }
                    } 
                }

                pages.push({
                    path, 
                    title,
                });
            }
        });
    }

    // Sort pages by path length 
    pages = pages.sort((a, b) => (a.path.length - b.path.length));

    // Add home page if not empty but no home
    if (check.nonEmptyArray(pages) && !pages.some(p => p.path.trim() === '/')) {
        pages.unshift({
            title: 'Home',
            path: '/',
        });
    }

    // Memoize output
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoized = useMemo(() => pages, [JSON.stringify(pages)]);

    return memoized;
}