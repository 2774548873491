
import { registerComponentVariant } from '../../../lib/components-registry';
import { CARD_BUTTON_VARIANT_DESCRIPTION, CARD_BUTTON_VARIANT_NAME, CARD_COMPONENT_DESCRIPTION, CARD_COMPONENT_NAME, CARD_IMG_VARIANT_DESCRIPTION, CARD_IMG_VARIANT_NAME, CARD_VARIANT_DESCRIPTION, CARD_VARIANT_NAME } from '../../../lib/constants';
import Card_B from './Card_button';
import {ImgCard} from './ImgCard';

registerComponentVariant({
    reactComponent: Card_B as React.ComponentType,
    name: CARD_COMPONENT_NAME,
    description: CARD_COMPONENT_DESCRIPTION,
    variantName: CARD_IMG_VARIANT_NAME,
    variantDescription: CARD_IMG_VARIANT_DESCRIPTION,
    subVariantName: CARD_BUTTON_VARIANT_NAME,
    subVariantDescription: CARD_BUTTON_VARIANT_DESCRIPTION,
    acceptsProps: ['image', 'title', 'description'],
    acceptsChildren: false,
    acceptSizes: ['lg'],
    acceptOrientations: ['east'],
    acceptsRoundness: ['none','sm', 'md', 'lg', 'xl'],
});

registerComponentVariant({
    reactComponent: ImgCard as React.ComponentType,
    name: CARD_COMPONENT_NAME,
    description: CARD_COMPONENT_DESCRIPTION,
    variantName: CARD_IMG_VARIANT_NAME,
    variantDescription: CARD_IMG_VARIANT_DESCRIPTION,
    acceptsProps: ['image', 'title', 'description'],
    acceptsChildren: false,
    acceptSizes: ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl'],
    acceptOrientations: ['north'],
    acceptsRoundness: ['none','sm', 'md', 'lg', 'xl', '2xl', '3xl', 'full'],
    acceptsAspectRatios: ['9/16', '3/4', 'square', '16/9', '4/3']
});