// ////////////////////////////////////////////
// From Layouts.dev/lvf0sfdt
// ////////////////////////////////////////////
/*
    /dropdown-menu @side=top
        /item @inset={false} p-0 min-w-[200px]
            /hstack rounded-lg bg-[#F4F3F1] gap-[6px] rounded-[6px] w-full py-[10px] px-[8px] items-start
                /avatar A size-[24px] bg-[#5B5E66]
                    /fallback bg-[#5B5E66] A
                /hstack justify-between flex-1
                    /vstack
                        /span Alex McKee text-[14px] font-['Inter'] font-[500] leading-[18px]
                        /span All settings font-['Inter'] text-[rgba(23,23,23,0.50)] text-[10px] leading-[18px] font-[500]
                    /shortcut <Cmd + P> opacity-100 text-[12px] leading-[20px] text-[#5B5E66] font-[400]
*/
// ////////////////////////////////////////////

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/shadcn/ui/dropdown-menu"
import UserAvatar from "../UserAvatar";
import { SignOutButton, UserProfile } from "@clerk/clerk-react";
import useAuth from "@/components/auth/useAuth";
import { Button } from "@/components/shadcn/ui/button";
import { Separator } from "@/components/shadcn/ui/separator";
import { Sheet, SheetContent } from "@/components/shadcn/ui/sheet";
import { Switch } from "@/components/shadcn/ui/switch";
import { Input } from "@/components/shadcn/ui/input";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/shadcn/ui/select";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/shadcn/ui/tooltip";
import { ArrowUpRight, Code, GitBranch, Info, LogOut, Pin, PinOff } from "lucide-react";
import { useState } from "react";
import { Dialog, DialogContent } from "@radix-ui/react-dialog";
import * as Portal from '@radix-ui/react-portal';
import { cn } from "@/lib/utils";
import packageJson from "@/../package.json";

export default function SettingsMenu({ 
	userName, children, asChild = false, userImageUrl = null, togglePin, pinned }
	: { userName: string, children: React.ReactNode, asChild?: boolean, userImageUrl?: string | null, togglePin: () => void, pinned: boolean }) {
    const { signOut } = useAuth();
	const [ sheetOpen, setSheetOpen ] = useState(false);
	const [ settingsOpen, setSettingsOpen ] = useState(false);
	const currentPage = window.location.href;
    const SignOut = SignOutButton as any;

    return (
		<>
			<Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
				<SheetContent className="flex flex-col gap-2.5" >
					<div className="flex flex-col gap-1.5" >
						<h1 className="text-2xl text-[#1e1f22]" >
							Account Settings
						</h1>
						<span className="text-sm text-[#5b5e66]" >
							Manage your account settings
						</span>
					</div>
					<Separator className="w-full" orientation="horizontal" />
					<div className="flex flex-col gap-2 py-2" >
						<div className="flex flex-row place-content-between w-full items-center" >
							<h1 className="text-sm font-medium text-[#1e1f22]" >
								Enable AI features
							</h1>
							<Switch />
						</div>
						<span className="text-xs font-normal text-[#5b5e66]" >
							Do you want to enable AI features including:
						</span>
						<span className="text-xs font-normal text-[#5b5e66]" >
							• AI Chat (Alpha)
						</span>
						<span className="text-xs font-normal text-[#5b5e66]" >
							• Prompt to Tailwind
						</span>
						<span className="text-xs font-normal text-[#5b5e66]" >
							• Generate images with AI
						</span>
					</div>
					<div className="flex flex-col gap-2 py-2 items-start" >
						<h1 className="text-sm text-[#5b5e66]" >
							Enter your OpenAI API key
						</h1>
						<Input className="reset border py-1.5 px-2.5 text-sm rounded-md w-full outline-white focus:outline-gray-200 text-[#1e1f22] focus:shadow-sm" placeholder="Your API key..." />
						<div className="flex w-full items-end" >
							<Button className="reset flex flex-col items-center rounded-lg bg-white border border-[#e4e4e4] shadow-sm hover:bg-[#f4f3f1] text-[#5b5e66] hover:text-[#1e1f22] w-fit h-min py-1.5 px-3 justify-end" variant="default" >
								<span className="text-[14px] font-normal" >
									Save
								</span>
							</Button>
						</div>
					</div>
					<Separator className="w-full" orientation="horizontal" />
					<div className="flex flex-col gap-2 py-2" >
						<div className="flex flex-col w-full items-start gap-2" >
							<h1 className="text-sm font-medium text-[#1e1f22]" >
								Component features
							</h1>
						</div>
						<Select key="select_lxqyyh2d" >
							<SelectTrigger className="shadow-sm text-xs font-normal" >
								<SelectValue className="placeholder:text-[#5b5e66]" placeholder="Pick your component" />
							</SelectTrigger>
							<SelectContent className="w-[180px] text-[#1e1f22]">
								<SelectGroup className="flex flex-col items-start" >
									<SelectLabel className="text-xs font-normal text-gray-400" >
										Component types
									</SelectLabel>
									<SelectItem className="reset flex flex-row p-2 w-full rounded-md text-sm hover:shadow-sm aria-selected:bg-[#f4f3f1] font-normal text-[#5b5e66] focus:bg-[#f4f3f1] pl-10 items-center aria-selected:text-[#1e1f22]" value="Production-grade" >
										<span>
											Production-grade
										</span>
									</SelectItem>
									<SelectItem className="reset flex flex-row p-2 w-full rounded-md text-sm hover:shadow-sm aria-selected:bg-[#f4f3f1] font-normal text-[#5b5e66] focus:bg-[#f4f3f1] pl-10 items-center aria-selected:text-[#1e1f22]" value="Experimental (Alpha)" >
										<span>
											Experimental
										</span>
									</SelectItem>
									<SelectItem className="reset flex flex-row p-2 w-full rounded-md text-sm hover:shadow-sm aria-selected:bg-[#f4f3f1] font-normal text-[#5b5e66] focus:bg-[#f4f3f1] pl-10 items-center aria-selected:text-[#1e1f22]" value="Web" >
										<span>
											Web
										</span>
									</SelectItem>
									<SelectItem className="reset flex flex-row p-2 w-full rounded-md text-sm hover:shadow-sm aria-selected:bg-[#f4f3f1] font-normal text-[#5b5e66] focus:bg-[#f4f3f1] pl-10 items-center aria-selected:text-[#1e1f22]" value="React Native (Apps)" >
										<span>
											React Native
										</span>
									</SelectItem>
								</SelectGroup>
							</SelectContent>
						</Select>
					</div>
				</SheetContent>
			</Sheet>

			{true && (
				<Portal.Root>
					<div className={cn ("flex flex-col justify-center items-center absolute top-0 left-0 w-screen h-screen z-50 bg-slate-950/80" , settingsOpen ? "" : 'hidden')}>
						<Dialog open={settingsOpen} onOpenChange={() => setSettingsOpen(false)}>
							<DialogContent className="reset">
								<UserProfile />
							</DialogContent>
						</Dialog>
					</div>
				</Portal.Root>
			)}	

			<DropdownMenu>
				<DropdownMenuTrigger asChild={asChild}  >
					{children}
				</DropdownMenuTrigger>
				<DropdownMenuContent side="top" sideOffset={8} align="start" className="rounded-lg border p-1  shadow-md gap-1">
						<DropdownMenuItem 
							onClick={() => setSettingsOpen(true)}
							className="reset flex flex-row items-start gap-2 p-2 hover:bg-[#f4f3f1] bg-white w-full rounded-lg cursor-pointer focus:outline-none" 
						>
							<UserAvatar userName={userName} url={userImageUrl} className="size-[32px]"/>
							<div className="flex flex-col rounded-lg items-start" >
								<span className="text-sm font-medium text-[#1e1f22] text-nowrap" >
									{userName}
								</span>
								<span className="text-xs font-normal text-[#5b5e66]" >
									Profile settings
								</span>
							</div>
						</DropdownMenuItem>
						

						<DropdownMenuSeparator className="bg-gray-100" />
						<DropdownMenuItem >
							<div className="flex flex-row gap-2 w-full items-center overflow-visible" >
								<GitBranch className="text-slate-400 size-3.5" />
								<div className="flex flex-col items-start overflow-visible" >
									<span className="text-sm text-slate-400 font-normal" >
										{`Editor version: ${packageJson.version}`}
									</span>
								</div>
							</div>
						</DropdownMenuItem>
						<DropdownMenuSeparator className="bg-gray-100" />
						
						<DropdownMenuItem 
							onClick={() => setSheetOpen(true)}
							className="reset flex flex-row items-center gap-2 p-2 hover:bg-[#f4f3f1] bg-white w-full rounded-lg place-content-between overflow-visible cursor-pointer  focus:outline-none" 
						>
							<div className="flex flex-row gap-2 w-full items-center overflow-visible" >
								<Code className="text-[#5b5e66] size-3.5" />
								<div className="flex flex-col items-start overflow-visible" >
									<span className="text-sm text-[#5b5e66] font-normal" >
										Editor Settings
									</span>
								</div>
							</div>
						</DropdownMenuItem>
						<TooltipProvider >
									<Tooltip>
										<TooltipTrigger asChild >
						<DropdownMenuItem onClick={() => {
							window.open("https://discord.com/invite/ysmacWJSzK", "_blank");
						}} className="reset flex flex-row items-center gap-2 p-2 hover:bg-[#f4f3f1] w-full rounded-lg place-content-between overflow-visible cursor-pointer  focus:outline-none" >
							<div className="flex flex-row gap-2 w-full items-center overflow-visible" >
								<Info className="text-[#5b5e66] size-3.5" />
								<span className="text-sm text-[#5b5e66]" >
									Beta feedback
								</span>	
							</div>									
							<ArrowUpRight className="size-3.5 text-[#5b5e66]" />
						</DropdownMenuItem>
						</TooltipTrigger>
										<TooltipContent 
											side="right" 
											sideOffset={8}
											className="reset bg-[#1e1f22] border-none py-1.5 px-3 h-min rounded-lg flex flex-col items-center" 
										>
											<span className="text-xs text-white" >
												Go to Discord
											</span>
										</TooltipContent>
									</Tooltip>
							</TooltipProvider>
						<DropdownMenuSeparator className="bg-gray-100" />
						<DropdownMenuItem 
							onClick={togglePin}
							className="reset flex flex-row items-center gap-2 p-2 hover:bg-[#f4f3f1] bg-white w-full rounded-lg place-content-between overflow-visible cursor-pointer  focus:outline-none" 
						>
							<div className="flex flex-row gap-2 w-full items-center overflow-visible" >
								{pinned ? (
									<PinOff className="text-[#5b5e66] size-3.5" />
								) : (
									<Pin className="text-[#5b5e66] size-3.5" />
								)}
								<div className="flex flex-col items-start overflow-visible" >
									<span className="text-sm text-[#5b5e66] font-normal" >
										{pinned ? 'Unpin dock' : 'Pin dock'}
									</span>
								</div>
							</div>
						</DropdownMenuItem>
						<DropdownMenuSeparator className="bg-gray-100" />
						<DropdownMenuItem className="reset flex flex-row items-center gap-2 p-2 hover:bg-[#f4f3f1] text-[#5b5e66] bg-white w-full rounded-lg hover:text-red-500 cursor-pointer focus:outline-none"  onClick={signOut} >
							<LogOut className="size-3.5" />
							<span className="text-sm" >
								Log out
							</span>
						</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
		</>
		
		
    );
};


		