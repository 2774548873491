import check from "@/vendors/check";
import { isUrlOrDataUrl, isVariableName, removeEmptyProps, selectVariant } from "../../util";
import { ComponentTreeNode } from "../component.type";
import { cn, nonEmptyTrimmed } from "@/lib/utils";
import { DynamicImageOf, ImageOf } from "@/vendors/illustrations.dev/ImageOf";
import { ComponentParserConfig } from "../../parser-config";
import { ComponentType } from "react";
import { getInstantMetadataForImageQuery, getInstantUrlForImageQuery, logUseOfUnsplashImage } from "@/vendors/illustrations.dev/get-image-of";
import { registerComponent } from "../../importsRegistry";
import { DataDisplay } from "@/vendors/data/data-v2";
import { IllustrationOf } from "@/vendors/illustrations.dev/IllustrationOf";
import { IconOf } from "@/vendors/illustrations.dev/IconOf";

export const ICON_COMPONENT_HTML_TYPE = 'ImageOf';
export const DEFAULT_IMAGE_VARIANT = 'unsplash';

export function isIconComponent(c: ComponentTreeNode) {
    return check.nonEmptyObject(c) && (
        c.component === 'icon'
        || c.component === 'img'
        || (
            check.nonEmptyString(c.htmlComponent) 
            && ['img', ICON_COMPONENT_HTML_TYPE, 'IconOf'].includes(c.htmlComponent)
        )
    );
}

const IMAGE_SETUP = [
    'npm install --save @layouts.dev/utils',
];

/*
function hasHardCodedUrl(c: ComponentTreeNode) {
    return (
        isUrlOrDataUrl(c?.text) 
        || isUrlOrDataUrl(c?.props?.src) 
        || (
            check.nonEmptyString(c?.text) 
            && (c.text as string).trim().split(' ').some((w: string) => isUrlOrDataUrl(w))
        )
    );
}

function renderStaticImage(c: ComponentTreeNode, className: string = '') {
    let url = '';
    let alt = '';
    if (isUrlOrDataUrl(c?.props?.src)) {
        url = c.props.src;
    } else if (check.nonEmptyString(c?.text) && !c.text.trim().includes(' ') && isUrlOrDataUrl(c?.text)) {
        url = c.text;
    } else if (check.nonEmptyString(c?.text) && check.nonEmptyString(c.text.trim())) {
        const words = c.text.trim().split(' ');
        url = words.find(w => isUrlOrDataUrl(w)) || '';
        alt = words.filter(w => !isUrlOrDataUrl(w)).join(' ');
    }

    return {
        component: 'img',
        classes: cn(className, c.classes || []).split(' ').filter(nonEmptyTrimmed),
        props: { 
            alt,
            ...c.props,
            src: url, 
        },
        children: null,
        htmlComponent: 'img',
        variant: null,
        text: null,
    };
}
*/

function parseImageText(txt : string, allowedVariants : string[]) : { src?: string, alt?: string, of?: string, variant?: string, transforms?: string[] } {
    if (!check.nonEmptyString(txt)) {
        return {};
    }

    if (isUrlOrDataUrl(txt.trim())) {
        return { src: txt.trim() };
    }
    let variant;
    let alt;
    let of = txt.trim();
    let transforms : string[] = [];

    if (/^[a-zA-Z0-9/-_]+:.+$/g.test(txt.trim())) {
        const parts = txt.trim().split(':');
        variant = parts.shift();
        of = parts.join(':');
    }

    if (of.includes('|')) {
        const parts = of.split('|').map(i => i.trim()).filter(check.nonEmptyString);
        if (parts.length > 0) {
            of = parts.shift() || '';
            transforms = parts;
        }
    }

    if (isUrlOrDataUrl(of) && !check.nonEmptyArray(transforms)) {
        return { src: of };
    }

    if (of.split(' ').some(w => isUrlOrDataUrl(w))) {
        const words = of.split(' ');
        const url = words.find(w => isUrlOrDataUrl(w));
        const newAlt = words.filter(w => !isUrlOrDataUrl(w)).join(' ').trim();
        if (check.nonEmptyString(url)) {
            of = url;
        }
        if (check.nonEmptyString(newAlt)) {
            alt = newAlt;
        }
    } else if (!isUrlOrDataUrl(of)) {
        alt = of;
    }

    if (isUrlOrDataUrl(of)) {
        return removeEmptyProps({ src: of, alt });
    }

    return {
        alt, 
        of,
        variant: (check.nonEmptyString(variant) && allowedVariants.includes(variant)) ? variant : undefined, 
        transforms 
    };
}

function imageComponentToProps(c: ComponentTreeNode, allowedVariants: string[]): { bind?: string, of?: string, alt?: string, variant?: string, src?: string, transforms?: string[] } {
    const text = c.text || c.props?.of || '';
    const parsed = parseImageText(text, allowedVariants);

    const cleanProps = removeEmptyProps(c.props || {});
    
    const src = cleanProps.src || parsed.src;
    const alt = cleanProps.alt || parsed.alt || undefined;
    const variant = parsed.variant || selectVariant(allowedVariants, c.variant);
    const of = parsed.of;

    if (check.nonEmptyString(src)) {
        if (isVariableName(src)) {
            return removeEmptyProps({ bind: src.trim(), alt, variant });
        }
        return removeEmptyProps({ src, alt });
    }

    if (isVariableName(of)) {
        return removeEmptyProps({ bind: of, alt, variant, transforms: parsed.transforms });
    }

    return removeEmptyProps({ of, alt, variant, transforms: parsed.transforms });
}

function sizesFromSrcSet(srcSet: string[]) {
    if (!check.nonEmptyArray(srcSet)) {
        return undefined;
    }
    
    try {
        return srcSet
            .map((line) => parseInt(line.split(' ')[1].replace('w', '')))
            .map((width, i) => {
                if (i === srcSet.length - 1) {
                    return `${width}px`;
                }
                return `(max-width: ${width}px) ${width}px`;
            })
            .join(', ');
    } catch (e) {
        console.error('Error parsing srcset:', e);
    }
    return undefined;
}
    

function getImageParser({
    reactComponent,
    reactComponentName,
    baseClasses,
    acceptedVariants, 
    queryPrefix = '',
    querySuffix = '',
    forcedVariant,
    defaultVariant,
} : {
    reactComponent: ComponentType | string,
    reactComponentName: string,
    baseClasses?: string[],
    acceptedVariants: string[],
    queryPrefix?: string,
    querySuffix?: string,
    forcedVariant?: string,
    defaultVariant?: string,
}) : (c: ComponentTreeNode, config: ComponentParserConfig) => ComponentTreeNode | null {
    return ((c: ComponentTreeNode, config: ComponentParserConfig) => {
        if (c._dontParse) {
            return c;
        }

        const parsedProps = imageComponentToProps(c, acceptedVariants);
        const { bind, of, alt, variant, src, transforms } = parsedProps;
        const classes = cn([...(baseClasses || []), ...(c.classes || [])]).split(' ').filter(nonEmptyTrimmed);

        // Return nothing if the image has no source
        if (!nonEmptyTrimmed(of) && !nonEmptyTrimmed(src) && !nonEmptyTrimmed(bind)) {
            return null;
        }

        // Handle dynamic images
        if (bind) {
            const queryTemplate = queryPrefix + '{{value}}' + querySuffix + (check.nonEmptyArray(transforms) ? (' | ' + transforms.join(' | ')) : '');
            let altValue : string | undefined = (check.nonEmptyString(alt) && alt !== bind) ? alt : ((check.nonEmptyString(of) && of !== bind) ? of : undefined);

            return {
                component: DataDisplay as React.ComponentType,
                htmlComponent: 'DataDisplay',
                classes: [],
                props: { bind },
                children: [{
                    component: DynamicImageOf as React.ComponentType,
                    htmlComponent: 'DynamicImageOf',
                    props: {
                        alt: altValue,
                        queryTemplate: queryTemplate === '{{value}}' ? undefined : queryTemplate,
                        variant: forcedVariant || variant || defaultVariant,
                        className: cn(classes).split(' ').filter(nonEmptyTrimmed).join(' '),
                    },
                    classes,
                    variant: null,
                    text: null,
                    children: null,
                }],
            };
        }

        // Handle static images
        if (src) {
            return {
                component: 'img',
                htmlComponent: 'img',
                classes,
                props: { 
                    alt: '', 
                    ...removeEmptyProps({ 
                        src, 
                        alt,
                        className: cn(classes).split(' ').filter(nonEmptyTrimmed).join(' '),
                    }),
                },
                variant: null,
                children: null,
                _dontParse: true, // If we don't add this, component can be parsed in loop
            };
        }

        // Handle illustrations.dev images code output
        if (config.imageExportMode !== 'component') {
            const source = forcedVariant || variant || defaultVariant || DEFAULT_IMAGE_VARIANT;
            const query = queryPrefix + of + querySuffix + (check.nonEmptyArray(transforms) ? (' | ' + transforms.join(' | ')) : '');
            const metadata = getInstantMetadataForImageQuery(source, query);
            console.log('Final metadata for image "' + source + ': ' + query + '":', metadata);

            return {
                comments: variant === 'unsplash' && metadata?.credits ? [ metadata?.credits.replaceAll('\n', ' - ') ] : [],
                component: 'img',
                htmlComponent: 'img',
                classes,
                props: { 
                    alt: '', 
                    ...removeEmptyProps({ 
                        // src: `./assets/${name}`,
                        src: getInstantUrlForImageQuery(
                            source,
                            query,
                        ),
                        // srcSet: check.nonEmptyArray(metadata?.srcSet) ? (metadata?.srcSet.join(', ')) : undefined,
                        // sizes: sizesFromSrcSet(metadata?.srcSet),
                        alt: alt || of,
                        className: cn(classes).split(' ').filter(nonEmptyTrimmed).join(' '),

                        ...(config.target === 'react-native' ? {
                            metadata,
                        } : {})
                    }),
                },
                variant: null,
                children: null,
                _dontParse: true, // If we don't add this, component can be parsed in loop
            };
        }

        // We are currently outputting a React components, so we're in the player
        // If image is an unsplash image, log its use to comply to Unsplash API
        if (variant === 'unsplash') {
            const source = forcedVariant || variant || defaultVariant || DEFAULT_IMAGE_VARIANT;
            const query = queryPrefix + of + querySuffix + (check.nonEmptyArray(transforms) ? (' | ' + transforms.join(' | ')) : '');
            const metadata = getInstantMetadataForImageQuery(source, query);

            if (check.nonEmptyObject(metadata)) {
                logUseOfUnsplashImage(metadata);
            }
        }

        // Handle illustrations.dev images
        return {
            component: reactComponent,
            htmlComponent: reactComponentName,
            classes,
            props: { alt: '', ...removeEmptyProps({ 
                of: queryPrefix + of + querySuffix + (check.nonEmptyArray(transforms) ? (' | ' + transforms.join(' | ')) : ''), 
                alt, 
                variant,
                className: cn(classes).split(' ').filter(nonEmptyTrimmed).join(' '),
            }) },
            variant: forcedVariant || variant || defaultVariant,
            children: null,
            _dontParse: true, // If we don't add this, component can be parsed in loop
        };
    });
}


/*
function renderIllustrationsDevImage(c: ComponentTreeNode, ofPrefix: string = '') {
    // Define alt, src, of
    const props : { [key: string]: any } = {};
    if (check.nonEmptyString(c?.props?.src)) {
        props.src = c.props.src;
        if (check.nonEmptyString(c.props?.alt)) {
            props.alt = c.props.alt;
        } else if (check.nonEmptyString(c.props?.of)) {
            props.alt = c.props?.of;
        }
    } else {
        if (check.nonEmptyString(c.props?.of)) {
            props.of = ofPrefix + c.props?.of;
            props.alt = c.props?.alt || c.props?.of;
        } else if (check.nonEmptyString(c.text)) {
            props.of = ofPrefix + c.text;
            props.alt = c.text;
        } else if (check.nonEmptyString(c.props?.alt)) {
            props.of = ofPrefix + c.props.alt;
            props.alt = c.props.alt;
        }
    }

    // Add other props
    const { src, alt, of: _, className, ...rest } = c.props || {};
    if (check.nonEmptyObject(rest)) {
        Object.keys(rest).forEach(k => {
            props[k] = rest[k];
        });
    }

    return {
        component: ImageOf,
        htmlComponent: 'ImageOf',
        classes: c.classes || [],
        props,
        variant: c.variant,
        children: null,
        text: null,
    };
}
*/

// TODO: Add sources for images when user has paid for them
const IMAGE_VARIANTS = [
    'unsplash',
    // 'lexica',
    'google',
    // 'lexica-aperture',
    /*
    'dall-e',
    'sdxl',
    'ai/3d-clay-icon',
    'ai/3d-icon',
    'ai/children-book',
    'ai/clear-style',
    'ai/comics',
    'ai/flat-illustration',
    'ai/interior-design',
    'ai/night-portrait',
    'ai/outline-icon',
    'ai/poly-clay-animal',
    'ai/portrait',
    'ai/undraw',
    'ai/vector-art',
    */
];

const image = {
    name: 'Image',
    description: 'An image component that fetches an image from the internet or your favorite assets libraries.',
    refImplementation: `/image %lexica spaceship size-48 rounded-lg`,

    tags: ['image', 'img', 'pic', 'picture'],
    parseTree: getImageParser({
        reactComponent: ImageOf as any,
        reactComponentName: 'ImageOf',
        baseClasses: [],
        acceptedVariants: IMAGE_VARIANTS,
        queryPrefix: '',
        querySuffix: '',
        defaultVariant: DEFAULT_IMAGE_VARIANT,
    }),
    
    /* (c: ComponentTreeNode, config: ComponentParserConfig) => {
        // If image is a URL or a data string, return native image directly
        if (hasHardCodedUrl(c)) {
            return renderStaticImage(c);
        }

        // If config asks us to return a url-based component, do so
        // Unless the image should be bound to a variable
        const boundVar = getSingleVarNameFromText(c.text);
        if(!boundVar && config.imageExportMode === 'url') {
            return renderIllustrationsDevImage(c);
        }

        // Else, handle image dynamically
        let { component, classes, props, text } = c;
        if (check.nonEmptyString(text) && nonEmptyTrimmed(text) && text.length > 0) {
            props.bind = getSingleVarNameFromText(text) || null;
            props.alt = text;
            props.of = text;
            component = DataBoundDisplayComponent(ImageOf as any, (rawValue: any) => {
                const value = check.nonEmptyString(rawValue) ? rawValue.trim() : null;
                if (!value) {
                    return {};
                }
                if (value.includes('http')) {
                    return ({ of: null, src: value });
                }
                return ({ alt: value, src: null, of: value });
            }) as any;
        }
        props.variant = c.variant || 'unsplash';
        return { ...c, component, classes, props, children: [], htmlComponent: 'ImageOf', variant: c.variant || 'unsplash'};
        
    }, */
    variants: IMAGE_VARIANTS,
    setup: IMAGE_SETUP,
};

const logo = {
    name: 'Logo',
    description: 'An image component that fetches the logo of a company from the internet.',
    tags: ['logo'],
    refImplementation: `
/logo Tailwind CSS
    `.trim().replaceAll(/ {4}/g, '\t'),

    parseTree: getImageParser({
        reactComponent: ImageOf as any,
        reactComponentName: 'ImageOf',
        baseClasses: ['h-12', 'object-contain'],
        acceptedVariants: ['google'],
        queryPrefix: '',
        querySuffix: ' vector logo',
        forcedVariant: 'google',
    }),
    
    
    /* (c: ComponentTreeNode) => {
        // If image is a URL or a data string, return native image directly
        if (hasHardCodedUrl(c)) {
            return renderStaticImage(c, 'h-12 object-contain');
        }

        let { component, classes, props, text } = c;
        if (check.nonEmptyString(text) && text.length > 0) {
            props.bind = getSingleVarNameFromText(text) || undefined;

            const parts = text.split('|');
            const subject = parts.shift()?.trim() || '';
            const transforms = check.nonEmptyArray(parts) ? parts.map(p => p.trim()).join(' | ') : null;

            props.alt = subject;
            // props.src = 'https://🤖🎨.ws/google:"'+text+'" vector logo';
            props.of = '' + subject +' vector logo' + (transforms ? ' | ' + transforms : '');
            props.variant = 'google';
            component = DataBoundDisplayComponent(ImageOf as any, (value) => {

                let sub;
                let transfs;

                if (check.nonEmptyString(value)) {
                    const prts = value.split('|');
                    sub = prts.shift() || '';
                    transfs = check.nonEmptyArray(prts) ? prts.map(p => p.trim()).join(' | ') : null;
                }
                
                return ({
                    alt: check.nonEmptyString(value) ? sub : '',
                    of: check.nonEmptyString(value) ? ( ''+sub?.trim()+' vector logo' + (transfs ? ' | ' + transfs : '')) : null,
                })}) as any;
        }
        return { ...c, component, classes: cn('h-12 object-contain', classes).split(' '), props, children: null, htmlComponent: 'ImageOf', variant:'google'};
    } */
   setup: IMAGE_SETUP,
};

const ICON_VARIANTS = [
    'lucide',
    /*
    'fontawesome-brands',
    'fontawesome-duotone',
    'fontawesome-light',
    'fontawesome-regular',
    'fontawesome-solid',
    'fontawesome-thin',
    */
];

const icon = {
    description: 'An icon component that fetches an icon from your favorite asset libraries.',
    name: 'Icon',
    refImplementation: `/icon %lucide calendar days size-8 text-teal-500`,
    tags: ['icon'],

    parseTree: getImageParser({
        reactComponent: IllustrationOf as any,
        reactComponentName: ICON_COMPONENT_HTML_TYPE,
        baseClasses: ['size-4'],
        acceptedVariants: ICON_VARIANTS,
        queryPrefix: '',
        querySuffix: '',
    }),
        
    
        /* (c: ComponentTreeNode) => {
        // If image is a URL or a data string, return native image directly
        if (hasHardCodedUrl(c)) {
            return renderStaticImage(c, 'w-4 h-4');
        }

        let { key, component, classes, props, text, variant = null } = c;
        classes = classes || [];
        classes.unshift('h-4');
        classes.unshift('w-4');

        component = DataBoundDisplayComponent(IllustrationOf as any, 'text') as any;
        props.text = text;
        props.key = key || uniqid();
        props.id = props.key;
        props.bind = getSingleVarNameFromText(text);
        props.variant = check.nonEmptyString(variant) ? variant : 'lucide';
        return { ...c, component, classes, props, children: null, htmlComponent: ICON_COMPONENT_HTML_TYPE, variant: props.variant};
    }, */
    variants: ICON_VARIANTS,
    setup: IMAGE_SETUP,
};


// TODO: Enable more variants once users will have paid for them
const ILLUSTRATION_VARIANTS = [
    /*
    'kukla-angle',
    'kukla-face',
    */
    'flatline',
    'isometric',
    'monochromatic',
    'outline',
    'two-color',
    // 'minimalistic',
];

const illustration = {
    tags: ['illustration'],
    name: 'Illustration',
    description: 'An illustration component that fetches an illustration from your favorite assets libraries or generates one via AI.',
    refImplementation: `/illustration %kukla-angle size-8 Calendar`,
    parseTree: getImageParser({
        reactComponent: IllustrationOf as any,
        reactComponentName: 'IllustrationOf',
        baseClasses: [],
        acceptedVariants: ILLUSTRATION_VARIANTS,
        queryPrefix: '',
        querySuffix: '',
    }),
    
    /* (c: ComponentTreeNode) => {
        // If image is a URL or a data string, return native image directly
        if (hasHardCodedUrl(c)) {
            return renderStaticImage(c);
        }

        // Else, handle illustration dynamically
        let { component, classes, props, text } = c;
        component = DataBoundDisplayComponent(IllustrationOf as any, 'text') as any;
        props.text = text;
        props.bind = getSingleVarNameFromText(text);
        return { ...c, component, classes, props, children: null, htmlComponent: 'IllustrationOf' };
    }, */
    variants: ILLUSTRATION_VARIANTS,
    setup: IMAGE_SETUP,
};

export {
    image,
    logo,
    icon,
    illustration,
};

export function getIllustrationComponent(c: { [key: string]: any }, config: ComponentParserConfig) {
    return illustration.parseTree({
        component: 'illustration',
        htmlComponent: 'IllustrationOf',
        classes: check.nonEmptyArray(c.classes) ? c.classes : [],
        props: check.nonEmptyObject(c.props) ? c.props : {},
        text: check.nonEmptyString(c.text) ? c.text : undefined,
        variant: check.nonEmptyString(c.variant) ? c.variant : undefined,
        children: [],
    }, config);
}

export function getImageComponent(c: { [key: string]: any }, config: ComponentParserConfig) {
    return image.parseTree({
        component: 'image',
        htmlComponent: 'ImageOf',
        classes: check.nonEmptyArray(c.classes) ? c.classes : [],
        props: check.nonEmptyObject(c.props) ? c.props : {},
        text: check.nonEmptyString(c.text) ? c.text : undefined,
        variant: check.nonEmptyString(c.variant) ? c.variant : undefined,
        children: [],
    }, config);
}

export function getIconComponent(c: { [key: string]: any }, config: ComponentParserConfig) {
    return icon.parseTree({
        component: 'icon',
        htmlComponent: 'IconOf',
        classes: check.nonEmptyArray(c.classes) ? c.classes : [],
        props: check.nonEmptyObject(c.props) ? c.props : {},
        text: check.nonEmptyString(c.text) ? c.text : undefined,
        variant: check.nonEmptyString(c.variant) ? c.variant : undefined,
        children: [],
    }, config);
}

export function getIconComponentChildParser() {
    return icon;
}

registerComponent('ImageOf', ImageOf, '@layouts.dev/utils');
registerComponent('IconOf', IconOf, '@layouts.dev/utils');
registerComponent('IllustrationOf', IllustrationOf, '@layouts.dev/utils');
registerComponent('DynamicImageOf', DynamicImageOf, '@layouts.dev/utils');
// registerComponent('LogoOf', LogoOf, '@layouts.dev/utils')