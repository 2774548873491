import { Button } from '@/components/shadcn/ui/button';
import React from 'react';

interface BgImageCardProps {
  image: string;
  title: string;
  description: string;
  label: string
}

/* export default function BgImageCard({ image, title, description, label }: BgImageCardProps) {
  return (
    <div className="w-[960px] h-[512px] p-4 flex-col justify-start items-start inline-flex">
        <div className="w-[928px] h-[480px] relative bg-gradient-to-r from-black to-black rounded-xl" style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover' }}>
            <div className="w-[895.84px] h-[480px] p-4 left-[32px] top-[16px] absolute flex-col justify-center items-start gap-8 inline-flex">
            <div className="w-[863.84px] h-[92px] flex-col justify-start items-start flex">
                <div className="w-[863.84px] h-[92px] flex-col justify-start items-start gap-2 flex">
                <div className="w-[863.84px] h-[60px] flex-col justify-start items-center flex">
                    <div className="self-stretch flex-col justify-start items-center flex">
                    <div className="text-center text-white text-5xl font-bold font-display leading-[60px]">{title}</div>
                    </div>
                </div>
                <div className="w-[863.84px] h-6 flex-col justify-start items-center flex">
                    <div className="self-stretch h-6 flex-col justify-start items-center flex">
                    <div className="text-center text-white text-base font-normal font-display leading-normal">{description}</div>
                    </div>
                </div>
                </div>
            </div>
            <div className="w-[863.84px] h-12 justify-center items-start inline-flex">
                <div className="w-[164.83px] h-12 px-5 bg-amber-400 rounded-xl justify-center items-center flex">
                <div className="w-[124.83px] h-6 bg-amber-400 flex-col justify-start items-start inline-flex">
                    <div className="self-stretch flex-col justify-start items-start flex">
                    <div className="text-red-700 text-base font-bold font-display leading-normal tracking-tight">{label}</div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    );
} */

export default function BgImageCard({ image, title, description, label }: BgImageCardProps) {
	return (
		<div className="justify-start items-start flex-col flex w-full min-h-[calc(100vw_*_0.5172413793)] relative" >
			<div className="justify-start items-start flex-col flex z-10 p-[40px] gap-[32px] m-auto" >
                <div className="justify-start items-start flex-col flex gap-[8px] text-white m-auto" >
                    <h1 className="text-semibold font-black text-[48px] font-display m-auto text-center" >
                        {title}
                    </h1>
                    <p className="font-body text-[16px] m-auto text-center" >
                        {description}
                    </p>
				</div>
                <div className='flex flex-row gap-4 m-auto'>
                    <Button className="gap-2 m-auto" variant="default" >
                        {label}
                    </Button>
                </div>
			</div>
            <img
                alt="coffee"
                className="absolute inset-0 h-full w-full object-cover z-0 overflow-hidden"
                sizes="(max-width: 200px) 200px, (max-width: 400px) 400px, (max-width: 800px) 800px, 4480px"
                src={image}
            />
		</div>
	);
}