import check from "@/vendors/check";
import { Navigate, useParams } from "react-router-dom";
import { CodeExplorer } from "@/components/code-explorer";

export function CodePage() {
    const { pageId } = useParams();
    // const uid = useClerkAuthedFirebase();
    // const { value: remoteMarkdown } = useLocalOrRemoteValue(`//${pageId}/editor-code`, publicPageMarkdown(pageId) /* pageMarkdown(pageId, uid) */, '', false);

    if (!check.nonEmptyString(pageId)) {
        return <Navigate to="/" />;
    }

    return (
        <CodeExplorer pageId={pageId} />
    );
}