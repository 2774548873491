import {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
  } from "@/components/shadcn/ui/pagination"
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import check from "@/vendors/check";
import { childrenParsers, ComponentAliasConfig, imports, setup } from "../_utils";
import { cn } from "@/lib/utils";
import { useDataVar } from "@/vendors/data/data";
import { useMemo } from "react";
import { getSingleVarNameFromText, mergeClasses, mergeProps } from "../../../util";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
  export function PaginationDemo() {
    return (
      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious href="#" />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#">1</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#" isActive>
              2
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#">3</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
          <PaginationItem>
            <PaginationNext href="#" />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    )
  }
*/

function paginationItemTextToComponent(item: [string, number], generatePageUrl?: (pageNumber: number) => '#', onPageChange?: (pageNumber: number) => void) : ComponentTreeNode {
  const [label, value] = item;
  
  if (label === 'x') {
    // Empty placeholder
    return {
      component: PaginationItem,
      htmlComponent: 'PaginationItem',
      classes: ['size-10'],
      props: {},
      children: [
        {
          component: 'div',
          htmlComponent: 'div',
          classes: [],
          children: [],
        }
      ],
    };
  }

  let component = PaginationLink;
  let htmlComponent = 'PaginationLink';
  if (label === '...') {
    component = PaginationEllipsis;
    htmlComponent = 'PaginationEllipsis';
  } else if (label.includes('<')) {
    component = PaginationPrevious;
    htmlComponent = 'PaginationPrevious';
  } else if (label.includes('>')) {
    component = PaginationNext;
    htmlComponent = 'PaginationNext';
  }

  const out = {
    component: PaginationItem,
    htmlComponent: 'PaginationItem',
    classes: [],
    props: {},
    children: [
      {
        component,
        htmlComponent,
        props: label !== '...' ? {
          href: generatePageUrl ? generatePageUrl(value) : '#',
          ...(onPageChange ? {
            onClick: () => onPageChange(value),
          } : {}),
          ...(label.includes('*') ? { isActive: true } : {}),
          ...(label.includes('|') ? { disabled: true } : {}),

          className: cn(label.includes('|') ? [ 'opacity-50', 'cursor-not-allowed', 'hover:bg-background' ] : []),
        }: {},
        classes: label.includes('|') ? [ 'opacity-50', 'cursor-not-allowed', 'hover:bg-background' ] : [],
        children: /[0-9]+/g.test(label) ? [label.replaceAll('*', '')] : [],
      }
    ],
  };

  return out;
}

function rawTextToPaginationItemComponent(text: string, generatePageUrl?: (pageNumber: number) => '#', onPageChange?: (pageNumber: number) => void) : ComponentTreeNode {
  // If the text contains a number, extract it and use it as the page number
  const numberMatch = text.match(/[0-9]+/g);
  if (numberMatch) {
    const pageNumber = parseInt(numberMatch[0]);
    return paginationItemTextToComponent([text, pageNumber], generatePageUrl, onPageChange);
  }

  // Else, return a pagination item with the text as the label
  // FIXME: buggy as we use 0 as the page number
  const item = paginationItemTextToComponent([text, 0], generatePageUrl, onPageChange);
  return item;
}

function getPaginationItems(currentPage: number, totalPages: number | null) : [string, number][] {
  const item = (n: number, labelOverride?: string) : [string, number] => [labelOverride || (n + 1 + ''), n];
  const items: [string, number][] = [];

  // Previous page button
  items.push(item(Math.max(0, currentPage - 1), currentPage > 0 ? '<' : '|<'));

  if (currentPage >= 2) {
    // Previous elipsis
    items.push(item(currentPage - 2, '...'));
  } else {
    // Placeholder
    items.push(item(-1, 'x')); // Adding value to avoid duplicate keys
  }
  if (currentPage >= 1) {
    // Previous page number
    items.push(item(currentPage - 1));
  } else {
    // Placeholder
    items.push(item(-2, 'x')); // Adding value to avoid duplicate keys
  }
  // Current page number
  items.push(item(currentPage, currentPage + 1 + '*'));
  
  if (totalPages === null || currentPage < totalPages - 1) {
    // Next page number
    items.push(item(currentPage + 1));
  } else {
    // Placeholder
    items.push(item(-3, 'x')); // Adding value to avoid duplicate keys
  }
  if (totalPages === null || (currentPage + 2) < totalPages - 1) {
    // Next elipsis
    items.push(item(currentPage + 2, '...'));
  } else {
    // Placeholder
    items.push(item(-4, 'x')); // Adding value to avoid duplicate keys
  }
  // Next page button
  items.push(item(Math.min(totalPages || Infinity, currentPage + 1), !totalPages || currentPage < totalPages - 1 ? '>':'>|'));

  return items;
}

function generatePaginationComponent(currentPage = 0, totalPages : number | null = null, generatePageUrl?: (pageNumber: number) => '#', onPageChange?: (pageNumber: number) => {}, classes?: string[]) : ComponentTreeNode {
  // Generate the list of page elements to display.
  const items = getPaginationItems(currentPage, totalPages);

  // Return the pagination component.
  const pagination = {
    component: Pagination,
    htmlComponent: 'Pagination',
    classes: classes || [],
    props: {},
    children: [
      {
        component: PaginationContent,
        htmlComponent: 'PaginationContent',
        classes: [],
        props: {},
        children: items.map(item => paginationItemTextToComponent(item, generatePageUrl, onPageChange)),
      },
    ],
  };

  return pagination;
}

function isPaginationChild(c: ComponentTreeNode | string) : boolean {
  if (check.nonEmptyString(c)) {
    if (['<', '>', '|<', '>|', '...'].includes(c.trim())) {
      return true;
    }
    return /^[0-9]+$/g.test(c.trim().replace('*', ''));
  }

  if (c.component === 'text') {
    return isPaginationChild(c.text || (c.children || []).filter(check.nonEmptyString).join(''));
  }

  return !!c?.component && ['item', 'link', 'next', 'previous', 'ellipsis', PaginationItem, PaginationLink, PaginationNext, PaginationPrevious, PaginationEllipsis].some(i => i === c.component);
}

function toPaginationItemComponent(c: ComponentTreeNode | string) : ComponentTreeNode | null {
  if (check.nonEmptyString(c)) {
    return rawTextToPaginationItemComponent(c.trim());
  }

  if (c.component === 'text') {
    let comp = toPaginationItemComponent(c.text || (c.children || []).filter(check.nonEmptyString).join(''));
    if (!comp) {
      return null;
    }
    return {
      ...comp,
      children: check.nonEmptyArray(comp.children) ? [
        {
          ...comp.children[0],
          classes: mergeClasses(comp.children?.[0]?.classes, c?.classes),
          props: mergeProps(comp.children?.[0]?.props, c?.props),
        }
      ] : [],
    };
  }

  if (c.component === 'item') {
    if ((!check.nonEmptyArray(c.children) && check.nonEmptyString(c.text)) || (check.nonEmptyArray(c.children) && c.children.every(check.nonEmptyString))) {
      let txt = (check.nonEmptyArray(c.children) ? c.children.join('') : c.text) || '';
      if (!isPaginationChild(txt)) {
        return null;
      }
      return rawTextToPaginationItemComponent(txt.trim());
    }
    
    return {...c, component: PaginationItem, htmlComponent: 'PaginationItem'};
  }

  if (c.component === 'link' || c.component === PaginationLink) {
    return {
      ...c,
      component: PaginationItem,
      htmlComponent: 'PaginationItem',
      children: [
        {...c, component: PaginationLink, htmlComponent: 'PaginationLink'},
      ]
    }
  }
  if (c.component === 'next' || c.component === PaginationNext) {
    return {
      ...c,
      component: PaginationItem,
      htmlComponent: 'PaginationItem',
      children: [
        {...c, component: PaginationNext, htmlComponent: 'PaginationNext'},
      ]
    }
  }
  if (c.component === 'previous' || c.component === PaginationPrevious) {
    return {
      ...c,
      component: PaginationItem,
      htmlComponent: 'PaginationItem',
      children: [
        {...c, component: PaginationPrevious, htmlComponent: 'PaginationPrevious'},
      ]
    }
  }
  if (c.component === 'ellipsis' || c.component === PaginationEllipsis) {
    return {
      ...c,
      component: PaginationItem,
      htmlComponent: 'PaginationItem',
      children: [
        {...c, component: PaginationEllipsis, htmlComponent: 'PaginationEllipsis'},
      ]
    }
  }

  return null;
}

function DynamicPaginationItem({ label, pageNumber, generatePageUrl, onPageChange, className } : { label: string, pageNumber: number, generatePageUrl?: (pageNumber: number) => '#', onPageChange?: (pageNumber: number) => void, className?: string }) {
  const componentMeta = paginationItemTextToComponent([label, pageNumber], generatePageUrl, onPageChange);

  if (!componentMeta || !check.nonEmptyArray(componentMeta.children)) {
    return null;
  }

  const WrapperComponent = componentMeta.component;
  const InnerComponent = componentMeta.children[0].component;

  return (
    <WrapperComponent className={cn(componentMeta.classes, className)} {...componentMeta.props as any}>
      <InnerComponent 
        className={cn(componentMeta.children[0].classes)}
        {...componentMeta.children[0].props as any}
      >
        {componentMeta.children[0].children || null}
      </InnerComponent>
    </WrapperComponent>
  );
}


function DynamicPagination({ value: currentPage, totalPages, generatePageUrl, onChange: onPageChange, className } : { value: number, totalPages: number | null, generatePageUrl?: (pageNumber: number) => '#', onChange?: (pageNumber: number) => void, className?: string }) {
  const items = getPaginationItems(currentPage, totalPages);
  if (!check.nonEmptyArray(items)) {
    return null;
  }

  return (
    <Pagination className={className}>
      <PaginationContent>
        {items.map(([label, pageNumber]) => (
          <DynamicPaginationItem 
            key={label + '-' + pageNumber}
            label={label}
            pageNumber={pageNumber}
            generatePageUrl={generatePageUrl}
            onPageChange={onPageChange}
          />
        ))}
      </PaginationContent>
    </Pagination>
  );
}

export function DataBoundPagination({ bind }: { bind: string }) {
  const defaultValue = useMemo(() => ({ currentPage: 0, totalPages: null, perPage: 10 }), []);
  const [currentPage, setCurrentPage] = useDataVar(bind + '.$.pagination.currentPage', defaultValue.currentPage);
  const [totalPages] = useDataVar(bind + '.$.pagination.totalPages', defaultValue.totalPages);

  return (
    <DynamicPagination 
      value={currentPage || 0}
      totalPages={totalPages || null}
      onChange={setCurrentPage}
    />
  );
}

const TAG = 'pagination';
const ALIASES: ComponentAliasConfig[] = [
  [['item', 'link', 'next', 'previous', 'ellipsis'], PaginationItem, 'PaginationItem', undefined, toPaginationItemComponent],
];

const PaginationComponentParser : ComponentParser = {
  name: 'Pagination',
  description: 'Pagination with page navigation, next and previous links.',
  tags: [TAG],

  refImplementation: `
/pagination 3 / 10
  `.trim(),

  childrenParsers: childrenParsers(ALIASES, TAG),

  parseTree: (c: ComponentTreeNode) : ComponentTreeNode => {

    // //////////////////////////////////////////////////////////////////////////////
    // Is component contains children, transform them into static pagination items.
    // //////////////////////////////////////////////////////////////////////////////
    if (check.nonEmptyArray(c.children)) {
      const onlyCompatibleChildren = c.children.filter(isPaginationChild);
      if (check.nonEmptyArray(onlyCompatibleChildren)) {
        return {
          ...c,
          component: Pagination,
          htmlComponent: 'Pagination',
          children: [
            {
              component: PaginationContent,
              htmlComponent: 'PaginationContent',
              classes: [],
              props: {},
              children: onlyCompatibleChildren.map(toPaginationItemComponent),
            }
          ],
        }
      }
    }

    // //////////////////////////////////////////////////////////////////////////////
    // Else, if the component is bound to a variable, return a dynamic component.
    // //////////////////////////////////////////////////////////////////////////////
    const variableInText = getSingleVarNameFromText(c.text);
    if (variableInText) {
      return {
        ...c,
        component: DataBoundPagination as any,
        htmlComponent: 'DataBoundPagination',
        props: {
          bind: variableInText,
        },
        children: null,
      };
    }

    // //////////////////////////////////////////////////////////////////////////////
    // Else, generate static items from the information we have
    // //////////////////////////////////////////////////////////////////////////////
    let currentPage = 0;
    let totalPages : number | null = null;
    let generatePageUrl;
    let onPageChange;

    // Attempt geeting current page from the text
    if (check.nonEmptyString(c.text)) {
      const text = c.text.trim();

      if (/^[0-9]+$/g.test(text.trim())) {
        currentPage = parseInt(text);
      } else if (/^\s*[0-9]+\s*\/\s*[0-9]+\s*$/g.test(text.trim())) {
        const [ p, t ] = text.split('/').map(i => parseInt(i.trim()));
        currentPage = p - 1;
        totalPages = t;
      }
    }

    // Override with props
    if (check.nonEmptyString(c.props?.currentPage) && /^[0-9]+$/g.test(c.props.currentPage.trim())) {
      currentPage = parseInt(c.props.currentPage);
    }
    if (check.integer(c.props?.currentPage)) {
      currentPage = c.props.currentPage;
    }
    if (check.nonEmptyString(c.props?.totalPages) && /^[0-9]+$/g.test(c.props.totalPages.trim())) {
      totalPages = parseInt(c.props.totalPages);
    }
    if (check.integer(c.props?.totalPages)) {
      totalPages = c.props.totalPages;
    }
    if (check.function(c.props?.generatePageUrl)) {
      generatePageUrl = c.props.generatePageUrl;
    }
    if (check.function(c.props?.onPageChange)) {
      onPageChange = c.props.onPageChange;
    }

    if (!generatePageUrl && check.nonEmptyString(c.props?.redirectTo)) {
      generatePageUrl = (pageNumber: number) => c.props.redirectTo.replaceAll('{page}', pageNumber + '');
    }

    // Get the items return the pagination component
    return generatePaginationComponent(currentPage, totalPages, generatePageUrl, onPageChange, c.classes || []);
  },
  
  imports: imports(['Pagination', 'PaginationContent', 'PaginationEllipsis', 'PaginationItem', 'PaginationLink', 'PaginationNext', 'PaginationPrevious'], TAG),
  setup: setup(TAG),
  variants: [],
};

registerShadcnComponent({
        Pagination,
        PaginationContent,
        PaginationEllipsis,
        PaginationItem,
        PaginationLink,
        PaginationNext,
        PaginationPrevious,
    },
    TAG,
);

export default PaginationComponentParser;