import { Button } from '@/components/shadcn/ui/button';
import { useComposableApi } from '../../../lib/composable';
import { cn } from '../../../lib/utils';
import { VariantProps, cva } from 'class-variance-authority';
import React from 'react';

const OutlineCardSizeVariants = cva(
	"justify-start items-start flex flex-col shadow-[0px_0px_4px_0px_rgba(0,0,0,0.10)] bg-card",
	{
		variants: {
			size: {
                "6xl": "w-full rounded-b-3xl",
                "5xl": "w-[1712px] rounded-b-3xl",
                "4xl": "w-[1168px] rounded-b-2xl",
                "3xl": "w-[800px] rounded-b-2xl",
                "2xl": "w-[544px] rounded-b-2xl",
                "xl": "w-[368px] rounded-b-xl",
                "lg": "w-[256px] rounded-b-xl",
                "md": "w-[176px] rounded-b-xl",
                "sm": "w-[112px] rounded-b-lg",
                "xs": "w-[80px] rounded-b-lg",
			},
            rounded: {
                'none': "rounded-none",
                "sm": "rounded-sm",
                "md": "rounded-md",
                "lg": "rounded-lg",
                "xl": "rounded-xl",
                "2xl": "rounded-2xl",
                "3xl": "rounded-3xl",
                "full": "rounded-full",
            },
		},
		defaultVariants: {
			size: "xl",
		},
	}
)

const OutlineCardPaddingVariants = cva(
	"w-full",
	{
		variants: {
			size: {
                "6xl": "p-[48px] gap-6",
                "5xl": "p-[40px] gap-5",
                "4xl": "p-[32px] gap-4",
                "3xl": "p-[24px] gap-3",
                "2xl": "p-[20px] gap-2",
                "xl": "p-[16px] gap-1",
                "lg": "p-[12px] gap-1",
                "md": "p-[8px] gap-1",
                "sm": "p-[6px]",
                "xs": "p-[4px]",
			},
		},
		defaultVariants: {
			size: "xl",
		},
	}
)

const OutlineCardGapVariants = cva(
	"justify-start items-start flex-col flex w-full",
	{
		variants: {
			size: {
                "6xl": "gap-6",
                "5xl": "gap-5",
                "4xl": "gap-4",
                "3xl": "gap-3",
                "2xl": "gap-2",
                "xl": "gap-1",
                "lg": "gap-1",
                "md": "gap-1",
                "sm": "",
                "xs": "",
			},
		},
		defaultVariants: {
			size: "xl",
		},
	}
)


const OutlineCardTitleVariants = cva(
	"font-semibold",
	{
		variants: {
			size: {
                "6xl": "w-9/12 typeface-display-5xl",
                "5xl": "w-9/12 typeface-display-4xl",
                "4xl": "w-9/12 typeface-display-3xl",
                "3xl": "w-9/12 typeface-display-2xl",
                "2xl": "w-9/12 typeface-display-xl",
                "xl": "w-9/12 typeface-display-lg",
                "lg": "w-full typeface-display-md",
                "md": "w-full typeface-display-sm",
                "sm": "w-full typeface-display-xs",
                "xs": "w-full typeface-display-xs text-[10px]",
			},
		},
		defaultVariants: {
			size: "xl",
		},
	}
)

const OutlineCardDescriptionVariants = cva(
	"font-extralight",
	{
		variants: {
			size: {
                "6xl": "w-9/12 typeface-body-4xl",
                "5xl": "w-9/12 typeface-body-3xl",
                "4xl": "w-9/12 typeface-body-2xl",
                "3xl": "w-9/12 typeface-body-xl",
                "2xl": "w-9/12 typeface-body-lg",
                "xl": "w-9/12 typeface-body-md",
                "lg": "w-full typeface-body-sm",
                "md": "w-full typeface-body-xs",
                "sm": "hidden",
                "xs": "hidden",
			},
		},
		defaultVariants: {
			size: "xl",
		},
	}
)

const OutlineCardBottomVariants = cva(
	"w-full relative",
	{
		variants: {
			size: {
                "6xl": "items-center justify-start flex",
                "5xl": "items-center justify-start flex",
                "4xl": "items-center justify-start flex",
                "3xl": "items-center justify-start flex",
                "2xl": "items-center justify-start flex",
                "xl": "items-center justify-start flex",
                "lg": "",
                "md": "",
                "sm": "",
                "xs": "",
			},
		},
		defaultVariants: {
			size: "xl",
		},
	}
)

const OutlineCardButtonDivVariants = cva(
	"",
	{
		variants: {
			size: {
                "6xl": "flex justify-end items-end w-full absolute bottom-0 right-0",
                "5xl": "flex justify-end items-end w-full absolute bottom-0 right-0",
                "4xl": "flex justify-end items-end w-full absolute bottom-0 right-0",
                "3xl": "flex justify-end items-end w-full absolute bottom-0 right-0",
                "2xl": "flex justify-end items-end w-full absolute bottom-0 right-0",
                "xl": "flex justify-end items-end w-full absolute bottom-0 right-0",
                "lg": "pt-4 w-full",
                "md": "pt-2 w-full",
                "sm": "hidden",
                "xs": "hidden",
			},
		},
		defaultVariants: {
			size: "xl",
		},
	}
)

const OutlineCardButtonVariants = cva(
	"",
	{
		variants: {
			size: {
                "6xl": "w-3/12",
                "5xl": "w-3/12",
                "4xl": "w-3/12",
                "3xl": "w-3/12",
                "2xl": "w-3/12",
                "xl": "w-3/12",
                "lg": "w-full",
                "md": "w-full",
                "sm": "hidden",
                "xs": "hidden",
			},
		},
		defaultVariants: {
			size: "xl",
		},
	}
)

const OutlineCardButtonTextVariants = cva(
	"",
	{
		variants: {
			size: {
                "6xl": "typeface-display-5xl",
                "5xl": "typeface-display-4xl",
                "4xl": "typeface-display-3xl",
                "3xl": "typeface-display-2xl",
                "2xl": "typeface-display-xl",
                "xl": "typeface-display-lg",
                "lg": "typeface-display-md",
                "md": "typeface-display-sm",
                "sm": "typeface-display-xs",
                "xs": "typeface-display-xs text-[10px]",
			},
		},
		defaultVariants: {
			size: "xl",
		},
	}
)

interface OutlineCardProps extends VariantProps<typeof OutlineCardSizeVariants> {
    className ?: string;
    children?: React.ReactNode;

    aspectRatio ?: "1.781094527" | "4/3" | "3/4" | "1/1" | "square" | "16/9" | "9/16";
}

type Size = "6xl" | "5xl" | "4xl" | "3xl" | "2xl" | "xl" | "lg" | "md" | "sm" | "xs";

const MINUS_SIZE: Record<Size, Size> = {
    "6xl": "5xl",
    "5xl": "4xl",
    "4xl": "3xl",
    "3xl": "2xl",
    "2xl": "xl",
    "xl": "lg",
    "lg": "md",
    "md": "sm",
    "sm": "xs",
    "xs": "xs"
};

function getSmallerSize(size: Size): Size {
    return MINUS_SIZE[size];
}

export function OutlineCard({ className, children, size = "xl", rounded = "xl", aspectRatio = "1.781094527" }: OutlineCardProps) {

	const { ChildWithRole, ChildrenWithRole } = useComposableApi(children);

    let button_size : "6xl" | "5xl" | "4xl" | "3xl" | "2xl" | "xl" | "lg" | "md" | "sm" | "xs" = "lg"
    if (size != null && size != undefined) {
        button_size = size;
    } 

    if (aspectRatio == "square") {
        aspectRatio = "1/1";
    }

    return (
        <div className={cn(OutlineCardSizeVariants({ size }), "rounded-t-" + rounded, className)} >
            <ChildWithRole
                role="image"
                className={"w-full aspect-[" + aspectRatio + "] object-cover rounded-" + rounded}
            />
            <div className={cn(OutlineCardPaddingVariants({ size }))} >
                <div className={cn(OutlineCardBottomVariants({ size }))} >
                    <div className={cn(OutlineCardGapVariants({ size }))}>
                        <ChildWithRole
                            role="title"
                            className={cn(OutlineCardTitleVariants({ size }))}
                        />
                        <ChildWithRole
                            role="description"
                            className={cn(OutlineCardDescriptionVariants({ size }))}
                        />
                        
                    </div>
                    <div className={cn(OutlineCardButtonDivVariants({ size }))}>
                        <ChildWithRole
                            role="button"
                            variant="default" 
                            size={getSmallerSize(getSmallerSize(button_size))}
                            className={cn(OutlineCardButtonTextVariants({ size }))}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}