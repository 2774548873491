import { publicPageMarkdown, publicPageUid } from "@/lib/dbRoutes";
import useClerkAuthedFirebase from "@/vendors/firebase/useClerkAuthedFirebase";
import useFirebaseValue from "@/vendors/firebase/useFirebase";

export function usePagePermissions(pageId?: string): { canRead?: boolean, belongsToUser?: boolean, canWrite?: boolean, loading: boolean } {
    const uid = useClerkAuthedFirebase();
    const { loading: loadingPublicMarkdown, value: publicMarkdown } = useFirebaseValue<string | null>(publicPageMarkdown(pageId || ''), null, true);
    const { loading: loadingOwner, value: owner } = useFirebaseValue<string | null>(publicPageUid(pageId || ''), null, true);

    const isLoading = !pageId || loadingPublicMarkdown || loadingOwner;
    if (isLoading) {
        return { loading: true };
    }

    const belongsToUser = owner === uid || (!isLoading && owner === null && publicMarkdown === null);

    const rights = {
        loading: false,
        owner,
        canRead: publicMarkdown !== null,
        belongsToUser,
        canWrite: belongsToUser,
    };
        
    return rights;
}