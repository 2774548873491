import { getBestMatchFromGenericName } from "./lib/components-registry";

// Register all existing components
import './component-variants/register-all';

// Will be used in app.layouts.dev to mockup the variou patterns
export default function GenericComponent({ variant, size, orientation, aspectRatio, rounded, ...props } : any ) {
    const Component = getBestMatchFromGenericName(variant, {
        size, orientation, aspectRatio, roundness: rounded
    });

    if (!Component) {
        return null;
    }

    return (
        <Component {...{
            size, 
            orientation, 
            aspectRatio, 
            rounded,
            roundness: rounded,
        }} {...props} />
    );
}