import { useState, useCallback } from 'react';

interface UseControlledValueProps {
  value?: any;
  defaultValue?: any;
  onValueChange?: (value: any) => void;
} 

function useControlledValue({ value, onValueChange, defaultValue } : UseControlledValueProps) {
  const isControlled = value !== undefined;
  const [localValue, setLocalValue] = useState(defaultValue);

  const currentValue = isControlled ? value : localValue;

  const setValue = useCallback(
    (newValue : any) => {
      if (isControlled) {
        if (onValueChange) {
          onValueChange(newValue);
        }
      } else {
        setLocalValue(newValue);
      }
    },
    [isControlled, onValueChange]
  );

  return [currentValue, setValue];
}

export default useControlledValue;