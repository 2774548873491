import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "../../../lib/utils";
import { useComposableApi } from "../../../lib/composable";

const CardVariantsW = cva("",{ 
    variants: {
        size: {
            "xs": "w-[80px]",
            "sm": "w-[112px]",
            "md": "w-[174px]",
            "lg": "w-[238px]",
            "xl": "w-[302px]",
            "2xl": "w-[366px]",
            "3xl": "w-[430px]",
            "4xl": "w-[526px]",
            "5xl": "w-[622px]",
            "6xl": "w-[718px]",
        },
        rounded: {
            'none': "rounded-none",
            "xs": "rounded-xs",
            "sm": "rounded-sm",
            "md": "rounded-md",
            "lg": "rounded-lg",
            "xl": "rounded-xl",
            "2xl": "rounded-2xl",
            "3xl": "rounded-3xl",
            "full": "rounded-full",
        },
    },
    defaultVariants: {
        size: "lg",
        rounded: 'xl',
    },
});

interface CardProps extends VariantProps<typeof CardVariantsW> {
    aspectRatio?:string,
    children?:React.ReactNode,
    className?:string,
}

function Card_P({
    size,
    rounded,
    aspectRatio="16/9",
    children,
    className,
} : CardProps ) {
    const { ChildWithRole, ChildrenWithRole } = useComposableApi(children);
    if (aspectRatio && aspectRatio == 'square') {
        aspectRatio = '1/1';
    }
    return (
        <div className={cn(CardVariantsW({size}), `h-fit flex-col justify-start items-start pb-[5px] `, className)}>
            <ChildWithRole
                role="image"
                className={cn(CardVariantsW({size, rounded}), `aspect-[${aspectRatio}] object-cover`)}
            ></ChildWithRole>
            <div className={cn(CardVariantsW({size}),"self-stretch text-neutral-900 dark:text-white typeface-display-base mt-[12px]")}>
                <ChildWithRole
                    role="title"
                ></ChildWithRole>
            </div>
            <div className="self-stretch text-slate-500 dark:text-slate-400 typeface-body-sm max-w-full min-h-fit ">
                <ChildWithRole
                    role="description"
                ></ChildWithRole>
            </div>
        </div>
    )
}

export default Card_P;