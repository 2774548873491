import { cn } from "@/lib/utils";

const grotesqueTheme = {
    name: 'merriweather',

    colors: {
        primary: '#7e22ce', // Purple
        secondary: '#B487DC', // Purplish
        accent: '#24a094', // Green
        background: '#F8F4FA', // Light gray
        destructive: '#DC2626',
    },

    fonts: [
        'Merriweather',
    ],

    cssVars: {
        '--font-display-family': `'Merriweather', serif`,
        '--font-display-style': `'normal'`,  
        '--font-body-family': `'Merriweather', serif`,
        '--font-body-style': `'normal'`,
        '--radius': '2rem',
        '--font-display-style-weight-9xl': '900',
        '--font-display-style-weight-8xl': '900',
        '--font-display-style-weight-7xl': '900',
        '--font-display-style-weight-6xl': '900',
        '--font-display-style-weight-5xl': '900',
        '--font-display-style-weight-4xl': '900',
        '--font-display-style-weight-3xl': '900',
        '--font-display-style-weight-2xl': '900',
        '--font-display-style-weight-xl': '900',
        '--font-display-style-weight-lg': '900',
        '--font-display-style-weight-md': '900',
        '--font-display-style-weight-sm': '900',
        '--font-display-style-weight-xs': '900',

    },

    components: {
        'Button': [
            cn("typeface-body-sm"),
            {
                "variants": {
                    "variant": {
                        "default": "bg-pink-400",
                        "secondary": "bg-pink-200"
                    },
                    "sizes": {
                        "lg": "h-10 px-4 py-2 text-base",
                        "default": "px-3 py-1.5 text-sm h-min",
                        "sm": "px-3 py-1.5 text-xs h-min"
                    }
                },
                "defaultVariants": {
                    "variant": "default",
                    "size": "default"
                }
            }
        ],
    },
}

export default grotesqueTheme;
