import * as buble from 'buble';

// Transforms JSX code to a tree structure
export function jsxToTree(jsxString: string) {
    const transformed = buble.transform(jsxString, {
        jsx: 'createCustomElement'
    });

    function convertComponentNames(codeString: string) {
        // This regex matches createCustomElement followed by an opening parenthesis
        // and a JavaScript identifier used as the component name, capturing the identifier.
        const regex = /createCustomElement\(\s*(\w+)\s*[,)]{1}/g;
    
        // Replace the captured component name with its string literal version
        return codeString.replace(regex, (match, componentName) => {
            return `createCustomElement("${componentName}",`;
        });
    }
    

    // Function to simulate React.createElement for parsing purposes
    function createCustomElement(component: string, props: { [key: string]: any }, ...children: any[]) {
        return { component, props, children };
    }

    // Use a custom evaluator to process the generated code safely
    // eslint-disable-next-line no-new-func
    const functionCode = new Function('createCustomElement', `return (${convertComponentNames(transformed.code)});`);
    const elementTree = functionCode(createCustomElement);

    return elementTree;
}


// Test
/*
const code = `
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Parent />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle />
          <AlertDialogDescription />
        </AlertDialogHeader>
        <Slot name="rest" />
        <AlertDialogFooter>
          <AlertDialogCancel __allowMultiple />
          <AlertDialogAction __allowMultiple />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
`;

console.log(JSON.stringify(jsxToTree(code), null, 4));
*/