import * as React from "react"
import { Button } from "@/components/shadcn/ui/button";
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/utils";

const CardVariantsBox = cva("",{ 
    variants: {
        size: {
            "xs": "w-[390px] min-w-[390px] max-w-[390px]",
            "sm": "w-[432px] min-w-[432px] max-w-[432px]",
            "md": "w-[532px] min-w-[532px] max-w-[532px]",
            "lg": "w-[620px] min-w-[620px] max-w-[620px]",
            "xl": "w-[770px] min-w-[770px] max-w-[770px]",
            "2xl": "w-[960px] min-w-[960px] max-w-[960px]",
            "3xl": "w-[960px] min-w-[960px] max-w-[960px]",
            "4xl": "w-[960px] min-w-[960px] max-w-[960px]",
            "5xl": "w-[960px] min-w-[960px] max-w-[960px]",
            "6xl": "w-[960px] min-w-[960px] max-w-[960px]",
        },
        orientation: {
            "east": "flex-row",
            "west": "flex-row-reverse"
        }
    },
    defaultVariants: {
        size: "lg",
        orientation: 'east',
    },
});

const CardVariantsImg = cva("",{ 
    variants: {
        size: {
            "xs": "",
            "sm": "",
            "md": "",
            "lg": "",
            "xl": "",
            "2xl": "",
            "3xl": "",
            "4xl": "",
            "5xl": "",
            "6xl": "",
        },
        roundness: {
            'none': "rounded-none",
            "sm": "rounded-sm",
            "md": "rounded-md",
            "lg": "rounded-lg",
            "xl": "rounded-xl",
        },
        orientation: {
            'east': "mr-[16px]",
            'west': "ml-[16px]"
        },
        aspectRatio: {
            '9/16': "",
            '16/9': "",
            'square': "",
            '4/3': "",
            '3/4': "",
        }
    },
    defaultVariants: {
        size: "lg",
        roundness: 'xl',
        orientation: 'east'
    },
});

const CardVariantsInnerBox = cva("",{ 
    variants: {
        size: {
            "xs": "min-h-[80]px",
            "sm": "min-h-[93px]",
            "md": "min-h-[114px]",
            "lg": "min-h-[152px]",
            "xl": "min-h-[174px]",
            "2xl": "min-h-[181px]",
            "3xl": "min-h-[220px]",
            "4xl": "min-h-[240px]",
            "5xl": "min-h-[260px]",
            "6xl": "min-h-[280px]",
        },
        roundness: {
            'none': "rounded-none",
            "sm": "rounded-sm",
            "md": "rounded-md",
            "lg": "rounded-lg",
            "xl": "rounded-xl",
        },
        aspectRatio: {
            '9/16': "",
            '16/9': "",
            'square': "",
            '4/3': "",
            '3/4': "",
        },
        orientation: {
            "east": "",
            "west": ""
        }
    },
    defaultVariants: {
        size: "lg",
        roundness: 'xl',
        orientation: 'east'
    },
});

const toRatio = (r: string | null) => {
    console.log("ratio = " + r);
    if (r == null) {return "16/9"}
    else if (r == "square") {return "1/1"}
    else if (r == "video") {return "16/9"}
    return r;
}

const toWidth = (h: number, r: string): number => {
    const [widthRatio, heightRatio] = r.split('/').map(Number);
    if (isNaN(widthRatio) || isNaN(heightRatio) || heightRatio === 0) {
      throw new Error('Invalid ratio');
    }
    const w = (h * widthRatio) / heightRatio;
    console.log("width calc : " + w);
    return w;
};

const imgFixedSize = {
    "xs": 80,
    "sm": 93,
    "md": 114,
    "lg": 152,
    "xl": 174,
    "2xl": 181,
    "3xl": 220,
    "4xl": 240,
    "5xl": 260,
    "6xl": 280,
}

interface CardProps extends VariantProps<typeof CardVariantsImg> {
    image : string,
    title: string,
    description: string,
    buttonLabel: string,
    desc2?: string,
    behavior?: string,
}

function ListItemImageCard({
    image,
    title,
    description,
    size,
    roundness,
    buttonLabel,
    desc2,
    orientation,
    behavior = "fixed",
    aspectRatio = '16/9',
} : CardProps ) {
    return (
        <div className={cn(CardVariantsBox({size, orientation}), `h-fit max-h-fit flex pt-[16px] pb-[16px] bg-white dark:bg-neutral-800`)}>
            <div className={cn(CardVariantsInnerBox({size, orientation}),`h-fit m-0 pl-[16px] mr-[16px] flex-1 rounded-none flex flex-col justify-between overflow-x-auto`)}>
                <div className={"min-h-fit w-full flex-1 overflow-x-hidden break-words"}>
                    {desc2 && desc2.length > 0 ? <div className="font-body-normal text-slate-500 dark:text-slate-400">{desc2}</div> : <></>}
                    <div className="font-display-base text-neutral-900 dark:text-white font-bold">{title}</div>
                    <div className="font-body-normal text-slate-500 dark:text-slate-400 overflow-hidden break-words">{description}</div>
                </div>
                {buttonLabel && buttonLabel.length > 0 ? <Button className="h-8 w-[84px] mt-[16px] border-none">{buttonLabel}</Button> : <></>}
            </div>
            <img className={cn(CardVariantsImg({roundness, orientation}), `${size && behavior == "fixed" ? `h-[${imgFixedSize[size]}px]` : ""} ${size ? `w-[${toWidth(imgFixedSize[size], toRatio(aspectRatio))}px]` : ""} aspect-video object-cover`)} src={image}/>
        </div>
    )
}

export default ListItemImageCard;