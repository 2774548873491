import BgImageCard_1 from './BgImageCard_1';
import BgImageCard_2 from './BgImageCard_2';
import BgImageCard_3 from './BgImageCard_3';
import BgImageCard_4 from './BgImageCard_4';
import BgImageCard_5 from './BgImageCard_5';
import BgImageCard_6 from './BgImageCard_6';
import BgImageCard_7 from './BgImageCard_7';
import BgImageCard_8 from './BgImageCard_8';
import BgImageCard_9 from './BgImageCard_9';

import BgImageCard from './BgImageCard';

import { registerComponentVariant } from '../../../lib/components-registry';
import { CARD_BGIMAGE_VARIANT_DESCRIPTION, CARD_BGIMAGE_VARIANT_NAME, CARD_COMPONENT_DESCRIPTION, CARD_COMPONENT_NAME } from '../../../lib/constants';
import { ComponentType } from 'react';

const BgImageCard_default_2xl = BgImageCard;
const BgImageCard_default_xl = BgImageCard;
const BgImageCard_default_lg = BgImageCard;
const BgImageCard_default_md = BgImageCard;
const BgImageCard_default_sm = BgImageCard;
const BgImageCard_default_xs = BgImageCard;

const BgImageCard_centred_2xl = BgImageCard_2;
const BgImageCard_centred_xl = BgImageCard_5;

const BgImageCard_multiple_buttons_xl = BgImageCard_3;

/*
export const BgImageCard = [
    {
      name: 'Default',
      sizes: [
        { name: '2xl', reactComponent: BgImageCard_default_2xl },
        { name: 'xl', reactComponent: BgImageCard_default_xl },
        { name: 'lg', reactComponent: BgImageCard_default_lg },
        { name: 'md', reactComponent: BgImageCard_default_md },
        { name: 'sm', reactComponent: BgImageCard_default_sm },
        { name: 'xs', reactComponent: BgImageCard_default_xs },
      ]
    },
    {
      name: 'Centred',
      sizes: [
        { name: '2xl', reactComponent: BgImageCard_centred_2xl },
        { name: 'xl', reactComponent: BgImageCard_centred_xl },
      ]
    },
    {
      name: 'MultipleButtons',
      sizes: [
        { name: '2xl', reactComponent: BgImageCard_multiple_buttons_xl },
      ]
    }
  ]
*/

function DefaultBgCard({ size, ...props }: any) {
    let ReactComponent = BgImageCard_default_md;
    if (size === '2xl') {
        ReactComponent = BgImageCard_default_2xl;
    } else if (size === 'xl') {
        ReactComponent = BgImageCard_default_xl;
    } else if (size === 'lg') {
        ReactComponent = BgImageCard_default_lg;
    } else if (size === 'md') {
        ReactComponent = BgImageCard_default_md;
    } else if (size === 'sm') {
        ReactComponent = BgImageCard_default_sm;
    } else if (size === 'xs') {
        ReactComponent = BgImageCard_default_xs;
    }

    return <ReactComponent size={size} {...props} label={props.buttonLabel || null} />;
}

function CentredBgCard({ size, ...props }: any) {
    let ReactComponent: ComponentType = BgImageCard_centred_xl as ComponentType;
    if (size === '2xl') {
        ReactComponent = BgImageCard_centred_2xl as ComponentType;
    } else if (size === 'xl') {
        ReactComponent = BgImageCard_centred_xl as ComponentType;
    }

    return <ReactComponent {...props} label={props.buttonLabel || null} />;
}

function MultipleButtonsBgCard({ size, ...props }: any) {
    let ReactComponent = BgImageCard_multiple_buttons_xl;
    if (size === '2xl') {
        ReactComponent = BgImageCard_multiple_buttons_xl;
    }

    return <ReactComponent {...props} label={props.buttonLabel || null} />;
}


registerComponentVariant({
    name: CARD_COMPONENT_NAME,
    description: CARD_COMPONENT_DESCRIPTION,
    variantName: CARD_BGIMAGE_VARIANT_NAME,
    variantDescription: CARD_BGIMAGE_VARIANT_DESCRIPTION,
    subVariantName: 'Default',
    subVariantDescription: 'Background image card',
    reactComponent: DefaultBgCard,
    acceptsProps: ['image', 'title', 'description', 'buttonLabel'],
    acceptSizes: ['2xl', 'xl', 'lg', 'md', 'sm', 'xs'],
    acceptsRoundness: ['full', '3xl', '2xl', 'xl', 'lg', 'md', 'sm'],
    acceptOrientations: ['north'],
    acceptsChildren: false,
});

registerComponentVariant({
    name: CARD_COMPONENT_NAME,
    description: CARD_COMPONENT_DESCRIPTION,
    variantName: CARD_BGIMAGE_VARIANT_NAME,
    variantDescription: CARD_BGIMAGE_VARIANT_DESCRIPTION,
    subVariantName: 'Centered',
    subVariantDescription: 'Background image card with centered content',
    reactComponent: CentredBgCard,
    acceptsProps: ['image', 'title', 'description', 'buttonLabel'],
    acceptSizes: ['2xl', 'xl'],
    acceptsRoundness: ['full', '3xl', '2xl', 'xl', 'lg', 'md', 'sm'],
    acceptOrientations: ['north'],
    acceptsChildren: false,
});

registerComponentVariant({
    name: CARD_COMPONENT_NAME,
    description: CARD_COMPONENT_DESCRIPTION,
    variantName: CARD_BGIMAGE_VARIANT_NAME,
    variantDescription: CARD_BGIMAGE_VARIANT_DESCRIPTION,
    subVariantName: 'MultipleButtons',
    subVariantDescription: 'Background image card with multiple buttons',
    reactComponent: MultipleButtonsBgCard,
    acceptsProps: ['image', 'title', 'description', 'buttonLabel'],
    acceptSizes: ['2xl'],
    acceptsRoundness: ['full', '3xl', '2xl', 'xl', 'lg', 'md', 'sm'],
    acceptOrientations: ['north'],
    acceptsChildren: false,
});