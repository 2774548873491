import * as React from "react"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { DayPicker } from "react-day-picker"

import { cn } from "@/lib/utils"
import { buttonVariants } from "@/components/shadcn/ui/button"

export interface CalendarProps extends Omit<React.ComponentProps<typeof DayPicker>, 'selected' | 'onSelect' | 'defaultMonth' | 'mode'> {
  onChange?: (value: string | undefined) => void;
  value?: string;
  defaultValue?: string;
  defaultMonth?: string;
}

// Transform Date object to YYYY-MM-DD string while respecting the local timezone
function dateToDay(date: Date) {
    if (!(date instanceof Date)) {
        return undefined;
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

// Transform YYYY-MM-DD to Date object while respecting the local timezone
function dayToDate(day?: string) {
    if (typeof day !== 'string' || !/^\d{4}-\d{2}-\d{2}$/g.test(day)) {
        return undefined;
    }
    const [year, month, date] = day.split('-').map(Number);
    return (new Date(year, month - 1, date));
}

function monthToDate(month?: string) {
    if (typeof month !== 'string' || !month.match(/^\d{4}-\d{2}$/)) {
        return undefined;
    }
    const [year, m] = month.split('-').map(Number);
    return (new Date(year, m - 1));
}

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  value,
  defaultValue,
  defaultMonth,
  onChange,
  ...props
}: CalendarProps) {

  // Transform YYYY-MM-DD string to Date object
  const dateValue = dayToDate(value);
  const dateDefaultValue = dayToDate(defaultValue);
  const selected = dateValue || dateDefaultValue;
  const onSelect = React.useCallback((val: any) => {
    const date : Date | undefined = (val instanceof Date ? val : val?.from) || undefined;
    const day = date ? dateToDay(date) : undefined;
    console.log('Calendar changed to: ', day);
    onChange?.(day);
  }, [onChange]);

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell:
          "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50                                    first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20", 
        //    "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
        ),
        day_range_end: "day-range-end",
        day_selected:
          "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        day_today: "bg-accent text-accent-foreground",
        day_outside:
          "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
        IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
      }}
      {...props}
      selected={selected}
      onSelect={onSelect}
      mode="single"
      defaultMonth={monthToDate(defaultMonth)}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }
