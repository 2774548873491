import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormLabel,
  FormMessage,
} from "../layouts-ui/form";
import upperlower from "upperlower";
import uniqid from "uniqid";

import { ComponentParser, ComponentTreeNode } from "../component.type";
import { addStagesIf, groupAdjacentComponentsOfTypes, markTreeLeafs, parseTextComponents, removeFirstOccurenceOfTextFromChildren, solveAliases, transformComponentsOfTypes, treeParsingPipeline, wrapComponentsNotOfTypes } from "../parsing-pipeline";
import { mergeClasses, mergeProps, removeEmptyProps } from "../../util";
import { nonEmptyTrimmed } from "@/lib/utils";
import check from "@/vendors/check";
import { parseInputOrCommandText } from "../layouts-lang";
import { Input } from "@/components/shadcn/ui/input";
import { Separator } from "@/components/shadcn/ui/separator";
import { RadioGroup, RadioGroupItem } from "@/components/shadcn/ui/radio-group";
import { Checkbox } from "@/components/shadcn/ui/checkbox";
import { Label } from "@/components/shadcn/ui/label";
import { Switch } from "@/components/shadcn/ui/switch";
import { ComponentAliasConfig, childrenParsers, imports, isTextComponent, normalizeTextComponent, setup } from "./_utils";
import { ComponentType } from "react";
import { Textarea } from "@/components/shadcn/ui/textarea";
import { registerShadcnComponent } from "../../importsRegistry";

/*
const formSchema = z.object({
  username: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
})
 
export function ProfileForm() {
  // ...
 
  return (
    <Form>
        <FormField
          name="username"
        >
            <FormLabel>Username</FormLabel>
            <FormControl>
                <Input placeholder="shadcn" {...field} />
            </FormControl>
            <FormDescription>
                This is your public display name.
            </FormDescription>
            <FormMessage />
        </FormField>
        <Button type="submit">Submit</Button>
    </Form>
  )
}
*/ 

// Virtual components for parsing
const RadioInput = ({ children }: any) => children;
RadioInput.displayName = 'RadioInput';
const CheckboxInput = ({ children }: any) => children;
CheckboxInput.displayName = 'CheckboxInput';
const SwitchInput = ({ children }: any) => children;
SwitchInput.displayName = 'SwitchInput';

function isFormInputComponent(c: ComponentTreeNode) {
  return [Input, RadioInput, CheckboxInput, SwitchInput].some(t => c.component === t);
}

const PRIMARY_INPUT_TYPES = [
  Input, Textarea, RadioGroup, RadioGroupItem, Checkbox, Switch
]

// Text parsing function to parse inputs
function parseTextChild(c: ComponentTreeNode) : ComponentTreeNode | ComponentTreeNode[] | null {
  const text = c.text;
  
  // Return nothing if there is no text
  if (!check.nonEmptyString(text) || !nonEmptyTrimmed(text)) {
    return null;
  }

  // Parse text
  const { type, label, description, checked, ...otherProps } = parseInputOrCommandText(text, { 
    title: true, 
    input: true, 
    description: true,
    switch: true,
    checkbox: true,
    radio: true,
    separator: true,
    textarea: true,
  });

  // Output the right component, or just the text 
  let out = [];
  if (type === 'separator') {
    out.push({
      ...c,
      component: Separator,
      htmlComponent: 'Separator',
      text: undefined,
      children: [],
    });
  }

  if (type === 'input') {
    out.push({
      ...c,
      component: Input,
      htmlComponent: 'Input',
      props: mergeProps(c.props, otherProps),
      text: undefined,
      children: [],
    });
  }

  if (type === 'textarea') {
    out.push({
      ...c,
      component: Textarea,
      htmlComponent: 'Textarea',
      props: mergeProps(c.props, otherProps),
      text: undefined,
      children: [],
    });
  }

  if (type === 'title') {
    out.push({
      ...c,
      component: FormLabel,
      htmlComponent: 'FormLabel',
      text: undefined,
      children: [label],
    });
  }

  if (['radio', 'checkbox', 'switch'].includes(type)) {
    /*
    <div className="flex items-center space-x-2">
      <RadioGroupItem value="comfortable" id="r2" />
      <Label htmlFor="r2">Comfortable</Label>
    </div>
    */

    let additionalProps : { [key:string]: any } = {};
    if (check.nonEmptyString(label) && type === 'radio') {
      additionalProps = { value: label };
    }

    const component = type === 'radio' ? RadioInput : type === 'checkbox' ? CheckboxInput : SwitchInput;
    const htmlComponent = type === 'radio' ? 'RadioInput' : type === 'checkbox' ? 'CheckboxInput' : 'SwitchInput';
    const innerComponent = type === 'radio' ? RadioGroupItem : type === 'checkbox' ? Checkbox : Switch;
    const innerHtmlComponent = type === 'radio' ? 'RadioGroupItem' : type === 'checkbox' ? 'Checkbox' : 'Switch';
    const id = c.props?.id || c.props?.htmlFor || c.props?.name || c.key || (check.nonEmptyString(label) ? upperlower(label, 'kebapcase') : uniqid());
    out.push({
      component,
      htmlComponent,
      props: {},
      classes: [],
      children: [
        {
          ...c,
          component: 'div',
          htmlComponent: 'div',
          props: { className: mergeClasses('flex items-center space-x-2', c.props?.className).join(' ') },
          classes: ["flex items-center space-x-2"],
          text: undefined,
          children: [
            {
              component: innerComponent,
              htmlComponent: innerHtmlComponent,
              props: removeEmptyProps(
                mergeProps(c.props, { ...otherProps, ...additionalProps, /* defaultChecked: checked , */ id })
              ),
              classes: [],
              children: [],
            },
            {
              component: Label,
              htmlComponent: 'Label',
              props: { htmlFor: id },
              classes: [],
              children: [label],
            },
          ],
        }
      ],
    });
  }

  if (check.nonEmptyString(description)) {
    out.push({
      ...c,
      component: FormDescription,
      htmlComponent: 'FormDescription',
      text: undefined,
      children: [description],
    });
  }

  return check.nonEmptyArray(out) ? out : c;
}


function groupComponentsIntoFormFields(c: ComponentTreeNode[]) : ComponentTreeNode[] {
  const out : ComponentTreeNode[] = [];
  let currentItemChildren : ComponentTreeNode[] = [];

  const pushCurrentItem = () => {
    if(!check.nonEmptyArray(currentItemChildren)) {
      return;
    }

    // Only create Field if there is a form control in the group
    if (!currentItemChildren.some(t => t.component === FormControl)) {
      out.push(...currentItemChildren);
      currentItemChildren = [];
      return;
    }

    out.push({
      component: FormField as any,
      htmlComponent: 'FormField',
      classes: [],
      props: {},
      children: currentItemChildren,
    });
    currentItemChildren = [];
  };

  c.forEach((child: ComponentTreeNode) => {
    if (child.component === FormLabel) {
      // We create a new form item when we see a new label
      pushCurrentItem();
      currentItemChildren.push(child);
    }

    // For other types of components, we allow one per formItem group
    else if ([FormControl, FormDescription, FormMessage].some(t => child.component === t)) {
      if (currentItemChildren.some(c => c.component === child.component)) {
        pushCurrentItem();
      }

      currentItemChildren.push(child);
    } else {
      pushCurrentItem();
      out.push(child);
    }
  });

  if (currentItemChildren.length > 0) {
    pushCurrentItem();
  }

  return out;
}

function getInputComponentName(c: ComponentTreeNode) : string | null {
  let name: string | null = null;
  const inputComponentTypes = PRIMARY_INPUT_TYPES;

  if (inputComponentTypes.some(t => c.component === t)) {
    return c.props?.id || c.props?.htmlFor || c.props?.name || c.key || null;
  }

  if (check.nonEmptyArray(c.children)) {
    for (let i = 0; i < c.children.length; i++) {
      name = getInputComponentName(c.children[i]);
      if (name) {
        return name;
      }
    }
  }

  return null;
}

function getRadioComponentValue(c: ComponentTreeNode) : any | null {
  if (c.component === (RadioGroupItem as any)) {
    return c.props.value || (c.children || []).filter(isTextComponent).map(normalizeTextComponent).filter(t => !!t).map(t => t!.text).join(' ') || null;
  }

  if (check.nonEmptyArray(c.children)) {
    for (let i = 0; i < c.children.length; i++) {
      const value = getRadioComponentValue(c.children[i]);
      if (value) {
        return value;
      }
    }
  }

  return null;
}

function getRadioComponentName(c: ComponentTreeNode) : string | null {
  if (c.component === (RadioGroupItem as any)) {
    return c.props.name || null;
  }

  if (check.nonEmptyArray(c.children)) {
    for (let i = 0; i < c.children.length; i++) {
      const name = getRadioComponentName(c.children[i]);
      if (name) {
        return name;
      }
    }
  }

  return null;
}

function getInputComponentType(c: ComponentTreeNode) : string | null {
  let type: string | null = null;
  const inputComponentTypes = PRIMARY_INPUT_TYPES;

  if (inputComponentTypes.some(t => c.component === t)) {
    if (c.component === Input) {
      return c.props.type || 'text';
    }
    if (c.component === Textarea) {
      return c.props.type || 'textarea';
    }
    if (c.component === (RadioGroupItem as any)) {
      return 'radio';
    }
    if (c.component === Checkbox) {
      return 'checkbox';
    }
    if (c.component === Switch) {
      return 'switch';
    }
  }

  if (check.nonEmptyArray(c.children)) {
    for (let i = 0; i < c.children.length; i++) {
      type = getInputComponentType(c.children[i]);
      if (type) {
        return type;
      }
    }
  }

  return null;
}

function addFieldPropsToInputComponents(c: ComponentTreeNode, field: { [key:string]: any }, parents: (string | ComponentType)[], condition: (c: ComponentTreeNode, parents: (string | ComponentType)[]) => boolean ) : ComponentTreeNode {
  const inputComponentTypes = PRIMARY_INPUT_TYPES;

  if (inputComponentTypes.some(t => c.component === t)) {
    if (!condition(c, parents)) {
      return c;
    }

    return {
      ...c,
      props: mergeProps(c.props, field),
    };
  }

  if (check.nonEmptyArray(c.children)) {
    return {
      ...c,
      children: c.children.map(child => addFieldPropsToInputComponents(child, field, [...parents, c.component], condition)),
    };
  }

  return c;
}

function addFieldPropsToLabelComponents(c: ComponentTreeNode, field: { [key:string]: any }, parents: (string | ComponentType)[], condition: (c: ComponentTreeNode, parents: (string | ComponentType)[]) => boolean) : ComponentTreeNode {
  const inputComponentTypes = [Label, FormLabel];

  if (inputComponentTypes.some(t => c.component === t)) {
    if (!condition(c, parents)) {
      return c;
    }

    return {
      ...c,
      props: mergeProps(c.props, field),
    };
  }

  if (check.nonEmptyArray(c.children)) {
    return {
      ...c,
      children: c.children.map(child => addFieldPropsToLabelComponents(child, field, [...parents, c.component], condition)),
    };
  }

  return c;
}

const parseFieldNode = (c: ComponentTreeNode, parents: ComponentType[]) : ComponentTreeNode | null => {
  return treeParsingPipeline([
    // Remove text from children if any
    addStagesIf(
      (parent: ComponentTreeNode) => (parent.component === Form),
      [removeFirstOccurenceOfTextFromChildren(c.text)],
    ),

    // Solve alias components
    solveAliases(ALIASES),

    // Parse text components
    parseTextComponents(parseTextChild),
  ]).run(c, [...parents, FormField as any]);
};

const formParseTree = (c: ComponentTreeNode) : ComponentTreeNode => {
  const rootComponent = {
    component: Form,
    htmlComponent: 'Form',
    classes: [],
    props: {},
    children: c.children || [],
  };

  let nInputsPerType = {} as { [key:string]: number };

  const parsed = treeParsingPipeline([
    // Remove text from children if any
    addStagesIf(
      (parent: ComponentTreeNode) => (parent.component === Form),
      [removeFirstOccurenceOfTextFromChildren(c.text)],
    ),

    // Solve alias components
    solveAliases(ALIASES),

    // Parse form field nodes and their children as won't won't be able to do it after  making them leafs
    transformComponentsOfTypes([FormField as any], parseFieldNode),

    // Parse text components
    parseTextComponents(parseTextChild),

    // Wrap all radio inputs into radio groups
    groupAdjacentComponentsOfTypes([RadioInput, RadioGroupItem as any], RadioGroup as any, 'RadioGroup'),

    // Parse all radio group items to make ids straight and unwrap RadioInput components
    transformComponentsOfTypes([RadioGroup], (c: ComponentTreeNode) => {
      let name = getRadioComponentName(c);
      let defaultValue = getRadioComponentValue(c);
      if (!name) {
        name = 'radio';
        if (nInputsPerType[name] > 0) {
          name = `${name}-${nInputsPerType[name]}`;
          nInputsPerType[name]++;
        } else {
          nInputsPerType[name] = 1;
        }
      }

      let n = 0;
      return {
        ...c,
        props: {
          ...c.props,
          name,
          defaultValue,
        },
        
        children: (c.children || []).map(child => {
          if (!check.nonEmptyObject(child) || child.component !== RadioInput) {
            return child;
          }

          if (!check.nonEmptyArray(child.children)) {
            return null;
          }

          let itemName = name + '_' + n;
          const itemValue = getRadioComponentValue(child.children[0]);
          n++;

          const out =  addFieldPropsToLabelComponents(
            addFieldPropsToInputComponents(
              child.children[0], 
              { id: itemName, /* value: itemValue */ },
              [c.component],
              (c: ComponentTreeNode, parents: (string | ComponentType)[]) => true,
            ),
            { htmlFor: itemName },
            [c.component],
            (c: ComponentTreeNode, parents: (string | ComponentType)[]) => true,
          );

          return out;
        }),
        
      };
    }),

    // Wrap all inputs into form controls
    transformComponentsOfTypes([Input, Textarea, RadioGroup, CheckboxInput, SwitchInput], (c: ComponentTreeNode) => ({
      component: FormControl,
      htmlComponent: 'FormControl',
      classes: [],
      props: {},
      children: [
        // Unwrap the CheckboxInput, SwitchInput components
        [CheckboxInput, SwitchInput].some(t => c.component === t) ? c.children?.[0] : c,
      ],
    })),

    // Group all form controls, labels, descriptions into form items
    groupComponentsIntoFormFields,

    // Make sure all form fields have a proper name, and all labels inside have the same name
    transformComponentsOfTypes([FormField as any], (c: ComponentTreeNode, parents: ComponentType[]) => {
      if (parents.some(p => p === FormField)) {
        return c;
      }

      let name = getInputComponentName(c); 
      if(!name) {
        const type = getInputComponentType(c) || 'input';
        nInputsPerType[type] = nInputsPerType[type] || 0;
        if (nInputsPerType[type] === 0) {
          name = type;
          nInputsPerType[type]++;
        } else {
          name = `${type}-${nInputsPerType[type]++}`;
        }
      }
     
      return {
        ...c,
        props: {
          ...c.props,
          name: c.props?.name || name,
        },
        children: (c.children || []).map(child => addFieldPropsToLabelComponents(
          addFieldPropsToInputComponents(
            child, 
            { id:name, name },
            [c.component],
            (c: ComponentTreeNode, parents: (string | ComponentType)[]) => !parents.some(p => p === RadioGroup),
          ),
          { htmlFor: name },
          [c.component],
          (c: ComponentTreeNode, parents: (string | ComponentType)[]) => !parents.some(p => p === RadioGroup),
        )),
      };
    }),

    // Mark all form input, label or descriptoin components as leafs
    markTreeLeafs([FormControl, FormField as any]),

  ]).run(rootComponent, []);

  return parsed;
};

const TAG = 'form';
const TAGS = [TAG];
  const ALIASES : ComponentAliasConfig[] = [
    ['control', FormControl, 'FormControl'],
    ['description', FormDescription, 'FormDescription'],
    ['field', FormField as any, 'FormField'],
    ['item', FormField as any, 'FormField'],
    ['label', FormLabel, 'FormLabel'],
    ['message', FormMessage, 'FormMessage'],
    ['radio-group', RadioGroup, 'RadioGroup'],
  ];

  const FormComponentParser : ComponentParser = {
    name: 'Form',
    description: 'A Form component.',
    tags: TAGS,

    refImplementation: `
          /form
            # Email
            [Your email]
            -- This will help us stay in touch. We don't spam!
        
            # Message
            [> Enter any message here...]
            -- Come on! You can write anything!
        
            <x> Send me important notifications
            <> Send me marketing emails
        
            # What do you prefer?
            [] Bacon
            [] Miso soup
            [] Pizza

            /button Submit @type=submit
    `.trim(),

    childrenParsers: childrenParsers(ALIASES, TAGS),
  
    parseTree: formParseTree,
  
    imports: imports([ 'Form', 'FormControl', 'FormDescription', 'FormField', 'FormLabel', 'FormMessage' ], TAG),
    setup: setup(TAG),
    variants: [],
  };

registerShadcnComponent({
        Form,
        FormControl,
        FormDescription,
        FormField,
        FormLabel,
        FormMessage,
    },
    TAG,
);
  
export default FormComponentParser;