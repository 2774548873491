import { useEffect, useMemo } from 'react';
import { getDatabase, ref, onValue, off, update, DatabaseReference, DataSnapshot } from 'firebase/database';
import { firebase } from '@/initializers/firebase';
import check from '@/vendors/check';
import useClerkAuthedFirebase from './useClerkAuthedFirebase';

const useFirebaseListAsQueue = (path: string | null, handler: (val: any) => void, allowAnonymous = false) => {
  const uid = useClerkAuthedFirebase();

  const dbRef = useMemo(() => {
    if (!uid && !allowAnonymous) {
      return null;
    }

    const database = getDatabase(firebase);
    return (check.nonEmptyString(path) ? ref(database, path) as DatabaseReference : null);
  }, [path, uid, allowAnonymous]);

  useEffect(() => {
    if (!dbRef) {
      return;
    }

    const onValueChange = (snapshot: DataSnapshot) => {
        const snapshotValue = snapshot.val();

        // Exit if the snapshotValue is not a non-empty array or object
        if (!check.nonEmptyArray(snapshotValue) && !check.nonEmptyObject(snapshotValue)) {
          return;
        }

        // Filter and process only valid and relevant items
        const updates: { [key: string]: any }  = {};
        Object.entries(snapshotValue).forEach(([key, item]) => {
          if (check.nonEmptyObject(item) && ((new Date(item.time)).getTime() + (1 * 60 * 1000)) > Date.now()) {
            try {
              handler(item); // Process the item
              // Mark for deletion if processed successfully
              updates[key] = null;
            } catch (e) {
              console.error(e); // Handle any errors during processing
            }
          } else if ((new Date(item.time)).getTime() + (1 * 60 * 1000) <= Date.now()) {
            // Item is too old and should be removed
            updates[key] = null;
          }
        });

        // Perform a batch update to delete only the old or processed items
        if (Object.keys(updates).length > 0) {
          update(dbRef, updates).catch(console.error);
        }
    };

    onValue(dbRef, onValueChange);
    return () => off(dbRef, 'value', onValueChange);
  }, [dbRef, handler]);
};

export default useFirebaseListAsQueue;
