import { ComponentType } from "react";
import arrayUnique from "array-unique";
import check from "check-types";

export type StandardProp = 
    'title'
    | 'description'
    | 'paragraph'
    | 'icon'
    | 'image'
    | 'buttonLabel'
    | 'iconButton'
    | 'link'
    | 'tagLine';

export type ComponentSize = 
    'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | 'full'

export type ComponentOrientation = 
    'north'
    | 'northEast'
    | 'east'
    | 'southEast'
    | 'south'
    | 'southWest'
    | 'west'
    | 'northWest';

export type ComponentAspectRatio = 
    'square'
    | '4/3'
    | '3/4'
    | '16/9'
    | '9/16';

export type ComponentRoundness = 
    'none'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | 'full';

export type ComponentRecord = {
    reactComponent: ComponentType,
    name: string,
    description: string,
    variantName: string,
    variantDescription: string,
    subVariantName: string,
    subVariantDescription: string,
    size: ComponentSize,
    orientation: ComponentOrientation,
    roundness?: ComponentRoundness,
    aspectRatio?: ComponentAspectRatio,
    acceptsProps: StandardProp[],
    acceptsChildren: boolean,
}

export type ComponentRegistryQuery = {
    name?: string,
    variantName?: string,
    subVariantName?: string,
    size?: ComponentSize,
    orientation?: ComponentOrientation,
    aspectRatio?: ComponentAspectRatio,
    roundness?: ComponentRoundness,
    props?: StandardProp[],
};

const components : ComponentRecord[] = [];

// Used to register a component variant
export function registerComponentVariant({
    reactComponent,
    name,
    description,
    variantName,
    variantDescription,
    subVariantName,
    subVariantDescription,
    acceptsProps,
    acceptsChildren,
    acceptSizes,
    acceptOrientations,
    acceptsRoundness,
    acceptsAspectRatios,
} : {
    reactComponent: ComponentType,
    name: string,
    description: string, 
    variantName: string,
    variantDescription: string,
    subVariantName?: string,
    subVariantDescription?: string,
    acceptsProps: StandardProp[], 
    acceptsChildren: boolean, 
    acceptSizes: ComponentSize[], 
    acceptOrientations: ComponentOrientation[],
    acceptsRoundness?: ComponentRoundness[],
    acceptsAspectRatios?: ComponentAspectRatio[],
}) {
    for (const size of acceptSizes) {
        for (const orientation of acceptOrientations) {
            for (const roundness of acceptsRoundness || [undefined]) {
                for (const aspectRatio of acceptsAspectRatios || [undefined]) {
                    components.push({
                        reactComponent,
                        name,
                        description,
                        variantName,
                        variantDescription,
                        size,
                        orientation,
                        roundness,
                        aspectRatio,
                        acceptsProps,
                        acceptsChildren,
                        subVariantName: subVariantName || 'default',
                        subVariantDescription: subVariantDescription || 'default',
                    });
                }
            }
        }
    }
}

export function sortSizes(sizes: ComponentSize[]) {
    const order = ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl'];
    return sizes.sort((a, b) => order.indexOf(a) - order.indexOf(b));
}

export function sortRoundnesses(roundnesses: ComponentRoundness[]) {
    const order = ['none', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', 'full'];
    return roundnesses.sort((a, b) => order.indexOf(a) - order.indexOf(b));
}

function findComponentRecords({
    name,
    variantName,
    subVariantName,
    size,
    orientation,
    aspectRatio,
    roundness,
    props
} : ComponentRegistryQuery) {
    let records = [...components];
    if (name) records = records.filter((c) => c.name.toLowerCase() === name.toLowerCase());
    if (variantName) records = records.filter((c) => c.variantName.toLowerCase() === variantName.toLowerCase());
    if (subVariantName) records = records.filter((c) => c.subVariantName.toLowerCase() === subVariantName.toLowerCase());
    if (size) records = records.filter((c) => c.size === size);
    if (orientation) records = records.filter((c) => c.orientation === orientation);
    if (aspectRatio) records = records.filter((c) => c.aspectRatio === aspectRatio);
    if (roundness) records = records.filter((c) => c.roundness === roundness);
    if (props) records = records.filter((c) => props.every((p) => c.acceptsProps.map(ap => ap.toLowerCase()).includes(p.toLowerCase())));
    
    return records;
}

function availableValuesForDimension(query: ComponentRegistryQuery, dimension: 'name' | 'variantName' | 'subVariantName' | 'size' | 'orientation' | 'aspectRatio' | 'roundness') {
    const records = findComponentRecords(query);
    return arrayUnique(records.map((c) => c[dimension])).filter(v => !!v);
}

export function getReactComponent(query: ComponentRegistryQuery) {
    const records = findComponentRecords(query);
    if (records.length === 0) return null;
    return records[0].reactComponent;
}

export function getBestMatchFromGenericName(genericName: string, query: { size?: ComponentSize, orientation?: ComponentOrientation, aspectRatio?: ComponentAspectRatio, roundness?: ComponentRoundness}) {
    if (!check.nonEmptyString(genericName)) {
        return null;
    }
    const parts = genericName.split('-');
    if (parts.length === 0) {
        return null;
    }

    const name = parts[0];
    let variantName = undefined;
    let subVariantName = undefined;

    if (parts.length > 1) {
        variantName = parts[1];
    }
    if (parts.length > 2) {
        subVariantName = parts[2];
    }

    const matches = findComponentRecords({name, variantName, subVariantName});
    if (matches.length === 0) {
        return null;
    }

    function getMatchScore(record: ComponentRecord) {
        let score = 0;
        if (query.size && record.size === query.size) score += 20;
        if (query.orientation && record.orientation === query.orientation) score += 5;
        if (query.aspectRatio && record.aspectRatio === query.aspectRatio) score++;
        if (query.roundness && record.roundness === query.roundness) score += 10;
        return score;
    }

    const sorted = matches.sort((a, b) => getMatchScore(b) - getMatchScore(a));
    return sorted[0].reactComponent;
}

export function getAvailableComponentNames() {
    return availableValuesForDimension({}, 'name');
}

export function getAvailableComponentVariantNames(query: ComponentRegistryQuery) : string[] {
    return availableValuesForDimension(query, 'variantName') as string[];
}

export function getAvailableComponentSubVariantNames(query: ComponentRegistryQuery) : string[] {
    return availableValuesForDimension(query, 'subVariantName') as string[];
}

export function getAvailableComponentSizes(query: ComponentRegistryQuery): ComponentSize[] {
    return sortSizes(availableValuesForDimension(query, 'size') as ComponentSize[]);
}

export function getAvailableComponentOrientations(query: ComponentRegistryQuery): ComponentOrientation[] {
    return availableValuesForDimension(query, 'orientation') as ComponentOrientation[];
}

export function getAvailableComponentAspectRatios(query: ComponentRegistryQuery): ComponentAspectRatio[] {
    return availableValuesForDimension(query, 'aspectRatio') as ComponentAspectRatio[];
}

export function getAvailableComponentRoundnesses(query: ComponentRegistryQuery) : ComponentRoundness[] {
    return sortRoundnesses(availableValuesForDimension(query, 'roundness') as ComponentRoundness[]);
}

export function getAllGenericNames() {
    return arrayUnique(components.map((c) => {
        let name = c.name;
        if (c.variantName) {
            name += `-${c.variantName}`;
        }
        if (c.subVariantName) {
            name += `-${c.subVariantName}`;
        }
        return name;
    })).sort();
}