import check from "check-types";
import { useMemo } from "react";
import useMongoLiveQuery from "./useMongoLiveQuery";
import { nonEmptyTrimmed } from "@/lib/utils";

// Wrapper around useMongoLiveQuery to simplify external API
export function useMongoLiveCollection(source: string, {
    filterKey, 
    filter,
    page = 0,
    perPage = 25,
} : { filterKey?: string, filter?: any, page?: number, perPage?: number } = {}) {
    const dbName = check.nonEmptyString(source) ? source.split('.')[0] : '';
    const collectionName = check.nonEmptyString(source) ? source.split('.').slice(1).join('.') : '';

    const shouldFilter = check.nonEmptyString(filterKey) && check.nonEmptyString(filter) && nonEmptyTrimmed(filterKey) && check.nonEmptyString(filter.trim());
    const pagination = useMemo(() => ({
        $skip: page * perPage,
        $limit: perPage,
    }), [page, perPage]);

    const query = useMemo(() => (shouldFilter 
        ? { 
            [filterKey]: {
                $regex: filter,
                $options: 'i', // 'i' makes it case-insensitive
            }, 
            ...pagination,
        } : { 
            ...pagination 
        }
    ), [shouldFilter, filterKey, filter, pagination]);

    const { data, loading, error, writeHandlers } = useMongoLiveQuery(dbName, collectionName, query);

    return {
        data: data,
        loading,
        error,
        page, 
        perPage,
        writeHandlers,
    };
}