import { ListItem } from "../ListItem";
import { BgImageCard } from "./BgImageCard";
import { ImgCard } from "./ImgCard";
import { OutlineCard } from "./OutlineCard";


export type CardVariant = 'bg-image' | 'outline' | 'list-item' | 'default';

interface CardProps {
    variant: CardVariant;

    [propName: string]: any;
}

export function Card({ variant, ...props } : CardProps) {
    switch (variant) {
      case 'bg-image':
        return <BgImageCard {...props} />;
      case 'outline':
        return <OutlineCard {...props} />;
      case 'list-item':
        return <ListItem {...props} />;
      case 'default':
      default:
        return <ImgCard {...props}/>
    }
};
  