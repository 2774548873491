
import { registerComponentVariant } from '../../../lib/components-registry';
import { CARD_BUTTON_VARIANT_DESCRIPTION, CARD_BUTTON_VARIANT_NAME, CARD_COMPONENT_DESCRIPTION, CARD_COMPONENT_NAME, CARD_LANDSCAPE_VARIANT_NAME, CARD_LANSCAPE_VARIANT_DESCRIPTION, CARD_PORTRAIT_VARIANT_DESCRIPTION, CARD_PORTRAIT_VARIANT_NAME, CARD_SQUARE_VARIANT_DESCRIPTION, CARD_SQUARE_VARIANT_NAME, CARD_VARIANT_DESCRIPTION, CARD_VARIANT_NAME, DEFAULT_VARIANT_DESCRIPTION, DEFAULT_VARIANT_NAME } from '../../../lib/constants';
import Card_L from './Card_img_l';
import Card_S from './Card_img_s';
import Card_B from './Card_button';
import Card_P from './Card_img_p';

registerComponentVariant({
    reactComponent: Card_B as React.ComponentType,
    name: CARD_COMPONENT_NAME,
    description: CARD_COMPONENT_DESCRIPTION,
    variantName: CARD_VARIANT_NAME,
    variantDescription: CARD_VARIANT_DESCRIPTION,
    subVariantName: CARD_BUTTON_VARIANT_NAME,
    subVariantDescription: CARD_BUTTON_VARIANT_DESCRIPTION,
    acceptsProps: ['image', 'title', 'description'],
    acceptsChildren: false,
    acceptSizes: ['lg'],
    acceptOrientations: ['east'],
    acceptsRoundness: ['none','sm', 'md', 'lg', 'xl'],
});

registerComponentVariant({
    reactComponent: Card_L as React.ComponentType,
    name: CARD_COMPONENT_NAME,
    description: CARD_COMPONENT_DESCRIPTION,
    variantName: CARD_VARIANT_NAME,
    variantDescription: CARD_VARIANT_DESCRIPTION,
    subVariantName: CARD_LANDSCAPE_VARIANT_NAME,
    subVariantDescription: CARD_LANSCAPE_VARIANT_DESCRIPTION,
    acceptsProps: ['image', 'title', 'description'],
    acceptsChildren: false,
    acceptSizes: ['2xl', 'xl', 'lg', 'md', 'sm', 'xs'],
    acceptOrientations: ['north'],
    acceptsRoundness: ['none','sm', 'md', 'lg', 'xl'],
    acceptsAspectRatios: ['16/9']
});

registerComponentVariant({
    reactComponent: Card_S as React.ComponentType,
    name: CARD_COMPONENT_NAME,
    description: CARD_COMPONENT_DESCRIPTION,
    variantName: CARD_VARIANT_NAME,
    variantDescription: CARD_VARIANT_DESCRIPTION,
    subVariantName: CARD_SQUARE_VARIANT_NAME,
    subVariantDescription: CARD_SQUARE_VARIANT_DESCRIPTION,
    acceptsProps: ['image', 'title', 'description'],
    acceptsChildren: false,
    acceptSizes: ['2xl', 'xl', 'lg', 'md', 'sm', 'xs'],
    acceptOrientations: ['north'],
    acceptsRoundness: ['none','sm', 'md', 'lg', 'xl', 'full'],
    acceptsAspectRatios: ['square']
});

registerComponentVariant({
    reactComponent: Card_P as React.ComponentType,
    name: CARD_COMPONENT_NAME,
    description: CARD_COMPONENT_DESCRIPTION,
    variantName: CARD_VARIANT_NAME,
    variantDescription: CARD_VARIANT_DESCRIPTION,
    subVariantName: CARD_PORTRAIT_VARIANT_NAME,
    subVariantDescription: CARD_PORTRAIT_VARIANT_DESCRIPTION,
    acceptsProps: ['image', 'title', 'description'],
    acceptsChildren: false,
    acceptSizes: ['2xl', 'xl', 'lg', 'md', 'sm', 'xs'],
    acceptOrientations: ['north'],
    acceptsRoundness: ['none','sm', 'md', 'lg', 'xl', 'full'],
    acceptsAspectRatios: ['9/16']
});

registerComponentVariant({
    reactComponent: Card_L as React.ComponentType,
    name: CARD_COMPONENT_NAME,
    description: CARD_COMPONENT_DESCRIPTION,
    variantName: CARD_VARIANT_NAME,
    variantDescription: CARD_VARIANT_DESCRIPTION,
    subVariantName: DEFAULT_VARIANT_NAME,
    subVariantDescription: DEFAULT_VARIANT_DESCRIPTION,
    acceptsProps: ['image', 'title', 'description'],
    acceptsChildren: false,
    acceptSizes: ['2xl', 'xl', 'lg', 'md', 'sm', 'xs'],
    acceptOrientations: ['north'],
    acceptsRoundness: ['none','sm', 'md', 'lg', 'xl'],
    acceptsAspectRatios: ['16/9']
});