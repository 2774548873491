import React, { Fragment, useCallback, useMemo } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { List, useDataVar, DataProvider as DataProviderPrimitive, StaticList } from "./data";
import check from '../check';
import { extractVarNamesFromText } from '@/lib/parser/util';
import arrayUnique from 'array-unique';
import { useCascadingDataVars } from './data-engine';
import { getNormalizer, normalizeValue, ValueType } from '@/lib/type-casts';

// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
// New API for the data components
// FIXME: Work in progress
// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////


// FIXME: This is a hack to get around the type error
const FlexibleSlot = Slot as any; 

// ---------------------------------------------------------------------------------------
// Data provider
// ---------------------------------------------------------------------------------------
// eg: <DataProvider data={{ myData: 123 }} >
//          <DataDisplay bind="myData" />
//     </DataProvider>
export const DataProvider = DataProviderPrimitive;

// ---------------------------------------------------------------------------------------
// Used to retrieve a data variable value and setter (read/write)
// ---------------------------------------------------------------------------------------
// eg: const [myData, setMyData] = useDataValue('myData');
// ---------------------------------------------------------------------------------------
export function useDataValue(bind: string) {
    return useDataVar(bind, null);
}

// ---------------------------------------------------------------------------------------
// Used to bind an input component to a data variable (read/write)
// ---------------------------------------------------------------------------------------
// eg: <DataInput bind="myData" ><Input placeholder="Enter your email" /></DataInput>
// ---------------------------------------------------------------------------------------
export function DataInput({ bind, onChange: onChangeProp, type, children, ...props } : { 
    bind: string, 
    type?: ValueType,
    onChange?: (value: any) => void,
    children: any,
}) {
    const [rawValue, setValue] = useDataValue(bind);

    // Handle data normalization
    const normalize = useMemo(() => getNormalizer(type), [type]);
    const value = normalize(rawValue);
    const changeHandler = useCallback((v: any) => {
        const normalized = normalize(v);

        setValue(normalized);
        if (check.function(onChangeProp)) {
            onChangeProp(normalized);
        }
    }, [setValue, onChangeProp, normalize]);
    
    return <FlexibleSlot
        value={value} 
        onChange={changeHandler}
        children={children}
        {...props}
    />
}

// ---------------------------------------------------------------------------------------
// Used to display a data value (read)
// ---------------------------------------------------------------------------------------
// eg: <DataDisplay bind="myData" />
// eg: <DataDisplay bind="myData" toProp="date" ><Calendar /></DataDisplay>
// ---------------------------------------------------------------------------------------
export function DataDisplay({ bind, toProp = 'value', type, ...otherProps} : { bind: string, type?: ValueType, [key:string]: any }) {
    const [rawValue] = useDataValue(bind);

    // Normalize the value
    const value = normalizeValue(type, rawValue);
    console.log('DataDisplay:', {
        rawValue,
        value,
        type,
    })

    // DataDisplay can be used to set a prop on a child component
    if (React.Children.count(otherProps.children) > 0) {
        let overrideProps : { [key:string]: any } = {};
        if (check.nonEmptyString(bind)) {
            if (check.function(toProp)) {
                overrideProps = toProp(value);
            } else {
                overrideProps[toProp] = value;
            }
        }

        return React.Children.map(otherProps.children, (child) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { ...overrideProps });
            } else {
                return child;
            }
        });
    }

    // Main use should be to display the value as text
    if (value === undefined || value === null) {
        return null;
    }
    return check.string(value) ? value : JSON.stringify(value);
}

// ---------------------------------------------------------------------------------------
// Used to resolve the data props of its direct children
// ---------------------------------------------------------------------------------------
// eg: <DataPropsResolver><Calendar date="$myVar" /></DataPropsResolver>
// ---------------------------------------------------------------------------------------
export function DataPropsResolver({ children } : { children: any }) {
    // Extract all vars from the direct children props
    console.log('DataPropsResolver children:', React.Children
    .toArray(children));

    const keys = arrayUnique(React.Children
        .toArray(children)
        .filter(child => React.isValidElement(child) && check.nonEmptyObject(child?.props))
        .map((child: any) => Object.values(child.props))
        .flat()
        .filter(check.nonEmptyString)
        .map(propValue => extractVarNamesFromText(propValue))
        .flat());

    console.log('DataPropsResolver keys:', keys);

    // Get the values for these variables
    const [valuesPerKey] = useCascadingDataVars(keys);

    // Resolve the props and return 
    return React.Children.map(children, (child) => {
        if (!React.isValidElement(child) || !check.nonEmptyObject(child.props)) {
            return child;
        }

        const newProps = Object.keys(child.props).reduce((acc, key) => {
            const value = (child.props as any)[key];
            if (!check.nonEmptyString(value)) {
                return { ...acc, [key]: value };
            }

            const vars = extractVarNamesFromText(value);
            let newValue = value;

            if (vars.length === 1 && vars[0] === value.trim()) {
                if (typeof (valuesPerKey as any)[value] !== 'undefined') {
                    newValue = (valuesPerKey as any)[value];
                }
            } else if (vars.length > 0) {
                vars.forEach((varName: string) => {
                    const varVal = (valuesPerKey as any)[varName];

                    if (check.string(varVal)) {
                        newValue = newValue.replace(varName, varVal);
                    }
                });
            }
            return { ...acc, [key]: newValue };
        }, {});


        console.log('DataPropsResolver new Props:', {newProps,valuesPerKey });
        return React.cloneElement(child, newProps);
    });
}

// ---------------------------------------------------------------------------------------
// Used to display lists of items
// ---------------------------------------------------------------------------------------
// eg:  <Foreach bind="myData" >
//          <Text>
//              <DataDisplay bind="$item" />
//          </Text>
//      </Foreach>
// eg:  <Foreach bind="[1...10]" >
//          <Text>
//              <DataDisplay bind="$item" />
//          </Text>
//      </Foreach>
// ---------------------------------------------------------------------------------------
export function ForEach({ bind, filter, children, ...otherProps} : { bind: string, filter?: string, children: any, [key:string]: any }) {
    if (check.nonEmptyArray(bind)) {
        console.log('Rendering ForEach as static list. Bind = ', bind)

        return <StaticList  
            as={Fragment}
            bind={bind} 
            filter={filter} 
            children={children}
            {...otherProps}
        />;
    }

    if (check.nonEmptyString(bind)) {
        console.log('Rendering ForEach as dynamic list. Bind = ', bind)
        return <List  
            as={Fragment}
            bind={bind} 
            filter={filter} 
            children={children}
            {...otherProps}
        />
    }
    return null;
}

export function extractVarsFromProps(props: { [key: string]: any }) {
    if (!check.nonEmptyObject(props)) {
        return [];
    }

    return Object.keys(props).reduce((acc, key) => {
        const value = props[key];
        if (check.nonEmptyString(value) && key !== 'bind' && key !== 'key' && key !== 'filter') {
            return [...acc, ...extractVarNamesFromText(value)];
        }
        return acc;
    }, [] as string[]);
}