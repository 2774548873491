import * as React from "react"
import * as ProgressPrimitive from "@radix-ui/react-progress"

import { cn } from "@/lib/utils"

const ProgressIndicator  = React.forwardRef<
React.ElementRef<typeof ProgressPrimitive.Root>,
React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Indicator> & { value?: number }
>(({ className, style, value, ...props }, ref) => (
  <ProgressPrimitive.Indicator
    ref={ref}
    className={cn(
      "h-full w-full flex-1 bg-primary transition-all",
      className
    )}
    style={Object.assign({ transform: `translateX(-${100 - (value || 0)}%)` }, style || {})}
    {...props}
  />
));
ProgressIndicator.displayName = ProgressPrimitive.Indicator.displayName

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, value, children, ...props }, ref) => {
    // Make component composable by accepting optional ProgressIndicator child
    let indicator = <ProgressIndicator value={value || undefined} />;
    const childrenArray = React.Children.toArray(children);
    if (childrenArray && childrenArray.length > 0) {
      const indicatorChild = childrenArray.find(
        (child) => React.isValidElement(child) && child.type === ProgressIndicator
      );
      if (indicatorChild) {
        indicator = React.cloneElement(indicatorChild as any, { ...((indicatorChild as any).props), value: value || undefined });
      }
    }

    return (
      <ProgressPrimitive.Root
        ref={ref}
        className={cn(
          "relative h-4 w-full overflow-hidden rounded-full bg-secondary",
          className
        )}
        {...props}
      >
        {indicator}
      </ProgressPrimitive.Root>
    );
});
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress, ProgressIndicator }
