import { Input } from "@/components/shadcn/ui/input"
import { getSingleVarNameFromText, removeEmptyProps } from "../../../util";
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { imports, importsRaw, setup } from "../_utils";
import { DataInput } from "@/vendors/data/data-v2";
import { ComponentType } from "react";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { cn } from "@/lib/utils";

export function InputDemo() {
  return <Input type="email" placeholder="Email" />
}


const InputComponentParser : ComponentParser = {
  name: 'Input',
  description: 'A form input field',
  tags: ['input'],
  refImplementation: `/input @type=email Enter your email`,
  parseTree: (c) => {
      let { text } = c;
      const props = { ...c.props };
      const textVar = getSingleVarNameFromText(text);
      let wrap = (w: ComponentTreeNode) => w;
      if (textVar) {
        // Remove var from text so we can use the rest as placeholder
        text = text?.replaceAll(/\$\w+/g, '')?.trim();

        wrap = (w: ComponentTreeNode) => ({
            component: DataInput as ComponentType,
            htmlComponent: 'DataInput',
            props: {
                bind: textVar,
            },
            classes: [],
            variant: null,
            children: [
                w
            ]
        });
      }

      props.placeholder = props.placeholder || text || null;
      props.className = cn(c.classes, c.props?.className);
      return wrap({ 
          ...c, 
          component: Input, 
          htmlComponent: 'Input', 
          props: removeEmptyProps(props), 
          text: null,
          children: null 
      });
  },

  variants: [],
  imports: [
      ...imports(['Input'], 'input'),
      ...importsRaw(['DataInput'], '@layouts.dev/utils'),
  ],
  setup: [
     ...setup('input'),
     'npm install --save @layouts.dev/utils',
  ],
};

registerShadcnComponent({
        Input
    },
    'input',
);


export default InputComponentParser;