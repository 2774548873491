import socketio from '@feathersjs/socketio-client';
import feathers from '@feathersjs/client';
import io from 'socket.io-client';

const feathersApp = feathers()
  .configure(socketio(
    io('https://lightspeeddb-feathers-47e469219c52.herokuapp.com', {
      // transports: ['websocket'], // ['polling'],
      // upgrade: false
    })
));

export default feathersApp;