import { ComponentParser, ComponentTreeNode } from "../component.type";
import check from "@/vendors/check";

// This component is a placeholder for the page.
// It will be reinterpreted by the parser
export function Page() {
  return null;
}

const PageComponentParser : ComponentParser = {
    name: 'Page',
    description: 'Creates a page for your app',
    tags: ['page'],
  
    refImplementation: `
    /button %link @to="about" Open about page
    /page /about About page
        /div grid gap-4
            /h1 font-bold text-3xl 
              About
            /p text-lg text-muted-foreground
              Welcome to the about page.
            /button %outline @to="/" w-fit
              /icon chevron-left
              Go back 
    `.trim().replaceAll(/ {2}/g, '\t'),
  
  
    // This function is called on nodes including children with the tag 'popover'
    // It wraps the parent and places all popover children to the right place
    parseTree: (c: ComponentTreeNode) : ComponentTreeNode => {
      const [path, ...rest] = (c.text || '').trim().split(' ');
      let title = rest.join(' ').trim();

      const props : {[key:string]: string} = {};
      if (check.nonEmptyString(title)) {
        props.title = title;
      }
      if (check.nonEmptyString(path)) {
        props.path = path;
      }

      return {
        ...c,
        component: Page,
        htmlComponent: 'Page',
        props: { ...(c.props || {}), ...props },
      };
    },
  
    imports: [],
    setup: [],
    variants: [],
  };
  
  export { PageComponentParser };
  