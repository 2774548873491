// Font pairs
import { Theme } from "@/components/layouts-ui/Theme";
import { ComponentTreeNode } from "../component.type";
import check from "@/vendors/check";

// Source: https://www.fontpair.co/all
const FONT_PAIRS : { display: string, body: string }[] = [
    { "display": "DM Serif Display", "body": "DM Sans" },
    { "display": "Ultra", "body": "PT Serif" },
    { "display": "IBM Plex Sans Condensed", "body": "IBM Plex Sans" },
    { "display": "Work Sans", "body": "Merriweather" },
    { "display": "Oswald", "body": "Source Sans Pro" },
    { "display": "Barlow Condensed", "body": "Montserrat" },
    { "display": "Alegreya", "body": "Alegreya" },
    { "display": "Karla", "body": "Merriweather" },
    { "display": "Roboto Condensed", "body": "Roboto" },
    { "display": "Nunito Sans", "body": "Nunito Sans" },
    { "display": "Libre Baskerville", "body": "Libre Baskerville" },
    { "display": "Oswald", "body": "Source Serif Pro" },
    { "display": "Nunito", "body": "PT Sans" },
    { "display": "Montserrat", "body": "Hind" },
    { "display": "Stint Ultra Expanded", "body": "Pontano Sans" },
    { "display": "Karla", "body": "Inconsolata" },
    { "display": "Playfair Display", "body": "Lato" },
    { "display": "Libre Baskerville", "body": "Source Sans Pro" },
    { "display": "Cormorant Garamond", "body": "Proza Libre" },
    { "display": "Quattrocento", "body": "Lora" },
    { "display": "Quattrocento", "body": "Quattrocento Sans" },
    { "display": "Playfair Display", "body": "Source Sans Pro" },
    { "display": "Yeseva One", "body": "Josefin Sans" },
    { "display": "Quicksand", "body": "Quicksand" },
    { "display": "Abril Fatface", "body": "Poppins" },
    { "display": "Fjalla One", "body": "Cantarell" },
    { "display": "Syne", "body": "Inter" },
    { "display": "Source Sans Pro", "body": "Source Serif Pro" },
    { "display": "Arvo", "body": "Lato" },
    { "display": "Roboto", "body": "Nunito" },
    { "display": "Cinzel", "body": "Fauna One" },
    { "display": "Playfair Display", "body": "Alice" },
    { "display": "Cormorant", "body": "Open Sans" },
    { "display": "Oswald", "body": "Lora" },
    { "display": "Yellowtail", "body": "Lato" },
    { "display": "Archivo", "body": "Tenor Sans" },
    { "display": "Open Sans", "body": "Average Sans" },
    { "display": "Rufina", "body": "Average Sans" },
    { "display": "Rufina", "body": "Oxygen" },
    { "display": "Rubik", "body": "Roboto Mono" },
    { "display": "Poiret One", "body": "Montserrat" },
    { "display": "Oswald", "body": "Quicksand" },
    { "display": "Merriweather", "body": "Merriweather" },
    { "display": "Montserrat", "body": "Open Sans" },
    { "display": "Roboto Slab", "body": "Roboto" },
    { "display": "Anton", "body": "Roboto" },
    { "display": "Montserrat", "body": "Montserrat" },
    { "display": "Sintony", "body": "Poppins" },
    { "display": "Fjalla One", "body": "Inter" },
    { "display": "Teko", "body": "Ubuntu" },
    { "display": "Josefin Slab", "body": "Josefin Sans" },
    { "display": "Outfit", "body": "Outfit" },
    { "display": "Philosopher", "body": "Mulish" },
    { "display": "Cormorant", "body": "Proza Libre" },
    { "display": "Mulish", "body": "Space Mono" },
    { "display": "Alfa Slab One", "body": "Gentium Book Basic" },
    { "display": "Quattrocento", "body": "Fanwood Text" },
    { "display": "Playfair Display", "body": "Quattrocento" },
    { "display": "Old Standard TT", "body": "Mulish" },
    { "display": "Alegreya", "body": "Source Sans Pro" },
    { "display": "Archivo Black", "body": "Archivo" },
    { "display": "Cardo", "body": "Hind" },
    { "display": "Josefin Sans", "body": "Open Sans" },
    { "display": "Josefin Sans", "body": "Inter" },
    { "display": "Lora", "body": "Merriweather" },
    { "display": "Cabin", "body": "Cabin" },
    { "display": "Kreon", "body": "Ubuntu" },
    { "display": "Nunito", "body": "Nunito Sans" },
    { "display": "Merriweather", "body": "Source Sans Pro" },
    { "display": "Lora", "body": "DM Sans" },
    { "display": "Archivo Black", "body": "Inter" },
    { "display": "Bitter", "body": "Raleway" },
    { "display": "Montserrat", "body": "Source Sans Pro" },
    { "display": "Libre Baskerville", "body": "Raleway" },
    { "display": "Bubblegum Sans", "body": "Open Sans" },
    { "display": "Poiret One", "body": "Lato" },
    { "display": "Roboto", "body": "Roboto" },
    { "display": "Roboto Mono", "body": "Roboto" },
    { "display": "EB Garamond", "body": "Montserrat" },
    { "display": "Oswald", "body": "Roboto" },
    { "display": "Inter", "body": "Inter" },
    { "display": "Forum", "body": "Work Sans" },
    { "display": "Tenor Sans", "body": "Archivo Narrow" },
    { "display": "Playfair Display", "body": "Raleway" },
    { "display": "Poppins", "body": "Lora" },
    { "display": "Montserrat", "body": "Lato" },
    { "display": "Six Caps", "body": "Inter" },
    { "display": "Orelega One", "body": "Montserrat" },
    { "display": "Della Respira", "body": "Open Sans" },
    { "display": "Rozha One", "body": "Questrial" },
    { "display": "Noto Serif", "body": "Noto Sans" },
    { "display": "Playfair Display", "body": "DM Sans" },
    { "display": "DM Serif Display", "body": "Red Hat Display" },
    { "display": "Oswald", "body": "Merriweather" },
    { "display": "Epilogue", "body": "Epilogue" },
    { "display": "Expletus Sans", "body": "Hind" }
]

const FONT_PAIR_VARIANT_TO_PROPS = FONT_PAIRS.reduce((acc, { display, body }) => {
    acc[`${display.toLowerCase().replaceAll(' ', '-')}_${body.toLowerCase().replaceAll(' ', '-')}`] = {
        displayFont: display,
        bodyFont: body,
    };
    return acc;
}, {} as Record<string, { displayFont: string, bodyFont: string }>);

const fontPair = {
    tags: ['font-pair'],
    name: 'Font-pair',
    description: 'Add a pre-selected font pair to the theme. Should be use as childen of the theme component.',
    refImplementation: `/font-pair %amulya-bold_synonym-regular`,
    variants: FONT_PAIRS.map(({ display, body }) => `${display.toLowerCase().replaceAll(' ', '-')}_${body.toLowerCase().replaceAll(' ', '-')}`),
    wrapParent: (parent: ComponentTreeNode) => {
        const component = (parent.children || []).find(c => c.component === 'font-pair')
        if (!component || !check.nonEmptyString(component.variant)) {
            return {
                ...parent,
                children: (parent.children || []).filter(c => c.component !== 'font-pair'),
            };
        }

        if (parent.component === Theme || parent.component === 'theme') {
            return {
                ...parent,
                props: {
                    ...parent.props,
                    ...(FONT_PAIR_VARIANT_TO_PROPS[component.variant] || {})
                },
                children: (parent.children || []).filter(c => c.component !== 'font-pair'),
            }
        }

        return {
            ...parent,
            children: (parent.children || []).map(child => {
                if (child.component === 'font-pair') {
                    return {
                        component: Theme,
                        htmlComponent: 'Theme',
                        props: {
                            ...(FONT_PAIR_VARIANT_TO_PROPS[child.variant] || {}),
                        },
                        children: [],
                        variant: null,
                        classes: [],
                    }
                }

                return child;
            })
        }
    },
};

export { fontPair, FONT_PAIR_VARIANT_TO_PROPS };