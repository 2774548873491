import { useEffect, useRef, useState } from 'react';
import { getAuth, signInWithCustomToken, onAuthStateChanged } from 'firebase/auth';
import { useAuth } from '@clerk/clerk-react';
import { firebase } from '@/initializers/firebase';

// Initialize Firebase App
const auth = getAuth(firebase);

// Custom hook
// TODO: Get uid from context to avoid fetching tokens multiple times
const useClerkAuthedFirebase = (): string | null => {
  const [userId, setUserId] = useState<string | null>(null);
  const userIdRef = useRef<string | null>(null);
  const { getToken } = useAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (userIdRef.current === user.uid){
            return; // Skip if the UID is the same
        }

        userIdRef.current = user.uid; // Update the UID ref
        setUserId(user.uid); // Set the Firebase user's UID
      } else {
        try {
          // Get the custom token from Clerk
          const token = await getToken({ template: "integration_firebase" });
          if (token) {
            // Sign in to Firebase with the custom token
            const userCredentials = await signInWithCustomToken(auth, token);

            if (userIdRef.current === userCredentials?.user?.uid ) {
                return; // Skip if the UID is the same
            }
            userIdRef.current = userCredentials.user.uid; // Update the UID ref
            setUserId(userCredentials.user.uid); // Set UID after successful authentication
          } else {
            console.error("Failed to retrieve token from Clerk.");
            userIdRef.current = null;
            setUserId(null);
          }
        } catch (error) {
          console.error("Error signing in with custom token:", error);
          userIdRef.current = null;
          setUserId(null);
        }
      }
    });

    return () => unsubscribe(); // Cleanup subscription
  }, [getToken]);

  return userId;
};

export default useClerkAuthedFirebase;
