import check from "@/vendors/check";
import { useCallback, useEffect, useRef, useState } from "react";

export function useDebouncedParsedValue(value: any, parser: ((v: any) => Promise<any>), timeBetweenLoops = 0) : any {
    const queue = useRef([] as any[]);
    const queued = useRef(false);
    const processing = useRef(false);
    const [parsedValue, setParsedValue] = useState<any>(null);

    const processQueue = useCallback(async () => {
        if (processing.current) {
            return;
        }

        if (!check.nonEmptyArray(queue.current)) {
            return;
        }

        // Set queue to processing
        processing.current = true;
        queued.current = true;

        // Get the last element of the queue. Skip all others
        const nextValue = queue.current.pop();
        queue.current = [];

        // Parse the value
        try {
            const parsed = await parser(nextValue);
            setParsedValue(parsed);
        } catch(err) {
            console.error(err);
            setParsedValue(null);
        }

        // Set queue to not processing
        processing.current = false;

        // If the queue is not empty, initiate a new processing loop 
        // after allowing a brief pause for the event loop to process other pending tasks
        if (check.nonEmptyArray(queue.current)) {
            queued.current = true;
            setTimeout(() => {
                queued.current = false;
                processQueue();
            }, timeBetweenLoops);
        } else {
            queued.current = false;
        }
    }, [parser, timeBetweenLoops]);

    
    useEffect(() => {
        if (!value && value !== '') {
            return;
        }
        if (value === queue.current[queue.current.length - 1]) {
            return;
        }

        // Add value to the queue 
        queue.current.push(value);

        // Ask to process the queue if no timeout has been set
        if (!queued.current) {
            processQueue();
        }
    }, [value, processQueue]);

    // Return the parsed value
    return { value: parsedValue };
}