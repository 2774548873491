import * as React from "react"
import { Button } from "@/components/shadcn/ui/button";
import { registerComponentVariant } from "../../../lib/components-registry";
import { VariantProps, cva } from "class-variance-authority";
import { cn } from "@/lib/utils";

const CardVariants = cva("",{ 
    variants: {
        size: {
            "lg": "",
        },
        roundness: {
            'none': "rounded-none",
            "sm": "rounded-sm",
            "md": "rounded-md",
            "lg": "rounded-lg",
            "xl": "rounded-xl",
        }
    },
    defaultVariants: {
        size: "lg",
        roundness: 'xl',
    },
});

interface CardProps extends VariantProps<typeof CardVariants> {
    image : string,
    title: string,
    description: string,
    buttonLabel: string,
}

function Card_B({
    image,
    title,
    description,
    buttonLabel = "Get Started",
    roundness,
} : CardProps ) {
    if (description.length > 82) {description = description.slice(0, 80) + "...";}
    return (
        <div className="w-[960px] h-[359px] px-4 py-10 justify-start items-start gap-8 inline-flex ">
            <img className={cn(CardVariants({roundness}), "aspect-video relative object-cover w-[496px] h-[279px]")} src={image} />

            <div className="w-[400px] h-[279px] flex-col justify-center items-start gap-8 inline-flex">
                <div className="w-[400px] h-[152px] flex-col justify-start items-start flex">
                    <div className="w-[400px] h-[152px] flex-col justify-start items-start gap-2 flex">
                        <div className="w-[400px] h-[120px] flex-col justify-start items-start flex">
                            <div className="self-stretch h-[120px] flex-col justify-start items-start flex">
                                <div className="text-zinc-900 typeface-display-6xl">{title}</div>
                            </div>
                        </div>
                        <div className="w-[400px] h-6 flex-col justify-start items-start flex">
                            <div className="self-stretch h-6 flex-col justify-start items-start flex">
                                <div className="text-zinc-900 typeface-body-normal">{description}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-[400px] h-12 justify-start items-start inline-flex">
                    <Button className="w-[134.41px] h-12 px-5 bg-indigo-500 rounded-3xl justify-center items-center flex">
                        <div className="text-neutral-50 typeface-body-normal">{buttonLabel}</div>
                    </Button>
                </div>
            </div>
        </div>
    )
}

registerComponentVariant({
    reactComponent: Card_B as React.ComponentType,
    name: "Card",
    description: "A card component",
    variantName: "buttonCard",
    variantDescription: "A card with a button",
    acceptsProps: ['image', 'title', 'description', 'buttonLabel'],
    acceptsChildren: false,
    acceptSizes: ['lg'],
    acceptOrientations: ['east'],
    acceptsRoundness: ['md'],
    acceptsAspectRatios: ['16/9']
})

export default Card_B;