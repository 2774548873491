import { ComponentType } from "react"

import {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
  SelectScrollUpButton,
  SelectScrollDownButton,
} from "@/components/shadcn/ui/select"
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { ComponentAliasConfig, childrenParsers, imports, isTextComponent, normalizeTextComponent, radixComponentDoc, setup } from "../_utils";
import { addStagesIf, deepRemoveSameTypeChildren, groupAdjacentComponentsOfTypes, markTreeLeafs, parseTextComponents, removeFirstOccurenceOfTextFromChildren, solveAliases, transformComponentsOfTypes, treeParsingPipeline } from "../../parsing-pipeline";
import check from "@/vendors/check";
import { parseInputOrCommandText } from "../../layouts-lang";
import kebabCase from "kebab-case";
import { componentAliasesSpec, mapComponentTreeToSchema } from "@/lib/parser/parser-v3-utils";
import upperlower from 'upperlower';
import uniqid from 'uniqid';
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { getSingleVarNameFromText, removeEmptyProps, removeTextFromChildren, splitTextByVars } from "@/lib/parser/util";
import { DataInput } from "@/vendors/data/data-v2";

// //////////////////////////////////////////
// Add displayNames to fix errors in prod
// //////////////////////////////////////////
Select.displayName = 'Select';
SelectGroup.displayName = 'SelectGroup';
SelectValue.displayName = 'SelectValue';
SelectTrigger.displayName = 'SelectTrigger';
SelectContent.displayName = 'SelectContent';
SelectLabel.displayName = 'SelectLabel';
SelectItem.displayName = 'SelectItem';
SelectSeparator.displayName = 'SelectSeparator';
SelectScrollUpButton.displayName = 'SelectScrollUpButton';
SelectScrollDownButton.displayName = 'SelectScrollDownButton';
// //////////////////////////////////////////

const kebab = (s: string) => {
  if (!check.nonEmptyString(s)) {
    return s;
  }

  let out = kebabCase(s);
  return (out[0] === '-' ? out.slice(1) : out);
}

const SplitBeforeLabels : ComponentType = (props: any) => null;

/*
export function SelectDemo() {
  return (
    <Select>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Select a fruit" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Fruits</SelectLabel>
          <SelectItem value="apple">Apple</SelectItem>
          <SelectItem value="banana">Banana</SelectItem>
          <SelectItem value="blueberry">Blueberry</SelectItem>
          <SelectItem value="grapes">Grapes</SelectItem>
          <SelectItem value="pineapple">Pineapple</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
*/

function parseTextChild(c: ComponentTreeNode) : ComponentTreeNode | null {
  if (!check.nonEmptyString(c.text) || !check.nonEmptyString(c.text.trim())) {
    return null;
  }

  const { type, label } = parseInputOrCommandText(c.text, {
    title: true,
    separator: true,
  });

  if (type === 'separator') {
    return {
      component: SelectSeparator,
      htmlComponent: 'SelectSeparator',
      classes: [],
      props: {},
      children: [],
    };
  }

  if (type === 'title') {
    return {
      component: SelectLabel,
      htmlComponent: 'SelectLabel',
      classes: [],
      props: {},
      children: [label],
    };
  }

  const value = c.props?.value || kebab(c.text.trim());
  if (!check.nonEmptyString(value)) {
    // Radix doesnt support SelectItems with empty string values
    // We add this as it may happen to have a SelectItem without a value, especially when you type '-' in the editor
    return null;
  }
  
  return {
    ...c,
    component: SelectItem as unknown as ComponentType,
    htmlComponent: 'SelectItem',
    children: [c.text.trim()],
    props: {
      ...(c.props || {}),
      value,
      // name: c.text.trim(),
    }
  };
}


const TAG = 'select';
const ALIASES : ComponentAliasConfig[] = [
  ['content', SelectContent, 'SelectContent', radixComponentDoc('Select.Content')],
  ['group', SelectGroup, 'SelectGroup', radixComponentDoc('Select.Group')],
  ['item', SelectItem as unknown as ComponentType, 'SelectItem', radixComponentDoc('Select.Item')],
  ['label', SelectLabel, 'SelectLabel', radixComponentDoc('Select.Label')],
  ['trigger', SelectTrigger, 'SelectTrigger', radixComponentDoc('Select.Trigger')],
  ['separator', SelectSeparator, 'SelectSeparator', radixComponentDoc('Select.Separator')],
  [['scroll-up-button', 'scroll-up', 'up', 'up-button'], SelectScrollUpButton, 'SelectScrollUpButton', radixComponentDoc('Select.ScrollUpButton')],
  [['scroll-down-button',  'scroll-down', 'down', 'down-button'], SelectScrollDownButton, 'SelectScrollDownButton', radixComponentDoc('Select.ScrollDownButton')],
  ['value', SelectValue, 'SelectValue', radixComponentDoc('Select.Value')],
];

const SelectComponentParser : ComponentParser = {
name: 'Select',
description: 'Displays a list of options for the user to pick from—triggered by a button.',
tags: [TAG],

refImplementation: `
/select w-[180px] Select a fruit
  # Fruits
  Apple
  Banana
  Blueberry
  Grapes
  Pineapple
  ---
  # Vegetables
  Carrot
  Cucumber
  Lettuce
  Onion
  Potato
`.trim().replaceAll(/ {2}/g, '\t'),

childrenParsers: childrenParsers(ALIASES, TAG),

parseTree: (cRaw: ComponentTreeNode) => {
  const c = { ...cRaw };

  // Remove occurence of text in children
  c.children = removeTextFromChildren(c.text, c.children || []);

  // Extract placeholder from text 
  let placeholder : string | undefined = splitTextByVars(c.text).filter(p => !p.startsWith('$')).join(' ').trim();
  placeholder = check.nonEmptyString(placeholder) ? placeholder : undefined;

  try {
  const SCHEMA = `
    <Select 
      __enforceChildrenOrder 
      __textAsChildComponentProp="SelectValue.placeholder"
    >
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup __allowMultiple /> 
        <SelectLabel __allowMultiple />
        <SelectItem __allowMultiple />
        <Rest __allowMultiple />
      </SelectContent>
    </Select>
  `;

  const COMPONENTS_MAP = componentAliasesSpec(
    [Select, 'Select'],
    [SelectTrigger, 'SelectTrigger'],
    [SelectValue, 'SelectValue'],
    [SelectContent, 'SelectContent'],
    [SelectGroup, 'SelectGroup'],
    [SelectLabel, 'SelectLabel'],
    [SelectItem as unknown as ComponentType, 'SelectItem'],
    [SelectSeparator, 'SelectSeparator'],
    [SelectScrollUpButton, 'SelectScrollUpButton'],
    [SelectScrollDownButton, 'SelectScrollDownButton'],
  );

  const withParsedTextItems = treeParsingPipeline([ 
    // Parse text components
    parseTextComponents(parseTextChild),

    // Mark SelectValue, SelectItem, SelectSeparator, SelectScrollUpButton, SelectScrollDownButton, SelectLabel as leafs
    markTreeLeafs([
      SelectValue,
      SelectItem as unknown as ComponentType,
      SelectSeparator,
      SelectScrollUpButton,
      SelectScrollDownButton,
      SelectLabel,
    ]),
  ]).run(c, []);

  const withMacroStructure = mapComponentTreeToSchema(SCHEMA, withParsedTextItems, COMPONENTS_MAP);

  const withGroupedItemsAndAdditionalCleaning = treeParsingPipeline([
    // Add placeholder to SelectValue
    transformComponentsOfTypes([SelectValue], (comp: ComponentTreeNode) => {
      return {
        ...comp,
        props: {
          ...comp.props,
          placeholder: comp.props?.placeholder || placeholder,
        },
      };
    }),

    // Make sure group, item, separator, label don't have themselves as children
    deepRemoveSameTypeChildren([SelectGroup, SelectItem as unknown as ComponentType, SelectSeparator, SelectLabel]),

    // Make sure there is no empty groups
    transformComponentsOfTypes([SelectGroup], (comp: ComponentTreeNode) => {
      if (!check.nonEmptyArray(comp.children)) {
        return null;
      }

      return comp;
    }),

    // Make sure all groups have a unique key changing at each render
    transformComponentsOfTypes([SelectGroup], (comp: ComponentTreeNode) => {
      comp.key = uniqid();
      return comp;
    }),

    // Clean items (make sure they have a value and a name)
    transformComponentsOfTypes([SelectItem as any], (comp: ComponentTreeNode) => {
      let itemText = '';

      if (check.nonEmptyString(comp.props?.name)) {
        itemText = comp.props.name;
      }
      if (!check.nonEmptyString(itemText) && check.nonEmptyString(comp.props?.value)) {
        itemText = upperlower(comp.props?.value.replaceAll(/-/g, ' ').trim(), 'titlecase');
      }

      if (!check.nonEmptyString(itemText)) {
        itemText = comp.text || '';
      }

      if (!check.nonEmptyString(itemText)) {
        itemText = (comp.children || []).filter(isTextComponent).map(i => normalizeTextComponent(i)?.text?.trim()).filter(check.nonEmptyString).join(' ').trim() || '';
      }

      if (!check.nonEmptyString(itemText) || !check.nonEmptyString(itemText.trim())) {
        return null;
      }

      if (!check.nonEmptyString(comp.props?.value)) {
        comp.props = {
          ...comp.props,
          value: kebab(itemText),
        };
      }
      if (!check.nonEmptyArray(comp.children)) {
        comp.children = [itemText];
      }

      // Wrap all text children in a span
      comp.children = (comp.children || []).map((child: ComponentTreeNode) => {
        if (isTextComponent(child)) {
          return {
            component: 'span',
            htmlComponent: 'span',
            classes: [],
            props: {},
            children: [child],
          };
        }
        return child;
      });

      return comp;
    }),

    // Group adjacent SelectItems into SelectGroup
    addStagesIf(
      (c: ComponentTreeNode) => c.component === SelectContent,
      [
        // Add splitter before labels to make sure we split before labels if necessary
        transformComponentsOfTypes([SelectLabel], (comp: ComponentTreeNode) => {
          return [
            {
              component: SplitBeforeLabels,
              htmlComponent: 'SplitBeforeLabels',
              classes: [],
              props: {},
              children: [],
            },
            comp,
          ];
        }),

        // Group labels & items together
        groupAdjacentComponentsOfTypes([SelectItem as unknown as ComponentType, SelectLabel], SelectGroup, 'SelectGroup'),

        // Remove splitters
        transformComponentsOfTypes([SplitBeforeLabels], () => null),

        // Remove unknown components
        transformComponentsOfTypes([
          SelectItem as unknown as ComponentType,
          SelectSeparator,
          SelectScrollUpButton,
          SelectScrollDownButton,
          SelectLabel,
          SelectGroup,
        ], () => null, true),
      ]
    ),
  ]).run(withMacroStructure, []);


  // Create wrapper if select should be bound to variable
  const bind = getSingleVarNameFromText(c.text);
  let wrap = (toWrap: ComponentTreeNode) => toWrap;
  if (bind) {
    wrap = (toWrap: ComponentTreeNode) => ({
        component: DataInput as React.ComponentType,
        htmlComponent: 'DataInput',
        props: {
            bind,
            type: 'string',
        },
        classes: [],
        variant: null,
        children: [toWrap],
    });
  }

  // Generate a unique key for the select component every time to avoid 
  // Radix-related error when modifying the component structure
  const id : string = 'select_'+uniqid();
  let out : any = wrap({
    ...withGroupedItemsAndAdditionalCleaning,
    key: id,
    props: {
      ...withGroupedItemsAndAdditionalCleaning.props,
      className: undefined,
      key: id,
    }
  });

  // Wrap the select in a div in case it has a className
  const className = withGroupedItemsAndAdditionalCleaning.props?.className || null;
  if (check.nonEmptyString(className)) {
    out = {
      component: 'div',
      htmlComponent: 'div',
      classes: className.split(' ').filter(check.nonEmptyString),
      props: {
        className,
      },
      children: [out],
    }
  }

  return out;

  } catch(err) {
    console.error('Error in parsing Select component:', err);
    return null;
  }

  /*

  const parsed = treeParsingPipeline([
    // Remove text from children if any
    addStagesIf(
      SelectContent,
      [removeFirstOccurenceOfTextFromChildren(c.text)],
    ),

    // Parse text components
    parseTextComponents(parseTextChild),

    // Solve alias components
    // solveAliases(ALIASES),

    // Mark SelectValue, SelectItem, SelectSeparator, SelectScrollUpButton, SelectScrollDownButton, SelectLabel as leafs
    markTreeLeafs([
      SelectValue,
      SelectItem as unknown as ComponentType,
      SelectSeparator,
      SelectScrollUpButton,
      SelectScrollDownButton,
      SelectLabel,
    ]),

    // Group adjacent SelectItems into SelectGroup
    addStagesIf(
      SelectContent,
      [
        // Add splitter before labels to make sure we split before labels if necessary
        transformComponentsOfTypes([SelectLabel], (comp: ComponentTreeNode) => {
          return [
            {
              component: SplitBeforeLabels,
              htmlComponent: 'SplitBeforeLabels',
              classes: [],
              props: {},
              children: [],
            },
            comp,
          ];
        }),

        // Group labels & items together
        groupAdjacentComponentsOfTypes([SelectItem as unknown as ComponentType, SelectLabel], SelectGroup, 'SelectGroup'),

        // Remove splitters
        transformComponentsOfTypes([SplitBeforeLabels], () => null),
      ],
    ),
  ]).run({ 
    component: SelectContent, 
    htmlComponent:'SelectContent',
    props:{}, 
    classes:[], 
    children: c.children || []
  }, [Select]);

  const out = {
    component: Select,
    htmlComponent: 'Select',
    props: {},
    classes: [],
    children: [
      { 
        component: SelectTrigger,
        htmlComponent: 'SelectTrigger',
        classes: c.classes || [],
        props: {
          className: cn(c.classes),
        },
        children: [
          {
            component: SelectValue,
            htmlComponent: 'SelectValue',
            classes: [],
            props: {
              placeholder: c.text || 'Select...',
              children: undefined,
            },
            children: undefined,
          }
        ]
      },
      parsed,
    ],
  };

  return out;
  */
},

imports: imports(['Select', 'SelectGroup', 'SelectValue', 'SelectTrigger', 'SelectContent', 'SelectLabel', 'SelectItem', 'SelectSeparator', 'SelectScrollUpButton', 'SelectScrollDownButton'], TAG),
setup: setup(TAG),
variants: [],
doc: {
    props: radixComponentDoc('Select.Root').props,
  }
};

registerShadcnComponent({
        Select,
        SelectGroup,
        SelectValue,
        SelectTrigger,
        SelectContent,
        SelectLabel,
        SelectItem,
        SelectSeparator,
        SelectScrollUpButton,
        SelectScrollDownButton,
    },
    TAG,
);

export default SelectComponentParser;

