import check from "@/vendors/check";

const PUBLIC = 'public';

export function pageMarkdown(pageId: string | undefined, uid: string | null) {
    if(!check.nonEmptyString(pageId) || !check.nonEmptyString(uid)) {
        return null;
    }

    return `/users/${uid}/projects/default/pages/${pageId}/markdown`;
}

export function pageRoot(pageId: string | undefined, uid: string | null) {
    if(!check.nonEmptyString(pageId) || !check.nonEmptyString(uid)) {
        return null;
    }

    return `/users/${uid}/projects/default/pages/${pageId}`;
}

export function pagesDirectory(uid: string | null) {
    if(!check.nonEmptyString(uid)) {
        return null;
    }

    return `/users/${uid}/directory`;
}

export function directoryEntryForPage(pageId: string | undefined, uid: string | null) {
    if(!check.nonEmptyString(pageId) || !check.nonEmptyString(uid)) {
        return null;
    }

    return `/users/${uid}/directory/${pageId}`;
}


export function publicPageMarkdown(pageId: string | undefined) {
    if(!check.nonEmptyString(pageId)) {
        return null;
    }

    return `/${PUBLIC}/pages/${pageId}/markdown`;
}

export function publicPageRoot(pageId: string | undefined) {
    if(!check.nonEmptyString(pageId)) {
        return null;
    }

    return `/${PUBLIC}/pages/${pageId}`;
}

export function publicPageTitle(pageId: string | undefined) {
    if(!check.nonEmptyString(pageId)) {
        return null;
    }
    return `/${PUBLIC}/pages/${pageId}/title`;
}

export function pageTitle(pageId: string | undefined, uid: string | null) {
    if(!check.nonEmptyString(pageId) || !check.nonEmptyString(uid)) {
        return null;
    }

    return `/users/${uid}/projects/default/pages/${pageId}/title`;
}

export function publicPageUid(pageId: string | undefined) {
    if(!check.nonEmptyString(pageId)) {
        return null;
    }

    return `/${PUBLIC}/pages/${pageId}/owner`;
}

export function pageDocUrl(pageId: string | undefined) {
    if(!check.nonEmptyString(pageId)) {
        return null;
    }

    return `/${PUBLIC}/pages/${pageId}/currentDisplay/docUrl`;
}

export function pagePublishQueueUrl(pageId: string | undefined, uid: string | null) {
    if(!check.nonEmptyString(pageId) || !check.nonEmptyString(uid)) {
        return null;
    }

    return `/users/${uid}/projects/default/pages/${pageId}/currentDisplay/pushQueue`;
}


export function pageHtmlCode(pageId: string | undefined, uid: string | null) {
    if(!check.nonEmptyString(pageId) || !check.nonEmptyString(uid)) {
        return null;
    }

    return `/users/${uid}/projects/default/pages/${pageId}/currentDisplay/rawHtml`;
}