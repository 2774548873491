import check from "./check";
import dJSON from 'dirty-json';
import split from 'string-split-by';

// Handle array parsing as dirty-json doesn't handle it well
function dirtyJsonParseArray(value: string) : any {
    if (!check.string(value) || !value.trim().startsWith('[') || !value.trim().endsWith(']')) {
        throw new Error('Invalid array string');
    }

    const contents = split(value.trim().substring(1, value.trim().length - 1), ',');
    return contents.map((v: string) => bulletProofJSONParse(v));
}

export function bulletProofJSONParse(value: any) : any {
    if (!check.string(value)) {
        return value;
    }

    // Support [1...5] notation
    if (/^\[\s*[0-9]+\s*\.{3}\s*[0-9]+\s*\]$/g.test(value.trim())) {
        console.log('Bulletproof JSON: Detected range notation:', value);
        const [start, end] = value.trim().replace('[', '').replace(']', '').split('...').map(v => parseInt(v, 10));
        return Array.from({ length: end - start + 1 }, (_, i) => i + start);
    }

    try {
        return JSON.parse(value);
    } catch (e) {
        try {
            // Attempt to parse arrays as they're not handled right by dirty JSON
            if (value.trim().startsWith('[') && value.trim().endsWith(']')) {
                return dirtyJsonParseArray(value);
            }

            return dJSON.parse(value);
        } catch (e) {
            return undefined;
        }
    }
}

export function isJsonLikeObjectOrArray(value: any) : boolean {
    if (!check.string(value)) {
        return false;
    }

    if (
        (value.trim().startsWith('{') && value.trim().endsWith('}'))
        || (value.trim().startsWith('[') && value.trim().endsWith(']'))
    ) {
        return !!bulletProofJSONParse(value);
    }

    return false;
}

export function isJsonLikeArray(value: any) : boolean {
    if (!check.string(value)) {
        return false;
    }

    if (
        (value.trim().startsWith('{') && value.trim().endsWith('}'))
        || (value.trim().startsWith('[') && value.trim().endsWith(']'))
    ) {
        return check.array(bulletProofJSONParse(value));
    }

    return false;
}