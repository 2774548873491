import { registerComponentVariant } from "../../lib/components-registry";
import ListItemImage from "./Img/ListItemImage";
import { LIST_ITEM_COMPONENT_DESCRIPTION, LIST_ITEM_COMPONENT_NAME, LIST_ITEM_ICON_VARIANT_DESCRIPTION, LIST_ITEM_ICON_VARIANT_NAME, LIST_ITEM_IMAGE_VARIANT_DESCRIPTION, LIST_ITEM_IMAGE_VARIANT_NAME, LIST_ITEM_VARIANT_DESCRIPTION } from "../../lib/constants";
import ListItemIcon from "./Icon/ListItemIcon";



registerComponentVariant({
    reactComponent: ListItemImage as React.ComponentType,
    name: LIST_ITEM_COMPONENT_NAME,
    description: LIST_ITEM_COMPONENT_DESCRIPTION,
    variantName: LIST_ITEM_IMAGE_VARIANT_NAME,
    variantDescription: LIST_ITEM_IMAGE_VARIANT_DESCRIPTION,
    acceptsProps: ['image', 'title', 'description'],
    acceptsChildren: false,
    acceptSizes: ['6xl', '5xl', '4xl', '3xl', '2xl', 'xl', 'lg', 'md', 'sm', 'xs'],
    acceptOrientations: ['west', 'east'],
    acceptsRoundness: ['none','sm', 'md', 'lg', 'xl', '2xl', '3xl', 'full'],
    acceptsAspectRatios: ['9/16', '16/9', '3/4', '4/3', 'square']
});

registerComponentVariant({
    reactComponent: ListItemIcon as React.ComponentType,
    name: LIST_ITEM_COMPONENT_NAME,
    description: LIST_ITEM_COMPONENT_DESCRIPTION,
    variantName: LIST_ITEM_ICON_VARIANT_NAME,
    variantDescription: LIST_ITEM_ICON_VARIANT_DESCRIPTION,
    acceptsProps: ['image', 'title', 'description'],
    acceptsChildren: false,
    acceptSizes: ['6xl', '5xl', '4xl', '3xl', '2xl', 'xl', 'lg', 'md', 'sm', 'xs'],
    acceptOrientations: ['west', 'east'],
    acceptsRoundness: ['none','sm', 'md', 'lg', 'xl', '2xl', '3xl', 'full'],
    acceptsAspectRatios: ['9/16', '16/9', '3/4', '4/3', 'square']
});