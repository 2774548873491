// Info extracted from: next/dist/compiled/@next/font/dist/google/index.d.ts
import check from '../check';
import googleFonts from './google-fonts.json';

const fontsByName = new Map<string, any>();
googleFonts.forEach((font: { component: string, weights: string[] }) => {
    fontsByName.set(font.component, font);
});

export function isGoogleFont(name: string) {
    return fontsByName.has(name);
}

export function isVariableGoogleFont(name: string) {
    return isGoogleFont(name) && fontsByName.get(name).weights.includes('variable');
}

export function getGoogleFontWeights(name: string) {
    return fontsByName.get(name).weights;
}

export function getGoogleFontSubsets(name: string) {
    return fontsByName.get(name).subsets;
}

export function getTailwindFontFamiliesForGoogleFont(name: string) {
    const twName = name.replaceAll('_', '').toLowerCase();
    const varName = `--font-${name.replaceAll('_', '-').toLowerCase()}`;

    return {
        twName,
        varName,
    };
}

export function getNextJSConfigForGoogleFont(name: string) {
    if (!isGoogleFont(name)) {
        return {};
    }
    const isVariable = isVariableGoogleFont(name);
    const weight: string[] = getGoogleFontWeights(name)
    const subsets: string[] = getGoogleFontSubsets(name);
    const variable = `--font-${name.replaceAll('_', '-').toLowerCase()}`;

    return {
        weight: isVariable ? undefined : weight,
        display: isVariable ? 'swap' : undefined,
        subsets: check.nonEmptyArray(subsets) ? subsets : undefined,
        variable,
    };
}