import { cn } from "@/lib/utils";

const grotesqueTheme = {
    name: 'grotesque',

    colors: {
        primary: '#7e22ce', // Purple
        secondary: '#B487DC', // Purplish
        accent: '#24a094', // Green
        background: '#F8F4FA', // Light gray
        destructive: '#DC2626',
    },

    fonts: [
        'Rubik',
    ],

    cssVars: {
        '--font-display-family': `'Rubik', sans-serif`,
        '--font-display-style': `'normal'`,  
        '--font-body-family': `'Rubik', sans-serif`,
        '--font-body-style': `'normal'`,
        '--radius': '0.5rem',
    },

    components: {
        'Button': [
            cn("inline-flex items-center justify-center whitespace-nowrap rounded-md ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 gap-2 border border-black hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] transition duration-200 hover:translate-y-[-5px] hover:translate-x-[-5px] typeface-body-sm font-medium"),
            {
                "variants": {
                    "variant": {
                        "default": "bg-purple-700 text-white hover:bg-purple-700 hover:text-white",
                        "secondary": "bg-white text-black hover:bg-white"
                    },
                    "sizes": {
                        "lg": "h-10 px-4 py-2 text-base",
                        "default": "px-3 py-1.5 text-sm h-min",
                        "sm": "px-3 py-1.5 text-xs h-min"
                    }
                },
                "defaultVariants": {
                    "variant": "default",
                    "size": "default"
                }
            }
        ],
    },
}

export default grotesqueTheme;
