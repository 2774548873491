import check from "@/vendors/check";
import ucfirst from 'ucfirst';
import { ComponentTreeNode } from "./components/component.type";
import { linesToTree, parseRootTree, parseTextLevel1, treeNodetoReactComponent, treeToImports, treeToSetupInstructions } from "./parser";
import { extractVarNamesFromText, getComponentName, removeEmptyProps, sortProps } from "./util";
import { outputJSX } from "./jsonTojsx";
import { Fragment, ReactNode, createElement } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Button } from "@/components/shadcn/ui/button";
import { joinAdjacentTextChildrenWithSpaces } from "./parser-v3-utils";
import { ComponentParserConfig, getParserConfig, ParserConfigTarget } from "./parser-config";
import { FONT_PAIR_VARIANT_TO_PROPS } from "./components/base/fonts";
import { getInstantMetadataForImageUrl } from "@/vendors/illustrations.dev/get-image-of";
import { getGoogleFontsUrl } from "@/vendors/theme/google-fonts";
import { PageProvider } from "@/components/layouts-ui/PageProvider";
import { cn, isSerializedFunction } from "../utils";
import { generateThemeCssVars } from "@/components/layouts-ui/generate-theme";
import arrayUnique from "array-unique";
import { mergeImportLines, sortSetupCommands } from "./importsRegistry";
import { isGoogleFont, getNextJSConfigForGoogleFont, getTailwindFontFamiliesForGoogleFont } from "@/vendors/fonts/google-fonts";
import { generateTailwindConfig } from "./generateTailwindConfig";
import { extractVarsFromProps } from "@/vendors/data/data-v2";
import { has } from "dot-prop";
import { sha1 } from "../sha1";

export type Font = {
    name: string;
}

export type Asset = {
    name: string;
    url: string;
    mimeType: string;
    credits: string;
}

export type PageJson = {
    title: string;
    path: string;
    imports: string[];
    setupCommands: string[];
    children: ComponentTreeNode[];
    usesVariables: string[];
}

export type SimplifiedPageJson = {
    title: string;
    path: string;
    children: JsonXNode[];
}

export type AppJson = {
    imports: string[];
    setupInstructions: string[];
    fonts: Font[];
    themeProps: Record<string, any>;
    assets: Asset[];
    components: v1TreeNode[];
    topLevelContent: ComponentTreeNode[];
    pages: PageJson[];
}

// Simplified appJson made to communicate with React Native
// In this scenario, we'll split tree parsing and matching component names
// Components will be matched to their names and their imports at time of use
export type SimplifiedAppJson = {
    // imports: string[];
    // setupInstructions: string[];
    fonts: Font[];
    assets: Asset[];
    // FIXME: Components format to be reviewed
    // components: v1TreeNode[];
    topLevelContent: JsonXNode[];
    pages: SimplifiedPageJson[];
}

export type v1TreeNode = {
    key: string;
    component: string;
    props: {
        [key: string]: string;
    };
    text?: string;
    variant?: string;
    classes: string[];
    children: v1TreeNode[];
}

export type JsonXNode = {
    component: string;
    props: {
        [key: string]: any;
    };
    children: (JsonXNode | string)[];
    comments?: string[]; // Comments to place above the component
}


export function layoutsLangToV1Tree(text: string): v1TreeNode[] | null {
    const lines = parseTextLevel1(text);
    // console.log('Base component lines', lines);

    const tree = linesToTree(lines);
    // console.log('Base component tree', tree);
    
    return tree as any; // FIXME: Fix typing
}

// Will be used to merge multiple fonts together
// In the future, should merge font weights
function mergeFonts(fonts: Font[]): Font[] {
    return fonts.map(f => f.name).filter((value, index, self) => self.indexOf(value) === index).map(name => ({ name } as Font));
}

function extractFontsFromClasses(classes: string[]): Font[] {
    const fontClasses = classes.filter(c => c.startsWith(`font-['`) && c.endsWith(`']`));
    return fontClasses.map((fontClass) => {
        const name = fontClass.replace(`font-['`, '').replace(`']`, '');
        return { name };
    });
}

function getFontsFromFontPairComponent(c: v1TreeNode): Font[] {
    if (c?.component !== 'font-pair' || !check.nonEmptyString(c.variant)) {
        return [];
    }

    const match = FONT_PAIR_VARIANT_TO_PROPS[c.variant];
    if (match) {
        return [
            { name: match.displayFont },
            { name: match.bodyFont },
        ];
    }

    return [];
}

function getFontsFromTree(tree: v1TreeNode | v1TreeNode[] | null): Font[] {
    const fonts: Font[] = [];
    
    if (!tree) {
        return fonts;
    }

    if (check.array(tree)) {
        return mergeFonts(tree.map(getFontsFromTree).flat());
    }

    if (!check.object(tree)) {
        return fonts;
    }

    return mergeFonts([
        ...extractFontsFromClasses([
            ...(tree.classes || []),
            ...(tree?.props?.className?.split(' ') || []),
        ]),
        ...(tree?.children || []).map(getFontsFromTree).flat(),
        ...(Object.keys(tree.props || {}).filter(k => k.toLowerCase().includes('font')).map(k => ({ name: tree.props[k] }))),
        ...((tree.component === 'font-pair') ? 
            getFontsFromFontPairComponent(tree)
        : [])
    ]);
}

function extractFromTree(tree: v1TreeNode | v1TreeNode[] | null, targetComponent: string): v1TreeNode[] {
    let out : v1TreeNode[] = [];
    if (!tree) {
        return [];
    }

    if (check.array(tree)) {
        return tree.map(t => extractFromTree(t, targetComponent)).flat();
    }

    if (!check.object(tree)) {
        return [];
    }

    if (tree.component === targetComponent) {
        out.push(tree);
    }

    out.push(...(tree.children || []).map(t => extractFromTree(t, targetComponent)).flat());
    return out;
}

function removeAllOccurencesOfComponents(tree: v1TreeNode | v1TreeNode[] | null, targetComponent: string[]): v1TreeNode | v1TreeNode[] | null {
    if (!tree) {
        return tree;
    }

    if (check.array(tree)) {
        return tree.map(t => removeAllOccurencesOfComponents(t, targetComponent)!).filter(t => !!t).flat();
    }

    if (!check.object(tree)) {
        return tree;
    }

    if (targetComponent.includes(tree.component)) {
        return null;
    }

    return {
        ...tree,
        children: (tree.children || []).map(t => removeAllOccurencesOfComponents(t, targetComponent)!).filter(t => !!t).flat(),
    }
}

function extractAllVariablesUsedInTree(tree: ComponentTreeNode | ComponentTreeNode[]): string[] {
    if (check.array(tree)) {
        return tree.map(extractAllVariablesUsedInTree).flat();
    }

    let vars : string[] = [];
    if (!check.object(tree)) {
        return vars;
    }

    if (check.nonEmptyString(tree.text)) {
        vars.push(...extractVarNamesFromText(tree.text));
    }

    if (check.nonEmptyObject(tree.props)) {
        Object.values(tree.props).forEach(v => {
            if (check.nonEmptyString(v)) {
                vars.push(...extractVarNamesFromText(v));
            } else if (isSerializedFunction(v)) {
                vars.push(...extractVarNamesFromText(v.code));
            }
        });
    }

    if (check.nonEmptyArray(tree.children)) {
        vars.push(...tree.children.map(extractAllVariablesUsedInTree).flat());
    }

    return arrayUnique(vars).sort();
}

function pagePropsFromv1TreeNode(c: v1TreeNode): { title?: string, path?: string } {
    const parts = (c.text || '').trim().split(' ').map(p => p.trim()).filter(p => check.nonEmptyString(p));

    let title;
    let path = parts.find(p => p.startsWith('/'));
    const rest = parts.filter(p => p !== path);

    if (check.nonEmptyArray(rest)) {
        title = ucfirst(rest.join(' ').toLowerCase());
    }

    if (check.nonEmptyArray(rest) && !path) {
        path = '/' + rest.join('-').toLowerCase();
    }

    const propsFromText : { title?: string, path?: string } = {};
    if (check.nonEmptyString(title)) {
        propsFromText.title = title;
    }
    if (check.nonEmptyString(path)) {
        propsFromText.path = path;
    }

    let props : { [key:string]: string } = {};
    if (check.nonEmptyString(c.props.title)) {
        props.title = c.props.title;
    }
    if (check.nonEmptyString(c.props.path)) {
        props.path = c.props.path;
    }
    props = { ...propsFromText, ...props };

    if (!check.nonEmptyString(props.title) && check.nonEmptyString(props.path) && check.nonEmptyString(props.path.replaceAll('/', ''))) {
        const parts = props.path.split('/').filter(part => check.nonEmptyString(part.trim()));
        props.title = ucfirst(parts.pop() || '');
    }

    // Ensure the page path always starts with a /
    if (check.nonEmptyString(props.path) && !props.path.startsWith('/')) {
        props.path = '/' + props.path;
    }

    if (!check.nonEmptyString(props.title) && props.path === '/') {
        props.title = 'Home';
    }

    props.path = (props.path || '/').toLowerCase().replaceAll(/\s{1,}/g, '-');

    return props;
}


function parsePageNode(c: v1TreeNode, config: ComponentParserConfig): PageJson {
    const props = pagePropsFromv1TreeNode(c);

    const children = [{
        component: 'div',
        htmlComponent: 'div',
        props: {
            className: cn('flex flex-col w-full h-full min-h-screen items-start justify-start', c.classes),
        },
        classes: cn('flex flex-col w-full h-full min-h-screen items-start justify-start', c.classes).split(' '),
        children: [parseRootTree(c.children, [], config)].flat(),
    }];

    const variablesUsed = extractAllVariablesUsedInTree(children);

    return {
        title: props.title || '',
        path: props.path || '/',
        imports: treeToImports(children, config, check.nonEmptyArray(variablesUsed) ? ['PageProvider'] : []),
        setupCommands: treeToSetupInstructions(children, config, check.nonEmptyArray(variablesUsed) ? ['PageProvider'] : []),
        children,
        usesVariables: variablesUsed,
    };
}

export function layoutsLangComponentToJsx(text: string, configOrPreset: ComponentParserConfig | ParserConfigTarget, includeTopLevel = false): string {
    const config = getParserConfig(configOrPreset);
    const parsedv1 = layoutsLangToV1Tree(text);
    const otherNodes = (includeTopLevel ? parsedv1 : removeAllOccurencesOfComponents(parsedv1, ['component', 'page'])) || [];
    const TOP_LEVEL_COMPONENTS = includeTopLevel ? [] : ['data', 'theme'];
    const homePageContent = (check.array(otherNodes) ? otherNodes : [otherNodes]).filter(n => !TOP_LEVEL_COMPONENTS.includes(n.component));
    const parsedHomeTree = [parseRootTree(homePageContent, [], config)].flat();
    const jsx = treeToJsx(parsedHomeTree);
    return jsx; 
}

function removeDuplicateAssets(assets: Asset[]): Asset[] {
    const existingNames = new Set<string>();
    return assets.filter(a => {
        if (existingNames.has(a.name)) {
            return false;
        }
        existingNames.add(a.name);
        return true;
    });
}

function getAssetsFromTree(tree: ComponentTreeNode | ComponentTreeNode[] | null): Asset[] {
    const assets: Asset[] = [];
    
    if (!tree) {
        return assets;
    }

    if (check.array(tree)) {
        return removeDuplicateAssets(tree.map(getAssetsFromTree).flat());
    }

    if (!check.object(tree)) {
        return assets;
    }

    // console.log('getAssets: Getting assets from tree', tree);

    return removeDuplicateAssets([
        ...assets,
        ...(tree.children || []).map(getAssetsFromTree).flat(),
        ...(Object.keys(tree.props || {}).filter(k => k === 'src').map(k => {
            // console.log('getAssets: getting for image', tree.props[k]);
            const url = tree.props[k];
            if (!check.nonEmptyString(url)) {
                return null;
            }

            const metadata = getInstantMetadataForImageUrl(url);
            if (!metadata) {
                // console.log('getAssets: Error getting metadata for image', url);
                return null;
            }

            const { name, credits, mimeType } = metadata;
            return {
                name,
                url,
                mimeType,
                credits,
            };
        }).filter(a => !!a) as Asset[]),
    ]);
}

export function layoutsLangToAppJson(text: string, configOrPreset : ComponentParserConfig | ParserConfigTarget): AppJson {
    const config = getParserConfig(configOrPreset);

    const parsedv1 = layoutsLangToV1Tree(text);

    const fonts = getFontsFromTree(parsedv1);

    const components = extractFromTree(parsedv1, 'component');
    const pagesContent = extractFromTree(parsedv1, 'page');
    const themeContent = extractFromTree(parsedv1, 'theme');

    // console.log('v2 pagesContent: ', pagesContent);
    let pages = pagesContent.map(c => parsePageNode(c, config));

    const otherNodes = removeAllOccurencesOfComponents(parsedv1, ['component', 'page']) || [];

    // console.log('v2 Pages: ', pages);
    // console.log('v2 Other nodes: ', otherNodes);

    // Extract global components if defined at top level
    const TOP_LEVEL_COMPONENTS = ['data', 'theme'];
    const topLevelContent = (check.array(otherNodes) ? otherNodes : [otherNodes]).filter(n => TOP_LEVEL_COMPONENTS.includes(n.component));
    
    // Put all remaining nodes in the home page
    const homePageContent = (check.array(otherNodes) ? otherNodes : [otherNodes]).filter(n => !TOP_LEVEL_COMPONENTS.includes(n.component));
    
    if (check.nonEmptyArray(homePageContent)) {
        // If page exists, add the content to the home page component and re-parse it
        const homePage = pages.find(p => p.path === '/');
        if (homePage) {
            const pageNode = pagesContent.find(c => pagePropsFromv1TreeNode(c).path === '/');
            if (!pageNode) {
                throw new Error('Impossible error: home page not found. Please warn our developers.');
            }
            pageNode.children = [...pageNode.children, ...homePageContent];

            // Replace component in the pages array
            const newHomePage = parsePageNode(pageNode, config);
            pages = pages.map(page => {
                if (page.path === '/') {
                    return newHomePage;
                }
                return page;
            });
        } else {
            // If page doesn't exist, create it
            const homePageComponent = {
                key: 'homepage',
                component: 'page',
                props: {
                    title: 'Home',
                    path: '/',
                },
                classes: [] as string[],
                children: homePageContent,
            };

            const newHomePage = parsePageNode(homePageComponent, config);
            pages.push(newHomePage);
        }
    }

    console.log('PARSED PAGES', pages)

    // Get assets
    const assets = getAssetsFromTree([
        ...pages.map(p => p.children).flat(),
    ]);

    // Get the theme props
    let themeProps = themeContent.map(t => t.props).reduce((acc, val) => {
        return {
            ...acc,
            ...val,
        };
    }, {});

    const parsedTopLevelContent = [parseRootTree(topLevelContent, [], config)].flat();

    // Create the app json object
    const appJson = {
        imports: mergeImportLines(arrayUnique([...pages.map(p => p.imports).flat(), ...treeToImports(parsedTopLevelContent, config)])),
        setupInstructions: sortSetupCommands([...pages.map(p => p.setupCommands).flat(), ...treeToSetupInstructions(topLevelContent, config)]),
        fonts,
        themeProps,
        assets,
        components,
        topLevelContent: parsedTopLevelContent,
        pages,
    };

    // console.log('v2 AppJson: ', appJson);

    return appJson;
}

export function getReactComponentForPage(page: PageJson): ReactNode {

    console.log('Page JSON: ', page)
    const comp = treeNodetoReactComponent({
        key: 'page::'+page.path,
        component: PageProvider,
        htmlComponent: 'PageProvider',
        props: {},
        classes: [],
        children: page.children,
    });

    return comp;
}

export function appJsonToComponentTree(appJson: AppJson): ComponentTreeNode {
    return {
        key: 'AppRoot',
        component: Fragment,
        htmlComponent: 'Fragment',
        props: {},
        classes: [],
        children: [
            {
                key: 'Helmet',
                component: Helmet,
                htmlComponent: 'Helmet',
                props: {},
                classes: [],
                children: [
                    ...appJson.fonts.map(f => ({
                        component: 'link',
                        htmlComponent: 'link',
                        props: {
                            rel: 'stylesheet',
                            href: `https://fonts.googleapis.com/css2?family=${f.name.split(/[\s_]/).map(ucfirst).join('+')}`,
                        },
                        classes: [],
                        children: [],
                    })),
                ],
            },
            ...(appJson.topLevelContent || []),
            {
                key: 'AppRoutes',
                component: Routes,
                htmlComponent: 'Routes',
                props: {},
                classes: [],
                children: appJson.pages.map(p => {
                    return ({
                        key: 'route::'+p.path,
                        component: Route,
                        htmlComponent: 'Route',
                        props: {
                            path: p.path,
                            element: getReactComponentForPage(p),
                        },
                        classes: [],
                        children: [],
                    })
                }),
            }
        ],
    }
}

const TreeRootErrorBoundaryFallback = ({ error } : { error: any }) => {
    return (
      <div className="flex flex-col items-center justify-center bg-red-700 text-white font-medium text-sm p-2 gap-2 h-screen w-screen"
      >
        {`Error on page`}
        {check.nonEmptyString(error?.message) && !(error.message.includes('minified react error')) && (
            <>
            {': '}
            <span className="font-normal text-center" >{error.message.replace('Error: ', '')}</span>
            </>
        )}
        <Button onClick={() => window.location.reload()} className="bg-red-800 hover:bg-red-900">Reload</Button>
      </div>
    );
};

export function appJsonToReactComponent(appJson: AppJson): ReactNode {
    const tree = treeNodetoReactComponent(appJsonToComponentTree(appJson));

    // Return tree with default error boundary
    return createElement(
        ErrorBoundary, 
        {
            fallbackRender: ({error}) => <TreeRootErrorBoundaryFallback error={error} />,
        }, 
        tree
    );
}

export function treeToJsonX(tree: ComponentTreeNode | ComponentTreeNode[]): JsonXNode {
    if (check.nonEmptyArray(tree)) {
        if (tree.length === 1) {
            return treeToJsonX(tree[0]);
        }
        return {
            component: 'Fragment',
            props: {},
            children: tree.map(treeToJsonX),
        };
    }

    if (!check.object(tree)) {
        return tree;
    }

    if (tree.component === 'text') {
        const props = removeEmptyProps(tree.props);
        if (check.nonEmptyObject(props)) {
            return {
                component: 'span',
                props,
                children: [tree.text || ''],
                comments: tree.comments,
            }
        }
        return {
            component: 'text',
            props: {},
            children: [tree.text || ''],
            comments: tree.comments,
        }
    }

    let parsedTree = joinAdjacentTextChildrenWithSpaces(tree);

    return {
        component: parsedTree.htmlComponent || getComponentName(parsedTree.component),
        props: sortProps(parsedTree.props),
        children: (parsedTree.children || []).map(treeToJsonX),
        comments: parsedTree.comments,
    };
}

export function treeToJsx(tree: ComponentTreeNode | ComponentTreeNode[], nIndents = 0): string {
    const cleanTree = removeNoTransitionClassesOnChildren(tree);
    const jx = treeToJsonX(cleanTree || []);

    return outputJSX(jx, nIndents);
}

function getPageComponentName(page: PageJson): string {
    let name = page.title.replaceAll(/[^a-zA-Z0-9]/g, ' ').split(' ').map(ucfirst).join('') + 'Page';

    if (name.endsWith('PagePage')) {
        name = name.replace('PagePage', 'Page');
    }

    return name;
}

function getComponentInnerJsxForPage(page: PageJson): string {
    const needsPageProvider = check.nonEmptyArray(page.usesVariables);
    const needsFragmentWrapper = page.children.length > 1 && !needsPageProvider;
    const baseIndent = needsPageProvider ? 2 : 1;
    const content = page.children.map(c => treeToJsx(c, page.children.length === 1 ? baseIndent : (baseIndent + 1))).join('\n\n');
    
    
    // Empty page, return null
    if (!check.nonEmptyString(content.trim())) {
        return `
return null;
`.trim();
    }

    // Non-empty page, return content

    // Add PageProvider if needed
    if (needsPageProvider) {
        return `
return (${'\n\t<PageProvider>'}
${content}
${'\t</PageProvider>\n'});
`.trim();
    }

    // Add Fragment wrapper if needed
    if (needsFragmentWrapper) {
        return `
return (${'\n\t<>'}
${content}
${'\t</>\n'});
`.trim();
    }

    // Non-empty page, return content
    return `
return (
${content}
);
    `.trim();
}

function getComponentJsxForPage(page: PageJson, exportAsDefault = false): string {
    const pageComponentName = getPageComponentName(page);
    const firstLine = `${exportAsDefault ? 'export default' : 'export'} function ${pageComponentName}() {`;


    let innerJsx = getComponentInnerJsxForPage(page);
    
    // Add indents 
    innerJsx = indent(innerJsx, 1, '\t');

   
    // Return code
    return `
${firstLine}
${innerJsx}
}
    `.trim();
}

function indent(text: string, nIndents: number, indentChain = '    '): string {
    return text.split('\n').map(l => indentChain.repeat(nIndents) + l).join('\n');
}

export function appJsonToSimplifiedAppJson(appJson: AppJson): SimplifiedAppJson {
    return {
        fonts: appJson.fonts,
        assets: appJson.assets,
        topLevelContent: appJson.topLevelContent.map(treeToJsonX),
        pages: appJson.pages.map(p => ({
            title: p.title,
            path: p.path,
            children: p.children.map(treeToJsonX),
        })),
    }
}

export function appJsonToReactCode(appJson: AppJson): string {
    const appHasOnlyHomePage = check.nonEmptyArray(appJson.pages) && appJson.pages.length === 1 && appJson.pages[0].path === '/';

    // License
    const license = `
///////////////////////////////////////////////////////////////////////////////////////////////////
// Generated by Layouts.dev
// ------------------------------------------------------------------------------------------------
// Copyright Creative Robots Inc. ${new Date().getFullYear()} - All rights reserved
// https://layouts.dev
// ------------------------------------------------------------------------------------------------
// We are constantly improving the way this code is generated.
// Notice something wrong? Please let us know at hey@creative-robots.ai and we'll fix it!
///////////////////////////////////////////////////////////////////////////////////////////////////`.trim();
    // Imports
    let imports = appJson.imports;
    
    // Setup instructons
    let setupCommands = appJson.setupInstructions;
    // setupCommands.push('npm install --save @layouts.dev/utils');
    setupCommands = sortSetupCommands(setupCommands);

    // Fonts
    let fontsImport = '';
    if (check.nonEmptyArray(appJson.fonts)) {

        const url = getGoogleFontsUrl(appJson.fonts.map(f => f.name));
        fontsImport = `
<link 
\trel="stylesheet"
\thref="${url}"
/> 
        `.trim();
    }

    // Top level content
    let topLevelContentCode = '';
    if (check.nonEmptyArray(appJson.topLevelContent)) {
        topLevelContentCode = appJson.topLevelContent.map(c => treeToJsx(c)).join('\n');
    }

    // Pages
    let pagesCode = '';
    if (check.nonEmptyArray(appJson.pages)) {
        pagesCode = appJson.pages.map(p => getComponentJsxForPage(p)).join('\n\n');
    }

    // Router code
    let routerCode = '';
    if (appHasOnlyHomePage) {
        routerCode = `<${getPageComponentName(appJson.pages[0])} />`;
    } else if (check.nonEmptyArray(appJson.pages)) {
        routerCode = `
<Router>
\t<Routes>
${appJson.pages.map(p => ('\t\t' + `
<Route path="${p.path}" element={<${getPageComponentName(p)} />} />
`.trim())).join('\n')}
\t</Routes>
</Router>
        `.trim();

        imports.push('import { BrowserRouter as Router, Route, Routes } from "react-router-dom";');
        setupCommands.push('npm install --save react-router-dom');
    }

    // Helmet code 
    let helmetCode = '';
    if (check.nonEmptyString(fontsImport)) {
        helmetCode = `
<Helmet>
${indent(fontsImport, 1)}
</Helmet>
        `.trim();

        imports.push('import { Helmet } from "react-helmet";');
        setupCommands.push('npm install --save react-helmet');
    };

    // App code
    let appCode = '';
    const appJsonContent = [
        helmetCode,
        topLevelContentCode,
        routerCode,
    ].filter(check.nonEmptyString);

    if (appJsonContent.length === 0) {
        appCode = `
export default function App() {
\t// Nothing here yet!
\treturn null;
}
        `.trim();
    } else if (appJsonContent.length === 1 && appHasOnlyHomePage) {
        pagesCode = '';
        appCode = `
export default function App() {
${indent(getComponentInnerJsxForPage(appJson.pages[0]), 1, '\t')}
}
        `.trim();
    } else if (appJsonContent.length > 1) {
        imports.push('import { Fragment } from "react";');
        appCode = `
export default function App() {
\treturn (
\t\t<>
${indent(appJsonContent.join('\n\n'), 3)}
\t\t</>
\t);
}
        `.trim();
    } else {
        appCode = `
export default function App() {
\treturn (
${indent(appJsonContent.join('\n\n'), 2)}
\t);
}
            `.trim();
    }

    // Final setup instructions
    const setupInstructions = check.nonEmptyArray(setupCommands) ? `
// ------------------------------------------------------------------------------------------------
// Project setup instructions
// ------------------------------------------------------------------------------------------------
${setupCommands.map(cmd => `// ${cmd}`).join('\n')}
// ------------------------------------------------------------------------------------------------`.trim() : '';

    // Full code
    const fullCode = [
        license,
        setupInstructions,
        mergeImportLines(imports).join('\n'),
        pagesCode,
        appCode,
    ].filter(check.nonEmptyString).join('\n\n');

    return fullCode;
}


//////////////////////////////////////////////////////////////////////////////
// NetxJS
////////////////////////////////////////////////////////////////////////////// 

const LICENSE = `
///////////////////////////////////////////////////////////////////////////////////////////////////
// Generated by Layouts.dev
// ------------------------------------------------------------------------------------------------
// Copyright Creative Robots Inc. ${new Date().getFullYear()} - All rights reserved
// https://layouts.dev
// ------------------------------------------------------------------------------------------------
// We are constantly improving the way this code is generated.
// Notice something wrong? Please let us know at hey@creative-robots.ai and we'll fix it!
///////////////////////////////////////////////////////////////////////////////////////////////////`.trim();

export type File = {
    path: string;
    content?: string;
    url?: string;
    setupCommands?: string[];
    hash?: string;
};

function fillFileHash(file: File): File {
    if(!check.nonEmptyObject(file)) {
        return file;
    }

    if (!check.nonEmptyString(file.content)) {
        return file;
    }

    return {
        ...file,
        hash: sha1(file.content),
    }
};

function getFontNames(fontName: string) {
    const parts = fontName.replaceAll('_', ' ').replaceAll('-', ' ').split(' ');
    return {
        original: fontName,
        nextJs : parts.map(ucfirst).join('_'),
        jsVar : parts.map(ucfirst).map((n: any, i: number) => {
            if (i === 0) {
                return n.toLowerCase();
            }
            return n;
        }).join(''),
    }
}

function removeNoTransitionClasses(c: ComponentTreeNode): ComponentTreeNode {
    if (!check.nonEmptyObject(c)) {
        return c;
    }

    return {
        ...c,
        classes: (c.classes || []).filter(cl => cl !== 'no-transition'),
        props: {
            ...c.props,
            className: (c.props?.className || '').split(' ').filter((cl: string) => cl !== 'no-transition').join(' '),
        },
        children: (c.children || []).map(removeNoTransitionClasses),
    };
}

function removeNoTransitionClassesOnChildren(c: ComponentTreeNode | ComponentTreeNode[] | null): ComponentTreeNode | ComponentTreeNode[] | null {
    if (!c) {
        return c;
    }

    if (check.array(c)) {
        return c.map(removeNoTransitionClassesOnChildren).filter(c => !!c).flat() as ComponentTreeNode[];
    }

    if (!check.object(c)) {
        return c;
    }

    return removeNoTransitionClasses(c);
} 

function getGoogleFonts(appJson: AppJson) {
    const fonts = appJson.fonts.map(f => f.name);
    const googleFonts = (fonts || [])
        .map(f => getFontNames(f))
        .filter(({ nextJs }) => isGoogleFont(nextJs));
    
    return googleFonts;
}

export function generateNextJSLayout(appJson: AppJson): File {
    const fonts = appJson.fonts.map(f => f.name);
    const nextJsNames: string[] = fonts.map(n => n.split('_').map(ucfirst).join('_'));

     // Top level content
     let topLevelContentCode = '';
     if (check.nonEmptyArray(appJson.topLevelContent)) {
        const withoutTheme = removeThemeComponents(appJson.topLevelContent) as ComponentTreeNode[];
        if (withoutTheme) {
            topLevelContentCode = withoutTheme.map((c: ComponentTreeNode) => treeToJsx(
                removeNoTransitionClasses(c)
            )).join('\n');
        }
     }

    const googleFonts = getGoogleFonts(appJson);
    console.log('Google fonts: ' + JSON.stringify(googleFonts, null, 4));

    let fontsImport = '';
    if (check.nonEmptyArray(appJson.fonts)) {
        const url = getGoogleFontsUrl(appJson.fonts.map(f => f.name));
        fontsImport = `
    <link 
    \trel="stylesheet"
    \thref="${url}"
    /> 
        `.trim();
    }

    let head = '';

    /*
    if (check.nonEmptyString(fontsImport)) {
        head = indent(`
<head>
${indent(fontsImport, 1)}
</head>
`, 1);
    }
    */

    let htmlClassName = '';
    if (check.nonEmptyArray(googleFonts)) {
        htmlClassName = ` className={\`${
            googleFonts.map(({ jsVar }) => `\${${jsVar}.variable}`).join(' ')
        }\`}`;
    }

    return {
        path: 'app/layout.tsx',
        setupCommands: sortSetupCommands([
            // 'npm install --save @layouts.dev/utils', // Useful for Theme, Data components
            // 'npm install --save @layouts.dev/utils.nextjs', // Useful for PageProvider
            ...(treeToSetupInstructions(appJson.topLevelContent, getParserConfig('nextjs')) || []),
        ]),
        content: `
${LICENSE}

import type { Metadata } from "next";
import "./globals.css";
import { appConfig } from "./config";
${(treeToImports(appJson.topLevelContent, getParserConfig('nextjs')) || []).join('\n')}
${nextJsNames.map((n, i) => `import { ${n} } from "next/font/google";`).join('\n')}
${
   check.nonEmptyArray(googleFonts) ? (
    '\n' +
    '// Load Google fonts server-side\n' +  
    googleFonts.map(({ jsVar, nextJs }) => {
        const config = getNextJSConfigForGoogleFont(nextJs) as Record<string, any>;
        return `
const ${jsVar} = ${nextJs}({
${Object.keys(config).filter(k => !!config[k]).map(k => `\t${k}: ${JSON.stringify(config[k])}`).join(',\n')}
});`.trim();
    }).join('\n') 
    + '\n\n'
    ) : ''
}

// Page metadata
export const metadata: Metadata = {
  title: appConfig.name,
  description: appConfig.description,
};

// Layout
export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <html lang="en"${htmlClassName}>${head}
        <body${appJson?.themeProps?.colorScheme === 'dark' ? ' className="dark"' : ''}>
${indent([
    topLevelContentCode,
].filter(check.nonEmptyString).join('\n\n'), 3)}
            {children}
        </body>
    </html>
  );
}        
`.trim().replace(/\n{3,}/g, '\n\n'),
    };
}

function removeThemeComponents(tree: ComponentTreeNode | ComponentTreeNode[]): ComponentTreeNode | ComponentTreeNode[] | null {
    if (check.nonEmptyArray(tree)) {
        return tree.map(t => removeThemeComponents(t)).flat().filter(t => !!t) as ComponentTreeNode[];
    }

    if (!check.object(tree)) {
        return tree;
    }

    if (['Theme', 'ThemeProvider'].includes(tree.htmlComponent)) {
        return null;
    }

    return {
        ...tree,
        children: (tree.children || []).map(t => removeThemeComponents(t)).flat(),
    };
}

function treeHasFunctionsInProps(tree: ComponentTreeNode | ComponentTreeNode[]): boolean {
    if (check.nonEmptyArray(tree)) {
        return tree.some(t => treeHasFunctionsInProps(t));
    }

    if (!check.object(tree)) {
        return false;
    }

    if (!check.nonEmptyObject(tree.props)) {
        return false;
    }

    const inProps = Object.values(tree.props).some(v => isSerializedFunction(v));
    if (inProps) {
        return true;
    }

    return (tree.children || []).some(t => treeHasFunctionsInProps(t));
}

function generateNextJSPages(pageJson: PageJson): File {
    const pageWithoutTheme = { 
        ...pageJson, 
        imports: pageJson.imports.filter(i => /* (!i.includes('@layouts.dev/utils') || */ (!i.includes('Theme'))),
        children: removeNoTransitionClassesOnChildren(removeThemeComponents(pageJson.children)),
    } as PageJson;
    const isClientPage = treeHasFunctionsInProps(pageWithoutTheme.children);
    console.log('treeHasFunctionsInProps: ', {
        tree: pageWithoutTheme.children,
        result: isClientPage,
    })
    const jsx = getComponentJsxForPage(pageWithoutTheme, true);
    const imports = pageJson.imports;

    return {
        path: `app${pageJson.path}/page.tsx`.replaceAll('//', '/'),
        setupCommands: treeToSetupInstructions(pageWithoutTheme.children, getParserConfig('nextjs')),
        content: `
${LICENSE}

${isClientPage ? '"use client";\n' : ''}${imports.join('\n')}

${jsx}

`.trim(),
    };
}

function getCreditsFile(assets: Asset[]): File | null {
    if (!check.nonEmptyArray(assets)) {
        return null;
    }

    return {
        path: 'public/assets/CREDITS.md',
        content: `
# Image credits

${assets.map(a => `
## ${a.name}
- [View](${a.url})
- ${a.credits}
`).join('\n')}
        `.trim(),
    };
}

const generateGlobalsCss = (themeVars: { default: string[], dark: string[] }) => `
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
\t\t${themeVars.default.join(';\n\t\t')}
    }

    .dark {
\t\t${themeVars.dark.join(';\n\t\t')}
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }
}
`.trim();

function replaceFontClassnames(item: ComponentTreeNode | ComponentTreeNode[], fontVars: { original: string, twName: string, varName: string }[]) : ComponentTreeNode | ComponentTreeNode[] {
    if (check.array(item)) {
        return item.map(i => replaceFontClassnames(i, fontVars)).flat();
    }

    if (!check.object(item)) {
        return item;
    }

    const parseClass = (c: string) => {
        if (!check.nonEmptyString(c)) {
            return c;
        }
        if (c.startsWith('font-[\'') && c.endsWith('\']')) {
            const originalName = c.replace('font-[\'', '').replace('\']', '');
            const font = fontVars.find(f => f.original.trim().toLowerCase() === originalName.trim().toLowerCase());
            if (font) {
                return `font-${font.twName}`
            }
        }
        return c;
    };

    let newItem = {...item};
    if (check.nonEmptyArray(item.classes)) {
        newItem.classes = item.classes.map(parseClass);
    }
    if (check.nonEmptyString(item?.props?.className)) {
        newItem.props.className = item.props.className.split(' ').map(parseClass).join(' ');
    }

    if (check.nonEmptyArray(item.children)) {
        newItem.children = item.children.map(c => replaceFontClassnames(c, fontVars));
    }

    return newItem;
}

export async function appJsonToNextJSRepository(appJson: AppJson) {
    // Setup instructons
    let setupCommands = appJson.setupInstructions;

    // Theme
    const themeVars = await generateThemeCssVars(appJson.themeProps);

    // Fonts
    const googleFonts = getGoogleFonts(appJson);
    const fontVars = googleFonts.map(f => ({
        original: f.original,
        ...getTailwindFontFamiliesForGoogleFont(f.nextJs)
    }));
    let pages = appJson.pages;
    // Handle className replacement for fonts
    if (check.nonEmptyArray(googleFonts)) {
        pages = pages.map(p => {
            const newChildren = (replaceFontClassnames(p.children, fontVars) as ComponentTreeNode[]).flat();
            return {
                ...p,
                children: newChildren,
            };
        });
    };

    // Generate files
    const files : File[] = ([
        generateNextJSLayout(appJson),
        ...pages.map(generateNextJSPages),
        ...appJson.assets.map(a => ({
            path: `public/assets/${a.name}`,
            url: a.url,
        })),
        getCreditsFile(appJson.assets),
        {
            path: 'app/globals.css',
            content: generateGlobalsCss(themeVars),
        },
        {
            path: 'tailwind.config.ts',
            content: generateTailwindConfig(fontVars)
        }
    ].filter(Boolean) as File[]).map(fillFileHash);

    // Final setup instructions
    const setupInstructions = [
        ...(setupCommands || []),
        ...files.map(f => f.setupCommands || []).flat(),
    ];

    // Return JSON content
    return {
        files, 
        setupCommands: setupInstructions,
    };
}