import { isHslColor, parseColorToHex, parseRadiusValue } from "@/lib/utils";
import check from "@/vendors/check";
import { generateSingleColorFamilyClasses } from "@/vendors/colors/palette-generator";
import { defaultColors, defaultFonts } from "@/vendors/theme/generate/css";
import { sha1 } from "object-hash";
import tinycolor from "tinycolor2";
import * as culori from 'culori';
import 'isomorphic-fetch';


export type ThemeProps = {
    /* 
    @colorScheme="light" // Dark or light mode
    @radius="0.5rem" // Border radius for card, input, and buttons
    // Colors
    @background="0 0% 100%" // Default background color of <body />...etc
    @foreground="222.2 47.4% 11.2%" // Foreground color
    @muted="210 40% 96.1%" // Muted backgrounds such as <TabsList />, <Skeleton /> and <Switch />
    @mutedForeground="215.4 16.3% 46.9%" // Muted foreground color
    @card="0 0% 100%" // Background color for <Card />
    @cardForeground="222.2 47.4% 11.2%" // Card foreground color
    @popover="0 0% 100%" // Background color for popovers such as <DropdownMenu />, <HoverCard />, <Popover />
    @popoverForeground="222.2 47.4% 11.2%" // Popover foreground color
    @border="214.3 31.8% 91.4%" // Default border color
    @input="214.3 31.8% 91.4%" // Border color for inputs such as <Input />, <Select />, <Textarea />
    @primary="222.2 47.4% 11.2%" // Primary color for <Button />
    @primaryForeground="210 40% 98%" // Primary foreground color for <Button />
    @secondary="210 40% 96.1%" // Secondary colors for <Button />
    @secondaryForeground="222.2 47.4% 11.2%" // Secondary foreground color for <Button />
    @accent="210 40% 96.1%" // Used for accents such as hover effects on <DropdownMenuItem>, <SelectItem>...etc
    @accentForeground="222.2 47.4% 11.2%" // Accent foreground color
    @destructive="0 100% 50%" // Used for destructive actions such as <Button variant="destructive">
    @destructiveForeground="210 40% 98%" // Destructive foreground color
    @ring="215 20.2% 65.1%" // Used for focus ring
    */

    // Variant === Preset
    variant?: string;
    preset?: string;

    // Global vars
    palette?: string;
    radius?: string;

    // Theme mode 
    colorScheme?: string;

    // Colors
    background?: string;
    foreground?: string;
    muted?: string;
    mutedForeground?: string;
    card?: string;
    cardForeground?: string;
    popover?: string;
    popoverForeground?: string;
    border?: string;
    input?: string;
    primary?: string;
    primaryForeground?: string;
    secondary?: string;
    secondaryForeground?: string;
    accent?: string;
    accentForeground?: string;
    destructive?: string;
    destructiveForeground?: string;
    ring?: string;

    // Text colors
    textColorPrimary?: string;
    textColorSecondary?: string;
    textColorTertiary?: string;

    // Fonts:
    displayFont?: string;
    bodyFont?: string;
}

export const THEME_PROP_TO_CSS_VAR : {[key:string]: string}  = {
    radius: '--radius',
    background: '--background',
    foreground: '--foreground',
    muted: '--muted',
    mutedForeground: '--muted-foreground',
    card: '--card',
    cardForeground: '--card-foreground',
    popover: '--popover',
    popoverForeground: '--popover-foreground',
    border: '--border',
    input: '--input',
    primary: '--primary',
    primaryForeground: '--primary-foreground',
    secondary: '--secondary',
    secondaryForeground: '--secondary-foreground',
    accent: '--accent',
    accentForeground: '--accent-foreground',
    destructive: '--destructive',
    destructiveForeground: '--destructive-foreground',
    ring: '--ring',
    displayFont: '--font-display',
    bodyFont: '--font-body',

    textColorPrimary: '--textcolor-primary',
    textColorSecondary: '--textcolor-secondary',
    textColorTertiary: '--textcolor-tertiary',
};


function isQuoted(str: string) {
    return (
        (str.startsWith('"') && str.endsWith('"'))
        || (str.startsWith("'") && str.endsWith("'"))
    );
}

function parseFontValue(rawValue: any) : string | null {
    if (!check.nonEmptyString(rawValue) || !check.nonEmptyString(rawValue.trim())) {
        return null;
    }

    let value = rawValue.trim();

    if (value.includes(',')) {
        const out = value.split(',').map((part: string) => parseFontValue(part.trim())).filter(p => !!p);
        if (!check.nonEmptyArray(out)) {
            return null;
        }
        return out.join(', ');
    }

    if (value.includes(' ') && !isQuoted(value)) {
        return `"${value}"`;
    }

    return value;
}

function parsePropValue(propName: string, value: any, knownColors: { [key: string]: string } = {}) {
    if (!check.nonEmptyString(value)) {
        return null;
    }

    if (propName.toLowerCase().includes('font')) {
        return parseFontValue(value);
    }

    if (propName === 'colorScheme') {
        if (!['light', 'dark'].includes(value.trim().toLowerCase())) {
            return null;
        }
        return value.trim().toLowerCase();
    }

    if (propName === 'radius') {
        return parseRadiusValue(value);
    }

    // Key is color, parse color value and return as HSL in the form of `h s l`
    try {
        if (isHslColor(value)) {
            return value;
        }

        const colorValue = parseColorToHex(value, knownColors);
        if (!colorValue) {
            return null;
        }

        const color = tinycolor(colorValue);
        const hsl = color.toHsl();
        return `${hsl.h} ${hsl.s * 100}% ${hsl.l * 100}%`;
    } catch(err) {
        console.error('ERROR parsing color "'+value+'"', err);
        return null;
    }
}

export function applyFontsToTypefaceVars(styles: string[]) {
    let out = [...styles];

    const displayFont = styles.find((style) => style.startsWith('--font-display'));
    const bodyFont = styles.find((style) => style.startsWith('--font-body'));

    if (displayFont) {
        const displayFontValue = displayFont.split(':')[1].trim();
        out.push(`--typeface-display-family: ${displayFontValue}`);
    }
    if (bodyFont) {
        const bodyFontValue = bodyFont.split(':')[1].trim();
        out.push(`--typeface-body-family: ${bodyFontValue}`);
    }

    return out;
}

export function mergeStyles(items: string[]) {
    const out : Record<string, string> = {};
    items.forEach((item) => {
        const [key, value] = item.split(':').map((s) => s.trim());
        out[key] = value;
    });

    return Object.keys(out).sort().map((key) => `${key}: ${out[key]}`);
}

export function generateBodyStyle(props: ThemeProps, vars: {[key:string]: string}) {
    const addedStyles :string[] = [];

    // Build array of know colors we can pass to the props parser
    const knownColors : {[key:string]: string} = {};
    for (const key in props) {
        if (THEME_PROP_TO_CSS_VAR.hasOwnProperty(key)) {
            knownColors[THEME_PROP_TO_CSS_VAR[key].substring(2)] = (props as any)[key];
        }
    }
    for (const key in vars) {
        knownColors[key.substring(2)] = vars[key];
    } 

    // Add the new theme vars
    for (const key in props) {
        // Make sure prop key exists
        if (!THEME_PROP_TO_CSS_VAR.hasOwnProperty(key)) {
            continue;
        }

        // Parse value
        let val = parsePropValue(key, (props as any)[key], knownColors);
        if(!val) {
            continue;
        }

        // Add to style
        addedStyles.push(`${THEME_PROP_TO_CSS_VAR[key]}: ${val}`);
    }

    // Add additional vars
    for (const key in vars) {
        addedStyles.push(`${key}: ${vars[key]}`);
    }

    // Create color variants for colors
    ['background', 'primary', 'secondary', 'destructive', 'muted', 'accent', 'popover', 'card'].forEach(color => {
        // Check if a style for this color was added
        if (!props.hasOwnProperty(color)) {
            return;
        }
        const cssKey = THEME_PROP_TO_CSS_VAR[color];
        const hasStyle = addedStyles.some((style) => style.startsWith(cssKey));
        if (!hasStyle) {
            return;
        }

        // Get the color from the theme
        const colorValue = parseColorToHex((props as any)[color], knownColors);
        if (!colorValue) {
            return;
        }

        // Generate a palette for this color
        const family = generateSingleColorFamilyClasses(color, colorValue);
        if (!check.nonEmptyObject(family)) {
            return;
        }

        // Add styles to the added styles
        for (const key in family) {
            addedStyles.push(`--${key}: ${family[key]}`);
        }
    });

    return mergeStyles(applyFontsToTypefaceVars(addedStyles));
}

function paletteCacheKey(name: string) {
    return 'palette::'+ sha1(name.trim().toLowerCase());
}

let localCache : Record<string, any> = {};

function cacheStore() {
    if (typeof localStorage === 'undefined') {
        return localCache;
    }
    return localStorage;
}

function setPaletteCache(name: string, value: any) {
    cacheStore()[paletteCacheKey(name)] = JSON.stringify(value);
}


function getPaletteCache(name: string) {
    let val = cacheStore()[paletteCacheKey(name)];
    if (!check.nonEmptyString(val)) {
        return null;
    }
    try {
        return JSON.parse(val);
    } catch(err) {
        return null;
    }
}


export async function fetchPalette(palette: string) {
    if (!check.nonEmptyString(palette) || !check.nonEmptyString(palette.trim())) {
        return {
            props: {},
            vars: {},
        }
    }

    const cached = getPaletteCache(palette);
    if (cached) {
        return cached;
    }

    const variantTxt = palette.split(' ').find(txt => /^%[0-9]+$/g.test(txt))?.substring(1);
    const variant = variantTxt ? parseInt(variantTxt) : 0;
    const description = palette.split(' ').filter(txt => !/^%[0-9]+$/g.test(txt)).join(' ');
    const url = `https://palette.layouts.dev/?description=${encodeURIComponent(description)}&variant=${variant}`;
    const res = await fetch(url);
    const data = await res.json();
    
    if (!check.nonEmptyArray(data?.colors)) {
        return;
    }

    // Generate new props
    const paletteProps : ThemeProps = {};
    const paletteVars : {[key:string]: string} = {};
    data.colors.forEach((color: any) => {
        // Set color
        if (
            check.nonEmptyString(color.name) 
            && check.nonEmptyString(color.hsl) 
            && THEME_PROP_TO_CSS_VAR.hasOwnProperty(color.name)
        ) {
            (paletteProps as any)[color.name] = color.hsl;                        
        }

        // Set color foreground
        if (
            check.nonEmptyString(color.name) 
            && check.nonEmptyString(color?.foreground?.hsl)
        ) {
            if (color.name === 'background') {
                (paletteProps as any)[`foreground`] = color.foreground.hsl;
            } else if (THEME_PROP_TO_CSS_VAR.hasOwnProperty(`${color.name}Foreground`)) {
                (paletteProps as any)[`${color.name}Foreground`] = color.foreground.hsl;
            }
        }

        // Set additional color vars
        if (check.nonEmptyObject(color?.family)) {
            Object.keys(color.family).forEach((key) => {
                paletteVars['--' + key] = color.family[key];
            });
        }
    });

    const out = { 
        props: paletteProps,
        vars: paletteVars,
    };

    console.log('Palette out:', out)

    setPaletteCache(palette, out);
    return out;
}

export function fetchInstantPalette(palette: string) {
    if (!check.nonEmptyString(palette) || !check.nonEmptyString(palette.trim())) {
        return {
            props: {},
            vars: {},
        }
    }
    return getPaletteCache(palette);
}

function convertToDarkMode(color: string) {
    if (!isHslColor(color)) {
        return color;
    }

    const [h, s, l] = color.split(' ');
    const newL = parseFloat(l.replace('%', ''));

    return `${h} ${s} ${100 - newL}%`;

    /*
    let parsed = parseColorToHex(color);
    if (!parsed) {
        return null;
    }

    // Convert the input color to the LCH color space
    const lchColor = culori.lch(tinycolor(parsed).toRgbString());
    if (!lchColor || !lchColor.l || !lchColor.c || !lchColor.h) {
        return null;
    }
    
    // Invert the lightness and ensure it stays within bounds (0 to 100)
    const invertedLightness = 100 - lchColor.l;
    const boundedLightness = Math.max(0, Math.min(100, invertedLightness));
    
    // Construct the dark mode LCH color
    const darkModeLchColor = {
        l: boundedLightness,
        c: lchColor.c,
        h: lchColor.h
    };
    
    // Convert the LCH color back to a hex string
    const darkModeColor = culori.formatHex(darkModeLchColor as any);
    const tiny = tinycolor(darkModeColor);
    const hsl = tiny.toHsl();
    return `${hsl.h} ${hsl.s * 100}% ${hsl.l * 100}%`;
    */
}

export async function generateThemeCssVars(props: ThemeProps) {
    const themeVals = {
        props: defaultThemeProps(),
        vars: {},
    };

    const { palette, ...otherProps } = props;

    if (check.nonEmptyString(palette)) {
        const paletteVals = await fetchPalette(palette);
        themeVals.props = { ...themeVals.props, ...(paletteVals.props || {}) };
        themeVals.vars = { ...themeVals.vars, ...(paletteVals.vars || {}) };
    }

    themeVals.props = { ...themeVals.props, ...(otherProps || {}) };

    // Generate body style
    const styles = generateBodyStyle(themeVals.props, themeVals.vars);


    // Create dark mode values
    const darkStyles = styles.map((style) => {
        const [key, value] = style.split(':').map((s) => s.trim());
        if (!isHslColor(value.trim())) {
            return style;
        }

        const darkValue = convertToDarkMode(value.trim());
        if (!darkValue) {
            return style;
        }

        return `${key}: ${darkValue}`;
    }).filter((style) => !!style);

    return {
        default: styles,
        dark: darkStyles,
    };
}

function defaultThemeProps() {
    const defaultVars : Record<string, string> = {
        ...defaultColors,
        ...defaultFonts,
    };

    const defaultProps : Record<string, string> = {
        colorScheme: 'light',
    };

    Object.keys(defaultVars).forEach((key) => {
        const matchingPropKey = Object.keys(THEME_PROP_TO_CSS_VAR).find((propKey) => THEME_PROP_TO_CSS_VAR[propKey] === ('--' + key));
        if(!matchingPropKey) {
            console.warn('[Theme] No matching theme prop for default key:', key);
            return;
        }

        let val : string | null = defaultVars[key];
        if (isHslColor(val)) {
            // val = parseColorToHex(val);
        }

        if (val) {
            defaultProps[matchingPropKey] = val;
        }
    });

    console.log('[Theme] Default props:', defaultProps)

    return defaultProps;
}