import { createContext, useCallback, useMemo, useState } from "react";
import { themePresets } from "./presets";
import check from "@/vendors/check";
import { useAppliedBodyStyle } from "./hooks/useAppliedBodyStyle";
import { getGoogleFontsUrl } from "./google-fonts";
import { Helmet } from "react-helmet";

import './theme.css';
import { Theme } from "@/components/layouts-ui/Theme";

export type ThemeContextType = {
    rotatePreset : () => void,
    setPreset: (preset: string) => void,
    classesPerComponent: { [key: string]: any },
    availablePresets: string[]
};

const availablePresets = Object.keys(themePresets);

export const ThemeContext = createContext<ThemeContextType>({
    rotatePreset: () => {},
    setPreset: () => {},
    classesPerComponent: {},
    availablePresets,
});

export default function LayoutsThemeProvider({ preset, children } : { preset: string, children: any }) {
    const [currentPreset, setCurrentPreset] = useState<string>(preset);

    const rotatePreset = useCallback(() => {
        setCurrentPreset((prev) => {
            const keys = Object.keys(themePresets);
            const currentIndex = keys.indexOf(prev);
            const nextIndex = (currentIndex + 1) % keys.length;
            return keys[nextIndex];
        });
    }, []);

    const value = useMemo(() => {
        const classesPerComponent = currentPreset && themePresets[currentPreset] && themePresets[currentPreset].components ? themePresets[currentPreset].components : {};
        return { rotatePreset, setPreset: setCurrentPreset, classesPerComponent, availablePresets };
    }, [currentPreset, rotatePreset, setCurrentPreset]);

    const presetObj = useMemo(() => {
        return (currentPreset && check.nonEmptyObject(themePresets[currentPreset])) ? themePresets[currentPreset] : {};
    }, [currentPreset]);

    

    // Apply the theme CSS vars to the body
    const bodyStyles = useMemo(() => {
        return Object.keys(presetObj.cssVars || {}).map((key) => {
            return `${key}: ${presetObj.cssVars[key]}`;
        });
    }, [presetObj?.cssVars]);
    useAppliedBodyStyle(bodyStyles);

    // Add font styles if any
    let fontUrl = '';
    if (check.nonEmptyArray(presetObj.fonts)) {
        fontUrl = getGoogleFontsUrl(presetObj.fonts as string[]);
    }

    console.log('Theme: ', {
        currentPreset,
        presetObj,
        bodyStyles,
        fontUrl
    })

    console.log('Google font url: ', fontUrl)

    return (
        <>
            <Helmet>
                <link id="layouts-theme" rel="stylesheet" href={fontUrl} />
            </Helmet>
            <ThemeContext.Provider value={value}>
                <>
                    {/* Apply colors */}
                    <Theme {...presetObj?.colors || {}} />

                    {children}
                </>
            </ThemeContext.Provider>
        </>
        
    );
}