import { bulletProofJSONParse } from "@/vendors/bulletproof-json";
import { Bar, BarChart, Line, LineChart, ResponsiveContainer } from "recharts";
import { imports, importsRaw, isTextComponent, normalizeTextComponent, setup } from "../shadcn/_utils";
import { cn } from "@/lib/utils";
import check from "@/vendors/check";
import { ComponentTreeNode } from "../component.type";
import { registerComponent, registerShadcnComponent } from "../../importsRegistry";
import { setuid } from "process";

const VARIANTS = ['bar', 'line'];

const getChartData = (c: any) => {
    const returnData = (data: any) => {
        if(!check.array(data)) {
            return [];
        }

        if (check.object(data[0])) {
            return data;
        }
        return data.map((d) => ({ default: d }));
    };

    if (check.array(c.data)) {
        return returnData(c.data);
    }

    if (check.nonEmptyString(c.text)) {
        const data = bulletProofJSONParse(c.text);
        if (check.array(data)) {
            return returnData(data);
        }
    }

    if (check.nonEmptyArray(c.children)) {
        const firstChild = c.children[0];
        if (isTextComponent(firstChild)) {
            const text = normalizeTextComponent(firstChild)?.text;
            if (check.nonEmptyString(text)) {
                const data = bulletProofJSONParse(text);
                if (check.array(data)) {
                    return returnData(data);
                }
            }
        }
    }

    return [];
}

const ChartComponentParser = {
    tags: ['chart'],
    name: 'Chart',
    description: 'Chart component',
    variants: VARIANTS,
    refImplementation: '/chart %bar [10, 20, 30, 50, 80, 110, 150] size-52',
    parseTree: (c: ComponentTreeNode) => {
        
        /* 
        <div className="mt-3 h-[120px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={data}>
                  <Bar
                    dataKey="goal"
                    style={
                      {
                        fill: "hsl(var(--foreground))",
                        opacity: 0.9,
                      } as React.CSSProperties
                    }
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
        */

        const data = getChartData(c);
        if (data.length === 0) {
            return null;
        } 

        const classes = cn('h-[120px]', c.classes).split(' ');

        let wrapperComponent = [BarChart, 'BarChart'];
        let lineComponent : [any, string] = [Bar, 'Bar'];
        if (c.variant === 'line') {
            wrapperComponent = [LineChart, 'LineChart'];
            lineComponent = [Line, 'Line'];
        }

        return {
            component: 'div',
            htmlComponent: 'div',
            classes,
            props: {
                className: classes.join(' '),
            },
            children: [
                {
                    component: ResponsiveContainer,
                    htmlComponent: 'ResponsiveContainer',
                    props: {
                        width: '100%',
                        height: '100%'
                    },
                    children: [
                        {
                            component: wrapperComponent[0],
                            htmlComponent: wrapperComponent[1],
                            props: {
                                ...(c.props || {}),
                                data,
                            },
                            children: [
                                {
                                    component: lineComponent[0],
                                    htmlComponent: lineComponent[1],
                                    props: {
                                        dataKey: 'default',
                                        ...(!!c.props.fill ? {} : {
                                            style: c.variant !== 'line' ? {
                                                fill: 'hsl(var(--foreground))',
                                                opacity: 0.9
                                            } : {},
                                        }),
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    },
    imports: imports(['BarChart', 'LineChart', 'Bar', 'Line', 'ResponsiveContainer'], 'recharts'),
    setup: setup('recharts'),
};

registerShadcnComponent({
    BarChart,
    LineChart,
    Bar: Bar as any,
    Line,
    ResponsiveContainer,
}, 'recharts');

/*
registerComponent('BarChart', BarChart, 'recharts');
registerComponent('LineChart', LineChart, 'recharts');
registerComponent('Bar', Bar as any, 'recharts');
registerComponent('Line', Line, 'recharts');
registerComponent('ResponsiveContainer', ResponsiveContainer, 'recharts');
*/

export { ChartComponentParser };