import { Player } from "@/components/player";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/shadcn/ui/resizable";
import { addIndent } from "@/lib/indents";
import { allUniqueComponentsAsArray } from "@/lib/parser/components";
import { ComponentParser } from "@/lib/parser/components/component.type";
import { usageExamplesForTags } from "@/lib/parser/doc/examples/examples";
import check from "@/vendors/check";
import ucfirst from 'ucfirst';

function componentCode (component: ComponentParser) {
    const componentName = component.name || ucfirst(component.tags[0]);
    const componentTag = component.tags[0];

    let code = `\t/div bg-white text-black p-3.5 rounded gap-4 flex flex-col\n`;
    code += `\t\t/h2 ${componentName} font-medium\n`;
    code += `\t\t/separator\n`;
    code += `\t\t/div relative w-full\n`
    // code += `\t\t/${componentTag}>>>\n`;

    code += `\t\t/h3 Reference implementation font-medium\n`;
    code += `\t\t/div relative w-full\n`;
    if (check.nonEmptyString(component.refImplementation)) {
        code += addIndent(
            component.refImplementation.replaceAll(/ {2}/g, '\t'), 
            3,
        );
    } else {
        code += `\t\t\t/p text-sm italic No reference implementation available\n`;
    }

    const examples = usageExamplesForTags(component.tags).filter(check.nonEmptyObject);

    if (check.nonEmptyArray(examples)) {
        code += `\n\n\t\t/h3 Examples font-medium mt-4\n`;
        code += `\t\t/div flex flex-col gap-4\n`;

        examples.forEach((example: any) => {
            code += `\t\t\t/div bg-gray-100 p-4 rounded flex flex-col gap-2\n`;
            code += `\t\t\t\t/h4 ${example.name}\n`;
            code += `\t\t\t\t/div flex flex-col gap-2\n`;

            if (example.works && check.nonEmptyString(example.code)) {
                code += addIndent(
                    example.code.replaceAll(/ {2}/g, '\t'), 
                    4,
                ) + '\n\n';
            } else {
                code += `\t\t\t\t/p text-sm italic ❌ Doesn't work\n`;
            }
        });
    }

    return code + '\n';
}

const headerCode = `
// ////////////////////////
// Sample page
// ////////////////////////
/div flex flex-col p-11 gap-8 bg-slate-900 text-white
\t/h1 Components test page     

    `.trim() + '\n\t\n';

export default function ComponentsTestPlayer() {
    const components = allUniqueComponentsAsArray().filter(c => !c.tags.includes('theme') && !c.tags.includes('bg-pattern')); // Exclude theme to make sure we see components in their default theme
    const testCode = headerCode + components.map(componentCode).join('\n');

    return (
        <Player pageId={'test'} src={testCode} fillScreen={false} />
    );
}