import * as React from "react"
import { registerComponentVariant } from "../../../lib/components-registry";
import { VariantProps, cva } from "class-variance-authority";
import { cn } from "../../../lib/utils";
import { useComposableApi } from "../../../lib/composable";


const CardVariants = cva("",{ 
    variants: {
        size: {
            "lg": "",
        },
        rounded: {
            'none': "rounded-none",
            "xs": "rounded-xs",
            "sm": "rounded-sm",
            "md": "rounded-md",
            "lg": "rounded-lg",
            "xl": "rounded-xl",
            "2xl": "rounded-2xl",
            "3xl": "rounded-3xl",
            "full": "rounded-full",
        }
    },
    defaultVariants: {
        size: "lg",
        rounded: 'xl',
    },
});

interface CardProps extends VariantProps<typeof CardVariants> {
    children?:React.ReactNode;
    aspectRatio?:string;
}

function Card_B({
    rounded,
    children,
    aspectRatio='16/9',
} : CardProps ) {
    const { ChildWithRole, ChildrenWithRole } = useComposableApi(children);
    if (aspectRatio && aspectRatio == 'square') {
        aspectRatio = '1/1';
    }
    return (
        <div className="w-[960px] h-[359px] px-4 py-10 justify-start items-start gap-8 inline-flex ">
            <ChildWithRole
                role="image"
                className={cn(CardVariants({rounded}), `aspect-${aspectRatio}`,"relative object-cover w-[496px] h-[279px]")}
            ></ChildWithRole>

            <div className="w-[400px] h-[279px] flex-col justify-center items-start gap-8 inline-flex">
                <div className="w-[400px] h-[152px] flex-col justify-start items-start flex">
                    <div className="w-[400px] h-[152px] flex-col justify-start items-start gap-2 flex">
                        <div className="w-[400px] h-[120px] flex-col justify-start items-start flex">
                            <div className="self-stretch h-[120px] flex-col justify-start items-start flex">
                                <ChildWithRole
                                    role="title"
                                    className="text-zinc-900 typeface-display-6xl"
                                ></ChildWithRole>
                            </div>
                        </div>
                        <div className="w-[400px] h-6 flex-col justify-start items-start flex">
                            <div className="self-stretch h-6 flex-col justify-start items-start flex">
                                <ChildWithRole
                                    role="description"
                                    className="text-zinc-900 typeface-body-normal truncate"
                                ></ChildWithRole>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-[400px] h-12 justify-start items-start inline-flex">
                    <ChildWithRole
                        role="button"
                        className="typeface-body-normal min-w-[134.41px] justify-center items-center flex text-white"
                    ></ChildWithRole>
                </div>
            </div>
        </div>
    )
}

registerComponentVariant({
    reactComponent: Card_B as React.ComponentType,
    name: "Card",
    description: "A card component",
    variantName: "buttonCard",
    variantDescription: "A card with a button",
    acceptsProps: ['image', 'title', 'description', 'buttonLabel'],
    acceptsChildren: false,
    acceptSizes: ['lg'],
    acceptOrientations: ['east'],
    acceptsRoundness: ['md'],
    acceptsAspectRatios: ['16/9']
})

export default Card_B;