import { cn } from "@/lib/utils";

export default function CenteredPageLayout({ className, children }: { className?: string, children: React.ReactNode }) {
    return (
        <div className={cn(
            "flex flex-col items-center justify-center min-h-screen h-full bg-[#F4F3F1] text-[#5B5E66]",
            className,
        )}>
            {children}
        </div>
    );
}