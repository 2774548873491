import check from "../check";

export function booleanParser(defaultValue: boolean) : (v: any) => boolean {
    return (value: any) => {
        if (value === undefined || value === null) {
            return defaultValue;
        }

        if (value === 0 || value === false) {
            return false;
        }

        if (check.nonEmptyString(value)) {
            if (['0', 'false', 'no', 'off'].includes(value.trim().toLowerCase())) {
                return false;
            }
        }

        return !!value;
    }
}

export function numberParser(defaultValue: number) : (v: any) => number {
    return (value: any) => {
        if (value === undefined || value === null) {
            return defaultValue;
        }

        let parsed = Number(value);
        return !isNaN(parsed) ? parsed : defaultValue;
    }
}

export function integerParser(defaultValue: number) : (v: any) => number {
    return (value: any) => {
        if (value === undefined || value === null) {
            return defaultValue;
        }

        if (check.integer(value)) {
            return value;
        }

        let parsed = parseInt(value);
        return !isNaN(parsed) ? parsed : defaultValue;
    }
}

export function enumParser(...options: any[]) : (v: any) => any {
    return (value: any) => {
        if (value === undefined || value === null) {
            return options[0];
        }

        const match = options.find((o) => {
            if (check.nonEmptyString(value)) {
                if (check.nonEmptyString(o)) {
                    return o.trim().toLowerCase() === value.trim().toLowerCase();
                }

                if (check.number(o)) {
                    return o+'' === value.trim();
                }

                if (check.boolean(o)) {
                    return o+'' === value.trim().toLowerCase();
                }

                return false;
            }

            return o === value;
        });

        if (match !== undefined) {
            return match;
        }

        return options[0];
    }
}

export function parseObject(value: any, propsParsers: { [propName: string]: (value: any) => any } ) : { [propName: string]: any } {
    if (check.nonEmptyString(value)) {
        try {
            parseObject(JSON.parse(value), propsParsers);
        } catch (e) {
            return {};
        }
    }

    if (!check.nonEmptyObject(value)) {
        return {};
    }

    let parsed : { [propName: string]: any } = {};
    for (let propName in value) {
        if (check.function(propsParsers[propName])) {
            parsed[propName] = propsParsers[propName](value[propName]);
        }
    }

    return parsed;
}