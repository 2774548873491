import check from "check-types";
import { ComponentParser, ComponentTreeNode } from "../component.type";
import { Button } from "react-day-picker";
import { getIconComponent, getImageComponent } from "../base/images";
import { getParserConfig } from "../../parser-config";
import { cn, nonEmptyTrimmed } from "@/lib/utils";

const componentToRole = new Map();
componentToRole.set(Button, 'button');
componentToRole.set('button', 'button');
componentToRole.set('h1', 'title');
componentToRole.set('iconof', 'icon');
componentToRole.set('img', 'image');
componentToRole.set('imageof', 'image');
componentToRole.set('image', 'image');

export function addRoles(children: any) : ComponentTreeNode[] {
    if(!check.nonEmptyArray(children)) {
        return [];
    }

    return children.map((c: ComponentTreeNode) => {
        if (!check.nonEmptyObject(c)) {
            return c;
        }

        let role = null;
        if (componentToRole.has(c.component)) {
            role = componentToRole.get(c.component);
        } else if (componentToRole.has(c.htmlComponent?.toLowerCase())) {
            role = componentToRole.get(c.htmlComponent?.toLowerCase());
        }

        if (!role) {
            return c;
        }

        return {
            ...c,
            props: {
                ...(c.props || {}),
                role: c.props.role || role,
            },
        };
    });
}

// FIXME: Declare globally? Merge with another existing type?
export type ParserFunc = (c: ComponentTreeNode) => ComponentTreeNode | null;

function withTextAsFirstChild(c: ComponentTreeNode) : ComponentTreeNode {
    if (nonEmptyTrimmed(c.text)) {
        return {
            ...c,
            children: [
                c.text,
                ...(c.children || [])
            ]
        }
    }

    return c;
}

function className(classes: string[]) : { className: string | undefined } {
    const str = cn(classes).trim();
    if (!nonEmptyTrimmed(str)) {
        return { className: undefined };
    }
    return { className: str };
}

const DEFAULT_PARSERS : Record<string, ParserFunc>= {
    'title': (c : ComponentTreeNode) => {
        const classes =  cn(c.classes).split(' ').filter(check.nonEmptyString);
        return withTextAsFirstChild({
            ...c,
            component: 'h1',
            htmlComponent: 'h1',
            classes,
            props: { role: 'title', ...className(classes)},
        });
    },
    'description': (c : ComponentTreeNode) => {
        const classes =  cn(c.classes).split(' ').filter(check.nonEmptyString);
        return withTextAsFirstChild({
            ...c,
            component: 'p',
            htmlComponent: 'p',
            classes,
            props: { role: 'description', ...className(classes) },
        })
    },
    'tagLine': (c : ComponentTreeNode) => {
        const classes =  cn(c.classes).split(' ').filter(check.nonEmptyString);
        return withTextAsFirstChild({
            ...c,
            component: 'span',
            htmlComponent: 'span',
            classes,
            props: { role: 'tagline', ...className(classes) },
        });
    },
    'image': (c : ComponentTreeNode) => {
        const classes =  cn(c.classes).split(' ').filter(check.nonEmptyString);
        return getImageComponent({
            ...c,
            classes,
            props: {
                ...c.props,
                role: 'image',
                ...className(classes),
            }
        },
        // Change image output type to make sure we get a src
        getParserConfig('html')
        );
    },
    'icon': (c : ComponentTreeNode) => {
        const classes =  cn(c.classes).split(' ').filter(check.nonEmptyString);
        return getIconComponent({
            ...c,
            classes,
            props: {
                ...c.props,
                role: 'icon',
                ...className(classes),
            }
        },
        // Change image output type to make sure we get a src
        getParserConfig('html')
        );
    },
}

export function mutualizedChildrenParsers(names: string[]) : ComponentParser[] {
    if (!check.nonEmptyArray(names)) {
        return [];
    }

    return names.map((name) => {
        if (!DEFAULT_PARSERS[name]) {
            return null;
        }

        return {
            name,
            tags: [name],
            parseTree: DEFAULT_PARSERS[name],
        } as ComponentParser;
    }).filter(p => !!p) as ComponentParser[];
}