import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/shadcn/ui/popover"
import { ComponentParser, ComponentTreeNode } from "../../component.type"
import { imports, radixComponentDoc, setup } from "../_utils";
import { selectVariant } from "@/lib/parser/util";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
export function PopoverDemo() {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">Open popover</Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none">Dimensions</h4>
            <p className="text-sm text-muted-foreground">
              Set the dimensions for the layer.
            </p>
          </div>
          <div className="grid gap-2">
            <div className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="width">Width</Label>
              <Input
                id="width"
                defaultValue="100%"
                className="col-span-2 h-8"
              />
            </div>
            <div className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="maxWidth">Max. width</Label>
              <Input
                id="maxWidth"
                defaultValue="300px"
                className="col-span-2 h-8"
              />
            </div>
            <div className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="height">Height</Label>
              <Input
                id="height"
                defaultValue="25px"
                className="col-span-2 h-8"
              />
            </div>
            <div className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="maxHeight">Max. height</Label>
              <Input
                id="maxHeight"
                defaultValue="none"
                className="col-span-2 h-8"
              />
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
*/

const VARIANTS = [
  'bottom',
  'left',
  'top',
  'right',
];

const PopoverComponentParser : ComponentParser = {
  name: 'Popover',
  description: 'Displays rich content in a portal, triggered by the parent button or link',
  tags: ['popover'],

  refImplementation: `
  /button Click to open popover
    /popover
      /div grid gap-4
        /div space-y-2
          /h4 font-medium leading-none Dimensions
          /p text-sm text-muted-foreground
            Set the dimensions for the layer.
        /div grid gap-2
          /div grid grid-cols-3 items-center gap-4
            /label Width
            /input @defaultValue="100%" col-span-2 h-8
          /div grid grid-cols-3 items-center gap-4
            /label Max. width
            /input @defaultValue="300px" col-span-2 h-8
          /div grid grid-cols-3 items-center gap-4
            /label Height
            /input @defaultValue="25px" col-span-2 h-8
          /div grid grid-cols-3 items-center gap-4
            /label Max. height
            /input @defaultValue="none" col-span-2 h-8`.trim().replaceAll(/ {2}/g, '\t'),


  // This function is called on nodes including children with the tag 'popover'
  // It wraps the parent and places all popover children to the right place
  wrapParent: (parent: ComponentTreeNode) : ComponentTreeNode => {
    const popoverContentChildren = (parent.children || []).filter(child => child.component === 'popover').map((c => {
      let side = c?.props?.side || selectVariant(VARIANTS, c.variant); 
      
      return ({
        ...c,
        component: PopoverContent,
        htmlComponent: 'PopoverContent',
        _dontParse: true,
        props: {
          ...(c.props || {}),
          side,
        },
        variant: null,
      })}));

    return {
      _dontParse: true,
      component: Popover,
      htmlComponent: 'Popover',
      classes: [],
      props: {},
      children: [
        {
          _dontParse: true,
          component: PopoverTrigger,
          htmlComponent: 'PopoverTrigger',
          props: {
            asChild: true,
          },
          children: [
            {
              ...parent,
              _dontParse: true,
              children: (parent.children || []).filter(child => child.component !== 'popover'),
            }
          ]
        },
        ...popoverContentChildren,
      ]
    }
  },

  imports: imports(['Popover', 'PopoverContent', 'PopoverTrigger'], 'popover'),
  setup: setup('popover'),
  variants: VARIANTS,
  doc: {
    props: radixComponentDoc('Popover.Content').props,
    variants: {
      'bottom': 'The popover will be displayed below the trigger',
      'left': 'The popover will be displayed to the left of the trigger',
      'top': 'The popover will be displayed above the trigger',
      'right': 'The popover will be displayed to the right of the trigger',
    },
  }
};

registerShadcnComponent({
        Popover,
        PopoverContent,
        PopoverTrigger,
    },
    'popover',
);

export default PopoverComponentParser;
