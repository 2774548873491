// import { Loader, Loader2 } from "lucide-react";
import CenteredPageLayout from "./CenteredPageLayout";
// import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import Lottie from 'react-lottie';
import animationData from '../assets/loading-logo.json';

export function LoadingAnimation() {
    return (
        <div className="size-[100px]">
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice"
                    }
                }}

                width={100}
                height={100}
            />
        </div>
    );
}

/*
export function LoadingAnimation() {
    return (
        <div className="size-[256px]">
            <DotLottieReact
                src="/lotties/loading-logo.json"
                loop
                autoplay
            />
        </div>
    );
}
*/

export default function LoadingPage() {
    return (
        <CenteredPageLayout className="w-screen h-screen overflow-hidden">
            <LoadingAnimation />
        </CenteredPageLayout>
    );
}