import { Label } from '@/components/shadcn/ui/label';
import { cn } from '../../../lib/utils';
import { RadioGroup as ShadcnRadioGroup, RadioGroupItem as ShadcnRadioGroupItem } from '@/components/shadcn/ui/radio-group';
import { VariantProps, cva } from 'class-variance-authority';
import React, { ReactElement, useState } from 'react';
import { any } from 'check-types';
import { useComposableApi } from '../../../lib/composable';
import useControlledValue from '../../../useControlledValue';

const RadioGroupPaddingVariants = cva(
	"flex box-content",
	{
		variants: {
			size: {
                "6xl": "p-16 space-x-16",
                "5xl": "p-12 space-x-12",
                "4xl": "p-10 space-x-10",
                "3xl": "p-8 space-x-8",
                "2xl": "p-6 space-x-6",
                "xl": "p-5 space-x-5",
                "lg": "p-4 space-x-4",
                "md": "p-3.5 space-x-3.5",
                "sm": "p-3 space-x-3",
                "xs": "p-2.5 space-x-2.5",
			},
			variant: {
				"default": "",
				"outline": "border border-primary-200",
				"card": "bg-card border border-border"
			}
		},
		defaultVariants: {
			size: "sm",
		},
	}
)

const RadioGroupGapVariants = cva(
	"w-fit h-fit flex m-px",
	{
		variants: {
			size: {
                "6xl": "gap-28",
                "5xl": "gap-20",
                "4xl": "gap-16",
                "3xl": "gap-12",
                "2xl": "gap-10",
                "xl": "gap-8",
                "lg": "gap-6",
                "md": "gap-5",
                "sm": "gap-4",
                "xs": "gap-3",
			},
            orientation : {
                "north": "flex-col",
                "south": "flex-col",
                "east": "flow-row",
                "west": "flex-row"
            },
			variant: {
				"default": "",
				"outline": "",
				"card": ""
			},
			rounded: {
                "none": "rounded-none",
                "sm": "rounded-sm",
                "md": "rounded-md",
                "lg": "rounded-lg",
                "xl": "rounded-xl",
                "2xl": "rounded-2xl",
                "3xl": "rounded-3xl",
                "full": "rounded-full",
            },
		},
		defaultVariants: {
			size: "sm",
            orientation: "north",
            variant: "default",
            rounded: "lg",
		},
	}
)

const RadioGroupSubGapVariants = cva(
	"flex flex-col",
	{
		variants: {
			size: {
                "6xl": "gap-8",
                "5xl": "gap-6",
                "4xl": "gap-5",
                "3xl": "gap-4",
                "2xl": "gap-3",
                "xl": "gap-2.5",
                "lg": "gap-2",
                "md": "gap-1.5",
                "sm": "gap-1",
                "xs": "gap-0.5",
			},
		},
		defaultVariants: {
			size: "sm",
		},
	}
)


const RadioGroupCheckVariants = cva(
	"",
	{
		variants: {
			size: {
                "6xl": "size-20",
                "5xl": "size-14",
                "4xl": "size-10",
                "3xl": "size-9",
                "2xl": "size-8",
                "xl": "size-7",
                "lg": "size-7",
                "md": "size-6",
                "sm": "size-5",
                "xs": "size-4",
			},
		},
		defaultVariants: {
			size: "sm",
		},
	}
)

const RadioGroupCircleVariants = cva(
	"",
	{
		variants: {
			size: {
                "6xl": "size-40",
                "5xl": "size-32",
                "4xl": "size-24",
                "3xl": "size-20",
                "2xl": "size-16",
                "xl": "size-12",
                "lg": "size-10",
                "md": "size-8",
                "sm": "size-6",
                "xs": "size-4",
			},
		},
		defaultVariants: {
			size: "sm",
		},
	}
)

const RadioGroupInlineVariants = cva(
	"w-fit",
	{
		variants: {
			size: {
                "6xl": "p-10 rounded-3xl",
                "5xl": "p-8 rounded-3xl",
                "4xl": "p-6 rounded-2xl",
                "3xl": "p-5 rounded-2xl",
                "2xl": "p-3.5 rounded-xl",
                "xl": "p-3 rounded-xl",
                "lg": "p-2 rounded-lg",
                "md": "p-1.5 rounded-lg",
                "sm": "p-1 rounded-lg",
                "xs": "p-0.5 rounded-lg",
			},
		},
		defaultVariants: {
			size: "sm",
		},
	}
)

export interface RadioGroupProps extends VariantProps<typeof RadioGroupGapVariants> {
    children?: React.ReactNode;
    className?: string;

    value?: string;
    defaultValue?: string;
    onValueChange?: (value: string) => void;
}

export function RadioGroup({ className, children, size = "sm", orientation = "north", variant = "default", rounded, value, onValueChange, defaultValue }: RadioGroupProps) {

	const [cleanedValue, cleanedOnValueChange] = useControlledValue({value, onValueChange, defaultValue})

    const hasRadioGroupItems = React.Children.toArray(children).some(child => 
        React.isValidElement(child) && child.type === TextRadioItem
    );

    return (
        <ShadcnRadioGroup className={cn(RadioGroupGapVariants({ size, orientation }), className)} defaultValue={defaultValue} value={value} onChange={cleanedOnValueChange}>
			{hasRadioGroupItems ? (
				React.Children.map(children, (child, index) => {
					if (React.isValidElement(child) && (child.type === TextRadioItem || child.type === ColorRadioItem)) {
						const newValue = child.props?.value || `item-${index}`;
						return React.cloneElement(child as ReactElement<RadioItemProps>, {
							size,
							variant,
                            orientation,
							radioValue: cleanedValue,
							value: newValue,
						});
					}
					return child;
				})
                ) : (
                    <>
                        <TextRadioItem size={size} variant={variant} orientation={orientation} rounded={rounded} title={"Coffee"} description={"Coffee Coffee Coffee Coffee Coffee"} value="r1" radioValue={cleanedValue} />
                        <TextRadioItem size={size} variant={variant} orientation={orientation} rounded={rounded} title={"Tea"} description={"Tea Tea Tea Tea Tea"} value="r2" radioValue={cleanedValue} />
                        <TextRadioItem size={size} variant={variant} orientation={orientation} rounded={rounded} title={"Milk"} description={"Milk Milk Milk Milk Milk"} value="r3" radioValue={cleanedValue} />
                        <ColorRadioItem size={size} variant={variant} orientation={orientation} rounded={rounded} value="r4" radioValue={cleanedValue} color='#E2E2E2' />
                        <ColorRadioItem size={size} variant={variant} orientation={orientation} rounded={rounded} value="r5" radioValue={cleanedValue} color='#A09991' />
                        <ColorRadioItem size={size} variant={variant} orientation={orientation} rounded={rounded} value="r6" radioValue={cleanedValue} color='#CEBFB7' />
                        <InlineRadioItem size={size} variant={variant} orientation={orientation} rounded={rounded} title={"T"} value="r7" radioValue={cleanedValue} />
                        <InlineRadioItem size={size} variant={variant} orientation={orientation} rounded={rounded} title={"Title"} value="r8" radioValue={cleanedValue} />
                        <InlineRadioItem size={size} variant={variant} orientation={orientation} rounded={rounded} title={"More"} value="r9" radioValue={cleanedValue} />
                    </>
                )}
        </ShadcnRadioGroup>
    );
}

export interface RadioItemProps extends VariantProps<typeof RadioGroupGapVariants> {
    className ?: string;
    children?: React.ReactNode;
	title?: string;
	description?: string;

	value?: string;
	radioValue?: string
}

export function TextRadioItem({ className, children, size = "sm", variant = "default", orientation = "north", rounded = "2xl", title, description, value = "default", radioValue }: RadioItemProps) {
    const { ChildWithRole, ChildrenWithRole } = useComposableApi(children);

    const title_style = cn("typeface-display-" + size + " text-primary font-medium", 
    (radioValue === value && variant == "card") ? "text-primary-800" : "")

    const description_style = cn("typeface-body-" + size + " text-primary/80 font-normal", 
    (radioValue === value && variant == "card") ? "text-primary-700" : (variant == "outline") ? "text-primary-800 font-light" : "")

    return (
        <Label htmlFor={value} className={cn(RadioGroupPaddingVariants({ size, variant }),
            "rounded-" + rounded,
            (radioValue === value && variant == "card") ? "bg-primary-50 border-primary-500 outline outline-1 outline-primary-500" : "")}>
            <ShadcnRadioGroupItem value={value} id={value} className={cn(RadioGroupCheckVariants({ size }),
                radioValue === value ? "border-primary-500 text-primary-500 bg-primary-50" : "", className)}/>
            <div className={cn(RadioGroupSubGapVariants({ size }))}>
                {title ? (
                    <div className={title_style}>
                        {title}
                    </div>
                ) : (
                    <ChildWithRole
                        role="title"
                        className={title_style}
                    />
                )}
                {description ? (
                    <div className={description_style}>
                        {description}
                    </div>
                ) : (
                    <ChildWithRole
                        role="description"
                        className={description_style}
                    />
                )}
            </div>
        </Label>
    );
}

export interface ColorItemProps extends VariantProps<typeof RadioGroupGapVariants> {
    className ?: string;
    children?: React.ReactNode;

    color?: string;

	value?: string;
	radioValue?: string
}

export function ColorRadioItem({ className, children, size = "sm", variant = "default", orientation = "north", rounded = "full", color = "#000000", value = "default", radioValue }: ColorItemProps) {
    const { ChildWithRole, ChildrenWithRole } = useComposableApi(children);

    const color_style = cn(RadioGroupCircleVariants({ size }), 
    "shrink-0 rounded-full bg-[" + color + "] " + "rounded-" + rounded,
    radioValue === value ? "border border-2 border-primary-500" : "border border-2 border-border", 
    (orientation == "north" || orientation == "south") ? "mx-auto" : "my-auto" , className)

    return (
        <>
            <ShadcnRadioGroupItem value={value} id={value} className="hidden" />
            {color ? (
                <label htmlFor={value} className={color_style}>
                    {}
                </label>
            ) : (
                <Label htmlFor={value}>
                    <ChildWithRole
                        role="color"
                        className={color_style}
                    />
                </Label>
            )}
        </>
    );
}

export interface InlineItemProps extends VariantProps<typeof RadioGroupGapVariants> {
    className ?: string;
    children?: React.ReactNode;

    title?: string;

	value?: string;
	radioValue?: string
}

export function InlineRadioItem({ className, children, size = "sm", variant = "default", orientation = "north", rounded = "full", title = "default", value = "default", radioValue }: InlineItemProps) {
    const { ChildWithRole, ChildrenWithRole } = useComposableApi(children);

    const title_style = cn(RadioGroupInlineVariants({ size }), 
    "typeface-display-" + size + " text-primary font-medium sizeshrink-0 rounded-full " + "rounded-" + rounded,
    radioValue === value ? "border border-2 border-primary-500" : "border border-2 border-border", 
    (orientation == "north" || orientation == "south") ? "mx-auto" : "my-auto", 
    (variant == "card" && radioValue === value) ? "bg-primary-50" : "", className)

    return (
        <>
            <ShadcnRadioGroupItem value={value} id={value} className="hidden" />
            {title ? (
                <label htmlFor={value} className={title_style}>
                    <div className=''>
                        {title}
                    </div>
                </label>
            ) : (
                <Label htmlFor={value}>
                    <ChildWithRole
                        role="title"
                        className={title_style}
                    />
                </Label>
            )}
        </>
    );
}