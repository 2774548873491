
// This hook will return the value of useBroadcast or useFirebaseValue, based on which 
// one is available and which one is the most local. 

import check from "@/vendors/check";
import useFirebaseValue from "@/vendors/firebase/useFirebase";
import { useRef } from "react";
import { useBroadcast } from '@/vendors/use-broadcast';

// It will unplug firebase use if a local event channel is available.
export function useLocalOrRemoteValue<T>(localChannel: string | null, firebasePath: string | null, defaultValue: T, allowAnonymous = false) {
    const localUsedAtLeastOnce = useRef(false);
    
    // Grab the local value
    const [localValue] = useBroadcast(localChannel, null);

    // If a local value is provided, save state so we know we can operate locally
    if (check.nonEmptyString(localValue)) {
        localUsedAtLeastOnce.current = true;
    }

    // Grab the Firebase value only if the local value is not set
    const { value: firebaseValue, loading } = useFirebaseValue(localUsedAtLeastOnce.current ? null : firebasePath, defaultValue, allowAnonymous);

    // Return the local value if available, otherwise return the Firebase value
    return {
        value: (localUsedAtLeastOnce.current ? localValue : firebaseValue) || defaultValue, 
        loading: localUsedAtLeastOnce.current ? false : loading,
    };
}