import {  BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { GatedEditorPage } from './pages/[id]/edit';
import uniqid from 'uniqid';
import { DisplayPage } from './pages/[id]';
import { DocumentationPage } from './pages/[id]/doc';
import { CodePage } from './pages/[id]/code';
import ComponentsTestPage from './pages/test';
import { ClerkProvider } from '@clerk/clerk-react';
import AuthedPage from './components/auth/AuthedPage';
import LayoutsThemeProvider from './vendors/theme/LayoutsThemeProvider';
import ProjectsPage from './pages/projects';
import ComponentsTestPlayer from './pages/test/player';
// eslint-disable-next-line import/no-webpack-loader-syntax

function App() {
  return (
    <ClerkProvider publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY as string}>
      <LayoutsThemeProvider preset="default">  
        <Router>
          <Routes>
              {/*
                <Route path="/colors" element={<ColorsGenerator />} />
              */}
              <Route path="/test" element={<ComponentsTestPage />} />
              <Route path="/test/player" element={<ComponentsTestPlayer />} />
              <Route path="/projects" element={<AuthedPage><ProjectsPage /></AuthedPage>} />
              <Route path="/test/*" element={<ComponentsTestPage />} />
              <Route path="/:pageId/code" element={<AuthedPage><CodePage /></AuthedPage>} />
              <Route path="/:pageId/doc" element={<DocumentationPage />} />
              <Route path="/:pageId/edit" element={<AuthedPage><GatedEditorPage /></AuthedPage>} />
              <Route path="/:pageId" element={<DisplayPage />} />
              <Route path="/:pageId/*" element={<DisplayPage />} />
              <Route path="*" element={<Navigate to={'/' + uniqid() + '/edit'} />} />
          </Routes>
        </Router>
      </LayoutsThemeProvider>
    </ClerkProvider>
  );
}

export default App;
