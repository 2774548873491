
import * as React from "react"
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import check from "@/vendors/check";
import { getSingleVarNameFromText } from "../../../util";
import { toDate } from "@/lib/type-casts";
import { DatePicker } from "@/components/shadcn/ui/date-picker";
import { DataInput } from "@/vendors/data/data-v2";
import { imports, setup } from "../_utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { parseCalendarProps } from "./calendar";


const DatePickerComponentParser : ComponentParser = {
    name: 'DatePicker',
    description: 'Displays a month view date-picker',
    tags: ['date-picker'],
    refImplementation: `
    /date-picker @value="7th of March 2024" Pick a date
    `.trim(),
    parseTree: (c: ComponentTreeNode) => {
        const { defaultMonth, mode, ...props } = parseCalendarProps(c);
        let wrap = (w: ComponentTreeNode) => w;

        // Handle variable binding
        const textVar = getSingleVarNameFromText(c.text);
        if (check.nonEmptyString(textVar)) {
            const restOfText = c.text?.replace(textVar, '').trim();
            if (check.nonEmptyString(restOfText)) {
                props.placeholder = restOfText;
            }

            wrap = (w: ComponentTreeNode) => ({
                component: DataInput as React.ComponentType,
                htmlComponent: 'DataInput',
                props: { bind: textVar, type: 'day' },
                classes: [],
                variant: null,
                text: null,
                children: [w],
            });
        } else {
            props.placeholder = check.nonEmptyString(c.text) ? c.text : undefined;
        }
        
        return wrap({
            ...c,
            component: DatePicker as React.ComponentType,
            htmlComponent: 'DatePicker',
            props,
            children: null,
        });
    },
    imports: [
        ...imports(['DatePicker'], 'date-picker'),
    ],
    setup: [
        ...setup('date-picker'),
    ],
};

registerShadcnComponent({
        DatePicker,
    },
    'date-picker',
);

export default DatePickerComponentParser;



