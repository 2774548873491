
import * as SheetPrimitive from "@radix-ui/react-dialog"
import { SheetContent } from "@/components/shadcn/ui/sheet"
import { useComposableApi } from "../../../lib/composable"


import { cn } from "../../../lib/utils"

const Sheet = SheetPrimitive.Root

const SheetTrigger = SheetPrimitive.Trigger

const SheetClose = SheetPrimitive.Close

const SheetPortal = SheetPrimitive.Portal



export function SideBar(
    {children, side='left', className, role}:{children?:React.ReactNode, side?:'left'|'right'|'top'|'bottom', className?:string, role?:string}
) {
    const { ChildWithRole, ChildrenWithRole, HasAtLeastOneChildWithRole } = useComposableApi(children);
    return (
        <SheetContent side={side} className={cn("bg-background max-h-screen flex flex-col p-0", className)}>
            <div className="w-full flex-1 overflow-y-scroll overflow-x-hidden max-h-full flex flex-col justify-between m-0 pointer-events-auto">
                <nav className="grid gap-1 text-lg font-medium max-h-full">
                    <ChildrenWithRole
                        role="items"
                        className="flex items-center gap-2 text-lg typeface-display-md max-w-full"
                    >
                    </ChildrenWithRole>
                </nav>
                <nav className="grid gap-1 max-h-full w-full">
                    <ChildrenWithRole
                        role="items-bottom"
                        className="flex items-center gap-2 text-lg typeface-display-md max-w-full w-full"
                    >
                    </ChildrenWithRole>
                </nav>
            </div>
            <div className="h-fit  w-full max-h-50% overflow-y-scroll overflow-x-hidden border-t-2 border-slate-200">
                <nav className="grid gap-0 text-lg font-medium max-h-full m-0 pt-[1px]">
                    <ChildrenWithRole
                        role="items-fixed"
                        className="flex items-center gap-2 text-lg typeface-display-md max-w-full"
                    >
                    </ChildrenWithRole>
                </nav>
            </div>
        </SheetContent>
    )
}