import check from '@/vendors/check';
import { onlyUnique } from '@/lib/utils';
import reactTagNames from "react-tag-names";
import { componentsByTag, onlyFirstTags, sortedTags } from './index.loader';
import { RadixPropDocumentation } from '../doc/radix/radix-docs';
import { ComponentParser } from './component.type';
import { getComponentParserForPath } from '../parser';
import { HTML_COMPONENTS_CATEGORY, SVG_COMPONENTS_CATEGORY } from './html';

export function getComponents(opts = {}) {
    return componentsByTag;
}

export function allUniqueComponentsAsArray() {
    return onlyFirstTags.map(c => componentsByTag[c]).filter(onlyUnique);
}

export function getComponentForTag(tag: string) {
    if (!check.nonEmptyString(tag)) {
        return null;
    }
    return componentsByTag[tag.trim().replace('/', '').toLowerCase()] || null;
}

export function isHtmlTag(tag: string) : boolean {
    if (!check.nonEmptyString(tag)) {
        return false;
    }
    return reactTagNames.includes(tag.trim().toLowerCase().replace('/', ''));
}

export function getComponentVariants(tag: string) {
    const c = getComponentForTag(tag);
    return c ? (c.variants || []) : [];
}

export function isComponentTagStart(word: string) {
    if(!check.nonEmptyString(word) || !/^\/[a-zA-Z_.-]+$/.test(word.trim())) {
        return false;
    }

    const tagName = word.trim().substr(1).toLowerCase();
    return sortedTags().some(t => t.startsWith(tagName));
}

export function searchComponents(word: string) {
    if (word === '/') {
        return onlyFirstTags.map(c => componentsByTag[c]).filter(Boolean).filter(onlyUnique).filter(c => !c.dontList);
    }

    if(!check.nonEmptyString(word) || !/^\/[a-zA-Z_.-]+$/.test(word.trim())) {
        return [];
    }

    const search = word.trim().substr(1).toLowerCase();
    return onlyFirstTags.filter(t => t.startsWith(search)).map(c => componentsByTag[c]).filter(Boolean).filter(onlyUnique).filter(c => !c.dontList);
}

export function reorderComponentsByCategories(components: ComponentParser[], tagChain: string[]) {
    const orderedComponents: ComponentParser[] = [];

    // First, we order by categories
    const categories: Record<string, ComponentParser[]> = getReorderedCategories(components, tagChain);

    // Then we order by name
    Object.keys(categories).forEach((category) => {
        orderedComponents.push(...categories[category])
    });

    return orderedComponents;
}

function sortOnKeys(categories: Record<string, ComponentParser[]>) {

    var sorted = [];
    for(var key in categories) {
        sorted[sorted.length] = key;
    }
    sorted.sort();

    var tempCategories: Record<string, ComponentParser[]> = {};
    for(var i = 0; i < sorted.length; i++) {
        tempCategories[sorted[i]] = categories[sorted[i]];
    }

    return tempCategories;
}

export function getReorderedCategories(components: ComponentParser[], tagChain: string[]) {
    const categories: Record<string, ComponentParser[]> = {};
    if (tagChain) {
        for (let x = tagChain.length - 1; x >= 0; x--) {
            categories[tagChain[x].slice(1)] = [];
        }
    }
    
    const otherComponents: ComponentParser[] = [];
    const otherCategories: Record<string, ComponentParser[]> = {};

    components.forEach((c) => {
        if (c.category) {
            if (otherCategories[c.category]) {
                otherCategories[c.category].push(c);
            } else {
                otherCategories[c.category] = [c];
            }
        } else {
            otherComponents.push(c);
        }
    });

    // Remove HTML_COMPONENTS_CATEGORY et SVG_COMPONENTS_CATEGORY from categories
    const htmlComponents = otherCategories[HTML_COMPONENTS_CATEGORY];
    const svgComponents = otherCategories[SVG_COMPONENTS_CATEGORY];
    if (htmlComponents) {
        delete otherCategories[HTML_COMPONENTS_CATEGORY];
    }
    if (svgComponents) {
        delete otherCategories[SVG_COMPONENTS_CATEGORY];
    }



    return {...categories, ...(sortOnKeys(otherCategories)), Others: otherComponents, [HTML_COMPONENTS_CATEGORY]: htmlComponents || [], [SVG_COMPONENTS_CATEGORY]: svgComponents || []};
}

export function searchComponentsForTagChain(word: string, tagChain: string[]) {
    if (!check.nonEmptyArray(tagChain)) {
        return searchComponents(word);
    }

    const { tags, parsers, firstTags } = getComponentsByTagsAndSortedTagsForTagChain(tagChain);

    if (word === '/') {
        return tags.map(c => parsers[c]).filter(Boolean).filter(onlyUnique).filter(c => !c.dontList);
    }

    if(!check.nonEmptyString(word) || !/^\/[a-zA-Z_.-]+$/.test(word.trim())) {
        return [];
    }

    const search = word.trim().substr(1).toLowerCase();
    return firstTags.filter(t => t.startsWith(search)).map(c => parsers[c]).filter(Boolean).filter(onlyUnique).filter(c => !c.dontList);
}

export function getComponentsByTagsForTagChain(tagChain: string[], parsers?: Record<string, ComponentParser>) : Record<string, ComponentParser> {
    // if element with same name in parser and componentsByTag, parser will be used
    const base : Record<string, ComponentParser> = {...(parsers || 
        onlyFirstTags.reduce<Record<string, ComponentParser>>((acc, c) => {
        const component = componentsByTag[c];
        if (component && component.tags && component.tags[0]) {
          acc[component.tags[0]] = component;
        }
        return acc;
      }, {}))
    };

    if (!check.nonEmptyArray(tagChain)) {
        return base;
    }

    const parsedTagChain = tagChain.map((c) => c.replace("/", ""))

    // Get the next component in the chain
    const nextComponentTag = parsedTagChain[0].trim().toLowerCase();
    const nextComponent = base[nextComponentTag];


    // If no next component or no children parsers, return the base
    if (!nextComponent || !check.nonEmptyArray(nextComponent.childrenParsers)) {
        return getComponentsByTagsForTagChain(parsedTagChain.slice(1), base);
    }
    
    // Add children parsers to the base

/*     nextComponent.childrenParsers.forEach(p => {
        p.tags.forEach(t => {
            base[t] = {...p, category: nextComponent.tags[0]};
        });
    }) */

    nextComponent.childrenParsers.forEach((c) => {
        base[c.tags[0]] = {...c, category: nextComponent?.tags[0] || ""};
    });

    return getComponentsByTagsForTagChain(parsedTagChain.slice(1), base);
}

export function getComponentsByTagsAndSortedTagsForTagChain(tagChain: string[]) : {
    parsers: Record<string, ComponentParser>,
    tags: string[],
    firstTags: string[],
} {
    const parsers = getComponentsByTagsForTagChain(tagChain);
    const tags = Object.keys(parsers).sort();
    const firstTags = Object.values(parsers).map(c => c.tags[0]).filter(onlyUnique).sort();

    return { parsers, tags, firstTags };
}; 

export function getComponentForLine(line: string) {
	let firstWord = line.trim().split(' ')[0];
	return firstWord;
}

export function getPropsForLineAndParentChain(line: string, parentComponents: string[]) : Partial<RadixPropDocumentation>[] {
    const tagChain = [ ...parentComponents, getComponentForLine(line) || null ].filter(Boolean) as string[];
    return getPropsForComponentTagChain(tagChain);
}

export function getPropsForComponentTagChain(tagChain: string[]) : Partial<RadixPropDocumentation>[] {
    try {
        const parser = getComponentParserForPath(tagChain);
        if (!parser) {
            return [];
        }
        return Object.keys(parser.doc?.props || []).sort().map(k => parser.doc?.props?.[k]).filter(p => !!p) as Partial<RadixPropDocumentation>[];
    } catch {
        return [];
    }
}

export function getSubComponentsForComponentTagChain(tagChain: string[]) : { name?: string, description?: string, tags: string[] } [] {
    const parser = getComponentParserForPath(tagChain);
    if (!parser || !check.nonEmptyArray(parser.childrenParsers)) {
        return [];
    }

    return parser.childrenParsers.map(p => ({
        name: p.name,
        description: p.description,
        tags: p.tags,
    }));
}

/* export function searchPropsForComponentTagChain(tagChain: string[], search: string) : Partial<RadixPropDocumentation>[] {
    const allProps = getPropsForLineAndParentChain(tagChain);

    if (!check.nonEmptyArray(allProps)) {
        return [];
    }

    if (!check.nonEmptyString(search) || !check.nonEmptyString(search.trim())) {
        return allProps;
    }

    const results : Partial<RadixPropDocumentation>[] = [];
    const rest : Partial<RadixPropDocumentation>[] = [];
    const normalizedSearch = search.trim().toLowerCase();

    // Return props starting with search first
    allProps.forEach(p => {
        if (!check.nonEmptyString(p.name)) {
            return;
        }
        if (p.name.toLowerCase().trim().startsWith(normalizedSearch)) {
            results.push(p);
        } else {
            rest.push(p);
        }
    });

    // Add other results containing search
    rest.forEach(p => {
        if (!check.nonEmptyString(p.name)) {
            return;
        }
        if (p.name.toLowerCase().trim().includes(normalizedSearch)) {
            results.push(p);
        }
    });

    return results;
} */

export function isComponentTag(word: string) {
    return check.nonEmptyString(word) && check.nonEmptyArray(sortedTags()) && sortedTags().includes(word.replace('/', '').trim().toLowerCase());
}

export function onlyOneBeginComponentTag(word: string) {
    return check.nonEmptyString(word) && check.nonEmptyArray(sortedTags()) && sortedTags().filter(t => t.startsWith(word.replace('/', '').trim().toLowerCase())).length <= 1;
}

export function OnlyOneBeginComponentTagAndInternalComonentTag(word: string, parentComponents: string[]) {
    if (!check.nonEmptyString(word) || !check.nonEmptyArray(sortedTags())) {
        return true;
    }
    let compList: string[] = sortedTags().filter(t => t.startsWith(word.replace('/', '').trim().toLowerCase()));
    let compListToKeep : string[] = compList.filter(t => {
        return componentsByTag[t]?.dontList === undefined || componentsByTag[t]?.dontList === false;
    })
    const cleanWord = word.replace('/', '').trim().toLowerCase();

    try {
        for (let x = parentComponents.length; x >= 0; x--) {
            const comparators = getComponentParserForPath(parentComponents.slice(0, x))?.childrenParsers?.map((c) => c.tags[0]?.toLowerCase())
            if (
                check.nonEmptyString(word) && 
                check.nonEmptyArray(comparators)
            ) {
                let toAdd = comparators.filter(t => t.startsWith(cleanWord));
                for (let i = 0; i < toAdd.length; i++) {
                    if (!compListToKeep.includes(toAdd[i]) && (componentsByTag[toAdd[i]]?.dontList === false || undefined)) {
                        compListToKeep.push(toAdd[i]);
                    }
                }
            }
        }
    } catch (e) {
    }

    return compListToKeep.length > 1 ? false : true
}

export function isComponentTagAndOnlyOneBeginComponentTag(word: string, parentComponents: string[]) {
    return isComponentTag(word) && OnlyOneBeginComponentTagAndInternalComonentTag(word, parentComponents);
}

export function isBeginComponentTag(word: string) {
    const cleanWord = word.replace('/', '').trim().toLowerCase();
    const tags = sortedTags();

    return (
        check.nonEmptyString(word) &&
        check.nonEmptyArray(tags) &&
        tags.some(tag => tag.startsWith(cleanWord))
    );
}

export function isPropsTag(word: string, parentComponents: string[], lineContent: string) {
    const cleanWord = word.replace('@', '').trim().toLowerCase();
    const comparators:any = getPropsForLineAndParentChain(lineContent, parentComponents).map((c) => c.name?.toLowerCase());

    return (
        check.nonEmptyString(word) &&
        check.nonEmptyArray(comparators) &&
        comparators.includes(cleanWord)
    );
}
    
export function isBeginPropsTag(word: string, parentComponents: string[], lineContent: string) {
    const cleanWord = word.replace('@', '').trim().toLowerCase();
    const comparators:any = getPropsForLineAndParentChain(lineContent, parentComponents).map((c) => c.name?.toLowerCase());

    return (
        check.nonEmptyString(word) &&
        check.nonEmptyArray(comparators) &&
        comparators.some(name => name.startsWith(cleanWord))
    );
}

export function isInternalComponentTag(word: string, parentComponents: string[]) {
    const cleanWord = word.replace('/', '').trim().toLowerCase();

    try {
        for (let x = parentComponents.length; x >= 0; x--) {
            const comparators = getComponentParserForPath(parentComponents.slice(0, x))?.childrenParsers?.map((c) => c.tags[0]?.toLowerCase())
            if (
                check.nonEmptyString(word) &&
                check.nonEmptyArray(comparators) &&
                comparators.includes(cleanWord)
            ) {
                return true;
            }
        }
    } catch {
        return false
    }
    return false
}

export function onlyOneBeginInternalComponentTag(word: string, parentComponents: string[]) {
    let count = 0;
    const cleanWord = word.replace('/', '').trim().toLowerCase();

    try {
        for (let x = parentComponents.length; x >= 0; x--) {
            const comparators = getComponentParserForPath(parentComponents.slice(0, x))?.childrenParsers?.map((c) => c.tags[0]?.toLowerCase())
            if (
                check.nonEmptyString(word) && 
                check.nonEmptyArray(comparators)
            ) {
                count += comparators.filter(t => t.startsWith(cleanWord)).length;
            }
        }
    } catch {
        return true
    }
    return count > 1 ? false : true
}

export function isInternalComponentTagAndOnlyOneBeginInternalComponentTag(word: string, parentComponents: string[]) {
    return isInternalComponentTag(word, parentComponents) && OnlyOneBeginComponentTagAndInternalComonentTag(word, parentComponents);
}

export function isBeginInternalComponentTag(word: string, parentComponents: string[]) {
    const cleanWord = word.replace('/', '').trim().toLowerCase();

    try {
        for (let x = parentComponents.length; x >= 0; x--) {
            const comparators = getComponentParserForPath(parentComponents.slice(0, x))?.childrenParsers?.map((c) => c.tags[0]?.toLowerCase())
            if (
                check.nonEmptyString(word) &&
                check.nonEmptyArray(comparators) &&
                comparators.some(name => name.startsWith(cleanWord))
            ) {
                return true;
            }
        }
    } catch {
        return false
    }
    return false
}