import * as React from "react"
import { Button } from "../../../lib/button";
import { cva, type VariantProps } from "class-variance-authority"
import { TailwindSize, cn, getSmallerSize } from "../../../lib/utils";
import { useComposableApi } from "../../../lib/composable";

const CardVariantsBox = cva("",{
    variants: {
        size: {
            "xs": "min-h-8",
            "sm": "min-h-9",
            "md": "min-h-10",
            "lg": "min-h-12",
            "xl": "min-h-14",
            "2xl": "min-h-16",
            "3xl": "min-h-20",
            "4xl": "min-h-24",
            "5xl": "min-h-28",
            "6xl": "min-h-32",
        },
        verticalAlign: {
            "top": "",
            "center": "",
            "bottom": "",
        }
    },
    defaultVariants: {
        size:'md',
        verticalAlign: "top",
    },
});

const CardVariantsInnerBox = cva("",{ 
    variants: {
        size : {
            "xs": "gap-2 px-1 py-2",
            "sm": "gap-3 px-2 py-3",
            "md": "gap-3 px-2 py-3",
            "lg": "gap-3 px-2 py-3",
            "xl": "gap-3 px-2 py-3",
            "2xl": "gap-3 px-2 py-3",
            "3xl": "gap-3 px-2 py-3",
            "4xl": "gap-4 px-3 py-4",
            "5xl": "gap-4 px-3 py-4",
            "6xl": "gap-5 px-4 py-5",
        },
        verticalAlign: {
            "top": "items-start",
            "center": "items-center",
            "bottom": "items-end",
        }
    },
    defaultVariants: {
        "verticalAlign": "center",
        'size': 'lg'
    },
});

const CardVariantsOther = cva("",{ 
    variants: {
        size: {
            "xs": "h-10",
            "sm": "h-11",
            "md": "h-12",
            "lg": "h-14",
            "xl": "h-16",
            "2xl": "h-20",
            "3xl": "h-24",
            "4xl": "h-32",
            "5xl": "h-40",
            "6xl": "h-52",
        },
        orientation: {
            "east": "",
            "west": ""
        },
        rounded: {
            'none': "rounded-none",
            "sm": "rounded-sm",
            "md": "rounded-md",
            "lg": "rounded-lg",
            "xl": "rounded-xl",
            "2xl": "rounded-2xl",
            "3xl": "rounded-3xl",
            'full': "rounded-full",
        },
        verticalAlign: {
            "top": "",
            "center": "",
            "bottom": "",
        }
    },
    defaultVariants: {
        size: "md",
        orientation: 'east',
    },
});

const IconCardLeftIconVariants = cva(
	"m-auto h-full text-black",
	{
		variants: {
			size: {
                "6xl": "w-28 stroke-[1px]",
                "5xl": "w-20 stroke-[1px]",
                "4xl": "w-20 stroke-[1px]",
                "3xl": "w-12 stroke-[2px]",
                "2xl": "w-8 stroke-[2px]",
                "xl": "w-8 stroke-[2px]",
                "lg": "w-8 stroke-[2px]",
                "md": "w-6 stroke-[2px]",
                "sm": "w-4 stroke-[3px]",
                "xs": "w-4 stroke-[3px]",
			}
		},
		defaultVariants: {
			size: "md",
		},
	}
)

const IconCardLeftSizeVariants = cva(
	"bg-secondary flex flex-col items-center justify-center",
	{
		variants: {
			size: {
                "6xl": "w-52 h-52",
                "5xl": "w-40 h-40",
                "4xl": "w-32 h-32",
                "3xl": "w-24 h-24",
                "2xl": "w-20 h-20",
                "xl": "w-16 h-16",
                "lg": "w-14 h-14",
                "md": "w-12 h-12",
                "sm": "w-11 h-11",
                "xs": "w-10 h-10",
			},
		},
		defaultVariants: {
			size: "md",
		},
	}
)

const TextSizeVariants : Record<any, TailwindSize | 'md'>= {
        "6xl": "2xl",
        "5xl": "2xl",
        "4xl": "xl",
        "3xl": "xl",
        "2xl": "lg",
        "xl": "lg",
        "lg": "lg",
        "md": "md",
        "sm": "md",
        "xs": "sm",
        null: 'lg',
}

interface CardProps extends VariantProps<typeof CardVariantsOther> {
    children: React.ReactNode;
    className?: string;
    role?: string;
    action?: () => void;
}

function ListItemIcon({
    size = 'md',
    rounded = "md",
    verticalAlign = 'center',
    className = "",
    children,
} : CardProps ) {
    const { ChildWithRole, HasAtLeastOneChildWithRole, composableApi } = useComposableApi(children);
    if (size === null) {
        size = 'md';
    }

    const icons = composableApi.getAllChildrenWithRole('icon');

    return (
        <Button className={cn(CardVariantsBox({verticalAlign, size}), ` reset h-fit w-full bg-white dark:bg-neutral-800 rounded grid`, className)}>
            <div className={cn(CardVariantsInnerBox({verticalAlign}), "p-[12px] pt-[8px] pb-[8px] m-auto flex flex-row justify-end h-fit w-full ")}>
                {icons.length > 0 && (
                   <div className={cn(CardVariantsOther({rounded}), IconCardLeftSizeVariants({size}), " bg-secondary")}>
                        {composableApi.displayChild(icons[0], {
                            role: "image",
                            className: cn(IconCardLeftIconVariants({ size })),
                        })}
                    </div>
                )}
                <div className={"min-h-full h-fit flex-1 flex flex-col justify-center items-start"}>
                    <ChildWithRole
                            role="title"
                            className={`typeface-display-${TextSizeVariants[size]} text-textcolor-primary dark:text-white align-middle text-left`}
                    ></ChildWithRole>
                    <ChildWithRole
                            role="description"
                            className={`typeface-body-${TextSizeVariants[getSmallerSize(size)]} text-textcolor-secondary dark:text-slate-400 align-middle text-left`}
                    ></ChildWithRole>
                </div>
                {icons.length > 1 && (
                    <div className={cn(CardVariantsOther({size}), "flex flex-col mr-2 justify-center")}>
                        {composableApi.displayChild(icons[1], {
                            role: "icon",
                            className: "aspect-square",
                        })}
                    </div>
                )}
            </div>
        </Button>
    )
}

export default ListItemIcon;