
import { ToggleGroup, ToggleGroupItem } from "@/components/shadcn/ui/toggle-group";
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { ComponentAliasConfig, childrenParsers, imports, isTextComponent, radixComponentDoc, setup } from "../_utils";
import { selectVariantWithUndefinedDefault } from "@/lib/parser/util";
import { addStagesIf, deepRemoveSameTypeChildren, ensureAllElementsHaveAChildrenArray, markTreeLeafs, onlyAllowTypes, parseTextComponents, removeFirstOccurenceOfTextFromChildren, solveAliases, transformComponentsOfTypes, treeParsingPipeline } from "../../parsing-pipeline";
import { getIconComponent, isIconComponent } from "../../base/images";
import check from "@/vendors/check";
import { cn } from "@/lib/utils";
import { ComponentParserConfig } from "@/lib/parser/parser-config";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
import { Bold, Italic, Underline } from "lucide-react"
 
import {
  ToggleGroup,
  ToggleGroupItem,
} from "@/components/ui/toggle-group"
 
export function ToggleGroupDemo() {
  return (
    <ToggleGroup type="multiple">
      <ToggleGroupItem value="bold" aria-label="Toggle bold">
        <Bold className="size-4" />
      </ToggleGroupItem>
      <ToggleGroupItem value="italic" aria-label="Toggle italic">
        <Italic className="size-4" />
      </ToggleGroupItem>
      <ToggleGroupItem value="underline" aria-label="Toggle underline">
        <Underline className="size-4" />
      </ToggleGroupItem>
    </ToggleGroup>
  )
}
*/

function ensureValuePropPresent(c: ComponentTreeNode, i: number) {
  return {
    ...c,
    props: {
      ...(c.props || {}),
      value: c.props?.value || `option-${i}`,
    }
  };
}

function parseItemComponent(c: ComponentTreeNode, config: ComponentParserConfig) : ComponentTreeNode | null {
  const text = check.nonEmptyString(c.text) ? c.text : (c.children || []).filter(isTextComponent).find(child => check.nonEmptyString(child.text))?.text || c.props?.value || null;
  let icon = (c.children || []).find(isIconComponent);

  if (!icon && !check.nonEmptyString(text)) {
    return null;
  }

  if (!icon) {
    icon = getIconComponent({
      classes: ['size-4'],
      children: [],
      variant: 'lucide',
      text,
      props: {
        alt: text,
      }
    }, config);
  }

  const classes = cn(c.classes, c.props?.className, (check.nonEmptyString(text) && c.props.withText) ? 'flex items-center gap-2' : '').split(' ').filter(check.nonEmptyString);
  
  return {
    ...c,
    component: ToggleGroupItem as any,
    htmlComponent: 'ToggleGroupItem',
    props: {
      ...(c.props || {}),
      value: c.props?.value || text,
      // Disabling as prop not existing on current component
      // ariaLabel: c.props?.ariaLabel || `Toggle ${text}`,
      className: cn(classes),
    },
    classes,
    children: [
      icon,
      (check.nonEmptyString(text) && c.props.withText) ? text : null,
    ].filter(Boolean),
  };
}

const TAG = 'toggle-group';
const VARIANTS = [
  'default',
  'outline',
];
const ALIASES : ComponentAliasConfig[] = [
  ['item', ToggleGroupItem as any, 'ToggleGroupItem', radixComponentDoc('ToggleGroup.Item')],
  ['toggle', ToggleGroupItem as any, 'ToggleGroupItem', radixComponentDoc('ToggleGroup.Item')],
];

const ToggleGroupComponentParser : ComponentParser = {
  name: 'Toggle Group',
  description: 'A set of two-state buttons that can be toggled on or off.',
  tags: [TAG],
  refImplementation: `
  /toggle-group %default @type="multiple"
    Bold
    Italic
    Underline
  `.trim(),

  childrenParsers: childrenParsers(ALIASES),

  parseTree: (c: ComponentTreeNode, config: ComponentParserConfig) => {
    return treeParsingPipeline([
      // Remove text from children if any
      addStagesIf(
        (parent: ComponentTreeNode) => (parent.component === ToggleGroup as any || parent.component === 'toggle-group'),
        [removeFirstOccurenceOfTextFromChildren(c.text)],
      ),
  
      // Solve alias components
      solveAliases(ALIASES),
  
      // Ensure all children have a children array (to solve a bug when typing /item without text or children)
      ensureAllElementsHaveAChildrenArray(),
      
      // Parse item components
      transformComponentsOfTypes([ToggleGroupItem as any], c => parseItemComponent(c, config)),

      // Parse text components
      parseTextComponents(c => parseItemComponent(c, config)),
  
      // Only allow supported children
      onlyAllowTypes([ToggleGroupItem]),
      
      // Prevent some components from having themselves as children
      deepRemoveSameTypeChildren([ToggleGroupItem, ToggleGroup as any]),

      // Make sure all items have a value
      transformComponentsOfTypes([ToggleGroupItem as any], (c, _, i) => ensureValuePropPresent(c, i)),
  
      // Mark tree leafs to avoid parsing text children of CommandItem & others...
      markTreeLeafs([ToggleGroupItem as any])
    ]).run({
      ...c, 
      component: ToggleGroup as any, 
      htmlComponent: 'ToggleGroup',
      props: {
        ...c.props,
        type: ['single', 'multiple'].includes(c.props?.type) ? c.props?.type : 'single',
        variant: selectVariantWithUndefinedDefault(VARIANTS, c.variant || c?.props?.variant),
      },
      variant: selectVariantWithUndefinedDefault(VARIANTS, c.variant || c?.props?.variant),
    }, []);
  },
  imports: imports(['ToggleGroup', 'ToggleGroupItem'], TAG),
  setup: setup(TAG),
  variants: VARIANTS,
  doc: {
    props: radixComponentDoc('ToggleGroup.Root').props,
  }
};

registerShadcnComponent({
        ToggleGroup, 
        ToggleGroupItem,
    },
    TAG,
);

export default ToggleGroupComponentParser;
