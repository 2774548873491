import * as React from "react"
import * as LabelPrimitive from "@radix-ui/react-label"
import { Slot } from "@radix-ui/react-slot"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  FieldPath,
  FieldValues,
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from "react-hook-form"

import { cn } from "@/lib/utils"
import { Label } from "@/components/shadcn/ui/label"

const Form = React.forwardRef<
  HTMLFormElement,
  React.ComponentPropsWithoutRef<"form">
>(({ zodSchema, onSubmit = () => {}, className, children, ...props } : any, ref) => {
  let resolver = undefined;

  if (zodSchema) {
    resolver = zodResolver(zodSchema);
  }

  const form = useForm<z.infer<typeof zodSchema>>({
    resolver,
  });

  return (
    <FormProvider {...form}>
      <form 
        ref={ref}
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn('space-y-8', className)}
        {...props}
      >
        {children}
      </form>
    </FormProvider>
  );

});
Form.displayName = "Form";

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  id: string,
  name: TName,
  field: {
    name: TName,
    value: unknown,
    onChange: (value: any) => void,
    onBlur: () => void,
    ref: React.Ref<any>,
  },
}

const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue
)

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name, 
  className, 
  ...props
}: { name: TName, children?: any, className?: string }) => {
  const id = React.useId();
  const { field } = useController({ name, defaultValue: '' as any });
  return (
    <FormFieldContext.Provider value={{ id, name, field }}>
      <div  className={cn("self-stretch flex flex-col gap-2", className)} {...props} />
    </FormFieldContext.Provider>
  )
}

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext)
  const { getFieldState, formState } = useFormContext()

  const fieldState = getFieldState(fieldContext.name, formState)

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>")
  }

  return {
    id: fieldContext.id,
    name: fieldContext.name,
    field: fieldContext.field,
    formItemId: `${fieldContext.id}-form-item`,
    formDescriptionId: `${fieldContext.id}-form-item-description`,
    formMessageId: `${fieldContext.id}-form-item-message`,
    ...fieldState,
  }
}

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField()

  return (
    <Label
      ref={ref}
      className={cn(error && "text-destructive", className)}
      htmlFor={formItemId}
      {...props}
    />
  )
})
FormLabel.displayName = "FormLabel"

const FormControl = ({ ...props }) => {
  const { error, formItemId, formDescriptionId, formMessageId, field } = useFormField()

  return (
    <Slot
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...field}
      {...props}
    />
  )
};
FormControl.displayName = "FormControl"

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField()

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn("text-sm text-muted-foreground", className)}
      {...props}
    />
  )
})
FormDescription.displayName = "FormDescription"

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField()
  const body = error ? String(error?.message) : children

  if (!body) {
    return null
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn("text-sm font-medium text-destructive", className)}
      {...props}
    >
      {body}
    </p>
  )
})
FormMessage.displayName = "FormMessage"

export {
  useFormField,
  Form,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
}
