import { ScrollArea, ScrollBar } from "@/components/shadcn/ui/scroll-area"
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { imports, radixComponentDoc, setup } from "../_utils";
import { cn } from "@/lib/utils";
import { getInnerClasses, getOuterClasses } from "@/lib/tailwind/tailwindClasses";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
const tags = Array.from({ length: 50 }).map(
  (_, i, a) => `v1.2.0-beta.${a.length - i}`
)

export function ScrollAreaDemo() {
  return (
    <ScrollArea className="h-72 w-48 rounded-md border">
      <div className="p-4">
        <h4 className="mb-4 text-sm font-medium leading-none">Tags</h4>
        {tags.map((tag) => (
          <>
            <div key={tag} className="text-sm">
              {tag}
            </div>
            <Separator className="my-2" />
          </>
        ))}
      </div>
    </ScrollArea>
  )
}
*/

const TAG = 'scroll-area';

const ScrollAreaComponentParser : ComponentParser = {
  name: 'Scroll-area',
  description: 'Augments native scroll functionality for custom, cross-browser styling.',
  refImplementation: `
/scroll h-72 w-48 rounded-md border gap-2 p-2
    /foreach [1...1042]
        /center p-4 bg-slate-100 w-full rounded $item 
  `.trim(),
  tags: [TAG, 'scroll'],
  parseTree: (c: ComponentTreeNode) => {
      const { variant } = c;

      if (variant === 'vertical-flex') {
        const outerClasses = getOuterClasses(c.classes);
        const innerClasses = cn('flex flex-col p-4', getInnerClasses(c.classes)).split(' ');

        return {
            ...c,
            component: ScrollArea,
            htmlComponent: 'ScrollArea',
            classes: outerClasses,
            props: {
              ...c.props,
              className: outerClasses.join(' '),
            },
            children: [
              {
                component: 'div',
                htmlComponent: 'div',
                classes: innerClasses,
                props: {
                  className: innerClasses.join(' '),
                },
                children: c.children
              }
            ],
            variant: null,
        };
      }

      if (variant === 'horizontal-flex') {
        const outerClasses = cn('whitespace-nowrap', getOuterClasses(c.classes)).split(' ');
        const innerClasses = cn('flex w-max space-x-4 p-4', getInnerClasses(c.classes)).split(' ');

        return {
            ...c,
            component: ScrollArea,
            htmlComponent: 'ScrollArea',
            classes: outerClasses,
            props: {
              ...c.props,
              className: outerClasses.join(' '),
            },
            children: [
              {
                component: 'div',
                htmlComponent: 'div',
                classes: innerClasses,
                props: {
                  className: innerClasses.join(' '),
                },
                children: c.children
              },
              {
                component: ScrollBar,
                htmlComponent: 'ScrollBar',
                classes: [],
                props: { orientation: "horizontal" },
                children: [],
              }
            ],
            variant: null,
        };
      }

      return {
          ...c,
          component: ScrollArea,
          htmlComponent: 'ScrollArea',
          variant: null,
      };
  },
  imports: imports(['ScrollArea', 'ScrollBar'], TAG),
  setup: setup(TAG),
  variants: [
    'vertical-flex',
    'horizontal-flex',
    'bare',
  ],
  doc: {
      props: radixComponentDoc('ScrollArea.Root').props,
      variants: {
        'vertical-flex': 'Vertical scroll.',
        'horizontal-flex': 'Horizontal scroll.',
        'bare': 'Default behavior.',
      }
  }
};

registerShadcnComponent({
        ScrollArea, 
        ScrollBar
    },
    TAG,
);

export default ScrollAreaComponentParser;







