import { useCallback, useMemo } from "react";
import { DataSourceBinding } from "../data/data";
import { useApiMockupQuery } from "./hooks/useMockupQuery";
import { DataListChangeEvent } from "../data/data-engine";
import check from "@/vendors/check";

const $ = '$'; // Symbol.for('$');

export function MockupDataSource({ bind, source } : { bind: string, source: string }) {
    const { 
        data,
        loading,
        error,
        page, 
        setPage,
        setOpts,
    } = useApiMockupQuery(source);

    // Making sure updates are made right
    const dataToBind = useMemo(() => {
        
        if (loading) {
            return null;
        }

        let newData = check.array(data) ? [...data] : [];
        /*
        (newData as any)[$] = {
            loading,
            error,
            pagination: {
                currentPage: page,
                setPage
            },
            setOpts,
        };
        */

        return newData;
       
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(data), bind, loading, error, page, setPage, setOpts]
    );
    
    const onChange = useCallback((newData: DataListChangeEvent) => {
        console.log('MockupDataSource: DATA CHANGE "'+bind+'": ' + JSON.stringify(newData, null, 4));
        //setOpts(newData);
    }, [bind]);
    

    return <DataSourceBinding bind={bind} value={dataToBind} onChange={onChange} />;
}