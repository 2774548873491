import 'isomorphic-fetch';

export async function promptOpenAI(prompt, schema) {
    // Send a POST request to https://openai-quick-two.vercel.app with the JSON body { prompt, outputSchema }
    // The response will be a JSON object which we will need to return parsed
    // If the response is an error, throw it
  
    try {
      const response = await fetch('https://openai-quick-two.vercel.app/api', {
        method: 'POST',
        body: JSON.stringify({ prompt, outputSchema: JSON.stringify(schema), model: 'gpt-3.5-turbo' }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      const responseJson = await response.json();
      if (responseJson.error) {
        throw responseJson.error;
      }
  
      return responseJson;
    } catch (e) {
      throw e;
    }
  };