import { ComponentParser } from "../component.type";
import AvatarComponentParser from "./_tests-ok/avatar";
import BadgeComponentParser from "./_tests-ok/badge";
import ButtonComponentParser from "./_tests-ok/button";
import ProgressComponentParser from "./_tests-ok/progress";
import CalendarComponentParser from "./_tests-ok/calendar";
import InputComponentParser from "./_tests-ok/input";
import PopoverComponentParser from "./_tests-ok/popover";
import DialogComponentParser from "./_tests-ok/dialog";
import AlertComponentParser from "./_tests-ok/alert";
import SeparatorComponentParser from "./_tests-ok/separator";
import KeyboardComponentParser from "./_tests-ok/keyboard";
import CheckboxComponentParser from "./_tests-ok/checkbox";
import LabelComponentParser from "./_tests-ok/label";
import HoverCardComponentParser from "./_tests-ok/hover-card";
import DrawerComponentParser from "./_tests-ok/drawer";
import SheetComponentParser from "./_tests-ok/sheet";
import AlertDialogComponentParser from "./_tests-ok/alert-dialog";
import CardComponentParser from "./_tests-ok/card";
import AccordionComponentParser from "./_tests-ok/accordion";
import AspectRatioComponentParser from "./_tests-ok/aspect-ratio";
import CarouselComponentParser from "./_tests-ok/carousel";
import MenubarComponentParser from "./_tests-ok/menubar";
import PaginationComponentParser from "./_ok/pagination";
import ScrollAreaComponentParser from "./_tests-ok/scroll-area";
import ResizableComponentParser from "./_tests-ok/resizable";
import CollapsibleComponentParser from "./_tests-ok/collapsible";
import CommandComponentParser from "./_tests-ok/command";
import NavigationMenuComponentParser from "./_tests-ok/navigation-menu";
import ContextMenuComponentParser from "./_tests-ok/context-menu";
import DropdownMenuComponentParser from "./_tests-ok/dropdown";
import AutoCompleteComponentParser from "../layouts-ui/auto-complete";
import SelectComponentParser from "./_tests-ok/select";
import TableComponentParser from "./_tests-ok/table";
import TooltipComponentParser from "./_tests-ok/tooltip";
import SkeletonComponentParser from "./_tests-ok/skeleton";
import ToggleComponentParser from "./_tests-ok/toggle";
import ToggleGroupComponentParser from "./_tests-ok/toggle-group";
import SliderComponentParser from "./_tests-ok/slider";
import SwitchComponentParser from "./_tests-ok/switch";
import TabsComponentParser from "./_ok/tabs";
import DatePickerComponentParser from "./_tests-ok/date-picker";
import BgPatternComponentParser from "../fun/bg-pattern";
import BgMeshComponentParser from "../fun/bg-mesh";
import BgVideoComponentParser from "../fun/bg-video";
import FormComponentParser from "./form";
import RadioComponentParser from "./_tests-ok/radio";
import TextareaComponentParser from "./_tests-ok/textarea";
import RadioGroupComponentParser from "./_tests-ok/radio-group";

const components : ComponentParser[] = [
    AccordionComponentParser,
    AlertDialogComponentParser,
    AlertComponentParser,
    AspectRatioComponentParser,
    AvatarComponentParser,
    BadgeComponentParser,
    BgPatternComponentParser,
    BgMeshComponentParser,
    BgVideoComponentParser,
    ButtonComponentParser,
    CardComponentParser,
    CarouselComponentParser,
    CalendarComponentParser,
    CheckboxComponentParser,
    CollapsibleComponentParser,
    AutoCompleteComponentParser,
    CommandComponentParser,
    ContextMenuComponentParser,
    DatePickerComponentParser,
    DialogComponentParser,
    DrawerComponentParser,
    DropdownMenuComponentParser,
    FormComponentParser,
    HoverCardComponentParser,
    InputComponentParser,
    KeyboardComponentParser,
    LabelComponentParser,
    MenubarComponentParser,
    NavigationMenuComponentParser,
    PaginationComponentParser,
    PopoverComponentParser,
    ProgressComponentParser,
    RadioComponentParser,
    RadioGroupComponentParser,
    ResizableComponentParser,
    ScrollAreaComponentParser,
    SelectComponentParser,
    SeparatorComponentParser,
    SheetComponentParser,
    SkeletonComponentParser,
    SliderComponentParser,
    SwitchComponentParser,
    TableComponentParser,
    TabsComponentParser,
    TextareaComponentParser,
    ToggleComponentParser,
    ToggleGroupComponentParser,
    TooltipComponentParser,
];

export default components;