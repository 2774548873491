import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/shadcn/ui/drawer"
import { ComponentParser, ComponentTreeNode } from "../../component.type"
import { nonEmptyTrimmed } from "@/lib/utils"
import { childrenParsers, ComponentAliasConfig, imports, parentWrapperForTriggerableComponent, parseCardLikeComponent, setup } from "../_utils";
import { ComponentType } from "react";
import check from "@/vendors/check";
import { withTextAsChildOfType, withTextAsFirstChild } from "@/lib/parser/util";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
const data = [
  {
    goal: 400,
  },
  {
    goal: 300,
  },
  {
    goal: 200,
  },
  {
    goal: 300,
  },
  {
    goal: 200,
  },
  {
    goal: 278,
  },
  {
    goal: 189,
  },
  {
    goal: 239,
  },
  {
    goal: 300,
  },
  {
    goal: 200,
  },
  {
    goal: 278,
  },
  {
    goal: 189,
  },
  {
    goal: 349,
  },
]

export function DrawerDemo() {
  const [goal, setGoal] = React.useState(350)

  function onClick(adjustment: number) {
    setGoal(Math.max(200, Math.min(400, goal + adjustment)))
  }

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Button variant="outline">Open Drawer</Button>
      </DrawerTrigger>
      <DrawerContent>
        <div className="mx-auto w-full max-w-sm">
          <DrawerHeader>
            <DrawerTitle>Move Goal</DrawerTitle>
            <DrawerDescription>Set your daily activity goal.</DrawerDescription>
          </DrawerHeader>
          <div className="p-4 pb-0">
            <div className="flex items-center justify-center space-x-2">
              <Button
                variant="outline"
                size="icon"
                className="h-8 w-8 shrink-0 rounded-full"
                onClick={() => onClick(-10)}
                disabled={goal <= 200}
              >
                <Minus className="size-4" />
                <span className="sr-only">Decrease</span>
              </Button>
              <div className="flex-1 text-center">
                <div className="text-7xl font-bold tracking-tighter">
                  {goal}
                </div>
                <div className="text-[0.70rem] uppercase text-muted-foreground">
                  Calories/day
                </div>
              </div>
              <Button
                variant="outline"
                size="icon"
                className="h-8 w-8 shrink-0 rounded-full"
                onClick={() => onClick(10)}
                disabled={goal >= 400}
              >
                <Plus className="size-4" />
                <span className="sr-only">Increase</span>
              </Button>
            </div>
            <div className="mt-3 h-[120px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={data}>
                  <Bar
                    dataKey="goal"
                    style={
                      {
                        fill: "hsl(var(--foreground))",
                        opacity: 0.9,
                      } as React.CSSProperties
                    }
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <DrawerFooter>
            <Button>Submit</Button>
            <DrawerClose asChild>
              <Button variant="outline">Cancel</Button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

*/


const InnerContent : ComponentType<any> = ({ children }) => {
  return <>{children}</>;
}

const TAG = 'drawer';
const ALIASES : ComponentAliasConfig[] = [
  ['header', DrawerHeader, 'DrawerHeader'],
  ['content', InnerContent, 'DrawerInnerContent'],
  ['footer', DrawerFooter, 'DrawerFooter'],
  ['close', DrawerClose, 'DrawerClose'],
  ['title', DrawerTitle, 'DrawerTitle'],
  ['description', DrawerDescription, 'DrawerDescription'],
];

const DrawerComponentParser : ComponentParser = {
  name: 'Drawer',
  description: 'Shows a drawer popping from the bottom of the screen',
  tags: ['drawer'],

  refImplementation: `
/button %outline Open Drawer
	/drawer 
		/header mx-auto w-full max-w-sm
			/title Move Goal
			/description Set your daily activity goal.
		/content p-4 pb-0 mx-auto w-full max-w-sm
			/div flex items-center justify-center gap-2
				/button %outline h-8 w-8 shrink-0 rounded-full @size="icon"
					/icon minus size-4
					/span sr-only Decrease
				/div flex-1 text-center
					/div text-7xl font-bold tracking-tighter 350
					/div text-[0.70rem] uppercase text-muted-foreground Calories/day
				/button %outline h-8 w-8 shrink-0 rounded-full @size=icon
					/icon plus size-4
					/span sr-only Increase
			/div mt-3 h-[120px]
				/chart %bar [10, 20, 30, 45, 90, 120]
    /footer mx-auto w-full max-w-sm
        /button Submit w-full
        /close w-full @asChild
            /button %outline Cancel
  `.trim(),

  childrenParsers: [
    {
      name: 'DrawerHeader',
      tags: ['header'],
      description: 'The header of the drawer',
      parseTree: (c: ComponentTreeNode) => {
        return withTextAsChildOfType(DrawerTitle, 'DrawerTitle')({
          ...c,
          component: DrawerHeader,
          htmlComponent: 'DrawerHeader',
        });
      },
    },
    ...childrenParsers(ALIASES, TAG),
  ],

  parseTree: (c: ComponentTreeNode) : ComponentTreeNode => {
    const parsed = parseCardLikeComponent(c, {
      component: [DrawerContent, 'DrawerContent'],
      header: [DrawerHeader, 'DrawerHeader'],
      content: [InnerContent, 'DrawerInnerContent'],
      footer: [DrawerFooter, 'DrawerFooter'],
      title: [DrawerTitle, 'DrawerTitle'],
      description: [DrawerDescription, 'DrawerDescription'],
    });

    // Filter out InnerContent from children
    parsed.children = (parsed.children || []).map(child => {
      if (child.component === InnerContent) {
        if (nonEmptyTrimmed(child.props?.className) || (check.nonEmptyArray(child.classes) && child.classes.some(nonEmptyTrimmed))) {
          return {
            ...child,
            component: 'div',
            htmlComponent: 'div',
          };
        }
        
        return child.children || [];
      }
      return child;
    }).flat();

    return parsed;
  },

  wrapParent: parentWrapperForTriggerableComponent(
    [Drawer, 'Drawer'], 
    [DrawerTrigger, 'DrawerTrigger'], 
    [DrawerContent, 'DrawerContent'],
  ),

  imports: imports(['Drawer', 'DrawerClose', 'DrawerContent', 'DrawerDescription', 'DrawerFooter', 'DrawerHeader', 'DrawerTitle', 'DrawerTrigger'], 'drawer'),
  setup: setup('drawer'),
  variants: [],
};

registerShadcnComponent({
        Drawer,
        DrawerClose,
        DrawerContent,
        DrawerDescription,
        DrawerFooter,
        DrawerHeader,
        DrawerTitle,
        DrawerTrigger,
    },
    'drawer',
);

export default DrawerComponentParser;
