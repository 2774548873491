/*const defaultText = `
// ////////////////////////
// Sample page
// ////////////////////////
    
/style 
    /p text-justify text-[#5B5E66]

/div flex flex-col p-11 gap-3 mx-auto max-w-[600px]
    /h1 font-semibold text-slate-900 Welcome to Layouts.dev! bg-gradient-to-r from-green-400 to-blue-500 text-transparent bg-clip-text w-fit font-['Oooh_Baby'] text-5xl leading-snug mb-5
    /p text-[#5B5E66] We built this small editor to make your life simpler when developing interfaces with TailwindCSS and Shadcn/UI.

    /p text-[#5B5E66] The project is pretty young, so it's very much in beta and probably full of bugs, but we figured it would be better to release it and co-build it with you rather than keep it in a cave for the next 3 years
    /p text-[#5B5E66] We'd love to get your feedback and thoughts on how to best reduce the boring parts of shipping user interfaces before AI takes over.
    
    /p font-normal Happy Tailwinding! mt-5

    /p text-sm text-sky-900 text-right font-['Oooh_Baby'] text-xl
        — Severin & Alex
`.trim();
*/

const defaultText = `
// ////////////////////////
// Sample page
// ////////////////////////
/// Introduction ///
/div flex flex-col w-full 
	/div flex flex-row p-12 gap-1 justify-between items-end flex-col items-start gap-7 mx-auto max-w-[920] w-full
		/div flex flex-col gap-1 items-start
			/label Developer Preview text-gray-400 text-xs 
			/p Welcome to Layouts.dev font-medium text-lg
			/div flex flex-col gap-0
				/text Build multi-platform interfaces at superspeed. text-sm
		/a inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:text-accent-foreground h-10 px-4 py-2 typeface-body-md gap-2 rounded-lg shadow-sm bg-white hover:bg-stone-50 hover:bg-stone-50 
			hidden
			@href="https://docs.layouts.dev"
			@target="_blank"
			/text See the docs text-sm font-normal 
			/icon arrow up right size-3
/separator %horizontal 
	/div flex flex-col p-12
		/div flex flex-col gap-1
			/p Layouts is a notebook for building app interfaces. We are in beta, expect for there to be some bugs in the platform. Please don’t hesitate to connect with us to submit feedback and ask for support. font-normal text-sm text-gray-500 w-[380px]
			/button hidden %link w-fit p-0 hover:decoration-blue-500 gap-1
				/text Contact us text-sm text-blue-500 font-normal
				/icon arrow up right size-3 text-blue-500

/// Quickstart ///
	/div flex flex-col p-11 w-full gap-6 
		/label A short walkthrough on how to get started: text-sm font-normal 
		/div grid grid-cols-1 sm:grid-cols-2 w-full gap-6
			/div flex flex-col gap-2
				/h1 Step 1: Delete all syntax in the editor. text-sm text-gray-500
				/div lg:h-96 md:h-80 h-64 w-full flex flex-col items-center
						/image object-cover size-full rounded https://assets.layouts.dev/delete.svg
			/div flex flex-col gap-2 items-start
				/div flex flex-row items-center gap-2
						  /text Step 2: Grab a component by pressing: text-sm text-gray-500
						  /key %sm bg-stone-100 shadow
								/text / 
				 /div lg:h-96 md:h-80 h-64 w-full flex flex-col items-center
						/image object-cover size-full rounded https://assets.layouts.dev/menu.svg
			/div flex flex-col gap-2
				/hstack gap-1 w-full
						/h1 Step 3: Use text-sm text-gray-500
						/key %sm bg-stone-100 shadow
								/text >>>
						/text to grab the implementation text-sm text-gray-500
				/div lg:h-96 md:h-80 h-64 w-full flex flex-col items-center bg-[#F4F3F1] justify-center
						/image object-contain size-full rounded https://assets.layouts.dev/ref.svg
			/div flex flex-col gap-2 items-start
				/div flex flex-row items-center gap-2
						  /text Step 4: Add styling using TailwindCSS text-sm text-gray-500
				 /div lg:h-96 md:h-80 h-64 w-full flex flex-col items-center 
						/div flex flex-col bg-[#F4F3F1] size-full rounded items-center gap-12 relative justify-center
							/button w-fit bg-blue-500 rounded-lg shadow-sm shadow-blue-500/20 hover:shadow-inner hover:bg-blue-600
								/text Learn more text-sm font-normal
							/text text-[10px] font-['Roboto_Mono'] text-gray-400  "w-fit bg-blue-500 rounded-lg shadow-sm shadow-blue-500/20 hover:shadow-inner hover:bg-blue-600" absolute top-5 left-5 w-[220px] 
`.trim();

export { defaultText }