import { RedirectToSignIn, SignedIn, SignedOut } from "@clerk/clerk-react";

export default function AuthedPage({ children } : { children: React.ReactNode }) {
    return (
        <>
            <SignedIn>
                {children}
            </SignedIn>
            <SignedOut>
                <RedirectToSignIn />
            </SignedOut>
        </>
    );
}