import { DataProvider } from "@/vendors/data/data";
import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";


// Page provider providing info about the current page route and params
// as data vars to its children
export function PageProvider({ children } : { children: any }) {
    const params = useParams();
    const query = useSearchParams();
    
    const data = useMemo(() => (
        { $params: params, $query: query }
    ), [params, query]);

    console.log('PAGE PARAMS:', params);
    
    // return children;

    return (
        <DataProvider data={data} >
            {children}
        </DataProvider>
    );
}