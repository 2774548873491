import { ComponentType, Fragment, ReactNode, createElement } from "react";
import check from '@/vendors/check';
import split from 'string-split-by';
import reactTagNames from "react-tag-names";

import { isTailwindClass } from "@/lib/tailwind/tailwindClasses";
import { extractVarNamesFromText, getComponentName, getSingleVarNameFromText, removeEmptyProps } from "./util";
import { getComponents } from "./components";
import { cn, isCustomClassName, isSerializedFunction, nonEmptyTrimmed, onlyUnique, parsePropValue, unserializeFunction } from "../utils";
// import pretty from 'pretty';
import { normalizeTextComponent, removeWrappingQuotes } from "./components/shadcn/_utils";
import { ErrorBoundary } from "react-error-boundary";
import { Routes, Route, Router } from "react-router-dom";
import { ComponentParser, ComponentParserFunction, ComponentTreeNode } from "./components/component.type";
// import { stringifyHTMLAst } from "@/vendors/html-stringify";
import { applyStylesToComponentTreeNode, joinAdjacentTextChildrenWithSpaces } from "./parser-v3-utils";
import { Helmet } from "react-helmet";
import { ComponentParserConfig } from "./parser-config";
import { DataDisplay, DataPropsResolver, extractVarsFromProps } from "@/vendors/data/data-v2";
import { importLinesForComponents, setupCommandsForComponents } from "./importsRegistry";
import arrayUnique from "array-unique";
import { componentsByTag } from "./components/index.loader";

type ParsedLineObject = {
    key: string,
    indent: number,
    component: string,
    classes: string[],
    props: { [key: string]: any },
    text: string,
    variant: string | null,
    childrenLines?: ParsedLineObject[],
}

type ParsableComponent = string | null | ComponentTreeNode | ComponentTreeNode[];

/*
const getLineIndent = (line) => {
    let indent = 0;
    let l = line + '';
  
    // Parse tab and space indents
    // Remove 
    while((l.startsWith('\t') || l.startsWith(' ')) && l.length > 0) {
      if (l.startsWith('\t')) {
        indent += 1;
        l = l.substring(1); 
      } else if (l.startsWith('    ')) {
        indent += 1;
        l = l.substring(4);
      } else if (l.startsWith(' ')) {
        // Remove group of spaces which length is < 4
        l = l.substring(1);
      }
    }
  
    return indent;
  }
  */
  
const ErrorBoundaryDefaultFallback = ({ error, classes, htmlComponent, component, id }:any) => {
  return (
    <div className={cn(
      classes,
      "flex flex-col items-center justify-center bg-red-700 text-white font-medium text-sm p-2 gap-2",
    )}>{`Error on component: ${htmlComponent || getComponentName(component)}${check.nonEmptyString(id) ? ` (${id.includes('line') ? id.replace('_',' '): id})` : ''}`}
    {check.nonEmptyString(error?.message) && !(error.message.includes('minified react error')) &&  (
      <>
        {': '}
        <span className="font-normal text-center" >{error.message.replace('Error: ', '')}</span>
      </>
    )}
    </div>
  );
};

function removeEscapableBlockComments(text: string) {
    let result = '';
    let isInComment = false;
    let isEscape = false;

    for (let i = 0; i < text.length; i++) {
        const currentChar = text[i];
        const nextChar = i + 1 < text.length ? text[i + 1] : '';

        // Handle escape character
        if (currentChar === '\\' && !isEscape) {
            isEscape = true;
            continue;
        }

        if (!isInComment) {
            // Check for start of unescaped comment
            if (currentChar === '/' && nextChar === '*' && !isEscape) {
                isInComment = true;
                i++; // Skip next char as it is part of comment start
            } else {
                // If not escaping or comment, add character to result
                result += currentChar;
            }
        } else if (currentChar === '*' && nextChar === '/' && !isEscape) {
            // Check for end of comment
            isInComment = false;
            i++; // Skip next char as it is part of comment end
        }

        // Reset escape flag if it was set
        if (isEscape) {
            isEscape = false;
            result += '\\' + currentChar; // Add escaped character to result
        }
    }

    return result;
}

function removeComments(line: string) {
    // return stripComments(line);
    const parts = split(line, ' ');
    let commentIndex = parts.findIndex((p: string) => p.startsWith('//'));
    if (commentIndex === -1) {
      return line;
    }
    return parts.slice(0, commentIndex).join(' ');
}

function parseLine(line: string, i: number): ParsedLineObject | null {
    if(!check.nonEmptyString(line)) {
      return null;
    }

    // Remove empty lines
    if (line.trim().length === 0) {
      return null;
    }

    // Remove comment lines
    if (line.trim().startsWith('//')) {
      return null;
    }
  
    let indent = 0;
    let component = '';
    let text = '';
    let classes = [];
    let props : { [key: string]: any }  = {};
    let variant = null;
    
    // Remove comments in-line
    let l = removeComments(line + '');

    // Parse tab and space indents
    // Remove 
    while((l.startsWith('\t') || l.startsWith(' ')) && l.length > 0) {
      if (l.startsWith('\t')) {
        indent += 1;
        l = l.substring(1); 
      } else if (l.startsWith('    ')) {
        indent += 1;
        l = l.substring(4);
      } else if (l.startsWith(' ')) {
        // Remove group of spaces which length is < 4
        // FIXME: Mutualize behavior with the nIndents/fixIndent functions. Should be the same logic everywhere
        l = l.substring(1);
      }
    }
  
    // Get to the component name
    if (l.trim().startsWith('/')) {
      // Component lines
      l = l.split('/').slice(1).join('/').trim();
      component = l.split(' ')[0].trim().toLowerCase();
      l = l.substring(component.length).trim();
    } else if (l.trim().startsWith('@')) { 
      // Props lines
      l = l.trim();
      component = 'prop';
      if (!l.includes('=')) {
        let key = l.trim().substring(1);
        props[key] = true;
      } else {
        const [key, ...valueParts] = l.trim().substring(1).split('=');
        const rawValue = valueParts.join('=');

        props[key] = parsePropValue(rawValue);
      }
    } else if (l.trim().split(' ').map((w: string) => w.trim()).filter((w: string) => w.length > 0).every((w: string) => isTailwindClass(w) || isCustomClassName(w))) { 
      // Props lines
      l = l.trim();
      component = 'className';
      classes = l.split(' ').map((w: string) => w.trim().replaceAll('.', '')).filter((w: string) => w.length > 0);
    } else {
      // Text lines
      l = l.trim();
      component = 'text';
    }

    // Parse the rest of the line
    if (!['className', 'prop'].includes(component)) {
        const nextWords = split(l, ' ');
      
        // Parse tailwind classes, raw classes and text 
        for (const word of nextWords) {
          if (isTailwindClass(word.trim())) {
            classes.push(word.trim().toLowerCase());
          } else if (isCustomClassName(word)) {
            classes.push(word.trim().substring(1));
          } else if (/^\%[a-zA-Z0-9]+[a-zA-Z_0-9-/]*$/g.test(word)) {
            variant = word.trim().substring(1).toLowerCase();
          } else if (word.startsWith('(') && word.endsWith('...)')) {
            // Ignore openAI query here. Will be managed by textchange handler
            continue;
          } else if (word.startsWith('@')) {
            if (!word.includes('=')) {
              let key = word.trim().substring(1);
              props[key] = true;
            } else {
              const [key, ...valueParts] = word.trim().substring(1).split('=');
              const rawValue = valueParts.join('=');
        
              // Save the prop
              props[key] = parsePropValue(rawValue);
            }
          } else {
            text += removeWrappingQuotes(word) + ' ';
          }
        }
        text = text.trim();
    }
  
    // Return the component
    return {
      key: 'line_' + i,
      indent,
      component,
      classes,
      props,
      text,
      variant,
    }
  }
  
  export function parseTextLevel1(text: string) : ParsedLineObject[] {
    if (!check.nonEmptyString(text)) {
      return [];
    }
    const withoutBlockComments = removeEscapableBlockComments(text);
    const rawLines = split(withoutBlockComments, '\n', { 
      ignore: ['`', '«»', '[]', '()', '{}'],
    });

    return rawLines.map(parseLine);
  }

  // Fix the identation of over indented lines
  function fixOverIndentedLines(lines: ParsedLineObject[]) : ParsedLineObject[] {
    let homelessLines = [];
    const otherLines = [];
    let firstComponentFound = false;
    for (const line of lines) {
      if (!firstComponentFound && line.indent > 0) {
        homelessLines.push(line);
      } else {
        firstComponentFound = true;
        otherLines.push(line);
      }
    }

    if (check.nonEmptyArray(homelessLines)) {
      homelessLines = fixOverIndentedLines(homelessLines.map(l => ({ ...l, indent: l.indent - 1 })));
    }

    return [...homelessLines, ...otherLines];
  }

  function attachClassNameAndPropComponentsToParentComponent(tree: ParsableComponent) : ParsableComponent {
    if (check.array(tree)) {
      return tree.map(attachClassNameAndPropComponentsToParentComponent) as any;
    }

    if (!check.nonEmptyObject(tree)) {
      return tree;
    }

    if (!check.nonEmptyArray(tree.children)) {
      return tree;
    }

    // Sort children
    const classChildren = tree.children.filter(c => c.component === 'className');
    const propChildren = tree.children.filter(c => c.component === 'prop');
    const otherChildren = tree.children.filter(c => !['className', 'prop'].includes(c.component));

    // Merge classes and props
    const classes = [...(tree.classes || []), ...classChildren.map(c => c.classes).flat()];
    let props = { ...(tree.props || {}) };
    propChildren.forEach(c => {
      props = { ...props, ...(c.props || {}) };
    });

    // Return the new tree
    return {
      ...tree,
      classes,
      props,
      children: otherChildren.map(attachClassNameAndPropComponentsToParentComponent),
    };
  };

  export function linesToTree(rawLines: ParsedLineObject[]): ParsableComponent {
    if(!check.nonEmptyArray(rawLines)) {
      return [];
    }
  
    let lines = rawLines;
    
    // Remove empty lines
    lines = lines.filter(l => !!l);
    
    // Fix wrongly indented lines
    lines = fixOverIndentedLines(lines);
  
    const components : ParsedLineObject[] = [];
    lines.forEach(line => {
      if (line === null) {
        return;
      }
  
      if (line.indent === 0) {
        components.push(line);
      }
  
      if (line.indent > 0) {
        if (components.length === 0) {
          throw new Error('Parser error: indentation. This should never happen. Please report this issue.')
        }
        const parent = components[components.length - 1];
        if (!parent.childrenLines) {
          parent.childrenLines = [];
        }
        parent.childrenLines.push({...line, indent: line.indent - 1});
      }
    });
  
    const tree = components.map(c => {
      const { childrenLines, indent, ...rest } = c;
  
      return {
        ...rest,
        children: linesToTree(childrenLines || []),
      };
    });

    // Attach the className and prop components to the parent component
    return attachClassNameAndPropComponentsToParentComponent(tree as ParsableComponent);
  }

  const COMPONENT_PARSERS = getComponents({});


  function getComponentParserForComponent(parsers:  ComponentParser[], component: any) : ComponentParser | null {
    if (!check.nonEmptyString(component)) {
      return null;
    }
    if (!check.nonEmptyArray(parsers)) {
      return null;
    }

    const normalizedComponent = component.replaceAll('/', '').trim().toLowerCase();
    return parsers.find(p => p.tags?.map(t => t.trim().toLowerCase()).includes(normalizedComponent)) || null;
  }

  function getExactComponentParserForComponent(parsers:  ComponentParser[], component: any) : ComponentParser | null {
    if (!check.nonEmptyString(component)) {
      return null;
    }
    if (!check.nonEmptyArray(parsers)) {
      return null;
    }

    const normalizedComponent = component.replaceAll('/', '').trim().toLowerCase();
    return parsers.find(p => p.tags?.[0].toLowerCase() === normalizedComponent) || null;
  }

  export function getComponentParserForPath(componentChain: string[], parsers?:  Record<string, ComponentParser>) : ComponentParser | null {
      console.log('[DEBUG] FIXED: Getting component parser for chain: ', componentChain)
      let allParsers = parsers || { ...componentsByTag };
      if (!check.nonEmptyArray(componentChain) || componentChain.some(c => !check.nonEmptyString(c))) {
        throw new Error('[getComponentParserForPath] Invalid component chain argument');
      }

      if (!check.nonEmptyObject(allParsers)) {
        console.log('[DEBUG] FIXED: Parsers object: ', allParsers)
        throw new Error('[getComponentParserForPath] invalid parsers object')
        
      }

      const component = componentChain[0].replaceAll('/', '').trim().toLowerCase();
      const restOfChain = componentChain.slice(1);
      if (!check.nonEmptyString(component)) {
        throw new Error('[getComponentParserForPath] Invalid component name');
      }

      const parser = allParsers[component];

      // If we're at the end of the chain, return the parser
      if (restOfChain.length === 0) {
        return parser || null;
      }

      // Else, build new parsers object for the rest of the chain
      const newParsers = { ...allParsers };
      if (parser) {
        (parser.childrenParsers || []).forEach(childParser => {
          (childParser.tags || []).forEach(childParserTag => {
              newParsers[childParserTag] = childParser;
          });
        });
      };

      // Process the rest of the chain
      return getComponentParserForPath(restOfChain, newParsers);
  }

  function parseComponent(c: ParsableComponent, componentsParsersOverride: ComponentParser[] = [], config: ComponentParserConfig): any {
    if (check.array(c)) {
      return c.map(cc => parseComponent(cc, componentsParsersOverride, config)).filter(cc => !!cc);
    }

    if (check.nonEmptyString(c)) {
      console.log('Parse component: component IS string: '+c)
      // return c;
      return parseComponent(normalizeTextComponent(c), componentsParsersOverride, config);
    }

    if (!check.nonEmptyObject(c)) { 
      console.log('Parse component: component IS NOT object: ',c)
      return null;
    }

    // If component is text, return the normalized text
    // FIXME: Need to factorize that better with variable management
    if (c.component === 'text') {
      let normalized = normalizeTextComponent(c);
      if (!normalized) {
        return null;
      }
      return {
        ...normalized,
        children: (normalized.children || []).map((c: any) => {
          return parseComponent(c, componentsParsersOverride, config);
        }),
      };
    }

    let { component, htmlComponent, classes, props, variant = null, children, ...rest } = c;
    let text : string | null = c.text || null;

    /*
    if (component === 'text') {
      return text || (check.nonEmptyArray(children) ? children.filter(check.nonEmptyString).join('') : null);
    }
    */

    let componentParser : ComponentParserFunction = (comp: ComponentTreeNode) => comp;
    let childrenParsers : ComponentParser[] = [];
    if (check.string(component)) {
      const matchningOverideParser = getComponentParserForComponent(componentsParsersOverride, component);
      if (matchningOverideParser && check.function(matchningOverideParser.parseTree)) {
        componentParser = matchningOverideParser.parseTree;
      } else if (COMPONENT_PARSERS[component]) {
        childrenParsers = COMPONENT_PARSERS[component].childrenParsers || [];
        if (check.function(COMPONENT_PARSERS[component].parseTree)) {
          componentParser = COMPONENT_PARSERS[component].parseTree!;
        }
      } else if (!reactTagNames.includes(component) && component !== 'text') {
        // TO BE CHECKED: Could return null instead of a div
        // componentParser = (comp) => ({ ...comp, component: 'div', htmlComponent: 'div' });
        return null;
      }
    }

    let nextParsers : ComponentParser[] = [
      ...(componentsParsersOverride || []),
      ...childrenParsers,
    ];

    // Check if component needs to be wrapped by children components
    // If so, create a wrapper
    let wrapResult : (comp: ComponentTreeNode) => ComponentTreeNode = (comp: ComponentTreeNode) => comp;
    const wrapParser = (c.children || [])
      .filter((c: ComponentTreeNode) => check.nonEmptyObject(c) && check.nonEmptyString(c.component) && c.component !== 'text')
      .map((c: ComponentTreeNode) => COMPONENT_PARSERS[c.component as string])
      .filter((c: any) => !!c)
      .find((parser: any) => check.function(parser.wrapParent));
    if (wrapParser) {
      wrapResult = (comp) => parseComponent(wrapParser.wrapParent!(comp, config), nextParsers, config);
    }


    let allChildren : any[] | null = [];
    let textAddedAsChild = false;

    if (check.nonEmptyString(text) && component !== 'foreach' && component !== 'data') {
      // If text contains varnames like $myVar, transform the text into an array of children and replace the vars by a component <DataVar name="myVar" />
      const variables = extractVarNamesFromText(text);
      if (variables.length > 0) {
        if (component === 'text') {
          // If the component is a text component, transform it into a span
          // Note: this is important to make sure text components output the variable values as children
          component = 'span';
          htmlComponent = 'span';
          text = null;
        }

        let textToParse = text || undefined;
        variables.forEach(v => {       
          let varIndex = textToParse?.indexOf(v);
          console.log('varIndex: ', varIndex)
          if (varIndex === undefined || varIndex === -1) {
            return;
          }

          const beforeVariable = textToParse?.substring(0, varIndex);
          textToParse = textToParse?.substring(varIndex + v.length);

          if (check.nonEmptyString(beforeVariable?.trim())) {
            allChildren!.push(beforeVariable?.trim());
          }

          allChildren!.push({
            component: DataDisplay, 
            htmlComponent: 'DataDisplay',
            classes: [],
            props: { bind: v /* , key: 'var_' + v */},
            text: null,
            variant: null,
            children: null,
          });
        });

        // Push the remaining text
        if (check.nonEmptyString(textToParse?.trim())) {
          allChildren!.push(textToParse?.trim());
        }
      } else if (check.nonEmptyString(component)) {
        if (reactTagNames.includes(component) && component !== 'text' && !getComponentParserForComponent(componentsParsersOverride, component) /* && !COMPONENT_PARSERS[component] */) {
          allChildren.push(text);
          textAddedAsChild = true;
        }
      }
    }
    if (check.nonEmptyArray(children)) {
      allChildren = allChildren.concat(parseTree(children, nextParsers, config).filter(c => !!c));
    }

    if (allChildren.length === 0) {
      allChildren = null;
    }

    // If some of the component children have variables inside their props, 
    // we need to wrap them in a DataPropsResolver
    if (check.nonEmptyArray(allChildren)) {
      allChildren = allChildren.map((c: any) => {
        if (!check.nonEmptyObject(c) || !check.nonEmptyObject(c.props)) {
          return c;
        }

        if (c.component === DataDisplay) {
          return c;
        }

        const varsInProps = extractVarsFromProps(c.props);
        if (!check.nonEmptyArray(varsInProps)) {
          return c;
        }

        return {
          component: DataPropsResolver,
          htmlComponent: 'DataPropsResolver',
          classes: [],
          props: {},
          text: null,
          variant: null,
          children: [c],
        };
    });
  }

    // Parse components
    let parsed;

    try {
      parsed = componentParser({ ...c, component, htmlComponent, text: text || undefined, children: allChildren }, config);
    } catch(err) {
      return {
        component: ErrorBoundaryDefaultFallback,
        htmlComponent: 'Error::' + htmlComponent,
        props: { 
          error: err,
          component,
          htmlComponent,
          id: rest.key,
        },
        children: [],
      }
    }
    
    if(!check.nonEmptyObject(parsed)) {
      if (check.nonEmptyString(parsed)) {
        return parsed;
      }
      return null;
    }

    const {
      key: parsedId,
      component: parsedComponent,
      htmlComponent: parsedHtmlComponent,
      classes: parsedClasses,
      props: parsedProps,
      text: parsedText,
      variant: parsedVariant,
      children: parsedChildren,
      _dontParse,
      comments,
    } = parsed;
    
    const out = ({
      component: parsedComponent,
      htmlComponent: check.nonEmptyString(parsedHtmlComponent) ? parsedHtmlComponent : getComponentName(parsedComponent),
      id: parsedId,
      props: {
        ...parsedProps,
        className: cn(parsedClasses),
        // Removing this as it contaminates output code. Need to check if required by some components
        // variant: parsedVariant,
      },
      children: parsedChildren,

      // Metadata
      classes: cn(parsedClasses).split(' ').filter(nonEmptyTrimmed),
      text: textAddedAsChild ? null : parsedText, // (component === 'text') ? parsedText : null,
      variant: parsedVariant,
      _dontParse, // Still useful for images
      comments,
    });

    // Return the parsed component after wrapping it if necessary
    return wrapResult(out);
}

function parseTree(tree: any, componentsParsersOverride: ComponentParser[] = [], config: ComponentParserConfig): ComponentTreeNode[] {
  if (!check.nonEmptyArray(tree)) {
    return [];
  }

  return tree.map(t => parseComponent(t, componentsParsersOverride, config)).filter(c => !!c);
}

export function parseRootTree(tree: any, componentsParsersOverride: ComponentParser[] = [], config: ComponentParserConfig) {
  if (!check.nonEmptyArray(tree)) {
    return [];
  }

  // Add a wrapper component as direct children may need a parent to wrap them
  const root : ComponentTreeNode = {
    component: 'div',
    htmlComponent: 'div',
    classes: [
      'treeroot',
      'w-full',
      'min-h-full',
    ],
    props: {},
    text: null,
    variant: null,
    children: tree,
  }

  // Experimental: parse markdown syntax prior to parsing the tree
  const markdownParsed = root; // experimentalMarkdownTransformOfTextComponents(root);

  // Apply styles from the /style components there, before anything 
  const styledRoot = applyStylesToComponentTreeNode(markdownParsed as ComponentTreeNode);


  let out = parseComponent(styledRoot, componentsParsersOverride, config);

  const returnIfNotEmpty = (c:any): any[]  => {
    if (check.emptyArray(c)) {
      return [];
    }
    if (check.nonEmptyArray(c)) {
      return c.map(returnIfNotEmpty).filter(c => !!c).flat();
    }
    if (check.nonEmptyObject(c)) {
      return [c].flat();
    }
    if (check.nonEmptyString(c) && nonEmptyTrimmed(c)) {
      return [c].flat();
    }
    return [];
  }

  // Remove wrapper if we can
  if (check.nonEmptyObject(out) && out.classes.includes('treeroot')) {
    return returnIfNotEmpty(out.children);
  }

  return returnIfNotEmpty([out].filter(c => !!c));
}

export function treeToComponents(tree: any) : string[] {
  const components : string[] = [];

  if (check.array(tree)) {
    return tree.map(treeToComponents).flat().filter(onlyUnique).sort();
  }

  if (!check.nonEmptyObject(tree)) {
    return components;
  }

  if (check.nonEmptyString(tree.component)) {
    components.push(tree.component);
  } else if (check.nonEmptyString(tree.htmlComponent)) {
    components.push(tree.htmlComponent);
  }

  if (check.nonEmptyArray(tree.children)) {
    components.push(...treeToComponents(tree.children));
  }

  return components.filter(onlyUnique).sort();
}

export function treeToImports(tree: any, config: ComponentParserConfig, additionalComponents?: string[]) : string[] {
  const components = [...treeToComponents(tree), ...(additionalComponents || [])];

  if(!check.nonEmptyArray(components)) {
    return [];
  }

  return importLinesForComponents(components, config.target);
}

export function treeToSetupInstructions(tree: any, config: ComponentParserConfig, additionalComponents?: string[]) : string[] {
  const components = [...treeToComponents(tree), ...(additionalComponents || [])];
  if(!check.nonEmptyArray(components)) {
    return [];
  }

  return setupCommandsForComponents(components, config.target);
}

/*
export function treeToSetupInstructions(tree: any): string[] {
  function componentToImport(c: any) {
    let setup: string[] = [];
    if (!check.nonEmptyObject(c)) {
      return [];
    }

    const { component, children } = c;
    
    if (check.nonEmptyString(component)) {
      const parser = COMPONENT_PARSERS[component];
      if (parser && check.nonEmptyArray(parser.setup)) {
        parser.setup.forEach(i => setup.push(i));
      }
    }
    if (check.nonEmptyArray(children)) {
      children.map(componentToImport).forEach(i => setup = setup.concat(i));
    }

    return setup.filter(onlyUnique);
  }

  if (check.array(tree)) {
    return tree.map(componentToImport).flat().filter(onlyUnique);
  }

  if (check.nonEmptyObject(tree)) {
    return componentToImport(tree);
  }

  return [];
}
*/
/*
function treeToHTML(tree: any, config: ComponentParserConfig) {
    const parsed = parseRootTree(tree, config);

    function toAst(c: any): any {
      if (check.array(c)) {
        return c.map(toAst);
      }
      if (check.string(c)) {
        return {
          type: 'text',
          content: c,
        };
      }
      if (!check.nonEmptyObject(c)) {
        return {
          type: 'text',
          content: '',
        };
      }

      if (c.component === 'text') {
        return {
          type: 'text',
          content: c.text || (c.children || []).join(''),
        };
      }

      const { htmlComponent, props, children } = c;

      const { className, variant, id, _dontParse, ...restProps } = props || {};
      const attrs : { [ key:string ]: any } = {};
      if (check.nonEmptyString(className)) {
        attrs.className = className;
      }
      if (check.nonEmptyString(variant)) {
        attrs.variant = variant;
      }

      return {
        type: 'tag',
        name: htmlComponent,
        attrs: {...attrs, ...restProps},
        voidElement: !children || check.emptyArray(children),
        children: toAst(children),
      };
    }

    let license = `
///////////////////////////////////////////////////////////////////////////////////////////////////
// Generated by Layouts.dev
// ------------------------------------------------------------------------------------------------
// Copyright Creative Robots Inc. ${new Date().getFullYear()} - All rights reserved
// Commercial use not allowed without a Pro license
// https://layouts.dev
///////////////////////////////////////////////////////////////////////////////////////////////////`.trim();

    const imports = treeToImports(tree).join('\n');

    let setupInstructions = treeToSetupInstructions(tree).filter(check.nonEmptyString).map(l => '// '+l).join('\n').trim();
    if (check.nonEmptyString(setupInstructions)) {
      setupInstructions = `
// ------------------------------------------------------------------------------------------------
// Project setup instructions
// ------------------------------------------------------------------------------------------------
${setupInstructions}
// ------------------------------------------------------------------------------------------------`.trim();
    }

    const prefix = `export function Page() {
\treturn (`;

    const indent = (lines: string, n: number) => lines.split('\n').map(l => ''.padEnd(n, '\t') + l).join('\n');

    const html = indent(pretty(
      stringifyHTMLAst(toAst(parsed))
        .replaceAll(/</g, '\n<')
        .replaceAll(/>/g, '>\n')
        .replaceAll(/<\//g, '\n</')
        .replaceAll('\n\n', '\n')
        .replaceAll('\n\n', '\n')
        .replaceAll('\n\n', '\n')
        .replaceAll('\n\n', '\n')
        .replaceAll('  ', '\t')
      ), 2);

    const suffix = `\t);
}`;

    return [license, setupInstructions, imports, [prefix, html, suffix].join('\n')].filter(check.nonEmptyString).join('\n\n');
}
*/

const canComponentHaveBoundary = (component: ComponentType) => {
  if ([Routes, Route, Router, Helmet, 'link'].some(c => c === component)) {
    return false;
  }
  
  return check.string(component) && reactTagNames.includes(component);
}

function unserializePropsFunctions(props: any) {
  if (!check.nonEmptyObject(props)) {
    return props;
  }

  const unserialized = { ...props };
  Object.entries(props).forEach(([key, value]) => {
    if (isSerializedFunction(value)) {
      unserialized[key] = unserializeFunction(value);
    }
  });

  return unserialized;
}

export function treeNodetoReactComponent(c: any, allowErrorBoundary = true): ReactNode | ReactNode[] {
  const forbiddenComponents : any[] = ['TopBar', 'ImageCard', 'BgImageCard', DataPropsResolver];
  const isForbiddenComponent = (comp: ComponentTreeNode) => true // forbiddenComponents.some(fc => fc === comp.component || fc === comp.htmlComponent);
  const childrenCanHaveErrorBoundary = c?.props?.asChild !== true && !c?.props?.role && !isForbiddenComponent(c);

  if (check.array(c)) {
    if (!check.nonEmptyArray(c)) {
      return null;
    }
    return c.map(child => treeNodetoReactComponent(child, childrenCanHaveErrorBoundary)).filter(c => !!c);
  }
  if (check.string(c)) {
    return c;
  }
  if (!check.nonEmptyObject(c)) {
    return null;
  }

  const { component, htmlComponent, id, props: rawProps = {}, children, classes } = joinAdjacentTextChildrenWithSpaces(c as ComponentTreeNode) as any;
  const props = unserializePropsFunctions(rawProps);

  if (component === 'text') {
    const normalizedProps = removeEmptyProps(props);
    if (!check.nonEmptyObject(normalizedProps)) {
        return c.text;
    }
    return createElement('span', normalizedProps, c.text);
  }

  try {
    // Define children as well as possible
    // Some Radix wrapper component differentiate between children=undefined and children=null or children=[]
    let compChildren = undefined;
    let filteredChildren = (check.nonEmptyArray(children) ? children.filter(c => !!c) : []);
    if (check.nonEmptyArray(filteredChildren)) {
      if (filteredChildren.length === 1) {
        compChildren = treeNodetoReactComponent(filteredChildren[0], childrenCanHaveErrorBoundary);
      } else {
        compChildren = treeNodetoReactComponent(filteredChildren, childrenCanHaveErrorBoundary);
      }
    }
    if (check.nonEmptyArray(compChildren) && compChildren.length === 1) {
      compChildren = compChildren[0];
    }
    const element : ReactNode = createElement(component, component === Fragment ? { key: props?.key || undefined } : { id, ...props, key: c?.key || props?.key || id }, compChildren);

    // Add default error boundary for HTML components only
    // TODO: Find a way of adding it to custom components as well, without breaking them
    //if (['Router', 'Route', 'Routes'].includes(htmlComponent) || !canComponentHaveBoundary(component)) {
    if (!canComponentHaveBoundary(component) || !allowErrorBoundary)  {// (!check.nonEmptyString(component) || !canComponentHaveBoundary(component)) {
      return element;
    }
    const fallbackRender = ({error} : {error: any}) => (<ErrorBoundaryDefaultFallback 
      component={component}
      classes={classes}
      htmlComponent={htmlComponent}
      id={id}
      error={error}
    />);
    const boundary = createElement(ErrorBoundary, { fallbackRender }, element);
    return boundary;

  } catch(err) {
    console.error('Error parsing component', err);
    return null;
  }
}