import { LocalStorageLRU } from '@cocalc/local-storage-lru';
import check from '@/vendors/check';
import queryString from 'query-string';
import { removeEmptyProps } from '@/lib/parser/util';
import upperlower from "upperlower";
import { sha1 } from 'object-hash';
import { nonEmptyTrimmed } from '@/lib/utils';
import 'isomorphic-fetch';
import { getImageExtension } from '../mime';

const cache = new LocalStorageLRU();

function createSrcSetFromCloudflareUrl(url: string) {
    if (!check.nonEmptyString(url) || !url.includes('/cdn-cgi/image/format=auto,fit=scale-down,width=1280/')) {
        return undefined;
    }

    return [320, 640, 960, 1280, 1920, 2560].map(size => {
        return `${url.replace('/cdn-cgi/image/format=auto,fit=scale-down,width=1280/', `/cdn-cgi/image/format=auto,fit=scale-down,width=${size}/`)} ${size}w`;
    });
}

function autoAddSrcSetIfUndefined(metadata: { [key: string]: any }) {
    if (!check.nonEmptyObject(metadata)) {
        return metadata;
    }

    if (!check.nonEmptyArray(metadata.srcSet)) {
        const srcSet = createSrcSetFromCloudflareUrl(metadata.src);
        if (check.nonEmptyArray(srcSet)) {
            return {
                ...metadata,
                srcSet,
            }
        }
    }

    return metadata;
}

function appendQueryIfExists(url: any, query: { [key: string]: string | null }) : string {
    if (!check.nonEmptyString(url)) {
        return url;
    }

    const {url: baseUrl, query: prev} = queryString.parseUrl(url);
    const newQuery = { ...(prev || {}), ...(query || {}) };

    return queryString.stringifyUrl({ url: baseUrl, query: removeEmptyProps(newQuery) });
}

function getCacheKey(source: string, query: string) {
    return `imageOfUrl:::${source}:::${query}`;
}

// Metadata cache is used to store info like credits, filename, etc.
function getMetadataCacheKey(source: string, query: string) {
    return `imageOfUrl/metadata:::${source}:::${query}`;
}

function getUrl2QueryCacheKey(url: string) {
    return `imageOfUrl:::src2query:::${sha1(url)}`;
}

function updateImageMetadata(source: string, query: string, metadata: { [key: string]: any }) {
    if (!check.nonEmptyObject(metadata)) {
        return;
    }

    const cacheKey = getMetadataCacheKey(source, query);

    let prev = cache.get(cacheKey);
    if (!check.nonEmptyObject(prev)) {
        prev = {};
    }

    const newMetadata = { ...prev, ...metadata };
    cache.set(cacheKey, newMetadata);

    // Save URL to query and source
    if (check.nonEmptyString(metadata.src)) {
        cache.set(getUrl2QueryCacheKey(metadata.src), { source, query });
    }
}


function getImageMetadata(source: string, query: string): Record<string, any> {
    const cacheKey = getMetadataCacheKey(source, query);
    let value = cache.get(cacheKey);
    if (!check.nonEmptyObject(value)) {
        value = {};
    }
    return value;
}

function getQueryFromUrl(url: string) {
    const cacheKey = getUrl2QueryCacheKey(url);
    return cache.get(cacheKey) as { source: string, query: string } | undefined;
}

// This function generates a unique name for an image
const imagesOnPage : Record<string, string> = {};
export function getInstantNameForImageQuery(source: string, query: string, mimeType?: string) {
    let ext = 'jpg';
    if (check.nonEmptyString(mimeType)) {
        ext = getImageExtension(mimeType) || 'jpg';
        // console.log('Extension for mime "' + mimeType + '": ' + ext)
        // console.log('Query for extnsion: ', query)
    }

    // If the name is set in cached metadata, return it
    const metadata = getImageMetadata(source, query);
    if (check.nonEmptyString(metadata?.name)) {
        return metadata.name;
    }

    // Else, invent a name
    let base = 'image';
    if (check.nonEmptyString(query) && !query.includes('://')) {
        base = upperlower(query.split('|')[0], 'kebapcase');
    }

    let name = base;
    if (check.string(imagesOnPage[name]) && imagesOnPage[name] !== getCacheKey(source, query)) {
        for (let i=0; check.string(imagesOnPage[name]) && imagesOnPage[name] !== getCacheKey(source, query); i++) {
            name = `${base}_${i}`;
        }
    }
    imagesOnPage[name] = getCacheKey(source, query);

    return name + '.' + ext;
}

export function getInstantMetadataForImageUrl(url: string, mimeType?: string) {
    const { source, query } = getQueryFromUrl(url) || {};
    console.log('Assets: Source and query for URL "' + url + '":', source, query);
    if (!nonEmptyTrimmed(source) || !nonEmptyTrimmed(query)) {
        return null;
    }

    return getInstantMetadataForImageQuery(source, query);
}


export async function getMetadataForImageQuery(source: string, query: string) {
    if (!nonEmptyTrimmed(source) || !nonEmptyTrimmed(query)) {
        return null;
    }

    // 0. Check if we already have the metadata
    const existing = getImageMetadata(source, query);
    if (existing?._final) {
        const { _final, ...rest } = existing;
        return rest;
    }

    // 1. Get the URL for the image (which will download part of the metadata)
    /*
    const url = await getProductionUrlForImageQuery(source, query);
    if (!url) {
        return null;
    }
    updateImageMetadata(source, query, { src: url });
    */
    const url = await getProductionUrlForImageQuery(source, query);
    if (!url) {
        return null;
    }

    // 2. Fetch the mime type of the file and its content it is svg
    const apiUrl = `https://illustrations.dev/soft-metadata?url=${encodeURIComponent(url)}`;
    const response = await fetch(apiUrl);
    const json = await response.json();
    /* if (check.nonEmptyString(json?.name)) {
        updateImageMetadata(source, query, { name: json.name });
    } */
    if (check.nonEmptyString(json?.mimeType)) {
        updateImageMetadata(source, query, { mimeType: json.mimeType });
    }
    if (check.nonEmptyString(json?.svgContent)) {
        updateImageMetadata(source, query, { svgContent: json.svgContent });
    }

    console.log('Metadata for image "' + source + ': ' + query + '":', json);
    
    /*
   const response = await fetch(url);
    const blob = await response.blob();
    const blobMimeType = blob.type;
    const headerMimeType = response.headers.get('content-type');
    const mimeType = headerMimeType || blobMimeType;
    const name = getInstantNameForImageQuery(source, query, mimeType);
    updateImageMetadata(source, query, { name, mimeType });
    if (mimeType === 'image/svg+xml') {
        const text = await blob.text();
        updateImageMetadata(source, query, { svgContent: text });
    }
    */

    // 3. If we're all good, set metadata as final
    //    while making sure we have at least a name
    const finalMetadata = getImageMetadata(source, query);
    updateImageMetadata(source, query, {  
        name: getInstantNameForImageQuery(source, query, finalMetadata?.mimeType),
        src: url,
        ...finalMetadata,   
        _final: true 
    });

    // 3. Return the metadata
    const metadata = getImageMetadata(source, query);

    const { _final, ...rest } = metadata;
    return autoAddSrcSetIfUndefined(rest);
}

export function getInstantMetadataForImageQuery(source: string, query: string) {
    if (!nonEmptyTrimmed(source) || !nonEmptyTrimmed(query)) {
        return null;
    }

    // 0. Return cached data if it is available
    const existing = getImageMetadata(source, query);
    if (existing?._final) {
        const { _final, ...rest } = existing;
        return autoAddSrcSetIfUndefined(rest);
    }

    // CACHE MISS
    // Trigger an async query to get full metadata
    getMetadataForImageQuery(source, query)
        .catch((err) => {
            console.error('Error fetching metadata for image "' + source + ': ' + query + '"', err);
        });

    // RETURN FALLBACK METADATA
    return autoAddSrcSetIfUndefined({
        name: getInstantNameForImageQuery(source, query, existing?.mimeType),
        mimeType: 'image/jpeg',
        src: getInstantUrlForImageQuery(source, query),
    });
}


export function getInstantUrlForImageQuery(source: string, query: string, skipCache = false) {
    // Invalidate empty queries
    if (!check.nonEmptyString(query) || !check.nonEmptyString(query.trim()) || !check.nonEmptyString(source) || !check.nonEmptyString(source.trim())) {
        return null;
    }

    // Get URL from cache if available
    if (!skipCache) {
        const cacheKey = getCacheKey(source, query);
        const cachedUrl = cache.get(cacheKey);
        if (check.nonEmptyString(cachedUrl)) {
            return cachedUrl;
        }

    // CACHE MISS
    // Trigger an async query to get full production URL
    // This will ensure that a better version of the URL is fetched and cached for next calls
    // FIXME: We should store the promises to avoid colling this multiple times
    getProductionUrlForImageQuery(source, query)
        .catch((err) => {
            console.error('Error fetching production URL for image "' + source + ': ' + query + '"', err);
        });

    }


    // RETURN FALLBACK URL
    // Handle unsplash
    if (source === 'unsplash') {
        // We return a directly usable URL
        return `https://unsplash.illustrations.dev/api?query=${encodeURIComponent(query)}&redirectToFirstImage`;
    }

    // Handle illustrations.dev
    return `https://illustrations.dev/${encodeURIComponent(`${source.trim()}:${query.trim()}`)}`;
}


// This function should be called everytime an unsplash image is displayed to the user
// If the image stays in use for more than 5 seconds, we consider a 'download' event should be triggered
// This function checks whether such an event was already triggered and triggers it if necessary
// It is necessary to comply with Unsplash's API terms
export function logUseOfUnsplashImage(metadata: Record <string, any>) {
    // Only run for unsplash images with valid metadata
    if (
        !check.nonEmptyObject(metadata) 
        || !check.nonEmptyString(metadata.unsplashId) 
        || !check.nonEmptyString(metadata.downloadEventUrl) 
        || !check.nonEmptyString(metadata.downloadedAt)
    ) {
        return;
    }

    // Only run if the image was downloaded more than 5 seconds ago
    const downloadedAt = new Date(metadata.downloadedAt);
    if (
        isNaN(downloadedAt.getTime()) 
        || ((new Date().getTime() - downloadedAt.getTime()) < 5000)
    ) {
        return;
    }

    // Only run if the download event was not already triggered
    const downloaded = cache.get(`unsplashDownloaded:::${metadata.unsplashId}`);
    if (downloaded) {
        return;
    }

    // Download event should be sent.
    // Send it!
    try {
        cache.set(`unsplashDownloaded:::${metadata.unsplashId}`, true);
        const apiUrl = 'https://unsplash.illustrations.dev/api/trigger-download-event?url=' + encodeURIComponent(metadata.downloadEventUrl);
        fetch(apiUrl)
            .then(() => {  
                console.log('[Unsplash] Download event triggered for Unsplash image', metadata.unsplashId);
            })
            .catch((err) => {
                console.error('[Unsplash] Error triggering download event for Unsplash image', err);
            });
    } catch(err: any) {
        console.error('[Unsplash] Error triggering download event for Unsplash image', err);
    }
}

async function getProductionUrlForUnsplashQuery(query: string, abortSignal?: AbortSignal) {
    if (!nonEmptyTrimmed(query)) {
        return null;
    }

    // Get URL from cache if available
    const source = 'unsplash';
    const cacheKey = getCacheKey(source, query);
    const cachedUrl = cache.get(cacheKey);
    if (check.nonEmptyString(cachedUrl)) {
        return cachedUrl;
    }

    // Get the URL from unsplash
    try {
        const apiUrl = `https://unsplash.illustrations.dev/api?query=${encodeURIComponent(query)}`;
        const response = await fetch(apiUrl, { signal: abortSignal });
        const json = await response.json();
        const imageUrl = json?.response?.results?.[0]?.urls?.full;

        // Make sure image is valid
        if (!check.nonEmptyString(imageUrl)) { 
            return null;
        }

        // If it is, grab its metadata
        const utms = {
            utm_source: 'layouts.dev',
            utm_medium: 'referral',
        };
        const metadata = {
            src: imageUrl,
            unsplashId: json?.response?.results?.[0]?.id,
            authorUsername: json?.response?.results?.[0]?.user?.username,
            authorName: json?.response?.results?.[0]?.user?.name,
            authorLink: appendQueryIfExists(json?.response?.results?.[0]?.user?.links?.html, utms),
            unsplashLink: appendQueryIfExists(json?.response?.results?.[0]?.links?.html, utms),
            downloadedAt: new Date().toISOString(),
            downloadEventUrl: json?.response?.results?.[0]?.links?.download_location,
            mimeType: 'image/jpeg',
            srcSet: [
                `${json?.response?.results?.[0]?.urls?.thumb} 200w`,
                `${json?.response?.results?.[0]?.urls?.small} 400w`,
                `${json?.response?.results?.[0]?.urls?.regular} 800w`,
                `${json?.response?.results?.[0]?.urls?.full} ${json.response.results[0].width}w`,
            ].filter(s => s.includes('https://')),
            credits: `Photo by ${json?.response?.results?.[0]?.user?.name} on Unsplash\n${json?.response?.results?.[0]?.links?.html}`,
        }

        // Cache the URL and metadata
        cache.set(cacheKey, imageUrl);
        updateImageMetadata(source, query, metadata);
        cache.set(getUrl2QueryCacheKey(imageUrl), { source, query });

        // Return the URL
        return imageUrl;
    
    } catch (err) {
        console.error(err);
        return null;
    }
}

async function getProductionUrlForImageQuery_Raw(source: string, query: string, abortSignal?: AbortSignal) : Promise<string | null> {
    // Handle unsplash
    if (source === 'unsplash') {
        return getProductionUrlForUnsplashQuery(query, abortSignal);
    }

    if (!nonEmptyTrimmed(source) || !nonEmptyTrimmed(query)) {
        return null;
    }

    // Get URL from cache if available
    const cacheKey = getCacheKey(source, query);
    const cachedUrl = cache.get(cacheKey);
    if (check.nonEmptyString(cachedUrl)) {
        return cachedUrl as string;
    }

    // Get the URL from illustrations.dev
    try {
        const apiUrl = `https://illustrations.dev/encrypted/get?q=${encodeURIComponent(`${source.trim()}:${query.trim()}`)}`;
        const response = await fetch(apiUrl, { signal: abortSignal });
        const json = await response.json();
        const imageUrl = json?.cdn;
        if (!check.nonEmptyString(imageUrl)) {
            return null;
        }

        const creditsBySource : Record<string, string> = {
            google: 'Image from Google image. Rights belong to the original creator.',
            lucide: 'Icon by Lucide (https://lucide.dev). ISC License.',
            'fontawesome-brands': 'Icon by Font Awesome (https://fontawesome.com). FontAwesome Free License.',
            'fontawesome-duotone': 'Icon by Font Awesome (https://fontawesome.com). FontAwesome Pro License required.',
            'fontawesome-light': 'Icon by Font Awesome (https://fontawesome.com). FontAwesome Pro License required.',
            'fontawesome-regular': 'Icon by Font Awesome (https://fontawesome.com). FontAwesome Free License.',
            'fontawesome-solid': 'Icon by Font Awesome (https://fontawesome.com). FontAwesome Free License.',
            'fontawesome-thin': 'Icon by Font Awesome (https://fontawesome.com). FontAwesome Pro License required.',
            'lexica': 'Illustration by Lexica (https://lexica.art). Rights belong to the original creator.',
            'lexica-aperture': 'Illustration by Lexica (https://lexica.art). Rights belong to the original creator.',
            'dall-e': 'Illustration by OpenAI (https://openai.com). Creative Commons License.',
            'sdxl': 'Illustration by Stability AI (https://stability.ai). Creative Commons License.',
            'ai/3d-clay-icon': 'Icon by OpenAI. Creative Commons License.',
            'ai/3d-icon': 'Icon by Stability AI. Creative Commons License.',
            'ai/children-book': 'Illustration by OpenAI. Creative Commons License.',
            'ai/clear-style': 'Illustration by OpenAI. Creative Commons License.',
            'ai/comics': 'Illustration by Stability AI. Creative Commons License.',
            'ai/flat-illustration': 'Illustration by OpenAI. Creative Commons License.',
            'ai/interior-design': 'Illustration by Stability AI. Creative Commons License.',
            'ai/night-portrait': 'Photography by OpenAI. Creative Commons License.',
            'ai/outline-icon': 'Icon by OpenAI. Creative Commons License.',
            'ai/poly-clay-animal': 'Illustration by Stability AI. Creative Commons License.',
            'ai/portrait': 'Photography by OpenAI. Creative Commons License.',
            'ai/undraw': 'Illustration by Stability AI. Creative Commons License.',
            'ai/vector-art': 'Illustration by Stability AI. Creative Commons License.',
            'kukla-angle': 'Illustration by Wannathis. License required.',
            'kukla-face': 'Illustration by Wannathis. License required.',
            'flatline': 'Illustration by ManyPixels. License required.',
            'isometric': 'Illustration by ManyPixels. License required.',
            'monochromatic': 'Illustration by ManyPixels. License required.',
            'outline': 'Illustration by ManyPixels. License required.',
            'two-color': 'Illustration by ManyPixels. License required.',
            'minimalistic': 'Illustration by PixelTrue (https://www.pixeltrue.com/). License required.',
        };

        const metadata = {
            credits: creditsBySource[source] ? creditsBySource[source] : undefined,
            src: imageUrl,
            srcSet: check.nonEmptyArray(json?.srcSet) ? json.srcSet : undefined,
        }

        cache.set(cacheKey, imageUrl);
        updateImageMetadata(source, query, metadata);
        return imageUrl;
    } catch (err) {
        console.error(err);
        return null;
    }
}


// Return the production URL for an image, but merges all requests into a single promise
const getImageUrlPromises = new Map<string, Promise<any>>();
export async function getProductionUrlForImageQuery(source: string, query: string, abortSignal?: AbortSignal) : Promise<string | null> {
    if (!nonEmptyTrimmed(source) || !nonEmptyTrimmed(query)) {
        return null;
    }
    
    const cacheKey = getCacheKey(source, query);
    if (cache.has(cacheKey)) {
        return cache.get(cacheKey) as string;
    }

    const promise = getProductionUrlForImageQuery_Raw(source, query, abortSignal);
    getImageUrlPromises.set(cacheKey, promise);

    let imageUrl = null;
    try {
        imageUrl = await promise;
    } catch (err) {
        getImageUrlPromises.delete(cacheKey);
    }

    return imageUrl;
}


// Load an image from a URL
const loadedImages = new Map<string, boolean>();
const loadingImages = new Map<string, Promise<HTMLImageElement>>();
export async function loadImageFromUrl(url: string) {
    if (loadingImages.has(url)) {
        return loadingImages.get(url);
    }

    const promise = new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            loadedImages.set(url, true);
            resolve(img);
        };
        img.onerror = reject;
        img.src = url;
    });

    loadingImages.set(url, promise);
    return promise;
}

export function isImageLoaded(source: string, query: string) : boolean {
    if (!nonEmptyTrimmed(source) || !nonEmptyTrimmed(query)) {
        return false;
    }
    
    const url = getInstantUrlForImageQuery(source, query)
    if (!url) {
        return false;
    }

    return loadedImages.has(url);
}

export async function loadImage(source: string, query: string, abortSignal?: AbortSignal) : Promise<string | null> {
    const url = await getProductionUrlForImageQuery(source, query, abortSignal);
    if (!url) {
        return null;
    }

    await loadImageFromUrl(url);
    return url;
}