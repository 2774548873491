import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/shadcn/ui/tabs"
import { ComponentParser, ComponentTreeNode } from "../../component.type"
import { ComponentAliasConfig, childrenParsers, imports, isTextComponent, mergeComponents, normalizeTextComponent, radixComponentDoc, removeOneOccurenceOfText, setup } from "../_utils";
import check from "@/vendors/check";
import uniqid from "uniqid";
import { cn, nonEmptyTrimmed } from "@/lib/utils";
import upperlower from 'upperlower';
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"

export function TabsDemo() {
  return (
    <Tabs defaultValue="account" className="w-[400px]">
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="account">Account</TabsTrigger>
        <TabsTrigger value="password">Password</TabsTrigger>
      </TabsList>
      <TabsContent value="account">
        <Card>
          <CardHeader>
            <CardTitle>Account</CardTitle>
            <CardDescription>
              Make changes to your account here. Click save when you're done.
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <div className="space-y-1">
              <Label htmlFor="name">Name</Label>
              <Input id="name" defaultValue="Pedro Duarte" />
            </div>
            <div className="space-y-1">
              <Label htmlFor="username">Username</Label>
              <Input id="username" defaultValue="@peduarte" />
            </div>
          </CardContent>
          <CardFooter>
            <Button>Save changes</Button>
          </CardFooter>
        </Card>
      </TabsContent>
      <TabsContent value="password">
        <Card>
          <CardHeader>
            <CardTitle>Password</CardTitle>
            <CardDescription>
              Change your password here. After saving, you'll be logged out.
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <div className="space-y-1">
              <Label htmlFor="current">Current password</Label>
              <Input id="current" type="password" />
            </div>
            <div className="space-y-1">
              <Label htmlFor="new">New password</Label>
              <Input id="new" type="password" />
            </div>
          </CardContent>
          <CardFooter>
            <Button>Save password</Button>
          </CardFooter>
        </Card>
      </TabsContent>
    </Tabs>
  )
}

*/

function getTabTitleAndValue(tabComponent: ComponentTreeNode, i: number) {
  let value = null;
  if (check.nonEmptyString(tabComponent.props?.value)) {
    value = tabComponent.props.value;
  } else if (check.nonEmptyString(tabComponent.text) && nonEmptyTrimmed(tabComponent.text)) {
    value = upperlower(tabComponent.text, 'kebapcase');
  } else {
    value = uniqid();
  }

  let title = null;
  if (nonEmptyTrimmed(tabComponent.props?.title)) {
    title = tabComponent.props.title.trim();
  } else if (nonEmptyTrimmed(tabComponent.text)) {
    title = tabComponent.text.trim();
  } else if (nonEmptyTrimmed(tabComponent.props?.value)) {
    title = upperlower(tabComponent.props.value?.trim(), 'titlecase');
  } else {
    title = `Tab ${i + 1}`;
  }

  return { value, title };
}

function parseTabComponent(c: ComponentTreeNode) : ComponentTreeNode | null {
  const { title = null, value = null } = nonEmptyTrimmed(c.text) || nonEmptyTrimmed(c.props?.value) || nonEmptyTrimmed(c.props?.title) ? getTabTitleAndValue(c, 0) : {};
  if (!title || !value) {
    // If title and value are not extractable, let the wrapParent() function generate them
    return {
      ...c,
      component: TabsContent as any,
      htmlComponent: 'TabsContent',
    }
  }

  return {
    ...c,
    component: TabsContent as any,
    htmlComponent: 'TabsContent',
    props: {
      ...(c.props || {}),
      value,
      title,
    },
    children: removeOneOccurenceOfText(c.children || [], c.text || '').map(cleanTextNodes).filter(Boolean),
  }
}

function cleanTextNodes(c: ComponentTreeNode) : ComponentTreeNode | null {
  if (check.string(c)) {
    return c;
  }

  if (isTextComponent(c)) {
    return normalizeTextComponent(c)?.text as any || null;
  }

  if (check.nonEmptyString(c.text)) {
    return {
      ...c,
      children: removeOneOccurenceOfText(c.children || [], c.text || '').map(cleanTextNodes).filter(Boolean),
    };
  }

  return {
    ...c,
    children: (c.children || []).map(cleanTextNodes).filter(Boolean),
  };
}

function parseTabsComponent(c: ComponentTreeNode) : ComponentTreeNode | null {
  const tabs = (c.children || []).filter(child => child.component === 'tab' || child.component === TabsContent);
  const triggerItems = (c.children || []).filter(child => child.component === TabsTrigger || child.component === 'trigger');
  const tabsLists = (c.children || []).filter(child => child.component === TabsList || child.component === 'list'); 
  const otherChildren = (c.children || []).filter(child => ![
    TabsContent, 'tab',
    TabsTrigger, 'trigger',
    TabsList, 'list',
  ].some(t => child.component === t));

  // Consolidate trigger items
  const triggerItemsChildren = [
    ...triggerItems,
    ...tabsLists.map(tl => (tl.children || []).filter((child: ComponentTreeNode) => !!child && (child.component === (TabsTrigger as any) || child.component === 'trigger'))).flat(),
  ];

  // Redefine list of triggers by listing the tabs inside the component
  const tabsListValues = tabs.map(getTabTitleAndValue).filter(({ value, title }) => check.nonEmptyString(value) && check.nonEmptyString(title));
  const tabsListMeta : { [value: string]: string } = tabsListValues.reduce((acc, { value, title }) => ({ ...acc, [value.trim().toLowerCase()]: title.trim() }), {});

  // If there is no tabs to create, return null
  if (Object.keys(tabsListMeta).length === 0) {
    return null;
  }

  // Recreate a list of trigger items with the new values
  const newTriggerItems = Object.keys(tabsListMeta).filter(check.nonEmptyString).map((value, i) => {
    const title = tabsListMeta[value];
    const triggerItemsWithValue = triggerItemsChildren.filter(c => (
      (check.nonEmptyString(c.props?.value) && c.props?.value?.trim().toLowerCase() === value?.trim().toLowerCase())
      || (check.nonEmptyString(c.props?.title) && c.props?.title?.trim().toLowerCase() === title?.trim().toLowerCase())
      || (check.nonEmptyString(c.text) && c.text?.trim().toLowerCase() === title?.trim().toLowerCase())
    ));

    return {
      ...mergeComponents([
        ...triggerItemsWithValue, 
        {
          component: TabsTrigger,
          htmlComponent: 'TabsTrigger',
          props: {
            value,
          },
          children: [title],
        },
      ]),
      component: TabsTrigger,
      htmlComponent: 'TabsTrigger',
      children: [title],
    }
  });

  // Recreate the list of tabs with the new values
  const newTabsList = {
    ...mergeComponents([
      ...tabsLists,
    ]),
    component: TabsList,
    htmlComponent: 'TabsList',
    children: newTriggerItems,
  };
  newTabsList.classes = cn('grid w-full grid-cols-'+newTriggerItems.length, newTabsList.classes || []).split(' ').filter(check.nonEmptyString);
  newTabsList.props = {
    ...(newTabsList.props || {}),
    className: newTabsList.classes.join(' '),
  }

  // Recreate the list of tabs with the new values
  const newTabs = Object.keys(tabsListMeta).map((value, i) => {
    const title = tabsListMeta[value];
    const tabWithValue = tabs.filter(c => (
      c.props?.value?.trim().toLowerCase() === value?.trim().toLowerCase()
      || c.props?.title?.trim().toLowerCase() === title?.trim().toLowerCase()
      || c.text?.trim().toLowerCase() === title?.trim().toLowerCase()
    ));

    let obj = {
      ...mergeComponents([
        ...tabWithValue, 
        {
          component: TabsContent,
          htmlComponent: 'TabsContent',
          props: {
            value,
          },
        },
      ]),
      component: TabsContent,
      htmlComponent: 'TabsContent',
    }

    obj.children = (obj.children || []).map(cleanTextNodes).filter(Boolean);
    return obj;
  });

  // If there are other children, we add a tab 
  if (check.nonEmptyArray(otherChildren)) {
    const title = `Tab ${tabs.length + 1}`;
    const value = uniqid();
    newTabs.push({
      component: TabsContent,
      htmlComponent: 'TabsContent',
      props: {
        value,
      },
      text: title,
      classes: [],
      children: otherChildren.map(cleanTextNodes).filter(Boolean),
    });
    newTriggerItems.push({
      component: TabsTrigger,
      htmlComponent: 'TabsTrigger',
      props: {
        value,
      },
      children: [title],
      classes: [],
    });
  }

  // Return the newly structures Tabs component
  return {
    ...c,
    component: Tabs,
    htmlComponent: 'Tabs',
    props: {
      ...(c.props || {}),
      defaultValue: c.props?.defaultValue || (newTriggerItems[0]?.props?.value || null),
    },
    children: [
      newTabsList,
      ...newTabs,
    ],
  };
}


const ALIASES : ComponentAliasConfig[] = [
  ['content', TabsContent as any, 'TabsContent', radixComponentDoc('Tabs.Content')],
  ['list', TabsList, 'TabsList', radixComponentDoc('Tabs.List')],
  ['trigger', TabsTrigger, 'TabsTrigger', radixComponentDoc('Tabs.Trigger')],
];

const TabsComponentParser : ComponentParser = {
  name: 'Tabs',
  description: 'Displays rich content in a portal, triggered by the parent button or link',
  tags: ['tabs', 'tab'],

  refImplementation: `
    /tabs w-[400px]
      /tab Account
        /card
          /header Account
          /content
            /label Name
            /input @id=name @defaultValue="Pedro Duarte"
            /label Username
            /input @id=username @defaultValue="@peduarte"
          /footer
            /button Save changes
      /tab Password
        /card
          /header Password
          /content
            /label Current password
            /input @id=current @type=password
            /label New password
            /input @id=new @type=password
          /footer
            /button Save password
  `.trim(),

  childrenParsers: childrenParsers(ALIASES),
  
  parseTree: (c: ComponentTreeNode) => {
    // Clean text nodes from children recursively
    const clean = cleanTextNodes(c);
    if (!clean) {
      return null;
    }

    // Parse any Tabs component
    if ([Tabs, 'tabs'].some(t => c.component === t)) {
      return parseTabsComponent(clean);
    }

    // Tab component will be parsed by wrapParent()
    if ([TabsContent, 'tab'].some(t => c.component === t)) {
      return parseTabComponent(clean);
    }

    // Other components should be ignored if any
    return null;
  },

  // Wrap any tab component inside a Tabs component if not already wrapped
  wrapParent: (parent: ComponentTreeNode) : ComponentTreeNode => {
    if ([Tabs, 'tabs'].some(t => parent.component === t)) { 
      return parent;
    }
    if(![TabsContent, 'tab'].some(t => (parent.children || []).some(c => c.component === t))) {
      return parent;
    }

    // Grab all children which belong inside a Tabs component
    const belongsToTabs = (c: ComponentTreeNode) => [
      TabsContent, 'tab',
        TabsTrigger, 'trigger',
        TabsList, 'list',
    ].some(t => c.component === t);
    const grabbed : ComponentTreeNode[] = (parent.children || []).filter(belongsToTabs);

    // If there are children to wrap, create a new Tabs component
    let tabsComponent = parseTabsComponent({
      component: Tabs,
      htmlComponent: 'Tabs',
      children: grabbed,
      classes: [],
      props: {},
    });

    // Return the parent with the new Tabs component inserted where we see the necessity
    let inserted = false;
    const newChildren = (parent.children || []).map(c => {
      if (belongsToTabs(c)) {
        if (!inserted) {
          inserted = true;
          return tabsComponent;
        }
        return null;        
      } else {
        return c;
      }
    }).filter(Boolean) as ComponentTreeNode[];

    return {
      ...parent,
      children: newChildren,
    };
  },

  imports: imports(['Tabs', 'TabsContent', 'TabsList', 'TabsTrigger'], 'tabs'),
  setup: setup('tabs'),
  variants: [],
  doc: {
    props: radixComponentDoc('Tabs.Root').props,
  }
};

registerShadcnComponent({
        Tabs,
        TabsContent,
        TabsList,
        TabsTrigger,
    },
    'tabs',
);

export default TabsComponentParser;
