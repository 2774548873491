import { directoryEntryForPage, pageTitle, publicPageTitle } from "@/lib/dbRoutes";
import check from "@/vendors/check";
import useClerkAuthedFirebase from "@/vendors/firebase/useClerkAuthedFirebase";
import useFirebaseValue from "@/vendors/firebase/useFirebase";
import arrayUnique from "array-unique";
import { useCallback, useEffect, useState } from "react";

export function useProjectTitle(pageId: string, readOnly: boolean = true) : [string | null, (v: string) => void, boolean] {
    const uid = useClerkAuthedFirebase();
    const active = check.nonEmptyString(uid) && check.nonEmptyString(pageId);
    const [loading, setLoading] = useState(true);

    const { value: title, set: setPrivateTitle, loading: l1 } = useFirebaseValue<string | null>(
        active ? pageTitle(pageId, uid) : null, 
        'Untitled project'
    );
    const { value: publicTitle, set: setPublicTitle, loading: l2 } = useFirebaseValue<string | null>(
        active ? publicPageTitle(pageId) : null,
        'Untitled project'
    );
    const { value: directoryTitle, set: setMetadataTitle, loading: l3 } =  useFirebaseValue<string | null>(
        active ? directoryEntryForPage(pageId, uid) + '/title' : null, 
        'Untitled project'
    );

    useEffect(() => {
        if (loading) {
            if (!l1 && !l2 && !l3) {
                setLoading(false);
            }
        }
    }, [loading, l1, l2, l3]);

    // TODO:  While title is not set by user, we can ask OpenAI for a suitable title every 5 seconds
    // const [titleSetByUser, setTitleSetByUser] = useState(false);    
    /*
    useEffect(() => {

    }, [])
    */

    const setTitle = useCallback((v: string) => {
        if (readOnly || !active) {
            return;
        }

        try {
            setPrivateTitle(v);
            setPublicTitle(v);
            setMetadataTitle(v);
        } catch (err) {
            console.error(err);
        }
       
    }, [readOnly, setPrivateTitle, setPublicTitle, setMetadataTitle, active]);


    // Make sure project names are always equal 
    useEffect(() => {
        if (loading) {
            return;
        }
        const uniqueTitles = arrayUnique([title, publicTitle, directoryTitle].filter(check.nonEmptyString));
        if (uniqueTitles.length === 0) {
            return;
        }

        const clear = setTimeout(() => {
            // Wait 1 second for dust to settle
            // Change unless cancelled
            const newTitle = title || publicTitle || directoryTitle;
            if (newTitle) {
                if (publicTitle !== newTitle) {
                    setPublicTitle(newTitle);
                }
                if (directoryTitle !== newTitle) {
                    setMetadataTitle(newTitle);
                }
            }
        }, 1000);

        return () => {
            clearTimeout(clear);
        }
    }, [title, publicTitle, directoryTitle, setPublicTitle, setMetadataTitle, loading]);

    return [title, setTitle, loading];
}