import { getHTMLComponentDoc, getHTMLComponentProps, HTMLComponentProp, htmlComponentsTags } from "@/lib/html/html-components";
import { ComponentParser, ComponentTreeNode } from "../component.type";
import svgComponentTags from './svg-tags.json';

export const HTML_COMPONENTS_CATEGORY = 'HTML';
export const SVG_COMPONENTS_CATEGORY = 'SVG';

const filterOutBadProps = (props: Record<string, any>, propsDoc: HTMLComponentProp[]) => {
    const newProps : Record<string, any> = {};

    const allowedProps = propsDoc.map(p => p.name);
    const requiredProps = propsDoc.filter(p => p.required);

    // Remove non-allowed props
    Object.keys(props).forEach((key) => {
        if (key !== 'className' && !allowedProps.includes(key)) {
            return;
        }
        newProps[key] = props[key];
    });

    // Fill in required props we don't have
    requiredProps.forEach((p) => {
        if (props[p.name] !== undefined) {
            return;
        }
        newProps[p.name] = '';
        if (p.type === 'boolean') {
            newProps[p.name] = false;
        }
        if (p.type === 'number') {
            newProps[p.name] = 0;
        }
    });

    return newProps;
}


// Create a ComponentParser for each HTML component
const htmlComponentParsers : ComponentParser[] = [];
htmlComponentsTags.forEach(tag => {
    const htmlDoc = getHTMLComponentDoc(tag);
    if (!htmlDoc) {
        return;
    }

    const propsDoc = getHTMLComponentProps(tag);

    const parser : ComponentParser = {
        name: tag,
        description: htmlDoc.description, // 'Native HTML element',
        tags: [tag, `html.${tag}`, ...((svgComponentTags.includes(tag) && tag !== 'svg') ? [`svg.${tag}`] : [])],
        channel: 'prod',
        category: svgComponentTags.includes(tag) ? SVG_COMPONENTS_CATEGORY : HTML_COMPONENTS_CATEGORY,

        parseTree: (c: ComponentTreeNode) => {
            return {
                ...c,
                component: tag,
                htmlComponent: tag,
                props: filterOutBadProps(c.props, propsDoc),
                children: htmlDoc.acceptsChildren ? c.children : null,
            };
        },
        doc: {
            variants: {},
            props: propsDoc.reduce((acc, p) => ({
                ...acc,
                [p.name]: {
                    name: p.name,
                    type: p.type,
                    required: p.required,
                    description: htmlDoc.props[p.name]?.description || '',
                }
            }), {}),
        },
        variants: [],
    };

    htmlComponentParsers.push(parser);
});

export { htmlComponentParsers };