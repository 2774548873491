import check from "@/vendors/check";

export const ADDITIONAL_TW_CLASSES = new Set(`
    peer
    reset
    font-display
    font-body
    typeface-display-9xl
    typeface-display-8xl
    typeface-display-7xl
    typeface-display-6xl
    typeface-display-5xl
    typeface-display-4xl
    typeface-display-3xl
    typeface-display-2xl
    typeface-display-xl
    typeface-display-lg
    typeface-display-md
    typeface-display-sm
    typeface-display-xs
    typeface-body-9xl
    typeface-body-8xl
    typeface-body-7xl
    typeface-body-6xl
    typeface-body-5xl
    typeface-body-4xl
    typeface-body-3xl
    typeface-body-2xl
    typeface-body-xl
    typeface-body-lg
    typeface-body-md
    typeface-body-sm
    typeface-body-xs
    text-textcolor-primary
    text-textcolor-secondary
    text-textcolor-tertiary
    `.split('\n').map(e => e.trim()).filter(check.nonEmptyString));