import CenteredPageLayout from "@/components/CenteredPageLayout";
import { PermissionsRouter } from "@/components/auth/PermissionsRouter";
import Editor from "@/components/editor";
import { editor } from "monaco-editor";
import { Button } from "@/components/shadcn/ui/button";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "./resizable-custom";
import { Separator } from "@/components/shadcn/ui/separator";
import { pageDocUrl, pageMarkdown, publicPageRoot } from "@/lib/dbRoutes";
import { isComponentTag, isHtmlTag } from "@/lib/parser/components";
import { getTailwindDoc, isTailwindClass } from "@/lib/tailwind/tailwindClasses";
import { cn, nonEmptyTrimmed } from "@/lib/utils";
import check from "@/vendors/check";
import useClerkAuthedFirebase from "@/vendors/firebase/useClerkAuthedFirebase";
import useFirebaseValue from "@/vendors/firebase/useFirebase";
import { BookOpenIcon, Maximize2, Minimize2 } from "lucide-react";
import { useCallback, useRef, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { ReactComponent as Logo } from "@/assets/LayoutsLogo.svg";
import { Monaco } from "@monaco-editor/react";
import { ChatPanel } from "./chat/ChatPanel";
import usePages, { PageInfo } from "@/hooks/usePages";
import { useHideUnimportantErrors } from "@/hooks/useHideResizableErrors";
import { ShareButton } from "./ShareButton";
import { ProjectSelector } from "./project-selector";
import { ViewControlIsland } from "@/components/ui/ViewControlsIand/ViewControlsland";
import { IllustrationOf } from "@/vendors/illustrations.dev/IllustrationOf";


function getDocUrlForWord(word: string | null, lineContent: string | null) {
    if (check.string(word) && !nonEmptyTrimmed(word) && (!lineContent || !nonEmptyTrimmed(lineContent))) {
        return 'local:/default';
    }

    if (check.nonEmptyString(word)) {
        if (isTailwindClass(word) && !word.startsWith('/')) {
            const { url } = getTailwindDoc(word) || {};
            return url || null;
        }

        if (isComponentTag(word) || isHtmlTag(word)) {
            return `local:/component/${word.replace('/', '')}`;
        }
    }

    // If the word has no doc, try to find the doc for the current line's component
    const comp = (lineContent && nonEmptyTrimmed(lineContent)) ? lineContent.trim().split(' ')[0] : null;
    if (comp) {
        if (isComponentTag(comp) || isHtmlTag(comp)) {
            return `local:/component/${comp.replace('/', '')}`;
        }
    }

    return null;
}

function ReadOnlyBanner() {
    return (
        <div className="flex gap-3 p-2 justify-center items-center w-full bg-[#5b5e66] text-white text-sm" >
            <IllustrationOf className="w-4 h-4" id="line_7" key="line_7" of="eye only" variant="lucide" />
            <span>
                This page is read only
            </span>
        </div>
    );
}

const PANEL_CN = `shadow-md bg-[#FBFBFB] border border-[#e4e4e4] borber-b-none rounded-t-sm gap-2`;
const SEPARATOR_CN = 'w-0.5';

function PageTabs({ pages, currentPath, onPageClick, showMaximize, maximized, onToggleMaximized }: { pages: PageInfo[], currentPath: string, onPageClick: (path: string) => void, maximized: boolean, onToggleMaximized: () => void, showMaximize: boolean}) {  
    console.log('Pages: ', pages)
    return (
        <div 
            className={
                'flex flex-row items-end justify-start w-full transition-all ' + 
                (check.nonEmptyArray(pages) ? 'h-[26px]' : 'h-0')
            } >
                {pages.map(({ title, path } : { title: string, path: string }) => (
                   <>
                        <div 
                            key={path}
                            className={cn(
                                'cursor-pointer',
                                'flex flex-row items-center justify-center',
                                'border-x border-t border-[#E4E4E4] rounded-t-[6px] px-[12px] py-[4px]',
                                'text-[12px] leading-[16px]',
                                currentPath === path ? 'text-[#1E1F22]' : 'text-[#B7B8B9]',
                                currentPath === path ? 'bg-white' : 'bg-[#E4E4E4] border-b',
                            )}
                            onClick={() => { onPageClick(path) }}
                        >
                            {title}
                        </div> 
                        <div className="h-full w-[4px] border-[#E4E4E4] border-b" />
                   </>
                ))}
                {check.nonEmptyArray(pages) && (
                    <div className="h-full flex-1 border-[#E4E4E4] border-b flex flex-row justify-end items-center">
                        {true && (
                            <div className={
                                "flex flex-row items-center justify-center p-[2px] duration-100 transition-all "
                                + ((showMaximize && maximized) ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[5px]')
                            }>
                                <div 
                                    className="flex flex-col p-[4px] w-fit rounded-sm size-[20px] hover:bg-white cursor-pointer" 
                                    onClick={onToggleMaximized}
                                >
                                    <Maximize2 className="size-full" />
                                </div>
                            </div>
                        )}
                    </div>                  
                )}
        </div>
    );
}

function CodeTabs({ show = false, showMaximizeButton = false, onToggleMaximized = () => {} }: { show: boolean, showMaximizeButton: boolean, onToggleMaximized: () => void }) {
    return (
        <div 
            className={
                'flex flex-row items-end justify-start w-full transition-all overflow-hidden ' + 
                (show ? 'h-[26px]' : 'h-0')
            } >
                <div className="h-full flex-1 border-none flex flex-row justify-end items-center">
                    {show && (
                        <div className={
                            "flex flex-row items-center justify-center p-[2px] "
                            + (showMaximizeButton ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[5px]')    
                        }>
                            <div 
                                className={
                                    "flex flex-col p-[4px] w-fit rounded-md size-[20px] " 
                                    + (showMaximizeButton ? 'hover:bg-white cursor-pointer' : '')
                                }
                                onClick={showMaximizeButton ? onToggleMaximized : () => {}}
                            >
                                <Maximize2 className="size-full" />
                            </div>
                        </div>
                    )}
                </div>                  
        </div>
    );
}

function EditorPage({ readOnly = false } : { readOnly?: boolean }) {
    useHideUnimportantErrors();
    const uid = useClerkAuthedFirebase();
    const [resizing, setResizing] = useState(false);
    const { pageId } = useParams();
    const [caretInfo, setLocalCaretInfo] = useState({ word: null });
    const { set: setDocUrl } = useFirebaseValue<string | null>(pageDocUrl(pageId), '');
    const [chatActive, setChatActiveRaw] = useState(false);
    const [docActive, setDocActiveRaw] = useState(false);
    const [editorActiveRaw, setEditorActiveRaw] = useState(true);
    const [viewActive, setViewActiveRaw] = useState(true);
    const [codeActive, setCodeActiveRaw] = useState(false);
    const [ maximized, setMaximized ] = useState(false);
    const [editorInstance, setEditorInstanceRaw] = useState<{ editor: editor.IStandaloneCodeEditor, monaco: Monaco } | null>(null);
    const editorInstanceRef = useRef<any>(null);


    const setEditorInstance = useCallback((instance: { editor: editor.IStandaloneCodeEditor, monaco: Monaco }) => {
        if (editorInstanceRef?.current?.monaco !== instance.monaco || editorInstanceRef?.current?.editor !== instance.editor) {
            setEditorInstanceRaw(instance);
            editorInstanceRef.current = instance;
        }
    }, []);

    console.log('[EditorPage] Editor instance: ', editorInstance);

    const pages = usePages(pageId);
    const [currentPathRaw, setCurentPath] = useState('/');

    const currentPath = check.nonEmptyArray(pages) && pages.some(p => p.path === currentPathRaw) ? currentPathRaw : '/';

    const toggleMaximized = useCallback(() => {
        setMaximized(prev => !prev);
    }, []);
    
    const setEditorActive = useCallback((active: boolean) => {
        if (!docActive && !viewActive && !codeActive && !chatActive) {
            setEditorActiveRaw(true);
        } else {
            setEditorActiveRaw(active);
        }
    }, [docActive, viewActive, codeActive, chatActive]);

    const setChatActive = useCallback((active: boolean) => {
        setChatActiveRaw(active);
        if (!active && !viewActive && !codeActive && !docActive) {
            setEditorActiveRaw(true);
        }
    }, [codeActive, viewActive, docActive]);

    const setDocActive = useCallback((active: boolean) => {
        setDocActiveRaw(active);
        if (!active && !viewActive && !codeActive && !chatActive) {
            setEditorActiveRaw(true);
        }
    }, [codeActive, viewActive, chatActive]);

    const setViewActive = useCallback((active: boolean) => {
        setViewActiveRaw(active);
        if (!active && !docActive && !codeActive && !chatActive) {
            setEditorActiveRaw(true);
        }
    }, [docActive, codeActive, chatActive]);

    const setCodeActive = useCallback((active: boolean) => {
        setCodeActiveRaw(active);
        if (!active && !docActive && !viewActive && !chatActive) {
            setEditorActiveRaw(true);
        }
    }, [docActive, viewActive, chatActive]);


    const editorActive = editorActiveRaw || (!docActive && !viewActive && !codeActive && !chatActive);

    const setCaretInfo = useCallback((info: any) => {
        setLocalCaretInfo(info);

        const { word: selectedWord, lineContent, } = info || {};
        const documentationUrl = getDocUrlForWord(selectedWord, lineContent);
        setDocUrl(documentationUrl);
    }, [setDocUrl]);

    if (!check.nonEmptyString(pageId)) {
        return <Navigate to="/" />;
    }

    const { word: selectedWord } = caretInfo;
    const documentationUrl = './doc'; // getDocUrlForWord(selectedWord, lineContent);

    // Calculate window sizes depending on active views
    const basis = [20, 20, 20, 20, 20];
    const defaultSizes = [...basis];
    const activeViews = [chatActive, docActive, editorActive, viewActive, codeActive];
    const activeViewCount = activeViews.filter((v) => v).length;
    activeViews.forEach((active, i) => {
        if (!active) {
            let prev = defaultSizes[i];
            defaultSizes[i] = 0;
            activeViews.forEach((viewActive, j) => {
                if (viewActive) {
                    defaultSizes[j] += (prev / activeViewCount);
                }
            });
        }
    });

    return (
        <div className='h-screen w-full flex flex-col items-center px-[9px] bg-[#F4F3F1] gap-0'>
            <div className={cn(
                'w-full flex flex-row justify-between items-center py-2.5 pl-3 transition-all duration-300',
                maximized ? '-mt-[60px] mb-[9px]' : 'mt-0 mb-0'
            )}
            
                style={{
                    height: '50px',
                    transition: 'all 0.4s ease-out !important'
                }}
            >
                <div className="flex flex-row items-center gap-6">
                    <Link to='/projects'>
                        <Logo 
                            // src="https://dl.dropbox.com/scl/fi/gyep6lfkol90ctw4o5c36/layouts-logo-transparent.svg?rlkey=hjkbqgzf05tlkavg2bde66tkx&st=3mjvn6xa&dl=0"
                            className="size-[22px]"
                            // alt=''
                        />
                    </Link>
                    <ProjectSelector pageId={pageId} readOnly={readOnly} />
                </div> 
                <div className="flex flex-row gap-2 items-center" >
                        <ShareButton />
                        {true && (
                            <div 
                                className={
                                    "flex flex-col p-1.5 w-fit rounded-lg size-[28px] hover:bg-white cursor-pointer "
                                    + (!maximized ? '' : 'opacity-0') 
                                }
                                onClick={toggleMaximized}
                            >
                            <Minimize2 className="size-full" />
                            </div>
                        )}
                </div>
            </div>
            <div className={'relative w-full flex flex-col items-center pt-[9px] bg-[#F4F3F1] transition-all duration-300 ' + (maximized ? 'h-[calc(100vh)]' : 'h-[calc(100vh_-_50px)]')} >
            {maximized && (!check.nonEmptyArray(pages) || !viewActive) && (
                <div 
                    className="z-50 flex flex-col p-1.5 bg-white border border-[#e4e4e4] w-fit rounded-lg shadow-xl absolute top-4 right-2 size-[28px] hover:bg-stone-50 cursor-pointer" 
                    onClick={toggleMaximized}
                >
                   <Maximize2 className="size-full" />
                </div>
            )}
                <ResizablePanelGroup
                    key={'panel_'+JSON.stringify(activeViews)}
                    direction="horizontal"
                    className="h-full w-full gap-0.5"
                >
                    <ResizablePanel defaultSize={defaultSizes[0]} className={cn(PANEL_CN, !chatActive && 'hidden')}>
                        {chatActive && (
                            <ChatPanel editorInstance={editorInstance} />
                        )}
                    </ResizablePanel>
                    <ResizableHandle withHandle className={cn(SEPARATOR_CN, !chatActive && 'hidden')} onMouseDown={() => setResizing(true)} onMouseUp={() => setResizing(false)}/>
                    

                    <ResizablePanel defaultSize={defaultSizes[1]} className={cn(PANEL_CN, !docActive && 'hidden')}>
                        {(documentationUrl && docActive) && (
                            <iframe title={pageId} src={documentationUrl} className="w-full h-full" />
                        )}
                        {!documentationUrl && (
                            <div className="relative w-full h-full bg-slate-100 flex items-center justify-center">
                                <BookOpenIcon className="w-20 h-20 text-slate-300" />
                            </div>
                        )}
                        
                    </ResizablePanel>
                    <ResizableHandle withHandle className={cn(SEPARATOR_CN, !docActive && 'hidden')} onMouseDown={() => setResizing(true)} onMouseUp={() => setResizing(false)}/>
                    
                    <ResizablePanel 
                        defaultSize={defaultSizes[2]} 
                        className={cn(
                            PANEL_CN, 
                            (!editorActive && (docActive || viewActive || codeActive)) && 'hidden',
                        )}
                    >
                        <div className="w-full h-full flex flex-col">
                            {readOnly && (
                                <ReadOnlyBanner />
                            )}
                            <Editor 
                                setEditorInstance={setEditorInstance}
                                readOnly={readOnly} 
                                pageId={pageId} 
                                dbPath={pageMarkdown(pageId, uid)} 
                                publicDbPath={publicPageRoot(pageId, )} 
                                onCaretInfoUpdate={setCaretInfo} 
                            /> 
                        </div>
                    </ResizablePanel>


                    <ResizableHandle withHandle className={cn(SEPARATOR_CN, !viewActive && 'hidden')} onMouseDown={() => setResizing(true)} onMouseUp={() => setResizing(false)} />

                    <ResizablePanel defaultSize={defaultSizes[3]} className={cn( !viewActive && 'hidden')}>
                        {viewActive && (
                            <div className='flex flex-col w-full h-full' >
                                <PageTabs showMaximize={!codeActive} pages={pages} onPageClick={setCurentPath} currentPath={currentPath} maximized={maximized} onToggleMaximized={toggleMaximized} />
                                <div 
                                    className={cn(
                                        'w-full h-full', 
                                        'shadow-md bg-[#FBFBFB] border-x border-[#e4e4e4] borber-b-none rounded-t-sm gap-2 overflow-hidden',
                                        check.nonEmptyArray(pages) ? 'rounded-tl-none rounded-tr-none border-t-none' : 'border-t rounded-t-sm '
                                    )} 
                                >
                                    <iframe title={pageId} src={`/${pageId}${currentPath || ''}`} className="w-full h-full" />
                                </div>
                            </div>
                        )}
                    </ResizablePanel>

                    <ResizableHandle withHandle className={cn(SEPARATOR_CN, !codeActive && 'hidden')} onMouseDown={() => setResizing(true)} onMouseUp={() => setResizing(false)} />
                    
                    <ResizablePanel defaultSize={defaultSizes[4]} className={cn( !codeActive && 'hidden')}>
                        {codeActive && (
                            <div className='flex flex-col w-full h-full' >
                                <CodeTabs show={viewActive && check.nonEmptyArray(pages)} showMaximizeButton={maximized} onToggleMaximized={toggleMaximized} />                                
                                <div 
                                    className={cn(
                                        'w-full h-full', 
                                        'shadow-md bg-[#FBFBFB] border-x border-[#e4e4e4] borber-b-none rounded-t-sm gap-2 overflow-hidden ',
                                        (viewActive && check.nonEmptyArray(pages)) ? 'rounded-tl-none rounded-tr-sm border-t -mt-[1px]' : 'border-t rounded-t-sm '
                                    )} 
                                >
                                    <iframe title={pageId} src={`/${pageId}/code`} className="w-full h-full" />
                                </div>
                            </div>
                        )}
                    </ResizablePanel>
                    
                </ResizablePanelGroup>
                {/*
                    <ViewControlIsland 
                        docActive={docActive} 
                        onDocActiveChange={setDocActive} 
                        viewActive={viewActive} 
                        onViewActiveChange={setViewActive}
                        codeActive={codeActive}
                        onCodeActiveChange={setCodeActive}
                        editorActive={editorActive}
                        onEditorActiveChange={setEditorActive}
                    />
                */}
                
                <ViewControlIsland
                    value={{ chat: chatActive, docs: docActive, editor: editorActive, preview: viewActive, code: codeActive }} 
                    onToggle={(id: string) => {
                        if (id === 'chat') {
                            setChatActive(!chatActive);
                        } else if (id === 'docs') {
                            setDocActive(!docActive);
                        } else if (id === 'editor') {
                            setEditorActive(!editorActive);
                        } else if (id === 'preview') {
                            setViewActive(!viewActive);
                        } else if (id === 'code') {
                            setCodeActive(!codeActive);
                        }
                    }}
                    hide={readOnly ? ['docs', 'code'] : []}
                    className={
                        'transition-all duration-[150ms] bottom-[8px]' 
                    }
                />
            </div>
        </div>
    );
}

function NoAccessPage() {
    return (
        <CenteredPageLayout>
			<div className="bg-[#f4f3f1] flex flex-col gap-10 p-6 md:p-12 items-center w-full" >
				<div className="bg-[#f4f3f1] flex flex-col gap-6 items-center" >
					<IllustrationOf className="size-[32px] text-[#5b5e66]" id="line_8" key="line_8" strokeWidth={1.5} of="lock" variant="lucide" />
					<h1 className="font-['inter'] text-[#1e1f22] font-medium text-xl" >
						This page is private
					</h1>
				</div>
				<Separator className="w-36 bg-[#5b5e66] opacity-25" orientation="horizontal" />
				<div className="bg-[#f4f3f1] flex flex-col gap-3.5 items-center w-full" >
					<p className="text-[#5b5e66] font-normal text-xs" >
						Want to create yours?
					</p>
					<Button 
                        onClick={() => { window.location.href = '/' } }
                        className="gap-2 bg-white shadow-sm border border-[#e4e4e4] max-w-[370px] w-full hover:bg-white/60" variant="secondary" >
						<img alt="" className="size-[16px]" src="https://dl.dropbox.com/scl/fi/2bxt0v45q4odj1rvxdqo4/Clip-path-group.svg?rlkey=j7y748ujkqvapbybc6wqcyxy8&st=qzu3jpn6&dl=0" />
						<IllustrationOf className="w-4 h-4 hidden text-[#5b5e66]" id="line_16" key="line_16" of="document plus" variant="lucide" />
						<p className="text-[#5b5e66] font-normal" >
							Create a page
						</p>
					</Button>
				</div>
			</div>
        </CenteredPageLayout>
    );
}

function ReadAccessPage() {
    return (
        <EditorPage readOnly />
    );
}

export function WriteAccessPage() {
    return (
        <EditorPage />
    );
}

export function GatedEditorPage() {
    const { pageId } = useParams();

    return (
        <PermissionsRouter 
            pageId={pageId}
            readAccess={<ReadAccessPage />}
            writeAccess={<WriteAccessPage />}
            noAccess={<NoAccessPage />}
        />
    )
}