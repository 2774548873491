import { publicPageMarkdown } from "@/lib/dbRoutes";
import check from "@/vendors/check";
import { Navigate, useParams } from "react-router-dom";
import { Player } from "@/components/player";
import { useLocalOrRemoteValue } from "@/hooks/useLocalOrRemoteValue";
import { Loader } from "lucide-react";
import { cn } from "@/lib/utils";
import React from "react";
import { LoadingAnimation } from "@/components/LoadingPage";

function generateInlineVariablesStyleHtml() {
    // Function to get the value of a CSS variable
    function getCssVariableValue(variable: string) {
        return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
    }

    // List of CSS variables to retrieve
    const cssVariables = [
        '--background', '--foreground',
        '--card', '--card-foreground',
        '--popover', '--popover-foreground',
        '--primary', '--primary-foreground',
        '--secondary', '--secondary-foreground',
        '--muted', '--muted-foreground',
        '--accent', '--accent-foreground',
        '--destructive', '--destructive-foreground',
        '--border', '--input', '--ring',
        '--radius'
    ];

    // Generate CSS string
    let cssString = '<style type="text/css">:root {';
    cssVariables.forEach(variable => {
        cssString += `${variable}: ${getCssVariableValue(variable)}; `;
    });
    cssString += '}</style>';

    return cssString;
}

const MemoizedPlayer = React.memo(({ src, pageId }: { src: string | null, pageId: string }) => (<Player pageId={pageId} src={src} />));

export function DisplayPage() {
    const { pageId } = useParams();

    const { value: remoteMarkdown, loading } = useLocalOrRemoteValue(`//${pageId}/editor-code`, publicPageMarkdown(pageId), null, true);

    // const { value: remoteCode, loading } = useFirebaseValue(publicPageMarkdown(pageId), '', true);
    /*
    const [localCode] = useBroadcast(`//${pageId}/editor-code`, null);
    

    // FIXME: Remove
    const remoteCode = null;
    const loading = false;
    
    
    let remoteMarkdown : string | null = localCode || remoteCode; // Use local code if available
    if (!loading && !check.nonEmptyString(remoteMarkdown)) {
        remoteMarkdown = defaultText;
    } 
    */

    // console.log('localCode: ', localCode);
    // console.log('remoteCode: ', remoteCode);

    /*
    const { set: setHtml } = useFirebaseValue(pageHtmlCode(pageId), '');
    
     // Export pure HTML code after each page change
    const onRootChange = useCallback(() => {
        let html = document.getElementById('root')?.innerHTML;
        if (check.nonEmptyString(html)) {
            // html = generateInlineVariablesStyleHtml() + '\n\n' + html;
            setHtml(html).catch(console.error);
        }
    }, [setHtml]);
    useRootMutationObserver('root', onRootChange);
    */

    if (!check.nonEmptyString(pageId)) {
        return <Navigate to="/" />;
    }

    if (loading) {
        return (
            <div className={cn(
                "flex-1 min-h-screen flex flex-col items-center justify-center size-full bg-[#F4F3F1] text-[#5B5E66]",
            )}>
                <LoadingAnimation />
            </div>
        );
    }

    return (
        <MemoizedPlayer pageId={pageId} src={remoteMarkdown} />
    );
}