import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../layouts-ui/sheet";
import { ComponentParser, ComponentTreeNode } from "../../component.type"
import { nonEmptyTrimmed } from "@/lib/utils"
import { childrenParsers, ComponentAliasConfig, imports, parentWrapperForTriggerableComponent, parseCardLikeComponent, radixComponentDoc, setup } from "../_utils";
import { ComponentType } from "react";
import check from "@/vendors/check";
import { withTextAsChildOfType, withTextAsFirstChild } from "@/lib/parser/util";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
export function SheetDemo() {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline">Open</Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Edit profile</SheetTitle>
          <SheetDescription>
            Make changes to your profile here. Click save when you're done.
          </SheetDescription>
        </SheetHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Name
            </Label>
            <Input id="name" value="Pedro Duarte" className="col-span-3" />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Username
            </Label>
            <Input id="username" value="@peduarte" className="col-span-3" />
          </div>
        </div>
        <SheetFooter>
          <SheetClose asChild>
            <Button type="submit">Save changes</Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
*/


const TAG = 'sheet';

const ALIASES : ComponentAliasConfig[] = [
  ['footer', SheetFooter, 'SheetFooter'],
  ['close', SheetClose, 'SheetClose', radixComponentDoc('Dialog.Close')],
  ['title', SheetTitle, 'SheetTitle', radixComponentDoc('Dialog.Title')],
  ['description', SheetDescription, 'SheetDescription', radixComponentDoc('Dialog.Description')],
]

const InnerContent : ComponentType<any> = ({ children }) => {
  return <>{children}</>;
}

const SheetComponentParser : ComponentParser = {
  name: 'Sheet',
  description: 'Extends the Dialog component to display content that complements the main content of the screen.',
  refImplementation: `
/button %outline Open
	/sheet
		/header
			/title Edit profile
			/description Make changes to your profile here. Click save when you're done.
		/div grid gap-4 py-4
			/div grid grid-cols-4 items-center gap-4
				/label @htmlFor="name" text-right Name
				/input @id="name" Severin Marcombes col-span-3
      /div grid grid-cols-4 items-center gap-4
          /label @htmlFor="username" text-right Username
          /input @id="username" severin__ col-span-3
    /footer
      /close @asChild
          /button @type="submit" Save changes
  `.trim(),
  tags: [TAG],

  childrenParsers: [
    {
        name: 'header',
        tags: ['header'],
        description: 'The header of the sheet',
        parseTree: (c: ComponentTreeNode) => {
          return withTextAsChildOfType(SheetTitle, 'SheetTitle')({
            ...c,
            component: SheetHeader,
            htmlComponent: 'SheetHeader',
        });
      },
    },
    {
        name: 'content',
        tags: ['content'],
        description: 'The content of the sheet',
        parseTree: (c: ComponentTreeNode) => {
          return withTextAsFirstChild({
            ...c,
            component: InnerContent,
            htmlComponent: 'SheetInnerContent',
          });
        },
    },
    ...childrenParsers(ALIASES, TAG)
  ],
  
  parseTree: (c: ComponentTreeNode) : ComponentTreeNode => {
    const parsed = parseCardLikeComponent(c, {
      component: [SheetContent, 'SheetContent'],
      header: [SheetHeader, 'SheetHeader'],
      content: [InnerContent, 'SheetInnerContent'],
      footer: [SheetFooter, 'SheetFooter'],
      title: [SheetTitle, 'SheetTitle'],
      description: [SheetDescription, 'SheetDescription'],
    });

    // Filter out InnerContent from children
    parsed.children = (parsed.children || []).map(child => {
      if (child.component === InnerContent) {
        if (nonEmptyTrimmed(child.props?.className) || (check.nonEmptyArray(child.classes) && child.classes.some(nonEmptyTrimmed))) {
          return {
            ...child,
            component: 'div',
            htmlComponent: 'div',
          };
        }
        
        return child.children || [];
      }
      return child;
    }).flat();

    return parsed;
  },

  wrapParent: parentWrapperForTriggerableComponent(
    [Sheet, 'Sheet'], 
    [SheetTrigger, 'SheetTrigger'], 
    [SheetContent, 'SheetContent'],
  ),

  imports: imports(['Sheet', 'SheetContent', 'SheetHeader', 'SheetTitle', 'SheetDescription', 'SheetTrigger', 'SheetClose', 'SheetFooter'], 'sheet'),
  setup: setup(TAG),
  variants: [
    'right',
    'bottom',
    'left',
    'top',
  ],
};

registerShadcnComponent({
        Sheet,
        SheetClose,
        SheetContent,
        SheetDescription,
        SheetFooter,
        SheetHeader,
        SheetTitle,
        SheetTrigger,
    },
    'sheet',
);

export default SheetComponentParser;
