
import { Switch } from "@/components/shadcn/ui/switch";
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { imports, parseBooleanInputComponent, radixComponentDoc, setup } from "../_utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"
 
export function SwitchDemo() {
  return (
    <div className="flex items-center space-x-2">
      <Switch id="airplane-mode" />
      <Label htmlFor="airplane-mode">Airplane Mode</Label>
    </div>
  )
}
*/

const SwitchComponentParser : ComponentParser = {
  name: 'Switch',
  description: 'A control that allows the user to toggle between checked and not checked.',
  tags: ['switch'],
  refImplementation: `
  /switch Airplane mode ON
  `.trim(),
  parseTree: (c: ComponentTreeNode) => {
      return parseBooleanInputComponent(Switch, 'Switch', c);
  },
  imports: [
    ...imports(['Switch'], 'switch'),
    ...imports(['Label'], 'label'),
  ],
  setup: [
    ...setup('switch'),
    ...setup('label'),
  ],
  variants: [],
  doc: {
      props: radixComponentDoc('Switch.Root').props,
  }
};

registerShadcnComponent({
        Switch
    },
    'switch',
);

export default SwitchComponentParser;
