import './player.css';
import { DataProvider } from "@/vendors/data/data";
import { appJsonToReactComponent, layoutsLangToAppJson } from "@/lib/parser/parser-v2";
import { useDebouncedParsedValue } from "@/hooks/useDebouncedParsedValue";
import { defaultText } from '@/lib/defaultPage';
import { EMPTY_EDITOR_CONTENT } from '@/lib/constants';

async function markdown2ReactComponent(markdown: string) {  
    const appJson  = layoutsLangToAppJson(markdown, 'react-component');
    if (!appJson) {
        return null;
    }

    const reactContent = appJsonToReactComponent(appJson);

    return reactContent;   
}

// Function to watch element under mouse
const closestAncestor = (el: any, tagName: string) => {
    do {
        if (el.tagName.toLowerCase() === tagName) return el;
        el = el.parentNode;
    } while (el !== null);
    return null;
};

const getElementUnderMouse = (event: any, callback: any) => {
    let elementBelow = document.elementFromPoint(event.clientX, event.clientY);

    do {
        if (elementBelow && elementBelow.id) {
        callback(elementBelow.id);
        return;
        }

        elementBelow = closestAncestor(elementBelow, 'body');
    } while (elementBelow !== null);
};


export function Player({ pageId, src, fillScreen = true }: { pageId:string, src: string | null, fillScreen?: boolean }) {
    const parsedSrc = src?.trim() === EMPTY_EDITOR_CONTENT ? '' : src;
    const { value: reactContent } = useDebouncedParsedValue((!parsedSrc && parsedSrc !== '') ? defaultText : parsedSrc, markdown2ReactComponent, 0);
    // const [, setHoveredElementId] = useBroadcast(`//${pageId}/hovered-element-id`, '');
    
    //const idUnderMouseRef = useRef('');

    // Emit event when new element is hovered under mouse
    /*
    const emitUnderMouseId = useCallback((value: string) => {
        if (idUnderMouseRef.current === value) {
            return;
        }
        idUnderMouseRef.current = value;
        // setHoveredElementId(value);
        console.log(`ID of the element under mouse: ${value}`);
    }, [setHoveredElementId]);
    */


    // Get element under mouse
    /*
    useEffect(() => {
        const listener = (event: any) => {
            getElementUnderMouse(event, (id: string) => {
                emitUnderMouseId(id);
            });
        }
        document.addEventListener('mousemove', listener);

        console.log(`Running player ID listener effect`);

        return () => {
            document.removeEventListener('mousemove', listener);
        }
    }, [emitUnderMouseId]);
    */

    return (
        <DataProvider data={{}} >
            <div className={"player bg-background " + (fillScreen ? 'w-full h-screen overflow-y-scroll' : '')}>
                {reactContent}
            </div>
        </DataProvider>
    );
}