import { Button } from "@/components/shadcn/ui/button";
import { Separator } from "@/components/shadcn/ui/separator";
import check from "@/vendors/check";
import moment from 'moment';
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "@/components/shadcn/ui/input";
import { useProjects } from "@/hooks/useProjects";
import { getNewProjectUrl } from "@/lib/utils";

export default function ProjectsPage() {
    const [search, setSearch] = useState('');
    const directory = useProjects();
    const navigate = useNavigate();

    return (
        <div className="flex flex-col w-screen min-h-screen bg-[#f4f3f1] p-8" >
			<div className="justify-start items-center flex-col flex w-full px-[15%] py-[12%] min-w-[300px]" >
				<div className="flex w-full justify-between items-end gap-6 max-w-[800px]" >
					<div className="justify-start items-start flex-col flex gap-[12px] max-w-[400px] w-full" >
						<h1 className="text-[#1e1f22] text-[20px] font-medium leading-[24px]" >
							Get started
						</h1>
						<p className="text-sm text-[#5b5e66]" >
							{check.nonEmptyArray(directory) && (
                                'This page shows the list of your projects. Pick one, or create a new file to get started.'
                            )}
                            {!check.nonEmptyArray(directory) && (
                                `This page will show the list of your projects. It's time to create one! Create a file to start with a blank canvas.`
                            )}
						</p>
					</div>
                    
                    <Button 
                        onClick={() => navigate(getNewProjectUrl())}
                        className="gap-2" 
                        size="sm" 
                        variant="default"  
                    >
                        + New file
                    </Button>
                   
				</div>
                {check.nonEmptyArray(directory) && (
                    <>
                        <div className="w-full py-6 max-w-[800px]" >
                            <Separator orientation="horizontal" />
                        </div>
                        <div className="w-full pb-4 max-w-[800px]" >
                            <Input className="reset border py-1.5 px-2.5 text-sm rounded-md w-full outline-[#f4f3f1] focus:outline-gray-200 text-[#1E1F22] focus:shadow-sm" type="text" placeholder="Search" onChange={(value) => setSearch(value)} />
                        </div>
                        
                    </>
                   
                )}
                {check.nonEmptyArray(directory) && (
                    <div className="justify-start items-start flex-col flex w-full max-w-[800px]" >
                        {directory.filter(p => p?.title && p.title.toLowerCase().includes(search.trim().toLowerCase())).map(p => (
                            <Link key={p.id} to={'/' + p.id + '/edit'} className="flex w-full p-[12px] rounded-[7px] hover:bg-[rgba(30,_31,_34,_0.05)] justify-between items-center" >
                                <p className="text-[14px] leading-[20px] text-[#1e1f22] font-[500]" >
                                    {p.title || 'Untitled project'}
                                </p>
                                <p className="text-[#5b5e66] text-[12px] font-[400]" >
                                    {p.created && `Created on ${moment(p.created).format('YYYY-MM-DD')}`}
                                </p>
                            </Link>
                        ))}
                    </div>
                )}
			</div>
		</div>
    );
}