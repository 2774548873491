import { useContext, useEffect } from "react";
import { ThemeContext } from "./LayoutsThemeProvider";

export function useThemePreset(preset: any) {
    const context = useContext(ThemeContext);
    const { setPreset, availablePresets } = context;

    // Change theme preset if needed
    useEffect(() => {
        if (preset && typeof preset === 'string' && availablePresets.includes(preset)) {
            setPreset(preset);
        }
    }, [preset, setPreset, availablePresets]);
}