///////////////////////////////////////////////////////////////////////////////////////////////////
// Generated by Layouts.dev
// ------------------------------------------------------------------------------------------------
// Copyright Creative Robots Inc. 2024 - All rights reserved
// https://layouts.dev
// ------------------------------------------------------------------------------------------------
// We are constantly improving the way this code is generated.
// Notice something wrong? Please let us know at hey@creative-robots.ai and we'll fix it!
///////////////////////////////////////////////////////////////////////////////////////////////////

// ------------------------------------------------------------------------------------------------
// Project setup instructions
// ------------------------------------------------------------------------------------------------
// npx shadcn-ui@latest init
// npx shadcn-ui@latest add tooltip
// ------------------------------------------------------------------------------------------------

import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "@/components/shadcn/ui/tooltip"
import { Code, Eye, FilePen, FileText, Sparkle } from "lucide-react";
import { cn } from "@/lib/utils";
import { useClerk } from "@clerk/clerk-react";
import SettingsMenu from "./SettingsMenu";
import UserAvatar from "../UserAvatar";
import { useCallback, useMemo, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { domain } from "@/config/app";
import check from "@/vendors/check";
import { ReactComponent as EyeClosed} from './eye-closed.svg';

function Separator() {
    return (
        <div className="h-[33px] py-[10px] w-[1px]" >
            <div className="bg-[rgba(23,23,23,0.12)] h-full w-full rounded-t-full rounded-b-full" />
        </div>
    )
}

function urlForPage(page: string) {
    return (window.location.href.includes('localhost') ? domain + window.location.pathname.split('/edit')[0]: window.location.href.split('/edit')[0]) + '/' + page;
}

function urlForName(name: string) {
    if (name === 'chat') {
        return null;
    }
    if (name === 'docs') {
        return urlForPage('docs');
    }
    if (name === 'editor') {
        return urlForPage('edit');
    }
    if (name === 'preview') {
        return urlForPage('');
    }
    if (name === 'code') {
        return urlForPage('code');
    }
}

function QRCodeElement({ name } : {name: string}) {
    const url = urlForName(name);
    if (!url) {
        return null;
    }

    return (
        <a 
            className="w-[52px] h-[52px] rounded-[2px] cursor-pointer" 
            href={url}
            target='_blank' rel="noreferrer"
        >
            <QRCode className="w-full h-full" value={url} />
        </a>
    );

}

function ViewIslandIcon({ name, active }:{ name: string, active: boolean }) {
    if (name === 'chat') {
        return <Sparkle className="w-4 h-4" />;
    }
    if (name === 'docs') {
        return <FileText className="w-4 h-4" />;
    }
    if (name === 'editor') {
        return <FilePen className="w-4 h-4" />;
    }
    if (name === 'preview') {
        if (active) {
            return <Eye className="w-4 h-4" />;
        } else {
            return <EyeClosed className="w-4 h-4" />;
        }
   }
    if (name === 'code') {
        return <Code className="w-4 h-4" />;
    }

    return null;
}

export function ViewIslandButton({ name, label, active, itemClass, onToggle }:{ name: string, label: string, active: boolean, onToggle: (name: string) => void, itemClass: (name: string, className: string) => string }) {
    const clickHandler = useCallback(() => onToggle(name), [name, onToggle]);
    const className = useMemo(() => itemClass(
        name, 
        cn(
            "flex flex-col hover:bg-stone-100 overflow-visible p-2 rounded-[10px] cursor-pointer hover:text-[#5b5e66]",
            active ? 'text-[#1E1F22] bg-[#F4F3F1]' : 'text-gray-400 bg-white',
        ),
    ), [active, itemClass, name]);
    
    return (
        <TooltipProvider delayDuration={150} >
            <div className="no-transition" >
                <Tooltip>
                    <TooltipTrigger asChild >
                        <div 
                            onClick={clickHandler}
                            className={className} >
                            <ViewIslandIcon name={name} active={active} />
                        </div>
                    </TooltipTrigger>
                    <TooltipContent className={cn(
                        "flex flex-col gap-1",
                        "w-fit items-start mb-1 bg-[#1e1f22] border-none",
                        check.nonEmptyString(urlForName(name)) ? 'p-1' : 'py-1.5 px-3',
                    )} side="top" >
                        <QRCodeElement name={name} />
                        <p className="text-xs text-white" >
                            {label}
                        </p>
                    </TooltipContent>
                </Tooltip>
            </div>
        </TooltipProvider>
    );
}

export function ViewControlIsland(
    { value, className, style, onToggle = (id: string) => {}, hide = [] }
    : { className?: string, style?: React.CSSProperties, onToggle: (id: string) => void, value: { [id: string]: boolean }, hide?: string[] }
) {
    const [pinned, setPinned] = useState(true);
    const [upRaw, setUp] = useState(false);
    const upDateRef = useRef<number | null>(null);
    const { user } = useClerk();
    const itemClass = (item: string, className: string) => {
        return cn(
            className,
            hide.includes(item) && 'hidden',
        );
    }

    const up = pinned || upRaw;

    const userName = user?.fullName || user?.firstName || user?.username || 'User';
    const photoUrl = user?.hasImage ? user?.imageUrl : null;

    const showMenu = useCallback(() => {
        upDateRef.current = Date.now();
        setUp(true);
    }, []);

    const CLOSE_DELAY = 500;
    const hideMenu = useCallback(() => {
        setTimeout(() => {
            if ((Date.now() - (upDateRef.current || 0)) > (CLOSE_DELAY - 20)) {
                setUp(false);
                upDateRef.current = null;
            }
        }, CLOSE_DELAY);
    }, []);

    const togglePin = useCallback(() => {
        setTimeout(() => {
            setPinned((p) => {
               const newPin = !p;

               if (!newPin) {
                    setTimeout(() => {
                        setUp(false);
                        hideMenu();
                    }, 200);
               }

               return newPin;
        });
        }, 100);
    }, [hideMenu]);

    const onMouseMove = useCallback(() => {
        upDateRef.current = Date.now();
    }, []);

	return (
		<div 
            onMouseEnter={showMenu}
            onMouseLeave={hideMenu}
            onMouseMove={onMouseMove}
            className={cn(
                !up && !pinned && 'pt-20',
                "absolute bottom-[0px] z-50 flex flex-row gap-1 items-center",
                up && 'bottom-[8px] pb-[0px]',
                up && !pinned && 'pt-20 px-10',
            )}
         >
			<div 
                className={cn(
                "transition-all",
                up ? 'translation-y-[0px]' : 'translate-y-[60px]',
                up ? 'duration-200' : 'duration-300',
                "flex flex-row rounded-[14px] border border-[#e4e4e4] shadow-md shadow-gray-200 w-fit items-center gap-1 overflow-visible h-min p-1 bg-white backdrop-blur-md relative",
             )} >
				<ViewIslandButton 
                    name="chat" 
                    label="AI Chat" 
                    active={value.chat} 
                    onToggle={onToggle} 
                    itemClass={itemClass}
                />
                <Separator />
                <ViewIslandButton
                    name="docs" 
                    label="Docs" 
                    active={value.docs} 
                    onToggle={onToggle} 
                    itemClass={itemClass}
                />
                <ViewIslandButton
                    name="editor" 
                    label="Editor" 
                    active={value.editor} 
                    onToggle={onToggle} 
                    itemClass={itemClass}
                />
                <ViewIslandButton
                    name="preview" 
                    label="Preview" 
                    active={value.preview} 
                    onToggle={onToggle} 
                    itemClass={itemClass}
                />
                <ViewIslandButton
                    name="code" 
                    label="Code" 
                    active={value.code} 
                    onToggle={onToggle} 
                    itemClass={itemClass}
                />
                <Separator />
                <SettingsMenu userName={userName} userImageUrl={photoUrl} asChild togglePin={togglePin} pinned={pinned}>
                    <div className="flex flex-col items-center justify-center cursor-pointer h-[33px] w-[40px] rounded-[10px] px-[4px] py-[4px] bg-white hover:bg-[#F4F3F1]" >
                        <UserAvatar userName={userName} url={photoUrl}  />
                    </div>
                </SettingsMenu>
			</div>
		</div>
	);
}