export const EXAMPLE_PROPS : Record<string, any> = {
    tagLine: 'Freshly roasted',
    title: 'Hayes Valley Espresso',
    description: 'Rich and balanced espresso blend from San Francisco. Notes of dark chocolate, nuts, and caramel. Perfect for espresso and drip coffee.',
    buttonLabel: "Buy now",
    image: 'https://images.unsplash.com/photo-1509042239860-f550ce710b93?crop=entropy&cs=srgb&fm=jpg&ixid=M3w1NjcxMjN8MHwxfHNlYXJjaHwxfHxjb2ZmZWV8ZW58MHx8fHwxNzE3NDk2ODc4fDA&ixlib=rb-4.0.3&q=85',
    button: 'Home',
}

export const CARD_COMPONENT_NAME = 'Card';
export const CARD_COMPONENT_DESCRIPTION = 'A card component';
export const DEFAULT_VARIANT_NAME = "default";
export const DEFAULT_VARIANT_DESCRIPTION = "A default Card"
export const CARD_VARIANT_NAME = "Card";
export const CARD_VARIANT_DESCRIPTION = "A Card with image and description"

export const CARD_BGIMAGE_VARIANT_NAME = 'BgImage';
export const CARD_BGIMAGE_VARIANT_DESCRIPTION = 'A card with a background image';

export const CARD_LANDSCAPE_VARIANT_NAME = 'landscapeImgCard';
export const CARD_LANSCAPE_VARIANT_DESCRIPTION = 'A card with a lanscape image';

export const CARD_SQUARE_VARIANT_NAME = 'squareImgCard';
export const CARD_SQUARE_VARIANT_DESCRIPTION = 'A card with a square image';

export const CARD_BUTTON_VARIANT_NAME = "buttonImgCard";
export const CARD_BUTTON_VARIANT_DESCRIPTION = 'A card with a button to make something';

export const CARD_PORTRAIT_VARIANT_NAME = 'portraitImgCard';
export const CARD_PORTRAIT_VARIANT_DESCRIPTION = 'A card with a portrait image';

export const LIST_ITEM_VARIANT_NAME = "ListItem";
export const LIST_ITEM_VARIANT_DESCRIPTION = "A List Item Card with image - description - button"

export const CARD_OUTLINE_VARIANT_NAME = 'OutlineCard';
export const CARD_OUTLINE_VARIANT_DESCRIPTION = 'A card with a outline';