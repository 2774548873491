import radixDocs from './radix-docs.json';

export type RadixPropDocumentation = {
    name: string,
    required?: boolean,
    type: string,
    default?: any,
    description: string,
    descriptionMdx: string
};

export type RadicSubComponentDocumentation = {
    name: string,
    description: string,
    props: RadixPropDocumentation[],
};

const docsByComponent : Map<string, Map<string, RadicSubComponentDocumentation>> = new Map();

radixDocs.forEach((doc: any) => {
    const components = new Map<string, any>();
    doc.subComponents.forEach((subComponent: any) => {
        components.set(subComponent.name.trim().toLowerCase(), subComponent);
    });
    docsByComponent.set(doc.name.trim().toLowerCase(), components);
});


export function getRadixSubcomponentDocumentation(componentName: string, subComponentName: string) : RadicSubComponentDocumentation | null {
    const comp = docsByComponent.get(componentName.trim().toLowerCase());
    if (!comp) {
        return null;
    }

    return comp.get(subComponentName.trim().toLowerCase()) || null;
} 

export function radixSubDoc(componentNameAndSubname : string) {
    if (!componentNameAndSubname || !componentNameAndSubname.includes('.')) {
        return null;
    }
    const [componentName, subComponentName] = componentNameAndSubname.split('.');
    return getRadixSubcomponentDocumentation(componentName, subComponentName);
}

export function getRadixComponentProps(componentName: string, subComponentName: string) : RadixPropDocumentation[] | null {
    const doc = getRadixSubcomponentDocumentation(componentName, componentName);
    if (!doc) {
        return null;
    }

    return doc.props;
}