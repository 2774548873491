import { Button } from "@/components/shadcn/ui/button";
import { registerComponentVariant } from "../../../lib/components-registry";
import { useComposableApi } from "../../../lib/composable";
import { ComponentType } from "react";
import { IconOf } from "@/vendors/illustrations.dev/IconOf";

function BottomBarButton({ children } : { children: any }) {
    const { composableApi, ChildWithRole } = useComposableApi(children);
    const child = composableApi.getAllChildrenWithRole("button")[0] || null;
    if (!child) {
        return null;
    }

    // Get text from inside the button
    const label = child.props.children;
    if (!label) {
        return null;
    }

    // If button content is not a string, return the child
    if (typeof label !== "string") {
        return (
            <ChildWithRole role="button" className="reset flex flex-col items-center h-full gap-1 justify-between min-w-[62px] hover:text-slate-800 hover:bg-muted rounded-lg text-slate-500 p-1" />
        );
    }

    // If it is a string, generate a button from the string
    return (<Button className="reset flex flex-col items-center h-full gap-1 justify-between min-w-[62px] hover:text-slate-800 hover:bg-muted rounded-lg text-slate-500 p-1" variant="default" >
        <div className="items-center justify-center flex-col flex w-full h-[32px]" >
            <IconOf of={label} className="size-[24px]" variant="lucide" />
        </div>
        <span className="text-[12px]" >
            {label}
        </span>
    </Button>);
}

export function DefaultBottomBar({ items, children } : { items: string[], children: any }) {
    const { composableApi } = useComposableApi(children);
    const buttons = composableApi.getAllChildrenWithRole("button");

    return (
        <div className="items-center flex fixed bottom-0 left-0 right-0 p-2 border-t-[1px] border-b-[1px] border-[#f0f2f5] bg-white px-4 pb-2 pt-0.5 justify-center overflow-y-scroll gap-2" >
            {buttons.map((button: any, i: number) => (
                <BottomBarButton key={'bottom-bar::'+i} >{button}</BottomBarButton>
            ))}
        </div>
    );
}

registerComponentVariant({
    reactComponent: DefaultBottomBar as ComponentType,
    name: "BottomBar",
    variantName: "default",
    description: "A bottom bar for mobile apps. With buttons.",
    variantDescription: "Default variant",
    acceptsProps: ["buttonLabel"],
    acceptOrientations: ["north"],
    acceptSizes: ["md"],
    acceptsChildren: true,
});