import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { getSingleVarNameFromText, removeEmptyProps } from "../../../util";
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { imports, importsRaw, setup } from "../_utils";
import { Textarea } from "@/components/shadcn/ui/textarea";
import { DataInput } from "@/vendors/data/data-v2";
import { ComponentType } from "react";
import { cn } from "@/lib/utils";

/*
export function TextareaDemo() {
  return <Textarea placeholder="Type your message here." />
}
*/

const TextareaComponentParser: ComponentParser = {
  name: 'Textarea',
  description: 'Displays a form textarea or a component that looks like a textarea.',
  tags: ['textarea'],
  refImplementation: `
/textarea Type your message here.
  `.trim(),
  parseTree: (c) => {
    let { text } = c;
    const props = { ...c.props };
    const textVar = getSingleVarNameFromText(text);
    let wrap = (w: ComponentTreeNode) => w;
    if (textVar) {
      text = text?.replaceAll(/\$\w+/g, '')?.trim();

      wrap = (w: ComponentTreeNode) => ({
          component: DataInput as ComponentType,
          htmlComponent: 'DataInput',
          props: {
              bind: textVar,
          },
          classes: [],
          variant: null,
          children: [w]
      });
    }

    props.placeholder = props.placeholder || text || null;
    props.className = cn(c.classes, c.props?.className);
    return wrap({ 
        ...c, 
        component: Textarea, 
        htmlComponent: 'Textarea', 
        props: removeEmptyProps(props), 
        children: null 
    });
  },

  variants: [],
  imports: [
    ...imports(['Textarea'], 'textarea'),
    ...importsRaw(['DataInput'], '@layouts.dev/utils'),
  ],
  setup: [
    ...setup('textarea'),  
    'npm install --save @layouts.dev/utils',
  ],
};

registerShadcnComponent({
        Textarea
    },
    'textarea',
);

export default TextareaComponentParser;