import { Badge } from "@/components/shadcn/ui/badge"
import { ComponentParser, ComponentTreeNode } from "../../component.type"
import { selectVariantWithUndefinedDefault } from "../../../util";
import { imports, setup } from "../_utils";
import check from "@/vendors/check";
import { nonEmptyTrimmed } from "@/lib/utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
export function BadgeDemo() {
  return <Badge 
    variant={'default' // | 'secondary' | 'outline' | 'destructive'
    }
    >Badge</Badge>
}
*/

const VARIANTS = [
    'default',
    'secondary',
    'outline',
    'destructive',
];

const BadgeComponentParser : ComponentParser = {
    name: 'Badge',
    description: 'Displays a badge',
    refImplementation: `
/badge %default Badge content here
    `.trim(),
    tags: ['badge', 'tag'],
    parseTree: (c: ComponentTreeNode) => {
        const props = {...(c.props || {})};
        const variant = selectVariantWithUndefinedDefault(VARIANTS, c.variant || c?.props?.variant);
        props.variant = variant;

        // Use text if defined
        const children = [...(c.children || [])];
        if (check.nonEmptyString(c.text) && nonEmptyTrimmed(c.text) && children?.[0] !== c.text) {
            children.unshift(c.text);
        }
        
        return {
            ...c,
            variant,
            props,
            classes: [...(c.classes || []), 'w-fit'],
            component: Badge,
            htmlComponent: 'Badge',
            children,
        }
    },
    imports: imports(['Badge'], 'badge'),
    setup: setup('badge'),
    variants: VARIANTS,
};

registerShadcnComponent({
        Badge
    },
    'badge',
);

export default BadgeComponentParser;