import { Separator } from "@/components/shadcn/ui/separator"
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { imports, radixComponentDoc, setup } from "../_utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
export function SeparatorDemo() {
  return (
    <div>
      <div className="space-y-1">
        <h4 className="text-sm font-medium leading-none">Radix Primitives</h4>
        <p className="text-sm text-muted-foreground">
          An open-source UI component library.
        </p>
      </div>
      <Separator className="my-4" />
      <div className="flex h-5 items-center space-x-4 text-sm">
        <div>Blog</div>
        <Separator orientation="vertical" />
        <div>Docs</div>
        <Separator orientation="vertical" />
        <div>Source</div>
      </div>
    </div>
  )
}
*/

const SeparatorComponentParser : ComponentParser = {
  name: 'Separator',
  description: 'Visually or semantically separates content',
  refImplementation: `
  /div 
    /div space-y-1
      /h4 text-sm font-medium leading-none Radix Primitives
      /p text-sm text-muted-foreground An open-source UI component library.
    /separator my-4
    /div flex h-5 items-center space-x-4 text-sm
      /div Blog 
      /separator %vertical
      /div Docs
      /separator %vertical
      /div Source
  `.trim(),
  tags: ['separator', 'hr', 'divider'],
  parseTree: (c: ComponentTreeNode) => {
      let orientation = 'horizontal';
      if (c.variant && ['vertical', 'horizontal'].includes(c.variant)) {
          orientation = c.variant;
      }
      if (c.props.orientatin && ['vertical', 'horizontal'].includes(c.props.orientation)) {
          orientation = c.props.orientation;
      }
      
      return {
          ...c,
          component: Separator,
          htmlComponent: 'Separator',
          props: { 
            ...c.props, 
            orientation, 
          },
          variant: null,
      };
  },
  imports: imports(['Separator'], 'separator'),
  setup: setup('separator'),
  doc: {
      props: radixComponentDoc('Separator.Root').props,
  },
  variants: [
    'horizontal',
    'vertical',
  ]
};

registerShadcnComponent({
        Separator,
    },
    'separator',
);

export default SeparatorComponentParser;



