"use client"

import * as React from "react"

import { cn, nonEmptyTrimmed } from "@/lib/utils"
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/shadcn/ui/navigation-menu"
import { NavigationMenu as RadixNavigationMenu } from "@radix-ui/react-navigation-menu";
import { ComponentAliasConfig, childrenParsers, componentHasChildrenOfTypes, hasDescendantMatching, imports, isTextComponent, radixComponentDoc, setup } from "../_utils"
import { ComponentParser, ComponentTreeNode } from "../../component.type"
import { addStagesIf, appendComponentIfNotExisting, deepRemoveSameTypeChildren, ensureAllElementsHaveAChildrenArray, markTreeLeafs, mergeAllComponentsOfType, onlyAllowTypes, parseTextComponents, removeFirstOccurenceOfTextFromChildren, solveAliases, transformComponentsOfTypes, treeParsingPipeline, wrapComponentsNotOfTypes } from "../../parsing-pipeline"
import check from "@/vendors/check"
import { parseInputOrCommandText } from "../../layouts-lang"
import { getInnerClasses, getOuterClasses } from "@/lib/tailwind/tailwindClasses"
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { ICON_COMPONENT_HTML_TYPE } from "../../base/images";

const NavigationMenuSub = RadixNavigationMenu;

/*
export function NavigationMenuDemo() {
  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Getting started</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              <li className="row-span-3">
                <NavigationMenuLink asChild>
                  <a
                    className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
                    href="/"
                  >
                    <UserIcon className="h-6 w-6" />
                    <div className="mb-2 mt-4 text-lg font-medium">
                      shadcn/ui
                    </div>
                    <p className="text-sm leading-tight text-muted-foreground">
                      Beautifully designed components that you can copy and
                      paste into your apps. Accessible. Customizable. Open
                      Source.
                    </p>
                  </a>
                </NavigationMenuLink>
              </li>
              <ListItem href="/docs" title="Introduction">
                Re-usable components built using Radix UI and Tailwind CSS.
              </ListItem>
              <ListItem href="/docs/installation" title="Installation">
                How to install dependencies and structure your app.
              </ListItem>
              <ListItem href="/docs/primitives/typography" title="Typography">
                Styles for headings, paragraphs, lists...etc
              </ListItem>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Components</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
              {components.map((component) => (
                <ListItem
                  key={component.title}
                  title={component.title}
                  href={component.href}
                >
                  {component.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link href="/docs" legacyBehavior passHref>
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              Documentation
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = "ListItem"
*/


const TAG = 'navigation-menu';
const TAGS = [TAG, 'nav-menu', 'navmenu'];

const NavigationMenuListItem = (props: any) => null;
const ListItemTitle = (props: any) => null;
const ListItemDescription = (props: any) => null;

const ALIASES : ComponentAliasConfig[] = [
  ['menu', NavigationMenu, 'NavigationMenu', radixComponentDoc('NavigationMenu.Root')],
  ['content', NavigationMenuContent, 'NavigationMenuContent', radixComponentDoc('NavigationMenu.Content')],
  ['item', NavigationMenuItem, 'NavigationMenuItem', radixComponentDoc('NavigationMenu.Item')],
  ['link', NavigationMenuLink, 'NavigationMenuLink', radixComponentDoc('NavigationMenu.Link')],
  ['list', NavigationMenuList, 'NavigationMenuList', radixComponentDoc('NavigationMenu.List')],
  ['trigger', NavigationMenuTrigger, 'NavigationMenuTrigger', radixComponentDoc('NavigationMenu.Trigger')],
  ['sub', NavigationMenuSub, 'RadixNavigationMenu.Sub', radixComponentDoc('NavigationMenu.Sub')],
  ['list-item', NavigationMenuListItem, 'NavigationMenuListItem'],
  ['title', ListItemTitle, 'ListItemTitle'],
  ['description', ListItemDescription, 'ListItemDescription'],
];

function parserNavigationMenuListItem(c: ComponentTreeNode) : ComponentTreeNode {
  const outerClasses = getOuterClasses(c.classes, true);
  const innerClasses = getInnerClasses(c.classes, true);

  // Grab text from first child if needed
  // FIXME: This shouldn't be necessary
  let text = c.text;
  if ((c.children || []).length > 1 && check.nonEmptyString(c.children?.[0])) {
    if (text !== c.children?.[0]) {
      text = c.children?.[0];
      c.children = (c.children || []).slice(1);
    }
  }

  const titleChild = c.children?.find((child) => ['title', ListItemTitle].some((type) => child.component === type)) || {};
  const descriptionChild = c.children?.find((child) => ['description', ListItemDescription].some((type) => child.component === type)) || {};
  const otherChildren = c.children?.filter((child) => !['title', 'description', ListItemTitle, ListItemDescription].some((type) => child.component === type));

  // Get title and description from either (i) text, (ii) props, (iii) text & description children components
  const { label, description } = {
    ...(parseInputOrCommandText(text, { description: true })),
    ...(nonEmptyTrimmed(titleChild?.children?.[0]) ? { label: titleChild?.children?.[0] } : {}),
    ...(nonEmptyTrimmed(titleChild?.text) ? { label: titleChild.text } : {}),
    ...(nonEmptyTrimmed(descriptionChild?.children?.[0]) ? { description: descriptionChild?.children?.[0] } : {}),
    ...(nonEmptyTrimmed(descriptionChild?.text) ? { description: descriptionChild.text } : {}),
    ...(nonEmptyTrimmed(c.props?.title) ? { label: c.props.title } : {}),
    ...(nonEmptyTrimmed(c.props?.description) ? { description: c.props.description } : {}),
  };

  const labelClasses = cn('text-sm', 'font-medium', 'leading-none', titleChild?.classes || []).split(' ');
  const descriptionClasses = cn('line-clamp-2', 'text-sm', 'leading-snug', 'text-muted-foreground', descriptionChild?.classes || []).split(' ');

 const { label: l, description: d, ...cleanProps} = c.props || {};


  return {
    _isLeaf: true,
    component: 'li',
    htmlComponent: 'li',
    classes: outerClasses,
    props: { className: outerClasses.join(' ') },
    children: [
      {
        component: NavigationMenuLink,
        htmlComponent: 'NavigationMenuLink',
        classes: [],
        props: { asChild: false },
        children: [
          {
            component: 'a',
            htmlComponent: 'a',
            classes: cn(
              "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              innerClasses,
            ).split(' '),
            props: {
              ...(cleanProps || {}),
              className: cn(
                "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
                innerClasses,
              ),
            },
            children: [
              nonEmptyTrimmed(label) ? 
              {
                component: 'div',
                htmlComponent: 'div',
                classes: labelClasses,
                props: { ...(titleChild?.props || {}), className: labelClasses.join(' ') },
                children: [label],
              } : null,
              nonEmptyTrimmed(description) ? 
              {
                component: 'p',
                htmlComponent: 'p',
                classes: descriptionClasses,
                props: {
                  ...(descriptionChild?.props || {}),
                  className: descriptionClasses.join(' '),
                },
                children: [description],
              } : null,
              check.nonEmptyArray(otherChildren || []) ?
              {
                component: 'div',
                htmlComponent: 'div',
                classes: ['text-sm', 'leading-snug', 'text-muted-foreground'],
                props: { className: cn('text-sm', 'leading-snug', 'text-muted-foreground') },
                children: otherChildren,
              } : null,
            ].filter(c => !!c),
          }
        ],
      },
    ],
  };
}

function parseTextChild(c: ComponentTreeNode, parents: React.ComponentType[]) : ComponentTreeNode {
  const isMenu = [NavigationMenu, NavigationMenuList].some(type => parents[parents.length - 1] === type) && check.nonEmptyArray(c.children);

  // If component is a menu or supermenu, wrap it's children and return the right component
  if (isMenu) {
    const ulClasses = /* (c.children || []).length > 3 */ true ? ['grid', 'gap-3', 'p-4', 'md:w-[500px]', 'md:grid-cols-2', 'lg:w-[600px]'] : ['grid', 'gap-3', 'p-4', 'md:w-[250px]', 'lg:w-[300px]'];

    return {
      component: NavigationMenuItem,
      htmlComponent: 'NavigationMenuItem',
      classes: [],
      props: {},
      children: [
        {
          _isLeaf: true,
          component: NavigationMenuTrigger,
          htmlComponent: 'NavigationMenuTrigger',
          children: [c.text || ''],
        },
        {
          classes: [],
          props: {},
          component: NavigationMenuContent,
          htmlComponent: 'NavigationMenuContent',
          children: 
          [
            ((c.children?.length === 1) && (['sub', NavigationMenuSub].some(comp => c.children?.[0]?.component === comp))) 
            ? c.children?.[0]
            : {
              component: 'ul',
              htmlComponent: 'ul',
              classes: cn(ulClasses, c.classes).split(' '),
              props: { ...(c.props || {}), className: cn(ulClasses, c.classes) },
              children: c.children || [],
            }
          ]
        }
      ],
    };
  }


  // //////////////////////////////////////////////////////// 
  // Else, it's a leaf
  // ////////////////////////////////////////////////////////

  // If we're inside a NavigationMenuList, wrap the text in a NavigationMenuItem
  if ([NavigationMenu, NavigationMenuList].some(type => parents[parents.length - 1] === type)) {
    return {
      _isLeaf: true,
      component: NavigationMenuItem,
      htmlComponent: 'NavigationMenuItem',
      classes: [],
      props: {},
      children: [{
        component: NavigationMenuLink,
        htmlComponent: 'NavigationMenuLink',
        classes: cn(navigationMenuTriggerStyle(), c.classes).split(' '),
        props: { ...(c.props || {}), className: cn(navigationMenuTriggerStyle(), c.classes), },
        children: [c.text || ''],
      }],
    };
  }

    /*
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
    */

  return parserNavigationMenuListItem({
    ...c,
    component: NavigationMenuListItem,
    htmlComponent: 'NavigationMenuListItem',
  });
}



  const NavigationMenuComponentParser : ComponentParser = {
    name: 'Navigation Menu',
    description: 'A collection of links for navigating websites.',
    tags: TAGS,

    
    refImplementation: `
/navigation-menu
    Getting started lg:grid-cols-[.75fr_1fr]
        /list-item row-span-3 size-full rounded-md justify-end flex flex-col bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none hover:bg-transparent focus:shadow-md cursor-pointer @href=#/list-item
            /div text-slate-900 flex flex-col justify-end h-full
                /icon logo h-6 w-6
                /div mb-2 mt-4 text-lg font-medium 
                    shadcn/ui
                /p text-sm leading-tight text-slate-700
                    Beautifully designed components that you can copy and paste into your apps. Accessible. Customizable. Open Source.
        Introduction
            Re-usable components built using Radix UI and Tailwind CSS.
        Installation @href=#/docs/installation
            How to install dependencies and structure your app. 
        Typography @href=#/docs/primitives/typography
            Styles for headings, paragraphs, lists...etc   
    Components
          Alert Dialog
              @href=#/docs/primitives/alert-dialog
              @description="A modal dialog that interrupts the user with important content and expects a response."
          Hover Card
              @href=#/docs/primitives/hover-card
              @description="For sighted users to preview content available behind a link."
          Progress
              @href=#/docs/primitives/progress
              @description="Displays an indicator showing the completion progress of a task, typically displayed as a progress bar."
          Scroll-area
              @href=#/docs/primitives/scroll-area
              @description="Visually or semantically separates content."
          Tabs
              @href=#/docs/primitives/tabs
              @description="A set of layered sections of content—known as tab panels—that are displayed one at a time."
          Tooltip
              @href=#/docs/primitives/tooltip
              @description="A popup that displays information related to an element when the element receives keyboard focus or the mouse hovers over it."
    Documentation @href="#/docs"
    `.trim(),
    
    childrenParsers: childrenParsers(ALIASES, TAGS),
  
    parseTree: (c: ComponentTreeNode) : ComponentTreeNode => {

      console.log('Navgation menu: Parsing tree', c);

      const parsed = treeParsingPipeline([
        // Remove text from children if any
        addStagesIf(
          NavigationMenu,
          [removeFirstOccurenceOfTextFromChildren(c.text)],
        ),

        // Solve alias components
        solveAliases(ALIASES),

        // Mark a, link, buttons, icons, trigger as leafs
        markTreeLeafs([
          NavigationMenuLink,
          NavigationMenuTrigger,
          'a',
          'link',
          'button',
          'icon',
          ICON_COMPONENT_HTML_TYPE,
          'IconOf',
          'img',
          'ImageOf'
        ]),
        
        // Parse item components
        // transformComponentsOfTypes([CommandItem], parseItemComponent),

        // Parse list items 
        transformComponentsOfTypes([NavigationMenuListItem], parserNavigationMenuListItem),

        // Parse text components
        parseTextComponents(parseTextChild),

        // If we're at root, wrap everything in a NavigationMenuList
        // Make sure there is at least one NavigationMenuList
        addStagesIf(
          NavigationMenu,
          [
            wrapComponentsNotOfTypes([], NavigationMenuList, 'NavigationMenuList'), 
            mergeAllComponentsOfType(NavigationMenuList),
            appendComponentIfNotExisting(NavigationMenuList, { component: NavigationMenuList, htmlComponent: 'NavigationMenuList', classes:[], props:{}, children: [] }),
          ]
        ),
      ]).run({...c, component: NavigationMenu}, []);

      const children = parsed.children || [];

      const out = {
        ...parsed,
        component: NavigationMenu,
        htmlComponent: 'NavigationMenu',
        children,
      };

      return out;
    },
  
    imports: [
      ...imports([
        'NavigationMenu',
        'NavigationMenuContent',
        'NavigationMenuItem',
        'NavigationMenuLink',
        'NavigationMenuList',
        'NavigationMenuTrigger',
      ], TAG),
      `import { NavigationMenu as RadixNavigationMenu } from "@radix-ui/react-navigation-menu";`,
    ],
    setup: setup(TAG),
    variants: [],
    doc: {
      props: radixComponentDoc('NavigationMenu.Root').props,
    }
  };

registerShadcnComponent({
        NavigationMenu,
        NavigationMenuContent,
        NavigationMenuItem,
        NavigationMenuLink,
        NavigationMenuList,
        NavigationMenuTrigger,
    },
    TAG,
);
  
  export default NavigationMenuComponentParser;