import checkTypes from 'check-types';

const check = {
    ...checkTypes,

    nonEmptyString: (str: any): str is string => checkTypes.nonEmptyString(str),
    nonEmptyObject: (input: any): input is { [key: string]: any } => checkTypes.nonEmptyObject(input),
    nonEmptyArray: (input: any): input is any[] => checkTypes.nonEmptyArray(input),
    function: (input: any): input is ((...args: any[]) => any) => checkTypes.function(input),
}

export default check;