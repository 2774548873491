import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/utils";

const CardVariantsW = cva("",{ 
    variants: {
        size: {
            "xs": "w-[173px] min-w-[173px] max-w-[173px]",
            "sm": "w-[232px] min-w-[232px] max-w-[232px]",
            "md": "w-[301px] min-w-[301px] max-w-[301px]",
            "lg": "w-[390px] min-w-[390px] max-w-[390px]",
            "xl": "w-[600px] min-w-[600px] max-w-[600px]",
            "2xl": "w-[928px] min-w-[928px] max-w-[928px]",
            "3xl": "",
            "4xl": "",
            "5xl": "",
            "6xl": "",
        },
        roundness: {
            'none': "rounded-none",
            "sm": "rounded-sm",
            "md": "rounded-md",
            "lg": "rounded-lg",
            "xl": "rounded-xl",
        }
    },
    defaultVariants: {
        size: "lg",
        roundness: 'xl',
    },
});

const CardVariantsH = cva("",{ 
    variants: {
        size: {
            "xs": "h-[97px] min-h-[97px] max-w-[97px]",
            "sm": "h-[132px] min-h-[132px] max-w-[132px]",
            "md": "h-[169px] min-h-[169px] max-h-[169px]",
            "lg": "h-[219px] min-h-[219px] max-h-[219px]",
            "xl": "h-[338px] min-h-[338px] max-h-[338px]",
            "2xl": "h-[522px] min-h-[522px] max-h-[522px]",
            "3xl": "",
            "4xl": "",
            "5xl": "",
            "6xl": "",
        },
    },
    defaultVariants: {
        size: "lg",
    },
});

interface CardProps extends VariantProps<typeof CardVariantsW> {
    image : string,
    title: string,
    description: string,
}

function Card_L({
    image,
    title,
    description,
    size,
    roundness,
} : CardProps ) {
    return (
        <div className={cn(CardVariantsW({size}), `h-fit flex-col justify-start items-start pb-[5px]`)}>
            <img className={cn(CardVariantsW({size, roundness}), CardVariantsH({size}), "aspect-video object-cover")} src={image} />
            <div className={cn(CardVariantsW({size}),"self-stretch text-neutral-900 dark:text-white typeface-display-base mt-[12px]")}>{title}</div>
            <div className="self-stretch text-slate-500 dark:text-slate-400 typeface-body-sm max-w-full min-h-fit ">{description}</div>
        </div>
    )
}

export default Card_L;