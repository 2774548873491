import { useEffect, useState } from 'react';
import uniqid from 'uniqid'; 
import check from 'check-types';
import feathersApp from '../initializers/feathers';

/*
const removeDuplicateIds = (data) => {
    const ids = {};
    return data.filter(d => {
        if (ids[d._id]) return false;
        ids[d._id] = true;
        return true;
    });
}
*/

const useMongoLiveQuery = (dbName: string, collectionName: string, query: any) => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);
    const [writeHandlers, setWriteHandlers] = useState<any>({});

    useEffect(() => {
        if (!check.nonEmptyString(dbName)) {
            return;
        }
        if (!check.nonEmptyString(collectionName)) {
            return;
        }

        const client = feathersApp;
        const service = client.service(`${dbName}.${collectionName}`);
        const id = uniqid();

        const fetchData = async (noLoading = false) => {
            try {
                if (!noLoading) {
                    setLoading(true);
                }
                
                const response = await service.find({query});
                console.log('RECEIVED Remote response: ', response)
                setData([...(response.data || response)]);
                setError(null);
            } catch (err) {
                setError(err);
                // setData([]);
                console.log('MONGO DATA ERROR: ', err)
            } finally {
                setLoading(false);
            }
        };

        const update = async (id: string, data: any) => {
            try {
                await service.update(id, data);
            } catch (err) {
                setError(err);
            }
        }
        const create = async (data: any) => {
            try {
                await service.create(data);
            } catch (err) {
                setError(err);
            }
        }
        const remove = async (id: string) => {
            try {
                await service.remove(id);
            } catch (err) {
                setError(err);
            }
        }
        const clear = async () => {
            try {
                await service.remove(null, { query: {} });
            } catch (err) {
                setError(err);
            }
        }
        
        fetchData();

        // Real-time updates
        const createdListener = (item: any) => { 
            console.log('RECEIVED Remote created: ', item)
        
            setData(currentData => [...currentData, item]) 
        
        }; /* fetchData(true).catch(console.error); }; */ 
        const updatedListener = (item: any) => { 
            console.log('RECEIVED Remote updated: ', item)
            setData(currentData => currentData.map(d => d._id === item._id ? item : d)); 
        };
        const removedListener = (item: any) => { 
            console.log('RECEIVED Remote removed: ', item)
            setData(currentData => currentData.filter(d => d._id !== item._id)); 
        };

        service.on('created', createdListener);
        service.on('updated', updatedListener);
        service.on('patched', updatedListener);
        service.on('removed', removedListener);


        // Output write handlers
        setWriteHandlers({
            create,
            update,
            remove,
            clear,
        });

        // Cleanup
        return () => {
            service.off('created', createdListener);
            service.off('updated', updatedListener);
            service.off('patched', updatedListener);
            service.off('removed', removedListener);
            // socket.close();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dbName, collectionName, JSON.stringify(query)]);

    return { data, loading, error, writeHandlers };
};

export default useMongoLiveQuery;
