// Regrouping all layouts-language specific parsing utils here
import { nonEmptyTrimmed } from "@/lib/utils";
import check from "@/vendors/check";
import arrayUnique from "array-unique";


// Regexps 
// Note: using function to avoid stateful regexps
const SEPARATOR = () => /^-{2,}$/g;
const RADIO = () => /^[(]{1}\s*[xXoOvV.*+_-]{0,1}\s*[)]{1}/g;
const CHECKBOX = () => /^\[\s*[xXoOvV.*+_-]{0,1}\s*\]/g;
const SWITCH_HAS = () => /^<\s*[xXoOvV.*+_-]{0,1}\s*>/g;
const SHORTCUT_HAS = () => /<[^<>]+>$/g;
const SHORTCUT_EXTRACT = () => /<([^>]+)>(?!.*<[^>]+>)/;
const TITLE = () => /^#{1}\s*[a-zA-Z0-9]+.*/g;
const DESCRIPTION_HAS = () => /(::|--)\s*[a-zA-Z0-9]+.*/;
const DESCRIPTION_EXTRACT = () => /[:-]{2}\s*([^<[#]+).*/;
const INPUT_TEXT_HAS = () => /^\[[^>\s]{1}.+\]$/;
const INPUT_TEXT_EXTRACT = () => /^\[(.+)\]$/;
const TEXTAREA_HAS = () => /^\[\s*>\s*.{2,}\]$/;
const TEXTAREA_EXTRACT = () => /^\[\s*>\s(.+)\]$/;
// /////////////////////////////////////////////////////////////////////////////////
// Parsers 
// /////////////////////////////////////////////////////////////////////////////////
type LayoutsLangItemParserResult = [text: string, props: { [key: string] : any }];
export type LayoutsLangItemParser = ([text, props]: LayoutsLangItemParserResult) => LayoutsLangItemParserResult;

// If the text is a separator, return '' as the text and { type: 'separator' } as the props
function separatorParser([text, props]: LayoutsLangItemParserResult) : LayoutsLangItemParserResult {
    if (!check.nonEmptyString(text)) {
        return ['', props];
    }

    if (SEPARATOR().test(text)) {
        return ['', { ...props, type: 'separator' }];
    }
    return [text, props];
}

function shortcutParser([text, props]: LayoutsLangItemParserResult) : LayoutsLangItemParserResult {
    if (!check.nonEmptyString(text)) {
        return ['', props];
    }

    if (hasShorcutPattern(text)) {
        const rawShortcut = getShortcutText(text);
        const shortcut = getParsedShortcut(text);
        const label = rawShortcut ? text.trim().substring(0, text.indexOf(rawShortcut)).trim() : text;
        return [
            label, 
            { ...props, shortcut }
        ];
    }

    return [text, props];
}

function radioParser([text, props]: LayoutsLangItemParserResult) : LayoutsLangItemParserResult {
    if (!check.nonEmptyString(text)) {
        return ['', props];
    }

    if (startsWithRadioPattern(text)) {
        return [
            text.trim().replace(RADIO(), '').trim(),
            { ...props, type: 'radio', checked: isChecked(text) }
        ];
    }

    return [text, props];
}

function checkboxParser([text, props]: LayoutsLangItemParserResult) : LayoutsLangItemParserResult {
    if (!check.nonEmptyString(text)) {
        return ['', props];
    }

    if (startsWithCheckboxPattern(text)) {
        return [
            text.trim().replace(CHECKBOX(), '').trim(),
            { ...props, type: 'checkbox', checked: isChecked(text) }
        ];
    }

    return [text, props];
}

function switchParser([text, props]: LayoutsLangItemParserResult) : LayoutsLangItemParserResult {
    if (!check.nonEmptyString(text)) {
        return ['', props];
    }

    if (SWITCH_HAS().test(text.trim())) {
        return [
            text.trim().substring(text.indexOf('>') + 1).trim(),
            { ...props, type: 'switch', checked: isChecked(text) }
        ];
    }

    return [text, props];
}

function titleParser([text, props]: LayoutsLangItemParserResult) : LayoutsLangItemParserResult {
    if (!check.nonEmptyString(text)) {
        return ['', props];
    }

    if (TITLE().test(text)) {
        return [
            text.trim().substring(1).trim(),
            { ...props, type: 'title' }
        ];
    }

    return [text, props];
}

function descriptionParser([text, props]: LayoutsLangItemParserResult) : LayoutsLangItemParserResult {
    if (!check.nonEmptyString(text)) {
        return ['', props];
    }

    if (DESCRIPTION_HAS().test(text)) {
        const description = text.match(DESCRIPTION_EXTRACT())?.[1];
        if (!description) {
            return [text, props];
        }
        return [
            text.trim().replace(description, '').replace('::', '').replace('--', '').replace('  ', ' ').trim(),
            { ...props, description }
        ];
    }

    return [text, props];
}

function inputTextParser([text, props]: LayoutsLangItemParserResult) : LayoutsLangItemParserResult {
    if (!check.nonEmptyString(text)) {
        return ['', props];
    }

    if (INPUT_TEXT_HAS().test(text)) {
        const inputText = text.match(INPUT_TEXT_EXTRACT())?.[1];
        if (!inputText) {
            return [text, props];
        }
        return [
            text.trim().replace(inputText, '').replace('[', '').replace(']', '').trim(),
            { ...props, type: 'input', placeholder: inputText }
        ];
    }

    return [text, props];
}

function textAreaParser([text, props]: LayoutsLangItemParserResult) : LayoutsLangItemParserResult {
    if (!check.nonEmptyString(text)) {
        return ['', props];
    }

    if (TEXTAREA_HAS().test(text)) {
        const inputText = text.match(TEXTAREA_EXTRACT())?.[1];
        if (!inputText) {
            return [text, props];
        }
        return [
            text.trim().replace(inputText, '').replace('[', '').replace(']', '').trim(),
            { ...props, type: 'textarea', placeholder: inputText }
        ];
    }

    return [text, props];
}

// Parse text and transform it into a spec object for an item or input
export function parseInputOrCommandText(text: any, features: {
    shortcut?: boolean,
    radio?: boolean,
    checkbox?: boolean,
    switch?: boolean,
    separator?: boolean,
    title?: boolean,
    description?: boolean,
    input?: boolean,
    textarea?: boolean,
}) : { [key:string]: any } {
    if (!check.nonEmptyString(text) || !nonEmptyTrimmed(text)) {
        return {};
    }

    const stages = [];
    if (features.separator) {
        stages.push(separatorParser);
    }
    if (features.shortcut) {
        stages.push(shortcutParser);
    }
    if (features.radio) {
        stages.push(radioParser);
    }
    if (features.checkbox) {
        stages.push(checkboxParser);
    }
    if (features.switch) {
        stages.push(switchParser);
    }
    if (features.title) {
        stages.push(titleParser);
    }
    if (features.description) {
        stages.push(descriptionParser);
    }
    if (features.textarea) {
        stages.push(textAreaParser);
    }
    if (features.input) {
        stages.push(inputTextParser);
    }

    let result : LayoutsLangItemParserResult = [text, {}];
    for (let stage of stages) {
        result = stage(result);
    }

    return {...result[1], label: result[0]};
}



// /////////////////////////////////////////////////////////////////////////////////
// Legacy below
// /////////////////////////////////////////////////////////////////////////////////
export function startsWithCheckboxPattern(text: string) {
    return /^\[\s*[xXoOvV.*+_-]{0,1}\s*\]/g.test(text);
}

export function startsWithRadioPattern(text: string) {
    return /^[(]{1}\s*[xXoOvV.*+_-]{0,1}\s*[)]{1}/g.test(text);
}

export function isChecked(text: string) {
    if (!check.nonEmptyString(text)) {
        return false;
    }
    let unspaced = text.replaceAll(/\s/g, '');
    if (unspaced.length < 3) {
        return false;
    }

    const brackets: { [key: string]: string } = {
        '[': ']',
        '(': ')',
        '<': '>',
    };

    if (!brackets[unspaced[0]]) {
        return false;
    }

    const expectedClosing = brackets[unspaced[0]];
    // Return true if we find a closing bracket at the 3rd position and another non-space character in between
    return unspaced[1] !== expectedClosing && unspaced[2] === expectedClosing;
}

export function hasShorcutPattern(text: string) {
    return SHORTCUT_HAS().test(text.trim());
}

export function getShortcutText(text: string) {
    return text.match(SHORTCUT_EXTRACT())?.[0] || null;
}

// Return an item text after stripping the radio, checbox and shortcut patterns
export function getItemMainText(text: string) {
    return text.trim().replace(/^\[\s*[xXoOvV.*+_-]{0,1}\s*\]/g, '').replace(/^[(]{1}\s*[xXoOvV.*+_-]{0,1}\s*[)]{1}/g, '').replace(/<.*>/g, '').trim();
}

// Transform shortcuts like "Cmd + S" to "⌘S"
export function parseShortcutText(rawText: string) {
    let text = rawText.trim();
    if (text.startsWith('<')) {
        text = text.substring(1);
    }
    if (text.endsWith('>')) {
        text = text.substring(0, text.length - 1);
    }

    const aliases : { [alias: string ]: string } = {
        'cmd': '⌘',
        'command': '⌘',
        'ctrl': '⌃',
        'control': '⌃',
        'shift': '⇧',
        'alt': '⌥',
        'option': '⌥',
        'enter': '↩',
        'return': '↩',
        'delete': '⌫',
        'backspace': '⌫',
        'esc': '⎋',
        'escape': '⎋',
        'space': '␣',
        'arrup': '↑',
        'arrdown': '↓',
        'arrleft': '←',
        'arrright': '→',
        'arrowup': '↑',
        'arrowdown': '↓',
        'arrowleft': '←',
        'arrowright': '→',
        'up': '↑',
        'down': '↓',
        'left': '←',
        'right': '→',
        'arrow-up': '↑',
        'arrow-down': '↓',
        'arrow-left': '←',
        'arrow-right': '→',
        'arr-up': '↑',
        'arr-down': '↓',
        'arr-left': '←',
        'arr-right': '→',
        '⌘': '⌘',
        '⌃': '⌃',
        '⇧': '⇧',
        '⌥': '⌥',
        '↩': '↩',
        '⌫': '⌫',
        '⎋': '⎋',
        '␣': '␣',
        '↑': '↑',
        '↓': '↓',
        '←': '←',
        '→': '→',
    };

    // Convert aliases
    let shortcut = text.trim().toLowerCase();
    for (let alias in aliases) {
        if (alias.length > 1) {
        shortcut = shortcut.replaceAll(alias, aliases[alias]);
        }
    }

    // Split aliases and rest of keys
    const words = shortcut.split(' ').map(w => w.split('+')).flat().filter(check.nonEmptyString);
    const modifiers = arrayUnique(words.filter(w => w.length === 1 && aliases[w]));
    const otherKeys = arrayUnique(words.filter(w => modifiers.indexOf(w) === -1));

    // Join modifiers and other keys
    shortcut = modifiers.join('') + otherKeys.join('');

    // Return uppercase shortcut 
    return shortcut.toUpperCase();
}

export function getParsedShortcut(text: string) {
    if (!hasShorcutPattern(text)) {
        return null;
    }
    const shortcutText = getShortcutText(text);
    if (!check.nonEmptyString(shortcutText)) {
        return null;
    }
    const out = parseShortcutText(shortcutText);
    return out;
}