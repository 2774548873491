"use client"

import { cn } from "@/lib/utils"
import { ComponentParser, ComponentTreeNode } from "../component.type"
import { imports, removeOneOccurenceOfText, setup } from "../shadcn/_utils"
import { commandParseTree } from "../shadcn/_tests-ok/command"
import check from "@/vendors/check"
import { getSingleVarNameFromText } from "../../util"
import { ComponentParserConfig } from "../../parser-config"
import { registerShadcnComponent } from "../../importsRegistry"
import { DataInput } from "@/vendors/data/data-v2"
import { AutoComplete } from "@/components/shadcn/ui/auto-complete"

const TAG = 'auto-complete';
const AutoCompleteComponentParser : ComponentParser = {
  name: 'Auto-complete',
  description: 'Autocomplete input and command palette with a list of suggestions',
  tags: [TAG],

  refImplementation: `
    /auto-complete Select an action...
    # Suggestions
        Calendar
        Search Emoji
            /icon smiley
        Calculator
    ---
    # Settings
        Profile
            /icon User profile
        Billing
            /icon Credit card
        Settings
  `.trim(),


  parseTree: (c: ComponentTreeNode, config: ComponentParserConfig) => {
    const parsedCommand = commandParseTree({...c, children: removeOneOccurenceOfText(c.children || [], c.text || '')}, config, true);
    if (!parsedCommand || !check.nonEmptyArray(parsedCommand.children)) {
      return null;
    }

    // Extract the command list component, make it absolute
    let commandList = parsedCommand.children.filter(c => !!c).find(c => c.htmlComponent === 'CommandList');
    if (!commandList) {
      return null;
    }
    const listClasses = cn(commandList.classes || [], 'absolute top-0 -left-px p-1 w-full opacity-100 bg-white rounded-b-lg border-l border-r border-b').split(' ').filter(check.nonEmptyString);
    commandList = {
      ...commandList,
      classes: listClasses,
      props: {
        ...(commandList.props || {}),
        className: cn(listClasses),
      },
    };

    // Detect if we need to use a dynamic component or a static one
    let component = AutoComplete as any;
    let htmlComponent = 'AutoComplete';
    let props = { ...(c.props || {}) };

    let wrap = (w: ComponentTreeNode) => w;
    const textVar = getSingleVarNameFromText(c.text || '');
    if (check.nonEmptyString(textVar)) {
      wrap = (w: ComponentTreeNode) => ({
        component: DataInput as any, 
        htmlComponent: 'DataInput',
        props: { bind: textVar, type: 'string' },
        classes: [],
        variant: null,
        text: null,
        children: [w],
      });
      props.placeholder = c.props?.placeholder || c.text?.replace(textVar, '').trim() || '';
    } else {
      props.placeholder = c.props?.placeholder || c.text?.trim() || '';
    }

    props.className = cn(c.classes, c.props?.className);

    return wrap({
      ...c,
      component,
      htmlComponent,
      props,
      children: [commandList],
    });
  },

  imports: [
    ...imports(['AutoComplete'], 'auto-complete'),
  ],
  setup: [
    ...setup('auto-complete'),
  ],
  variants: [],
};

export default AutoCompleteComponentParser;


registerShadcnComponent({ AutoComplete }, 'auto-complete');