import check from "@/vendors/check";
import { ComponentTreeNode } from "./component.type";
import { ComponentAliasConfig, componentMatchesType, mergeComponents, normalizeTextComponent } from "./shadcn/_utils";
import { ComponentType } from "react";
import { cn, nonEmptyTrimmed } from "@/lib/utils";

export type ParsingPipelineStage = (input: ComponentTreeNode[], parents: ComponentType[]) => ComponentTreeNode[];
export type ParsingPipelineCondition = (parentComponent: ComponentTreeNode) => boolean;
export type ConditionnedParsingPipelineStages = [ParsingPipelineStage | ParsingPipelineStage[], ParsingPipelineCondition];

// //////////////////////////////////////////////////////////////////////
// Pipeline function
// ////////////////////////////////////////////////////////////////////// 
export function parsingPipeline(stages: ParsingPipelineStage[]) {
    return {
        run: (input: ComponentTreeNode[], parents: ComponentType[]) : ComponentTreeNode[] => {
            if (!check.nonEmptyArray(input)) {
                return [];
            }
            return stages.reduce((acc, stage) => (stage(acc, parents) || []), input);
        }
    };
}

export function treeParsingPipeline(stages: (ParsingPipelineStage | ConditionnedParsingPipelineStages)[]) {
    return {
        run: (rootComponent: ComponentTreeNode, parents: ComponentType[]) : ComponentTreeNode => {
            if (!check.nonEmptyObject(rootComponent)) {
                return rootComponent;
            }

            // Filter stages by condition on the parent
            const pipelineStages = [];
            for (const stage of stages) {
                if (check.nonEmptyArray(stage) && stage.length === 2) {
                    const [rawStageFunctions, condition] = stage as [ParsingPipelineStage, ParsingPipelineCondition];
                    if (condition(rootComponent)) {
                        const stageFunctions = check.array(rawStageFunctions) ? rawStageFunctions : [rawStageFunctions];
                        for (const stageFunction of stageFunctions) {
                            pipelineStages.push(stageFunction);
                        }
                    }
                } else {
                    pipelineStages.push(stage as ParsingPipelineStage);
                }
            }

            // Run the pipelines on the children array first
            let newParents = [...parents, rootComponent.component as ComponentType];
            let children = parsingPipeline(pipelineStages).run(rootComponent.children || [], newParents);

            // Run the pipeline on the sub children of children not marked as leafs
            children = children.map((child) => {
                if (!child._isLeaf && check.nonEmptyArray(child.children)) {
                    return treeParsingPipeline(stages).run(child, newParents);
                }
                return child;
            });
            
            // Return the root component with the children
            return {
                ...rootComponent,
                children,
            };
        }
    };
}

export function multiSteptreeParsingPipeline(stages: (ParsingPipelineStage | ConditionnedParsingPipelineStages)[][]) {
    return {
        run: (rootComponent: ComponentTreeNode, parents: ComponentType[]) : ComponentTreeNode => {
            if (!check.nonEmptyObject(rootComponent)) {
                return rootComponent;
            }

            let pipelineNumber = 0;
            let newRoot = rootComponent;
            for (const stage of stages) {
                newRoot = treeParsingPipeline(stage).run(newRoot, parents);

                /*
                console.log('Pipeline results #'+pipelineNumber, newRoot);
                pipelineNumber++;
                */

            }
            return newRoot;
        }
    };
}

// FIXME: Not fully reliable
function isReactComponent(component: any) {
    return (
      typeof component === 'function' && 
      (component.prototype?.isReactComponent || component.displayName)
    );
}

export function addStagesIf(condition: ParsingPipelineCondition | ComponentType | string, stages: ParsingPipelineStage[]) : ConditionnedParsingPipelineStages {
    let conditionFunction : ParsingPipelineCondition;
    if (check.function(condition) && !isReactComponent(condition as any)) {
        conditionFunction = condition as ParsingPipelineCondition;
    } else {
        conditionFunction = (c: ComponentTreeNode) => componentMatchesType(c, condition as ComponentType);
    }
    return [stages, conditionFunction];
}


// //////////////////////////////////////////////////////////////////////
// Pipeline parsing stages
// ////////////////////////////////////////////////////////////////////// 
export function onlyAllowTypes(types: any[]) {
    return (input: ComponentTreeNode[]) : ComponentTreeNode[] => {
        if (!check.nonEmptyArray(input)) {
            return [];
        }
        return input.filter((child) => {
            if (!check.nonEmptyObject(child)) {
                return false;
            }

            return types.some((type) => {
                if (type === 'text') {
                    return !!normalizeTextComponent(child);
                }
                return child.component === type;
            });
        });
    }
}

export function solveAliases(aliases: ComponentAliasConfig[]) {
    return (input: ComponentTreeNode[]) : ComponentTreeNode[] => {
        if (!check.nonEmptyArray(input)) {
            return [];
        }
        return input.map((child) => {
            if (!check.nonEmptyObject(child) || !check.nonEmptyString(child.component)) {
                return child;
            }

            const match = aliases.find(([alias]) => (
                (check.nonEmptyString(alias) && alias === child.component)
                || (check.array(alias) && alias.includes(child.component as string))
            ));

            if (!match) {
                return child;
            }

            const [_, Component, componentName] = match;
            return {
                ...child,
                component: Component,
                htmlComponent: componentName,
            };
        });
    }
}

export function parseTextComponents(parser : (c: ComponentTreeNode, parents: ComponentType[]) => ComponentTreeNode | ComponentTreeNode[] | null) {
    return (input: ComponentTreeNode[], parents: ComponentType[]) : ComponentTreeNode[] => {
        if (!check.nonEmptyArray(input)) {
            return [];
        }
        return input.map((child) => {
            let textComp = normalizeTextComponent(child);
            if (!textComp) {
                return child;
            }
            return parser(textComp, parents);
        }).filter(c => !!c).flat() as ComponentTreeNode[];
    }
}

// Loop around an array of components
// Wrap all components of a certain type with a wrapper component if they are adjacent
// If a wrapper function is provided, it will be called with the array of components to wrap
// Else, the components will be wrapped in a single wrapper component without props or classes
// ----
// The function returns the input of components with the wrapped components replaced by the wrapper component at the same place
// All unwrapped components should remain in the array at their position.
export function groupAdjacentComponentsOfTypes(
    componentTypesToWrap: (string | ComponentType)[], 
    wrapperComponent: string | ComponentType,
    wrapperComponentName: string,
    wrapperFunction?: (c: ComponentTreeNode[]) => ComponentTreeNode,
    reverseMatch = false, // When true, the wrapper will wrap all adjacent components wich to not match the types
    onlyIfMultipleItems = false, // When true, the wrapper will only wrap if there are more than one adjacent components
    isMatchOverride?: (c: ComponentTreeNode) => boolean,
) {
    return (input: ComponentTreeNode[]) : ComponentTreeNode[] => {
        if (!check.nonEmptyArray(input)) {
            return [];
        }

        let output : ComponentTreeNode[] = [];
        let currentGroup : ComponentTreeNode[] = [];

        const isMatch = isMatchOverride || ((c: ComponentTreeNode) => componentMatchesType(c, componentTypesToWrap, reverseMatch));

        const pushGroup = () => {
            if (check.nonEmptyArray(currentGroup)) {
                if (onlyIfMultipleItems && currentGroup.length < 2) {
                    output.push(...currentGroup);
                } else {
                    if (check.function(wrapperFunction)) {
                        output.push(wrapperFunction(currentGroup));
                    } else {
                        output.push({
                            component: wrapperComponent,
                            htmlComponent: wrapperComponentName,
                            classes: [],
                            props: {},
                            children: currentGroup,
                        });
                    }
                }
                currentGroup = [];
            }
        }

        for (let i = 0; i < input.length; i++) {
            const current = input[i];
            if (isMatch(current)) {
                currentGroup.push(current);
            } else {
                pushGroup()
                output.push(current);
            }
        }
        pushGroup();

        return output;
    };
}

export function wrapComponentsNotOfTypes(componentTypesToWrap: (string | ComponentType)[], wrapperComponent: string | ComponentType, wrapperComponentName: string, wrapperFunction?: (c: ComponentTreeNode[]) => ComponentTreeNode) {
    return groupAdjacentComponentsOfTypes(componentTypesToWrap, wrapperComponent, wrapperComponentName, wrapperFunction, true);
}

export function mergeAllComponentsOfType(type: string | ComponentType) {
    return (input: ComponentTreeNode[]) : ComponentTreeNode[] => {
        if (!check.nonEmptyArray(input)) {
            return [];
        }

        const newChildren : ComponentTreeNode[] = [];
        let mergeInto = -1;

        for (let i = 0; i < input.length; i++) {
            const current = input[i];
            if (componentMatchesType(current, type)) {
                if (mergeInto === -1) {
                    newChildren.push(current);
                    mergeInto = newChildren.length - 1;
                } else {
                    newChildren[mergeInto] = mergeComponents([newChildren[mergeInto], current]);
                }
            } else {
                newChildren.push(current);
            }
        }

        return newChildren;
    };
}

export function transformComponentsOfTypes(
    types: (string | ComponentType)[], 
    transformer: (c: ComponentTreeNode, parents: ComponentType[], i: number) => ComponentTreeNode | null | (ComponentTreeNode | null)[], 
    reverseMatch = false
) {
    return (input: ComponentTreeNode[], parents: ComponentType[]) : ComponentTreeNode[] => {
        if (!check.nonEmptyArray(input)) {
            return [];
        }

        const isMatch = (c: ComponentTreeNode) => componentMatchesType(c, types, reverseMatch);
        return input.map((child, i) => {
            if (isMatch(child)) {
                return transformer(child, parents, i);
            }
            return child;
        }).flat().filter(c => !!c) as ComponentTreeNode[];
    }
}

export function removeChildren(types?: (string | ComponentType)[], reverseMatch = false) {
    return transformComponentsOfTypes(
        types || [], 
        (c) => {
            if (!check.nonEmptyObject(c)) {
                return c;
            }
            return {
                ...c,
                children: [],
            };
        },
        reverseMatch || !check.nonEmptyArray(types), // Applying reverse when type is an empty array selects all components
    );
}

export function applyBaseProps(props: { [key: string]: any }, types?: (string | ComponentType)[], reverseMatch = false) {
    return transformComponentsOfTypes(
        types || [],
        (c) => {
            if (!check.nonEmptyObject(c)) {
                return c;
            }
            return {
                ...c,
                props: {
                    ...(props || {}),
                    ...(c.props || {}),
                },
            };
        },
        reverseMatch || !check.nonEmptyArray(types), // Applying reverse when type is an empty array selects all components
    );
}

export function applyBaseClasses(classes: string | string[], types?: (string | ComponentType)[], reverseMatch = false) {
    return transformComponentsOfTypes(
        types || [],
        (c) => {
            if (!check.nonEmptyObject(c)) {
                return c;
            }
            return {
                ...c,
                classes: cn(classes, c.classes).split(' '),
                props: {
                    ...(c.props || {}),
                    className: cn(classes, c.props?.className),
                }
            };
        },
        reverseMatch || !check.nonEmptyArray(types), // Applying reverse when type is an empty array selects all components
    );
}

// Mark all components matching the given types as leafs of the tree
// When parsing the pipeline recursively, this will allow to know when to stop the recursion
export function markTreeLeafs(types: (string | ComponentType)[], reverseMatch = false) {
    return transformComponentsOfTypes(
        types,
        (c) => {
            if (!check.nonEmptyObject(c)) {
                return c;
            }
            return {
                ...c,
                _isLeaf: true,
            };
        },
        reverseMatch,
    );
}

export function appendComponentIfNotExisting(type: string | ComponentType, component: ComponentTreeNode) {
    return (input: ComponentTreeNode[]) : ComponentTreeNode[] => {
        if (!check.nonEmptyArray(input)) {
            return [];
        }

        if (input.some((c) => componentMatchesType(c, type))) {
            return input;
        }

        return [...input, component];
    };
}

export function deepRemoveTypes(types: (string | ComponentType) | (string | ComponentType)[]) {
    const typesToRemove = check.array(types) ? types : [types];

    return (input: ComponentTreeNode[]) : ComponentTreeNode[] => {
        if (!check.nonEmptyArray(input)) {
            return [];
        }
        return input.map((child) => {
            if (typesToRemove.some((type) => componentMatchesType(child, type))) {
                return null;
            }

            if (!check.nonEmptyObject(child)) {
                return child;
            }

            return {
                ...child,
                children: deepRemoveTypes(typesToRemove)(child.children || []),
            };
        }).filter((c) => !!c) as ComponentTreeNode[];
    };
}

// Prevent some components from having themselves as children
export function deepRemoveSameTypeChildren(types: (string | ComponentType)[]) {
    return (input: ComponentTreeNode[]) : ComponentTreeNode[] => {
        if (!check.nonEmptyArray(input)) {
            return [];
        }

        return input.map((child) => {
            if (types.some((type) => componentMatchesType(child, type))) {
                if (!check.nonEmptyObject(child)) {
                    return child;
                }

                return {
                    ...child,
                    children: deepRemoveTypes(child.component)(child.children || []),
                };
            }
            return child;
        });
    };
}

export function removeFirstOccurenceOfTextFromChildren(text: any) {
    return (input: ComponentTreeNode[]) : ComponentTreeNode[] => {
        if (!check.nonEmptyArray(input)) {
            return [];
        }

        if (!check.nonEmptyString(text) || !nonEmptyTrimmed(text)) {
            return input;
        }

        let found = false;
        return input.map((child) => {
            if (!found && normalizeTextComponent(child)?.text === text) {
                found = true;
                return null;
            }
            return child;
        }).filter((c) => !!c) as ComponentTreeNode[];
    };
}

export function ensureAllElementsHaveAChildrenArray() {
    return (input: ComponentTreeNode[]) : ComponentTreeNode[] => {
        if (!check.nonEmptyArray(input)) {
            return [];
        }
        return input.map((child) => {
            if (!check.nonEmptyObject(child)) {
                return child;
            }
            return {
                ...child,
                children: check.nonEmptyArray(child.children) ? child.children : [],
            };
        });
    };
}
