import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./table";

// Utility type check functions
function nonEmptyArray(value: any) : value is any[] {
    return Array.isArray(value) && value.length > 0;
}
function nonEmptyString(value: any) : value is string {
    return typeof value === 'string' && value.length > 0;
}
function nonEmptyObject(value: any) : value is {[key: string]: any} {
    return typeof value === 'object' && Object.keys(value).length > 0;
}

// Parse the first 10 objects of an array to extract the keys
function extractKeyNamesFromValues(values: any[]) {
    if (!Array.isArray(values) || values.length === 0) {
        return [];
    }
    const sliced = values.slice(0, 10); // Only check the first 10 values for perf reasons

    return sliced.reduce((acc: string[], val: any) => {
        if (nonEmptyObject(val)) {
            Object.keys(val).forEach(key => {
                if (!acc.includes(key)) {
                    acc.push(key);
                }
            });
        }
        return acc;
    }, []);
}
  
// Transform camelCase to Title Case
function camelCaseToTitleCase(str: string) {
    if (typeof str !== 'string' || str.trim().length === 0) {
        return '';
    }

    return str.replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase())
        .trim();
}


export function DynamicTableRow({ value, columnKeys }: { value?: any, columnKeys?: string[] }) {
    if (!nonEmptyObject(value)) {
        return null;
    }
    let cols  = nonEmptyArray(columnKeys) ? columnKeys : Object.keys(value);
    return (
        <TableRow>
            {cols.map((col, index) => (
                <TableCell key={index}>{nonEmptyString(value[col]) ? value[col] : JSON.stringify(value[col])}</TableCell>
            ))}
        </TableRow>
    );
}
  
export function DynamicTable({ value, columnKeys, children }: { value?: any, columnKeys?: string[], children?: any }) {
    if (!nonEmptyArray(value)) {
        return null;
    }
    let cols = (nonEmptyArray(columnKeys)) ? columnKeys : extractKeyNamesFromValues(value);
    if (!nonEmptyArray(cols)) {
        return null;
    }

    const colNames = cols.map(col => {
        if (!nonEmptyString(col)) {
            return '';
        }

        if (col.includes(':')) {
            return col.split(':')[1].trim();
        }

        return camelCaseToTitleCase(col);
    });

    const colKeys = cols.map(col => {
        if (!nonEmptyString(col)) {
            return '';
        }

        if (col.includes(':')) {
            return col.split(':')[0].trim();
        }

        return col;
    });

    return (
        <Table>
            <TableHeader>
                <TableRow>
                    {colNames.map((name, index) => (
                        <TableHead key={'colhead::' + cols[index] + '::' + index}>{name}</TableHead>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBody>
                {value.map((line, index) => (
                    <DynamicTableRow key={index} value={line} columnKeys={colKeys} />
                ))}
            </TableBody>
            {children}
        </Table>
    );
}