
import { registerComponentVariant } from '../../../lib/components-registry';
import { CARD_COMPONENT_DESCRIPTION, CARD_COMPONENT_NAME, LIST_ITEM_VARIANT_DESCRIPTION, LIST_ITEM_VARIANT_NAME } from '../../../lib/constants';
import ListItemImageCard from './Image/Card';

registerComponentVariant({
    reactComponent: ListItemImageCard as React.ComponentType,
    name: CARD_COMPONENT_NAME,
    description: CARD_COMPONENT_DESCRIPTION,
    variantName: LIST_ITEM_VARIANT_NAME,
    variantDescription: LIST_ITEM_VARIANT_DESCRIPTION,
    subVariantName: "ListItemImage",
    subVariantDescription: "a la main for moment",
    acceptsProps: ['image', 'title', 'description'],
    acceptsChildren: false,
    acceptSizes: ['6xl', '5xl', '4xl', '3xl', '2xl', 'xl', 'lg', 'md', 'sm', 'xs'],
    acceptOrientations: ['west', 'east'],
    acceptsRoundness: ['none','sm', 'md', 'lg', 'xl'],
    acceptsAspectRatios: ['9/16']
});