// Code from https://github.com/ibelick/background-snippets/blob/main/app/components/background.tsx
// License TBD

import { ComponentTreeNode } from "../component.type";
import check from "@/vendors/check";
import { generateMeshGradientTwClasses } from "@/vendors/mesh-gradients/generate-mesh-gradients";
import { isTextComponent, normalizeTextComponent } from "../shadcn/_utils";
import { cn, isColor, parseColorToHex } from "@/lib/utils";

function anyCharStringToInt(str: string) {
    return str.split('').map((c) => c.charCodeAt(0)).reduce((acc, c) => acc + c, 0);
}

function gradientStyleForComponent(c: ComponentTreeNode) {
  let text = c.text || (c.children || []).filter(isTextComponent).map(normalizeTextComponent).filter(t => !!t).map((t) => t?.text).join(' ');
  let color;
  let length;
  let opacity;
  let seedText = '';
  if (check.nonEmptyString(text)) {
    const words = text.split(' ').map(t => t.trim()).filter(check.nonEmptyString);

    words.forEach((word) => {
      // Detect opacity
      if (/^opacity-[0-9]{1,3}$/g.test(word)) {
        opacity = word.replace('opacity-', '');
        return;
      }

      // Detect colors
      if (isColor(word)) {
        color = parseColorToHex(word);
        return;
      }

      // Detect length
      if (/^[0-9]{1,2}$/g.test(word)) {
        length = parseInt(word, 10);
        return;
      }

      // Other words are added to seed 
      seedText += word + ' ';
    });
  }

  const opacityClass = c.classes?.find((c) => c.startsWith('opacity-'));
  if (!opacity && opacityClass) {
    opacity = opacityClass.replace('opacity-', '');
  }

  const { className } = generateMeshGradientTwClasses({
    length,
    baseColor: color,
    seed: anyCharStringToInt(seedText.toLowerCase().trim()),
    opacity: parseInt(opacity || '100'),
  });

  return className;
}

  const BgMeshComponentParser = {
    tags: ['bg-mesh'],
    name: 'Background Mesh Gradient',
    description: 'A background gradient to use for the page.',
    refImplementation: `
    /div size-40 rounded-lg
        /bg-mesh Any seed text here for reproductability!
    `.trim(),
    wrapParent: (parent: ComponentTreeNode) => {
        const patterns = (parent.children || []).filter(comp => comp.component === 'bg-mesh');
        if (patterns.length === 0) {
          return parent;
        }
        const otherChildren = (parent.children || []).filter((comp: ComponentTreeNode) => comp.component !== 'bg-mesh')

        let isDark = false;

        if (patterns.length === 1) {
          return {
            ...parent,
            classes: cn(parent.classes, 'relative overflow-hidden', gradientStyleForComponent(patterns[0])).split(' '),
            props: {
              ...(parent.props || {}),
              className: cn(parent.classes, 'relative overflow-hidden', gradientStyleForComponent(patterns[0])),
            },
            children: otherChildren,
          }
        }

        
        return {
          ...parent,
          classes: cn(parent.classes, 'relative overflow-hidden').split(' '),
          props: {
            className: cn(parent.classes, 'relative overflow-hidden'),
          },
          children: [
            {
              component: 'div',
              htmlComponent: 'div',
              classes: ['absolute size-full z-0'],
              props: {
                className: 'absolute size-full z-0',
              },
              children: [
                ...patterns.map((c: ComponentTreeNode) => {
                    const bgClassName = gradientStyleForComponent(c).replaceAll('bg-opacity-', 'opacity-');
  
                    return {
                      component: 'div',
                      htmlComponent: 'div',
                      classes:  cn('size-full', bgClassName).split(' '),
                      props: {
                        className: cn('size-full', bgClassName),
                      },
                      children: [],
                    };
                }),
              ],
            },
            {
              component: 'div',
              htmlComponent: 'div',
              classes: ['relative size-full z-1' + (isDark ? ' dark' : '')],
              props: {
                className: 'relative size-full z-1' + (isDark ? ' dark' : ''),
              },
              children: otherChildren,
            }
          ],
        };
    },
    variants: [],
};

export default BgMeshComponentParser;