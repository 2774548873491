import { registerComponentVariant } from "../../lib/components-registry";

import React from "react";
import { TopBar } from "./default/TopBar";
import { SideBar } from "./default/SideBar";

registerComponentVariant({
    reactComponent: TopBar as React.ComponentType,
    name: "TopBar",
    variantName: "default",
    description: "A Top bar for mobile and Web apps. With buttons.",
    variantDescription: "Default variant",
    acceptsProps: ["buttonLabel"],
    acceptOrientations: ["north"],
    acceptSizes: ["md"],
    acceptsChildren: true,
});


registerComponentVariant({
    reactComponent: SideBar as React.ComponentType,
    name: "SideBar",
    variantName: "default",
    description: "The needed SideBar for the TopBar",
    variantDescription: "Default variant",
    acceptsProps: ["buttonLabel"],
    acceptOrientations: ["north"],
    acceptSizes: ["md"],
    acceptsChildren: true,
});