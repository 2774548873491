 // Handle openAI queries

import check from "@/vendors/check";
import { promptOpenAI } from "@/vendors/openAi";
import 'isomorphic-fetch';

 

const fillAIQuery = async (editor, monaco, query) => {
    let q = query.trim();
    if (q.startsWith('(')) {
        q = q.substring(1);
    }
    if (q.endsWith(')')) {
        q = q.substring(0, q.length - 1);
    }
    if (q.endsWith('...')) {
        q = q.substring(0, q.length - 3);
    }

    try {
        const { classes } = JSON.parse(await promptOpenAI(
    `You are a web developer using TailwindCSS.
    What tailwind class or classes would you use to do the following: "${q}".
    Remember that if you need to use arbitrary values, you can use classes in the following format with TailwindCSS: .bg-[#ff0000]. 
    However, please do not use arbitrary values if not explicitely required by the task instruction.`,
            JSON.stringify({
            classes: 'string'
            }),
        ));

        const currentText = editor.getValue();
        let newText = currentText.replace(query, classes);

        if (newText !== currentText) {
            editor.setValue(newText);
        }
    } catch(err) {
        console.error(err);
    } finally {
        delete openAIPrompts[query];
    }
};

const fillAICreateQuery = async (editor, monaco, query) => {
    if (query.includes('🤖...')) {
        return;
    }

    let q = query.trim();
    if (q.startsWith('<<<')) {
        q = q.substring(3).trim();
    }
    if (q.endsWith('>>>')) {
        q = q.substring(0, q.length - 3);
    }
    q = q.trim();

    try {
        editor.setValue(editor.getValue().replace(query, '<<< 🤖... >>>'));

        // Fetch https://ai-designer.layouts.dev/api/create?q=... with the query
        const response = await fetch(`https://ai-designer.layouts.dev/api/create?q=${encodeURIComponent(q)}`);
        // const response = await fetch(`https://ai-designer.layouts.dev/api/create-app?q=${encodeURIComponent(q)}`);
        const { code } = await response.json();

        let txt = '';
        if (!check.nonEmptyString(code)) {
            txt = '❌ Error: Could not generate code for the given query.';
        } else {
            txt = code;
        }

        const currentText = editor.getValue();
        if (check.nonEmptyString(code) && !currentText.includes('/theme')) {
            txt = '/theme %mobile\n\n' + txt;
        }
        let newText = currentText.replace('<<< 🤖... >>>', txt);

        if (newText !== currentText) {
            editor.setValue(newText);
        }
    } catch(err) {
        console.error(err);
    } finally {
        delete openAIPrompts[query];
    }
};

const openAIPrompts = {};
export function handleOpenAIQueries(editor, monaco, txt) {
    const queries = txt.match(/\((.*?)\.\.\.\)/g);
    if (queries) {
        queries.forEach(q => {
        if (openAIPrompts[q]) {
            return;
        }
        openAIPrompts[q] = true;
            fillAIQuery(editor, monaco, q).catch(console.error);
        });
    }

    const createQueries = txt.match(/<<<(.*?)>>>/g);
    if (createQueries) {
       
        createQueries.forEach(q => {
        if (openAIPrompts['create::' + q]) {
            return;
        }
        console.log('[AI Engine] Creating query: ' + q)
        
        openAIPrompts['create::' + q] = true;
            fillAICreateQuery(editor, monaco, q).catch(console.error);
        });
    }
};