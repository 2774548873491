import { Button } from '@/components/shadcn/ui/button';import { cn } from '@/lib/utils';
import { VariantProps, cva } from 'class-variance-authority';
import React from 'react';

const OutlineCardSizeVariants = cva(
	"justify-start items-start flex rounded-2xl shadow-[0px_0px_4px_0px_rgba(0,0,0,0.10)] bg-card",
	{
		variants: {
			size: {
                "6xl": "w-full",
                "5xl": "w-[1712px]",
                "4xl": "w-[1168px]",
                "3xl": "w-[800px]",
                "2xl": "w-[544px]",
                "xl": "w-[368px]",
                "lg": "w-[256px]",
                "md": "w-[176px]",
                "sm": "w-[112px]",
                "xs": "w-[80px]",
			},
            roundness: {
                'none': "rounded-none",
                "sm": "rounded-sm",
                "md": "rounded-md",
                "lg": "rounded-lg",
                "xl": "rounded-xl",
                "2xl": "rounded-2xl",
                "3xl": "rounded-2xl",
                "full": "rounded-full",
            },
            orientation: {
                "north" : "flex-col",
                "west" : "flex-row",
            }
		},
		defaultVariants: {
			size: "xl",
            roundness: "2xl",
            orientation: "north",
		},
	}
)

const OutlineCardPaddingVariants = cva(
	"",
	{
		variants: {
			size: {
                "6xl": "p-[48px] gap-6",
                "5xl": "p-[40px] gap-5",
                "4xl": "p-[32px] gap-4",
                "3xl": "p-[24px] gap-3",
                "2xl": "p-[20px] gap-2",
                "xl": "p-[16px] gap-1",
                "lg": "p-[12px] gap-1",
                "md": "p-[8px] gap-1",
                "sm": "p-[6px]",
                "xs": "p-[4px]",
			},
            orientation: {
                "north" : "w-full",
                "west" : "w-1/2",
            }
		},
		defaultVariants: {
			size: "xl",
            orientation: "north"
		},
	}
)

const OutlineCardGapVariants = cva(
	"justify-start items-start flex-col flex w-full",
	{
		variants: {
			size: {
                "6xl": "gap-6",
                "5xl": "gap-5",
                "4xl": "gap-4",
                "3xl": "gap-3",
                "2xl": "gap-2",
                "xl": "gap-1",
                "lg": "gap-1",
                "md": "gap-1",
                "sm": "",
                "xs": "",
			},
		},
		defaultVariants: {
			size: "xl",
		},
	}
)


const OutlineCardTitleVariants = cva(
	"font-semibold",
	{
		variants: {
			size: {
                "6xl": "w-9/12 typeface-display-5xl text-5xl",
                "5xl": "w-9/12 typeface-display-4xl text-4xl",
                "4xl": "w-9/12 typeface-display-3xl text-3xl",
                "3xl": "w-9/12 typeface-display-2xl text-2xl",
                "2xl": "w-9/12 typeface-display-xl text-xl",
                "xl": "w-9/12 typeface-display-lg text-lg",
                "lg": "w-full typeface-display-md text-md",
                "md": "w-full typeface-display-sm text-sm",
                "sm": "w-full typeface-display-xs text-xs",
                "xs": "w-full typeface-display-xs text-[10px]",
			},
		},
		defaultVariants: {
			size: "xl",
		},
	}
)

const OutlineCardDescriptionVariants = cva(
	"font-extralight",
	{
		variants: {
			size: {
                "6xl": "w-9/12 typeface-body-4xl text-4xl",
                "5xl": "w-9/12 typeface-body-3xl text-3xl",
                "4xl": "w-9/12 typeface-body-2xl text-2xl",
                "3xl": "w-9/12 typeface-body-xl text-xl",
                "2xl": "w-9/12 typeface-body-lg text-lg",
                "xl": "w-9/12 typeface-body-md text-md",
                "lg": "w-full typeface-body-sm text-sm",
                "md": "w-full typeface-body-xs text-xs",
                "sm": "hidden",
                "xs": "hidden",
			},
		},
		defaultVariants: {
			size: "xl",
		},
	}
)

const OutlineCardBottomVariants = cva(
	"w-full relative",
	{
		variants: {
			size: {
                "6xl": "items-center justify-start flex",
                "5xl": "items-center justify-start flex",
                "4xl": "items-center justify-start flex",
                "3xl": "items-center justify-start flex",
                "2xl": "items-center justify-start flex",
                "xl": "items-center justify-start flex",
                "lg": "",
                "md": "",
                "sm": "",
                "xs": "",
			},
		},
		defaultVariants: {
			size: "xl",
		},
	}
)

const OutlineCardButtonDivVariants = cva(
	"",
	{
		variants: {
			size: {
                "6xl": "flex justify-end items-end w-full absolute bottom-0 right-0",
                "5xl": "flex justify-end items-end w-full absolute bottom-0 right-0",
                "4xl": "flex justify-end items-end w-full absolute bottom-0 right-0",
                "3xl": "flex justify-end items-end w-full absolute bottom-0 right-0",
                "2xl": "flex justify-end items-end w-full absolute bottom-0 right-0",
                "xl": "flex justify-end items-end w-full absolute bottom-0 right-0",
                "lg": "pt-4 w-full",
                "md": "pt-2 w-full",
                "sm": "hidden",
                "xs": "hidden",
			},
		},
		defaultVariants: {
			size: "xl",
		},
	}
)

const OutlineCardButtonVariants = cva(
	"rounded-full",
	{
		variants: {
			size: {
                "6xl": "w-3/12 typeface-body-4xl text-4xl h-14",
                "5xl": "w-3/12 typeface-body-3xl text-3xl h-12",
                "4xl": "w-3/12 typeface-body-2xl text-2xl h-10",
                "3xl": "w-3/12 typeface-body-xl text-xl h-9",
                "2xl": "w-3/12 typeface-body-lg text-lg h-8",
                "xl": "w-3/12 typeface-body-md text-md h-7",
                "lg": "w-full typeface-body-sm text-sm h-6",
                "md": "w-full typeface-body-xs text-xs h-5",
                "sm": "hidden",
                "xs": "hidden",
			},
		},
		defaultVariants: {
			size: "xl",
		},
	}
)


interface OutlineCardProps extends VariantProps<typeof OutlineCardSizeVariants> {
	image: string;
	title: string;
	description: string;
	label: string;
}

export default function OutlineCard({ size, roundness, orientation, image, title, description, label }: OutlineCardProps) {
	let button_size : any = "default";
	if (size == "3xl" || size == "4xl" || size == "5xl" || size == "6xl") {
		button_size = "lg";
	} else if (size === "xl" || size === "2xl") {
		button_size = "default";
	} else if (size === "lg" || size === "md") {
		button_size = "sm";
	}

	let card_roundness : any = "2xl";
	if (roundness) {
		card_roundness = roundness;
	}

    console.log(orientation)

    return (
        <div className={cn(OutlineCardSizeVariants({ size, orientation}))} >
            <img
                alt=""
                className={"aspect-[1.781094527] object-cover rounded-" + card_roundness}
                sizes="(max-width: 200px) 200px, (max-width: 400px) 400px, (max-width: 800px) 800px, 4480px"
                src={image}
            />
            <div className={cn(OutlineCardPaddingVariants({ size }))} >
                <div className={cn(OutlineCardBottomVariants({ size }))} >
                    <div className={cn(OutlineCardGapVariants({ size }))}>
                        <div className={cn(OutlineCardTitleVariants({ size }))} >
                            {title}
                        </div>
                        <div className={cn(OutlineCardDescriptionVariants({ size }))} >
                            {description}
                        </div>
                        
                    </div>
                    <div className={cn(OutlineCardButtonDivVariants({ size }))}>
                        <Button className={cn(OutlineCardButtonVariants({ size }))} variant="default">
                            {label}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}