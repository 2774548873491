import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/shadcn/ui/tooltip"
import { ComponentParser, ComponentTreeNode } from "../../component.type"
import { imports, radixComponentDoc, setup } from "../_utils";
import { selectVariant } from "@/lib/parser/util";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
export function TooltipDemo() {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button variant="outline">Hover</Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>Add to library</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
*/

const VARIANTS = ['top', 'right', 'bottom', 'left'];

const TooltipComponentParser : ComponentParser = {
  name: 'Tooltip',
  description: 'A popup that displays information related to an element when the element receives keyboard focus or the mouse hovers over it.',
  tags: ['tooltip'],

  refImplementation: `
  /button Hover to reveal tooltip
    /tooltip %right
      Hey there!
  `.trim(),


  // This function is called on nodes including children with the tag 'tooltip'
  // It wraps the parent and places all tooltip children to the right place
  wrapParent: (parent: ComponentTreeNode) : ComponentTreeNode => {
    let rootDelayDuration;

    const tooltipContentChildren = (parent.children || []).filter(child => child.component === 'tooltip').map((c => {
      const { delayDuration, openDelay, variant, ...other } = c.props || {};

      if (delayDuration) {
        rootDelayDuration = delayDuration;
      }
      if (openDelay) {
        rootDelayDuration = openDelay;
      }

      return ({
      ...c,
      component: TooltipContent,
      htmlComponent: 'TooltipContent',
      props: {
        ...other,
        side: selectVariant(VARIANTS, c.variant),
      },
      _dontParse: true,
      variant: null,
    })}));

    return {
      _dontParse: true,
      component: TooltipProvider as any,
      htmlComponent: 'TooltipProvider',
      classes: [],
      props: rootDelayDuration ? { delayDuration: rootDelayDuration } : {},
      children: [
        {
          _dontParse: true,
          component: 'div',
          classes: [ 'no-transition' ],
          props: {},
          children: [ 
            {
              _dontParse: true,
              component: Tooltip,
              htmlComponent: 'Tooltip',
              classes: [],
              props: {},
              children: [
                {
                  _dontParse: true,
                  component: TooltipTrigger,
                  htmlComponent: 'TooltipTrigger',
                  props: {
                    asChild: true,
                  },
                  children: [
                    {
                      ...parent,
                      _dontParse: true,
                      children: (parent.children || []).filter(child => child.component !== 'tooltip'),
                    }
                  ]
                },
                ...tooltipContentChildren,
              ]
            },
          ],
        },
      ],
    };
  },

  imports: imports(['Tooltip', 'TooltipContent', 'TooltipTrigger', 'TooltipProvider'], 'tooltip'),
  setup: setup('tooltip'),
  variants: VARIANTS,
  doc: {
    props: {
      ...radixComponentDoc('Tooltip.Root').props,
      ...radixComponentDoc('Tooltip.Content').props,
    },
  }
};

registerShadcnComponent({
        Tooltip,
        TooltipContent,
        TooltipProvider,
        TooltipTrigger,
    },
    'tooltip',
);

export default TooltipComponentParser;
