import pretty from 'pretty';
// import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
// import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx';
import { appJsonToReactCode, layoutsLangToAppJson } from "@/lib/parser/parser-v2";
import { useDebouncedParsedValue } from "@/hooks/useDebouncedParsedValue";
import { useDebounce } from 'use-debounce';
import { useLocalOrRemoteValue } from "@/hooks/useLocalOrRemoteValue";
import { publicPageMarkdown } from "@/lib/dbRoutes";
import { memo, useCallback, useState } from "react";
import { defaultText } from '@/lib/defaultPage';
import { EMPTY_EDITOR_CONTENT } from '@/lib/constants';
// SyntaxHighlighter.registerLanguage('jsx', jsx);

import { Highlight } from '@seafront/react-fast-highlight';
// import 'highlight.js/styles/atom-one-dark.css';
import './code-style.css';
import { CheckCircle, Clipboard, ClipboardCheck, CopyIcon } from 'lucide-react';
import { Label } from '../shadcn/ui/label';
import { Button } from '../shadcn/ui/button';
import { nonEmptyTrimmed } from '@/lib/utils';

// import "@/lib/parser/doc/generate-components-docs";

function rawHTMLToPageHTML(rawHtml: string) {

    let license = `
///////////////////////////////////////////////////////////////////////////////////////////////////
// Generated by Layouts.dev
// ------------------------------------------------------------------------------------------------
// Copyright Creative Robots Inc. ${new Date().getFullYear()} - All rights reserved
// Commercial use not allowed without a Pro license
// https://layouts.dev
///////////////////////////////////////////////////////////////////////////////////////////////////`.trim();

    let globalCSSUrl = (Array.from(document.querySelectorAll('[rel=stylesheet]')).find((e: any) => e?.href?.startsWith(window.location.origin)) as any)?.href;

    let prettyHtml = pretty(`
    <html>
        <head>
        <title>Layouts.dev</title>

        <!-- DaisyUI -->
        <link href="https://cdn.jsdelivr.net/npm/daisyui@4.6.1/dist/full.min.css" rel="stylesheet" type="text/css" />
        <!-- Tailwind CDN -->
        <script src="https://cdn.tailwindcss.com"></script>

        ${rawHtml.includes('data-action') ? `
        <!-- Buttons.dev -->
        <script src="https://cdn.buttons.dev?pid=uidemo"></script>
        ` : ''}

        ${globalCSSUrl ? `
        <!-- Shadcn -->
        <link rel="stylesheet" href="${globalCSSUrl}" />
        ` : ''}

        </head>
        <body>
        ${
            rawHtml
                .replaceAll(/</g, '\n<')
                .replaceAll(/>/g, '>\n')
                .replaceAll(/<\//g, '\n</')
                .replaceAll('  ', '\t')
        }
        </body>
    </html>
    `.trim());

    while(prettyHtml.includes('\n\n')) {
        prettyHtml = prettyHtml.replace('\n\n', '\n');
    }
    while(prettyHtml.includes('  ')) {
        prettyHtml = prettyHtml.replace('  ', '\t');
    }

    prettyHtml = prettyHtml.replaceAll(/id="line_[0-9]+"/g, '');
    prettyHtml = prettyHtml.replaceAll(/class=""/g, '');
    while(prettyHtml.includes('  ')) {
        prettyHtml = prettyHtml.replace('  ', ' ');
    }

    return license + '\n\n' + prettyHtml;
}


async function markdown2ReactCode(markdown: string) {
    const appJson  = layoutsLangToAppJson(markdown, 'create-react-app');
    if (!appJson) {
        return null;
    }

    console.log('appJson', appJson);
    const reactCode = appJsonToReactCode(appJson);

    return reactCode;   
}

const DisplayCode = memo(({ code } : { code: string }) => {
    return  (
        <Highlight languages={['js']} className='p-0 m-0 text-xs flex-1 p-2' >
            {code || ''}
        </Highlight>
    )
    
    /* (
        <SyntaxHighlighter language="jsx" style={oneDark} className='p-0 m-0 text-xs flex-1'>
            {code || ''}
        </SyntaxHighlighter>
    );
    */
});

function CopyButton({ code } : { code: string }) {
    const [copied, setCopied] = useState(false);

    const onCopy = useCallback(() => {
        navigator.clipboard.writeText(code);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    }, [code]); 

    return (
        <div 
            onClick={onCopy}
            className="cursor-pointer bg-white hover:bg-[#F4F3F1] flex flex-row items-center rounded-[6px] border border-[#E4E4E4] text-[12px] text-[#5B5E66] gap-[6px] leading-[16px] px-[12px] py-[6px]"
        >
            {copied && (
                <>
                    <span>Copied</span>
                    <CheckCircle className='size-[12px]' />
                </>
            )}
            {!copied && (
                <>
                    <span>Copy code</span>
                    <CopyIcon className='size-[12px]' />
                </>
            )}
        </div>
    )

}

export function TerminalCommandButton() {
    const [ copied, setCopied ] = useState(false);

    const projectId = window.location.pathname.split('/').filter(nonEmptyTrimmed).shift();
    const command = `npx layouts-dev init ${projectId}`;

    const onCopy = useCallback(() => {
        navigator.clipboard.writeText(command);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000)
    }, [command]);

	return (
		<Button 
            onClick={onCopy}
            className="reset flex flex-row justify-between bg-[#f4f3f1] py-1.5 px-3 rounded-lg shadow-inner w-full text-gray-400 items-center delay-75 transition duration-200 hover:bg-blue-100 hover:text-blue-500 flex-nowrap overflow-hidden" 
        >
            {!copied && (
                <>
                    <span className="font-['roboto_mono'] text-xs text-nowrap" >
                        {`> ${command}`}
                    </span>
                    <Clipboard
                        className="size-3" 
                    />
                </>
            )}
            {copied && (
                <>
                    <span className="font-['roboto_mono'] text-xs text-nowrap" >
                        Copied!
                    </span>
                    <ClipboardCheck
                        className="size-3" 
                    />
                </>
            )}
        </Button>
	);
}

export function CodeExplorer({ pageId }: { pageId: string }) {
    const { value: src } = useLocalOrRemoteValue(`//${pageId}/editor-code`, publicPageMarkdown(pageId), null, true);
    const [debouncedSrc] = useDebounce(src, 1000);
    const parsedSrc = debouncedSrc === EMPTY_EDITOR_CONTENT ? '' : debouncedSrc;
    const { value: reactCode } = useDebouncedParsedValue((parsedSrc || parsedSrc === '') ? parsedSrc : defaultText , markdown2ReactCode, 1000);
    /*
    if (!check.nonEmptyString(html)) {
        return null;
    }
    */

    /*
    const outputHtml = rawHTMLToPageHTML(html);
    return (
        <div className="relative w-full h-screen overflow-y-scroll flex flex-col items-center" >
            <div className="flex w-full h-full min-h-screen bg-[#282c34] p-0 m-0">
                <SyntaxHighlighter language="jsx" style={oneDark} className='p-0 m-0 text-xs flex-1'>
                    {outputHtml}
                </SyntaxHighlighter>
            </div> 

            <div className="absolute bottom-8 z-50">
                <FigmaClipboardCopyButton htmlContent={outputHtml.substr(outputHtml.indexOf('<html>'))} />
            </div>
        </div>
        
    );
    */
    
    // const { html: reactCode } = parseText(src);

    /*
    let reactCode = '';
    if (check.nonEmptyString(src)) {
        const appJson = layoutsLangToAppJson(src);
        reactCode = appJsonToReactCode(appJson);
    }
    */
    
    return (
        <div className="flex flex-col w-full h-full h-screen bg-white p-0 m-0 overflow-y-hidden gap-0">
            <div className='flex flex-col px-0 pt-12px gap-7' >
                <div className="flex flex-row items-center pt-4 px-4 w-full" >
                    <div className="flex flex-row gap-1.5 items-center text-[#1e1f22]" >
                        <span className="text-sm font-medium" >
                            Export your project
                        </span>
                    </div>
                </div>
                <div className="flex flex-col px-4 gap-3" >
                    <Label className="text-gray-400 text-xs font-normal w-[200px]" >
                        Use this to sync your project with a local folder on your computer:
                    </Label>
                    <div className="flex flex-row gap-1 border rounded-xl p-0.5 bg-white/10 hover:backdrop-blur hover:bg-white/10 delay-75 transition duration-200" >
                        <TerminalCommandButton />
                    </div>
                </div>
                <div className="flex flex-col gap-4" >
                    <div className='w-full px-4'>
                        <Label className="text-gray-400 text-xs font-normal w-full" >
                            Or, simply copy/paste code into your project:
                        </Label>
                    </div>
                    
                    <div className='w-full flex flex-row items-end'>
                        <div className="w-[12px] border-[#E4E4E4] border-b" ></div>
                        <div className="bg-[#fbfbfb] flex flex-col items-center py-1 px-3 border-[#e4e4e4] border-t border-l border-r rounded-t-lg text-gray-400 cursor-pointer hover:text-[#5b5e66]" >
                            <span className="text-xs" >
                                App.tsx
                            </span>
                        </div>
                        <div className='flex-1 border-[#E4E4E4] border-b' ></div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full bg-[#FBFBFB] p-[12px] gap-[10px] overflow-y-scroll h-[calc(100%_-_121px)]">
                <div className="bg-[#FBFBFB] flex flew-row w-full items-center justify-end" >
                    <CopyButton code={reactCode} />
                </div>
                <div className='bg-[#FBFBFB] p-0 m-0 flex-1'>
                    <DisplayCode key="code-pre" code={reactCode} />
                </div>
            </div>
            
        </div> 
    );
    
}