import {
    Avatar,
    AvatarFallback,
    AvatarImage,
  } from "@/components/shadcn/ui/avatar"
import sha256 from 'sha256';
import { ComponentParser, ComponentTreeNode } from "../../component.type"
import { childrenParsers, ComponentAliasConfig, imports, isTextComponent, normalizeTextComponent, radixComponentDoc, setup } from "../_utils";
import check from "@/vendors/check";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { removeEmptyProps } from "@/lib/parser/util";

/*
  export function SimpleAvatar() {
    return (
      <Avatar>
        <AvatarImage src="https://github.com/shadcn.png" alt="@shadcn" />
        <AvatarFallback>CN</AvatarFallback>
      </Avatar>
    )
  }
*/

function getInitialLetters(name: string) {
    let parsedName = name.split('@')[0].trim().replaceAll(/[^a-zA-Z0-9]/g, ' ');
    
    if (!parsedName.includes(' ')) {
        return parsedName.substring(0, 2).toUpperCase();
    }

    return parsedName.split(' ').filter(check.nonEmptyString).map(w => w.substring(0, 1).toUpperCase()).join('');
}

const TAG = 'avatar';
const ALIASES : ComponentAliasConfig[] = [
    ['fallback', AvatarFallback, 'AvatarFallback', radixComponentDoc('Avatar.Fallback')],
    ['image', AvatarImage, 'AvatarImage', radixComponentDoc('Avatar.Image')],
];

function extractImageUrl(text: string) {
    if (!check.nonEmptyString(text)) {
        return { url: null, remainingText: '' };
    }

    const parts = text.split(' ');
    const url = parts.filter(p => p.startsWith('http://') || p.startsWith('https://') || p.startsWith('data:')).shift() || null;
    const remainingText = parts.filter(p => p !== url).join(' ').trim();

    return { url, remainingText };
}

function getSrcAndFallbackFromComponent(c: ComponentTreeNode) {
    const { text: rawText, props = {} } = c;
    let text = (rawText || '').trim();

    // Add children text to the text
    const childrenTexts = (c.children || []).filter(isTextComponent).map(c => normalizeTextComponent(c)?.text?.trim()).filter(Boolean);
    if (childrenTexts.length > 0) {
        text = [text, ...childrenTexts].join(' ').trim();
    }

    let src = props?.src || null;
    let fallback = props.fallback || null;
    if (src && !fallback) {
        fallback = fallback || text.trim();
    } else if (!src) {
        const { url, remainingText } = extractImageUrl(text);
        if (url) {
            src = url;
            if (!fallback && check.nonEmptyString(remainingText)) {
                fallback = remainingText;
            }
        } else if (text.includes('@')) {
            src = 'https://gravatar.com/avatar/'+sha256(text.trim().toLowerCase());
            fallback = fallback || text.trim();
        } else {
            fallback = fallback || text.trim();
        }
    }

    if (check.nonEmptyString(fallback)) {
        fallback = getInitialLetters(fallback);
    }

    return {
        src,
        fallback,
    }
}

const AvatarComponentParser : ComponentParser = {
    name: 'Avatar',
    description: 'An image element with a fallback for representing the user',
    tags: [TAG],
    childrenParsers: childrenParsers(ALIASES, TAG),
    refImplementation: `
/avatar https://github.com/creative-robots.png
    @fallback="CR"
    `.trim(),
    parseTree: (c: ComponentTreeNode) => {

        // Extract source and callback from children
        let src = null;
        let fallback = null;
        
        let imageComponent = (c.children || []).find(c => c.component === AvatarImage);
        let fallbackComponent = (c.children || []).find(c => c.component === AvatarFallback);

        console.log('Avatar children: ', {
            imageComponent,
            fallbackComponent,
        }
        )

        if (imageComponent) {
            src = getSrcAndFallbackFromComponent(imageComponent).src;
            fallback = getSrcAndFallbackFromComponent(imageComponent).fallback;
            console.log('Image component found: ', {
                src,
                fallback,
            });
        }
        if (fallbackComponent) {
            fallback = getSrcAndFallbackFromComponent(fallbackComponent).fallback;
        }
        
        const { src: mainSrc, fallback: mainFallback } = getSrcAndFallbackFromComponent(c);
        if (!src && mainSrc) {
            src = mainSrc;
        }
        if (!fallback && mainFallback) {
            fallback = mainFallback;
        }

        // Props fallback overwrites everything else
        if (check.nonEmptyString(c.props?.fallback)) {
            fallback = c.props.fallback
        }

        // Normalize fallback
        if (check.nonEmptyString(fallback)) {
            fallback = getInitialLetters(fallback);
        }

        // Create or modify the image component
        if (!imageComponent && check.nonEmptyString(src)) {
            imageComponent = {
                component: AvatarImage,
                htmlComponent: 'AvatarImage',
                props: removeEmptyProps({ 
                    src,
                    alt: fallback 
                }),
                children: null,
            };
        } else if (check.nonEmptyString(src)) {
            imageComponent.props = removeEmptyProps({
                ...imageComponent.props,
                src,
                alt: fallback 
            });
            imageComponent.children = null;
        }

        // Create or modify the fallback component
        if (!fallbackComponent && check.nonEmptyString(fallback)) {
            fallbackComponent = {
                component: AvatarFallback,
                htmlComponent: 'AvatarFallback',
                props: {},
                children: [fallback || ''],
            };
        } else if (check.nonEmptyString(fallback)) {
            let fallbackChildren = fallbackComponent.children || [];
            // Remove first text component from the fallback component as it is probably the text of the component
            // FIXME: Text shouldn't be transformed into a child component there
            if (fallbackChildren.length > 0 && isTextComponent(fallbackChildren[0])) {
                fallbackChildren = fallbackChildren.slice(1);
            }
            fallbackComponent.children = check.nonEmptyArray(fallbackChildren) ? fallbackChildren : [fallback || ''];
        }

        if (check.nonEmptyString(fallback)) {
            fallback = getInitialLetters(fallback);
        }
        
        return {
            ...c,
            component: Avatar,
            htmlComponent: 'Avatar',
            classes: c.classes,
            props: check.boolean(c.props?.asChild) ? { asChild: c.props?.asChild === true } : {},
            children: [
                imageComponent,
                fallbackComponent,
            ].filter(Boolean),
        }
    },
    imports: imports(['Avatar', 'AvatarFallback', 'AvatarImage'], 'avatar'),
    setup: setup('avatar'),
}

registerShadcnComponent({
        Avatar,
        AvatarFallback,
        AvatarImage,
    },
    'avatar',
);

export default AvatarComponentParser;