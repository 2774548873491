import { ComponentParser, ComponentTreeNode } from "../component.type";
import { BottomBar, ImageCard, ListItem, Metric, TextRadioItem, TopBar } from "./layouts-ui";
import { addRoles, mutualizedChildrenParsers } from "./utils";
import { isTextComponent, normalizeTextComponent } from "../shadcn/_utils";
import { getIconComponent } from "../base/images";
import { ComponentParserConfig, getParserConfig } from "../../parser-config";
import { ComponentType } from "react";
import { IconOf } from "@/vendors/illustrations.dev/IconOf";

const BottomBarParser : ComponentParser = {
    // Disable component for now
    dontList: true,

    name: 'Bottom bar',
    channel: 'experimental',
    tags: ['bottom-bar'],
    description: 'Bottom navigation bar',
    refImplementation: 
`/bottom-bar
    News
    Tags
    Settings
`,
    parseTree: (c: ComponentTreeNode, config: ComponentParserConfig) => {
        let children = addRoles(c.children).map(child => {
            if (isTextComponent(child)) {
                const textComponent = normalizeTextComponent(child);
                const text = textComponent?.text;

                /*
                return (
                    <Button className="reset flex flex-col items-center h-full gap-1 justify-between min-w-[62px] hover:text-slate-800 hover:bg-muted rounded-lg text-slate-500 p-1" variant="default" >
                        <div className="items-center justify-center flex-col flex w-full h-[32px]" >
                            <IconOf of={label} className="size-[24px]" variant="lucide" />
                        </div>
                        <span className="text-[12px]" >
                            {label}
                        </span>
                    </Button>
                )
                */
                return {
                    component: 'a',
                    htmlComponent: 'a',
                    classes: ['reset', 'flex', 'flex-col', 'items-center', 'h-full', 'gap-1', 'justify-between', 'min-w-[62px]', 'hover:text-slate-800', 'hover:bg-muted', 'rounded-lg', 'text-slate-500', 'p-1'],
                    props: { 
                        ...(textComponent?.props || {}), 
                        role: 'button',
                        href: textComponent?.props?.href || textComponent?.props?.to,
                    },
                    children: [
                        {
                            component: 'div',
                            htmlComponent: 'div',
                            classes: ['items-center', 'justify-center', 'flex-col', 'flex', 'w-full', 'h-[32px]', 'hover:text-slate-800', 'text-slate-500',],
                            children: [
                                getIconComponent({
                                    component: IconOf,
                                    htmlComponent: 'IconOf',
                                    classes: ['size-[24px]',
                                    'hover:text-slate-800', 'text-slate-500',
                                    ],
                                    props: {
                                        of: text, 
                                        role: 'icon',
                                    },
                                    variant: 'lucide',
                                    children: [],
                                }, getParserConfig('html'))
                            ]
                        },/*
                        {
                            component: 'span',
                            htmlComponent: 'span',
                            classes: ['text-[12px]'],
                            children: [text],
                            variant: null,
                        } */
                    ]
                };
            }

            return child;
        });
        return { 
            ...c,
            component: BottomBar as ComponentType,
            htmlComponent: 'BottomBar',
            children,
        }
    }
};

const CardParser : ComponentParser = {
    // Disable component for now
    dontList: true,
    
    name: 'Image card',
    tags: ['image-card'],
    description: 'Card with image or icon',
    refImplementation: '',
    childrenParsers: mutualizedChildrenParsers(['title', 'description', 'image']),
    parseTree: (c: ComponentTreeNode) => {
        return { 
            ...c,
            component: ImageCard as ComponentType,
            htmlComponent: 'ImageCard',
            children: addRoles(c.children),
        }
    },
    variants: ['default', 'bg-image', 'outline', 'list-item'],
};

const ListItemCardParser : ComponentParser = {
    // Disable component for now
    dontList: true,

    name: 'List item card',
    tags: ['list-item-card'],
    description: 'Card usable as a list-item',
    refImplementation: '',
    childrenParsers: mutualizedChildrenParsers(['title', 'description', 'image', 'button']),
    parseTree: (c: ComponentTreeNode) => {
        return { 
            ...c,
            component: ListItem,
            htmlComponent: 'ListItem',
            children: addRoles(c.children),
        }
    },
    variants: ['with-image', 'with-icon'],
};

const MetricCardParser : ComponentParser = {
    // Disable component for now
    dontList: true,
    
    name: 'Metric card',
    tags: ['metric-card'],
    description: 'Card displaying a metric',
    refImplementation: '',
    childrenParsers: mutualizedChildrenParsers(['title', 'description']),
    parseTree: (c: ComponentTreeNode) => {
        return { 
            ...c,
            component: Metric,
            htmlComponent: 'Metric',
            children: addRoles(c.children),
        }
    },
    variants: ['default', 'outline'],
};


const RadioBlockParser : ComponentParser = {
    // Disable component for now
    dontList: true,

    name: 'Radio block',
    tags: ['radio-block-item'],
    description: 'Clickable block serving as a radio group option',
    refImplementation: '',
    childrenParsers: mutualizedChildrenParsers(['title', 'description']),
    parseTree: (c: ComponentTreeNode) => {
        return { 
            ...c,
            component: TextRadioItem,
            htmlComponent: 'TextRadioItem',
            children: addRoles(c.children),
        }
    },
};

// TODO: Radio color, radio inline

const TopBarParser : ComponentParser = { 
    // Disable component for now
    dontList: true,

    name: 'Top bar',
    channel: 'experimental',
    tags: ['top-bar'],
    description: 'Top navigation bar',
    refImplementation: 
`/top-bar
    News
    Tags
    Settings
`,
    childrenParsers: mutualizedChildrenParsers(['title']),
    parseTree: (c: ComponentTreeNode) => {
        return { 
            ...c,
            component: TopBar,
            htmlComponent: 'TopBar',
            children: addRoles(c.children),
        }
    }
};


const layoutsPatternsComponents : ComponentParser[] = [
    BottomBarParser,
    CardParser,
    ListItemCardParser,
    MetricCardParser,
    RadioBlockParser,
    TopBarParser,
];

export default layoutsPatternsComponents;