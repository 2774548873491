
import * as React from "react"
import { Calendar } from "@/components/shadcn/ui/calendar"
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import check from "@/vendors/check";
import { getSingleVarNameFromText, removeEmptyProps } from "../../../util";
import { toDay, toMonth } from "@/lib/type-casts";
import { imports, setup } from "../_utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { DataInput } from "@/vendors/data/data-v2";
import { cn } from "@/lib/utils";

/*
export function CalendarDemo() {
  const [date, setDate] = React.useState<Date | undefined>(new Date())

  return (
    <Calendar
      mode="single"
      selected={date}
      onSelect={setDate}
      className="rounded-md border"
    />
  )
}
*/

/*
// ////////////////////////////////////////////////
// Note: disabling mode for now. 
// Only single is supported
// ////////////////////////////////////////////////
const MODES = ['default', 'multiple', 'range', 'single'];
function isValidMode(mode: any) : mode is typeof MODES[number] {
    return check.nonEmptyString(mode) && MODES.includes(mode);
}
// ////////////////////////////////////////////////
*/

export function parseCalendarProps(c: ComponentTreeNode) {
    /*
    // ////////////////////////////////////////////////
    // Note: disabling mode for now. 
    // Only single is supported
    // ////////////////////////////////////////////////
    let mode : typeof MODES[number] | null = null;
    if (isValidMode(c.variant)) {
        mode = c.variant;
    } else if (isValidMode(c.props.mode)) {
        mode = c.props.mode;
    } else {
        mode = 'single';
    }*/

    const value = toDay(c.props.value || c.props.selected || c.text);
    const defaultValue = toDay(c.props.defaultValue);

    return removeEmptyProps({
        key: (c.key || '') + (check.nonEmptyString(c.text) ? `_${c.text.replaceAll('$', '').replaceAll(/[^a-zA-Z0-9]/g, '-').replaceAll(/-{2,}/g, '-')}` : ''),
        // mode,
        value,
        defaultValue,
        defaultMonth: toMonth(c.props.defaultMonth) || toMonth(value) || toMonth(defaultValue) || undefined,
        className: cn(c.classes, c.props.className),
    });
}

const CalendarComponentParser : ComponentParser = {
    name: 'Calendar',
    description: 'Displays a month view calendar',
    tags: ['calendar'],
    refImplementation: `
    /calendar rounded-md border 7th of March 2024
    `.trim(),
    parseTree: (c: ComponentTreeNode) => {
        let wrap = (w: ComponentTreeNode) => w;
        const textVar = getSingleVarNameFromText(c.text);
        if (check.nonEmptyString(textVar)) {
            wrap = (w: ComponentTreeNode) => ({
                component: DataInput as React.ComponentType,
                htmlComponent: 'DataInput',
                props: { bind: textVar, type: 'day' },
                classes: [],
                variant: null,
                text: null,
                children: [w],
            });
        }
        
        return wrap({
            ...c,
            component: Calendar,
            htmlComponent: 'Calendar',
            props: parseCalendarProps(c),
            children: null,
        });
    },
    imports: imports(['Calendar'], 'calendar'),
    setup: setup('calendar'),
    variants: [
        // Only supporting 'single' for now
    ]
};

registerShadcnComponent({
        Calendar,
    },
    'calendar',
);

export default CalendarComponentParser;



