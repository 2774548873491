import React, { useMemo } from "react";
import { cn } from "./utils";

function getClonedComponent(child: any, additionalProps: { [key: string]: any[] }) {
    const { className, style, ...rest } = additionalProps || {};
    return React.cloneElement(child, {
        // Child props are prioritized over additional props
        ...rest,
        ...(child.props || {}),
        className: cn([className, child.props?.className]),
        style: { ...(style || {}), ...(child.props?.style || {}) },
        
    });
}

class ComposableApiChildren {
    children: any;

    constructor(children: any) {
        this.children = React.Children.toArray(children).filter(child => React.isValidElement(child));
    }

    getAllChildrenWithRole(role: string) {
        console.log('getAllChildrenWithRole', this.children, role)
        return this.children.filter((child: any) => child.props?.role === role);
    }
}

export function useComposableApi(children: any) {
    const composableApi = useMemo(() => new ComposableApiChildren(children), [children]);

    const functions = useMemo(() => {
        function ChildWithRole({ role, allowMultiple = false, ...otherProps } : { role: string, allowMultiple?: boolean, [key: string]: any }) {
            let matches = composableApi.getAllChildrenWithRole(role);

            if (matches.length === 0) {
                return null;
            }

            if (!allowMultiple && matches.length > 1) {
                matches = [matches[0]];
            }

            if (matches.length === 1) {
                return getClonedComponent(matches[0], otherProps);
            }

            return matches.map((child: any) => getClonedComponent(child, otherProps));
        }

        function ChildrenWithRole({ role, ...otherProps } : { role: string, [key: string]: any }) {
            return <ChildWithRole role={role} allowMultiple {...otherProps} />;
        };

        return { ChildWithRole, ChildrenWithRole };
    }, [composableApi]);

    return { composableApi, ...functions }
}