import { parseColorToHex, parseRadiusValue } from "@/lib/utils";
import check from "@/vendors/check";
import { generateSingleColorFamilyClasses } from "@/vendors/colors/palette-generator";
import { useAppliedBodyStyle } from "@/vendors/theme/hooks/useAppliedBodyStyle";
import { useThemeControls } from "@/vendors/theme/useThemeControls";
import { useThemePreset } from "@/vendors/theme/useThemePreset";
import chroma from "chroma-js";
import { useCallback, useEffect, useRef, useState } from "react";
import { THEME_PROP_TO_CSS_VAR, ThemeProps, generateBodyStyle } from "./generate-theme";
import 'isomorphic-fetch';


// Clean up body class attribute
function cleanUpColorScheme() {
    const body = document.body;
    if (!body) {
        return;
    }

    // Remove dark/light classes
    const prevClass = body.getAttribute('class') || '';
    const newClass = prevClass.split(' ').filter((c) => !['dark', 'light'].includes(c)).join(' ').trim();
    if (newClass.length === 0) {
        body.removeAttribute('class');
        return;
    }
    body.setAttribute('class', newClass);
}

// Cleans up any theme data that was set by us
function unmountStyles(styles: string[]) {
    if (!check.nonEmptyArray(styles)) {
        return;
    }

   const currentStyle = document.body.style.cssText;
   if (!check.nonEmptyString(currentStyle)) {
       return;
   }

    const toUmount = styles.map((line) =>  line.trim());
    const newStyle = currentStyle.split(';').filter((line) => {
         return !toUmount.includes(line.trim());
    }).join('; ');

    document.body.style.cssText = newStyle;
}

// Defines the theme of the Shadcn/Radix page by modifying CSS variables
export function Theme(props: ThemeProps) {
    // Apply preset 
    useThemePreset(props?.preset || props?.variant);

    // State
    const [themeProps, setThemeProps] = useState<ThemeProps>(props);
    const [themeVars, setThemeVars] = useState<{[key:string]: string}>({});
    const paletteRef = useRef<string | undefined>(undefined);
    const paletteContentRef = useRef<{props: { [key: string]: string }, vars: { [key: string]: string }} | undefined>({ props: {}, vars: {} });
    const propsRef = useRef(props);

    // Callback used to update theme props
    const updateThemeProps = useCallback(() => {
        setThemeProps({ ...(paletteContentRef.current?.props || {}), ...(propsRef.current || {}) });
    }, []);

    // Effect to update theme props if palette changes
    useEffect(() => {
        const palette = props?.palette;

        // Only play if palette is a string
        if (!check.nonEmptyString(palette) || !check.nonEmptyString(palette.trim())) {
            // Reset palette if it's not the case
            paletteRef.current = undefined;
            paletteContentRef.current = { props: {}, vars: {} };
            updateThemeProps();
            return;
        }

        // Only play if palette has changed
        if (palette === paletteRef.current) {
            return;
        }

        // Fetch palette after debounce
        paletteRef.current = palette;
        setTimeout(() => {
            // Abort if palette has changed
            if (palette !== paletteRef.current) {
                return;
            }

            // Fetch palette by calling https://palette.layouts.dev/?description=...
            // API returns a JSON object
            const variantTxt = palette.split(' ').find(txt => /^%[0-9]+$/g.test(txt))?.substring(1);
            const variant = variantTxt ? parseInt(variantTxt) : 0;
            const description = palette.split(' ').filter(txt => !/^%[0-9]+$/g.test(txt)).join(' ');
            const url = `https://palette.layouts.dev/?description=${encodeURIComponent(description)}&variant=${variant}`;
            fetch(url).then((res) => res.json()).then((data) => {
                // Abort if palette has changed
                if (palette !== paletteRef.current) {
                    return;
                }

                if (!check.nonEmptyArray(data?.colors)) {
                    return;
                }

                // Generate new props
                const newVars : {[key:string]: string} = {...themeVars};
                const paletteProps : ThemeProps = {};
                const paletteVars : {[key:string]: string} = {};
                data.colors.forEach((color: any) => {
                    // Set color
                    if (
                        check.nonEmptyString(color.name) 
                        && check.nonEmptyString(color.hsl) 
                        && THEME_PROP_TO_CSS_VAR.hasOwnProperty(color.name)
                    ) {
                        (paletteProps as any)[color.name] = color.hsl;                        
                    }

                    // Set color foreground
                    if (
                        check.nonEmptyString(color.name) 
                        && check.nonEmptyString(color?.foreground?.hsl)
                    ) {
                        if (color.name === 'background') {
                            (paletteProps as any)[`foreground`] = color.foreground.hsl;
                        } else if (THEME_PROP_TO_CSS_VAR.hasOwnProperty(`${color.name}Foreground`)) {
                            (paletteProps as any)[`${color.name}Foreground`] = color.foreground.hsl;
                        }
                    }

                    // Set additional color vars
                    if (check.nonEmptyObject(color?.family)) {
                        Object.keys(color.family).forEach((key) => {
                            newVars['--' + key] = color.family[key];
                            paletteVars[key] = color.family[key];
                        });
                    }
                });

                // Update props
                paletteContentRef.current = { props: paletteProps, vars: paletteVars };
                updateThemeProps();
                setThemeVars(newVars);
            })
            .catch(console.error);
        }, 1000);
    }, [props?.palette, paletteRef, themeVars, updateThemeProps]);

    // Effect to apply new style overrides on props change
    useEffect(() => {
        const { palette, ...otherProps } = props;

        // Only play if props have changed
        if (JSON.stringify(otherProps) === JSON.stringify(propsRef.current)) {
            return;
        }
        propsRef.current = otherProps;

        updateThemeProps();
    }, [props, updateThemeProps]);

    // Effect to apply style on state change
    const newStyles = generateBodyStyle(themeProps, themeVars);
    useAppliedBodyStyle(newStyles)

    // Apply dark/light mode if any
    useEffect(() => {
        if (themeProps.colorScheme) {
            const isDark = themeProps.colorScheme === 'dark';
            const prevClass = document.body.getAttribute('class') || '';
            let newClass = prevClass.split(' ').filter((c) => !['dark', 'light'].includes(c)).join(' ');
            if (isDark) {
                newClass += ' dark';
            }
            document.body.setAttribute('class',  newClass);
        }

        return () => {
            // Clean up colorScheme
            cleanUpColorScheme();
        };
    }, [themeProps, themeVars]);

    return null;
}