import { Button } from "@/components/shadcn/ui/button";
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import check from "@/vendors/check";
import { selectVariant } from "../../../util";
import { getDestinationUrlForLink, imports, setup } from "../_utils";
import { Link, Navigate } from "react-router-dom";
import { ComponentType, Fragment, useCallback, useMemo, useState } from "react";
import { redirect } from "react-router-dom";
import { registerComponent, registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { serializedFunctionFromString } from "@/lib/utils";


// Variants: 'primary' | 'secondary' | 'destructive' | 'outline' | 'ghost' | 'link'
// If only one icon child: size='icon'
/*
<Button variant="outline">Button</Button>
*/

const VARIANTS = [
    'default',
    'secondary',
    'outline',
    'ghost',
    'link',
    'destructive',
];

const ButtonAsLink = (props: any) => {
    const { to, ...otherProps } = props;
    const [shouldRedirect, setShouldRedirect] = useState(false);


    const onClick = useCallback(() => {
        setShouldRedirect(true);
    }, [setShouldRedirect]);

    if (shouldRedirect) {
        let dest = to;
        let baseUrl = window.location.origin;
        if (baseUrl.includes('localhost') || baseUrl.includes('layouts.dev')) {
            baseUrl = window.location.origin + '/' + window.location.pathname.split('/')[1];
        }
        if (dest.startsWith(window.location.origin)) {
            dest = dest.replace(window.location.origin, '');
        }
        console.log('Navigating to ' + dest)
        return <Navigate to={dest} />;
    }

    return <Button  {...otherProps} onClick={onClick} />;
}

const ButtonComponentParser : ComponentParser = {
    name: 'Button',
    description: 'A button to trigger an action',
    tags: ['button'],
refImplementation: `
/button %default
    @action="Start a video meeting"
    /icon Visioconference
    /text Start meeting
    `.trim(),
    parseTree: (c: ComponentTreeNode, config) => {
        const props = {...(c.props || {})};
        const variant = selectVariant(VARIANTS, c.variant || c?.props?.variant);
        props.variant = variant;

        // FIXME: Create an up-to-date actions API instead
        if (check.nonEmptyString(props.action)) {
            props['data-action'] = props.action;
            props.onClick = serializedFunctionFromString(
            `
                (e) => {
                    // Calling buttons.dev click handler
                    // @ts-ignore
                    const clickHandler = window?.actions?.clickHandler;
                    if (typeof clickHandler === 'function') {
                        clickHandler(e);
                    } else {
                        console.log('[Buttons.dev] No click handler found in page context. Did you include the CDN script?');
                    }
                }
            `.trim())
        
            delete props.action;
        }



        // If button is a link, wrap children inside a Link and use asChild
        let children = c.children || [];
        if (check.nonEmptyString(props.href) || check.nonEmptyString(props.to)) {
            const dest = getDestinationUrlForLink(props, config);
            
            delete props.href;
            delete props.to;
            delete props.onClick;

            let component = Link as any;
            let htmlComponent = 'Link';
            let destProp = 'href';
            if ([
                'react-component', 'create-react-app', 'test'
            ].includes(config.target)) {
                destProp = 'to';
            }
            if (config.target === 'html') {
                component = 'a';
                htmlComponent = 'a';
            }
            
            props.asChild = true;
            children = [{
                component,
                htmlComponent,
                classes: [],
                props: {
                    [destProp]: dest,
                },
                children: check.nonEmptyArray(children) ? children : null,
            }];
        }

        // If no text and only child is an icon, set size='icon'
        if (
            check.nonEmptyArray(c.children) 
            && c.children.length === 1 
            && c.children[0].component === 'icon' 
            && (!check.nonEmptyString(c.text) || !check.nonEmptyString(c.text.trim()))
        ) {
            props.size = 'icon';
        }
        
        return {
            variant,
            classes: ['gap-2', ...(c.classes || [])],
            component: Button,
            htmlComponent: 'Button',
            props,
            children,
            text: null,
        };
    },
    imports: imports(['Button'], 'button'),
    setup: setup('button'),
    variants: VARIANTS
};

registerShadcnComponent({
        Button,
    },
    'button',
);

// Register link button
registerComponent('Link', ButtonAsLink, 'next/link', {
    importAsDefault: true,
    scope: 'nextjs',
});
registerComponent('Link', ButtonAsLink, 'react-router-dom', {
    scope: 'create-react-app',
});
registerComponent('Link', ButtonAsLink, 'react-router-native', {
    scope: 'react-native',
});

export default ButtonComponentParser;
