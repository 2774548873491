import { Button } from '@/components/shadcn/ui/button';
import { useComposableApi } from '../../../lib/composable';
import { cn } from '../../../lib/utils';
import { VariantProps, cva } from 'class-variance-authority';
import React from 'react';

const BgImageCardSizeVariants = cva(
	"justify-start items-start flex-col flex relative",
	{
		variants: {
			size: {
                "6xl": "w-full",
                "5xl": "w-[1600px]",
                "4xl": "w-[1152px]",
                "3xl": "w-[832px]",
                "2xl": "w-[608px]",
                "xl": "w-[448px]",
                "lg": "w-[320px]",
                "md": "w-[224px]",
                "sm": "w-[160px]",
                "xs": "w-[128px]",
			},
			rounded: {
                "none": "rounded-none",
                "sm": "rounded-sm",
                "md": "rounded-md",
                "lg": "rounded-lg",
                "xl": "rounded-xl",
                "2xl": "rounded-2xl",
                "3xl": "rounded-3xl",
                "full": "rounded-full",
            },
		},
		defaultVariants: {
			size: "2xl",
			rounded: "none",
		},
	}
)

const BgImageCardPaddingVariants = cva(
	"items-start flex-col flex flex-1 z-10 justify-end w-full",
	{
		variants: {
			size: {
				"6xl": "p-10 gap-10",
				"5xl": "p-10 gap-10",
				"4xl": "p-8 gap-8",
				"3xl": "p-8 gap-8",
				"2xl": "p-6 gap-6",
				"xl": "p-6 gap-6",
				"lg": "p-4 gap-4",
				"md": "p-3 gap-3",
				"sm": "p-2 gap-2",
				"xs": "p-2 gap-2",
			},
		},
		defaultVariants: {
			size: "2xl",
		},
	}
)

const BgImageCardTitleVariants = cva(
	"font-black",
	{
		variants: {
			size: {
				"6xl": "typeface-display-6xl",
				"5xl": "typeface-display-6xl",
				"4xl": "typeface-display-5xl",
				"3xl": "typeface-display-4xl",
				"2xl": "typeface-display-3xl",
				"xl": "typeface-display-2xl",
				"lg": "typeface-display-xl",
				"md": "typeface-display-lg",
				"sm": "typeface-display-md",
				"xs": "typeface-display-sm",
			},
		},
		defaultVariants: {
			size: "2xl",
		},
	}
)

const BgImageCardDescriptionVariants = cva(
	"typeface-body-md",
	{
		variants: {
			size: {
				"6xl": "",
				"5xl": "",
				"4xl": "",
				"3xl": "",
				"2xl": "",
				"xl": "",
				"lg": "",
				"md": "",
				"sm": "hidden",
				"xs": "hidden",
			},
		},
		defaultVariants: {
			size: "2xl",
		},
	}
)

const BgImageCardButtonVariants = cva(
	"",
	{
		variants: {
			size: {
				"6xl": "",
				"5xl": "",
				"4xl": "",
				"3xl": "",
				"2xl": "",
				"xl": "",
				"lg": "",
				"md": "",
				"sm": "",
				"xs": "",
			},
		},
		defaultVariants: {
			size: "2xl",
		},
	}
)

type Size = "6xl" | "5xl" | "4xl" | "3xl" | "2xl" | "xl" | "lg" | "md" | "sm" | "xs";

const MINUS_SIZE: Record<Size, Size> = {
    "6xl": "3xl",
    "5xl": "3xl",
    "4xl": "2xl",
    "3xl": "xl",
    "2xl": "lg",
    "xl": "md",
    "lg": "sm",
    "md": "xs",
    "sm": "xs",
    "xs": "xs"
};

function getSmallerSize(size: Size): Size {
    return MINUS_SIZE[size];
}

interface BgImageCardProps extends VariantProps<typeof BgImageCardSizeVariants> {
    className ?: string;
    children ?: React.ReactNode;

	centered ?: boolean;

	aspectRatio ?: "1.927272727" | "4/3" | "3/4" | "1/1" | "square" | "16/9" | "9/16";
}

export function BgImageCard({ className, children, size = "2xl", rounded = "none", aspectRatio = "1.927272727", centered = false }: BgImageCardProps) {
	const { ChildWithRole, ChildrenWithRole } = useComposableApi(children);

    let button_size : "6xl" | "5xl" | "4xl" | "3xl" | "2xl" | "xl" | "lg" | "md" | "sm" | "xs" = "lg"
    if (size !== null && size !== undefined) {
        button_size = size;
    } 

	if (aspectRatio === "square") {
        aspectRatio = "1/1";
    }

    return (
        <div className={cn(BgImageCardSizeVariants({ size }), "aspect-[" + aspectRatio + "]", className)} >
			<div className={cn(BgImageCardPaddingVariants({ size }), "overflow-hidden", centered ? "m-auto" : "")} >
				<div className={cn("justify-start items-start flex-col flex gap-2 text-white", centered ? "mt-auto mx-auto" : "")} >
					<ChildWithRole
						role="title"
						className={cn(BgImageCardTitleVariants({ size }), centered ? "mx-auto text-center" : "")}
					/>
					<ChildWithRole
						role="description"
						className={cn(BgImageCardDescriptionVariants({ size }), centered ? "mx-auto text-center" : "", 
						(size === "md" || size === "lg") && (aspectRatio !== "9/16" && aspectRatio !== "3/4") ? "hidden" : "")}
					/>
				</div>
				<div className={cn(BgImageCardButtonVariants({ size }), centered ? "mb-auto mx-auto" : "")} >
					<div className={cn('flex flex-row gap-4', 
					(size === "xs" || size === "sm") && (aspectRatio !== "9/16" && aspectRatio !== "3/4" && aspectRatio !== "1/1") ? "hidden" : "")}>
						<ChildWithRole
							size={getSmallerSize(button_size)} variant="default"
							role="button"
						/>
					</div>
				</div>
			</div>
			<ChildWithRole
                role="image"
                className={"absolute inset-0 w-full h-full object-cover z-0 overflow-hidden rounded-" + rounded}
            />
			<div className={"absolute inset-0 w-full h-full bg-gradient-to-r from-black/10 to-black/40 rounded-" + rounded}></div>
		</div>
    );
}