import { cn } from "@/lib/utils";
import { useComposableApi } from "../../../lib/composable";

export default function MobileTopBar({ className, children } : { className?: string, children?: any }) {
    const { ChildWithRole, composableApi } = useComposableApi(children);

    const icons = composableApi.getAllChildrenWithRole("icon");

    return (
        <div className={cn("fixed left-0 right-0 top-0 flex flex-row justify-between items-center px-4 pt-4 pb-2 h-[72px] bg-background z-50", className)} >
            {icons.length > 0 && (
                <div className="size-12 flex flex-row items-center justify-end cursor-pointer" >
                    {composableApi.displayChild(icons[0], {
                        role: "icon",
                        className: "size-6"
                    })}
                </div>
            )}
            
            <div className="flex flex-col items-center justify-center flex-1" >
                <ChildWithRole
                    role="title"
                    className="typeface-body-lg font-bold text-center"
                />
            </div>
            
            {icons.length > 1 && (
                <div className="size-12 flex flex-row items-center justify-start cursor-pointer" >
                    {composableApi.displayChild(icons[1], {
                        role: "icon",
                        className: "size-6"
                    })}
                </div>
            )}
        </div>
    );
}