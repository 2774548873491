import ucfirst from 'ucfirst';

export function getGoogleFontsUrl(fontNames: string[]) {
    if (!fontNames || fontNames.length === 0) {
        return '';
    }

    const weights = '100,200,300,400,500,700,800,900'; // This range specifies all weights from 100 to 900
    const query = fontNames.sort().map(name => `family=${(name.split(/[\s_]/).map(ucfirst).join('+'))}:wght@${weights}`).join('&');
    return `https://fonts.googleapis.com/css?${query}&display=swap`;
}