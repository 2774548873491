import { MutableRefObject, createRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import InnerEditor, { KeyBinding, getRangeOfWordAtPosition, getWordFromPosition } from "./Editor";
import useFirebaseValue from "@/vendors/firebase/useFirebase";
import useFirebaseListAsQueue from "@/vendors/firebase/useFirebaseListAsQueue";
import check from "@/vendors/check";
import ucfirst from 'ucfirst';
import { getNextTailwindClass, getTailwindDoc, isTailwindClass } from "@/lib/tailwind/tailwindClasses";
import { handleOpenAIQueries } from "@/lib/ai/fill-ai-query";
import { defaultText } from "@/lib/defaultPage";
import { getReorderedCategories, reorderComponentsByCategories, getComponentVariants, getPropsForLineAndParentChain, isComponentTag, isComponentTagStart, isInternalComponentTag, isPropsTag, searchComponents, searchComponentsForTagChain, isComponentTagAndOnlyOneBeginComponentTag, isInternalComponentTagAndOnlyOneBeginInternalComponentTag, getComponentForLine } from "@/lib/parser/components";
import { Car, Code2Icon, Loader } from "lucide-react";
import { cn, isVariantTag } from "@/lib/utils";
import { fixIndent } from '@/lib/indents';
import { useBroadcast } from '@/vendors/use-broadcast';
import { directoryEntryForPage, pagePublishQueueUrl, pagesDirectory, publicPageUid } from "@/lib/dbRoutes";
import { PRIMARY_BG } from "@/vendors/theme/colors";
import useClerkAuthedFirebase from "@/vendors/firebase/useClerkAuthedFirebase";
import { appJsonToNextJSRepository, appJsonToSimplifiedAppJson, layoutsLangToAppJson } from "@/lib/parser/parser-v2";
import moment from 'moment';
import { Separator } from "../shadcn/ui/separator";
import { useProjectTitle } from "@/hooks/useProjectTitle";
import { LoadingAnimation } from "../LoadingPage";
import { generateThemeCssVars } from "../layouts-ui/generate-theme";
import { EMPTY_EDITOR_CONTENT } from "@/lib/constants";
import { Label } from "../shadcn/ui/label";
import { getComponentParserForPath } from "@/lib/parser/parser";
import * as monaco from 'monaco-editor';
import { ComponentParser } from "@/lib/parser/components/component.type";
import { RadixPropDocumentation } from "@/lib/parser/doc/radix/radix-docs";
import { OnChange } from "@monaco-editor/react";
import { boolean } from "check-types";

function findWordStart(line: string, word: string, caretPos: number) {
    // Ensure the caret position is within the bounds of the line
    if (caretPos < 0 || caretPos > line.length) {
        return -1; // Invalid caret position
    }

    // Check if the word at the caret position matches the given word
    if (line.substring(caretPos, Math.min(line.length, caretPos + word.length)) === word) {
        return caretPos;
    }

    // Move backwards to find the start of the word
    for (let i = caretPos - 1; i >= 0; i--) {
        if (line.substring(i, Math.min(line.length, i + word.length)) === word) {
            return i;
        }
    }

    return -1; // Word not found
}

interface replaceWordAndPreserveCaretProps {
    model: monaco.editor.ITextModel | null;
    editor: monaco.editor.IStandaloneCodeEditor;
    monaco: typeof import('monaco-editor');
    line: number;
    prevWord: string;
    newWord: string;
    caretPosition: {column: number};
    lineContent: string;
}

const replaceWordAndPreserveCaret = ({model, editor, monaco, line, prevWord, newWord, caretPosition, lineContent} : replaceWordAndPreserveCaretProps) => {
    if (!model) {
        return;
    }
    const indexOfPrevWord = findWordStart(lineContent, prevWord, caretPosition.column - 1);
    if (indexOfPrevWord === -1) {
        console.error('Could not find word in line');
        return;
    }

    const newLineContent = lineContent.substring(0, indexOfPrevWord) + newWord + lineContent.substring(indexOfPrevWord + prevWord.length);

    // Replace the word in the model
    const edits = [{
      range: new monaco.Range(line, 1, line, lineContent.length + 1),
      text: newLineContent,
      forceMoveMarkers: true,
    }];

    model.pushEditOperations([], edits, () => null);

    // Move the caret to the end of the new word
    const newCaretPos = indexOfPrevWord + newWord.length + 1;
    editor.setPosition({ lineNumber: line, column: newCaretPos });
};

function CssLine({ line }: { line: string }) {
    if (!check.nonEmptyString(line) || !line.includes(':')) {
        return null;
    }

    const keyword = line.split(':')[0] + ':';
    const value = line.split(':')[1].split('/*')[0];
    const comment = line.includes('/*') ? ('/*' + line.split('/*')[1]) : null;

    return (
        <tr>
            <td className="text-nowrap text-xs text-[#ed5f00] pr-2">{keyword}</td>
            <td className="text-nowrap text-xs text-[#5b5e66]">{value}</td>
            {comment && <td className="text-xs text-[#5b5e668A] pl-2">{comment}</td>}
        </tr>
    );
}

function Key({ children } : { children: React.ReactNode }) {
    return (
        <kbd className="pointer-events-none h-4 inline-flex select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
            {children}
        </kbd>
    );
}

function InfoBubble({ title, word } : { title?: string, word: string }) {
    return (
        <div className="flex flex-col rounded-lg border bg-white border-[#e4e4e4] shadow-lg items-start p-2 gap-2 min-w-[320px]" >
			<div className="flex-col items-start gap-2 w-full" >
                {title && ( <span className="text-sm font-medium text-[#1e1f22]" >
					{title}
				</span>)}
                <table className="">
                    <tbody>
                        {word.split('\n').map((line, i) => (<CssLine key={'info_'+i} line={line} />))}
                    </tbody>
                </table>
			</div>
			<Separator className="bg-gray-100" orientation="horizontal" />
			<div className="flex flex-row gap-2 items-center" >
				<div className="flex flex-col px-1 py-0.5 rounded bg-[#f4f3f1]" >
					<span className="text-xs font-['roboto_mono'] text-[#5b5e66]" >
						Shift
					</span>
				</div>
				<img alt="plus" className="size-2.5 text-[#5b5e66]" sizes="(max-width: 320px) 320px, (max-width: 640px) 640px, (max-width: 960px) 960px, (max-width: 1280px) 1280px, (max-width: 1920px) 1920px, 2560px" src="https://illustrations.dev/cdn-cgi/image/format=auto,fit=scale-down,width=1280/encrypted/img_MzM1QkNEQUQwQzgzQ0MxOTk5N0FGNkI2Q0JBOTczRDI=" srcSet="https://illustrations.dev/cdn-cgi/image/format=auto,fit=scale-down,width=320/encrypted/img_MzM1QkNEQUQwQzgzQ0MxOTk5N0FGNkI2Q0JBOTczRDI= 320w, https://illustrations.dev/cdn-cgi/image/format=auto,fit=scale-down,width=640/encrypted/img_MzM1QkNEQUQwQzgzQ0MxOTk5N0FGNkI2Q0JBOTczRDI= 640w, https://illustrations.dev/cdn-cgi/image/format=auto,fit=scale-down,width=960/encrypted/img_MzM1QkNEQUQwQzgzQ0MxOTk5N0FGNkI2Q0JBOTczRDI= 960w, https://illustrations.dev/cdn-cgi/image/format=auto,fit=scale-down,width=1280/encrypted/img_MzM1QkNEQUQwQzgzQ0MxOTk5N0FGNkI2Q0JBOTczRDI= 1280w, https://illustrations.dev/cdn-cgi/image/format=auto,fit=scale-down,width=1920/encrypted/img_MzM1QkNEQUQwQzgzQ0MxOTk5N0FGNkI2Q0JBOTczRDI= 1920w, https://illustrations.dev/cdn-cgi/image/format=auto,fit=scale-down,width=2560/encrypted/img_MzM1QkNEQUQwQzgzQ0MxOTk5N0FGNkI2Q0JBOTczRDI= 2560w" />
				<div className="flex flex-col px-1 py-0.5  rounded bg-[#f4f3f1]" >
					<span className="text-xs font-['roboto_mono'] text-[#5b5e66]" >
						Up
					</span>
				</div>
				<span className="text-xs text-[#5b5e66]" >
					or
				</span>
				<div className="flex flex-col px-1 py-0.5 rounded bg-[#f4f3f1]" >
					<span className="text-xs font-['roboto_mono'] text-[#5b5e66]" >
						Down
					</span>
				</div>
				<span className="text-xs text-[#5b5e66]" >
					to cycle through values
				</span>
			</div>
		</div>
    )
}

function VariantInfoBubble({ value } : { value: string }) {
    /*
    return (
        <div 
            className={`whitespace-nowrap bg-slate-700 flex items-center justify-start px-1.5 py-1 text-xs text-white shadow rounded-lg w-fit gap-1`}
        >
            <Key>Shift</Key> + <Key>Up</Key> or <Key>Down</Key> to cycle through variants
        </div>
    );
    */

    return (
        <div 
            className={`whitespace-nowrap bg-slate-800 flex flex-col items-start justify-start px-2 py-1 text-xs text-white shadow rounded-lg w-fit`}
        >
            <div className="flex items-center gap-2 text-md font-bold h-5">
                <span>{check.nonEmptyString(value) ? 'Variant:' : 'Variant'}</span>
                {check.nonEmptyString(value) && (
                    <span className="font-mono font-normal text-sky-500 text-[12px] mt-px">{value}</span>
                )}
            </div>

            <div className={`whitespace-nowrap border-t-solid border-t border-t-white/20 mt-2 py-1 text-white/80`} >
                <Key>Shift</Key> + <Key>Up</Key> or <Key>Down</Key> to cycle through values
            </div>
            
        </div>
    );
}

interface PropDropdownProps {
    word: string;
    replaceText: (word: string, newWord: string) => void;
    selectedDrowdownProp: any;
    parentComponents: string[] | null;
    lineContent: string;
}

function PropDropdown({word, replaceText = () => {}, selectedDrowdownProp = null, parentComponents = null, lineContent} : PropDropdownProps) {
    const components = getPropsForLineAndParentChain(lineContent, parentComponents || []).filter(c => c.name?.toLowerCase().includes(word.toLowerCase().trim().replace('@', '')));
    let content = null;
    const itemRefs: React.MutableRefObject<React.RefObject<HTMLDivElement>[]> = useRef(components.map(() => createRef()));

    // Make sure to always scroll to the selected component
    useEffect(() => {
        if (!check.nonEmptyArray(components) || !selectedDrowdownProp) {
            return;
        }
        const index = components.indexOf(selectedDrowdownProp);
        if (!itemRefs.current || !itemRefs.current[index]?.current) {
            return;
        }
        itemRefs.current[index >= 0 ? (index % components.length) : 0].current?.scrollIntoView({
            behavior: 'auto',
            block: 'nearest',

        });
    }, [selectedDrowdownProp, components]);

    const editorRoot = document.getElementById('editor-root');
    if (!editorRoot) {
        return null
    }
    const editorRect = editorRoot.getBoundingClientRect();

    if (!check.nonEmptyArray(components)) {
        content = (
            <div className={cn("h-full flex flex-col justify-center items-center gap-2 rounded-lg border bg-white border-[#e4e4e4] shadow-lg shadow-gray-400/20 p-3", 
                editorRect.height > 420 + 64 ? "max-h-[420px]" : `max-h-[${editorRect.height - 64}px]`,
                editorRect.width > 350 + 32 ? "w-[350px]" : `w-[${editorRect.width - 32}px]`)}>
                <span className="text-sm font-medium text-[#1e1f22]" >No prop starting with <b>{word}</b></span>
                <span className="text-[13px] text-[#5b5e66]" >Erase a few characters to find other options</span>
            </div>
        );
    } else {
        content = (
            <div 
                className="flex flex-col items-center" 
                onKeyUp={(e) => {
                    // If the user presses any letter or space, write the new word in the monaco editor using replaceWordAndPreserveCaret()
                    if (e.key.length === 1) {
                        const newWord = word.trim() + e.key;
                        replaceText(word, newWord);
                    }
        
                }}>
			    <div /* align="start" */ className={cn("justify-start flex-col flex p-0 rounded-lg border bg-[#f5f5f5] border-[#e4e4e4] shadow-lg shadow-gray-400/20 items-start gap-2 relative h-fit overflow-y-scroll", 
                    editorRect.height > 420 + 64 ? "max-h-[420px]" : `max-h-[${editorRect.height - 64}px]`,
                    editorRect.width > 350 + 32 ? "w-[350px]" : `w-[${editorRect.width - 32}px]`)} >
                    <div ref={itemRefs.current[0]} className="items-center justify-start flex w-full p-2 rounded-tr-lg rounded-tl-lg gap-1" >
                        <Label className="text-xs text-[#5d770d] font-['roboto_mono']" >
                            @props
                        </Label>
                        <Label className="text-xs text-gray-400" >
                            for
                        </Label>
                        <Label className="text-xs text-[#33aaee] font-['roboto_mono']" >
                            {getComponentForLine(lineContent)}
                        </Label>
                    </div>
                    <div className="justify-start items-start flex-col flex gap-1 p-1 w-full mt-0" >
                        {components.map((c, i) => {
                            return (
                                <div
                                    ref={i != 0 ? itemRefs.current[i] : null}
                                    key={'componentCard::' + c.name} 
                                    /* value={c.name} */
                                    className={cn(
                                        "justify-start flex p-2 w-full gap-3 items-start rounded-lg hover:bg-white cursor-pointer",
                                        (selectedDrowdownProp && selectedDrowdownProp.name === c.name) ? `bg-white`: ''
                                    )}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        replaceText(word, "@" + c.name);                                 
                                    }}
                                >
                                    <div className="justify-start items-start flex-col flex w-full pr-1 gap-1.5" >
                                        <div className="flex items-center justify-between w-full" >
                                            <span className="text-xs font-medium text-[#1e1f22]" >
                                                @{c.name || ucfirst(c.name)}
                                            </span>
                                            <span className="text-xs font-normal text-gray-400" >
                                                {c.type}
                                            </span>
                                        </div>
                                        <span className="text-xs text-[#5b5e66] text-wrap w-full font-normal" >
                                            {c.description || '' }
							            </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

    return (
        content
    )
}

interface ComponentDropdownProps {
    word: string;
    replaceText: (word: string, newWord: string) => void;
    selectedDrowdownComponent: any;
    parentComponents: string[];
}

function ComponentDropdown({word, replaceText = () => {}, selectedDrowdownComponent = null, parentComponents} : ComponentDropdownProps) {
    const components = reorderComponentsByCategories(searchComponentsForTagChain(word, parentComponents), parentComponents).filter(c => !!c && check.nonEmptyArray(c.tags));
    const categories : Record<string, any[]> = getReorderedCategories(components, parentComponents);
    let content = null;
    const itemRefs : React.MutableRefObject<React.RefObject<HTMLDivElement>[]> = useRef(components.map(() => createRef()));
    const formattedParents = parentComponents.map((c) => c.slice(1));

    // Make sure to always scroll to the selected component
    useEffect(() => {
        if (!check.nonEmptyArray(components) || !selectedDrowdownComponent) {
            return;
        }

        const index = components.findIndex(c => c.name === selectedDrowdownComponent.name);
        if (!itemRefs.current || !itemRefs.current[index]?.current) {
            return;
        }
        itemRefs.current[index >= 0 ? (index % components.length) : 0].current?.scrollIntoView({
            behavior: 'auto',
            block: 'nearest',

        });
    }, [selectedDrowdownComponent, components]);

    const editorRoot = document.getElementById('editor-root');
    if (!editorRoot) {
        return null
    }
    const editorRect = editorRoot.getBoundingClientRect();

    if (!check.nonEmptyArray(components)) {
        content = (
            <div className={cn("h-full flex flex-col justify-center items-center gap-2 rounded-lg border bg-white border-[#e4e4e4] shadow-lg shadow-gray-400/20 p-3 overflow-y-scroll", 
                editorRect.height > 420 + 64 ? "max-h-[420px]" : `max-h-[${editorRect.height - 64}px]`,
                editorRect.width > 350 + 32 ? "w-[350px]" : `w-[${editorRect.width - 32}px]`)}>
                <span className="text-sm font-medium text-[#1e1f22]" >No component starting with <b>{word}</b></span>
                <span className="text-[13px] text-[#5b5e66]" >Erase a few characters to find other options</span>
            </div>
        );
    } else {
        let index = -1;
        let category_index = -1;
        content = (
            <div
            className="flex flex-col items-center" 
                onKeyUp={(e) => {
                    // If the user presses any letter or space, write the new word in the monaco editor using replaceWordAndPreserveCaret()
                    if (e.key.length === 1) {
                        const newWord = word.trim() + e.key;
                        replaceText(word, newWord);
                    }
        
                }}
            >
                <div className={cn("justify-start flex-col flex rounded-lg border bg-white border-[#e4e4e4] shadow-lg shadow-gray-400/20 items-start relative overflow-y-scroll overflow-x-hidden", 
                    editorRect.height > 420 + 64 ? "max-h-[420px]" : `max-h-[${editorRect.height - 64}px]`,
                    editorRect.width > 350 + 32 ? "w-[350px]" : `w-[${editorRect.width - 32}px]`)} >
                    {Object.keys(categories).map((category_key, i) => {
                        if (categories[category_key].length === 0) {
                            return null;
                        }
                        category_index++;

                        return (
                            <>
                                { formattedParents.includes(category_key) ? (
                                    <div ref={itemRefs.current[index + 1]} className={cn("items-center justify-start flex w-full bg-[#f5f5f5] p-2 rounded-tr-lg rounded-tl-lg gap-1 pb-4", 
                                        category_index != 0 ? "pt-4" : "")} >
                                        <Label className="text-xs text-[#33aaee] font-['roboto_mono']" >
                                            /{category_key}
                                        </Label>
                                        <Label className="text-xs text-gray-400" >
							                (Inner components)
						                </Label>
                                    </div>
                                ) : (
                                    <div ref={itemRefs.current[index + 1]} className={cn("justify-start items-start flex-col flex w-[290px] bg-white p-2 rounded-tr-lg rounded-tl-lg",
                                        category_index != 0 ? "pt-4" : "")} >
                                        <Label className="text-[13px] text-gray-400" >
                                            {capitalizeFirstLetter(category_key)}
                                        </Label>
                                    </div>
                                )}
                                <div className={cn("justify-start items-start flex-col flex gap-1 p-1 w-full",
                                    (formattedParents.includes(category_key) ? "bg-[#f5f5f5]" : "bg-white"),
                                )} >
                                    {categories[category_key].map((c, i) => {
                                        let IconComponent = c.icon || Code2Icon;
                                        index++;

                                        return (
                                            <div
                                                ref={i != 0 ? itemRefs.current[index] : null}
                                                key={'componentCard::' + c.tags[0]} 
                                                /* value={c.tags[0]} */
                                                className={cn(
                                                    `justify-start flex p-2 w-full gap-3 items-start rounded-lg cursor-pointer`,
                                                    (formattedParents.includes(category_key) ? "hover:bg-white" : `hover:bg-[#f5f5f5]`),
                                                    (formattedParents.includes(category_key) ? "bg-[#f5f5f5]" : "bg-white"),
                                                    (selectedDrowdownComponent && selectedDrowdownComponent.tags.some((t: string) => c.tags.includes(t))) ? formattedParents.includes(category_key) ? "bg-white" : `bg-[#f5f5f5]` : '',
                                                )}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    replaceText(word, "/" + c.tags.find((t: string) => t.trim().toLowerCase().startsWith(word.trim().substr(1).toLowerCase())));                       
                                                }}
                                            >
                                                <div className="flex p-4 border border-[#e4e4e4] rounded-lg bg-white" >
                                                    <IconComponent className="size-4 text-gray-400" />
                                                </div>
                                                <div className="justify-start items-start flex-col flex w-full pr-1" >
                                                    <span className="text-[13px] font-medium text-[#1e1f22]" >{c.tags[0] || ucfirst(c.tags[0])}</span>
                                                    <span className="text-[13px] text-[#5b5e66] text-wrap w-full font-normal" >{c.description || (formattedParents.includes(category_key) ? `${capitalizeFirstLetter(c.tags[0])} sub component for ${capitalizeFirstLetter(category_key)}` : 'Native HTML component')}</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {i < Object.keys(categories).length - 1 && (
                                    <Separator orientation="horizontal" />
                                )}
                            </>
                        );
                    })}
                </div>
            </div>
        );
    }

    return (
        content
    )
}

function capitalizeFirstLetter(string : string) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
}

interface DropDownForWordProps {
    caretInfo: any;
    word: string;
    replaceText: (word: string, newWord: string) => void;
    selectedDrowdownComponent: any;
    selectedDrowdownProp: any;
    parentComponents: string[];
}

function DropDownForWord({caretInfo, word, replaceText, selectedDrowdownComponent, selectedDrowdownProp, parentComponents} : DropDownForWordProps) {
    if (!check.nonEmptyString(word)) {
        return null;
    }

    // Handle props dropdown
    if (word.startsWith('@') && !word.includes("=")) {
        const { startColumn, endColumn, column, lineContent } = caretInfo;

        if (column <= (startColumn + 1) || (column > endColumn && isPropsTag(word, parentComponents, lineContent))) {
            return null;
        }

        return <PropDropdown word={word} replaceText={replaceText} selectedDrowdownProp={selectedDrowdownProp} parentComponents={parentComponents} lineContent={lineContent}/>;
    }

    // Handle tailwind classes
    if (isTailwindClass(word)) {
        const { title, css } = getTailwindDoc(word) || {};
        if (!css) {
            return null;
        }
        return <InfoBubble title={title} word={css} />;
    }

    // Handle component variants
    if (isVariantTag(word)) {
        return <VariantInfoBubble value={word.replace('%', '').trim()} />;
    }


    // Handle component tags
    if (word.startsWith('/') && !word.includes(">") && !word.includes("//")) {
        const { startColumn, endColumn, column, lineContent } = caretInfo;
        // Don't display if at the vry start or end of the word

        const trimmed_line = lineContent.trimStart();
        const { start: trimmed_start } = getRangeOfWordAtPosition(trimmed_line, column - (lineContent.length - trimmed_line.length));
        const notFirstWord: boolean = word && trimmed_start !== 0 ? true : false;

        if (notFirstWord || column <= (startColumn + 1) || (column > endColumn && (isComponentTagAndOnlyOneBeginComponentTag(word, parentComponents) || isInternalComponentTagAndOnlyOneBeginInternalComponentTag(word, parentComponents)))) {
            return null;
        }
        if ((isComponentTagAndOnlyOneBeginComponentTag(word, parentComponents) || isInternalComponentTagAndOnlyOneBeginInternalComponentTag(word, parentComponents)) && column >= endColumn + 1) {
            return null;
        }

        return <ComponentDropdown word={word} replaceText={replaceText} selectedDrowdownComponent={selectedDrowdownComponent} parentComponents={parentComponents} />;
    }

    return null;
}

interface upDownKeyListenerProps {
    mode: KeyBinding['mode'];
    code: KeyBinding['code'];
    editor: monaco.editor.IStandaloneCodeEditor;
    monaco: typeof import('monaco-editor');
    line: number;
}

function upDownKeyListener({ mode, code, editor, monaco } : upDownKeyListenerProps, { line } : upDownKeyListenerProps) : boolean {
    if (mode !== 'Shift' || !['UpArrow', 'DownArrow'].includes(code)) {
        return true;
    }


    // Find word under caret and replace it by the right one
    const model = editor.getModel();
    if (!model) {
        return true;
    }
    const lineContent = model.getLineContent(line);
    const selection = editor.getSelection();
    const caretPosition = selection ? selection.getStartPosition() : null;
    if (caretPosition === null) {
        console.error('Caret position is null');
        return true;
    }
   
    const prevWord = getWordFromPosition(lineContent, caretPosition.column);
    
    // Handle up/down on tailwind classes
    if (isTailwindClass(prevWord)) {
        const newWord = getNextTailwindClass(prevWord, code === 'UpArrow' ? 1 : -1);

        // Replace word by nextClass within a line
        replaceWordAndPreserveCaret({model, editor, monaco, line, prevWord, newWord, caretPosition, lineContent});
        return false;
    }

    // Handle up/down on variants
    if (isVariantTag(prevWord)) {
        // Make sure we're on a component line
        if (!lineContent.trim().startsWith('/')) {
            return true;
        }
        const firstWord = lineContent.trim().split(' ')[0];
        if (!isComponentTag(firstWord)) {
            return true;
        }
        const variants = getComponentVariants(firstWord);
        if (!check.nonEmptyArray(variants)) {
            return true;
        }

        
        // Replace word by next variant
        const currentVariant = prevWord.replace('%', '');
        let currentIndex = Math.max(variants.indexOf(currentVariant), 0);

        const newWord = '%' + variants[(variants.length + currentIndex + (code === 'UpArrow' ? -1 : 1)) % variants.length];
        replaceWordAndPreserveCaret({model, editor, monaco, line, prevWord, newWord, caretPosition, lineContent});
        return false;
    }
    
    return true;
}

interface keyListenerProps {
    mode: KeyBinding['mode'], 
    code: KeyBinding['code'], 
    listener: (props: upDownKeyListenerProps, data: any) => boolean | void, 
    condition: string | undefined
}

const keyboardListeners = [
    {
        mode: 'Shift' as KeyBinding['mode'],
        code: 'UpArrow' as KeyBinding['code'],
        listener: upDownKeyListener,
        condition: undefined as string | undefined
    },
    {
        mode: 'Shift' as KeyBinding['mode'],
        code: 'DownArrow' as KeyBinding['code'],
        listener: upDownKeyListener,
        condition: undefined as string | undefined
    },
] as keyListenerProps[];

export function nIndents(line: string) {
    let indent = 0;
    let l = line;
    while (l.startsWith('    ')||l.startsWith('\t')) {
        indent++;

        if (l.startsWith('\t')) {
            l = l.substring(1);
        } else if (l.startsWith('    ')) {
            l = l.substring(4);
        }
    }

    return indent;
}

export function getComponentParserForLine(line : string, parentComponents : string[] | null) {
    let before = line.trim().split('>>>')[0].trim(); // .split(' ')[0];

    if (before[0] !== '/') {
        return undefined;
    }

    const normalized = before.replace(/\s+/g, ' ').trim();

    if (!isComponentTag(before)) {
        // Handle "/tag %variant >>>"
        if (normalized.split(' ').length === 2) {
            const [comp, variant] = normalized.split(' ');
            if (isComponentTag(comp) && isVariantTag(variant)) {
                before = comp
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    // If there's a space after the component, don't add current component to the chain
    // it was already added.
    const shouldAppendChain = true;

    const chain = [...(parentComponents || []), shouldAppendChain ? before : null].filter(Boolean) as string[];

    if (!chain.length) {
        return;
    }

    const component = getComponentParserForPath(chain);

    if (!component || !component.refImplementation?.length) {
        return undefined;
    }

    return component;
}

function handleAccelerateCommand(editor: monaco.editor.IStandaloneCodeEditor, monaco: typeof import('monaco-editor'), txt: string, parentComponents: string[] | null) {
    if (!txt.includes('>>>')) {
        return;
    }

    const currentText = editor.getValue();
    const lines = currentText.split('\n');
    lines.forEach((line, i) => {
        if (!line.includes('>>>')) {
            return;
        }

        if (!check.array(parentComponents)) {
            throw new Error('[handleAccelerateCommand] Invalid parentComponents');
        }

        let component = getComponentParserForLine(line, parentComponents);
        if(!component) {
            return;
        }

        let newValue = fixIndent(component.refImplementation || '');
        const indent = line.substring(0, line.search(/\S/));
        if (check.nonEmptyString(newValue)) {
            newValue = newValue.split('\n').map((l, i) => {
                return indent + l;
            }).join('\n');
        }

        const prevWord = line.split('>>>')[0] + '>>>';

        replaceWordAndPreserveCaret({model: editor.getModel(), editor, monaco, line: lines.indexOf(line) + 1, prevWord, newWord: newValue, caretPosition: { column: 1 }, lineContent: line});

        // Move caret to the end of the part we've just replaced
        let lineNumber = i + 1;
        let column = line.indexOf('>>>') + 1;
        if (check.nonEmptyString(newValue) && newValue.split('\n').length > 1) {
            lineNumber += newValue.split('\n').length - 1;
            const popped = newValue.split('\n').pop();
            if (popped) {
                column = popped.length + 1;
            }
        } else if (check.nonEmptyString(newValue)) {
            column += newValue.length;
        }
        editor.setPosition({ lineNumber, column });
    });
}


// /////////////////////////////////////////////////////////////////////
// FIXME: Complex handler to update appJson in DB sequentially
// /////////////////////////////////////////////////////////////////////
let isRunning = false;
let nextMarkdownToParse : string | null = null;

async function updateNextJsRepository(updateFunc: (data: any) => void, md: string) {
    const appJsonNextJS  = layoutsLangToAppJson(md, 'nextjs');
    if (!appJsonNextJS) {
        return;
    }
    const nextJs = await appJsonToNextJSRepository(appJsonNextJS);
    await updateFunc({
        nextjs: nextJs,
        theme: await generateThemeCssVars(appJsonNextJS.themeProps),
    });
}

let nextJsUpdateTimeout : NodeJS.Timeout | null = null;
function runDebouncedUpdateOfNextJS(updateFunc: (data: any) => void, md: string) {
    if(nextJsUpdateTimeout !== null) {
        clearTimeout(nextJsUpdateTimeout);
        nextJsUpdateTimeout = null;
    }

    // Wait for 1 second after last change
    nextJsUpdateTimeout = setTimeout(() => {
        updateNextJsRepository(updateFunc, md)
            .catch(console.error);
    }, 500);
}

async function runLoop(updateFunc: (data: any) => void) {
    if (isRunning) {
        return;
    }
    if (!nextMarkdownToParse || !updateFunc) {
        return;
    }
    const start = Date.now();
    isRunning = true;
    try {
        const md = nextMarkdownToParse;
        nextMarkdownToParse = null;
        const appJsonNative  = layoutsLangToAppJson(md, 'react-native');
        // const appJsonNextJS  = layoutsLangToAppJson(md, 'nextjs');
        if (!appJsonNative /*  || !appJsonNextJS */ ) {
            return;
        }
        const simplified = JSON.stringify(appJsonToSimplifiedAppJson(appJsonNative));

        // const nextJs = await appJsonToNextJSRepository(appJsonNextJS);
        await updateFunc({
            appJson: simplified,
            // nextjs: nextJs,
            // theme: await generateThemeCssVars(appJsonNextJS.themeProps),
        });

        console.log('Simplified appJson: ', simplified);

        // Update NextJS
        runDebouncedUpdateOfNextJS(updateFunc, md);

    } finally {
        isRunning = false;
        console.log('Runloop: done in '+ (Date.now() - start) + 'ms');
        if (nextMarkdownToParse) {
            setTimeout(() => runLoop(updateFunc), 10);
        }
    }
}

function parseAndUpdateSimplifiedAppJson(updateFunc: (data: any) => void, markdown: string) {
    nextMarkdownToParse = markdown;
    runLoop(updateFunc);
}
// /////////////////////////////////////////////////////////////////////

// Hook with effect to highlight currently hovered line from the player
/*
function useHightlightHoveredComponentEffect(pageId, editor, monaco) {
    const [hoveredElementId] = useBroadcast(`//${pageId}/hovered-element-id`, '');
    const prevHighlight = useRef('');

    useEffect(() => {
        // Act only if editor is set
        if(!editor || !monaco) {
            return;
        }

        // Act only if hovered element ID is a line number
        if (!check.nonEmptyString(hoveredElementId) || !/^line_[0-9]+$/g.test(hoveredElementId)) {
            return;
        }

        // Act only if the line wasn't previoulsy selected
        if (prevHighlight.current === hoveredElementId) {
            return;
        }
        prevHighlight.current = hoveredElementId;

        const lineNumber = parseInt(hoveredElementId.substring('line_'.length));

        // Highlight line
        editor.setPosition({ lineNumber: lineNumber+1, column: 0 });
    }, [editor, hoveredElementId, monaco]);
}
*/

interface EditorProps {
    pageId: string;
    dbPath: string | null;
    publicDbPath: string | null;
    onCaretInfoUpdate?: (info: any) => void;
    readOnly?: boolean;
    setEditorInstance?: (instance: any) => void;
}

interface CaretInfoProps {
    line: number;
    column: number;
    lineContent: string;
    word: string;
    startColumn: number;
    endColumn: number;
    monaco: typeof import('monaco-editor');
    editor: monaco.editor.IStandaloneCodeEditor;
    model: monaco.editor.ITextModel;
}

interface PublicPageRoot {
    owner: string;
    markdown: string;
}

export default function Editor({ pageId, dbPath, publicDbPath, onCaretInfoUpdate, readOnly = false, setEditorInstance } : EditorProps) {
    const uid = useClerkAuthedFirebase();
    const { value: remoteMarkdown, set: setRemoteMarkdown, loading } = useFirebaseValue(dbPath, '');
    const { update: updatePublicRemoteRoot, value: publicPageRoot, /* publicLoading */ } : {update: (data: {}) => void, value: {}} = useFirebaseValue(publicDbPath, {});
    const { value: projectCreationDate, set: setProjectCreationDate, loading: projectCreationDateLoading } = useFirebaseValue(directoryEntryForPage(pageId, uid) + '/created', '');
    const { value: projectOpenedDate, set: setProjectOpenedDate } = useFirebaseValue(directoryEntryForPage(pageId, uid) + '/opened', '');
    const [localCode, setLocalCode] = useBroadcast(`//${pageId}/editor-code`, '');
    const [projectTitle, setProjectTitle, projectTitleLoading] = useProjectTitle(pageId, readOnly);
    // const [localCode, setLocalCode] = useState(null);

    const [caretInfo, setCaretInfoState] = useState<CaretInfoProps | null>(null);
    const [actionCancelled, setActionCancelled] = useState(false);
    const caretInfoRef : MutableRefObject<any> = useRef(caretInfo);
    const [isLoading, setIsLoading] = useState(loading /* || publicLoading */);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const openDate = useMemo(() => moment().toISOString(), [pageId]);
    // useHightlightHoveredComponentEffect(pageId, caretInfoRef?.current?.editor,  caretInfoRef?.current?.monaco)


    useEffect(() => {
        if (loading/*  || publicLoading */) {
            setIsLoading(true);
            return;
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [loading/* , publicLoading */]);
        
    
    const setCaretInfo = useCallback((info: any) => {
        // Cancel any actions cancellations when caret moves from one word to another
        if (caretInfoRef?.current?.word !== info?.word) {
            setActionCancelled(false);
        }

        // Update editor instance when it changes
        const { editor, monaco } = info || {};
        if (
            editor 
            && monaco 
            && (editor !== caretInfoRef.current?.editor || monaco !== caretInfoRef.current?.monaco)
            && check.function(setEditorInstance)
        ) {
            setEditorInstance({
                editor, 
                monaco,
            });
        }
    
        // Update caret info
        caretInfoRef.current = info;
        setCaretInfoState(info);
        if (check.function(onCaretInfoUpdate)) {
            onCaretInfoUpdate(info);
        }
    }, [onCaretInfoUpdate, setEditorInstance]);
    const { word } = caretInfo || {};
    const [ selectedDrowdownComponent, setSelectedDropdownComponent ] = useState(null);
    const [ selectedDrowdownProp, setSelectedDropdownProp ] = useState(null);
    const dropdownRef: MutableRefObject<any> = useRef({ 
        matchingComponents: [], 
        selectedComponent: null, 
        matchingProps: [],
        selectedProp: null 
    });

    const [parentComponents, setParentComponents] = useState([]);

    const selectNextComponent = useCallback(() => {
		if (!check.nonEmptyArray(dropdownRef.current.matchingComponents)) {return null;}

        if (!dropdownRef.current.selectedComponent) {
            dropdownRef.current.selectedComponent = dropdownRef.current.matchingComponents[0];
            return dropdownRef.current.selectedComponent;
        }
		const currentIndex = dropdownRef.current.matchingComponents.indexOf(dropdownRef.current.selectedComponent);
		let index = currentIndex === -1 ? 0 : currentIndex;
		dropdownRef.current.selectedComponent = dropdownRef.current.matchingComponents[(index + 1) % dropdownRef.current.matchingComponents.length];
        return dropdownRef.current.selectedComponent;
    }, [])


    const selectPrevComponent = useCallback(() => {
        if (!check.nonEmptyArray(dropdownRef.current.matchingComponents)) {return null;}

        if (!dropdownRef.current.selectedComponent) {
            dropdownRef.current.selectedComponent = dropdownRef.current.matchingComponents[0];
            return dropdownRef.current.selectedComponent;
        }
        const currentIndex = dropdownRef.current.matchingComponents.indexOf(dropdownRef.current.selectedComponent);
        let index = currentIndex === -1 ? 0 : currentIndex;
        dropdownRef.current.selectedComponent = dropdownRef.current.matchingComponents[(index - 1) >= 0 ? index - 1 : dropdownRef.current.matchingComponents.length - 1];
        return dropdownRef.current.selectedComponent;
    }, [])

    const selectNextProp = useCallback(() => {
		if (!check.nonEmptyArray(dropdownRef.current.matchingProps)) {return null;}

        if (!dropdownRef.current.selectedProp) {
            dropdownRef.current.selectedProp = dropdownRef.current.matchingProps[0];
            return dropdownRef.current.selectedProp;
        }
        const currentIndex = dropdownRef.current.matchingProps.findIndex((c: ComponentParser) => c.name === dropdownRef.current.selectedProp.name);
		let index = currentIndex === -1 ? 0 : currentIndex;
		dropdownRef.current.selectedProp = dropdownRef.current.matchingProps[(index + 1) % dropdownRef.current.matchingProps.length];
        return dropdownRef.current.selectedProp;
    }, [])


    const selectPrevProp = useCallback(() => {
        if (!check.nonEmptyArray(dropdownRef.current.matchingProps)) {return null;}

        if (!dropdownRef.current.selectedProp) {
            dropdownRef.current.selectedProp = dropdownRef.current.matchingProps[0];
            return dropdownRef.current.selectedProp;
        }
        const currentIndex = dropdownRef.current.matchingProps.findIndex((c: ComponentParser) => c.name === dropdownRef.current.selectedProp.name);
        let index = currentIndex === -1 ? 0 : currentIndex;
        dropdownRef.current.selectedProp = dropdownRef.current.matchingProps[(index - 1) >= 0 ? index - 1 : dropdownRef.current.matchingProps.length - 1];
        return dropdownRef.current.selectedProp;
    }, [])

    const setCodeValue = useCallback((rawValue: string) => {
        const value = check.nonEmptyString(rawValue) ? rawValue : EMPTY_EDITOR_CONTENT;
        setRemoteMarkdown(value);
        setLocalCode(value);
        updatePublicRemoteRoot({
            owner: uid,
            markdown: value,
        });

        // Update project metadata
        if (!projectCreationDateLoading && !check.nonEmptyString(projectCreationDate)) {
            setProjectCreationDate(moment().toISOString());
        }
        if (!projectTitleLoading && (!check.nonEmptyString(projectTitle) || projectTitle === 'Untitled project')) {
            setProjectTitle('Untitled project #'+pageId);
        }

        // Parse the value and produce a simplified app.json we can send to React Native
        parseAndUpdateSimplifiedAppJson(updatePublicRemoteRoot, value);
    }, [setRemoteMarkdown, setLocalCode, updatePublicRemoteRoot, uid, projectCreationDate, projectTitle, setProjectCreationDate, setProjectTitle, pageId, projectTitleLoading, projectCreationDateLoading]);

    useFirebaseListAsQueue(pagePublishQueueUrl(pageId, uid), (item) => {
        console.log('Received snippet: ', item)
    });

    useEffect(() => {
        // Update project metadata
        if (!projectCreationDateLoading && !check.nonEmptyString(projectCreationDate)) {
            setProjectCreationDate(moment().toISOString());
        }
        if (!projectTitleLoading && (!check.nonEmptyString(projectTitle) || projectTitle === 'Untitled project')) {
            setProjectTitle('Untitled project #'+pageId);
        }
    }, [projectCreationDate, setProjectCreationDate, projectTitle, setProjectTitle, pageId, projectTitleLoading, projectCreationDateLoading]);

    // Update project last opened date
    useEffect(() => {
        if(projectOpenedDate !== openDate) {
            setProjectOpenedDate(openDate);
        }
    }, [openDate, projectOpenedDate, setProjectOpenedDate]);

    useEffect(() => {
        if (word) {
            if (word.startsWith("/")) {
                dropdownRef.current.matchingComponents = reorderComponentsByCategories(searchComponentsForTagChain(word, parentComponents), parentComponents);
                dropdownRef.current.selectedComponent = null;
                setSelectedDropdownComponent(null);
                //setSelectedDropdownComponent(dropdownRef.current.matchingComponents[0]);
            } else if (word.startsWith("@") && parentComponents && caretInfo) {
                const word_without_at = word.toLowerCase().trim().replace('@', '');
                dropdownRef.current.matchingProps = getPropsForLineAndParentChain(caretInfo?.lineContent, parentComponents).filter((c: Partial<RadixPropDocumentation>) => c.name?.toLowerCase().includes(word_without_at));
                dropdownRef.current.selectedProp = null;
                setSelectedDropdownProp(null);
            }
        }
    }, [word, parentComponents]);
   
    // Effect to control whether we should show an empty text or the default text
    // FIXME: Bazooka to kill a fly
    let editorValue = (remoteMarkdown ? remoteMarkdown : (publicPageRoot as PublicPageRoot)?.markdown) || defaultText;
    if (editorValue === EMPTY_EDITOR_CONTENT) {
        editorValue = '';
    }

    const replaceText = useCallback((prevWord: string, newWord: string) => {
        const { 
            monaco, 
            editor,
            model,
            word,
            line,
            lineContent,
            column,
         } = caretInfoRef.current || {};

         if (!monaco || !editor || !model || !word) {
             return;
         }

         replaceWordAndPreserveCaret({model, editor, monaco, line, prevWord, newWord, caretPosition: { column }, lineContent});
         // Focus on the editor
        editor.focus();
    }, []);

    const dropdown = <DropDownForWord caretInfo={caretInfoRef.current} word={word || ""} replaceText={replaceText} selectedDrowdownComponent={selectedDrowdownComponent} selectedDrowdownProp={selectedDrowdownProp} parentComponents={parentComponents}/>;

    // Add caret & word info to keyboard listeners
    let keyListeners = useMemo(() => {
        if (!check.nonEmptyArray(keyboardListeners)) {
            return [];
        }
        return keyboardListeners.map(({ mode, code, listener}) => {
            return {
                mode,
                code,
                listener: (editor: monaco.editor.IStandaloneCodeEditor, monaco: typeof import('monaco-editor')) : boolean | void => {
                    return !!listener({ mode, code, editor, monaco } as upDownKeyListenerProps, caretInfoRef.current || {});
                },
                condition: "" as string | undefined,
            };
        });
    }, []);

    // Listener for up/down buttons when the component dropdown is open
    // TODO: Place somewhere else?
    const upDownOverButtonKeyListener = useCallback(({ mode, code, editor, monaco } : upDownKeyListenerProps, { word } : {word: string}) => {        
        if (code === 'UpArrow') {
            const newComp = selectPrevComponent();
            
            // replaceText(word, '/' + newComp.tags[0]);
            setSelectedDropdownComponent(newComp);
        }

        if (code === 'DownArrow') {
            const newComp = selectNextComponent();
            
            // replaceText(word, '/' + newComp.tags[0]);
            setSelectedDropdownComponent(newComp);
        }

        const { selectedComponent } = dropdownRef.current;
        if (code === 'Enter' || code === 'Tab') {
            const comp = selectedComponent ? selectedComponent : selectNextComponent();
            if (comp) {
                let newWord = '/' + comp.tags[0];
                const variants = comp.variants || [];
                if (check.nonEmptyArray(variants)) {
                    newWord = newWord + ' %' + variants[0];
                } else {
                    newWord = newWord + ' ';
                }

                replaceText(word, newWord);
                setSelectedDropdownComponent(null);
            }
        }
    }, [replaceText, setSelectedDropdownComponent]);

    const upDownOverPropKeyListener = useCallback(({ mode, code, editor, monaco } : upDownKeyListenerProps, { word } : {word: string}) => {  
        if (code === 'UpArrow') {
            const newComp = selectPrevProp();
            setSelectedDropdownProp(newComp);
        }

        if (code === 'DownArrow') {
            const newComp = selectNextProp();
            setSelectedDropdownProp(newComp);
        }

        const { selectedProp } = dropdownRef.current;
        if (code === 'Enter' || code === 'Tab') {
            const comp = selectedProp ? selectedProp : selectNextProp();
            if (comp) {
                let newWord = '@' + comp.name + ' ';

                replaceText(word, newWord);
                setSelectedDropdownProp(null);
            }
        }
    }, [replaceText, setSelectedDropdownProp]);

    keyListeners = [
        ...keyListeners,
        ...[
            {
                mode: undefined,
                code: 'Escape' as KeyBinding['code'],
                listener: () => setActionCancelled(true),
                condition: undefined,
            }, 
            {
                mode: undefined,
                code: 'UpArrow' as KeyBinding['code'],
                listener: upDownOverButtonKeyListener,
                condition: 'caretOverComponent',
            },
            {
                mode: undefined,
                code: 'DownArrow' as KeyBinding['code'],
                listener: upDownOverButtonKeyListener,
                condition: 'caretOverComponent',
            },
            {
                mode: undefined,
                code: 'Enter' as KeyBinding['code'],
                listener: upDownOverButtonKeyListener,
                condition: 'caretOverComponent',
            },
            {
                mode: undefined,
                code: 'Tab' as KeyBinding['code'],
                listener: upDownOverButtonKeyListener,
                condition: 'caretOverComponent',
            },
            {
                mode: undefined,
                code: 'UpArrow' as KeyBinding['code'],
                listener: upDownOverButtonKeyListener,
                condition: 'caretOverInternalComponent',
            },
            {
                mode: undefined,
                code: 'DownArrow' as KeyBinding['code'],
                listener: upDownOverButtonKeyListener,
                condition: 'caretOverInternalComponent',
            },
            {
                mode: undefined,
                code: 'Enter' as KeyBinding['code'],
                listener: upDownOverButtonKeyListener,
                condition: 'caretOverInternalComponent',
            },
            {
                mode: undefined,
                code: 'Tab' as KeyBinding['code'],
                listener: upDownOverButtonKeyListener,
                condition: 'caretOverInternalComponent',
            },
            {
                mode: undefined,
                code: 'UpArrow' as KeyBinding['code'],
                listener: upDownOverPropKeyListener,
                condition: 'caretOverProps',
            },
            {
                mode: undefined,
                code: 'DownArrow' as KeyBinding['code'],
                listener: upDownOverPropKeyListener,
                condition: 'caretOverProps',
            },
            {
                mode: undefined,
                code: 'Enter' as KeyBinding['code'],
                listener: upDownOverPropKeyListener,
                condition: 'caretOverProps',
            },
            {
                mode: undefined,
                code: 'Tab' as KeyBinding['code'],
                listener: upDownOverPropKeyListener,
                condition: 'caretOverProps',
            }].map(({ mode, code, listener, condition } : {listener: (props: upDownKeyListenerProps, data: any) => boolean | void, mode: KeyBinding['mode'], code: KeyBinding['code'], condition: string | undefined}) => {
                return {
                    mode,
                    code,
                    listener: (editor: monaco.editor.IStandaloneCodeEditor, monaco: typeof import('monaco-editor')) => {
                        return listener({ mode: undefined, line: 0, code, editor, monaco} as upDownKeyListenerProps , caretInfoRef.current || {});
                    },
                    condition,
                };
            }),
    ];

    const contentListeners = useMemo(() => {
        return [handleOpenAIQueries, handleAccelerateCommand];
    }, []);

    return (
        <div id="editor-root" className={cn(
            "relative w-full h-full flex items-center justify-center",
            isLoading ? 'bg-[#F4F3F1]' : 'bg-[#FBFBFB]',
        )} style={{
            paddingTop: '12px', // '40px',
        }}>
            {(!isLoading) && (
                <InnerEditor 
                    key="editor" 
                    value={editorValue} 
                    onChange={setCodeValue as OnChange}
                    onCaretUpdate={setCaretInfo}
                    setEditorInstance={setEditorInstance}
                    setParentComponents={setParentComponents}
                    parentComponents={parentComponents}
                    dropdownElement={actionCancelled ? null : dropdown /* Only show dropdown if user has not pressed escapes since last caret change of word */}
                    keyboardListeners={keyListeners}
                    contentListeners={contentListeners}
                    readOnly={readOnly}
                />
            )}
            {(isLoading) && (
                <div className={cn(
                    "flex flex-col items-center justify-center size-full bg-[#F4F3F1] text-[#5B5E66]",
                )}>
                    <LoadingAnimation />
                </div>
            )}
            
        </div> 
    );
}