import * as React from "react"

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/shadcn/ui/carousel"
import { childrenParsers, ComponentAliasConfig, imports, radixComponentDoc, setup } from "../_utils";
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { booleanParser, enumParser, integerParser, parseObject } from "@/vendors/types/types-parsers";
import check from "@/vendors/check";
import { cn } from "@/lib/utils";
import { bulletProofJSONParse } from "@/vendors/bulletproof-json";
import { removeEmptyProps, selectVariant } from "../../../util";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
export function CarouselDemo() {
  return (
    <Carousel className="w-full max-w-xs" opts={{
        align: "start",
        loop: true,
      }}
      orientation="vertical" // | 'horizontal'
      >
      <CarouselContent>
        {Array.from({ length: 5 }).map((_, index) => (
          <CarouselItem key={index}>
            <div className="p-1">
              <Card>
                <CardContent className="flex aspect-square items-center justify-center p-6">
                  <span className="text-4xl font-semibold">{index + 1}</span>
                </CardContent>
              </Card>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  )
}
*/

const propsParsers = {
  active: booleanParser(true),
  align: enumParser('center', 'start', 'end'),
  containScroll: enumParser('trimSnaps', 'keepSnaps', false),
  direction: enumParser('ltr', 'rtl'),
  dragFree: booleanParser(false),
  dragThreshold: integerParser(10),
  duration: integerParser(25),
  inViewThreshold: integerParser(0),
  loop: booleanParser(false),
  skipSnaps: booleanParser(false),
  slidesToScroll: integerParser(1),
  startIndex: integerParser(0),
  watchDrag: booleanParser(true),
  watchResize: booleanParser(true),
  watchSlides: booleanParser(true),
}

const itemsShownObjParsers = {
  base: integerParser(1),
  md: integerParser(1),
  lg: integerParser(1),
  xl: integerParser(1),
  '2xl': integerParser(1),
}

const VARIANTS = [
  'horizontal',
  'vertical',
];

const TAG = 'carousel';
const ALIASES : ComponentAliasConfig[] = [
    ['content', CarouselContent, 'CarouselContent', radixComponentDoc('Carousel.Content')],
    ['item', CarouselItem, 'CarouselItem', radixComponentDoc('Carousel.Item')],
    ['next', CarouselNext, 'CarouselNext', radixComponentDoc('Carousel.Next')],
    ['previous', CarouselPrevious, 'CarouselPrevious', radixComponentDoc('Carousel.Previous')],
];

const CarouselComponentParser : ComponentParser = {
  name: 'Carousel',
  description: 'A carousel with motion and swipe',
  /* refImplementation: `
/carousel %horizontal @itemsPerSlide={base: 1, md: 3} @align=start @loop=true gap-4
  /foreach 1...5
    /card m-1 flex aspect-square items-center justify-center p-6 text-4xl font-semibold
      $item
`.trim().replaceAll(/ {2}/g, '\t'),*/
  refImplementation:`
/carousel %horizontal @align=start @itemsPerSlide=3 mx-0 p-5 gap-3
    /image rounded-lg shadow-md aspect-video object-cover w-full bear
    /image rounded-lg shadow-md aspect-video object-cover w-full tiger
    /image rounded-lg shadow-md aspect-video object-cover w-full hippo
    /image rounded-lg shadow-md aspect-video object-cover w-full koala
    /image rounded-lg shadow-md aspect-video object-cover w-full flamingo
    /image rounded-lg shadow-md aspect-video object-cover w-full ostrich
  `.trim().replaceAll(/ {2}/g, '\t'),
  tags: [TAG],
  childrenParsers: childrenParsers(ALIASES, TAG),
  parseTree: (c: ComponentTreeNode) => {
      const { variant, props, classes: nativeClasses } = c;
      const { orientation, itemsPerSlide: itemsShown, classes: propClasses, ...rest } = props;

      const classes = cn('gap-0', nativeClasses).split(' ');
      const opts = parseObject(rest, propsParsers);
      const otherProps = Object.keys(rest).filter((k: any) => !(propsParsers as any)[k]).reduce((acc, k) => ({ ...acc, [k]: rest[k] }), {});

      // Parse orientation 
      const o = check.nonEmptyString(orientation) && VARIANTS.includes(orientation.trim().toLowerCase()) ? orientation.trim().toLowerCase() : selectVariant(VARIANTS, variant);

      // Parse itemsShown
      let itemsShownValue : number | null | { [key:string]: number } = null;
      if (itemsShown) {
        itemsShownValue = bulletProofJSONParse(itemsShown) || null;

        if (check.object(itemsShownValue)) {
          itemsShownValue = parseObject(itemsShownValue, itemsShownObjParsers);
          if (!check.nonEmptyObject(itemsShownValue)) {
            itemsShownValue = null;
          }
        }
      }
      let basisClassName = '';
      if (check.integer(itemsShownValue)) {
        basisClassName = `basis-1/${itemsShownValue}`;
      } else if (check.nonEmptyObject(itemsShownValue)) {
        basisClassName = Object.keys(itemsShownValue).map((k) => `${k === 'base' ? '' : `${k}:`}basis-1/${(itemsShownValue as any)[k]}`).join(' ');
      }

      // Parse gap
      // To transform it in -ml-<VALUE> and pl-<VALUE> as advised in Shadcn docs
      const gapClasses = check.nonEmptyArray(classes) ? classes.filter(check.nonEmptyString).map(c => c.trim().toLowerCase()).filter((c) => /^[a-z]*:{0,1}gap-[0-9]+$/g.test(c.trim())) : [];
      const otherClasses = check.nonEmptyArray(classes) ? classes.filter(check.nonEmptyString).map(c => c.trim().toLowerCase()).filter((c) => !/^[a-z]*:{0,1}gap-[0-9]+$/g.test(c.trim())) : [];
      const contentGapClasses = gapClasses.map((c) => c.replace('gap-', `-m${o === 'horizontal' ? 'l' : 't'}-`));
      const itemGapClasses = gapClasses.map((c, i) => c.replace('gap-', `p${o === 'horizontal' ? 'l' : 't'}-`));

      const carouselBaseClasses = o === 'horizontal' ? 'mx-12' : 'my-12';
      const contentBaseClasses = '';


      // Get composable API children
      let prevousComponent : ComponentTreeNode = (c.children || []).find((c) => c.component === CarouselPrevious) || {
        component: CarouselPrevious,
        htmlComponent: 'CarouselPrevious',
        props: {},
        children: null,
      };

      let nextComponent : ComponentTreeNode = (c.children || []).find((c) => c.component === CarouselNext) || {
        component: CarouselNext,
        htmlComponent: 'CarouselNext',
        props: {},
        children: null,
      };

      let contentComponent : ComponentTreeNode = (c.children || []).find((c) => c.component === CarouselContent) || {
        component: CarouselContent,
        htmlComponent: 'CarouselContent',
        props: {},
        classes: [],
        children: c.children || [],
      };


      return {
          _dontParse: true,
          component: 'div',
          htmlComponent: 'div',
          props: { className: cn(otherClasses) },
          classes: cn(otherClasses).split(' '),
          children: [
            {
              _dontParse: true,
              component: Carousel,
              htmlComponent: 'Carousel',
              props: { 
                opts: check.nonEmptyObject(opts) ? opts : undefined, 
                className: cn(carouselBaseClasses), orientation: o, ...otherProps },
              classes: cn(carouselBaseClasses).split(' '),
              children: [
                {
                  ...contentComponent,
                  classes: cn(contentBaseClasses, contentGapClasses, contentComponent.classes).split(' '),
                  props: { ...(contentComponent.props || {}), className: cn(contentBaseClasses, contentGapClasses, contentComponent.classes) },
                  children: (contentComponent.children || []).filter(c => (
                    !!c && ![CarouselNext, CarouselPrevious, CarouselContent].some((cc) => c.component === cc)
                  )).map((child: ComponentTreeNode, index: number) => {
                      if (child?.component === CarouselItem) {
                          const classes = cn('flex flex-col items-center justify-center', basisClassName, ...itemGapClasses, child.classes).split(' ');
                          return {
                              ...child,
                              classes,
                              props: removeEmptyProps({
                                  ...(child.props || {}),
                                  className: cn('flex flex-col items-center justify-center', basisClassName, ...itemGapClasses, child.classes, child.props?.className),
                              }),
                          };
                      }
                    
                      return ({
                          component: CarouselItem,
                          htmlComponent: 'CarouselItem',
                          classes: cn('flex flex-col items-center justify-center', basisClassName, ...itemGapClasses).split(' '),
                          props: { className: cn('flex flex-col items-center justify-center', basisClassName, ...itemGapClasses) },
                          children: [child],
                      }); 
                  }),
                },
                prevousComponent,
                nextComponent,
              ]
            }
          ]
      }
  },
  imports: imports(['Carousel', 'CarouselContent', 'CarouselItem', 'CarouselNext', 'CarouselPrevious'], 'carousel'),
  setup: setup('carousel'),
  variants: VARIANTS,
};

registerShadcnComponent({
        Carousel,
        CarouselContent,
        CarouselItem,
        CarouselNext,
        CarouselPrevious,
    },
    'carousel',
);

export default CarouselComponentParser;
