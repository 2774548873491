
import { Label } from "@/components/shadcn/ui/label"
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { imports, radixComponentDoc, setup } from "../_utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
import { Checkbox } from "@/components/shadcn/ui/checkbox"

export function LabelDemo() {
  return (
    <div>
      <div className="flex items-center space-x-2">
        <Checkbox id="terms" />
        <Label htmlFor="terms">Accept terms and conditions</Label>
      </div>
    </div>
  )
}
*/

const LabelComponentParser : ComponentParser = {
  name: 'Label',
  description: 'Renders an accessible label associated with controls',
  tags: ['label'],
  refImplementation: `
  /div flex items-center gap-2
      /checkbox @id="terms"
      /label @htmlFor="terms" Accept terms and conditions
  `.trim(),
  parseTree: (c: ComponentTreeNode) => {
      return {
          ...c,
          component: Label,
          htmlComponent: 'Label',
      };
  },
  imports: imports(['Label'], 'label'),
  setup: setup('label'),
  variants: [],
  doc: {
      props: radixComponentDoc('Label.Root').props,
  }
};

registerShadcnComponent({
        Label
    },
    'label',
);

export default LabelComponentParser;
