import { getInnerClasses, getOuterClasses } from "@/lib/tailwind/tailwindClasses";
import { ComponentTreeNode } from "../component.type";
import { cn } from "@/lib/utils";

const TAG = 'bg-video';

const BgVideoComponentParser = {
    tags: [TAG],
    name: 'Background video',
    description: 'Add a background video to a component.',
    refImplementation: `
    /center size-80 p-5 rounded-2xl shadow-xl
        /bg-video https://motion.layouts.dev/colorful-gradient.mp4
        Hello text-white text-3xl font-semibold
    `.trim(),
    wrapParent: (parent: ComponentTreeNode) => {
        
        const patterns = (parent.children || []).filter(comp => comp.component === TAG);
        if (patterns.length === 0) {
          return parent;
        }
        const otherChildren = (parent.children || []).filter((comp: ComponentTreeNode) => comp.component !== TAG)

        
        return {
          ...parent,
          classes: cn(getOuterClasses(parent.classes), 'relative overflow-hidden').split(' '),
          props: {
            className: cn(getOuterClasses(parent.classes), 'relative overflow-hidden'),
          },
          children: [
            {
              component: 'div',
              htmlComponent: 'div',
              classes: ['absolute size-full z-0'],
              props: {
                className: 'absolute size-full z-0',
              },
              children: [
                ...patterns.map((c: ComponentTreeNode) => {
                    /*
                    <video
                      autoplay
                      loop
                      muted
                      class="absolute z-10 w-auto min-w-full min-h-full max-w-none"
                    >
                      <source
                        src="https://assets.mixkit.co/videos/preview/mixkit-set-of-plateaus-seen-from-the-heights-in-a-sunset-26070-large.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video> */

                    return {
                      component: 'video',
                      htmlComponent: 'video',
                      classes:  cn('absolute z-10 h-full w-auto min-w-full min-h-full max-w-none').split(' '),
                      props: {
                        className: cn('absolute z-10 h-full w-auto min-w-full min-h-full max-w-none'),
                        autoPlay: true,
                        loop: true,
                        muted: true,
                        // playsInline: true,
                      },
                      children: [
                        {
                          component: 'source',
                          htmlComponent: 'source',
                          props: {
                            src: c.props.src || c.text,
                            type: 'video/mp4',
                          },
                          children: [
                            
                          ],
                        }
                      ],
                    };
                }),
              ],
            },
            {
              component: 'div',
              htmlComponent: 'div',
              classes: cn('relative size-full z-1', getInnerClasses(parent.classes)).split(' '),
              props: {
                className: cn('relative size-full z-1', getInnerClasses(parent.classes)).split(' '),
              },
              children: otherChildren,
            }
          ],
        };
    },
    variants: [],
};

export default BgVideoComponentParser;