import React from 'react';

interface BgImageCardProps {
  image: string;
  title: string;
  description: string;
}

export default function BgImageCard({ image, title, description}: BgImageCardProps) {
	return (
		<div className="justify-start items-start flex-col flex w-[380px] h-[490px] max-h-[100vh] relative" >
			<div className="justify-start items-start flex-col flex z-10 p-[40px] gap-[32px] m-auto" >
                <div className="justify-start items-start flex-col flex gap-[8px] text-white m-auto" >
                    <h1 className="text-semibold font-black text-[36px] font-display m-auto text-center" >
                        {title}
                    </h1>
                    <p className="font-body text-[14px] m-auto text-center" >
                        {description}
                    </p>
				</div>
                <div className='flex flex-row gap-4 m-auto'>
                    {/* Buttons */}
                </div>
			</div>
            <img
                alt="coffee"
                className="absolute inset-0 h-full w-full object-cover z-0 overflow-hidden"
                sizes="(max-width: 200px) 200px, (max-width: 400px) 400px, (max-width: 800px) 800px, 4480px"
                src={image}
            />
		</div>
	);
}