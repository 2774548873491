import { getSingleVarNameFromText } from "../../util";
import check from "@/vendors/check";
import { MongoDataSource } from "@/vendors/db/MongoDataSource";
import { cn, nonEmptyTrimmed } from "@/lib/utils";
import { MockupDataSource } from "@/vendors/db/MockupDataSource";
import { ChartComponentParser } from "./chart";
import { ThemeComponentParser } from "./theme";
import { bulletProofJSONParse, isJsonLikeArray } from "@/vendors/bulletproof-json";
import { PageComponentParser } from "./page";
import { importsRaw, normalizeTextComponent } from "../shadcn/_utils";
import { ComponentTreeNode } from "../component.type";
import { image, icon, illustration, logo } from "./images";
import { fontPair } from "./fonts";
import { cva } from "class-variance-authority";
import { registerComponent } from "../../importsRegistry";
import { DataDisplay, DataInput, DataPropsResolver, DataProvider, ForEach } from "@/vendors/data/data-v2";

const hstack = {
    name: 'HStack',
    description: 'A horizontal stack of elements.',
    tags: ['hstack'],

    refImplementation: `
/hstack p-2 gap-2 rounded-xl size-56 border border-slate-300
    /center bg-slate-200 w-16 h-full rounded-lg text-slate-700 font-bold A
    /center bg-slate-200 w-16 h-full rounded-lg text-slate-700 font-bold B
`.trim().replaceAll(/ {4}/g, '\t'),

    parseTree: (c: ComponentTreeNode ) => {
        let { component, classes, text, children } = c;
        component = 'div';
        classes = classes || [];
        classes.unshift('flex');
        classes.unshift('justify-start');
        classes.unshift('items-center');

        if (check.nonEmptyString(text)) {
            children = [normalizeTextComponent(text), ...(children || [])];
        }

        return { ...c, component, classes, text: null, children };
    }
};

const vstack = {
    name: 'VStack',
    description: 'A vertical stack of elements.',
    tags: ['vstack'],
    variants: ['left', 'center', 'right'],

    refImplementation: `
/vstack p-2 gap-2 rounded-xl size-56 border border-slate-300
    /center bg-slate-200 h-16 w-full rounded-lg text-slate-700 font-bold A
    /center bg-slate-200 h-16 w-full rounded-lg text-slate-700 font-bold B
`.trim().replaceAll(/ {4}/g, '\t'),

    parseTree: (c: ComponentTreeNode) => {
        let { component, variant, classes, text, children } = c;
        
        let items = 'items-start';
        if (variant === 'center') {
            items = 'items-center';
        }
        if (variant === 'right') {
            items = 'items-end';
        }

        component = 'div';
        classes = classes || [];
        classes.unshift('flex');
        classes.unshift('flex-col');
        classes.unshift(items);
        classes.unshift('justify-start');

        if (check.nonEmptyString(text)) {
            children = [normalizeTextComponent(text), ...(children || [])];
        }

        return { ...c, component, classes, text: null, children };
    }
};

const center = {
    name: 'Center',
    description: 'A center-aligned flex container.',
    tags: ['center'],
    parseTree: (c: ComponentTreeNode) => {
        let { component, classes, text, children } = c;
        component = 'div';
        classes = classes || [];
        classes.unshift('flex');
        classes.unshift('flex-col');
        classes.unshift('justify-center');
        classes.unshift('items-center');
        
        if (check.nonEmptyString(text)) {
            const varName = getSingleVarNameFromText(text);
            let rest = text;
            if (varName) {
                rest = text.replace(varName, '');
            }
            children = [nonEmptyTrimmed(rest) ? normalizeTextComponent(rest) : null, ...(children || [])].filter(c => !!c);
        }

        return { ...c, component, classes, text: null, children };
    },
    refImplementation: `
    /center p-2 rounded-xl size-56 border border-slate-300
        /div bg-slate-200 size-1/3 rounded-lg
    `.trim(),
};

const heading1 = {
    name: 'Heading #1',
    tags: ['h1', 'title'],
    description: 'Level 1 heading.',
    refImplementation: `/h1 Hello, world!`,
    parseTree: (c: ComponentTreeNode) => {
        let { component, classes } = c;
        component = 'h1';
        classes = classes || [];
        classes.unshift('text-3xl');
        classes.unshift('text-semibold');
        return { ...c, component, classes };
    }
};

const heading2 = {
    name: 'Heading #2',
    description: 'Level 2 heading.',
    tags: ['h2'],
    refImplementation: `/h2 Hello, world!`,
    parseTree: (c: ComponentTreeNode) => {
        let { component, classes } = c;
        component = 'h2';
        classes = classes || [];
        classes.unshift('text-2xl');
        classes.unshift('text-semibold');
        return { ...c, component, classes };
    }
};

const heading3 = {
    name: 'Heading #3',
    description: 'Level 3 heading.',
    refImplementation: `/h3 Hello, world!`,
    tags: ['h3'],
    parseTree: (c: ComponentTreeNode) => {
        let { component, classes } = c;
        component = 'h3';
        classes = classes || [];
        classes.unshift('text-xl');
        classes.unshift('text-semibold');
        return { ...c, component, classes };
    }
};

const grid = {
    name: 'Grid',
    tags: ['grid'],
    description: 'A responsive grid layouts with pre-made column numbers',
    refImplementation: `
/grid %xs gap-4
    /foreach [1...10]
        /div bg-slate-200 p-4 text-slate-700 rounded-lg text-center $item
`.trim(),
    parseTree: (c: ComponentTreeNode) => {
        let cols = '';
        switch (c.variant) {
            case 'xs':
                cols = 'grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 2xl:grid-cols-10';
                break;
            case 'sm':
                cols = 'grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8';
                break;
            default:
            case 'md':
                cols = 'grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6';
                break;
            case 'lg':
                cols = 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5';
                break;
            case 'xl':
                cols = 'grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4';
                break;
            case '2xl':
                cols = 'grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3';
                break;
        }
        
        return {
            ...c,
            component: 'div',
            classes: ['grid', cols, ...(c.classes || [])],
            variant: null,
        }
    },
    variants: ['xs', 'sm', 'md', 'lg', 'xl', '2xl'].reverse(),
};

const list = {
    tags: ['foreach'],
    name: 'For each',
    description: 'A component that iterates over a list of items.',
    refImplementation: `
/hstack gap-3
	/foreach [1...10]
		/center bg-slate-200 size-11 text-slate-700 rounded-lg $item
`.trim(),
    parseTree: (c: ComponentTreeNode) => {
        let { component, classes, props, text } = c;

        // TODO: Handle static arrays statically
        if (check.nonEmptyString(text) && isJsonLikeArray(text.trim())) {
            /*
            const values = bulletProofJSONParse(text.trim());
            return {
                component: Fragment,
                htmlComponent: 'Fragment',
                classes: [],
                props: {},
                children: values.map(...)
            */

            props.bind = bulletProofJSONParse(text.trim());
            component = ForEach as any;
            return { ...c, component, htmlComponent: 'ForEach', classes, props };
        }

        // Handle data bindings dynamically
        props.bind = getSingleVarNameFromText(text);
        component = ForEach as any;
        return { ...c, component, htmlComponent: 'ForEach', classes, props };
    },
    imports: importsRaw(['ForEach', 'DataDisplay'], '@layouts.dev/utils'),
};
registerComponent('ForEach', ForEach, '@layouts.dev/utils');
registerComponent('DataDisplay', DataDisplay, '@layouts.dev/utils');
registerComponent('DataInput', DataInput, '@layouts.dev/utils');
registerComponent('DataProvider', DataProvider, '@layouts.dev/utils');
registerComponent('DataPropsResolver', DataPropsResolver, '@layouts.dev/utils');

const data = {
    name: 'Data source',
    description: 'A component that fetches data from a remote data source.',
    tags: ['data'],
    refImplementation: `
/data $movies = mock://movies with title and poster
/div w-full overflow-x-scroll
    /hstack bg-slate-950 gap-5 p-4 text-white items-start w-fit
        /foreach $movies
            /vstack gap-3 %center justify-start
                /div w-32
                    /aspect-ratio %video-portrait overflow-hidden
                        /image $item.poster size-full object-cover rounded hover:scale-105 cursor-pointer
                /center $item.title w-full text-center text-sm
`.trim().replaceAll(/ {4}/g, '\t'),
    parseTree: (c: ComponentTreeNode) => {
        let { component, classes, text } = c;
        let props = {};
        let htmlComponent;

        if(!check.nonEmptyString(text)) {
            return null;
        }

        // New values must be like $varName = sourceEngine://...
        if (!text.trim().startsWith('$') || text.split('=').length !== 2 || text.split('://').length !== 2) {
            // console.log('Invalid data instruction: ', c);
            return null;
        }

        const [varName, sourceUrl] = text.trim().split('=').map(s => s.trim());
        let [engine, source] = sourceUrl.split('://').map(s => s.trim());

        if (!nonEmptyTrimmed(varName) || !nonEmptyTrimmed(source) || !nonEmptyTrimmed(engine)) {
            // console.log('Ignoring incomplete data instruction: ', c);
            return null;
        }

        switch(engine.toLowerCase()) {
            case 'mock': 
                component = MockupDataSource as any;
                htmlComponent = 'MockupDataSource';
                break;

            case 'mongo':
                component = MongoDataSource as any;
                htmlComponent = 'MongoDataSource';

                if (!/^[a-zA-Z./&?_-]+$/g.test(source)) {
                    // console.log('Invalid data instruction: ', c);
                    return null;
                }

                source = source.replaceAll('/', '.');
                break;

            default:
                // console.log('Ignoring unknown data engine: ', engine);
                return null;
        }

        props = { bind: varName, source };
        return { ...c, component, htmlComponent, props };
    }
};
registerComponent('MockupDataSource', MockupDataSource, '@layouts.dev/utils');
registerComponent('MongoDataSource', MongoDataSource, '@layouts.dev/utils');

// Figma components for fun
const autoLayout = {
    name: 'Auto-layout',
    description: 'A configurable stack component for people used to Figma auto-layout frames.',
    tags: ['auto-layout', 'flex'],
    refImplementation: `
/auto-layout %vertical-bottom-right p-2 gap-2 rounded-xl size-56 border border-slate-300
    /center bg-slate-200 size-1/3 rounded-lg text-slate-700 font-bold A
    /center bg-slate-200 size-1/3 rounded-lg text-slate-700 font-bold B
`.trim().replaceAll(/ {4}/g, '\t'),

    parseTree: (c: ComponentTreeNode) => {
        let { component, classes, variant: rawVariant } = c;
        component = 'div';

        classes = classes || [];
        // classes.unshift('h-full w-full');

        let variant = rawVariant;
        if (variant === 'vertical-center') {
            variant = 'vertical-center-center';
        }
        if (variant === 'horizontal-center') {
            variant = 'horizontal-center-center';
        }

        if (check.nonEmptyString(variant) && variant.split('-').length === 3) {
            const [mode, v, h] = variant.split('-');

            const hAlign = {
                left: 'start',
                center: 'center',
                right: 'end'
            };
            const vAlign = {
                top: 'start',
                center: 'center',
                bottom: 'end'
            };
            let hKey = 'justify';
            let vKey = 'items';
            if (variant.startsWith('vertical')) {
                [hKey, vKey] = [vKey, hKey];
            }

            classes.unshift(hKey + '-' + ((hAlign as any)[h] || 'start'));
            classes.unshift(vKey + '-' + ((vAlign as any)[v] || 'start'));
            if (mode === 'vertical') {
                classes.unshift('flex-col');
            }
            classes.unshift('flex');
        }
        
        // No children for <br> elements
        return { ...c, component, classes: cn(classes) };
    },
    variants: [
        'horizontal-top-left',
        'horizontal-top-center',
        'horizontal-top-right',
        'horizontal-center-left',
        'horizontal-center',
        'horizontal-center-right',
        'horizontal-bottom-left',
        'horizontal-bottom-center',
        'horizontal-bottom-right',
        'vertical-top-left',
        'vertical-top-center',
        'vertical-top-right',
        'vertical-center-left',
        'vertical-center',
        'vertical-center-right',
        'vertical-bottom-left',
        'vertical-bottom-center',
        'vertical-bottom-right'
    ]
};


// Style component placeholder
const style = {
    name: 'Style',
    description: 'A style component that applies Tailwind classes to its parent\'s descendants.',
    refImplementation: `
/style
    /h1 font-['Jersey_10'] text-8xl

/h1 Hello, styles!
    `,
    tags: ['style'],
    parseTree: () => {
        // Style is parsed before any other component, so this should never be executed
        return null;
    }
};

// Any additional components can be added following this pattern.
// Example for a custom component
/*
const linkVariants = cva(
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
    {
      variants: {
        variant: {
          default: "bg-primary text-primary-foreground hover:bg-primary/90",
          destructive:
            "bg-destructive text-destructive-foreground hover:bg-destructive/90",
          outline:
            "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
          secondary:
            "bg-secondary text-secondary-foreground hover:bg-secondary/80",
          ghost: "hover:bg-accent hover:text-accent-foreground",
          link: "text-primary underline-offset-4 hover:underline",
        },
        size: {
          icon: "h-10 w-10",
          default: "h-10 px-4 py-2 typeface-body-md",
          xs: "h-8 rounded-md px-2 typeface-body-xs",
          sm: "h-9 rounded-md px-3 typeface-body-sm",
          md: "h-10 px-4 rounded-md typeface-body-md",
          lg: "h-11 rounded-md px-8 typeface-body-lg",
          xl: "h-12 rounded-lg px-10 typeface-body-xl",
          "2xl": "h-14 rounded-lg px-12 typeface-body-2xl",
          "3xl": "h-16 rounded-xl px-14 typeface-body-3xl",
          "4xl": "h-20 rounded-xl px-16 typeface-body-4xl",
          "5xl": "h-24 rounded-2xl px-20 typeface-body-5xl",
          "6xl": "h-28 rounded-2xl px-24 typeface-body-6xl",
        },
      },
      defaultVariants: {
        variant: "link",
        size: "default",
      },
    }
  )

const link = {
    name: 'Link',
    description: 'Text link.',
    tags: ['link', 'a'],
    refImplementation: `/a Home @href="https://layouts.dev"`,
    parseTree: (c: ComponentTreeNode) => {
        let { component, classes, props, variant } = c;
        component = 'a';
        const className = cn(linkVariants({ variant: (variant || undefined) as any, size: props?.size, className: (classes || []).join(' ') }));
        classes = className.split(' ');
        console.log('Link props: ', props)
        return { ...c, component, classes, props: { ...props, className }};
    },
    variants: [
        'link',
        'default',
        'destructive',
        'outline',
        'secondary',
        'ghost',
    ]
};
*/
const components = [
    hstack,
    vstack,
    center,
    // button,
    // input,
    // textarea,
    heading1,
    heading2,
    heading3,
    grid,
    list,
    data,
    icon,
    illustration,
    image,
    logo,
    autoLayout,
    ChartComponentParser,
    ThemeComponentParser,
    PageComponentParser,
    style,
    fontPair,
    // link,
];

export default components;