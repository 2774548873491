import { nonEmptyTrimmed } from '../utils';
import htmlComponentsDoc from './html-components.json';

const typedDoc = htmlComponentsDoc as Record<string, HTMLComponentDoc>;
const htmlComponentsMap = new Map<string, HTMLComponentDoc>(Object.entries(typedDoc))

export const htmlComponentsTags = Object.keys(typedDoc).sort();

export type HTMLComponentDoc = {
    name: string;
    props: {
        [key: string]: {
            required: boolean;
            type: string;
            description: string;
        }
    };
    description: string;
    acceptsChildren: boolean;
}

export type HTMLComponentProp = {
    name: string;
    required: boolean;
    type: string;
}

export const getHTMLComponentDoc = (tagName: string): HTMLComponentDoc | undefined => {
    return htmlComponentsMap.get(tagName);
}

export const searchHtmlComponents = (search: string) : HTMLComponentDoc[] => {
    if (!nonEmptyTrimmed(search)) {
        return htmlComponentsTags.map(tag => getHTMLComponentDoc(tag)).filter(Boolean) as HTMLComponentDoc[];
    }

    const searchLower = search.toLowerCase().trim();
    return htmlComponentsTags
        .filter(tag => tag.toLowerCase().trim().includes(searchLower))
        .map(tag => getHTMLComponentDoc(tag))
        .filter(Boolean) as HTMLComponentDoc[];
}

export function getHTMLComponentProps(tagName: string): HTMLComponentProp[] {
    const doc = getHTMLComponentDoc(tagName);
    if (!doc) {
        return [];
    }

    return Object.entries(doc.props).map((entry : [tag: string, doc: { required: boolean; type: string }]) => {
        const [tag, doc] = entry;
        return {
            name: tag,
            required: doc.required,
            type: doc.type,
        };
    });
}

