import { Button } from '@/components/shadcn/ui/button';import { cn } from '@/lib/utils';
import { VariantProps, cva } from 'class-variance-authority';
import React from 'react';

const BgImageCardSizeVariants = cva(
	"justify-start items-start flex-col flex relative",
	{
		variants: {
			size: {
				"2xl": "w-full aspect-[1.927272727]",
				"xl": "w-[380px] h-[490px] max-h-[100vh]",
				"lg": "w-[358px] h-[366px] max-h-[100vh]",
				"md": "w-[358px] h-[252px] max-h-[100vh]",
				"sm": "w-[300px] h-[168px] max-h-[100vh]",
				"xs": "w-[173px] h-[98px] max-h-[100vh]",
			},
			roundness: {
                "none": "rounded-none",
                "sm": "rounded-sm",
                "md": "rounded-md",
                "lg": "rounded-lg",
                "xl": "rounded-xl",
                "2xl": "rounded-2xl",
                "3xl": "rounded-2xl",
                "full": "rounded-full",
            },
		},
		defaultVariants: {
			size: "2xl",
			roundness: "none",
		},
	}
)

const BgImageCardPaddingVariants = cva(
	"items-start flex-col flex flex-1 z-10 justify-end gap-[32px]",
	{
		variants: {
			size: {
				"2xl": "p-[40px]",
				"xl": "p-[40px]",
				"lg": "p-[40px]",
				"md": "p-[16px] w-[250px]",
				"sm": "p-[16px]",
				"xs": "p-[16px]",
			},
		},
		defaultVariants: {
			size: "2xl",
		},
	}
)

const BgImageCardTitleVariants = cva(
	"font-black",
	{
		variants: {
			size: {
				"2xl": "typeface-display-5xl text-5xl",
				"xl": "typeface-display-4xl text-4xl",
				"lg": "typeface-display-4xl text-4xl",
				"md": "typeface-display-2xl text-2xl",
				"sm": "typeface-display-md text-md",
				"xs": "typeface-display-md text-md",
			},
		},
		defaultVariants: {
			size: "2xl",
		},
	}
)

const BgImageCardDescriptionVariants = cva(
	"",
	{
		variants: {
			size: {
				"2xl": "typeface-body-md text-md",
				"xl": "typeface-body-md text-md",
				"lg": "typeface-body-md text-md",
				"md": "typeface-body-md text-md",
				"sm": "hidden",
				"xs": "hidden",
			},
		},
		defaultVariants: {
			size: "2xl",
		},
	}
)

const BgImageCardButtonVariants = cva(
	"",
	{
		variants: {
			size: {
				"2xl": "",
				"xl": "",
				"lg": "ml-auto z-10 bottom-[16px] right-[16px]",
				"md": "absolute z-10 bottom-[16px] right-[16px]",
				"sm": "hidden",
				"xs": "hidden",
			},
		},
		defaultVariants: {
			size: "2xl",
		},
	}
)

interface BgImageCardProps extends VariantProps<typeof BgImageCardSizeVariants> {
	image: string;
	title: string;
	description: string;
	label: string;
}

export default function BgImageCard({ size, roundness, image, title, description, label }: BgImageCardProps) {
	let button_size : any = "default";
	if (size === "2xl") {
		button_size = "lg";
	} else if (size === "lg" || size === "xl") {
		button_size = "default";
	} else if (size === "md") {
		button_size = "sm";
	}

	let card_roundness : any = "none";
	if (roundness) {
		card_roundness = roundness;
	}

    return (
        <div className={cn(BgImageCardSizeVariants({ size, roundness }))} >
			<div className={cn(BgImageCardPaddingVariants({ size }))} >
				<div className="justify-start items-start flex-col flex gap-[8px] text-white" >
					<h1 className={cn(BgImageCardTitleVariants({ size }))} >
						{title}
					</h1>
					<p className={cn(BgImageCardDescriptionVariants({ size }))} >
                        {description}
					</p>
				</div>
				<div className={cn(BgImageCardButtonVariants({ size }))} >
					<div className='flex flex-row gap-4'>
						<Button className="gap-2" size={button_size} variant="default" >
							{label}
						</Button>
					</div>
				</div>
			</div>
			<img
                alt="coffee"
                className={"absolute inset-0 h-full w-full object-cover z-0 overflow-hidden rounded-" + card_roundness}
                sizes="(max-width: 200px) 200px, (max-width: 400px) 400px, (max-width: 800px) 800px, 4480px"
                src={image}
            />
		</div>
    );
}