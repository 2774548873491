import { useClerk } from "@clerk/clerk-react";
import { useCallback } from "react";
import { signOut as firebaseSignOut, getAuth } from "firebase/auth";
import { firebase } from "@/initializers/firebase";

export default function useAuth() {
    const { signOut: clerkSignOut } = useClerk();
    const auth = getAuth(firebase);
    const href = window.location.href;

    const signOut = useCallback(() => {
        firebaseSignOut(auth).then(() => {
            return clerkSignOut();
        })
        .then(() => {
            window.location.href = href;
        })
        .catch(console.error);
    }, [clerkSignOut, auth, href]);

    return {
        signOut,
    }
}