import { ComponentParser, ComponentTreeNode } from "../../component.type";
 
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../layouts-ui/alert";
import { childrenParsers, ComponentAliasConfig, imports, setup } from "../_utils";
import { ComponentAliasesSpec, mapComponentTreeToSchema } from "@/lib/parser/parser-v3-utils";
import { ComponentType } from "react";
import { ICON_COMPONENT_HTML_TYPE } from "../../base/images";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { IllustrationOf } from "@/vendors/illustrations.dev/IllustrationOf";
 
/*
function AlertDemo() {
  return (
    <Alert>
      <Terminal className="w-4 h-4" />
      <AlertTitle>Heads up!</AlertTitle>
      <AlertDescription>
        You can add components to your app using the cli.
      </AlertDescription>
    </Alert>
  );
}
*/

const TAG = 'alert';
const TAGS = [TAG];

const ALIASES : ComponentAliasConfig[] = [
  ['alert', Alert, 'Alert'],
  ['description', AlertDescription, 'AlertDescription'],
  ['title', AlertTitle, 'AlertTitle'],
];

const VARIANTS = [
  'default',
  'destructive',
];

const SCHEMA = `
    <Alert 
      __enforceChildrenOrder 
      __textAsChildComponent="AlertTitle"
      __textChildrenAsChildComponents="AlertDescription"
    >
      <${ICON_COMPONENT_HTML_TYPE} className="w-4 h-4" />
      <div className="w-full" __enforceChildrenOrder>
        <AlertTitle />
        <AlertDescription __allowMultiple />
        <Rest />
      </div>
    </Alert>
`;

const COMPONENTS_MAP : ComponentAliasesSpec = {
  Alert: {
    component: Alert,
    htmlComponent: 'Alert',
    aliases: ['alert', Alert, 'Alert'],
  },
  AlertDescription: {
    component: AlertDescription,
    htmlComponent: 'AlertDescription',
    aliases: ['alert-description', AlertDescription, 'AlertDescription', 'description'],
  },
  AlertTitle: {
    component: AlertTitle,
    htmlComponent: 'AlertTitle',
    aliases: ['alert-title', AlertTitle, 'AlertTitle', 'title'],
  },
  Icon: {
    component: IllustrationOf as ComponentType,
    htmlComponent: ICON_COMPONENT_HTML_TYPE,
    aliases: ['icon', 'Icon', 'alert-icon', IllustrationOf, 'IllustrationOf', ICON_COMPONENT_HTML_TYPE],
  },
};

const AlertComponentParser : ComponentParser = {
  name: 'Alert',
  description: 'Displays a callout for user attention',
  tags: ['alert'],
refImplementation: `
/alert %default Heads up!
    /icon Alert circle
    /text There's something very important you should know.
  `.trim(),


  childrenParsers: childrenParsers(ALIASES, TAGS),

  parseTree: (c: ComponentTreeNode): ComponentTreeNode => {
    return mapComponentTreeToSchema(SCHEMA, c, COMPONENTS_MAP);
  },
  imports: imports(['Alert', 'AlertDescription', 'AlertTitle'], 'alert'),
  setup: setup('alert'),
  variants: VARIANTS
};

registerShadcnComponent({
        Alert,
        AlertDescription,
        AlertTitle,
    },
    'alert',
);

export default AlertComponentParser;
