import React from "react";
import { ArrowsChevronRight } from "@/assets/ArrowChevronRight";
import { ReactComponent as Logo } from "@/assets/logo.svg";

export function DefaultCheatSheet() {
  return (
    <div className="flex flex-col items-start relative bg-[#ffffff] w-full min-h-screen pb-20">
      <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]">
        <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]">
          <div className="flex items-center gap-[10px] p-[16px] relative self-stretch w-full flex-[0_0_auto] border-b [border-bottom-style:solid] border-[#1717171f]">
            <div className="inline-flex items-center gap-[6px] relative flex-[0_0_auto]">
              <Logo
                className="relative w-[62.31px] h-[75.71px] mt-[-10.86px] mb-[-42.86px] ml-[-16.00px]"
                alt="Frame"
              />
              <div className="relative w-fit mt-[-1.00px] [font-family:'Onest',Helvetica] font-semibold text-neutral-900 text-[16px] tracking-[0] leading-[22px] whitespace-nowrap">
                Layouts.dev
              </div>
            </div>
            <div className="inline-flex items-center justify-center gap-[10px] px-[8px] py-[4px] relative flex-[0_0_auto] bg-variable-collection-primary-BG-duplicate rounded-[6px]">
              <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-medium text-[#ed5f00] text-[12px] tracking-[0] leading-[17px] whitespace-nowrap">
                Private Beta
              </div>
            </div>
          </div>
          <div className="flex items-start gap-[4px] p-[16px] relative self-stretch w-full flex-[0_0_auto] border-b [border-bottom-style:solid] border-[#1717171f]">
            <div className="inline-flex items-start relative flex-[0_0_auto]">
              <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-medium text-[#1717175c] text-[12px] tracking-[0] leading-[17px] whitespace-nowrap">
                Getting Started
              </div>
              <ArrowsChevronRight className="!relative !w-[16px] !h-[16px]" />
            </div>
            <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-medium text-[#5b5e66] text-[12px] tracking-[0] leading-[17px] whitespace-nowrap">
              Cheat Sheet
            </div>
          </div>
          <div className="flex flex-col items-start gap-[6px] p-[16px] relative self-stretch w-full flex-[0_0_auto]">
            <div className="inline-flex flex-col items-start gap-[6px] relative flex-[0_0_auto]">
              <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-medium text-[#ed5f00] text-[12px] tracking-[0] leading-[17px] whitespace-nowrap">
                Getting Started
              </div>
              <div className="relative w-fit [font-family:'Onest',Helvetica] font-semibold text-neutral-900 text-[18px] tracking-[0] leading-[24px] whitespace-nowrap">
                Cheat Sheet
              </div>
            </div>
            <p className="[font-family:'Inter',Helvetica] font-normal text-variable-collection-grey text-[16px] tracking-[0] leading-[22px] relative self-stretch">
              Layouts is like a playground for Tailwind.
            </p>
            <p className="[font-family:'Inter',Helvetica] font-normal text-variable-collection-grey text-[16px] tracking-[0] leading-[22px] relative self-stretch">
              You write something on the left and it displays on the right. That simple.
            </p>
            <p className="[font-family:'Inter',Helvetica] font-normal text-variable-collection-grey text-[16px] tracking-[0] leading-[22px] relative self-stretch">
              The only difference is that we use a more concise syntax than HTML to improve DX (developer experience).
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start gap-[24px] p-[16px] w-full flex-[0_0_auto] relative self-stretch">
          <div className="flex flex-col items-start gap-[6px] relative self-stretch w-full flex-[0_0_auto]">
            <div className="relative self-stretch mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-variable-collection-indigo text-[14px] tracking-[0] leading-[20px]">
              Instead of writing:
            </div>
            <div className="flex items-start gap-[10px] p-[12px] relative self-stretch w-full flex-[0_0_auto] bg-black rounded-[8px]">
              <p className="relative flex-1 mt-[-1.00px] [font-family:'JetBrains_Mono',Helvetica] font-normal text-white text-[12px] tracking-[0] leading-[17px]">
                &lt;div class=&#34;bg-indigo-100&#34; &gt;
                <br /> &lt;button&gt;
                <br />
                &nbsp;&nbsp;Hello
                <br /> &lt;/button&gt;
                <br />
                &lt;/div&gt;
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start gap-[6px] relative self-stretch w-full flex-[0_0_auto]">
            <div className="relative self-stretch mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-variable-collection-indigo text-[14px] tracking-[0] leading-[20px]">
              You write:
            </div>
            <div className="flex items-start gap-[10px] p-[12px] relative self-stretch w-full flex-[0_0_auto] bg-black rounded-[8px]">
              <div className="relative flex-1 mt-[-1.00px] [font-family:'JetBrains_Mono',Helvetica] font-normal text-white text-[12px] tracking-[0] leading-[17px]">
                /div bg-indigo-100 <br />
                &nbsp;&nbsp;/button Hello
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start gap-[12px] p-[16px] relative self-stretch w-full flex-[0_0_auto]">
        <div className="flex flex-col items-start gap-[6px] relative self-stretch w-full flex-[0_0_auto]">
          <div className="flex h-[17px] items-start justify-between relative self-stretch w-full">
            <div className="relative w-fit mt-[-1.00px] mb-[-4.00px] [font-family:'Inter',Helvetica] font-medium text-variable-collection-indigo text-[16px] tracking-[0] leading-[22px] whitespace-nowrap">
              Keyboard actions
            </div>
            <div className="relative w-fit mt-[-1.00px] mb-[-4.00px] [font-family:'Inter',Helvetica] font-medium text-variable-collection-indigo text-[16px] tracking-[0] leading-[22px] whitespace-nowrap">
              Description
            </div>
          </div>
          <img
            className="relative self-stretch w-full h-px object-cover"
            alt="Line"
            src="https://c.animaapp.com/cgbhOQxn/img/line-2.svg"
          />
        </div>
        <div className="flex items-start justify-between relative self-stretch w-full flex-[0_0_auto]">
          <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-medium text-[#2d41aa] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
            /div
          </div>
          <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-variable-collection-grey text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
            creates a container
          </div>
        </div>
        <img
          className="relative self-stretch w-full h-px object-cover"
          alt="Line"
          src="https://c.animaapp.com/cgbhOQxn/img/line-2.svg"
        />
        <div className="flex items-start justify-between relative self-stretch w-full flex-[0_0_auto]">
          <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-medium text-[#2d41aa] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
            /button
          </div>
          <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-variable-collection-grey text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
            creates a button
          </div>
        </div>
        <img
          className="relative self-stretch w-full h-px object-cover"
          alt="Line"
          src="https://c.animaapp.com/cgbhOQxn/img/line-2.svg"
        />
        <div className="flex items-start justify-between relative self-stretch w-full flex-[0_0_auto]">
          <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-medium text-[#2d41aa] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
            /h1
          </div>
          <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-variable-collection-grey text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
            Insert H1 text
          </div>
        </div>
        <img
          className="relative self-stretch w-full h-px object-cover"
          alt="Line"
          src="https://c.animaapp.com/cgbhOQxn/img/line-2.svg"
        />
        <div className="flex items-start justify-between relative self-stretch w-full flex-[0_0_auto]">
          <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-medium text-[#2d41aa] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
            /h2
          </div>
          <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-variable-collection-grey text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
            Insert H2 text
          </div>
        </div>
        <img
          className="relative self-stretch w-full h-px object-cover"
          alt="Line"
          src="https://c.animaapp.com/cgbhOQxn/img/line-2.svg"
        />
        <div className="flex items-start justify-between relative self-stretch w-full flex-[0_0_auto]">
          <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-medium text-[#2d41aa] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
            /p
          </div>
          <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-variable-collection-grey text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
            Insert body text
          </div>
        </div>
      </div>
    </div>
  );
};
