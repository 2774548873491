const firebaseConfig = {
    apiKey: "AIzaSyBHJPJVFFpJdbNFVrsRwt12S_j56j4SfIM",
    authDomain: "layouts-466b8.firebaseapp.com",
    databaseURL: "https://layouts-466b8-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "layouts-466b8",
    storageBucket: "layouts-466b8.appspot.com",
    messagingSenderId: "757976924423",
    appId: "1:757976924423:web:98a87df09daad1a8d905a2",
    measurementId: "G-4DXCXCYLMC"
}

export { firebaseConfig };