
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { imports, parseBooleanInputComponent, radixComponentDoc, setup } from "../_utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { RadioGroup, RadioGroupItem } from "@/components/shadcn/ui/radio-group";
import { ComponentType } from "react";
import { groupAdjacentComponentsOfTypes } from "../../parsing-pipeline";
import check from "@/vendors/check";
import uniqid from 'uniqid';
import { Label } from "@/components/shadcn/ui/label"

const RADIO_GROUP_LABEL = "___%%%@@@<<<$$$!!!§§§&&&RADIO_GROUP_ITEM&&&§§§!!!$$$>>>@@@%%%___";

function hasRadioItemDescendant (c: ComponentTreeNode) {
  if (!check.nonEmptyObject(c)) {
    return false;
  }

  if (c.component === (RadioGroup as any) || c.component === 'radio-group' ) {
    return false;
  }

  if (c.component === (RadioGroupItem as any) || c.component === 'radio') {
    return true;
  }

  if (check.nonEmptyArray(c.children)) {
    return c.children.some(hasRadioItemDescendant);
  }

  return false;
}

function groupAdjacentRadioItems (
  componentTypesToWrap: (string | ComponentType)[], 
  wrapperComponent: string | ComponentType,
  wrapperComponentName: string,
) {
  return groupAdjacentComponentsOfTypes(
      componentTypesToWrap, 
      wrapperComponent,
      wrapperComponentName,
      (wc: ComponentTreeNode[]) => (addValuesToRadioItemChildren({
        component: wrapperComponent,
        htmlComponent: wrapperComponentName,
        classes: [],
        props: {},
        children: wc,
      })),
      false, 
      false, 
      hasRadioItemDescendant
  );
}

export function addValuesToRadioItemChildren(c: ComponentTreeNode, indexRef: { index: number, values: string[] } = { index: 0, values: [] }, labelDict: { [key: string]: string } = {}) : ComponentTreeNode {
  if (!check.nonEmptyObject(c)) {
    return c;
  }

  if (c.component === (RadioGroupItem as any) || c.component === 'radio' || c.htmlComponent === 'RadioGroupItem') {
    indexRef.index++;
    let value = c.props?.value;

    if (!check.nonEmptyString(value)) {
        let idBasedValue : string | null = 'item_' + indexRef.index;
        if (check.nonEmptyString(c.props?.id)) {
            let baseValue = c.props.id.slice(RADIO_GROUP_LABEL.length + 9)
            let repeatIndex = 0;
            idBasedValue = baseValue;
            while (indexRef.values.includes(idBasedValue)) {
                repeatIndex++;
                idBasedValue = baseValue + '_' + repeatIndex;
            }
        }

        value = idBasedValue;
    }

    indexRef.values.push(value);

    if (c.props.id?.startsWith(RADIO_GROUP_LABEL)) {
      const key : string = c.props.id.slice(0, RADIO_GROUP_LABEL.length + 8)
      labelDict[key] = "radiogroupitem-" + value;
    }

    return {
      ...c,
      props: {
        ...c.props,
        value,
        id : c.props.id?.startsWith(RADIO_GROUP_LABEL) ? "radiogroupitem-" + value : c.props.id,
      },
    };
  }

  if (c.component === (Label)) {
    let id = c.props.htmlFor.slice(0, RADIO_GROUP_LABEL.length + 8)
    if (labelDict[id]) {
      return {
        ...c,
        props: {
          ...c.props,
          htmlFor: labelDict[id],
        },
        children: (check.nonEmptyArray(c.children)) ? c.children.map((child) => addValuesToRadioItemChildren(child, indexRef, labelDict)) : null,
      };
    }
  }

  if (check.nonEmptyArray(c.children)) {
    return {
      ...c,
      children: c.children.map((child) => addValuesToRadioItemChildren(child, indexRef, labelDict)),
    };
  }

  return c;
}

// Finds a radiogroup item in the given tree and overrides value on it
const overrideValueOnRadioGroupItem = (c: ComponentTreeNode, value: any) : ComponentTreeNode => {
    if (!check.nonEmptyString(value)) {
        return c;
    }

    if (c.component === (RadioGroupItem as any) || c.htmlComponent === 'RadioGroupItem' || c.component === 'radio') {
        return {
            ...c,
            props: {
                ...c.props,
                value,
            },
        };
    }

    if (check.nonEmptyArray(c.children)) {
        return {
            ...c,
            children: c.children.map((child) => overrideValueOnRadioGroupItem(child, value)),
        };
    }

    return c;
}


const RadioComponentParser : ComponentParser = {
  name: 'Radio',
  description: 'A radio item.',
  tags: ['radio'],
  refImplementation: `
/radio Vegan
/radio Vegetarian
/radio Omnivore
  `.trim(),
  parseTree: (c: ComponentTreeNode) => {
      const uniqueIdLong = uniqid()
      const uniqueId = uniqueIdLong.slice(uniqueIdLong.length - 8);

      // Override id if value is set 
      let id = c.props?.id;

      if (!check.nonEmptyString(id) && check.nonEmptyString(c.props?.value)) {
          id = RADIO_GROUP_LABEL + uniqueId + c.props.value;
      }
      const c2 = {
          ...c,
          props: {
            ...c.props,
            id,
          },
      }

      // Parse component like a boolean, to create label if any
      // FIXME: should be better factorized. Too many dependencies
      const out = parseBooleanInputComponent(RadioGroupItem as any, 'RadioGroupItem', c2, false, false, id || (RADIO_GROUP_LABEL + uniqueId));
      const { value, defaultValue, ...outProps } = out.props;
      return overrideValueOnRadioGroupItem(out, c.props.value || outProps.value || id);
  }, 
  
  // This component makes sure that it's always wrapped into a RadioGroup
  wrapParent: (c: ComponentTreeNode) => {
      // Don't wrap if component is already a radio group
      if (c.component === RadioGroup || c.htmlComponent === 'RadioGroup' || c.component === 'radio-group') {
        return c;
      }

      // Wrap all adjacent child radio items radio groups
      const newChildren : any[] = groupAdjacentRadioItems(
        [RadioGroupItem as any, 'RadioGroupItem', 'radio'], 
        RadioGroup as any, 
        'RadioGroup',
      )(c.children || []);
      console.log('Radio parent wrapper: ', {
        prevChildren: c.children,
        newChildren,
      });


      // Return new component
      return {
          ...c,
          children: newChildren,
      };
  },
  imports: [
    ...imports(['RadioGroup', 'RadioGroupItem'], 'radio-group'),
  ],
  setup: [
    ...setup('radio-group'),
  ],
  variants: [],
  doc: {
      props: radixComponentDoc('RadioGroup.Item').props,
  }
};

registerShadcnComponent({
        RadioGroupItem: RadioGroupItem as ComponentType<any>,
    },
    'radio-group',
);

export default RadioComponentParser;
