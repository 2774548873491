import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "@/components/shadcn/ui/dialog"
import { ComponentParser, ComponentTreeNode } from "../../component.type"
import { nonEmptyTrimmed } from "@/lib/utils"
import { childrenParsers, ComponentAliasConfig, imports, parentWrapperForTriggerableComponent, parseCardLikeComponent, radixComponentDoc, setup } from "../_utils"
import { ComponentType } from "react"
import check from "@/vendors/check"
import { withTextAsChildOfType } from "@/lib/parser/util"
import { registerShadcnComponent } from "@/lib/parser/importsRegistry"

/*
export function DialogDemo() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Edit Profile</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit profile</DialogTitle>
          <DialogDescription>
            Make changes to your profile here. Click save when you're done.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Name
            </Label>
            <Input
              id="name"
              defaultValue="Pedro Duarte"
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Username
            </Label>
            <Input
              id="username"
              defaultValue="@peduarte"
              className="col-span-3"
            />
          </div>
        </div>
        <DialogFooter>
          <Button type="submit">Save changes</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
*/

const InnerContent : ComponentType<any> = ({ children }) => {
  return <>{children}</>;
}

Dialog.displayName = 'Dialog';
DialogTrigger.displayName = 'DialogTrigger';
DialogContent.displayName = 'DialogContent';

const TAG = 'dialog';
const ALIASES: ComponentAliasConfig[] = [
  ['content', InnerContent, 'DialogInnerContent'],
  ['footer', DialogFooter, 'DialogFooter'],
  ['close', DialogClose, 'DialogClose', radixComponentDoc('Dialog.Close')],
  ['title', DialogTitle, 'DialogTitle', radixComponentDoc('Dialog.Title')],
  ['description', DialogDescription, 'DialogDescription', radixComponentDoc('Dialog.Description')],
];

const DialogComponentParser : ComponentParser = {
  name: 'Dialog',
  description: 'A window overlaid on either the primary window or another dialog window, rendering the content underneath inert',
  tags: ['dialog'],
  refImplementation: `
  /button %outline Edit Profile
    /dialog sm:max-w-[425px]
      /header
        /title Edit profile
        /description Make changes to your profile here. Click save when you're done.
      /content 
        /div grid gap-4 py-4
          /div grid grid-cols-4 items-center gap-4
            /label Name text-right
            /input @defaultValue="Alex McKee" col-span-3
          /div grid grid-cols-4 items-center gap-4
            /label Username text-right
            /input @defaultValue="@McKeeAlex_" col-span-3
      /footer
        /close
          /button @type=submit Save changes
  `, 

  childrenParsers: [
    {
        name: 'DialogHeader',
        tags: ['header', 'dialog-header'],
        description: 'The header of the dialog',
        parseTree: (c: ComponentTreeNode) => {
          return withTextAsChildOfType(DialogTitle, 'DialogTitle')({
            ...c,
            component: DialogHeader,
            htmlComponent: 'DialogHeader',
          });
        },
    },
    ...childrenParsers(ALIASES, TAG),
  ],

  parseTree: (c: ComponentTreeNode) : ComponentTreeNode => {
    const parsed = parseCardLikeComponent(c, {
      component: [DialogContent, 'DialogContent'],
      header: [DialogHeader, 'DialogHeader'],
      content: [InnerContent, 'DialogInnerContent'],
      footer: [DialogFooter, 'DialogFooter'],
      title: [DialogTitle, 'DialogTitle'],
      description: [DialogDescription, 'DialogDescription'],
    });

    // Filter out InnerContent from children
    parsed.children = (parsed.children || []).map(child => {
      if (child.component === InnerContent) {
        if (nonEmptyTrimmed(child.props?.className) || (check.nonEmptyArray(child.classes) && child.classes.some(nonEmptyTrimmed))) {
          return {
            ...child,
            component: 'div',
            htmlComponent: 'div',
          };
        }
        
        return child.children || [];
      }
      return child;
    }).flat();

    return parsed;
  },

  wrapParent: parentWrapperForTriggerableComponent(
    [Dialog, 'Dialog'], 
    [DialogTrigger, 'DialogTrigger'], 
    [DialogContent, 'DialogContent'],
  ),

  imports: imports(['Dialog', 'DialogTrigger', 'DialogContent', 'DialogHeader', 'DialogTitle', 'DialogDescription', 'DialogFooter', 'DialogClose'], 'dialog'),
  setup: setup('dialog'),
  variants: [],
  doc: {
      props: radixComponentDoc('Dialog.Root').props,
  }
};

registerShadcnComponent({
        Dialog,
        DialogContent,
        DialogDescription,
        DialogFooter,
        DialogHeader,
        DialogTitle,
        DialogTrigger,
        DialogClose,
    },
    'dialog',
);

export default DialogComponentParser;
