// FIXME: Should be defined elsewhere ?
export type Asset = {
    name: string;
    url: string;
    type: string;
    data: any;
}

export type ParserConfigTarget = 'react-component' | 'create-react-app' | 'nextjs' | 'react-native' | 'react-native-runtime' | 'html' | 'test';

export type ComponentParserConfig = {
    target: ParserConfigTarget,
    imageExportMode: 'url' | 'asset' | 'component',
    registerAsset: (asset: Partial<Asset>, fetcher: (a: Partial<Asset>) => Promise<Partial<Asset>>) => void,
}

export function getParserConfig(config: ComponentParserConfig | ParserConfigTarget) : ComponentParserConfig {
    if (typeof config === 'string') {
        let target = config;

        return {
            target,
            imageExportMode: (target === 'react-component' || target === 'test') ? 'component' : 'url',
            registerAsset: (asset, fetcher) => {
                console.warn('Attempting to register asset with unimplemented registerAsset():', asset);
            }
        }
    }
    return config;
}