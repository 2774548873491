
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { imports, isTextComponent, normalizeTextComponent, radixComponentDoc, setup } from "../_utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { RadioGroup, RadioGroupItem } from "@/components/shadcn/ui/radio-group";
import { ComponentType } from "react";
import { getSingleVarNameFromText, removeEmptyProps, removeTextFromChildren } from "@/lib/parser/util";
import { DataInput } from "@/vendors/data/data-v2";
import RadioComponentParser, { addValuesToRadioItemChildren } from "./radio";
import { cn } from "@/lib/utils";
import { parseInputOrCommandText } from "../../layouts-lang";
import { normalize } from "path";
import check from "@/vendors/check";
import { getParserConfig } from "@/lib/parser/parser-config";

const RadioGroupComponentParser : ComponentParser = {
  name: 'Radio Group',
  description: 'A radio group.',
  tags: ['radio-group'],
  refImplementation: `
  /radio-group @defaultValue=vegan
      /radio Vegan
      /radio Vegetarian
      /radio Omnivore
  `.trim(),
  parseTree: (c: ComponentTreeNode) => {
      console.log("RadioComponentParser: ", JSON.stringify(c));

      let txtVar = getSingleVarNameFromText(c.text);
      let wrap = (w: ComponentTreeNode) => w;
      const props = { ...c.props };

      if (txtVar) {
          wrap = (w: ComponentTreeNode) => ({
              component: DataInput as any,
              htmlComponent: 'DataInput',
              props: {
                  bind: txtVar,
              },
              classes: [],
              variant: null,
              children: [w],
          });
      }

      props.className = cn(c.classes, c.props?.className);
      let defaultValueIndex = -1;

      const new_children = c.children?.map((child, i) => {
        if (!isTextComponent(child)) {
          return child;
        }

        const normalizedText = normalizeTextComponent(child)?.text;
        if (!normalizedText || !check.nonEmptyString(normalizedText)) {
          return child;
        }

        const result = parseInputOrCommandText(child.text, {radio: true});
        if (!result || result.type !== 'radio') {
          return child;
        }
        
        if (RadioComponentParser && RadioComponentParser.parseTree) {
          const new_child = (RadioComponentParser.parseTree as any)({
            ...child,
            component: "radio",
            htmlComponent: undefined,
            text: result.label,
            classes: [],
            children: null,
            variant: null,
          });

          if (result.checked === true) {
            defaultValueIndex = i;
          }

          return new_child;
        }
        return child;
      });

      const componentWithValues = addValuesToRadioItemChildren({
        ...c,
        component: RadioGroup,
        htmlComponent: 'RadioGroup',
        variant: null,
        text: null,
        props: removeEmptyProps(props),
        // Note: /radio children will be handled by their own parser
        children: removeTextFromChildren(c.text, new_children || []),
      })

      function getChildrenDefaultValue(children: any[], index: number) {
        if (check.nonEmptyArray(children)) {
          const child = children[index];
          if (check.nonEmptyObject(child) && check.nonEmptyObject(child.props)) {
            return child.children[0].props.value;
          }
        }
        return null;
      }

      if (componentWithValues.props?.defaultValues === undefined && defaultValueIndex != -1) {
        const defaultValue = getChildrenDefaultValue(componentWithValues.children || [], defaultValueIndex);

        if (defaultValue) {
          componentWithValues.props.defaultValue = defaultValue;
        }
      }

      return wrap(componentWithValues);
  },
  
  imports: [
    ...imports(['RadioGroup'], 'radio-group'),
  ],
  setup: [
    ...setup('radio-group'),
  ],
  variants: [],
  doc: {
    props: radixComponentDoc('RadioGroup.Root').props,
  },
};

registerShadcnComponent({
        RadioGroup: RadioGroup as ComponentType<any>,
    },
    'radio-group',
);

export default RadioGroupComponentParser;
