import { Theme } from "@/components/layouts-ui/Theme";
import { ComponentParser, ComponentTreeNode } from "../component.type";
import { ComponentAliasConfig, childrenParsers, imports, importsRaw, isTextComponent, normalizeTextComponent } from "../shadcn/_utils";
import { themePresets } from "@/vendors/theme/presets";

const Palette = () => null;

const TAG = 'theme';
const ALIASES : ComponentAliasConfig[] = [
  ['palette', Palette, 'Palette'],
];


  /*
   background: '--background',
    foreground: '--foreground',
    muted: '--muted',
    mutedForeground: '--muted-foreground',
    card: '--card',
    cardForeground: '--card-foreground',
    popover: '--popover',
    popoverForeground: '--popover-foreground',
    border: '--border',
    input: '--input',
    primary: '--primary',
    primaryForeground: '--primary-foreground',
    secondary: '--secondary',
    secondaryForeground: '--secondary-foreground',
    accent: '--accent',
    accentForeground: '--accent-foreground',
    destructive: '--destructive',
    destructiveForeground: '--destructive-foreground',
    ring: '--ring',
    */

const ThemeComponentParser : ComponentParser = {
    name: 'Theme',
    description: 'Defines the theme of the page',
    tags: ['theme'],
  refImplementation: `
/theme
    @colorScheme="light" // Light or dark mode
    @radius="0.5rem" // Border radius for card, input, and buttons
    @palette="mediterranean turquoise" // AI-generated color-palette if needed
    @background="primary-50" // Color overrides
    @accent="#00ff00"
    // @foreground="slate-900"
    // @muted="slate-100"
    // @mutedForeground="slate-800"
    // @card="white"
    // @cardForeground="slate-900"
    // @popover="white"
    // @popoverForeground="slate-900"
    // @border="slate-200"
    // @input="slate-100"
    // @primary="blue-600"
    // @primaryForeground="white"
    // @secondary="blue-200"
    // @secondaryForeground="blue-800"
    // @accent="green-800"
    // @accentForeground="white"
    // @destructive="red-600"
    // @destructiveForeground="white"
    // @ring="blue-600"
    `.trim(),

    childrenParsers: childrenParsers(ALIASES, TAG),

    parseTree: (c: ComponentTreeNode): ComponentTreeNode => {
        let palette : string | null = null;
        const paletteComponent = (c.children || []).find(c => c.component === Palette);
        if (paletteComponent) {
            palette = paletteComponent.text || paletteComponent.children?.filter(isTextComponent).map(normalizeTextComponent).filter((c:any) => !!c).map((c: ComponentTreeNode) => c.text).join(' ');
        }

        return {
            ...c,
            component: Theme,
            htmlComponent: 'Theme',
            children: [],
            props: {
              preset: c.variant || 'default',
              ...(c.props || {}),
              palette: c.props.palette || palette || undefined,
            }
        }
    },
    imports: importsRaw(['Theme'], '@layouts.dev/utils'),
    setup: ['npm install --save @layouts.dev/utils'],
    variants: Object.keys(themePresets),
  };
  
  export { ThemeComponentParser };