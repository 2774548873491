/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ArrowsChevronRight = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="white" fillOpacity="0.01" height="16" width="16" />
      <path
        clipRule="evenodd"
        d="M6.56896 3.34408C6.78384 3.14262 7.12136 3.15351 7.32282 3.3684L11.3228 7.63507C11.5151 7.84021 11.5151 8.15945 11.3228 8.3646L7.32282 12.6312C7.12136 12.8462 6.78384 12.8571 6.56896 12.6556C6.35407 12.4541 6.34318 12.1166 6.54464 11.9018L10.2027 7.99984L6.54464 4.09794C6.34318 3.88305 6.35407 3.54553 6.56896 3.34408Z"
        fill="#171717"
        fillOpacity="0.36"
        fillRule="evenodd"
      />
    </svg>
  );
};
