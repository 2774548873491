/*
<p className="text-sm text-muted-foreground">
  Press{" "}
  <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
    <span className="text-xs">⌘</span>J
  </kbd>
</p>
*/

import { cn, nonEmptyTrimmed } from "@/lib/utils";
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { parseShortcutText } from "../../layouts-lang";
import check from "@/vendors/check";
import { selectVariant } from "@/lib/parser/util";
import { isTextComponent, normalizeTextComponent } from "../_utils";
import arrayUnique from "array-unique";

const VARIANTS = [
  'md',
  'lg',
  'xl',
  'xs',
  'sm',
].reverse();

const KeyboardComponentParser : ComponentParser = {
  tags: ['kbd', 'key', 'keyboard', 'shortcut'],
  name: 'Keyboard',
  description: 'Represents a keyboard key',
  refImplementation: '/kbd Cmd',
  parseTree: (c: ComponentTreeNode) => {

    

    const variant = selectVariant(VARIANTS, c.variant);

    let sizeClasses = '';

    switch (variant) {
      case 'xs': {
        sizeClasses = 'h-4 gap-1 px-1 text-xs font-medium rounded'
        break;
      }

      case 'sm': {
        sizeClasses = 'h-5 gap-1 px-1.5 text-xs font-medium rounded'
        break;
      }

      case 'md': {
        sizeClasses = 'h-6 gap-1 px-2 text-sm font-medium rounded'
        break;
      }

      case 'lg': {
        sizeClasses = 'h-7 gap-1 px-2.5 text-md font-medium rounded'
        break;
      }

      case 'xl': {
        sizeClasses = 'h-8 gap-1 px-3 text-md font-medium rounded'
        break;
      }
    }

    let childrenTexts = arrayUnique([...(c.children || [])].filter(isTextComponent).map(c => (normalizeTextComponent(c) || {}).text).filter(nonEmptyTrimmed));
    childrenTexts = childrenTexts.filter(child => check.nonEmptyString(child) && child !== c.text);
    const newChildren = [parseShortcutText(c.text || ''), ...childrenTexts];
    const parsedChildren = check.nonEmptyArray(newChildren) ? [ newChildren.join(' ') ] : [];

    return {
          ...c,
          component: 'kbd',
          htmlComponent: 'kbd',
          classes: cn(
            'w-fit',
            'pointer-events-none', 
            'inline-flex', 
            'select-none', 
            'items-center', 
            'border', 
            'bg-muted', 
            'font-mono', 
            'text-muted-foreground', 
            'opacity-100',
            sizeClasses,
            c.classes,
          ).split(' '),
          text: null,
          children: parsedChildren,
          variant: null,
    };
  },
  imports: [],
  setup: [],
  variants: VARIANTS,

  doc: {
    variants: {
      'md': 'Default size',
      'lg': 'Large size',
      'xl': 'Extra large size',
      'sm': 'Small size',
      'xs': 'Extra small size',
    }
  }
};

export default KeyboardComponentParser;
