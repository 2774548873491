import check from "@/vendors/check";
import { extractVarNamesFromText, getComponentName, getSingleVarNameFromText, mergeClasses, mergeProps, removeEmptyProps, withTextAsFirstChild } from "../../util";
import { ComponentParser, ComponentParserFunction, ComponentTreeNode } from "../component.type";
import { ComponentType } from "react";
import { cn, nonEmptyTrimmed } from "@/lib/utils";
import { parseInputOrCommandText, parseShortcutText } from "../layouts-lang";
import { nonEmptyArray } from "check-types";
import { toBoolean } from "@/lib/type-casts";
import { DataDisplay, DataInput } from "@/vendors/data/data-v2";
import { Label } from "@/components/shadcn/ui/label";
import { ParserConfigTarget } from "../../parser-config";
import { RadixPropDocumentation, radixSubDoc } from "../../doc/radix/radix-docs";

export function setup(component: string) : string[] {
    return [
        `npx shadcn-ui@latest init`,
        `npx shadcn-ui@latest add ${component}`,
    ];
}

export function imports(components: string | string[], from: string) : string[] {
    return importsRaw(components, `@/components/ui/${from}`);
}

export function importsRaw(components: string | string[], from: string) : string[] {
    return [
        `import * as React from "react"`,
        `import { ${(check.string(components) ? [components] : components).join(', ')} } from "${from}"`,
    ];
}

export type ComponentDoc = {
    description?: string,
    props?: { [propName:string]: Partial<RadixPropDocumentation> },
}

export type ComponentAliasConfig = [alias: string | string [], component: ComponentType<any>, componentName: string, doc?: ComponentDoc, parserFunction?: ComponentParserFunction];

export function radixComponentDoc(fullComponentName: string) : ComponentDoc {
    const doc = radixSubDoc(fullComponentName);
    if (!doc) {
        return {};
    }

    return {
        description: doc.description,
        props: doc.props.reduce((acc: { [propName:string]: Partial<RadixPropDocumentation> }, prop: RadixPropDocumentation) => {
            acc[prop.name] = prop;
            return acc;
        }, {}),
    };
}

export function childrenParsers(
    aliases: ComponentAliasConfig[], 
    additionalPrefixes?: string | string[],
) : ComponentParser[] {
    if (!check.nonEmptyArray(aliases)) {
        throw new Error('Calling childrenParsers() with empty ALIASES array')
    }

    const parsers : ComponentParser[] = [];

    aliases.forEach(([alias, component, componentName, doc, parserFunction]) => {
        if (!component) {
            throw new Error('Missing component in alias : ' + JSON.stringify(alias));
        }

        const allTags : string[] = [];
        const componentAliases = check.string(alias) ? [alias] : alias;
        componentAliases.forEach((a) => {
            const keys = [a];
            if (check.nonEmptyString(additionalPrefixes)) {
                keys.push(`${additionalPrefixes}-${a}`);
            } else if (check.nonEmptyArray(additionalPrefixes)) {
                keys.push(...additionalPrefixes.map(p => `${p}-${a}`));
            }

            allTags.push(...keys);
        });

        let parseTree : ComponentParserFunction = (c: ComponentTreeNode) => (
            withTextAsFirstChild({
                ...c,
                component,
                htmlComponent: componentName || getComponentName(component),
            })
        );

        if (parserFunction) {
            parseTree = parserFunction
        }

        parsers.push({
            name: componentName,
            description: doc?.description || undefined,
            tags: allTags,
            parseTree,
            doc: {
                props: doc?.props || {},
            } 
        });
    });

    return parsers;
}

export function grabChildrenOfTypes(children: any[], types: any[]) : any[] {
    const extracted : any[] = [];
    const keysToDelete = [];

    if (!check.nonEmptyArray(children)) {
        return extracted;
    }
    if (!check.nonEmptyArray(types)) {
        return extracted;
    }

    // Extract children with the wanted types
    for (let key in children) {
        for (var type in types) {
            if (children[key]?.component === type) {
                extracted.push(children[key]);
                keysToDelete.push(key); 
            }
        }
    }

    // Remove the extracted children from the original array
    for (let key in keysToDelete) {
        delete children[key];
    }

    // Recursively extract children from the children
    for (let key in children) {
        if (check.nonEmptyArray(children[key].children)) {
            extracted.push(...grabChildrenOfTypes(children[key].children, types));
        }
    }

    return extracted;
}

export function extractChildrenByType(children: any[], types: { [key: string]: (string | ComponentType)[] }) : { [key: string]: any[] } {
    let extracted : { [key: string]: any[] }  = { rest: [] };
    Object.keys(types).forEach((type) => {
        extracted[type] = [];
    });

    if (!check.nonEmptyArray(children)) {
        return extracted;
    }

    children.forEach((child) => {
        if (!check.nonEmptyObject(child)) {
            // Child could be a text, or null, for example
            extracted.rest.push(child);
            return;
        }

        const matchingType = Object.keys(types).find((type) => types[type].some(t => child?.component === t));
        
        // Extract recursively from children
        const newChild = { ...child };
        const { rest: newChildren, ...extractedProps } = extractChildrenByType(newChild.children, types);
        newChild.children = newChildren;
        
        if (matchingType) {
            extracted[matchingType].push(newChild);
        } else {
            extracted.rest.push(newChild);
        }

        if (check.nonEmptyObject(extractedProps)) {
            Object.keys(extractedProps).forEach((key) => {
                extracted[key].push(...extractedProps[key]);
            });
        }
    });

    return extracted;
}

export function propsToComponents(rawProps: any, transforms: {[propName: string]: any}) : any[] {
    const components = [];
    const keysToDelete = [];
    const props = { ...(rawProps || {}) };
    

    for (let key in props) {
        if (transforms[key] && !!props[key]) {
            components.push(
                {
                    component: transforms[key],
                    htmlComponent: getComponentName(transforms[key]),
                    classes: [],
                    props: {},
                    children: [
                        props[key]
                    ]
                }
            );
            keysToDelete.push(key);
        }
    }

    for (let key in keysToDelete) {
        delete props[key];
    }

    return components;
}

export function filterOutIfEmpty(component: string | ComponentType) {
    return (c: ComponentTreeNode) => {
        if (c.component === component && !check.nonEmptyArray(c.children)) {
            return null;
        }

        return c;
    }
}

export function mergeShortcutComponents(comps: ComponentTreeNode[]) {
    const merged = mergeComponents(comps);

    const newText = (merged.children || []).filter(isTextComponent).map(c => normalizeTextComponent(c)?.text).filter(check.nonEmptyString).join(' ').trim();
    const newChild = parseShortcutText(newText);
    merged.children = check.nonEmptyString(newChild?.trim()) ? [newChild.trim()] : [];

    return merged;
}

export function mergeComponents(components: ComponentTreeNode[]) : ComponentTreeNode {
    const merged : ComponentTreeNode = {
        component: 'div',
        htmlComponent: 'div',
        classes: [],
        props: {},
        children: [],
    };

    if (!check.nonEmptyArray(components)) {
        return merged;
    }

    merged.component = components[0].component;
    merged.htmlComponent = components[0].htmlComponent;

    components.forEach((c) => {
        if (!check.nonEmptyObject(c)) {
            return;
        }

        (merged.classes as string []).push(...(c.classes || []));
        merged.props = { ...merged.props, ...(c.props || {}), className: cn(merged.props.className, c.props.className) };
        (merged.children as any[]).push(...(c.children || []));
    });

    merged.classes = cn(...(merged.classes || [])).split(' ');

    return merged;
}

// Merge a list of components to a component of a specific type
// All components of the same type will have their props and children merged
// All other components will be added as children
export function mergeComponentsInto(components: ComponentTreeNode[], into: { component: string | ComponentType, htmlComponent: string, [key: string]: any }, nullIfNoChildren = false) : (ComponentTreeNode | null) {
    const comps = components.map((c) => {
        if (check.nonEmptyString(c) && nonEmptyTrimmed(c)) {
            return { 
                classes: [],
                props: {},
                ...into,
                children: [c],
            }
        }
        
        if (!check.nonEmptyObject(c)) {
            return null;
        }

        if (c.component !== into.component) {
            if (c.component === 'text') {
                return { 
                    ...into,
                    classes: c.classes,
                    props: c.props,
                    children: [normalizeTextComponent(c)?.text],
                }
            }

            return { 
                classes: [],
                props: {},
                ...into,
                children: [c],
            }
        }

        return c;
    }).filter(Boolean) as ComponentTreeNode[];

    if (check.nonEmptyArray(comps)) {
        comps[0] = { ...comps[0], ...into };
    }
    
    let merged = mergeComponents(comps);
    if (nullIfNoChildren && !check.nonEmptyArray(merged.children)) {
        return null;
    }
    return { ...into, ...merged };
}

export function removeOneOccurenceOfText(children: any[], text: string) : any[] {
    if (!check.nonEmptyString(text) || !nonEmptyTrimmed(text) || !check.nonEmptyArray(children)) {
        return children;
    }

    let normalizedText = text.trim();
    let varName = getSingleVarNameFromText(normalizedText);
    if (varName) {
        normalizedText = normalizedText.replace(varName, '').trim();
    }

    let removed = false;
    return children.filter((c) => {
        if (!removed && isTextComponent(c)) {
            const normalized = normalizeTextComponent(c);
            if (normalized?.text?.trim() === text.trim() || normalized?.text?.trim() === normalizedText.trim()) {
                removed = true;
                return false;
            }
        }
        return true;
    });
}


// Parse a card-like component by isolating header, content, footer, title and description child components
export function parseCardLikeComponent(
    c: ComponentTreeNode, 
    params: {
        component: [string | ComponentType, string],
        header: [string | ComponentType, string],
        content: [string | ComponentType, string],
        footer: [string | ComponentType, string],
        title: [string | ComponentType, string],
        description: [string | ComponentType, string],
    }
) : ComponentTreeNode {
        const [ OUT_COMP, OUT_COMP_NAME ] = params.component;
        const [ HEADER_COMP, HEADER_COMP_NAME ] = params.header;
        const [ CONTENT_COMP, CONTENT_COMP_NAME ] = params.content;
        const [ FOOTER_COMP, FOOTER_COMP_NAME ] = params.footer;
        const [ TITLE_COMP, TITLE_COMP_NAME ] = params.title;
        const [ DESCRIPTION_COMP, DESCRIPTION_COMP_NAME ] = params.description;

        const { text, props } = c;
        const { title: propsTitle, description: propsDescription, text: propsText, ...rest } = props;

        const titles : string[] = [
            propsTitle,
            propsText,
            text,
        ].filter(nonEmptyTrimmed);
        const descriptions  : string[] = [
            propsDescription,
        ].filter(nonEmptyTrimmed);
    
        let children = [...(c.children || [])];
        // If we used the text prop, remove it from the children
        if (check.nonEmptyString(text)) {
            children = removeOneOccurenceOfText(children, text);
        }
        // Extract title and description from the remaining text children
        children = children.map((child) => {
            if (isTextComponent(child)) {
                const childText = normalizeTextComponent(child)!.text;

                const { type, label, description } = parseInputOrCommandText(childText, { title: true, description: true });
                if (type === 'title') {
                    titles.push(label);
                    return null;
                } 
                if (check.nonEmptyString(description)) {
                    descriptions.push(description);
                    return null;
                }
            }
            return child;
        }).filter(Boolean);

        const title = nonEmptyArray(titles) ? titles.join(' ') : null;
        const description = nonEmptyArray(descriptions) ? descriptions.join(' ') : null;

        // Grab existing header, content and footer components from children
        const { 
          headerComps,
          contentComps,
          footerComps,
          rest: otherComps, 
        } = extractChildrenByType(children, {
          headerComps: [ 'header', HEADER_COMP],
          contentComps: [ 'content', CONTENT_COMP],
          footerComps: [ 'footer', FOOTER_COMP],
        });
    
        // Grab remaining unwrapped title and description components from children subtree
        const { 
          titleComps,
          descriptionComps,
          rest: remainingComps, 
        } = extractChildrenByType(otherComps, {
          titleComps: [ 'title', TITLE_COMP],
          descriptionComps: [ 'description', DESCRIPTION_COMP],
        });
    
        // Build header component
        const header = mergeComponentsInto(
          [
            ...(nonEmptyTrimmed(title) ? [{ _dontParse: true, component: TITLE_COMP, htmlComponent: TITLE_COMP_NAME, props:{}, classes:[], children: [title] }] : []),
            ...(nonEmptyTrimmed(description) ? [{ _dontParse: true, component: DESCRIPTION_COMP, htmlComponent: DESCRIPTION_COMP_NAME, props:{}, classes:[], children: [description] }] : []),
            ...(titleComps || []).map(c => ({...c, _dontParse: true, component: TITLE_COMP, htmlComponent: TITLE_COMP_NAME })).filter(filterOutIfEmpty(TITLE_COMP)),
            ...(descriptionComps || []).map(c => ({...c, _dontParse: true, component: DESCRIPTION_COMP, htmlComponent: DESCRIPTION_COMP_NAME })).filter(filterOutIfEmpty(DESCRIPTION_COMP)),
            ...headerComps.map(c => ({...c, _dontParse: true, component: HEADER_COMP, htmlComponent: HEADER_COMP_NAME })).filter(filterOutIfEmpty(HEADER_COMP)),
          ], 
          { component: HEADER_COMP, htmlComponent: HEADER_COMP_NAME, _dontParse: true }, 
          true
        );
    
        // Build content component
        const content = mergeComponentsInto(
            [
                ...contentComps.map(c => ({...c, _dontParse: true, component: CONTENT_COMP, htmlComponent: CONTENT_COMP_NAME })).filter(filterOutIfEmpty(CONTENT_COMP)),
                ...remainingComps, 
            ],
            { component: CONTENT_COMP, htmlComponent: CONTENT_COMP_NAME, _dontParse: true }, 
            true
        );
    
        // Build footer component
        const footer = mergeComponentsInto(
          footerComps.map(c => ({...c, _dontParse: true, component: FOOTER_COMP, htmlComponent: FOOTER_COMP_NAME })).filter(filterOutIfEmpty(FOOTER_COMP)), 
          { component: FOOTER_COMP, htmlComponent: FOOTER_COMP_NAME, _dontParse: true }, 
          true
        );
    
        return {
          ...c,
          classes: c.classes || [],
          props: rest || {},
          _dontParse: true,
          text: undefined,
          component: OUT_COMP,
          htmlComponent: OUT_COMP_NAME,
          children: [
            header,
            content,
            footer,
          ].filter(c => !!c)
        }
}


// Creates a parent wrapper to wrap a component with a trigger component and a content component
export function parentWrapperForTriggerableComponent(outerComponent: [ComponentType | string, string], triggerComponent: [ComponentType | string, string], contentComponent: [ComponentType | string, string]) : (c: ComponentTreeNode) => ComponentTreeNode {
    return (parent: ComponentTreeNode) : ComponentTreeNode => {
        const contentChildren = (parent.children || []).filter(child => child.component === contentComponent[0]).map((c => ({
          ...c,
          component: contentComponent[0],
          htmlComponent: contentComponent[1],
        })));
    
        return {
          component: outerComponent[0],
          htmlComponent: outerComponent[1],
          classes: [],
          props: {},
          children: [
            {
              _dontParse: true,
              component: triggerComponent[0],
              htmlComponent: triggerComponent[1],
              props: {
                asChild: true,
              },
              children: [
                {
                  ...parent,
                  children: (parent.children || []).filter(child => child.component !== contentComponent[0]),
                }
              ]
            },
            ...contentChildren,
          ]
        };
    };
}

// Remove wrapping " or ' or ` from a string. 
// If the string is not wrapped, it is returned as is
// Note: some chars can be escaped by using \
export function removeWrappingQuotes(s: string) : string {
    if (!check.nonEmptyString(s)) {
        return s;
    }

    const firstChar = s[0];
    const lastChar = s[s.length - 1];
    if (firstChar === lastChar && ['"', "'", "`"].includes(firstChar)) {
        return s.slice(1, -1);
    }

    return s;
}

export function splitTextWithVariablesIntoComponents(text?: string | null) : ComponentTreeNode[] | null {
    if (!check.nonEmptyString(text)) {
        return [];
    }

    const variables = extractVarNamesFromText(text);
    if (variables.length === 0) {
        return normalizeTextComponent(text) ? [normalizeTextComponent(text)!] : null;
    }

    const components = [];
    let remainingText = text;
    variables.forEach((variable) => {
        if (!check.nonEmptyString(remainingText) || !check.nonEmptyString(remainingText.trim())) {
            return;
        }

        if (!remainingText.includes(variable)) {
            return;
        }

        if (remainingText.trim() === variable) {
            components.push({
                component: DataDisplay, 
                htmlComponent: 'DataDisplay',
                classes: [],
                props: { bind: variable /* , key: 'var_' + variable.replaceAll(/\$/g, '') */},
                text: null,
                variant: null,
                children: null,
            });
            remainingText = '';
            return;
        }

        const before = remainingText.split(variable)[0];
        if (check.nonEmptyString(before)) {
            components.push(normalizeTextComponent(before)!);
        }
        components.push({
            component: DataDisplay, 
            htmlComponent: 'DataDisplay',
            classes: [],
            props: { bind: variable, key: 'var_' + variable.replaceAll(/\$/g, '')},
            text: null,
            variant: null,
            children: null,
        });

        remainingText = remainingText.substring(before.length + variable.length);
    });
    if (check.nonEmptyString(remainingText)) {
        components.push(normalizeTextComponent(remainingText)!);
    }

    return components;
}

export function normalizeTextComponent(c: any) : ComponentTreeNode | null {
    function normalize (c: any) : ComponentTreeNode | null {
        if (check.nonEmptyString(c) && nonEmptyTrimmed(c)) {
            if (!check.nonEmptyString(removeWrappingQuotes(c).trim())) {
                return null;
            }
            return {
                component: 'text',
                htmlComponent: 'span',
                classes: [],
                props: {},
                text: c,
                children: [],
            };
        }
    
        if (check.nonEmptyObject(c) && c.component === 'text') {
            let text = removeWrappingQuotes(c.text);
            if (!check.nonEmptyString(text) || !nonEmptyTrimmed(text)) {
                text = (c.children || []).filter(check.nonEmptyString).map((child: string) => removeWrappingQuotes(child).trim()).join(' ');
            }
    
            if (!check.nonEmptyString(text) || !nonEmptyTrimmed(text)) {
                return null;
            }
    
            return {
                component: 'text',
                htmlComponent: 'span',
                classes: c.classes || [],
                props: c.props || {},
                text,
                children: removeOneOccurenceOfText(c.children || [], text),
            };
        }
    
        return null;
    }

    const n = normalize(c);
    // return n;
    
    if (!n) {
        return null;
    }

    // Extract variables from text 
    const variables = extractVarNamesFromText(n.text);
    if (variables.length > 0) {
        const children = splitTextWithVariablesIntoComponents(n.text);
        
        if (!check.nonEmptyArray(children)) {
            return null;
        }

        if (children.length === 1 && !check.nonEmptyObject(n.props) && !check.nonEmptyArray(n.classes)) {
            return children[0];
        }

        return {
            ...n,
            component: 'span',
            htmlComponent: 'span',
            text: null,
            props: removeEmptyProps({
                ...(n.props),
                className: cn(n.classes, n.props?.className,),
            }),
            children,
        }
    }

    return {
        ...n,
        props: removeEmptyProps({
            ...(n.props || {}),
            className: cn(n.classes, n.props?.className),
        }),
    };
}

export function isTextComponent(c: any) : boolean {
    return !!normalizeTextComponent(c);
}

export function resolvesToTextComponent(c: any) : boolean {
    return (
        (
            check.string(c) 
            || (
                check.nonEmptyObject(c) 
                && 
                (  
                    c.component === 'text'
                    || ['span', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(c.component)
                ))
         )
         && (
            check.nonEmptyString(c)
            || (
                !check.array(c.children)
                || c.children.every((child: any) => resolvesToTextComponent(child))
            )
        )
    );
}

export function getInnerTextContent(c: ComponentTreeNode) : string {
    if (check.string(c)) {
        return c;
    }

    let texts = [];

    if (check.nonEmptyString(c.text)) {
        texts.push(c.text.trim());
    }

    if (check.nonEmptyArray(c.children)) {
        texts.push(c.children.map(getInnerTextContent).join(' '))
    }

    return texts.join(' ').trim();
}

export function getInnerPropsAndClasses(c: ComponentTreeNode) : { props: any, classes: string[] } {
    if (check.string(c)) {
        return { props: {}, classes: [] };
    }

    let props = { ...c.props || {} };
    let classes = [...(c.classes || [])];

    if (check.nonEmptyArray(c.children)) {
        c.children.forEach((child) => {
            const { props: childProps, classes: childClasses } = getInnerPropsAndClasses(child);
            props = mergeProps({}, props, childProps);
            classes = mergeClasses(classes, childClasses);
        });
    }

    return { props, classes };
}

export function mergeIntoSingleTextComponent(c: ComponentTreeNode | string ) : ComponentTreeNode | null {
    if (check.string(c)) {
        return normalizeTextComponent(c);
    }

    if (!check.nonEmptyObject(c)) {
        return null;
    }

    const { props, classes } = getInnerPropsAndClasses(c);
    const text = getInnerTextContent(c);

    return {
        component: 'text',
        htmlComponent: 'span',
        classes,
        props,
        text,
        children: [],
    };
}

export function mergeManyIntoSingleTextComponent(...comps: ComponentTreeNode[]) : ComponentTreeNode | null {
    return mergeIntoSingleTextComponent({
        component: 'text',
        htmlComponent: 'span',
        classes: [],
        props: {},
        children: comps.filter(resolvesToTextComponent)
    });
}

export function componentMatchesType(c: ComponentTreeNode, types: string | ComponentType | (string | ComponentType)[], reverseMatch = false) : boolean {
    let typesArray = check.array(types) ? types : [types];
    const match = typesArray.some(type => (
        (type === 'text' && isTextComponent(c))
        || (check.nonEmptyObject(c) && (c.component === type))
    ));
    return reverseMatch ? !match : match;
}

export function getComponentInnerText(c: ComponentTreeNode) : string {
    if (isTextComponent(c)) {
        return (normalizeTextComponent(c)?.text || '').trim();
    }

    let textItems = '';
    if (check.nonEmptyString(c.text)) {
        textItems += c.text + ' ';
    }
    
    if (check.nonEmptyArray(c.children)) {
        textItems += c.children.map(getComponentInnerText).join(' ');
    }

    return textItems.split(' ').map(s => s.trim()).join(' ').trim();
}

export function componentHasChildrenOfTypes(c: ComponentTreeNode, types: string | ComponentType | (string | ComponentType)[]) : boolean {
    const typesArray = check.array(types) ? types : [types];
    return check.nonEmptyArray(grabChildrenOfTypes((c.children || []), typesArray));
}

export function hasDescendantMatching(c: ComponentTreeNode, matching: (c: ComponentTreeNode) => boolean) : boolean {
    if (matching(c)) {
        return true;
    }

    if (check.nonEmptyArray(c.children)) {
        return c.children.some((child) => hasDescendantMatching(child, matching));
    }

    return false;
}

export function extractBooleanInputComponentMetadata(htmlComponent: string, c: ComponentTreeNode, allowBinding = true, enableValueParsingInText = true, defaultLabelName : string | undefined = undefined) {
    let text : string | null | undefined = c.text;
    let valueInText: boolean | undefined;
    let txtVar : string | null = null;

    // Extract first variable from text
    if (check.nonEmptyString(text)) {
        txtVar = getSingleVarNameFromText(text);
        if (check.nonEmptyString(txtVar)) {
            text = text.split(' ').filter(s => s !== txtVar).join(' ').trim();
        }

        // Extract value from text
        if (check.nonEmptyString(text) && enableValueParsingInText) {
            const words = text.trim().split(' ');
            const newWords = [];

            // Check text words to extract the last boolean value if one exists
            for(let i = words.length - 1; i >= 0; i--) {
                const word = words[i];

                if (check.boolean(valueInText)) {
                    newWords.push(word);
                    continue;
                }

                const wVal = toBoolean(word);
                if (check.boolean(wVal)) {
                    valueInText = wVal;
                } else {
                    newWords.push(word);
                }
            }

            // If a value is found, it will be removed from the text
            text = newWords.reverse().join(' ');
        }
    }

    let forLabel : string | null = null;
    const labelText = [text, ...(c.children || []).filter(isTextComponent).map(t => t.text)].filter(nonEmptyTrimmed).join(' ').trim();
    if (check.nonEmptyString(labelText)) {
        forLabel = c.props.id || c.props.htmlFor || c.props.name || defaultLabelName ? `${defaultLabelName}-${labelText.toLowerCase().replace(/\s/g, '-').replaceAll(/[^a-z0-9-]/gi, '')}` : `${htmlComponent.toLowerCase()}-${labelText.toLowerCase().replace(/\s/g, '-').replaceAll(/[^a-z0-9-]/gi, '')}`;
    }

    // Define how component should be wrapped
    let wrap = (toWrap: ComponentTreeNode) => toWrap;
    if (txtVar && allowBinding) {
        wrap = (toWrap: ComponentTreeNode) => ({
            component: DataInput as React.ComponentType,
            htmlComponent: 'DataInput',
            props: {
                bind: txtVar,
                type: 'boolean',
            },
            classes: [],
            variant: null,
            children: [toWrap],
      });
    }

    return {
        bind: txtVar || undefined,
        defaultValue: c.props.defaultValue || (check.boolean(valueInText) ? valueInText : undefined),
        forLabel,
        id: c.props.id || forLabel || undefined,
        label: labelText,
        wrap,
    };
}

export function getDestinationUrlForLink(props: Record<string, any>, config: { target: ParserConfigTarget }) : string {
    let dest = props.href || props.to;

    let baseUrl = '';

    // Change URL when in live/runtime mode
    if (config?.target === 'react-component') {
        baseUrl = window.location.origin;
        if (baseUrl.includes('localhost') || baseUrl.includes('layouts.dev')) {
            baseUrl = /* window.location.origin + */ '/' + window.location.pathname.split('/')[1];
        }
    }

    if (!dest.includes('://')) {
        dest = (baseUrl + (dest.trim().startsWith('/') ? dest.trim() : ('/' + dest.trim())));
    }

    return dest;
}

export function parseBooleanInputComponent(component: ComponentType, htmlComponent: string, c: ComponentTreeNode, allowBinding = true, enableValueParsingInText = true, defaultLabelName : string | undefined = undefined) {
    const {
        defaultValue,
        forLabel,
        id,
        label,
        wrap: genericWrap,
    } = extractBooleanInputComponentMetadata(htmlComponent, c, allowBinding, enableValueParsingInText, defaultLabelName);

    // Define how component should be wrapped
    let wrap = genericWrap;

    // Add wrapping for label
    let classesOnWrappedComponent : string[] = c.classes || [];
    if (check.nonEmptyString(label.trim())) {
        const prevWrap = wrap;

        classesOnWrappedComponent = [];
        wrap = (toWrap: ComponentTreeNode) => ({
            component: 'div',
            htmlComponent: 'div',
            classes: cn('flex items-center gap-2', c.classes).split(' ').filter(check.nonEmptyString),
            props: {
              className: cn('flex items-center gap-2', c.classes).split(' ').filter(check.nonEmptyString).join(" "),
            },
            children: [
              prevWrap(toWrap),
              {
                component: Label,
                htmlComponent: 'Label',
                props: { htmlFor: defaultLabelName || forLabel },
                children: [label],
                classes: [],
              },
            ],
        });
    }

    const classes = cn('no-transition', classesOnWrappedComponent).split(' ').filter(check.nonEmptyString);
    const props = removeEmptyProps({
        id,
        value: toBoolean(c.props?.value) === null ? undefined : toBoolean(c.props?.value),
        defaultValue,
        className: classes.join(' '),
    });

    return wrap({
        component,
        htmlComponent,
        classes,
        props,
        children: [],
        variant: null,
        text: null,
    });
}