import check from "@/vendors/check";
import { cn } from "../utils";
import { ComponentType } from "react";
import jsonStableStringify from 'json-stable-stringify';
import { ComponentTreeNode } from "./components/component.type";
import { removeFirstOccurenceOfTextFromChildren } from "./components/parsing-pipeline";

export function isVariableName(text: any) {
    if (!check.nonEmptyString(text)) {
      return false;
    }
    const varName = getSingleVarNameFromText(text);
    return check.nonEmptyString(varName) && varName.trim() === text.trim();
}



export function getSingleVarNameFromText(text: any) : string | null {
    if (!check.nonEmptyString(text)) {
      return null;
    }
    
    // Drop if text does not contain a dollar sign followed by an alpha character
    if (!/\$[a-zA-Z]+/g.test(text)) {
        return null;
    }

    // Get to the start of the first $ followed by an alpha character
    let toParse = text.trim().substring(text.trim().indexOf('$'));
    while (toParse.length > 0 && !/^\$[a-zA-Z]+/g.test(toParse)) {
      toParse = toParse.trim().substring(toParse.trim().indexOf('$'));
    }
    if (!check.nonEmptyString(toParse)) {
      throw new Error('Impossible error: Could not find a variable name in text: ' + text);
    }
    toParse = toParse.trim().substring(1);

    if (!check.nonEmptyString(toParse)) {
      return null;
    }

    let variableName = '$';
    let withinBrackets = 0;
    for (let i = 0; i < toParse.length; i++) {
      const char = toParse[i];
      
      // Break if we find a space, or a non-alpha, non-numerical, non-bracket character 
      if (!withinBrackets && !/[a-zA-Z0-9._-]/g.test(char + '') && (!'[]{}\'"'.includes(char)))  {
        break;
      }

      if (char === '[' || char === '{') {
        withinBrackets++;
      } else if (char === ']' || char === '}') {
        if (withinBrackets <= 0) {
          break;
        }
        withinBrackets--;
      }

      variableName += char;
    }

    return variableName;
}

export function extractVarNamesFromText(text: any) : string[] {
  let t = text;
  let out : string[] = [];
  do {
    let varName = getSingleVarNameFromText(t);
    if (check.nonEmptyString(varName)) {
      out.push(varName);
      t = t.substring(t.indexOf(varName) + varName.length);
    } else {
      t = null;
    }
  } while(check.nonEmptyString(t));

  return out;
}

export function splitTextByVars(text: any) : string[] {
  let t = text;
  const parts : string[] = [];

  if (!check.nonEmptyString(t)) {
    return [];
  }

  do {
    let varName = getSingleVarNameFromText(t);
    if (check.nonEmptyString(varName)) {
      parts.push(t.substring(0, t.indexOf(varName)));
      parts.push(varName);
      t = t.substring(t.indexOf(varName) + varName.length);
    } else {
      parts.push(t);
      t = null;
    }
  } while(check.nonEmptyString(t));

  return parts;
}

// Remove first occurence of text from children, even when text has variables and was split in multiple parts
export function removeTextFromChildren(text: string | null | undefined, children: ComponentTreeNode[] | null | undefined) : ComponentTreeNode[] {
  if (!check.nonEmptyString(text) || !check.nonEmptyArray(children)) {
    return children || [];
  }

  let out = [...children];
  const parts = splitTextByVars(text);
  console.log('removeTextFromChildren:', {
    text,
    parts,  
    children: out
  });
  parts.forEach(part => {
    if (part.startsWith('$')) {
      out = out.filter(c => (c.htmlComponent !== 'DataDisplay' || c.props?.bind !== part));
    } else {
      out = removeFirstOccurenceOfTextFromChildren(part.trim())(out);
    }
  });

  return out;
}

export function getComponentName(component: ComponentType | string) {
    if (check.nonEmptyString(component)) {
      return component;
    }

    if (check.object(component)) {
      if (check.nonEmptyString(component.displayName)) {
        return component.displayName;
      }
  
      if (check.nonEmptyString(component.name)) {
          return component.name;
      }
    }

    if (check.function(component)) {
        const name = component.toString().match(/^function\s*([^\s(]+)/)?.[1];
        if (check.nonEmptyString(name)) {
            return name;
        }

        if (check.nonEmptyString(component.displayName)) {
          return component.displayName;
        }
    }

    return 'UnknownComponent';
}

export function selectVariant(variants: string[], value: any) : string {
  if (!check.nonEmptyString(value)) {
    return variants[0];
  }
  let out = variants.find(v => v.toLowerCase().trim() === value.toLowerCase().trim());
  if (out) {
    return out;
  }

  return variants[0];
}

// Will select a variant from the list of variants, but will return undefined 
// if the output value is the default variant
export function selectVariantWithUndefinedDefault(variants: string[], value: any) : string | undefined {
  let out = selectVariant(variants, value);
  if (out === variants[0]) {
    return undefined;
  }
  return out;
}

export function mergeProps(...props: { [key: string]: any }[]): { [key: string]: any } {
  let out = props.reduce((acc, prop) => {
    if (check.nonEmptyObject(prop)) {
      return { ...acc, ...prop, className: cn(acc.className, prop.className) };
    }
    return acc;
  }, {});

  if (!check.nonEmptyString(out.className)) {
    delete out.className;
  }

  return out;
}

export function mergeClasses(...classes: any[]) : string[] {
  return cn(...(classes || [])).split(' ').filter(check.nonEmptyString);
}

export function withTextAsFirstChild(c: ComponentTreeNode) : ComponentTreeNode {
  if (!check.nonEmptyString(c.text) || !check.nonEmptyString(c.text.trim())) {
    return c;
  }

  return {
    ...c,
    text: null,
    children: [
      {
        component: 'text',
        htmlComponent: 'span',
        props: {},
        classes: [],
        text: c.text,
        children: [],
      }, 
      ...(c.children || [])
    ],
  };
}

export function withTextAsCustomChild(c: ComponentTreeNode, textParser : ((text: string) => any) = (text: string) => ({})) {
  if (!check.nonEmptyString(c.text) || !check.nonEmptyString(c.text.trim())) {
    return c;
  }
  
  return {
    ...c,
    text: null,
    children: [textParser(c.text), ...(c.children || [])]
  };
}

export function withTextAsChildOfType(component: ComponentType, htmlComponent: string) {
  return (c: ComponentTreeNode) => withTextAsCustomChild(c, (text) => ({
    component,
    htmlComponent,
    props: {},
    classes: [],
    text: null,
    children: [
      {
        component: 'text',
        htmlComponent: 'span',
        props: {},
        classes: [],
        text,
        children: [],
      }
    ],
  }));
}

export function sortProps(props: { [key: string]: any }) : { [key: string]: any } {
  if (!check.nonEmptyObject(props)) {
    return {};
  }

  let out : { [key: string]: any } = {};

  Object.keys(props).sort().forEach(key => {
    const value = props[key];

    if (key === 'className') {
      out[key] = cn(value);
    } else if (check.nonEmptyObject(value) || !check.array(value)) {
      try {
        out[key] = JSON.parse(jsonStableStringify(value));
      } catch (err) {
        out[key] = value;
      }
    } else {
      out[key] = value;
    }
  });

  return out;
}

export function isUrlOrDataUrl(url: any): url is string {
  return check.nonEmptyString(url) && [
      'https://',
      'http://',
      '//',
      'file://',
      'data:',
  ].some((prefix) => url.trim().toLowerCase().startsWith(prefix))
}

export function removeEmptyProps(obj: { [key: string]: any }) {
  if (!check.nonEmptyObject(obj)) {
      return {};
  }

  return Object.keys(obj).reduce((acc, key) => {
      const val = obj[key];
      if (val === null || val === undefined) {
        return acc;
      }
      if (check.string(val) && !check.nonEmptyString(val.trim())) {
        return acc;
      }
      if (check.array(val) && !check.nonEmptyArray(val)) {
        return acc;
      }
      if (check.object(val) && !check.nonEmptyObject(val)) {
        return acc;
      }

      acc[key] = obj[key];
      return acc;
  }, {} as { [key: string]: any });
}