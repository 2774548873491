import { ComponentAspectRatio } from "../../../lib/components-registry"
import Card_L from "./Card_L"
import Card_P from "./Card_P"
import Card_B from "./Card_button"

interface CardProps {
    aspectRatio?:ComponentAspectRatio,
    subVariant?:string,
}

export function ImgCard({
    aspectRatio="16/9",
    subVariant="default",
    ...props
} : CardProps ) {
    if (subVariant == 'button') {
        return <Card_B {...props}></Card_B>
    } else {
        if (aspectRatio == '16/9' || aspectRatio == '4/3') {
            return <Card_L aspectRatio={aspectRatio} {...props}></Card_L>
        } else {
            return (
                <Card_P aspectRatio={aspectRatio} {...props}></Card_P>
            )
        }
    }
}