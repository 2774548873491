import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
  } from "@/components/shadcn/ui/resizable"
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import check from "@/vendors/check";
import { cn, nonEmptyTrimmed } from "@/lib/utils";
import { childrenParsers, ComponentAliasConfig, imports, removeOneOccurenceOfText, setup } from "../_utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
  
  /*
  export function ResizableDemo() {
    return (
      <ResizablePanelGroup
        direction="horizontal"
        className="max-w-md rounded-lg border"
      >
        <ResizablePanel defaultSize={50}>
          <div className="flex h-[200px] items-center justify-center p-6">
            <span className="font-semibold">One</span>
          </div>
        </ResizablePanel>
        <ResizableHandle />
        <ResizablePanel defaultSize={50}>
          <ResizablePanelGroup direction="vertical">
            <ResizablePanel defaultSize={25}>
              <div className="flex h-full items-center justify-center p-6">
                <span className="font-semibold">Two</span>
              </div>
            </ResizablePanel>
            <ResizableHandle />
            <ResizablePanel defaultSize={75}>
              <div className="flex h-full items-center justify-center p-6">
                <span className="font-semibold">Three</span>
              </div>
            </ResizablePanel>
          </ResizablePanelGroup>
        </ResizablePanel>
      </ResizablePanelGroup>
    )
  }
  */ 

  function wrapUnknownComponent(c: ComponentTreeNode) {
    return {
      component: ResizablePanel,
      htmlComponent: 'ResizablePanel',
      props: {},
      classes: [],
      children: [c],
    };
  }

  function propsFromPanelText(text: any): { [key: string]: any } {
    if (!check.nonEmptyString(text) || !nonEmptyTrimmed(text)) {
      return {};
    }

    if (/^[0-9]+$/g.test(text.trim())) {
      return {
        defaultSize: Math.min(Math.max(0, parseInt(text.trim())), 100),
      };
    }

    if (/^[0-9]+\s*,\s*[0-9]+\s*,{0,1}\s*[0-9]*$/g.test(text.trim())) {
      const [min, size, max] = text.split(',').map(s => parseInt(s.trim()));
      const props : { [key: string]: any } = {};

      if (min) {
        props.minSize = Math.max(0, min);
      }
      if (max) {
        props.maxSize = Math.min(100, max);
      }
      if (size) {
        props.defaultSize =  Math.min(Math.max(0, size), 100);
      }

      return props;
    }

    return {};
  }

  function toResizableChildComponent(c: ComponentTreeNode) : ComponentTreeNode | null {
    if (!c) {
      return null;
    }

    if (!check.nonEmptyObject(c)) {
      return wrapUnknownComponent(c);
    }

    const allowedComponents = [ResizablePanel, ResizableHandle, 'panel', 'handle'];
    if (!allowedComponents.some(ac => c.component === ac)) {
      return wrapUnknownComponent(c);
    }

    const isPanel = [ResizablePanel, 'panel'].some(ac => c.component === ac);

    return {
      ...c,
      text: undefined,
      component: isPanel ? ResizablePanel : ResizableHandle,
      htmlComponent: isPanel ? 'ResizablePanel' : 'ResizableHandle',
      children: isPanel ? (c.children || []) : [], // Handles have no children
      props: {
        ...propsFromPanelText(c.text),
        ...c.props,
      }
    };
  }

  function ensureHandleBetweenPanels(children: ComponentTreeNode[], withHandle = false) {
    const newChildren = [];
    for (let i = 0; i < children.length; i++) {
      const current = children[i];
      
      if (newChildren.length % 2 === 0) {
        // Every even index should be a component
        if (current.component === ResizableHandle) {
          continue;
        }
        newChildren.push(current);
      } else if (newChildren.length % 2 === 1) {
        // Every odd index should be a handle
        if (current.component !== ResizableHandle) {
          newChildren.push({
            component: ResizableHandle,
            htmlComponent: 'ResizableHandle',
            props: { withHandle },
            classes: [],
            children: [],
          });
        }
        newChildren.push(current);
      }
    }

    return newChildren;
  }

  function applyBaseProps(children: ComponentTreeNode[], baseProps: { [key: string]: any }[]): ComponentTreeNode[] {
    if (!check.nonEmptyArray(baseProps)) {
      return children;
    }

    let childIndex = 0;
    return children.map((c) => {
      if (c.component === ResizablePanel) {
        let base = baseProps[childIndex] || {};
        childIndex += 1;

        if (!check.nonEmptyObject(base)) {
          return c;
        }
        
        return {
          ...c,
          props: {
            ...base,
            ...c.props,
          },
        };
      }
      return c;
    });
  }

  const TAG = 'resizable';
  const ALIASES: ComponentAliasConfig[] = [
    ['panel', ResizablePanel, 'ResizablePanel', undefined, (c: ComponentTreeNode) => {
      return {
        ...c,
        component: ResizablePanel,
        htmlComponent: 'ResizablePanel',
      };
    }],
    ['handle', ResizableHandle, 'ResizableHandle', undefined, (c: ComponentTreeNode) => {
      return {
        ...c,
        component: ResizableHandle,
        htmlComponent: 'ResizableHandle',
      };
    }],
  ];
    

  const ResizableComponentParser : ComponentParser = {
    name: 'Resizable',
    description: 'Accessible resizable panel groups and layouts with keyboard support.',
    tags: [TAG],
    childrenParsers: childrenParsers(ALIASES, TAG),

    refImplementation: `
    /resizable @withHandle 25
      /center size-full text-2xl font-semibold gap-5
          1
          /span text-sm text-slate-400 font-light defaultWidth: 25%
      /resizable %vertical @withHandle 80 | 10,20,50
          /center size-full text-2xl font-semibold gap-5
              2
              /span text-sm text-slate-400 font-light defaultHeight: 80%
          /center size-full text-2xl font-semibold gap-5
              3
              /vstack
                  /span text-sm text-slate-400 font-light minHeight: 10%
                  /span text-sm text-slate-400 font-light defaultHeight: 20%
                  /span text-sm text-slate-400 font-light maxHeight: 50%
    `.trim(),

    parseTree: (c: ComponentTreeNode) => {
        const { variant } = c;
        const direction = variant === 'vertical' ? 'vertical' : 'horizontal';
        const withHandle = !!c.props?.withHandle;
        const { direction: d, withHandle: w, ...otherProps } = c.props || {};
        let children = c.children || [];

        let baseChildrenProps: { [key: string]: any }[] = [];
        if (check.nonEmptyString(c.text)) {
          baseChildrenProps = c.text.split('|').map(s => propsFromPanelText(s));
          children = removeOneOccurenceOfText(children, c.text);
        }
        
        children = applyBaseProps(
          ensureHandleBetweenPanels(
            children
              .map(toResizableChildComponent)
              .filter(c => !!c) as ComponentTreeNode[], 
            withHandle
          ),
          baseChildrenProps,
        );
  
        return {
            ...c,
            component: ResizablePanelGroup as any,
            htmlComponent: 'ResizablePanelGroup',
            props: {
              direction: c.props?.direction || direction,
              ...otherProps,
              className: cn('no-transition', otherProps?.className),
            },
            children,
            classes: cn('no-transition', c.classes).split(' '),
            variant: null,
        };
    },
    imports: imports(['ResizableHandle', 'ResizablePanel', 'ResizablePanelGroup' ], TAG),
    setup: setup(TAG),
    variants: [
      'horizontal',
      'vertical',
    ]
  };

registerShadcnComponent({
        ResizableHandle,
        ResizablePanel,
        ResizablePanelGroup,
    },
    TAG,
);
  
export default ResizableComponentParser;
