import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import check from "@/vendors/check";


const tailwindFontSizes = [
  { name: 'text-xs', size: 12 },
  { name: 'text-sm', size: 14 },
  { name: 'text-base', size: 16 },
  { name: 'text-lg', size: 18 },
  { name: 'text-xl', size: 20 },
  { name: 'text-2xl', size: 24 },
  { name: 'text-3xl', size: 30 },
  { name: 'text-4xl', size: 36 },
  { name: 'text-5xl', size: 48 },
  { name: 'text-6xl', size: 60 },
  { name: 'text-7xl', size: 72 },
  { name: 'text-8xl', size: 96 },
  { name: 'text-9xl', size: 128 },
];

function convertToPixels(value: string | number) {
  if (typeof value === 'number') return value; // if the value is already a number (assumed in pixels)
  
  const remToPx = 16;
  const unit = value.slice(-2);
  const number = parseFloat(value);
  
  switch (unit) {
    case 'px':
      return number;
    case 'rem':
      return number * remToPx;
    case 'em':
      return number * remToPx; // assuming 1em = 16px, adjust if needed
    default:
      throw new Error('Unsupported unit: ' + unit);
  }
}

// Return the closest Tailwind CSS font size class to the given value
export function getClosestTwFontSize(value: string | number) {
  const inputSizeInPx = convertToPixels(value);
  
  let closest = tailwindFontSizes[0];
  let closestDiff = Math.abs(inputSizeInPx - closest.size);

  for (const fontSize of tailwindFontSizes) {
    const diff = Math.abs(inputSizeInPx - fontSize.size);
    if (diff < closestDiff) {
      closest = fontSize;
      closestDiff = diff;
    }
  }
  
  return closest.name;
}


// Enable users to remove the beginning of a class chain by adding 'reset' in it
// eg "text-color-500 reset text-center" => "text-center"
function handleReset(className: string) : string {
  if (!check.string(className)) {
    return className;
  }
  const classes = className.split(' ');
  const resetIndex = classes.findIndex(cls => cls === 'reset');
  if (resetIndex === -1) {
    return className;
  }

  return 'reset ' + classes.slice(resetIndex + 1).filter(c => c && c.toLowerCase() !== 'reset').join(' ');
}

function handleTypeface(className: string) : string {
  if (!check.nonEmptyString(className)) {
    return ''
  }

  let classes = className.split(' ').map(l => l.trim()).filter(check.nonEmptyString);
  if (classes.length === 0) {
    return '';
  }

  // Remove anything related to typeface before typeface-*
  const typefaceIndex = classes.findIndex(cls => cls.startsWith('typeface-'));
  if (typefaceIndex === -1) {
    return className;
  }
  classes = classes.map((cls, i) => {
    if (i < typefaceIndex) {
      if (cls.startsWith('leading-')) {
        return '';
      }
      if (cls.startsWith('tracking-')) {
        return '';
      }
      if (cls.startsWith('font-')) {
        return '';
      }
      if (cls.startsWith('text-')) {
        return '';
      }
    }

    return cls;
  });

  // Convert absolute values like typeface-body-[16px] into typeface-body-base text-[16px]
  classes = classes.map(cls => {
    if (/^typeface-(display|body)-\[.*\]$/g.test(cls)) {
      const prefix = cls.split('[')[0];
      const arbitraryValue = cls.split('[')[1].split(']')[0];
      return `${prefix}${getClosestTwFontSize(arbitraryValue)} text-[${arbitraryValue}]`;
    }

    return cls;
  });

  return classes.filter(check.nonEmptyString).join(' ');
}
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(handleTypeface(handleReset(clsx(inputs))));
}