import EventEmitter from "events";
import uniqid from 'uniqid';
import check from "../check";
import { get as getPropertyRaw, set, set as setPropertyRaw } from 'dot-prop';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { isVariableName } from "@/lib/parser/util";
import simpleArrayDiff from 'simple-array-diff'; 

export type DataListChangeEvent = {
    key: string,
    items: Record<string, any>[],
    op: 'push' | 'clear' | 'remove' | 'update',
}

function extractKeyAndPathFromVarName(fullVarName: string) {
    if (!check.nonEmptyString(fullVarName)) {
        return { key: null, path: null };
    }
    const name = fullVarName.trim().split('.')[0].split('[')[0].trim();
    let path : string | null = null;
    if (fullVarName.trim() !== name) {
        path = fullVarName.trim().substring(name.length).trim();
        if (path.startsWith('.')) {
            path = path.substring(1).trim();
        }
    }

    return { key: name, path };
}

const updatePaginationIfNecessary = (prevValue: any, nextValue: any) => {
    try {
        let prevPage = prevValue?.$?.pagination?.currentPage || 0;
        let nextPage = nextValue?.$?.pagination?.currentPage || 0;
    
        if (prevPage !== nextPage) {

            // console.log('Updating page to '+nextPage + '...')
            if (check.function(nextValue?.$?.pagination?.setPage)) {
                nextValue?.$?.pagination?.setPage(nextPage);
                // console.log('Updating page to '+nextPage + '...OK');
            } else {
                // console.log('Updating page to '+nextPage + '...NOK');
            }
        }
    } catch(err) {
        console.error('Pagination update error:', err);
    }
}

// Functions to translate paths the way we like
// Useful to get item by id in a collection
function getProperty(obj: any, path: string | null) : any {
    if (!check.nonEmptyString(path)) {
        return obj;
    }

    // console.log('Trying to get value at path "'+path+'" on obj: ', obj)

    if (check.array(obj) && path.startsWith('[')) {
        const childKey: string = path.substring(1, path.indexOf(']'));
        
        if (obj[childKey as any] !== undefined) {
            return getProperty(obj[childKey as any], path.substring(path.indexOf(']') + 1));
        }
        if (!isNaN(parseInt(childKey)) && obj[parseInt(childKey as any)] !== undefined) {
            return getProperty(obj[parseInt(childKey as any)], path.substring(path.indexOf(']') + 1));
        }
        const match = obj.find((item: any) => check.nonEmptyObject(item) && (item._id === childKey || item.id === childKey));
        if (match) {
            return getProperty(match, path.substring(path.indexOf(']') + 1));
        }
    }

    // Clean path
    let cleanPath = path;

    cleanPath = path.replaceAll(/\[/g, '.').replaceAll(/\]/g, '.');
    if (cleanPath.startsWith('.')) {
        cleanPath = cleanPath.substring(1);
    }
    if (cleanPath.endsWith('.')) {
        cleanPath = cleanPath.substring(0, cleanPath.length - 1);
    }

    // console.log('Running getPropertyRaw on obj: ', obj, ' and path:', cleanPath.trim())

    return getPropertyRaw(obj, cleanPath.trim());
}


// Functions to translate paths the way we like
// Useful to get item by id in a collection
/*
function setProperty(obj: any, path: string, value: any) : any {
    if (!check.nonEmptyString(path) || !check.array(obj)) {
        return setPropertyRaw(obj, path, value);
    }

    if (check.array(obj) && path.startsWith('[')) {
        const childKey: string = path.substring(1, path.indexOf(']'));
        const nextPath = path.substring(path.indexOf(']') + 1);
        if (isNaN(parseInt(childKey))) {
             // Child key not an array key
            // append or update the item by id

            if (!check.nonEmptyString(nextPath) && !check.object(value)) {
                // We can only work if the value we want to set is an object or within an object
                console.error('Failed to set non object value at path ' + path);
                return;
            }

            let match = obj.find((item: any) => check.nonEmptyObject(item) && (item._id === childKey || item.id === childKey));
            if (!match) {
                const newValue = setPropertyRaw({ _id: childKey }, nextPath, value);
                obj.push(newValue);
            } else {
                const matchIndex = obj.indexOf(match);
                const newValue = setPropertyRaw(match, nextPath, value);
                obj[matchIndex] = newValue;
            }

            return obj;
        }
    }

    return setPropertyRaw(obj, path, value);
}
*/

const setProperty = setPropertyRaw;

class DataVar extends EventEmitter {
    name: string;
    value: any;

    constructor(name: string, initialValue?: any) {
        super();
        this.name = name;
        this.value = initialValue;
    }

    set(value: any) {
        updatePaginationIfNecessary(this.value, value);
        this.value = value;
        this.emit('change', this.value);
    }

    updatePath(path: string, value: any) {
        const prev = this.value;
        this.value = setProperty(this.value || {}, path, value);
        this.emit('change', this.value);

        updatePaginationIfNecessary(prev, this.value);
    }

    get(path: string | null = null): any {
        if (!check.nonEmptyString(path)) {
            return this.value;
        }
        if (!check.nonEmptyObject(this.value) && !check.nonEmptyArray(this.value)) {
            // console.log('CANNOT getProperty at path: ' + path)
            return undefined;
        }
        const val = getProperty(this.value, path);
        // console.log('getProperty at path: ' + path, { val })
        return val;
    }
}

class DataNamespace extends EventEmitter {
    private engine: DataEngine;
    private parent?: DataNamespace;
    private delegateEventHandlers: Map<any, any>;
    private listChangeHandlers: Map<any, any>;
    id: string
    parents: string[]
    values: Map<string, any>

    constructor(id: string, engine: DataEngine, parent?: DataNamespace) {
        super();
        this.id = id;
        this.engine = engine;
        this.values = new Map();
        this.parent = parent;
        this.parents = parent ? [...parent.parents, parent.id] : [];
        this.delegateEventHandlers = new Map();
        this.listChangeHandlers = new Map();

        // Register listeners on parent
        if (this.parent) {
            this.parent.on('change', ({key} : {key: string, value: any}) => {
                // Forward the change event
                // if we don't own the key
                if (!this.values.has(key)) {
                    const value = this.parent?.getValue(key);
                    this.emit('change', { key, value });
                    this.emit(key, value);
                } else {
                    // Ignore the event if we have the key
                    // as our value overrides the parent's value
                }
            });

            this.parent.on('listChange', ({key, items, op} : DataListChangeEvent) => {
                // Forward the change event
                // if we don't own the key
                if (!this.values.has(key)) {
                    this.emit('listChange', { key, items, op });
                } else {
                    // Ignore the event if we have the key
                    // as our value overrides the parent's value
                }
            });
        }
    }

    // By default, set() saves the value within the current namespace
    // Could change this to set() the parent's value
    setValue(keyAndPath: string, value: any) {
        if (!check.nonEmptyString(keyAndPath)) {
            return;
        }

        const { key, path } = extractKeyAndPathFromVarName(keyAndPath);

        // console.log('SETTING '+keyAndPath + ' to ', value);

        // No key: do nothing
        if (!key) {
            return;
        }

        // Get previous value to detect changes
        const prevValue = this.values.get(key)?.get();

        // Set the value
        if (!this.values.has(key)) {
            // Value doesn't exist
            // Create a dataRef and set the initial value
            let data = {};
            if (path) {
                data = setProperty(data, path, value);
            } else {
                data = value;
            }

            let dataRef = new DataVar(key, data);
            this.values.set(key, dataRef);
        } else {
            // Value exists. Update it
            const dataRef = this.values.get(key);
            if (path) {
                dataRef.updatePath(path, value);
            } else {
                dataRef.set(value);
            }
        }

        // Emit the change event
        const newValue = this.values.get(key).get();
        this.emit(key, newValue);
        this.emit('change', { key, value: newValue });
        
        // If we are in presence of a collection
        // Detect the kind of change and emit the appropriate event
        if (
            (check.array(prevValue) || check.array(newValue))
            && (
                (check.array(prevValue) && prevValue.some((v: any) => check.nonEmptyObject(v) && v._id))
                || (check.array(newValue) && newValue?.some((v: any) => check.nonEmptyObject(v) && v._id))
            )
        ){
            if (!path) {
                // This was a set event, at root
                if (check.nonEmptyArray(prevValue) && !check.nonEmptyArray(newValue)) {
                    // This was a clear event
                    this.emit('listChange', { key, items: [], op: 'clear' });
                } else if (!check.nonEmptyArray(prevValue) && check.nonEmptyArray(newValue)) {
                    // This was a push event
                    this.emit('listChange', { key, items: newValue, op: 'push' });
                } else {
                    const prevKeys = prevValue.map((v: any) => v._id || v.id);
                    const newKeys = newValue.map((v: any) => v._id || v.id);

                    // This was an update event
                    const { removed, added, common } = simpleArrayDiff(prevKeys, newKeys);
                    if (check.nonEmptyArray(removed)) {
                        // With removed items
                        const removedItems = prevValue.filter((v: any) => (!!v && removed.includes(v._id || v.id)));
                        if (check.nonEmptyArray(removedItems)) {
                            this.emit('listChange', { key, items: removedItems, op: 'remove' });
                        }
                    }
                    if (check.nonEmptyArray(added)) {
                        // With added items
                        const addedItems = newValue.filter((v: any) => (!!v && added.includes(v._id || v.id)));
                        if (check.nonEmptyArray(addedItems)) {
                            this.emit('listChange', { key, items: addedItems, op: 'push' });
                        }
                    }
                    if (check.nonEmptyArray(common)) {
                        // With updated items
                        const prevItemsJson = prevValue.filter((v: any) => (!!v && common.includes(v._id || v.id))).map((v: any) => JSON.stringify(v));
                        const updatedItems = newValue.filter((v: any) => (
                            !!v 
                            && common.includes(v._id || v.id)
                            && !prevItemsJson.includes(JSON.stringify(v))
                        ));
                        if (check.nonEmptyArray(updatedItems)) {
                            this.emit('listChange', { key, items: updatedItems, op: 'update' });
                        }
                    }
                }
            } else {
                if (path.startsWith('[') && path.includes(']')) {
                    const childKey = path.substring(1, path.indexOf(']'));
                    
                    let prevItem = null;
                    let newItem = null;
                    if (check.nonEmptyArray(newValue)) {
                        newItem = newValue.find((v: any) => v._id === childKey || v.id === childKey);
                        
                        if (!newItem && !isNaN(parseInt(childKey))) {
                            newItem = newValue[parseInt(childKey)] || null;
                        }
                    }
                    if (check.nonEmptyArray(prevValue)) {
                        prevItem = prevValue.find((v: any) => v._id === childKey || v.id === childKey);
                        
                        if (!prevItem && !isNaN(parseInt(childKey))) {
                            prevItem = prevValue[parseInt(childKey)] || null;
                        }
                    }

                    if (!newItem && prevItem) {
                        this.emit('listChange', { key, items: [prevItem], op: 'remove' });
                    } else if (newItem && !prevItem) {
                        this.emit('listChange', { key, items: [newItem], op: 'push' });
                    } else if (newItem && prevItem) {
                        this.emit('listChange', { key, items: [newItem], op: 'update' });
                    } else {
                        console.error('Failed to detect list change operation', { key, path, prevValue, newValue });
                    }
                }
            }
        }
    }

    // The get() method returns the value of the variable from the current namespace
    // if it exists, otherwise it returns it from the parent namespace
    getValue(keyAndPath: string): any {
        if (!check.nonEmptyString(keyAndPath)) {
            return undefined;
        }

        const { key, path: rawPath } = extractKeyAndPathFromVarName(keyAndPath);

        // Solve the path in case it contains variable names
        const path = this.solvePath(rawPath);

        // console.log('[Data engine] Getting value at keyPath: ' + keyAndPath, { key, path, rawPath })

        // No key: do nothing
        if (!key) {
            return undefined;
        }

        // Get the value
        if (this.values.has(key)) {
            const base = this.values.get(key).get(path);
            // console.log('[Data engine] Found value at keyPath: ' + keyAndPath, { key, path, base })
            return this.values.get(key).get(path);
        } else {
            return this.parent?.getValue(keyAndPath);
        }
    }

    onListChange(key: string, listener: (value: DataListChangeEvent) => void) {
        const handler = (data: any) => {
            if (data.key === key) {
                listener(data);
            }
        };
        this.listChangeHandlers.set(listener, handler);
        this.on('listChange', handler);
    }

    offListChange(key: string, listener: (DataListChangeEvent: any) => void) {
        if (this.listChangeHandlers.has(listener)) {
            const handler = this.listChangeHandlers.get(listener);
            this.off('listChange', handler);
            this.listChangeHandlers.delete(listener);
        }
    }

    // Add a listener to a key composed of a path
    // FIXME: Add listener for $var when trying to listen $movies[$var]
    onPathUpdate(keyAndPath: string, listener: (value: any) => void) {
        const { key, path: rawPath } = extractKeyAndPathFromVarName(keyAndPath);

        // Solve the path in case it contains variable names
        const path = this.solvePath(rawPath);

        if (!key) {
            return;
        }

        if (!path) {
            this.on(key, listener);
            return;
        }

        const dependencies = this.getPathVariables(rawPath);

        // If a path is set, we need to create an event handler
        // that will listen for changes in the value of the key
        // and emit the change event only if the path has changed
        const mainHandler = (value: any) => {
            const newValue = getProperty(value, path);

            // console.log('Firing listener on path '+keyAndPath)
            listener(newValue);
        };
        this.on(key, mainHandler);

        // We also create handlers for dependencies
        const depsHandlers = dependencies.map((dep) => {
            const { key: depKey, path: depPath } = extractKeyAndPathFromVarName(dep);
            if (!depKey) {
                return null;
            }
            const handler = (value: any) => {
                const newValue = this.getValue(keyAndPath.replace(dep, getProperty(value, depPath)));
                // console.log('Firing listener FROM DEPS on path '+keyAndPath + ' | new value: ',newValue)
                listener(newValue);
            };
            this.on(depKey, handler);
            return { key: depKey, handler };
        }).filter((h) => h !== null);

        const handlers = [
            { key, handler: mainHandler},
            ...depsHandlers
        ];

        // We remove the previous handlers if any
        if (this.delegateEventHandlers.has(listener)) {
            const prevHandlers = this.delegateEventHandlers.get(listener);
            prevHandlers.forEach((h: any) => {
                if (h.key && h.handler) {
                    this.off(h.key, h.handler);
                }
            });
        }

        // And save the new ones
        this.delegateEventHandlers.set(listener, handlers);

        // Emit the initial value
        const initialValue = this.getValue(keyAndPath);
        listener(initialValue);
    }

    // Remove a listener from a key composed of a path
    offPathUpdate(keyAndPath: string, listener: (value: any) => void) {
        const { key, path } = extractKeyAndPathFromVarName(keyAndPath);

        if (!key) {
            return;
        }

        if (!path) {
            this.off(key, listener);
            return;
        }

        if (this.delegateEventHandlers.has(listener)) {
            const prevHandlers = this.delegateEventHandlers.get(listener);
            prevHandlers.forEach((h: any) => {
                if (h.key && h.handler) {
                    this.off(h.key, h.handler);
                }
            });
            this.delegateEventHandlers.delete(listener);
        }
    }

    // Unmount the namespace
    // Will typically remove all listeners and clrea all values
    unmount() {
        this.removeAllListeners();
        this.delegateEventHandlers.clear();
        this.values.clear();

        // FIXME: We should remove listeners set within constructor on our parent
    }

    // Solve the path in case it contains variable names
    solvePath(path: string | null): string | null {
        if (!check.nonEmptyString(path)) {
            return null;
        }

        if (path.startsWith('[') && path.includes(']')) {
            const key = path.substring(1, path.indexOf(']'));
            const nextPath = path.substring(path.indexOf(']') + 1);
            if (isVariableName(key)) {
                const val = this.getValue(key);
                const newPath = `[${val}]` + (this.solvePath(nextPath) || '');

                // console.log('Solving path: ('+key+') '+path+' -> '+newPath);
                return newPath;
            }
        }

        return path;
    }

    // Returns the list of variables to solve to access the path
    getPathVariables(path: string | null): string[] {
        if (!check.nonEmptyString(path)) {
            return [];
        }

        if (path.startsWith('.')) {
            return this.getPathVariables(path.substring(1).trim());
        }

        const vars = [];
        let next = path;

        if (path.startsWith('[') && path.includes(']')) {
            const key = path.substring(1, path.indexOf(']'));
            next = path.substring(path.indexOf(']') + 1);
            if (isVariableName(key)) {
                vars.push(key);
            }

            vars.push(...this.getPathVariables(next));
        } else if (path.includes('.') || path.includes('[')) {
            let i1 = path.indexOf('.');
            let i2 = path.indexOf('[');

            if (i1 === -1) {
                i1 = path.length;
            }
            if (i2 === -1) {
                i2 = path.length;
            }

            const i = Math.min(i1, i2);
            if (i === 0) {
                next = path.substring(1);
            } else {
                next = path.substring(i);
            }
            vars.push(...this.getPathVariables(next));
        }

        return vars;
    }
}


export class DataEngine {
    private namespaces: Map<string, DataNamespace>;

    constructor() {
        this.namespaces = new Map<string, DataNamespace>();
    }

    logNamespaces() {
        const namespaces = Array.from(this.namespaces.values());
        /*
        console.log('Namespaces: ', JSON.stringify(namespaces.map((n) => ({
            id: n.id,
            parents: n.parents,
            values: Array.from(n.values.entries()).reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {} as Record<string, any>),
        })), null, 4));
        */
    }

    // Get the first namespace without parent
    // FIXME: Could be useful to integrate this notion of root namespace
    getRootNamespace() {
        return Array.from(this.namespaces.values()).find((n) => n.parents.length === 0);
    }

    // Gets or create a namespace
    // If provided with a parentId, the namespace will be created as a child of the parent
    getNamespace(id: string) {
        return this.namespaces.get(id);
    }

    hasNamespace(id: string) {
        return this.namespaces.has(id);
    }

    mountNamespace(id: string, parentId?: string) {
        let n = this.namespaces.get(id);
        if (!n) {
            n = new DataNamespace(id, this, parentId ? this.namespaces.get(parentId) : undefined);
            this.namespaces.set(id, n);
        }

        return n;
    }

    unmountNamespace(id: string) {
        if (this.namespaces.has(id)) {
            const n = this.namespaces.get(id);
            this.namespaces.delete(id);
            n?.unmount();
        }
    }

    // Set a value in a namespace
    setValue(namespace: string, key: string, value: any) {
        if (!this.namespaces.has(namespace)) {
            console.error('Data namespace not found:', namespace);
            return;
        }
        this.namespaces.get(namespace)?.setValue(key, value);
    }

    // Get a value from a namespace
    getValue(namespace: string, key: string) {
        this.logNamespaces()
        if (!this.namespaces.has(namespace)) {
            console.error('Data namespace not found:', namespace);
            return undefined;
        }
        return this.namespaces.get(namespace)?.getValue(key);
    }

    setValueOnRootNamespace(key: string, value: any) {
        this.logNamespaces()
        const root = this.getRootNamespace();
        if (!root) {
            console.error('Root namespace not found');
            return;
        }
        root.setValue(key, value);
    }

    getValueFromRootNamespace(key: string) {
        this.logNamespaces()
        const root = this.getRootNamespace();
        if (!root) {
            console.error('Root namespace not found');
            return undefined;
        }
        return root.getValue(key);
    }

    getValueFromFirstNamespaceWithValue(key: string) {
        this.logNamespaces()
        // Validate input
        if (!check.nonEmptyString(key)) {
            return;
        }

        // Get root var name
        const rootVarName = key.split('.')[0].split('[')[0];

        // Lookup for namespace
        const namespace = Array.from(this.namespaces.values()).find((n) => n.values.has(rootVarName));
        if (!namespace) {
            return this.getValueFromRootNamespace(key);
        }

        // Get value
        return namespace.getValue(key);
    }

    setValueOnFirstNamespaceWithValue(key: string, value: any) {
        this.logNamespaces()
        // Validate input
        if (!check.nonEmptyString(key)) {
            return;
        }

        // console.log('Setting value on first namespace with value: '+key, value)

        // Get root var name
        const rootVarName = key.split('.')[0].split('[')[0];

        // Lookup for namespace
        const namespace = Array.from(this.namespaces.values()).find((n) => n.values.has(rootVarName));
        if (!namespace) {
            return this.setValueOnRootNamespace(key, value);
        }

        // Set value
        return namespace.setValue(key, value);
    }
}

const dataEngine = new DataEngine();


const CascadingDataContext = createContext<DataNamespace | null>(null);

export function CascadingDataProvider({ id: propId, data, children } : { id?: string, data: { [key: string]: any }, children: any }) {
    const id = useMemo(() => (propId || uniqid()), [propId]);
    const parentNamespace = useContext(CascadingDataContext);
    const [namespace, setNamespace] = useState<DataNamespace | null>(null);

    useEffect(() => {
        let n : any = null;
        if (dataEngine.hasNamespace(id)) {
            n = dataEngine.getNamespace(id);
            n!.parents = parentNamespace?.id ? [parentNamespace.id] : [];
        } else {
            n = dataEngine.mountNamespace(id, parentNamespace?.id);
        }

        setNamespace(n || null);

        return () => {
            if (n) {
                dataEngine.unmountNamespace(n.id);
                setNamespace(null);
            }
        }

    }, [id, parentNamespace?.id]);

    
     // Add initial data at the beginning and update it when it changes
     useEffect(() => {
        if (!namespace) {
            return;
        }

        if (check.nonEmptyObject(data)) {
            Object.keys(data).forEach((key) => {
                namespace.setValue(key, data[key]);
            });
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [namespace, JSON.stringify(data)]);
     

    return (
        <CascadingDataContext.Provider value={namespace}>
            { children }
        </CascadingDataContext.Provider>
    );
}

function shallowCopy(value: any) {
    if (check.array(value)) {
        return [...value];
    }
    if (check.object(value)) {
        return { ...value };
    }
    return value;
}

export function useAttachedListChangeListener(key: string, listener: (value: DataListChangeEvent) => void) {
    const namespace = useContext(CascadingDataContext);

    useEffect(() => {
        if (!namespace) {
            return;
        }

        namespace.onListChange(key, listener);

        return () => {
            namespace.offListChange(key, listener);
        }
    }, [namespace, key, listener]);
}

export function useCascadingDataVar(key: string, initialValue?: any) {
    const namespace = useContext(CascadingDataContext);
    const [value, setStateValue] = useState(namespace?.getValue(key) || initialValue);

    const setValue = useCallback((newValue: any) => {
        namespace?.setValue(key, newValue);
    }, [namespace, key]);

    useEffect(() => {
        const listener = (newValue: any) => {
            setStateValue((prev: any) => {
                if (JSON.stringify(prev) === JSON.stringify(newValue)) {
                    return prev;
                }
                return shallowCopy(newValue);
            });
        }
        namespace?.onPathUpdate(key, listener);

        return () => {
            namespace?.offPathUpdate(key, listener);
        }
    }, [namespace, key]);

    return [value, setValue];
}

export function useCascadingDataVars(keys: string[], initialValues?: any[]) {
    const namespace = useContext(CascadingDataContext);

    const startValues = (keys || []).reduce((acc, key, i) => {
        if (!check.nonEmptyString(key)) {
            return acc;
        }

        acc[key] = namespace?.getValue(key) || initialValues?.[i];
        return acc;
    }, {} as Record<string, any>);

    const valuesRef = useRef(startValues);
    const [values, setStateValue] = useState(startValues);

    const setValue = useCallback((key: string, newValue: any) => {
        namespace?.setValue(key, newValue);
    }, [namespace]);

    useEffect(() => {
        const listeners = keys.map((key) => {
            if (!check.nonEmptyString(key)) {
                return null;
            }

            const listener = (newValue: any) => {
                if (JSON.stringify(valuesRef.current?.[key]) === JSON.stringify(newValue)) {
                    return;
                }
                valuesRef.current[key] = newValue;
                setStateValue((prev) => ({ ...prev, [key]: newValue }));
            }
            namespace?.onPathUpdate(key, listener);
            return { key, listener };
        }).filter((l) => l !== null);

        return () => {
            listeners.forEach((listener: any) => {
                namespace?.offPathUpdate(listener.key, listener.listener);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [namespace, JSON.stringify(keys), valuesRef]);

    return [values, setValue];
}


// Define a proxy dataProxy which will enable setting/getting variables 
// by referencing them like dataProxy.movies[0].title = '42'
// The proxy should use the main dataEngine object and set/get values on the root namespace
const dataProxy = new Proxy({}, {
    get: function(target: any, key) {
        if (!check.string(key)) {
            return target[key];
        }

        let val = dataEngine.getValueFromFirstNamespaceWithValue(key as string);
        // console.log('getValueFromFirstNamespaceWithValue for key: '+(key as string), val);
        // console.log('getValueFromFirstNamespaceWithValue >> val[0] = ', val?.[0])
        
        // If an array or if undefined, return a proxy with a push method
        // FIXME: Should be a deep proxy
        if (check.array(val) || val === undefined) {
            return new Proxy(val || [], {
                get: function(target: any, subKey) {
                    if (!check.string(subKey)) {
                        return target[subKey];
                    }

                    let arr : any[] = [];
                    // Map push
                    if (subKey === 'push') {
                        return function(value: any) {
                            const prevVal = dataEngine.getValueFromFirstNamespaceWithValue(key as string);

                            let toPush = value;
                            if (!check.nonEmptyObject(value)) {
                                toPush = {
                                    _id: uniqid(),
                                    value,
                                };
                            }

                            dataEngine.setValueOnFirstNamespaceWithValue(
                                key as string, 
                                [
                                    ...(check.nonEmptyArray(prevVal) ? prevVal : []),
                                    toPush,
                                ]
                            );
                        }
                    } else if (subKey === 'clear') { 
                        // Function to clear all values
                        return function() {
                            dataEngine.setValueOnFirstNamespaceWithValue(key as string, []);
                        }
                    } else if (subKey === 'remove') { 
                        // Function to remove an item from the array
                        return function(item: any) {
                            // console.log(`I'm trying to remove item: `, item);
                            const prevVal = dataEngine.getValueFromFirstNamespaceWithValue(key as string);
                            let newVal = [...prevVal];
                            if (check.nonEmptyObject(item) && check.nonEmptyString(item._id)) {
                                newVal = newVal.filter((v :any) => !v || (v !== item && v._id !== item._id));
                            } else {
                                newVal = newVal.filter((v :any) => v !== item);
                            }

                            dataEngine.setValueOnFirstNamespaceWithValue(key as string, newVal);
                        }
                    } else if (check.function(arr[subKey as any])) {
                        // Map any other array function
                        const valueClone = [...dataEngine.getValueFromFirstNamespaceWithValue(key as string) || []];
                        return (...args: any[]) => {
                            const out = valueClone[subKey as any](...args);
                            dataEngine.setValueOnFirstNamespaceWithValue(key as string, valueClone);

                            return out;
                        }
                    }   

                    // Else, return the value
                    const currentVal = dataEngine.getValueFromFirstNamespaceWithValue(key as string) || [];
                    return currentVal[subKey];
                },
            });
        }

        return val; 
    },
    set: function(target, key, value) {
        dataEngine.setValueOnFirstNamespaceWithValue(key as string, value);
        return true;
    }
});

// Try and register the data proxy
if (typeof window !== 'undefined') {
    (window as any).dataProxy = dataProxy
}

export { dataEngine, dataProxy };