import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/utils";

const CardVariantsW = cva("",{ 
    variants: {
        size: {
            "xs": "w-[100px] min-w-[100px] max-w-[100px]",
            "sm": "w-[130px] min-w-[130px] max-w-[130px]",
            "md": "w-40 min-w-40 max-w-40",
            "lg": "w-[201px] min-w-[201px] max-w-[201px]",
            "xl": "w-80 min-w-80 max-w-80",
            "2xl": "w-[500px] min-w-[500px] max-w-[500px]",
            "3xl": "",
            "4xl": "",
            "5xl": "",
            "6xl": "",
        },
        roundness: {
            'none': "rounded-none",
            "sm": "rounded-sm",
            "md": "rounded-md",
            "lg": "rounded-lg",
            "xl": "rounded-xl",
        },
        dark: {
            true: "bg-gray-900",
            false: "",
        },
    },
    defaultVariants: {
        size: "lg",
        roundness: 'xl',
    },
});

const CardVariantsH = cva("",{ 
    variants: {
        size: {
            "xs": "h-[173px] min-h-[173px] max-w-[173px]",
            "sm": "h-[200px] min-h-[200px] max-w-[200px]",
            "md": "h-[246px] min-h-[246px] max-h-[246px]",
            "lg": "h-[358px] min-h-[358px] max-h-[358px]",
            "xl": "h-[426px] min-h-[426px] max-h-[426px]",
            "2xl": "h-[675px] min-h-[675px] max-h-[675px]",
            "3xl": "",
            "4xl": "",
            "5xl": "",
            "6xl": "",
        },
    },
    defaultVariants: {
        size: "lg",
    },
});

interface CardProps extends VariantProps<typeof CardVariantsW> {
    image : string,
    title: string,
    description: string,
}

function Card_P({
    image,
    title,
    description,
    size,
    roundness,
    dark,
} : CardProps ) {
    return (
        <div className={cn(CardVariantsW({size}), `h-fit flex-col justify-start items-start pb-[5px] `)}>
            <img className={cn(CardVariantsW({size, roundness}), CardVariantsH({size}), "aspect-[9/16] object-cover")} src={image} />
            <div className={cn(CardVariantsW({size}),"self-stretch text-neutral-900 dark:text-white typeface-display-base mt-[12px]")}>{title}</div>
            <div className="self-stretch text-slate-500 dark:text-slate-400 typeface-body-sm max-w-full min-h-fit ">{description}</div>
        </div>
    )
}

export default Card_P;