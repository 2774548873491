import { ComponentType } from 'react';
import { ReactComponent as AccordionIcon } from './assets/accordion_icon.svg';
import { ReactComponent as AlertIcon } from './assets/alert_icon.svg';
import { ReactComponent as AspectRatioIcon } from './assets/aspect-ratio_icon.svg';
import { ReactComponent as AutoLayoutIcon } from './assets/auto-layout_icon.svg';
import { ReactComponent as AvatarIcon } from './assets/avatar_icon.svg';
import { ReactComponent as BadgeIcon } from './assets/badge_icon.svg';
import { ReactComponent as ButtonIcon } from './assets/button_icon.svg';
import { ReactComponent as CalendarIcon } from './assets/calendar_icon.svg';
import { ReactComponent as CardIcon } from './assets/card_icon.svg';
import { ReactComponent as CarouselIcon } from './assets/carousel_icon.svg';
import { ReactComponent as CenterIcon } from './assets/center_icon.svg';
import { ReactComponent as CheckboxIcon } from './assets/checkbox_icon.svg';
import { ReactComponent as CollapsibleIcon } from './assets/collapsible_icon.svg';
import { ReactComponent as CommandIcon } from './assets/command_icon.svg';
import { ReactComponent as ContextMenuIcon } from './assets/context-menu_icon.svg';
import { ReactComponent as DataIcon } from './assets/data_icon.svg';
import { ReactComponent as DialogIcon } from './assets/dialog_icon.svg';
import { ReactComponent as DivIcon } from './assets/div_icon.svg';
import { ReactComponent as DropdownMenuIcon } from './assets/dropdown-menu_icon.svg';
import { ReactComponent as H1Icon } from './assets/h1_icon.svg';
import { ReactComponent as H2Icon } from './assets/h2_icon.svg';
import { ReactComponent as H3Icon } from './assets/h3_icon.svg';
import { ReactComponent as HoverCardIcon } from './assets/hover-card_icon.svg';
import { ReactComponent as HstackIcon } from './assets/hstack_icon.svg';
import { ReactComponent as IconIcon } from './assets/icon_icon.svg';
import { ReactComponent as IllustrationIcon } from './assets/illustration_icon.svg';
import { ReactComponent as ImageIcon } from './assets/image_icon.svg';
import { ReactComponent as InputIcon } from './assets/input_icon.svg';
import { ReactComponent as KeyboardIcon } from './assets/keyboard_icon.svg';
import { ReactComponent as LabelIcon } from './assets/label_icon.svg';
import { ReactComponent as ListIcon } from './assets/list_icon.svg';
import { ReactComponent as LogoIcon } from './assets/logo_icon.svg';
import { ReactComponent as MenubarIcon } from './assets/menubar_icon.svg';
import { ReactComponent as NavigationMenuIcon } from './assets/navigation-menu_icon.svg';
import { ReactComponent as PaginationIcon } from './assets/pagination_icon.svg';
import { ReactComponent as PopoverIcon } from './assets/popover_icon.svg';
import { ReactComponent as ProgressIcon } from './assets/progress_icon.svg';
import { ReactComponent as ResizableIcon } from './assets/resizable_icon.svg';
import { ReactComponent as ScrollAreaIcon } from './assets/scroll-area_icon.svg';
import { ReactComponent as SelectIcon } from './assets/select_icon.svg';
import { ReactComponent as SeparatorIcon } from './assets/separator_icon.svg';
import { ReactComponent as SheetIcon } from './assets/sheet_icon.svg';
import { ReactComponent as SkeletonIcon } from './assets/skeleton_icon.svg';
import { ReactComponent as SliderIcon } from './assets/slider_icon.svg';
import { ReactComponent as SwitchIcon } from './assets/switch_icon.svg';
import { ReactComponent as TableIcon } from './assets/table_icon.svg';
import { ReactComponent as TabsIcon } from './assets/tabs_icon.svg';
import { ReactComponent as TextAreaIcon } from './assets/text-area_icon.svg';
import { ReactComponent as ToggleGroupIcon } from './assets/toggle-group_icon.svg';
import { ReactComponent as ToggleIcon } from './assets/toggle_icon.svg';
import { ReactComponent as TooltipIcon } from './assets/tooltip_icon.svg';
import { ReactComponent as VstackIcon } from './assets/vstack_icon.svg';

const componentIcons: { [tag:string]: ComponentType } = {
	'accordion': AccordionIcon,
	'alert': AlertIcon,
	'aspect-ratio': AspectRatioIcon,
	'auto-layout': AutoLayoutIcon,
	'avatar': AvatarIcon,
	'badge': BadgeIcon,
	'button': ButtonIcon,
	'calendar': CalendarIcon,
	'card': CardIcon,
	'carousel': CarouselIcon,
	'center': CenterIcon,
	'checkbox': CheckboxIcon,
	'collapsible': CollapsibleIcon,
	'command': CommandIcon,
	'context-menu': ContextMenuIcon,
	'data': DataIcon,
	'dialog': DialogIcon,
	'div': DivIcon,
	'dropdown-menu': DropdownMenuIcon,
	'h1': H1Icon,
	'h2': H2Icon,
	'h3': H3Icon,
	'hover-card': HoverCardIcon,
	'hstack': HstackIcon,
	'icon': IconIcon,
	'illustration': IllustrationIcon,
	'image': ImageIcon,
	'input': InputIcon,
	'keyboard': KeyboardIcon,
	'label': LabelIcon,
	'list': ListIcon,
	'logo': LogoIcon,
	'menubar': MenubarIcon,
	'navigation-menu': NavigationMenuIcon,
	'pagination': PaginationIcon,
	'popover': PopoverIcon,
	'progress': ProgressIcon,
	'resizable': ResizableIcon,
	'scroll-area': ScrollAreaIcon,
	'select': SelectIcon,
	'separator': SeparatorIcon,
	'sheet': SheetIcon,
	'skeleton': SkeletonIcon,
	'slider': SliderIcon,
	'switch': SwitchIcon,
	'table': TableIcon,
	'tabs': TabsIcon,
	'text-area': TextAreaIcon,
	'toggle-group': ToggleGroupIcon,
	'toggle': ToggleIcon,
	'tooltip': TooltipIcon,
	'vstack': VstackIcon,
}


export function iconForTags(tags: string[]) : ComponentType | null {
    return tags.map(tag => componentIcons[tag]).find(Boolean) || null;
}


export default componentIcons;