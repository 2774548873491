import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"
import { useComponentCvaTheme } from "@/vendors/theme/useComponentCvaTheme"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        icon: "h-10 w-10",
        default: "h-10 px-4 py-2 typeface-body-md",
        xs: "h-8 rounded-md px-2 typeface-body-xs",
        sm: "h-9 rounded-md px-3 typeface-body-sm",
        md: "h-10 px-4 rounded-md typeface-body-md",
        lg: "h-11 rounded-md px-8 typeface-body-lg",
        xl: "h-12 rounded-lg px-10 typeface-body-xl",
        "2xl": "h-14 rounded-lg px-12 typeface-body-2xl",
        "3xl": "h-16 rounded-xl px-14 typeface-body-3xl",
        "4xl": "h-20 rounded-xl px-16 typeface-body-4xl",
        "5xl": "h-24 rounded-2xl px-20 typeface-body-5xl",
        "6xl": "h-28 rounded-2xl px-24 typeface-body-6xl",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const buttonTheme = useComponentCvaTheme("Button");
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), buttonTheme({ variant, size, className }), className)}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
