import check from "@/vendors/check";
import uniqid from "uniqid";
import { ComponentTreeNode } from "./components/component.type";
import { jsxToTree } from "./jsx-utils";
import { JsonXNode } from "./parser-v2";
import { cn, onlyUnique } from "../utils";
import { mergeClasses, mergeProps, removeEmptyProps } from "./util";
import { ComponentType } from "react";
import upperlower from "upperlower";
import { isTextComponent, normalizeTextComponent } from "./components/shadcn/_utils";

export type JsonXNodeWithId = {
    __id: string,
    component: string;
    props: {
        [key: string]: any;
    };
    children: (JsonXNodeWithId | string)[];
    [key: string]: any;
}

export type ComponentAliasesSpec = { [componentName: string]: { component: string | ComponentType, htmlComponent: string, aliases: any[] } };

export type SimplifiedComponentAliasesSpec = { [componentName: string]: string | ComponentType };

// Transforms a list of React components into a spec with component, htmlComponent and aliases
export function componentAliasesSpec(...components: (ComponentType | string | [ComponentType, string])[]): ComponentAliasesSpec {
    const spec = components.reduce((acc, component) => {
        if (check.array(component) && component.length === 2 && check.nonEmptyString(component[1])) {
            acc[component[1]] = component[0];
            return acc;
        }
        if (!check.nonEmptyString(component) && !check.nonEmptyString((component as any || {}).displayName)) {
            throw new Error ('Calling componentAliasesSpec() on component without displayName:' + component.toString());
        }

        if (check.nonEmptyString(component)) {
            acc[component] = component;
        } else if (check.nonEmptyString((component as any).displayName) && !check.array(component)) {
            acc[(component as any).displayName] = component;
        }
        return acc;
    }, {} as SimplifiedComponentAliasesSpec);

    let detailedSpec = Object.entries(spec).reduce((acc, [key, value]) => {
        // Transform SelectGroupItem into ['item', 'group-item', 'select-group-item']
        const kebapKey = key.split(/(?=[A-Z])/).map((s) => s.toLowerCase()).join('-');
        let keyParts = kebapKey.split('-')
        let aliases = [];
        for (let i = 0; i < keyParts.length; i++) {
            aliases.push(keyParts.slice(i).join('-'));
        }

        acc[key] = {
            component: value,
            htmlComponent: key,
            aliases: [key, value, ...aliases, ...aliases.map(a =>  upperlower(a.replaceAll(/-/g, ' '), 'pascalcase'))].filter(onlyUnique),
        };
        return acc;
    }, {} as ComponentAliasesSpec);

    // Ban aliases used on multiple components
    let knownStringAliases = new Set();
    let bannedStringAliases = new Set();
    Object.values(detailedSpec).forEach((specItem) => {
        specItem.aliases
            .filter(check.nonEmptyString)
            .forEach((alias) => {
                if (knownStringAliases.has(alias)) {
                    bannedStringAliases.add(alias);
                } else {
                    knownStringAliases.add(alias);
                }
            });
    });
    return Object.entries(detailedSpec).reduce((acc, [key, value]) => {
        acc[key] = {
            ...value,
            aliases: value.aliases.filter((alias) => !bannedStringAliases.has(alias)),
        };
        return acc;
    }, {} as ComponentAliasesSpec);
}

function parseParentChainsFromTree(node: JsonXNode, parents: string[], registerParentChain: (componentName: string, parentChain: string[]) => void) {
    if (!check.nonEmptyObject(node)) {
        return;
    }
    
    (node.children || [])
        .forEach((child) => {
            parseParentChainsFromTree(child as JsonXNode, [...parents, node.component as string], registerParentChain);
        });

    registerParentChain(node.component, parents);
}

function enforceMinimalParentChainsInTree(node: JsonXNode | string, nodeParentChain: string[], minimalParentChainsByType : { [componentName: string]: string[] }) : JsonXNode | string {
    if (!check.nonEmptyObject(node)) {
        return node;
    }

    // Check if the component should be wrapped in a parent
    let minimalParentChain = minimalParentChainsByType[node.component];
    if (!minimalParentChain) {
        // If component has no defined parent chain, apply the minimum parent chain of the 
        // "Rest" component (which is an optional wildcard slot for all other components)
        minimalParentChain = minimalParentChainsByType['Rest'];
    }

    if (!check.nonEmptyArray(minimalParentChain)) {
        return {
            ...node,
            children: (node.children || []).map((child) => enforceMinimalParentChainsInTree(
                child, 
                [...nodeParentChain, node.component], 
                minimalParentChainsByType
            )),
        };
    }

    // If the component is missing a parent, wrap it recursively into the right parents
    // Note: this will parse children later on
    const shouldBeWrappedInto = minimalParentChain[minimalParentChain.length - 1];
    if (!nodeParentChain.includes(shouldBeWrappedInto)) {
        return enforceMinimalParentChainsInTree({
            component: shouldBeWrappedInto,
            props: {},
            children: [node]
        }, nodeParentChain, minimalParentChainsByType);
    }

    // Else, return the component after parsing its children
    return {
        ...node,
        children: (node.children || []).map((child) => enforceMinimalParentChainsInTree(
            child, 
            [...nodeParentChain, node.component], 
            minimalParentChainsByType
        )),
    };
}

function listComponentTypesMatching(node: JsonXNode | string, test: (n: JsonXNode) => boolean): { type: string, hasChildren: boolean }[] {
    let out: { type: string, hasChildren: boolean }[] = [];

    if (!check.nonEmptyObject(node)) {
        return [];
    }

    if (test(node)) {
        out.push({type: node.component, hasChildren: check.nonEmptyArray(node.children) });
    }

    out.push(...(node.children || []).flatMap((child) => listComponentTypesMatching(child, test)));

    return out.filter(onlyUnique);
}

function addIdsToTree(node: JsonXNode | string): JsonXNodeWithId | string {
    if (!check.nonEmptyObject(node)) {
        return node;
    }
    return {
        ...node,
        __id: (node as any).__id || uniqid(),
        children: (node.children || []).map(addIdsToTree),
    };
}

function grabAllChildrenOfTypeWithIdAndPath(node: JsonXNodeWithId | string, type: string, parents: string[]): { node: JsonXNodeWithId, parents: string[] }[] {
    if (!check.nonEmptyObject(node)) {
        return [];
    }

    let grabbed: { node: JsonXNodeWithId, parents: string[] }[] = [];
    if (node.component === type) {
        grabbed = [{ node, parents }];
    }

    return [...grabbed, ...(node.children || []).flatMap((child) => grabAllChildrenOfTypeWithIdAndPath(child, type, [...parents, node.__id]))];
}

function replaceComponentInTree(node: JsonXNodeWithId | string, replacement: JsonXNodeWithId): JsonXNodeWithId | string {
    if (!check.nonEmptyObject(node)) {
        return node;
    }

    if (node.__id === replacement.__id) {
        return replacement;
    }

    return {
        ...node,
        children: (node.children || []).map((child) => replaceComponentInTree(child, replacement)),
    };
}

function deleteComponentsById(node: JsonXNodeWithId | string, ids: string[]): JsonXNodeWithId | string | null {
    if (!check.nonEmptyObject(node)) {
        return node;
    }

    if (ids.includes(node.__id)) {
        return null;
    }

    return {
        ...node,
        children: (node.children || []).map((child) => deleteComponentsById(child, ids)).filter(c => c !== null) as (JsonXNodeWithId | string)[],
    };
}

function mergeComponentsOfTypeToLowestInTree(node: JsonXNodeWithId | string, type: string): JsonXNodeWithId | string {
    if (!check.nonEmptyObject(node)) {
        return node;
    }

    // Extract all children of the right type, with their path
    const grabbed = grabAllChildrenOfTypeWithIdAndPath(node, type, []);

    // If we didn't find any component of this type, return the node as is
    if (grabbed.length === 0) {
        return node;
    }

    // Merge all other components into the shortest parent chain
    const sorted = grabbed.sort((a, b) => a.parents.length - b.parents.length);
    const targetComponent = sorted.shift()!;

    const { classes, props, text, variant, children } = getClassesAndPropsFromChildren(node, type);

    const merged = {
        ...targetComponent.node,
        __id: targetComponent.node.__id,
        component: targetComponent.node.component,
        classes, 
        props, 
        text,
        variant, 
        children,
    };

    // Replace the merged component in the tree and delete others 
    const idsToDelete = grabbed.filter((g) => g.node.__id !== targetComponent.node.__id).map((s) => s.node.__id);
    const replaced = replaceComponentInTree(node, merged);
    const clean = deleteComponentsById(replaced, idsToDelete)!;

    return clean;
}

function mergeTexts(...texts: (string | null)[]): string | null {
    const text = texts.filter(check.nonEmptyString).join('');
    return check.nonEmptyString(text) ? text : null;
}
function mergeVariants(...variants: (string | null)[]): string | null {
    return variants.filter(check.nonEmptyString).shift() || null;
}

function getClassesAndPropsFromChildren(node: JsonXNodeWithId, type: string): { classes: string[], props: { [key: string]: any }, text: string | null, variant: string | null, children: (JsonXNodeWithId | string)[] } {
    let classes: string[] = [];
    let props: { [key: string]: any } = {};
    let variant = null;
    let text = null;
    let children : (JsonXNodeWithId | string)[] = [];

    if (node.component === type) { 
        classes = mergeClasses(classes, node.classes || []);
        props = mergeProps(props, node.props || {});
        variant = mergeVariants(variant, node.variant || null);
        text = mergeTexts(text, node.text || null);
        children = [...node.children || []]
    }

    const fromChildren = ((node.children || []).filter(c => check.nonEmptyObject(c)) as JsonXNodeWithId[]).map((c: JsonXNodeWithId) => getClassesAndPropsFromChildren(c, type));
    classes = mergeClasses(classes, fromChildren.flatMap(c => c.classes));
    props = mergeProps(props, ...fromChildren.map(c => c.props));
    variant = mergeVariants(variant, ...fromChildren.map(c => c.variant));
    text = mergeTexts(text, ...fromChildren.map(c => c.text));
    children = [...(children || []), ...fromChildren.map(c => c.children).flat()]

    return { classes, props, text, variant, children };
}

function countOccurencesOfType(node: JsonXNodeWithId | string, type: string, parentCount = 0, stopAt = 0): number {
    if (!check.nonEmptyObject(node)) {
        return 0;
    }

    let newCount = parentCount;

    if (node.component === type) {
        newCount += 1;
    }

    if (stopAt > 0 && newCount >= stopAt) {
        return newCount;
    }

    // Check children
    for (let i = 0; i < (node.children || []).length; i++) {
        newCount += countOccurencesOfType(node.children[i], type, newCount, stopAt);
        if (stopAt > 0 && newCount >= stopAt) {
            return newCount;
        }
    }

    return newCount;
}

function hasMultipleOccurencesOfType(node: JsonXNodeWithId | string, type: string): boolean {
    return countOccurencesOfType(node, type, 0, 2) > 1;
}

function hasMultipleOccurencesOfAtLeastOneType(node: JsonXNodeWithId | string, types: string[]): boolean {
    return types.some(type => hasMultipleOccurencesOfType(node, type));
}

// This function will wrap two components having the same type in their children branches in a common parent
function factorizeEncapsulationType(tree: JsonXNodeWithId | string, type: string): JsonXNodeWithId | string {
    if (!check.nonEmptyObject(tree)) {
        return tree;
    }

    function hasTargetType(n: JsonXNodeWithId): boolean {
        if (n.component === type) return true;
        return n.children.some(c => check.object(c) && hasTargetType(c));
    }

    function removeWrapperComponentFromChildren(node: JsonXNodeWithId, type: string): JsonXNodeWithId {
        const newChildren = (node.children || []).map(c => {
            if (!check.nonEmptyObject(c)) {
                return c;
            }

            if (c.component !== type) {
                return removeWrapperComponentFromChildren(c, type);
            }

            return c.children || [];
        }).flat();

        return {
            ...node,
            children: newChildren,
        };
    }

    function encapsulate(n: JsonXNodeWithId): JsonXNodeWithId {
        if (n.component === type) {
            return n;
        }

        // Check if the current node's has at least 2 children including target type into their children or subchildren
        if (n.children.filter(c => check.nonEmptyObject(c) && hasTargetType(c)).length > 1) {

            // Wrap the children in a new parent
            // Grab classes and props from the children
            const { classes, props, text, variant } = getClassesAndPropsFromChildren(n, type);

            let wrapped = false;


            return {
                ...n,
                children: n.children.map(c => {
                    if (!check.nonEmptyObject(c)) {
                        return c;
                    }

                    if (hasTargetType(c)) {
                        if (wrapped) {
                            return null;
                        }

                        wrapped = true;
                        return removeWrapperComponentFromChildren({
                            __id: uniqid(),
                            component: type,
                            htmlComponent: type,
                            classes,
                            props,
                            text,
                            variant,
                            children: (n.children || []).filter(c => check.nonEmptyObject(c) && hasTargetType(c)),
                        }, type);
                    }

                    return c;
                }).filter(Boolean) as (string | JsonXNodeWithId)[],
            };
        }

        // Else, continue climbing the tree
        return {
            ...n,
            children: (n.children || []).map(c => {
                if (check.nonEmptyObject(c)) {
                    return encapsulate(c);
                }
                return c;
            }),
        };
    }

    return encapsulate(tree);
}

function componentTreeNodeToJsonXNode(node: ComponentTreeNode, componentsAliases: ComponentAliasesSpec, configPerType: { [type: string]: { [key: string]: any } }): JsonXNode | string {
    if (!check.nonEmptyObject(node)) {
        return node;
    }

    const componentSpec = Object.values(componentsAliases).find(spec => (
        // (spec.component && spec.component === node.component)
        // || (check.nonEmptyString(spec.htmlComponent) && spec.htmlComponent === node.component)
        // || 
        (spec.aliases || []).some(a => a === node.htmlComponent)
        || (spec.aliases || []).some(a => a === node.component)
        // || (spec.component && spec.component === node.htmlComponent)
        // || (check.nonEmptyString(spec.htmlComponent) && spec.htmlComponent === node.htmlComponent)
    ));

    let component;

    if (!componentSpec) {
        if (!check.nonEmptyString(node.component)) {
            if (check.nonEmptyString(node.htmlComponent)) {
                component = node.htmlComponent;
            } else {
                component = 'UnknownComponent';
            }
        } else {
            component = node.component;
        }
    } else {
        component = componentSpec.htmlComponent;
    }

    // Prepare output component
    const out = {
        ...node,
        component,
        props: node.props,
        children: (node.children || []).map((child) => componentTreeNodeToJsonXNode(child, componentsAliases, configPerType)),
    };

    // /////////////////////////////////////////////
    // Apply component config-based modifications
    // /////////////////////////////////////////////
    const config = configPerType[component] || {};

    // Text --> child component
    if (check.nonEmptyString(config.__textAsChildComponent)) {
        if (check.nonEmptyString(node.text)) {
            const type = config.__textAsChildComponent;
            out.children = [{ component: type, props: {}, children: [node.text] }, ...out.children];
            out.text = null;
        }
    }

    // Text children --> child component prop
    if (check.nonEmptyString(config.__textAsChildComponentProp)) {
        if (check.nonEmptyString(node.text) && config.__textAsChildComponentProp.includes('.')) {
            const [type, prop] = config.__textAsChildComponentProp.split('.');
            out.children = [{ component: type, props: { [prop]: node.text }, children: [] }, ...out.children];
            out.text = null;
        }
    }

    // Text children --> child components
    if (check.nonEmptyString(config.__textChildrenAsChildComponents)) {
        const type = config.__textChildrenAsChildComponents;
        out.children = out.children.map((child) => {
            if (check.string(child)) {
                return { component: type, props: {}, children: [child] };
            }
            if (check.nonEmptyObject(child) && child.component === 'text') {
                // FIXME: Prone to bugs. Make sure all text components are normlized 
                //        beforehand to avoid this kind of spaghetti logic
                return { ...child, component: type, children: (child as any).text ? [(child as any).text] : (child.children || []) };
            }
            return child;
        });
    }

    return out;
}

// Experiment: transform all text components matching a specific markdown syntax before any other parsing    
export function experimentalMarkdownTransformOfTextComponents(node: ComponentTreeNode | string) : ComponentTreeNode | string {
    // return node; // Disabling for now

    
    function parseTextComponent(text: string): ComponentTreeNode | null {
        if (!check.nonEmptyString(text)) {
            return null;
        }

        let children : any[] = [text];
        let component = 'text';
        let props = {};
        let variant = null;

        const RADIO = () => /^[(]{1}\s*[xXoOvV.*+_-]{0,1}\s*[)]{1}$/g;
        const CHECKBOX = () => /^\[\s*[xXoOvV.*+_-]{0,1}\s*\]$/g;
        const SWITCH_HAS = () => /^<\s*[xXoOvV.*+_-]{0,1}\s*>$/g;

        if (RADIO().test(text.trim())) {
            children = [];
            component = 'radio';
        } else if (CHECKBOX().test(text.trim())) {
            children = [];
            component = 'checkbox';
        } else if (SWITCH_HAS().test(text.trim())) {
            children = [];
            component = 'switch';
        } else if (text.trim().startsWith('####')) {
            children = [text.trim().slice(4)];
            component = 'h4';
        } else if (text.trim().startsWith('###')) {
            children = [text.trim().slice(3)];
            component = 'h3';
        } else if (text.trim().startsWith('##')) {
            children = [text.trim().slice(2)];
            component = 'h2';
        } else if (text.trim().startsWith('#')) {
            children = [text.trim().slice(1)];
            component = 'title';
        } else if (/^-{2,}$/g.test(text.trim())) {
            children = [];
            component = 'separator';
        } else if (/^--\s*[A-Za-z0-9]+/g.test(text.trim())) {
            children = [text.trim().slice(2)];
            component = 'description';
        } else if (/^\[>>.+\]$/g.test(text.trim())) {
            children = [];
            component = 'textarea';
            props = {
                type: "text",
                placeholder: text.trim().slice(3, -1),
            };
        } else if (/^\[>.+\]$/g.test(text.trim())) {
            children = [];
            component = 'input';
            props = {
                type: "text",
                placeholder: text.trim().slice(2, -1),
            };
        } else if (/^\[.+\]$/g.test(text.trim())) {
            children = [text.trim().slice(1, -1)];
            component = 'button';
        } else if (/^\(.+\)$/g.test(text.trim())) {
            children = [text.trim().slice(1, -1)];
            component = 'button';
            variant = 'link';
        } else {
            return null;
        }

        return {
            component,
            htmlComponent: component,
            props,
            classes: [],
            children,
            variant,
        };
    }

    if (check.nonEmptyString(node)) {
        return parseTextComponent(node) || node;
    }

    if (!check.nonEmptyObject(node)) {
        return node;
    }

    if (isTextComponent(node)) {
        const text = normalizeTextComponent(node)!.text!;
        return parseTextComponent(text) || node;
    }

    return {
        ...node,
        children: (node.children || []).map(experimentalMarkdownTransformOfTextComponents),
    };
    
}

export function applyStylesToComponentTreeNode(node: ComponentTreeNode, styleContext: { [type: string]: string } = {}): ComponentTreeNode {
    if (!check.nonEmptyObject(node)) {
        return node;
    }

    let context = styleContext;

    // If node has a style children, update the context
    const styleChildren = (node.children || []).filter(c => check.nonEmptyObject(c) && c.component === 'style');
    styleChildren.forEach((styleNode) => {
        const newStyleContext = (styleNode.children || []).reduce((acc: any, child: any) => {
            if (check.nonEmptyObject(child) && check.nonEmptyString(child.component)) {
                acc[child.component] = cn(child.classes);
            }
            return acc;
        }, {});

        context = { ...styleContext, ...newStyleContext };
    });

    // Then, return the node with updated children classes and no more style children
    const newChildren = (node.children || []).filter(c => !check.nonEmptyObject(c) || c.component !== 'style').map((child) => applyStylesToComponentTreeNode(child, context));

    return {
        ...node,
        classes: mergeClasses(context[node.component as string] ? context[node.component as string].split(' ') : [], node.classes ),
        children: newChildren,
    };
}

function jsonXNodeWithIdToComponentTreeNode(node: JsonXNodeWithId | string, componentsAliases: ComponentAliasesSpec): ComponentTreeNode | string {
    if (!check.nonEmptyObject(node)) {
        return node;
    }

    const componentSpec = Object.values(componentsAliases).find(spec => (
        // (spec.component && spec.component === node.component)
        // || (check.nonEmptyString(spec.htmlComponent) && spec.htmlComponent === node.component)
        // || 
        (spec.aliases || []).some(a => a === node.component)
        || (spec.aliases || []).some(a => a === node.htmlComponent)
        // || (spec.component && spec.component === node.htmlComponent)
        // || (check.nonEmptyString(spec.htmlComponent) && spec.htmlComponent === node.htmlComponent)
    ));

    const { __id, ...rest } = node;

    let component;
    let htmlComponent;
    if (!componentSpec) {
        if(check.nonEmptyString(node.component)) {
            component = node.component;
            htmlComponent = node.component;
        } else {
            component = node.component;
            htmlComponent = rest.htmlComponent || 'UnknownComponent';
        }
    } else {
        component = componentSpec.component;
        htmlComponent = componentSpec.htmlComponent;
    }

    return {
        ...rest,
        component,
        htmlComponent,
        props: node.props,
        classes: check.nonEmptyString(node.props?.className) ? node.props.className.split(' ') : [],
        children: (node.children || []).map((child) => jsonXNodeWithIdToComponentTreeNode(child, componentsAliases)),
    } as ComponentTreeNode;
}

export function propsPerType(node: JsonXNode | string): { [type: string]: { [key: string]: any } } {
    let byType : { [type: string]: { [key: string]: any } } = {};

    if (!check.nonEmptyObject(node)) {
        return byType;
    }

    if (!byType[node.component]) {
        byType[node.component] = node.props || {};
    } else {
        byType[node.component] = mergeProps(byType[node.component], node.props || {});
    }

    (node.children || []).forEach((child) => {
        const childProps = propsPerType(child);
        Object.keys(childProps).forEach((type) => {
            if (!byType[type]) {
                byType[type] = childProps[type];
            }
            byType[type] = mergeProps(byType[type], childProps[type]);
        });
    });

    return byType;
}

export function applyDefaultPropsTree(node: JsonXNodeWithId | string, defaultProps: { [type: string]: { [key: string]: any } }): JsonXNodeWithId | string {
    if (!check.nonEmptyObject(node)) {
        return node;
    }

    let props = {...(defaultProps[node.component] || {})};
    Object.keys(props).filter(p => p.startsWith('__')).forEach((schemaOnlyProp) => {
        delete props[schemaOnlyProp];
    });

    return {
        ...node,
        props: mergeProps(props, node.props || {}, { className: cn(node.classes) }),
        children: (node.children || []).map((child) => applyDefaultPropsTree(child, defaultProps)),
    };
}

// Takes a tree as input and retreives the component at a path specified by an aray of types, like so: 
// getComponentAtPath(tree, ['AlertDialog[0]', 'AlertDialogContent[0]', 'Slot[3]', 'Alert[0]', 'AlertTitle[2]'])
function getComponentAtTypePath(node: JsonXNodeWithId | string, path: string[]): JsonXNodeWithId | string | null {
    if (!check.nonEmptyObject(node)) {
        return null;
    }

    if (path.length === 0) {
        return node;
    }

    const [head, ...tail] = path;
    
    if (!check.nonEmptyString(head) || !/^[A-Za-z0-9]+\[[0-9]+\]$/.test(head)) {
        return null;
    }

    const [type, index] = head.split('[');
    const i = parseInt(index.slice(0, -1), 10);

    const childrenForType = (node.children || []).filter(c => check.nonEmptyObject(c) && c.component === type);
    if (childrenForType.length <= i) {
        return null;
    }

    return getComponentAtTypePath(childrenForType[i], tail);
}

// Parse schema tree and return the list of components having an __enforceChildrenOrder prop
// along with theit type path in the tree and the order of children they expect
function getEnforceChildrenOrderPolicies(node: JsonXNodeWithId | string, parentPath: string[] = [], itemIndexByType = 0): { path: string[], node: JsonXNodeWithId | string, order:  string[] }[] {
    if (!check.nonEmptyObject(node)) {
        return [];
    }

    const path = check.emptyArray(parentPath) ? ['ROOT'] : [...parentPath, node.component + `[${itemIndexByType}]`];
    let policy : { path: string[], node: JsonXNodeWithId | string, order:  string[] }[] = [];

    if (node.props?.__enforceChildrenOrder !== undefined) {
        policy = [
            {
                path,
                node,
                order: (node.children || []).map((c: JsonXNodeWithId | string) => check.nonEmptyObject(c) ? c.component : 'text'),
            }
        ]
    }

    let indexesByType : { [type: string]: number } = {};

    const childrenPolicies = (node.children || []).flatMap((child) => {
        const type = check.nonEmptyObject(child) ? child.component : 'text';
        indexesByType[type] = (indexesByType[type] || -1) + 1;
        return getEnforceChildrenOrderPolicies(child, path, indexesByType[type]);
    });

    return [...policy, ...childrenPolicies, ].map(p => ({ 
        ...p,
        path: p.path.filter((p) => p !== 'ROOT'),
    })); 
}

function enforeChildrenOrder(node: JsonXNodeWithId | string, order: string[]): JsonXNodeWithId | null {
    if (!check.nonEmptyObject(node) || !check.nonEmptyArray(node.children)) {
        return null;
    }

    let prevChildren = [...node.children];
    let newChildren : (JsonXNodeWithId | string)[] = [];
    const restPlaceholder = 'REST_PLACEHOLDER_'+uniqid();

    // Browse component by order and insert them in the new children array
    for (let i = 0; i < order.length; i++) {
        const type = order[i];
        if (type === 'Rest') {
            newChildren.push(restPlaceholder);
        }
        let selectedChild: JsonXNodeWithId | string | null = null;
        prevChildren = prevChildren.map((c) => {
            if (selectedChild) {
                return c;
            }
            if (type === 'text' && check.string(c)) {
                selectedChild = c;
                return null;
            } else if (check.nonEmptyObject(c) && c.component === type) {
                selectedChild = c;
                return null;
            }

            return c;
        }).filter(c => !!c) as (JsonXNodeWithId | string)[];

        if (selectedChild) {
            newChildren.push(selectedChild);
        }
    }

    // Add the rest of the children, either at the end or at the position of the rest placeholder
    const rest = prevChildren;
    if (newChildren.includes(restPlaceholder)) {
        newChildren = newChildren.map(c => c === restPlaceholder ? rest : c).flat();
    } else {
        newChildren.push(...rest);
    }
    
    // Return the node with the new children
    return {
        ...node,
        children: newChildren,
    };
}

function enforceChildrenOrderAtTypePath(tree: JsonXNodeWithId | string, path: string[], order: string[]) {
    if (!check.nonEmptyObject(tree)) {
        return;
    }

    const targetNode = getComponentAtTypePath(tree, path);
    if (!check.nonEmptyObject(targetNode)) {
        return;
    }

    const node = enforeChildrenOrder(targetNode, order);
    if (!check.nonEmptyObject(node)) {
        return;
    }

    targetNode.children = node.children;
}


// Rules:
// - Schema components are always at the same position in the tree
// - Slots grab components of a specific type, to place them as children of their parent, whitout changing their position relative to their siblings
/* 
Example schema: 
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Parent />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle />
          <AlertDialogDescription />
        </AlertDialogHeader>
        <Slot name="rest" />
        <AlertDialogFooter>
          <AlertDialogCancel __allowMultiple />
          <AlertDialogAction __allowMultiple />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
*/

function getConfigPerType(schema: JsonXNode | string): { [type: string]: { [key: string]: any } } {
    let config : { [type: string]: { [key: string]: any } } = {};

    if (!check.nonEmptyObject(schema)) {
        return config;
    }

    // Fill config for current component
    config[schema.component] = Object.keys(schema.props || {})
        .filter(prop => prop.startsWith('__'))
        .reduce((acc: any, key: string) => {
            acc[key] = schema.props[key];
            return acc;
        },  (config[schema.component] || {}) as { [key:string]: any });

    // Get config from children recursively
    (schema.children || []).forEach((child) => {
        const childConfig = getConfigPerType(child);
        Object.keys(childConfig).forEach((type) => {
            config[type] = { ...(config[type] || {}), ...childConfig[type] };
        });
    });

    return config;
}

type ParsedSchema = { 
    schema: JsonXNode;
    minimalParentChainsByType: { [componentName: string]: string[] };
    configPerType: { [type: string]: { [key: string]: any } };
    typesToMerge: { type: string, hasChildren: boolean }[];
    defaultPropsPerType: { [type: string]: { [key: string]: any } };
    enforceChildrenOrderPolicies: { path: string[], order: string[] }[];
};

// Group all the parsing of JSX schemas in a single function and cache the results
const schemas = new Map<string, ParsedSchema>();
function parseJsxSchema(jsxSchema: string): ParsedSchema {
    if (schemas.has(jsxSchema)) {
        return schemas.get(jsxSchema)!;
    }

    // Parse the JSX schema
    const schema = jsxToTree(jsxSchema);

    // Build a list of parent chains for every component type
    // This will give us the ancestors each component type should be wrapped in at minimum
    const minimalParentChainsByType : { [componentName: string]: string[] } = {};
    parseParentChainsFromTree(schema, [], (componentName, parentChain) => {
        minimalParentChainsByType[componentName] = parentChain;
    });

    // Extract config per component type from the schema
    const configPerType = getConfigPerType(schema);

    // Find which components should be merged together
    const typesToMerge = listComponentTypesMatching(schema, (n) => n.props?.__allowMultiple === undefined);

    // Get default props per type
    const defaultPropsPerType = propsPerType(schema);

    // Get enforced children policies
    const enforceChildrenOrderPolicies = getEnforceChildrenOrderPolicies(schema);

    // Create parsed object
    const parsed : ParsedSchema = {
        schema,
        minimalParentChainsByType,
        configPerType,
        typesToMerge,
        defaultPropsPerType,
        enforceChildrenOrderPolicies,
    };

    // Save it to the cache
    schemas.set(jsxSchema, parsed);

    // Return it
    return parsed;
}

export function mapComponentTreeToSchema(jsxSchema: string, rawTree: ComponentTreeNode, componentsAliases: ComponentAliasesSpec, preParseTextNodes = true): ComponentTreeNode {
    // Parse the JSX schema
    const { minimalParentChainsByType, configPerType, typesToMerge, defaultPropsPerType, enforceChildrenOrderPolicies } = parseJsxSchema(jsxSchema);

    // (Optional) Parse text nodes to transform them into components
    const tree : ComponentTreeNode = preParseTextNodes ? experimentalMarkdownTransformOfTextComponents(rawTree) as ComponentTreeNode : rawTree;

    // Now, transform the tree to ensure all components are wrapped in their minimal parent chain
    const parsedTree = componentTreeNodeToJsonXNode(tree, componentsAliases, configPerType);
    const withEnforcedParentChains = enforceMinimalParentChainsInTree(parsedTree, [], minimalParentChainsByType);

    // Add ids to the tree
    let output = addIdsToTree(withEnforcedParentChains);    

    // Now, merge components which should be merged together
    // FIXME: Current algorithm may merge components of the same type if at least one of them doesn't have the __allowMultiple prop
    for (let i = 0; i < typesToMerge.length; i++) {
        const { hasChildren, type } = typesToMerge[i];
        if (!hasChildren) {
            output = mergeComponentsOfTypeToLowestInTree(output, type);
        } else {
            output = factorizeEncapsulationType(output, type);
        }
    }

    // ❌ TODO: Treat wrapping adjacent components in the same parent...


    // Merge schema props to the tree
    output = applyDefaultPropsTree(output, defaultPropsPerType);

    // TEMP: Reformat output to return ComponentTreeNode format
    // TODO: ❌ Redesign parser to exclusively use strings as components, 
    //       and use a separate component registry to convert them to React components

    
    // Enforce children order where needed
    if (check.nonEmptyArray(enforceChildrenOrderPolicies)) {
        enforceChildrenOrderPolicies.forEach((policy) => {
            enforceChildrenOrderAtTypePath(output, policy.path, policy.order);
        });
    }

    const outputTree = jsonXNodeWithIdToComponentTreeNode(output, componentsAliases);


    if (!check.nonEmptyObject(outputTree)) {
        return {
            component: 'UnknownComponent',
            htmlComponent: 'UnknownComponent',
            props: {},
            children: [outputTree],
            classes: [],
            variant: null, 
            text: null,
        };
    }

    return outputTree;
}

export function joinAdjacentTextChildrenWithSpaces(node: ComponentTreeNode) {
    if (!check.nonEmptyObject(node)) {
        return node;
    }

    if (isTextComponent(node)) {
        return node;
    }

    if (!check.nonEmptyArray(node.children)) {
        return node;
    }

    let currentText = null;
    let newChildren = [];
    for(let i = 0; i < node.children.length; i++) {
        const child = node.children[i];
        if (check.string(child)) {
            currentText = ((currentText || '') + ' ' + child).trim();
        } else if (
            isTextComponent(child) 
            // Don't merge text components with non-empty props as we need to keep styling per component
            && !check.nonEmptyObject(removeEmptyProps(normalizeTextComponent(child)?.props || {}))
        ) {
            currentText = ((currentText || '') + ' ' + (normalizeTextComponent(child)?.text || '')).trim();
        } else {
            if (check.string(currentText)) {
                newChildren.push(normalizeTextComponent(currentText));
                currentText = null;
            }
            newChildren.push(child);
        }
    }
    if (check.string(currentText)) {
        newChildren.push(normalizeTextComponent(currentText));
        currentText = null;
    }

    return {
        ...node,
        children: newChildren,
    };
}
