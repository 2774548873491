import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from "@/components/shadcn/ui/command"
import { ComponentAliasConfig, childrenParsers, getComponentInnerText, imports, isTextComponent, normalizeTextComponent, setup } from "../_utils";
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { addStagesIf, appendComponentIfNotExisting, deepRemoveSameTypeChildren, ensureAllElementsHaveAChildrenArray, markTreeLeafs, mergeAllComponentsOfType, onlyAllowTypes, parseTextComponents, removeChildren, removeFirstOccurenceOfTextFromChildren, solveAliases, transformComponentsOfTypes, treeParsingPipeline, wrapComponentsNotOfTypes } from "../../parsing-pipeline";
import { mergeClasses, mergeProps, removeEmptyProps } from "../../../util";
import { cn, nonEmptyTrimmed } from "@/lib/utils";
import check from "@/vendors/check";
import { parseInputOrCommandText, parseShortcutText } from "../../layouts-lang";
import { ComponentParserConfig } from "@/lib/parser/parser-config";
import { getIconComponent, isIconComponent } from "../../base/images";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
export function CommandDemo() {
  return (
    <Command className="rounded-lg border shadow-md">
      <CommandInput placeholder="Type a command or search..." />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup heading="Suggestions">
          <CommandItem>
            <Calendar className="mr-2 size-4" />
            <span>Calendar</span>
          </CommandItem>
          <CommandItem>
            <Smile className="mr-2 size-4" />
            <span>Search Emoji</span>
          </CommandItem>
          <CommandItem>
            <Calculator className="mr-2 size-4" />
            <span>Calculator</span>
          </CommandItem>
        </CommandGroup>
        <CommandSeparator />
        <CommandGroup heading="Settings">
          <CommandItem>
            <User className="mr-2 size-4" />
            <span>Profile</span>
            <CommandShortcut>⌘P</CommandShortcut>
          </CommandItem>
          <CommandItem>
            <CreditCard className="mr-2 size-4" />
            <span>Billing</span>
            <CommandShortcut>⌘B</CommandShortcut>
          </CommandItem>
          <CommandItem>
            <Settings className="mr-2 size-4" />
            <span>Settings</span>
            <CommandShortcut>⌘S</CommandShortcut>
          </CommandItem>
        </CommandGroup>
      </CommandList>
    </Command>
  )
}
*/ 

function parseTextChild(c: ComponentTreeNode, config: ComponentParserConfig) : ComponentTreeNode | null {
  const parsed = parseTextChildInner(c, config);
  if (!parsed) {
    return null;
  }

  return {
    ...c,
    component: parsed.component,
    htmlComponent: parsed.htmlComponent,
    props: parsed.props,
    classes: parsed.classes,
    children: parsed.children,
  };
}

function parseTextChildInner(c: ComponentTreeNode, config: ComponentParserConfig) : ComponentTreeNode | null {
  const text = c.text;
  
  // Return nothing if there is no text
  if (!check.nonEmptyString(text) || !nonEmptyTrimmed(text)) {
    return null;
  }

  // Parse text
  const props = parseInputOrCommandText(text, { shortcut: true, separator: true, title: true });

  // If text is a separator, return a separator component
  if (props.type === 'separator') {
    return {
      component: CommandSeparator,
      htmlComponent: 'CommandSeparator',
      props: {},
      classes: [],
      children: [],
    };
  }

  if (props.type === 'title') {
    return {
      component: CommandGroup,
      htmlComponent: 'CommandGroup',
      props: { heading: props.label },
      classes: [],
      children: c.children || [],
    };
  }

  // Get or create shortcut component
  let shortcutComponents = [c.children?.find((child: ComponentTreeNode) => child.component === CommandShortcut)].filter(Boolean);
  if (!!props.shortcut) {
    shortcutComponents.push({
      component: CommandShortcut,
      htmlComponent: 'CommandShortcut',
      props: {},
      classes: [],
      children: [props.shortcut],
    });
  }

  // Get or create an icon component
  let iconComponent = c.children?.find(isIconComponent);
  if (!iconComponent) {
    iconComponent = getIconComponent({
      text: props.label,
      variant: 'lucide',
      props: {
        id: (c.key || 'icon') + '::' + props.label,
        key: (c.key || 'icon') + '::' + props.label,
      },
      classes: ['size-4'],
      children: [],
    }, config);
  } else {
    iconComponent = getIconComponent({
      ...iconComponent,
      props: {
        ...iconComponent.props,
        className: mergeClasses('size-4', iconComponent.props.className).join(' '),
      },
      classes: mergeClasses(['size-4'], iconComponent.classes),
    }, config);
  }

  return {
    component: CommandItem,
    htmlComponent: 'CommandItem',
    props: mergeProps({ className: 'gap-2'}, c.props),
    classes: mergeClasses(['gap-2'], c.classes),
    text: undefined,
    children: [
      iconComponent,
      {
        component: 'span',
        htmlComponent: 'span',
        props: {},
        classes: [],
        children: [props.label.trim()],
      },
      ...shortcutComponents,
    ],
  };
}

function parseInputComponent(c: ComponentTreeNode) : ComponentTreeNode | null {
    console.log('[Command] Parsing input component: ', c)

    if (c.component !== CommandInput) {
      return null;
    }

    // Get text
    const text = [
      c.text,
      ...(c.children || []).filter(isTextComponent).map(child => normalizeTextComponent(child)?.text)
    ].filter(check.nonEmptyString).join(' ').trim();

    // Parse placeholder
    let placeholder = undefined;
    if (check.nonEmptyString(text) && nonEmptyTrimmed(text)) {
        placeholder = text.trim();
    }
    placeholder = c.props?.placeholder || placeholder;

    // Parse classes
    const classes = cn(c.classes, c.props?.className).split(' ').filter(check.nonEmptyString);

    // Return element
    return {
      ...c,
      component: CommandInput,
      htmlComponent: 'CommandInput',
      props: removeEmptyProps({
        ...c.props,
        placeholder,
        className: classes.join(' '),
      }),
      classes, 
      children: [],
    };
}

// This function reparses and normalizes items because we need to merge all text components together
function parseItemComponent(c: ComponentTreeNode, config: ComponentParserConfig) : ComponentTreeNode | null {
  if (c.component !== CommandItem) {
    return null;
  }

  if ((!check.nonEmptyString(c.text) || !nonEmptyTrimmed(c.text)) && (!check.nonEmptyArray(c.children))) {
    return null;
  }

  // If item as a non null text and a single text component as a child, 
  // just parse the item as a text item
  if (
    check.nonEmptyString(c.text) 
    && nonEmptyTrimmed(c.text)
    && (
      !check.nonEmptyArray(c.children) 
      || (c.children.length === 1 && isTextComponent(c.children[0]) && normalizeTextComponent(c.children[0])?.text === c.text)
    )) {
    return parseTextChild(c, config);
  }
  if (!nonEmptyTrimmed(c.text || '') && c.children?.length === 1 && isTextComponent(c.children[0])) {
    return parseTextChild({
      ...c,
      text: normalizeTextComponent(c.children[0])?.text || '',
    }, config);
  }
  
  let children = c.children || [];
  const textComponents : ComponentTreeNode[] = (c.children || []).filter(c => !isIconComponent(c) && c.component !== CommandShortcut);
  const nonTextComponents : ComponentTreeNode[] = (c.children || []).filter(c => isIconComponent(c) || c.component === CommandShortcut);
  
  // If multiple text components, merge them together
  // as having multiple text children can cause a bug in the CommandItem component
  if (textComponents.length > 1) {
    const text = getComponentInnerText(c);
    children = [
      ...nonTextComponents,
      {
        component: 'span',
        htmlComponent: 'span',
        props: mergeProps(...textComponents.map(c => c.props)),
        classes: mergeClasses(['gap-2'], ...mergeClasses(textComponents.map(c => c.classes))),
        children: [text],
      },
    ]
  }

  // On the contrary, if there is no children and a text, create a span component
  if (!check.nonEmptyArray(children) && check.nonEmptyString(c.text)) {
    children = [
      {
        component: 'span',
        htmlComponent: 'span',
        props: {},
        classes: [],
        children: [c.text],
      }
    ];
  }

  // If there are shortcut components as children, parse them
  children = children.map(child => {
    if (child.component === CommandShortcut) {
      return parseShortcutComponent(child);
    }
    return child;
  }).filter(Boolean);

  // Return the item with the children and an added gap-2 class
  return {
    ...c,
    classes: mergeClasses(['gap-2'], c.classes),
    props: {
      ...c.props,
      className: mergeClasses(['gap-2'], c.props?.className).join(' '),
    },
    children,
  }
}

function parseShortcutComponent(c: ComponentTreeNode) : ComponentTreeNode | null {
  const text = (check.nonEmptyString(c.text) && nonEmptyTrimmed(c.text)) ? c.text.trim() : getComponentInnerText(c);

  if (!check.nonEmptyString(text)) {
    return null;
  }

  return {
    ...c,
    text: undefined,
    children: [
      parseShortcutText(text),
    ],
  }
}

function commandInputPropsFromText(text: any) {
  if (!check.nonEmptyString(text) || !nonEmptyTrimmed(text)) {
    return {};
  }

  return {
    placeholder: text,
  };
}

const commandParseTree = (c: ComponentTreeNode, cfg: ComponentParserConfig, noInput = false) : ComponentTreeNode => {
  const parsed = treeParsingPipeline([
    // Remove text from children if any
    addStagesIf(
      (parent: ComponentTreeNode) => (parent.component === Command || parent.component === 'command'),
      [removeFirstOccurenceOfTextFromChildren(c.text)],
    ),

    // Solve alias components
    solveAliases(ALIASES),

    // Ensure all children have a children array (to solve a bug when typing /item without text or children)
    ensureAllElementsHaveAChildrenArray(),

    // Prevent some components from having themselves as children
    deepRemoveSameTypeChildren([CommandItem, CommandSeparator, CommandShortcut, CommandEmpty, CommandInput, CommandGroup, CommandList]),
    
    // Parse item components
    transformComponentsOfTypes([CommandItem], (c) => parseItemComponent(c, cfg)),

    // Parse input components
    transformComponentsOfTypes([CommandInput], (c) => parseInputComponent(c)),

    // Parse text components
    parseTextComponents((c) =>parseTextChild(c, cfg)),

    // Only allow supported children
    onlyAllowTypes([CommandItem, CommandSeparator, CommandShortcut, CommandEmpty, CommandInput, CommandGroup, CommandList]),

    // If we're at root, wrap everything except CommandInput in a CommandList
    // Make sure there is at least one command input and one command list
    addStagesIf(
      (parent: ComponentTreeNode) => (parent.component === Command || parent.component === 'command'),
      [
        wrapComponentsNotOfTypes([CommandInput], CommandList, 'CommandList'), 
        mergeAllComponentsOfType(CommandList),
        appendComponentIfNotExisting(CommandInput, { component: CommandInput, htmlComponent: 'CommandInput', classes:[], props:{}, children: [] }),
        appendComponentIfNotExisting(CommandList, { component: CommandList, htmlComponent: 'CommandList', classes:[], props:{}, children: [] }),
        ...(noInput ? [
          removeChildren([CommandInput]),
        ] : [])
      ]
    ),

    // If we're inside a command list, only allow supported components
    // If we're inside a command list, make sure there is only one CommandEmpty. If there is none, add one.
    addStagesIf(
      CommandList,
      [
        onlyAllowTypes([CommandEmpty, CommandGroup, CommandSeparator, CommandShortcut, CommandItem]),
        mergeAllComponentsOfType(CommandEmpty),
        appendComponentIfNotExisting(CommandEmpty, { component: CommandEmpty, htmlComponent: 'CommandEmpty', classes:[], props:{}, children: ['No results found.'] }),
      ],
    ),

    // Remove empty CommandList if any
    transformComponentsOfTypes([CommandList], (c) => (check.nonEmptyArray(c.children) ? c : null)),

    // Mark tree leafs to avoid parsing text children of CommandItem & others...
    markTreeLeafs([CommandItem, CommandSeparator, CommandShortcut, CommandEmpty, CommandInput])
  ]).run({...c, component: Command}, []);

  const children = (parsed.children || []).map((child: ComponentTreeNode) =>{
      if (child.component === CommandInput) {
        return {
          ...child,
          props: {
            ...commandInputPropsFromText(c.text),
            ...child.props,
          }
        };
      }

      return child;
  });

  const out = {
    ...parsed,
    component: Command,
    htmlComponent: 'Command',
    classes: mergeClasses(['rounded-lg', 'border', 'shadow-md', 'h-fit'], parsed.classes),
    children: [
      // Make sure we always dispaly the children in the right order
      children.find((child: ComponentTreeNode) => child.component === CommandInput),
      children.find((child: ComponentTreeNode) => child.component === CommandList),
    ].filter(Boolean),
  };

  return out;
};

const TAG = 'command';
const TAGS = [TAG, 'cmd', 'cmdk'];
  const ALIASES : ComponentAliasConfig[] = [
    ['empty', CommandEmpty, 'CommandEmpty'],
    ['group', CommandGroup, 'CommandGroup'],
    ['input', CommandInput, 'CommandInput'],
    ['item', CommandItem, 'CommandItem'],
    ['list', CommandList, 'CommandList'],
    ['separator', CommandSeparator, 'CommandSeparator'],
    ['shortcut', CommandShortcut, 'CommandShortcut'],
  ];

  const CommandComponentParser : ComponentParser = {
    name: 'Command',
    description: 'Fast, composable, unstyled command menu for React.',
    tags: TAGS,

    refImplementation: `
    /command Type a command or search...
        # Suggestions
            Calendar
            Search Emoji
                /icon smiley
            Calculator
        ---
        # Settings
            Profile <Cmd + P>
                /icon User profile
            Billing <Cmd + B>
                /icon Credit card
            Settings <Space + S + shift>
    `.trim(),

    childrenParsers: childrenParsers(ALIASES, TAGS),
  
    parseTree: commandParseTree,
  
    imports: imports(['Command', 'CommandEmpty', 'CommandGroup', 'CommandInput', 'CommandItem', 'CommandList', 'CommandSeparator', 'CommandShortcut'], TAG),
    setup: setup(TAG),
    variants: [],
  };

registerShadcnComponent({
        Command,
        CommandEmpty,
        CommandGroup,
        CommandInput,
        CommandItem,
        CommandList,
        CommandSeparator,
        CommandShortcut,
    },
    TAG,
);
  
export default CommandComponentParser;
export { commandParseTree };