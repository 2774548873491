
import { Skeleton } from "@/components/shadcn/ui/skeleton";
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { imports, setup } from "../_utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
import { Skeleton } from "@/components/ui/skeleton"
 
export function SkeletonDemo() {
  return (
    <div className="flex items-center space-x-4">
      <Skeleton className="h-12 w-12 rounded-full" />
      <div className="space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    </div>
  )
}
*/

const SkeletonComponentParser : ComponentParser = {
  name: 'Skeleton',
  description: 'Use to show a placeholder while content is loading.',
  tags: ['skeleton'],
  refImplementation: `
  /div flex items-center space-x-4
    /skeleton h-12 w-12 rounded-full
    /div space-y-2
      /skeleton h-4 w-[250px]
      /skeleton h-4 w-[200px]
  `.trim(),
  parseTree: (c: ComponentTreeNode) => {
      return {
          ...c,
          component: Skeleton,
          htmlComponent: 'Skeleton',
      };
  },
  imports: imports(['Skeleton'], 'skeleton'),
  setup: setup('skeleton'),
  variants: []
};

registerShadcnComponent({
        Skeleton
    },
    'skeleton',
);

export default SkeletonComponentParser;
