"use client"

import * as React from "react"
import * as SliderPrimitive from "@radix-ui/react-slider"

import { cn } from "@/lib/utils"

interface SliderProps extends Omit<React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>, 'onChange' | 'value' | 'defaultValue' > {
  onChange?: (value: number) => void;
  value?: number;
  defaultValue?: number;
}

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  SliderProps
>(({ className, onChange, value, defaultValue, ...props }, ref) => {

  const onChangeHandler = React.useCallback((value: number[]) => {
    if (onChange) {
      onChange(value[0]);
    }
  }, [onChange]);
  
  return (
      <SliderPrimitive.Root
        ref={ref}
        className={cn(
          props.orientation !== "vertical"
            ? "flex w-full"
            : "flex h-full w-2 flex-col",
          "relative touch-none select-none items-center",
          className
        )}
        value={(typeof value === 'number') ? [value] : undefined}
        defaultValue={(typeof defaultValue === 'number') ? [defaultValue] : undefined}
        onValueChange={onChangeHandler}
        {...props}
      >
        <SliderPrimitive.Track
          className={cn(
            props.orientation !== "vertical" ? "h-2 w-full" : "h-full w-2",
            "relative grow overflow-hidden rounded-full bg-secondary"
          )}
        >
          <SliderPrimitive.Range
            className={cn(
              props.orientation !== "vertical" ? "h-full" : "w-full",
              "absolute bg-primary"
            )}
          />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb className="block h-5 w-5 rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
      </SliderPrimitive.Root>
  );
})
Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
