import * as React from "react"
import { Calendar } from "@/components/shadcn/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/shadcn/ui/popover";
import { Button } from "@/components/shadcn/ui/button";
import { cn } from "@/lib/utils";

function isDay(val: any) : val is string {
    return typeof val === 'string' && /^\d{4}-\d{2}-\d{2}$/g.test(val);
}

function dayToDate(day?: string) {
    if (typeof day !== 'string' || !/^\d{4}-\d{2}-\d{2}$/g.test(day)) {
        return undefined;
    }
    const [year, month, date] = day.split('-').map(Number);
    return (new Date(year, month - 1, date));
}

export function DatePicker({ placeholder, value, defaultValue, onChange, className, ...props }: {
    placeholder?: string;
    value?: string;
    defaultValue?: string;
    onChange?: (value?: string) => void;
    className?: string;
}) {    
    const [localVal, setLocalVal] = React.useState<string | null>(isDay(defaultValue) ? defaultValue : null);
    const date = isDay(value) ? value : localVal as any;
    const defaultMonth = isDay(date) ? date.split('-').slice(0, 2).join('-') : undefined;

    const onChangeHandler = React.useCallback((val?: string) => {
        setLocalVal(val || null);
        onChange?.(val);
    }, [onChange]);

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                variant={"outline"}
                className={cn(
                    "w-[280px] justify-start text-left font-normal",
                    !date && "text-muted-foreground",
                    className,
                )}
                >
                {date ? (dayToDate(date)?.toLocaleDateString() || '') : <span>{placeholder}</span>}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar
                    value={date}
                    defaultMonth={defaultMonth}
                    onChange={onChangeHandler}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    );
}