import {
    Menubar,
    MenubarCheckboxItem,
    MenubarContent,
    MenubarItem,
    MenubarMenu,
    MenubarRadioGroup,
    MenubarRadioItem,
    MenubarSeparator,
    MenubarShortcut,
    MenubarSub,
    MenubarSubContent,
    MenubarSubTrigger,
    MenubarTrigger,
  } from "@/components/shadcn/ui/menubar"
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { ComponentAliasConfig, childrenParsers, imports, radixComponentDoc, removeOneOccurenceOfText, setup } from "../_utils";
import check from "@/vendors/check";
import { nonEmptyTrimmed } from "@/lib/utils";
import { ComponentType } from "react";
import { getItemMainText, getParsedShortcut, isChecked, startsWithCheckboxPattern, startsWithRadioPattern } from "../../layouts-lang";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";


const componentAliases : { [alias:string]: ComponentType }= {
  'menubar': Menubar,
  'menubar-checkbox-item': MenubarCheckboxItem,
  'checkbox-item': MenubarCheckboxItem,
  'checkbox': MenubarCheckboxItem,
  'menubar-content': MenubarContent,
  'content': MenubarContent,
  'menubar-item': MenubarItem,
  'item': MenubarItem,
  'menubar-menu': MenubarMenu,
  'menu': MenubarMenu,
  'menubar-radio-group': MenubarRadioGroup,
  'radio-group': MenubarRadioGroup,
  'menubar-radio-item': MenubarRadioItem as any,
  'radio-item': MenubarRadioItem as any,
  'menubar-separator': MenubarSeparator,
  'separator': MenubarSeparator,
  'menubar-shortcut': MenubarShortcut,
  'shortcut': MenubarShortcut,
  'menubar-sub': MenubarSub,
  'sub': MenubarSub,
  'menubar-sub-content': MenubarSubContent,
  'sub-content': MenubarSubContent,
  'menubar-sub-trigger': MenubarSubTrigger,
  'sub-trigger': MenubarSubTrigger,
  'menubar-trigger': MenubarTrigger,
  'trigger': MenubarTrigger,
}

const isItemComponent = (c: ComponentTreeNode) => {
  return [MenubarItem, MenubarCheckboxItem, MenubarRadioItem].includes(c.component as any);
}

const canComponentHaveChildren = (c: ComponentTreeNode) => {
  return ![MenubarSeparator].includes(c.component as any);
}
  
  function textToMenuItem(text: string) {
    if (!check.nonEmptyString(text) || !nonEmptyTrimmed(text)) {
      return null;
    }

    // By default, component is a simple item
    let component = MenubarItem;
    let htmlComponent = 'MenubarItem';
    let props = {};

    // If the text is a separator, we return a separator component
    if (/^-{2,}$/g.test(text.trim())) {
      component = MenubarSeparator as any;
      return {
        component,
        htmlComponent: 'MenubarSeparator',
        classes: [],
        props,
        children: [],
      };
    }

    // If the text starts with a radio pattern, it's a radio item
    if (startsWithRadioPattern(text)) {
      component = MenubarRadioItem as any;
      htmlComponent = 'MenubarRadioItem';
      props = { 
        value: getItemMainText(text),
        checked: isChecked(text),
      };
    }

    // If the text starts with a checkbox pattern, it's a checkbox item
    if (startsWithCheckboxPattern(text)) {
      component = MenubarCheckboxItem;
      htmlComponent = 'MenubarCheckboxItem';
      props = { 
        checked: isChecked(text),
      };
    }

    // If the text includes a shortcut pattern, we add a shortcut component
    let compChildren : (string | ComponentTreeNode)[] = [getItemMainText(text)];
    const shortcut = getParsedShortcut(text);
    if (shortcut) {
      compChildren.push({
        classes: [],
        component: MenubarShortcut,
        htmlComponent: 'MenubarShortcut',
        props: {},
        children: [shortcut],
      });
    }

    return {
      component,
      htmlComponent,
      classes: [],
      props,
      children: compChildren || [],
    };
  }

  function wrapComponentInMenu(component: ComponentTreeNode, children: any[], level = 0) {
    if (level <= 0) {
      return {
        component: MenubarMenu,
        htmlComponent: 'MenubarMenu',
        classes: [],
        props: {},
        children: [
          { ...component, component: MenubarTrigger, htmlComponent: 'MenubarTrigger' },
          {
            component: MenubarContent,
            htmlComponent: 'MenubarContent',
            classes: [],
            props: {},
            children,
          }
        ]
      }
    } else {
      return {
        component: MenubarSub,
        htmlComponent: 'MenubarSub',
        classes: [],
        props: {},
        children: [
          { ...component, component: MenubarSubTrigger, htmlComponent: 'MenubarSubTrigger' },
          {
            component: MenubarSubContent,
            htmlComponent: 'MenubarSubContent',
            classes: [],
            props: {},
            children,
          }
        ]
      }
    }
  }

  function parseTextComponent(c: ComponentTreeNode, level = 0) {
    if (!check.nonEmptyObject(c) || c.component !== 'text') {
      return null;
    }

    let parsed = null;
    const { text, children: rawChildren } = c;
    const children = removeOneOccurenceOfText(rawChildren || [], text || '');

    // Create a new component from the text
    const textMenuItem = textToMenuItem(text || '');
    if (!textMenuItem) {
      return null;
    }

    // Merge it with the classes and props of the current component
    parsed = {
      ...textMenuItem, 
      classes: [...(c.classes || []), ...(textMenuItem?.classes || [])],
      props: { ...(c.props || {}), ...(textMenuItem?.props || {}) },
    };

    // If our component has children, wrap them in a menu
    if (check.nonEmptyArray(children) && (children.length > 1 || children[0] !== text)) {
      return wrapComponentInMenu(parsed, children, level);
    }
    
    // Else return the component as is
    return parsed;
  }


  function parseMenuItem(
    c: ComponentTreeNode | string | null, 
    level = 0,
    isWithinItem = false,
  ): ComponentTreeNode | ComponentTreeNode[] | string | null {
    if (!c) {
      return null;
    }

    let comp : ComponentTreeNode | string | null = c;
    
    // Parse string components only if we are within an item
    if (check.string(comp)) {
      if (isWithinItem) {
        return comp;
      }

      comp = textToMenuItem(comp);
    }

    if (!comp || !check.nonEmptyObject(comp)) {
      return null;
    }

    // If component is a text component, pre-parse it
    if (comp.component === 'text') {
      if (isWithinItem) {
        return comp;
      }
      comp = parseTextComponent(comp, level);
      if (!comp) {
        return null;
      }
    }

    const { component, children } = comp as ComponentTreeNode;
    let parsed : ComponentTreeNode = {
      ...comp,
    };

    // Alias component names to the real React component
    if (check.nonEmptyString(component) && !!componentAliases[component.trim().toLowerCase()]) {
      parsed.component = componentAliases[component.trim().toLowerCase()];
    }

    // If component cannot have children, return it
    if (!canComponentHaveChildren(parsed)) {
      return { ...parsed, children: [] };
    }

    // Detect if the current component is an item 
    const commponentIsItem = isItemComponent(parsed) || [MenubarSubTrigger, MenubarTrigger, MenubarShortcut].includes(parsed.component as any);
   
    // Parse the children one by one
    let parsedChildren : any[] = (children || [])
      .map(c => parseMenuItem(c, level + 1, commponentIsItem))
      .filter(c => !!c);

    // If the children contain a radiogroup or checkbox, add inset to all other items by default
    const insetCausingComps = [MenubarCheckboxItem, MenubarRadioItem, MenubarRadioGroup];
    const componentWithNoInsetProp = [MenubarSeparator, MenubarContent];
    if (parsedChildren.some(c => insetCausingComps.includes(c.component as any))) {
      parsedChildren = parsedChildren.map(c => {
        if (!insetCausingComps.includes(c.component as any) && !componentWithNoInsetProp.includes(c.component as any)) {
          // We add the inset prop only if it's not already set to false
          return { ...c, props: { ...c.props, inset: c.props.inset === false ? false : true } };
        }
        return c;
      });
    }

    // If the children contain radio items without a group, we create the group
    if (parsed.component !== MenubarRadioGroup && parsedChildren.some(c => c.component === MenubarRadioItem)) {
      let newChildren : any[] = [];
      let currentGroup : { value: string | null, children: any[] } = {
        value: null,
        children: [],
      } 

      const appendGroup = () => {
        if (!check.nonEmptyArray(currentGroup.children)) {
          return;
        }

        newChildren.push({
          component: MenubarRadioGroup,
          htmlComponent: 'MenubarRadioGroup',
          classes: [],
          props: { value: currentGroup.value || currentGroup.children[0]?.props?.value },
          children: currentGroup.children,
        });
        currentGroup.children = [];
        currentGroup.value = null;
      }

      // Group radio items which follow each other together
      parsedChildren.forEach((c) => {
        if (c.component === MenubarRadioItem) {
          if (c.props.checked) {
            currentGroup.value = c.props.value || (c.children || []).filter(check.nonEmptyString).join('');
          }
          
          currentGroup.children.push({
            ...c,
            // Clean props
            props: { 
              value: c.props.value || (c.children || []).filter(check.nonEmptyString).join(''), 
              checked: null, 
            },
          });
        } else {
          // Append last group
          appendGroup();

          // Append current item
          newChildren.push(c);
        }
      });
      // Append last group and replace the children
      appendGroup();
      parsedChildren = newChildren;
    }

    // If component is a Menubar, make sure all its children are MenubarMenus
    // And fill its htmlComponent with the correct value
    if (parsed.component === Menubar) {
      parsed.htmlComponent = 'Menubar';
      parsedChildren = parsedChildren.map(c => {
        if (c.component !== MenubarMenu && c.component !== MenubarSeparator) {
          return wrapComponentInMenu(c, [...(c.children || [])].splice(1), level);
        }
        return c;
      });
    }

    // If component is a MenubarMenu, make sure it only has one trigger and one content child
    if (parsed.component === MenubarMenu) {
      let trigger : ComponentTreeNode | null = parsedChildren.find(c => c.component === MenubarTrigger) || parsedChildren.find(c => c.component === MenubarSubTrigger) || null;
      let content : ComponentTreeNode | null = parsedChildren.find(c => c.component === MenubarContent) || parsedChildren.find(c => c.component === MenubarSubContent) || null;
      let rest : ComponentTreeNode[] = parsedChildren.filter(c => ![MenubarTrigger, MenubarContent].includes(c.component as any));
      
      if (!trigger) {
        return null;
      }
      if (!content) {
        content = {
          component: MenubarContent,
          htmlComponent: 'MenubarContent',
          classes: [],
          props: {},
          children: rest,
        }
      }

      parsedChildren = [trigger, content];
    }

    // If component is a MenubarSub, make sure it only has one trigger and one content child
    if (parsed.component === MenubarSub) {
      let trigger : ComponentTreeNode | null = parsedChildren.find(c => c.component === MenubarSubTrigger) || parsedChildren.find(c => c.component === MenubarTrigger) || null;
      let content : ComponentTreeNode | null = parsedChildren.find(c => c.component === MenubarSubContent) || parsedChildren.find(c => c.component === MenubarContent) || null;
      let rest : ComponentTreeNode[] = parsedChildren.filter(c => ![MenubarSubTrigger, MenubarSubContent].includes(c.component as any));
      
      if (!trigger) {
        return null;
      }
      if (!content) {
        content = {
          component: MenubarContent,
          htmlComponent: 'MenubarContent',
          classes: [],
          props: {},
          children: rest,
        }
      }

      parsedChildren = [trigger, content];
    }

    // Wrap item components in a submenu if it has children items
    if (commponentIsItem && check.nonEmptyArray(parsedChildren) && parsedChildren.some(isItemComponent)) {
      return wrapComponentInMenu({ ...parsed, children:[] }, parsedChildren, level);
    }

    // Return the parsed component with its children
    return {
      ...parsed,
      children: (parsedChildren || []).filter(c => !!c),
    };
  }
  
  const TAG = 'menubar';
  const ALIASES : ComponentAliasConfig[] = [
    ['checkbox', MenubarCheckboxItem, 'MenubarCheckboxItem', radixComponentDoc('Menubar.CheckboxItem')],
    ['content', MenubarContent, 'MenubarContent', radixComponentDoc('Menubar.Content')],
    ['item', MenubarItem, 'MenubarItem', radixComponentDoc('Menubar.Item')],
    ['menu', MenubarMenu, 'MenubarMenu', radixComponentDoc('Menubar.Menu')],
    ['radio-group', MenubarRadioGroup, 'MenubarRadioGroup', radixComponentDoc('Menubar.RadioGroup')],
    ['radio-item', MenubarRadioItem as any, 'MenubarRadioItem', radixComponentDoc('Menubar.RadioItem')],
    ['separator', MenubarSeparator, 'MenubarSeparator', radixComponentDoc('Menubar.Separator')],
    ['shortcut', MenubarShortcut, 'MenubarShortcut', radixComponentDoc('Menubar.Shortcut')],
    ['sub', MenubarSub, 'MenubarSub', radixComponentDoc('Menubar.Sub')],
    ['sub-content', MenubarSubContent, 'MenubarSubContent', radixComponentDoc('Menubar.SubContent')],
    ['sub-trigger', MenubarSubTrigger, 'MenubarSubTrigger', radixComponentDoc('Menubar.SubTrigger')],
    ['trigger', MenubarTrigger, 'MenubarTrigger', radixComponentDoc('Menubar.Trigger')],
  ];

  const MenubarComponentParser : ComponentParser = {
    name: 'Menubar',
    description: 'A visually persistent menu common in desktop applications that provides quick access to a consistent set of commands',
    tags: [TAG],

    refImplementation: `
/menubar
  File
    New Tab <Cmd + T>
    New Window <Cmd + N>
    New Incognito Window
    ---
    Share
      Email link
      Messages
      Notes
    ---
    Print... <Cmd + P>
  Edit
    Undo <Cmd + Z>
    Redo <Shift + Cmd + Z>
    ---
    Find
      Search the web
      ---
      Find...
      Find Next
      Find Previous
    ---
    Cut
    Copy
    Paste
  View
    [ ] Always Show Bookmarks Bar
    [ ] Always Show Full URLs
    ---
    Reload <Cmd + R>
    Force Reload <Shift + Cmd + R>
    ---
    Toggle Fullscreen
    ---
    Hide Sidebar
  Profiles
    ( ) Andy
    (x) Benoit
    ( ) Luis
    ---
    Edit...
    ---
    Add Profile...
`.trim().replaceAll(/ {2}/g, '\t'),

    childrenParsers: childrenParsers(ALIASES, TAG),
  
    parseTree: (c: ComponentTreeNode) : ComponentTreeNode => {
      const parsed = parseMenuItem(c, -1) as ComponentTreeNode;

      // Force the root component name
      parsed.htmlComponent = 'Menubar';
      return parsed;
    },
  
    imports: imports(['Menubar', 'MenubarCheckboxItem', 'MenubarContent', 'MenubarItem', 'MenubarMenu', 'MenubarRadioGroup', 'MenubarRadioItem', 'MenubarSeparator', 'MenubarShortcut', 'MenubarSub', 'MenubarSubContent', 'MenubarSubTrigger', 'MenubarTrigger'], TAG),
    setup: setup(TAG),
    variants: [],
    doc: {
      props: radixComponentDoc('Menubar').props,
    }
  };


registerShadcnComponent({
        Menubar,
        MenubarCheckboxItem,
        MenubarContent,
        MenubarItem,
        MenubarMenu,
        MenubarRadioGroup,
        MenubarRadioItem,
        MenubarSeparator,
        MenubarShortcut,
        MenubarSub,
        MenubarSubContent,
        MenubarSubTrigger,
        MenubarTrigger,
    },
    TAG,
);
  
  export default MenubarComponentParser;