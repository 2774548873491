"use client"

import * as React from "react"
import { ChevronsUpDown, Plus, X } from "lucide-react"

import { Button } from "@/components/shadcn/ui/button"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/shadcn/ui/collapsible"
import { ComponentAliasConfig, childrenParsers, imports, mergeComponentsInto, normalizeTextComponent, radixComponentDoc, setup } from "../_utils"
import { ComponentParser, ComponentTreeNode } from "../../component.type"
import { mergeClasses, mergeProps, withTextAsFirstChild } from "@/lib/parser/util"
import check from "@/vendors/check"
import { registerShadcnComponent } from "@/lib/parser/importsRegistry"

/*
export function CollapsibleDemo() {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="w-[350px] space-y-2"
    >
      <div className="flex items-center justify-between space-x-4 px-4">
        <h4 className="text-sm font-semibold">
          @peduarte starred 3 repositories
        </h4>
        <CollapsibleTrigger asChild>
          <Button variant="ghost" size="sm" className="w-9 p-0">
            <ChevronsUpDown className="w-4 h-4" />
            <span className="sr-only">Toggle</span>
          </Button>
        </CollapsibleTrigger>
      </div>
      <div className="rounded-md border px-4 py-3 font-mono text-sm">
        @radix-ui/primitives
      </div>
      <CollapsibleContent className="space-y-2">
        <div className="rounded-md border px-4 py-3 font-mono text-sm">
          @radix-ui/colors
        </div>
        <div className="rounded-md border px-4 py-3 font-mono text-sm">
          @stitches/react
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}
*/

const TAG = 'collapsible';
  const ALIASES : ComponentAliasConfig[] = [
    ['trigger', CollapsibleTrigger, 'CollapsibleTrigger', radixComponentDoc('Collapsible.Trigger')],
    ['content', CollapsibleContent, 'CollapsibleContent', radixComponentDoc('Collapsible.Content')],
  ];

  const CollapsibleComponentParser : ComponentParser = {
    name: 'Collapsible',
    description: 'An interactive component which expands/collapses a panel',
    tags: [TAG],

    refImplementation: `
    /collapsible p-3.5 border rounded-lg flex flex-col items-stretch gap-3.5
      /trigger font-semibold text-left Can I use this in my project?
      /content bg-gray-100 p-3.5 Yes. Free to use for personal and commercial projects. No attribution required.
`.trim().replaceAll(/ {2}/g, '\t'),

    childrenParsers: childrenParsers(ALIASES, TAG),
  
    parseTree: (c: ComponentTreeNode) : ComponentTreeNode => {
      const triggers: ComponentTreeNode[] = [
        check.nonEmptyString(c.text) ? {
          component: CollapsibleTrigger,
          htmlComponent: 'CollapsibleTrigger',
          children: [c.text],
          classes: [],
          props: {},
        } : null, 
        ...(c.children || []).filter((child) => child.component === CollapsibleTrigger)
      ].filter(Boolean);

      const contents = [
        ...(c.children || []).filter((child) => child.component !== CollapsibleTrigger)
      ];


      const trigger =  mergeComponentsInto(triggers, {
        component: CollapsibleTrigger,
        htmlComponent: 'CollapsibleTrigger',
      });

      console.log('Trigger components: ', triggers)
      console.log('Trigger components --> merged: ', trigger)

      

      const content = mergeComponentsInto(contents, {
        component: CollapsibleContent,
        htmlComponent: 'CollapsibleContent',
      });

      return {
        ...c,
        component: Collapsible,
        htmlComponent: 'Collapsible',
        children: [trigger, content],
        text: null,
        variant: null, 
      };
    },
  
    imports: imports(['Collapsible', 'CollapsibleTrigger', 'CollapsibleContent'], TAG),
    setup: setup(TAG),
    variants: [],
    doc: {
      props: radixComponentDoc('Collapsible.Root').props,
    }
  };

registerShadcnComponent({
        Collapsible,
        CollapsibleContent,
        CollapsibleTrigger,
    },
    TAG,
);
  
  export default CollapsibleComponentParser;