
import { Toggle } from "@/components/shadcn/ui/toggle";
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { imports, radixComponentDoc, setup } from "../_utils";
import { getSingleVarNameFromText, removeTextFromChildren, selectVariant } from "@/lib/parser/util";
import check from "@/vendors/check";
import { getIconComponent, isIconComponent } from "../../base/images";
import { cn } from "@/lib/utils";
import { ComponentParserConfig } from "@/lib/parser/parser-config";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { DataInput } from "@/vendors/data/data-v2";
import { removeFirstOccurenceOfTextFromChildren } from "../../parsing-pipeline";

/*
import { Bold } from "lucide-react"
 
import { Toggle } from "@/components/ui/toggle"
 
export function ToggleDemo() {
  return (
    <Toggle aria-label="Toggle bold">
      <Bold className="size-4" />
    </Toggle>
  )
}
*/

function removeFirstOccurenceOfTextsFromChildren(texts: string[], c: ComponentTreeNode[]) {
    let out = c;

    for (let text of texts) {
        out = removeFirstOccurenceOfTextFromChildren(text)(out);
    }

    return out;
}

function parseItemComponent(c: ComponentTreeNode, config: ComponentParserConfig) : ComponentTreeNode | null {
  // Extract text var from text 
  let text = c.text;
  let wrap = (toWrap: ComponentTreeNode) => toWrap;
  let childrenToClean: string[] = [];
  if (check.nonEmptyString(text)) {
      const bind = getSingleVarNameFromText(text);
      if (bind) {
        // Isolate texts to remove from children
        const before = text.split(bind)[0].trim();
        const after = text.split(bind)[1].trim();
        childrenToClean.push(before, after);

        text = text?.split(' ').filter(t => t !== bind).filter(check.nonEmptyString).join(' ');

        wrap = (toWrap: ComponentTreeNode) => ({
              component: DataInput as React.ComponentType,
              htmlComponent: 'DataInput',
              props: {
                  bind,
                  type: 'boolean',
              },
              classes: [],
              variant: null,
              children: [toWrap],
        });
      }
  }

  let icon = (c.children || []).find(isIconComponent);
  let labelText = check.nonEmptyString(text) ? text : (c.props?.name || null);

  let variant : string | undefined = selectVariant(VARIANTS, c.variant);
  if (variant === 'default') {
    variant = undefined;
  }

  // Parse children to remove any child which might have been added by adding the text as a child
  // This includes the whole text if it was added as a child, or the text prefix, the variable and the text suffix when a variable is set
  let children : any[] = removeTextFromChildren(c.text, c.children || []);

  if (check.nonEmptyString(labelText)) {
    if (c.props?.withText === true) {
      children = [...children, labelText];
    }
    if (!icon) {
      icon = getIconComponent({
        variant: 'lucide',
        classes: ['size-4'],
        text: labelText,
      }, config);
      children = [icon, ...children];
    }
  }

  const classes = cn('no-transition', children.length > 1 && 'flex items-center gap-2', c.classes, c.props?.className).split(' ').filter(check.nonEmptyString);
  const props : { [key:string]: any }  = {
      ...(c.props || {}),
      className: cn(classes),
      variant,
  }

  return wrap({
    ...c,
    component: Toggle as any,
    htmlComponent: 'Toggle',
    props,
    variant,
    classes,
    children,
    text: null,
  });
}

const VARIANTS = [
  'default',
  'outline',
];

const ToggleComponentParser : ComponentParser = {
  name: 'Toggle',
  description: 'A two-state button that can be either on or off.',
  tags: ['toggle'],
  refImplementation: `
  /toggle Bold
  `.trim(),
  parseTree: (c: ComponentTreeNode, config: ComponentParserConfig) => {
      return parseItemComponent(c, config);
  },
  imports: imports(['Toggle'], 'toggle'),
  setup: setup('toggle'),
  variants: VARIANTS,
  doc: {
      props: radixComponentDoc('Toggle.Root').props,
  }
};

registerShadcnComponent({
        Toggle, 
    },
    'toggle',
);

export default ToggleComponentParser;
