import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
  } from "@/components/shadcn/ui/accordion"
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { childrenParsers, ComponentAliasConfig, imports, radixComponentDoc, setup } from "../_utils";
import { ComponentAliasesSpec, mapComponentTreeToSchema } from "@/lib/parser/parser-v3-utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
  export function AccordionDemo() {
    return (
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="item-1">
          <AccordionTrigger>Is it accessible?</AccordionTrigger>
          <AccordionContent>
            Yes. It adheres to the WAI-ARIA design pattern.
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-2">
          <AccordionTrigger>Is it styled?</AccordionTrigger>
          <AccordionContent>
            Yes. It comes with default styles that matches the other
            components&apos; aesthetic.
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-3">
          <AccordionTrigger>Is it animated?</AccordionTrigger>
          <AccordionContent>
            Yes. It&apos;s animated by default, but you can disable it if you
            prefer.
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    )
  }
*/

const SCHEMA = `
    <AccordionItem 
      __enforceChildrenOrder 
      __textAsChildComponent="AccordionTrigger"
      __textChildrenAsChildComponents="AccordionContent"
    >
      <AccordionTrigger />
      <AccordionContent />
    </AccordionItem>
`;

const COMPONENTS_MAP : ComponentAliasesSpec = {
  AccordionItem: {
    component: AccordionItem as any,
    htmlComponent: 'AccordionItem',
    aliases: ['accordion-item', 'item', 'AccordionItem', AccordionItem, 'Item', 'accordion'],
  },
  AccordionTrigger: {
    component: AccordionTrigger,
    htmlComponent: 'AccordionTrigger',
    aliases: ['accordion-trigger', AccordionTrigger, 'AccordionTrigger', 'trigger'],
  },
  AccordionContent: {
    component: AccordionContent,
    htmlComponent: 'AccordionContent',
    aliases: ['accordion-content', AccordionContent, 'AccordionContent', 'content'],
  },
};

const TAG = 'accordion';
const ALIASES : ComponentAliasConfig[] = [
  ['trigger', AccordionTrigger, 'AccordionTrigger', radixComponentDoc('Accordion.Trigger')],
  ['content', AccordionContent, 'AccordionContent', radixComponentDoc('Accordion.Content')],
];

const AccordionComponentParser : ComponentParser = {
    name: 'Accordion',
    description: 'A vertically stacked set of interactive headings that each reveal a section of content.',
    tags: ['accordion'],
    childrenParsers: childrenParsers(ALIASES, TAG),

    refImplementation: `
/accordion Is it accessible?
  Yes. It adheres to the WAI-ARIA design pattern.
/accordion Is it styled?
  Yes. It comes with default styles that matches the other components' aesthetic.
/accordion Is it animated?
  Yes. It's animated by default, but you can disable it if you prefer.
    `.trim(),

    parseTree: (c: ComponentTreeNode): ComponentTreeNode => {
      // Make sure we always have a trigger and content at the component root
      // FIXME: Dirty hack
      // FIXME: We could accept not having them if user has at least defined one /content and one /trigger in the component
      const c2 = {
        ...c,
        children: [
          ...(c.children || []), 
          {
            component: AccordionTrigger,
            htmlComponent: 'AccordionTrigger',
            props: {},
            classes: [],
            children: [],
          },
          {
            component: AccordionContent,
            htmlComponent: 'AccordionContent',
            props: {},
            classes: [],
            children: [],
          }, 
        ]
      };
      const parsed = mapComponentTreeToSchema(SCHEMA, c2, COMPONENTS_MAP);

      // Move all other children from root into the content
      // FIXME: Dirty hack
      // FIXME: Implement that generically?
      const rest = (parsed.children || []).filter((child) => {
        return ![AccordionTrigger, AccordionContent].some(t => child.component === t);
      });
      parsed.children = (parsed.children || []).filter((child) => {
        return [AccordionTrigger, AccordionContent].some(t => child.component === t);
      });

      parsed.children.find(c => c.component === AccordionContent)?.children.push(...rest);

      // Return
      return parsed;
    },

    /*
    parseTree: (c: ComponentTreeNode) : ComponentTreeNode => {
      return {
        component: AccordionItem as any,
        htmlComponent: 'AccordionItem',
        props: c.props || {},
        classes: c.classes || [],
        children: [
          {
            component: AccordionTrigger,
            htmlComponent: 'AccordionTrigger',
            props: {},
            classes: [],
            children: [ c.text ],
          },
          {
            component: AccordionContent,
            htmlComponent: 'AccordionContent',
            props: c.props || {},
            classes: [],
            children: removeOneOccurenceOfText(c.children || [], c.text || ''),
          }
        ],
      };
    },
    */
  
    wrapParent: (parent: ComponentTreeNode) => {
      if (parent.component === (Accordion as any)) {
        return parent;
      }

      let newChildren : ComponentTreeNode[] = [];
      let currentGroup : ComponentTreeNode[] = [];

      const appendGroup = (group: ComponentTreeNode[]) => {
        newChildren.push({
          component: Accordion as any,
          htmlComponent: 'Accordion',
          props: {
            type: 'single',
            collapsible: true,
            className: 'w-full',
            ...(group[0]?.props || {})
          },
          classes: ['w-full'],
          children: group.map((item: any, i: number) => ({
            ...item,
            props: {
              ...item.props,
              value: 'item_' + i,
            }
          })),
        });
      }

      (parent.children || []).forEach((child) => {
        if (child?.component === AccordionItem) {
          currentGroup.push(child);
        } else if (currentGroup.length > 0) {
          appendGroup(currentGroup);
          currentGroup = [];

          newChildren.push(child);
        } else {
          newChildren.push(child);
        }
      });
      if (currentGroup.length > 0) {
        appendGroup(currentGroup);
      }

      return {
        ...parent,
        children: newChildren,
      }
    },
  
    imports: imports(['Accordion', 'AccordionItem', 'AccordionTrigger', 'AccordionContent'], 'accordion'),
    setup: setup('accordion'),
    variants: [],
    doc: {
        props: {
          ...radixComponentDoc('Accordion.Root').props,
          ...radixComponentDoc('Accordion.Item').props,
        },
    }
  };

registerShadcnComponent({
        Accordion,
        AccordionContent,
        AccordionItem,
        AccordionTrigger,
    },
    'accordion',
);
  
export default AccordionComponentParser;
  