import { pagesDirectory } from "@/lib/dbRoutes";
import check from "@/vendors/check";
import useClerkAuthedFirebase from "@/vendors/firebase/useClerkAuthedFirebase";
import useFirebaseValue from "@/vendors/firebase/useFirebase";
import { useMemo } from "react";
import jsonStableStringify from 'json-stable-stringify';
import moment from "moment";

export type ProjectMetadata = {
    title: string, 
    id: string,
    created: string,
    opened?: string,
}

export function useProjects() {
    const uid = useClerkAuthedFirebase();
    const { value: rawDirectory } = useFirebaseValue<Record<string, ProjectMetadata>>(pagesDirectory(uid || null), {});

    // Parse directory
    const directory = useMemo(() => {
        if (!check.nonEmptyObject(rawDirectory)) {
            return [] as ProjectMetadata[];
        }
        return Object.keys(rawDirectory)
            .map(k => ({...rawDirectory[k], id: k}))
            .sort((a, b) => {
                const dateB = b.opened || b.created || '2001-01-01T00:00:00.000Z';
                const dateA = a.opened || a.created || '2001-01-01T00:00:00.000Z';

                return moment(dateB).valueOf() - moment(dateA).valueOf();
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jsonStableStringify(rawDirectory)]);

    return directory;
}