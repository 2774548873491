/*
// ////////////////////////////
// Gotten from:
// ////////////////////////////
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
Array
    .from(document.querySelectorAll('.code-highlight'))
    .map(e => e.innerHTML.trim())
    .filter(txt => txt.includes(':'))
    .map(txt => txt.split(':')[0])
    .filter(onlyUnique)
*/

const twPrefixes = `
hover
dark
active
focus
first
last
odd
even
disabled
invalid
group-hover
peer-invalid
peer-disabled
*
after
before
placeholder
file
marker
selection
first-line
first-letter
backdrop
motion-reduce
motion-safe
contrast-more
forced-colors
print
supports-grid
aria-checked
aria-selected
aria-disabled
aria-invalid
aria-hidden
group-focus
group-hover
group-disabled
group-invalid
data-checked
xs
sm
md
lg
xl
2xl
focus-within
focus-visible
visited
target
only
first-of-type
last-of-type
only-of-type
empty
enabled
checked
indeterminate
default
required
valid
in-range
out-of-range
placeholder-shown
autofill
read-only
`.split('\n').filter(Boolean).map(e => e.trim());

const twWildcardPrefixes = [
    () => /^\[.+\]$/g, // arbitrary variants
    () => /^has-\[.+\]$/g,
    () => /^peer-\[.+\]$/g,
    () => /^peer-[a-zA-Z]+$/g,
    () => /^group-\[.+\]$/g,
    () => /^data-\[.+\]$/g,
    () => /^aria-\[.+\]$/g,
    () => /^group-aria-\[.+\]$/g,
    () => /^supports-\[.+\]$/g,
    () => /^peer-has-\[.+\]$/g,
    () => /^group-has-\[.+\]$/g,
    () => /^group-over\/.+$/g,
    () => /^peer-checked\/.+$/g,
]

export { twPrefixes, twWildcardPrefixes };