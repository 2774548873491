import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/shadcn/ui/hover-card"
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { imports, radixComponentDoc, setup } from "../_utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
export function HoverCardDemo() {
  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <Button variant="link">@nextjs</Button>
      </HoverCardTrigger>
      <HoverCardContent className="w-80">
        <div className="flex justify-between space-x-4">
          <Avatar>
            <AvatarImage src="https://github.com/vercel.png" />
            <AvatarFallback>VC</AvatarFallback>
          </Avatar>
          <div className="space-y-1">
            <h4 className="text-sm font-semibold">@nextjs</h4>
            <p className="text-sm">
              The React Framework – created and maintained by @vercel.
            </p>
            <div className="flex items-center pt-2">
              <CalendarDays className="mr-2 size-4 opacity-70" />{" "}
              <span className="text-xs text-muted-foreground">
                Joined December 2021
              </span>
            </div>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  )
}
*/

const HoverCardComponentParser : ComponentParser = {
  name: 'Hover Card',
  description: 'For sighted users to preview content available behind a link',
  tags: ['hover-card', 'hover'],
  refImplementation: `
    /button %link 
      "@nextjs"
      /hover-card w-80
        /div flex justify-between space-x-4
          /avatar https://github.com/vercel.png @fallback="VC"
          /div space-y-1
            /h4 text-sm font-semibold "@nextjs"
            /p text-sm
              The React Framework – created and maintained by vercel.
            /div flex items-center pt-2
              /icon %lucide calendar-days size-4 opacity-70 mr-2
              /span text-xs text-muted-foreground Joined December 2021
  `.trim(),


  // This function is called on nodes including children with the tag 'popover'
  // It wraps the parent and places all popover children to the right place
  wrapParent: (parent: ComponentTreeNode) : ComponentTreeNode => {
    let parentProps : { [key:string]: any }  = {};

    const contentChildren = (parent.children || []).filter(child => ['hover-card', 'hover'].includes(child.component)).map((c => {
      const { openDelay, closeDelay, variant, ...other } = c.props || {};

      if (openDelay !== undefined && openDelay !== null) {
        parentProps.openDelay = openDelay;
      }
      if (closeDelay !== undefined && closeDelay !== null) {
        parentProps.closeDelay = closeDelay;
      }

      return ({
      ...c,
      component: HoverCardContent,
      htmlComponent: 'HoverCardContent',
      props: {
        ...(other), 
        ...{ 
          side: c.props?.side || c.variant || 'bottom', 
        }},
      children: [
        ...(c.children || []), 
        /*
        ...(check.nonEmptyString(c.props.arrow) ? [{
          _dontParse: true,
          component: Arrow,
          classes: [ "bg-white", 'text-white' ],
          props: { asChild: false, fill: 'currentColor' },
          children: [ { component: 'div', classes: cn(c.props.arrow).split(' '), children: [] } ],
        }] : [])
        */
      ],
      variant: null,

    });
  }));

    return {
      component: HoverCard,
      htmlComponent: 'HoverCard',
      classes: [],
      props: parentProps,
      children: [
      {
        _dontParse: true,
        component: 'div',
        htmlComponent: 'div',
        classes: [ 'hover-card' ],
        props: {},
        children: [ 
        {
          _dontParse: true,
          component: HoverCardTrigger,
          htmlComponent: 'HoverCardTrigger',
          classes: [ 'relative' ],
          props: {
            asChild: true, // Not working
          },
          children: [
            {
              ...parent,
              children: (parent.children || []).filter(child => !['hover-card', 'hover'].includes(child.component)),
            }
          ]
        },
        ...contentChildren,
        ]
      }],
    }
  },

  imports: imports(['HoverCard', 'HoverCardContent', 'HoverCardTrigger'], 'hover-card'),
  setup: setup('hover-card'),
  variants: [
    'bottom',
    'left',
    'top',
    'right',
  ],
  doc: {
      props: {
        openDelay: radixComponentDoc('HoverCard.Root').props?.openDelay!,
        closeDelay: radixComponentDoc('HoverCard.Root').props?.closeDelay!,
        ...radixComponentDoc('HoverCard.Content').props,
      },
      variants: {
        'bottom': 'The hover card will be displayed below the trigger',
        'left': 'The hover card will be displayed to the left of the trigger',
        'top': 'The hover card will be displayed above the trigger',
        'right': 'The hover card will be displayed to the right of the trigger',
      },
  },
  playerStyle: `
.hover-card * {
    transition: none !important;
}
`.trim(),
};


registerShadcnComponent({
        HoverCard,
        HoverCardContent,
        HoverCardTrigger,
    },
    'hover-card',
);

export default HoverCardComponentParser;
