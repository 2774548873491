import { cn } from "@/lib/utils";

const mobileTheme = {
    name: 'mobile',

    colors: {
        
        // FIXME: TBD
        accent: '#24a094',
        destructive: '#DC2626',


        // Mapped OK
        primary: '#119DEC', // Blue
        secondary: '#E8EDF5', // Gray
        background: '#F7FAFC', // Light blueish gray
    },

    fonts: [
        'Plus Jakarta Sans',
    ],

    cssVars: {
        '--font-display-family': `'Plus Jakarta Sans', sans-serif`,
        '--font-display-style': `'normal'`,  
        '--font-body-family': `'Plus Jakarta Sans', sans-serif`,
        '--font-body-style': `'normal'`,
        '--radius': '10px',
        '--textcolor-primary': '210 37% 8%',
        '--textcolor-secondary': '212 36% 45%',
        '--textcolor-tertiary': '212 36% 45%',
        '--font-display': `'Plus Jakarta Sans', sans-serif`,
        '--font-body': `'Plus Jakarta Sans', sans-serif`,

        '--font-display-style-weight-xs': '400',
        '--font-display-style-weight-sm': '500',
        '--font-display-style-weight-md': '500',
        '--font-display-style-weight-lg': '500',
        '--font-display-style-weight-xl': '700',
        '--font-display-style-weight-2xl': '700',
        '--font-display-style-weight-3xl': '700',
        '--font-display-style-weight-4xl': '700',
        '--font-display-style-weight-5xl': '700',
        '--font-display-style-weight-6xl': '700',
        '--font-display-style-weight-7xl': '700',
        '--font-display-style-weight-8xl': '700',
        '--font-display-style-weight-9xl': '700',
    },

    components: {
    },
}

export default mobileTheme;
