import { useCallback, useMemo, useRef, useState } from "react"
import { Button } from "@/components/shadcn/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/shadcn/ui/popover"
import { Input } from "@/components/shadcn/ui/input"
import { Separator } from "@/components/shadcn/ui/separator"
import { Label } from "@/components/shadcn/ui/label"
import { ProjectMetadata, useProjects } from "@/hooks/useProjects"
import check from "@/vendors/check"
import { useProjectTitle } from "@/hooks/useProjectTitle"
import { ChevronDown, ChevronsDown, FoldersIcon, PlusIcon } from "lucide-react"
import { cn, getNewProjectUrl } from "@/lib/utils"
import { Link, useNavigate } from "react-router-dom"

const TRIGGER_THRESHOLD = 0.6;

export function ProjectSelector({ pageId, readOnly = true } : { pageId: string, readOnly: boolean }) {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const editableRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [rightHovered, setRightHovered] = useState(false);
    const [search, setSearch] = useState(''); 
    const projects = useProjects();
    const [projectTitle, setProjectTitle] = useProjectTitle(pageId, readOnly);
    const navigate = useNavigate();

    const projectsToShow = useMemo(() => {
        let out : ProjectMetadata[] = [];
        for(let i = 0; i < projects.length && out.length < 4; i++) {
            if (
                !check.nonEmptyString(projects[i]?.title)
                || !check.nonEmptyString(projects[i]?.created)
            ) {
                continue;
            }
            if (projects[i].id === pageId) {
                continue;
            }
            if (projects[i].title.toLowerCase().includes(search.trim().toLowerCase())) {
                out.push(projects[i]);
            }
        }

        return out;
    }, [projects, search, pageId]);

    const onElementClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => { 
        // Stop propagation 
        // Except is target has class 'popoverItem'
        if (!popoverOpen) {
            e.preventDefault();
            e.stopPropagation();
        }

        // If the click was made on the 50% at the left of the component, 
        // focus the editable <p> and close the popover
        // If the click was made on the 50% at the right of the component,
        // open the popover and blur the editable <p>
        if (!editableRef.current) {
            console.log('onElementClick: Editable ref not found')
            return;
        }
        if (!containerRef.current) {
            console.log('onElementClick: Container ref not found')
            return;
        }

        
        const rect = (containerRef.current as HTMLElement).getBoundingClientRect(); 
        const x = e.clientX - rect.left;
        if (x < (rect.width - 22)) {
            console.log('onElementClick: Handling click: EDITABLE')
            editableRef.current.focus();
            setPopoverOpen(false);
        } else {
            console.log('onElementClick: Handling click: POPOVER')
            editableRef.current.blur();
            setPopoverOpen((prev) => !prev);
        }
    }, [editableRef, popoverOpen, containerRef]);


    const onElementHover = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        // If the hover was made on the 50% at the left of the component, 
        // do nothing
        // If the hover was made on the 50% at the right of the component,
        // change the hover state of the popover button
        if (!editableRef.current) {
            return;
        }
        if (!containerRef.current) {
            return;
        }
        const rect = containerRef.current.getBoundingClientRect(); 
        const x = e.clientX - rect.left;
        if (x < (rect.width - 22)) {
            setRightHovered(false);
        } else {
            setRightHovered(true);
        }
    }, [editableRef]);

    const onMouseLeave = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        setRightHovered(false);
    }, []);

    const onPopoverClick = useCallback((value: boolean) => {
        if (value) {
            return;
        }
        setPopoverOpen(false);
        if (editableRef.current) {
            editableRef.current.blur();
        }
    }, []);


    console.log('ProjectSelector : ', { popoverOpen });

	return (
		<div 
            ref={containerRef}
            onClickCapture={onElementClick} 
            onMouseMove={onElementHover}
            onMouseLeave={onMouseLeave}
            className={cn(
                "justify-start items-start flex-col flex size-full",
                rightHovered && "cursor-pointer",
                !rightHovered && "cursor-text",
            )}
        >
			
				<Popover
                    open={popoverOpen}
                    onOpenChange={onPopoverClick}   
                >
					<PopoverTrigger asChild >
                        <div className="flex flex-row gap-2 items-center w-full py-0.5 px-1.5 hover:bg-white/60 rounded-lg" onClick={(e) => e.stopPropagation()}>
                            <p 
                                ref={editableRef}
                                className="text-sm font-medium text-[#1e1f22] outline-none" 
                                contentEditable={!readOnly} 
                                suppressContentEditableWarning={true}
                                onBlur={readOnly ? undefined : (e) => {
                                    const value = (e.target as any).innerText;
                                    setProjectTitle(value);
                                }}
                                    >{check.nonEmptyString(projectTitle) ? projectTitle : 'Untitled project'}
                            </p>
                            <Button 
                                className={cn(
                                    "reset transition-all duration-200 ease-in-out",
                                    rightHovered && "translate-y-[2px]",
                                )}
                                variant="default" 
                            >
                                {!rightHovered && (
                                    <ChevronDown className={cn(
                                        "size-3.5",
                                    )} />
                                )}
                                {rightHovered && (
                                    <ChevronsDown className={cn(
                                        "size-3.5",
                                    )} />
                                )}
                                
                            </Button>
                        </div>
					</PopoverTrigger>
					<PopoverContent align="start" className="flex flex-col items-start rounded-lg border p-1 w-48 shadow-md gap-1 bg-white" side="bottom" >
						<Input 
                            onChange={(value) => setSearch(value)}
                            className="reset border py-1.5 px-2.5 text-sm rounded-md w-full outline-white focus:outline-gray-200 text-[#1e1f22] focus:shadow-sm" 
                            placeholder="Search all projects..." 
                        />
						<Separator className="mt-1 mb-1 bg-gray-100" orientation="horizontal" />
						<Label className="text-xs ml-1 mt-1 text-gray-300 mb-1" >
							Recent
						</Label>
                        {projectsToShow.map(p => (
                            <Button 
                                key={p.id} 
                                onClick={() =>{
                                    navigate('/' + p.id + '/edit');
                                }}
                                className="popoverItem reset flex flex-row p-2 place-content-between gap-1 rounded-lg hover:bg-[#f4f3f1] bg-white w-full items-center" 
                            >
                                <div className="flex flex-row items-center justify-start text-left" >
                                    <span className="text-sm text-[#5b5e66]" >
                                        {p.title}
                                    </span>
                                </div>
                            </Button>
                        ))}
						<Separator className="mt-1 mb-1 bg-gray-100" orientation="horizontal" />
						<Button 
                            onClick={() => {
                                navigate(getNewProjectUrl());
                            }}
                            className="reset flex flex-row p-2 place-content-between gap-1 rounded-lg hover:bg-[#f4f3f1] bg-white w-full items-center" >
							<div className="flex flex-row items-center gap-2" >
								<PlusIcon className="size-3.5 text-[#5b5e66]" />
                                <span className="text-sm text-[#5b5e66]" >
									New Project
								</span>
							</div>
						</Button>
						<Button 
                            onClick={() => {
                                navigate('/projects');
                            }}
                            className="reset flex flex-row p-2 place-content-between gap-1 rounded-lg hover:bg-[#f4f3f1] bg-white w-full items-center" >
							<div className="flex flex-row items-center gap-2" >
								<FoldersIcon className="size-3.5 text-[#5b5e66]" />
								<span className="text-sm text-[#5b5e66]" >
									All Projects
								</span>
							</div>
						</Button>
					</PopoverContent>
				</Popover>
			</div>
	);
}