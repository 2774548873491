import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/utils";

const CardVariantsW = cva("",{ 
    variants: {
        size: {
            "xs": "w-[173px] min-w-[173px] max-w-[173px]",
            "sm": "w-[223px] min-w-[223px] max-w-[223px]",
            "md": "w-60 min-w-60 max-w-60",
            "lg": "w-[301px] min-w-[301px] max-w-[301px]",
            "xl": "w-[522px] min-w-[522px] max-w-[522px]",
            "2xl": "w-[928px] min-w-[928px] max-w-[928px]",
            "3xl": "",
            "4xl": "",
            "5xl": "",
            "6xl": "",
        },
        roundness: {
            'none': "rounded-none",
            "sm": "rounded-sm",
            "md": "rounded-md",
            "lg": "rounded-lg",
            "xl": "rounded-xl",
            "full": "rounded-full",
        },
    },
    defaultVariants: {
        size: "lg",
        roundness: 'xl',
    },
});

interface CardProps extends VariantProps<typeof CardVariantsW> {
    image : string,
    title: string,
    description: string,
}

function Card_S({
    image,
    title,
    description,
    size,
    roundness,
} : CardProps ) {
    return (
        <div className={cn(CardVariantsW({size}), `h-fit flex-col justify-start items-start pb-[5px] `)}>
            <img className={cn(CardVariantsW({size, roundness}), "aspect-square object-cover")} src={image} />
            <div className={cn(CardVariantsW({size}),"self-stretch text-neutral-900 dark:text-white typeface-display-base mt-[12px]")}>{title}</div>
            <div className="self-stretch text-slate-500 dark:text-slate-400 typeface-body-sm max-w-full min-h-fit ">{description}</div>
        </div>
    )
}

export default Card_S;