import { useComposableApi } from '../../../lib/composable';
import { cn } from '../../../lib/utils';
import { VariantProps, cva } from 'class-variance-authority';
import React from 'react';

const MetricsPaddingVariants = cva(
	"h-fit w-full justify-start items-start flex-col flex rounded-xl",
	{
		variants: {
			size: {
                "6xl": "p-16 gap-4",
                "5xl": "p-12 gap-4",
                "4xl": "p-10 gap-3",
                "3xl": "p-8 gap-3",
                "2xl": "p-6 gap-2",
                "xl": "p-5 gap-2",
                "lg": "p-4 gap-1.5",
                "md": "p-3.5 gap-1.5",
                "sm": "p-3 gap-1",
                "xs": "p-2.5 gap-1",
			},
			variant: {
				"default": "bg-card bg-secondary",
				"outline": "border border-border",
			},
			orientation: {
				"north": "",
				"south": "",
			},
			rounded: {
				"none": "rounded-none",
				"sm": "rounded-sm",
				"md": "rounded-md",
				"lg": "rounded-lg",
				"xl": "rounded-xl",
				"2xl": "rounded-2xl",
				"3xl": "rounded-3xl",
				"full": "rounded-full",
			}
		},
		defaultVariants: {
			size: "lg",
			variant: "default",
			orientation: "north",
		},
	}
)

export interface MetricsProps extends VariantProps<typeof MetricsPaddingVariants> {
    className ?: string;
    children?: React.ReactNode;
}

function fontSizeToPx(size: any) {
	if (!size || typeof size !== "string") {
		return "16px";
	}
	switch(size) {
		case "xs": return "12px";
		case "sm": return "14px";
		case "md": return "16px";
		case "lg": return "18px";
		case "xl": return "20px";
		case "2xl": return "24px";
		case "3xl": return "30px";
		case "4xl": return "36px";
		case "5xl": return "48px";
		case "6xl": return "60px";
		case "7xl": return "72px";
		case "8xl": return "96px";
		case "9xl": return "128px";
		default: return "16px";
	}
}

function sizePercentageInPx(size: any, percent: number) {
	const fontSize = fontSizeToPx(size);
	const fontSizeValue = parseFloat(fontSize.substring(0, fontSize.length - 2));
	const percentageValue = Math.round(fontSizeValue * percent);
	return percentageValue + "px";
}


export function Metrics({ className, children, size = "lg", orientation = "north", variant = "default" }: MetricsProps) {
	const { ChildWithRole, ChildrenWithRole } = useComposableApi(children);


	const titleSize = fontSizeToPx(size);
	const descriptionSize = sizePercentageInPx(size, 2/3);

	const title_style = cn("typeface-display-[" + titleSize + "] text-textcolor-primary font-bold" )
    const description_style = cn("typeface-body-[" + descriptionSize + "] text-textcolor-secondary font-medium" )

	return (
		<div className={cn(MetricsPaddingVariants({ size, variant }), className)} >
			{orientation === "north" ? (
				<>
					<ChildWithRole
						role="title"
						className={title_style}
					/>
					<ChildWithRole
						role="description"
						className={description_style}
					/>
				</>
			) : (
				<>
					<ChildWithRole
						role="description"
						className={description_style}
					/>
					<ChildWithRole
						role="title"
						className={title_style}
					/>
				</>
			)}
		</div>
	);
}