import { ComponentAspectRatio, ComponentRoundness } from "../../lib/components-registry";
import ListItemIcon from "./Icon/ListItemIcon";
import ListItemImage from "./Img/ListItemImage";

interface CardProps {
    children?: React.ReactNode;
    className?: string;
    role?: string;
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | null | undefined;
    rounded?: ComponentRoundness;
    verticalAlign?: 'top'|'center'|'bottom';
    aspectRatio?: ComponentAspectRatio;
    action?: () => void;
    variant?: string;
}

export type ListItemCardVariant = 'with-image' | 'with-icon';

export function ListItem({
    children,
    className,
    size,
    rounded,
    verticalAlign,
    aspectRatio,
    variant = 'with-image',
} : CardProps){
    if (variant === 'with-icon') {
        return (
            <ListItemIcon className={className} size={size} rounded={rounded} verticalAlign={verticalAlign}>{children}</ListItemIcon>
        )
    } else {
        return (
            <ListItemImage className={className} aspectRatio={aspectRatio} size={size} rounded={rounded} verticalAlign={verticalAlign}>{children}</ListItemImage>
        )
    }
}