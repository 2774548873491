import { twRegex1, twRegex2 } from "@/lib/tailwind/tailwindClasses";

export const FORMAT = {

    keywords: ["not"],
  
  
    tokenizer: {
      root: [
        // AutoCode Types
        [/\/[a-zA-Z][a-zA-Z0-9-.]*/, "blockhead"],
        [/#[a-zA-Z0-9-_]+/, "hash"],
        [/%[0-9a-zA-Z_/-]+/, "variant"],
        [/\(.*\.{3,}.*\)/, "openai"],
        [/[\s\n]{1,}\/\/.*/, "comment"],
        [/^\/\/.*/, "comment"],
        [/\s@[a-zA-Z0-9_-]+={0,1}/, "prop-name"],

        // Tailwind CSS Classes with value
        // [/((hover|focus|active|group-hover|group-focus|focus-within|focus-visible|motion-safe|motion-reduce|disabled|visited|checked|first|last|odd|even|dark|md|lg|xl|sm):)*(m|p|mx|my|mt|mb|mr|ml|px|py|pt|pb|pr|pl|w|h|max-w|max-h|min-w|min-h|text|font|tracking|leading|border|divide|bg|gradient|from|via|to|ring|shadow|opacity|scale|rotate|translate|skew|transition|ease|duration|delay|animate|grid)-[\w/:0-9]+(-[\w/:0-9]+)*/, "tailwind-class"],
        // Standalone Tailwind CSS Classes
        // [/((hover|focus|active|group-hover|group-focus|focus-within|focus-visible|motion-safe|motion-reduce|disabled|visited|checked|first|last|odd|even|dark|md|lg|xl|sm):)*(block|hidden|flex|grid|table|flow-root|auto|fr|subgrid|col|col-span|col-start|col-end|row|row-span|row-start|row-end|gap|gap-x|gap-y|auto-cols|auto-rows|grid-cols|grid-rows|rounded|shadow|border)/, "tailwind-class"],
        [new RegExp(twRegex1), "tailwind-class"],
        [new RegExp(twRegex2), "tailwind-class"],
      ],

      multiLineComment: [
          [/\/\*.*/, { token: "multi.line.comment", next: "@insideMultiLineComment" }],
      ],
      insideMultiLineComment: [
          [/(?!\*\/)/, "multi.line.comment"],
          [/\*\//, { token: "multi.line.comment", next: "@pop" }],
      ],
    }
  };


  const COLORS = {
    COMMENT: 'BBBBBB', 
    TAILWIND: '8855FF', 
    PROPS: '5D770D', 
    VARIANT: 'FFAA00', 
    COMPONENT: '33AAEE', 
    DEFAULT_TEXT: '1E1F22',
    BACKGROUND: 'FBFBFB',
  };

  /*
  // Log colors for use in other products
  console.log('STYLES FOR CODE: ' + JSON.stringify({
    REGEXP: [
      {
        type: 'blockhead',
        reg: `\\/[a-zA-Z][a-zA-Z0-9-.]*`,
      },
      {
        type: 'hash',
        reg: `#[a-zA-Z0-9-_]+`,
      },
      {
        type: 'variant',
        reg: `%[0-9a-zA-Z_/-]+`,
      },
      {
        type: 'openai',
        reg: `\\(.*\\.{3,}.*\\)`,
      },
      {
        type: 'comment',
        reg: `[\\s\n]{1,}\\/\\/.*`,
      },
      {
        type: 'comment',
        reg: `^\\/\\/.*`,
      },
      {
        type: 'prop-name',
        reg: `\\s@[a-zA-Z0-9_-]+={0,1}`,
      },
       {
        reg: twRegex1,
        type: "tailwind-class"
      },
      {
        reg: twRegex2,
        type: "tailwind-class",
      }
    ],
    colors: {
      "background": COLORS.BACKGROUND,
      blockhead: COLORS.COMPONENT,
      hash: '27E8A7',
      variant: COLORS.VARIANT,
      "multi.line.comment": COLORS.COMMENT,
      comment: COLORS.COMMENT,
      keywords: '4b0082',
      openai: '32AE86',
      "tailwind-class": COLORS.TAILWIND,
      "prop-name": COLORS.PROPS,
    }
  }, null, 4))
*/


  const NORD_THEME = {
    "base": "vs",
    "inherit": true,
    "rules": [
      {
        "background": COLORS.BACKGROUND,
        "token": ""
      },
      {
        "foreground": COLORS.COMMENT,
        "token": "comment"
      },
      {
        "foreground": "5d90cd",
        "token": "string"
      },
      {
        "foreground": "46a609",
        "token": "constant.numeric"
      },
      {
        "foreground": "39946a",
        "token": "constant.language"
      },
      {
        "foreground": "af956f",
        "token": "keyword"
      },
      {
        "foreground": "af956f",
        "token": "support.constant.property-value"
      },
      {
        "foreground": "af956f",
        "token": "constant.other.color"
      },
      {
        "foreground": "96dc5f",
        "token": "keyword.other.unit"
      },
      {
        "foreground": "484848",
        "token": "keyword.operator"
      },
      {
        "foreground": "c52727",
        "token": "storage"
      },
      {
        "foreground": "858585",
        "token": "entity.other.inherited-class"
      },
      {
        "foreground": "606060",
        "token": "entity.name.tag"
      },
      {
        "foreground": "bf78cc",
        "token": "constant.character.entity"
      },
      {
        "foreground": "bf78cc",
        "token": "support.class.js"
      },
      {
        "foreground": "606060",
        "token": "entity.other.attribute-name"
      },
      {
        "foreground": "c52727",
        "token": "meta.selector.css"
      },
      {
        "foreground": "c52727",
        "token": "entity.name.tag.css"
      },
      {
        "foreground": "c52727",
        "token": "entity.other.attribute-name.id.css"
      },
      {
        "foreground": "c52727",
        "token": "entity.other.attribute-name.class.css"
      },
      {
        "foreground": "484848",
        "token": "meta.property-name.css"
      },
      {
        "foreground": "c52727",
        "token": "support.function"
      },
      {
        "background": "ff002a",
        "token": "invalid"
      },
      {
        "foreground": "c52727",
        "token": "punctuation.section.embedded"
      },
      {
        "foreground": "606060",
        "token": "punctuation.definition.tag"
      },
      {
        "foreground": "bf78cc",
        "token": "constant.other.color.rgb-value.css"
      },
      {
        "foreground": "bf78cc",
        "token": "support.constant.property-value.css"
      },
      {
        token: 'scroll-decoration',
        foreground: 'CC3689', // Change this to your desired color
      },
    ],
    "colors": {
      "editor.foreground": "#" + COLORS.DEFAULT_TEXT,
      "editor.background": "#" + COLORS.BACKGROUND,
      "editor.selectionBackground": "#BDD5FC",
      "editor.lineHighlightBackground": "#e2e8f0",
      "editorCursor.foreground": "#000000",
      "editorWhitespace.foreground": "#BFBFBF"
    }
  }

  const rules =  [
    { token: "blockhead", foreground: COLORS.COMPONENT, fontWeight: 'black'},
    { token: "hash", foreground: "27E8A7"},
    { token: "variant", foreground: COLORS.VARIANT},
    { token: "multi.line.comment", foreground: COLORS.COMMENT},
    { token: "comment", foreground: COLORS.COMMENT},
    { token: "keywords", foreground: "4b0082"},
    { token: "openai", foreground: '32AE86' }, // Color will be replaced by CSS
    { token: "tailwind-class", foreground: COLORS.TAILWIND /* '606060' */ },
    { token: "prop-name", foreground: COLORS.PROPS /* '606060' */ },
  ];
  
  export const THEME = {...NORD_THEME, rules}; 
  
  /*{
      base: 'vs-dark', // can also be vs-dark or hc-black
      inherit: true
      , // can also be false to completely replace the builtin rules
      rules: [
      { token: "blockhead", foreground: "32AE85", weight:'bold'},
      { token: "hash", foreground: "27E8A7"},
      { token: "variant", foreground: "DF4576"},
      { token: "multi.line.comment", foreground: "ffe505"},
      { token: "comment", foreground: "ffe505"},
      { token: "keywords", foreground: "4b0082"},
      { token: "openai", foreground: '32AE86' }, // Color will be replaced by CSS
      { token: "tailwind-class", foreground: 'DF3079' },
    ],
    colors: {
      "editorCursor.foreground": "#586677",
      // "editor.lineHighlightBackground": "#f9fcff",
    }
  } */