
import { Menu } from "lucide-react"

import { Button } from "@/components/shadcn/ui/button"
import * as SheetPrimitive from "@radix-ui/react-dialog"
import { useComposableApi } from "../../../lib/composable"
import { SideBar } from "./SideBar"
import MobileTopBar from "./MobileTopBar"

const Sheet = SheetPrimitive.Root

const SheetTrigger = SheetPrimitive.Trigger

// const SheetClose = SheetPrimitive.Close

// const SheetPortal = SheetPrimitive.Portal

export function TopBar(
    {children, barOrientation='left', side='left', variant = 'default', className }
    :{children?:React.ReactNode, barOrientation?:'left'|'right'; side?:'left'|'right', variant?:string, className?:string}
) {
const { ChildWithRole, ChildrenWithRole, HasAtLeastOneChildWithRole } = useComposableApi(children);
const side_: 'left'|'right'|'top'|'bottom' = side;

// Handle mobile variant separately
if (variant === 'mobile') {
    return <MobileTopBar className={className} >{children}</MobileTopBar>
}

const showSidebar = HasAtLeastOneChildWithRole("sideBar");

return (
    <>
    <header className="fixed left-0 right-0 top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6 w-full z-50">
        {showSidebar && (
            <Sheet>
                <SheetTrigger asChild>
                    {HasAtLeastOneChildWithRole("leftElement") === true
                    ?   <ChildWithRole
                            role="leftElement"
                            className="md:hidden shrink-0"
                        ></ChildWithRole>
                    :   <Button
                            variant="outline"
                            size="icon"
                            className="shrink-0 md:hidden"
                        >
                            <Menu className="h-5 w-5" />
                            <span className="sr-only">Toggle navigation menu</span>
                        </Button>
                    }
                </SheetTrigger>
                {HasAtLeastOneChildWithRole("sideBar")
                ? <ChildWithRole
                    role="sideBar"
                ></ChildWithRole>
            :   <SideBar></SideBar>}
            </Sheet>
        )}
        <ChildWithRole
            role="title"
            className="md:flex text-foreground transition-colors hover:text-foreground typeface-body-lg font-bold flex-row items-center gap-2"
        >
        </ChildWithRole>
        {barOrientation == 'right' 
        ?   <div className="hidden sm:flex h-full w-[1px] ml-auto"></div>
        :   <></>}
        <nav className={`hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6`}>          
            <ChildrenWithRole
                role="links"
                className="text-foreground transition-colors hover:text-foreground typeface-display-base"
                >
            </ChildrenWithRole>
        </nav>
        <div className={`flex items-center gap-4 ${barOrientation == 'left' ? "md:ml-auto" : "sm:w-fit"} md:gap-2 lg:gap-4 h-full`}>
            {HasAtLeastOneChildWithRole("middleElement") === true
                ?   <div className={`${barOrientation == 'left' ? "ml-auto " : ""}flex-1 sm:flex-initial overflow-hidden flex flex-row gap-2 items-center`}><ChildrenWithRole
                        role="middleElement"
                        className=""
                    ></ChildrenWithRole></div>
                :   <div className={`h-full w-4 ${barOrientation == 'left' ? "ml-auto " : ""}`}></div>}
            <ChildrenWithRole
                role="rightElement"
                className="h-full max-h-full"
            ></ChildrenWithRole>
        </div>
    </header>
    </>
)
}
