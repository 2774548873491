import { AspectRatio } from "@/components/shadcn/ui/aspect-ratio"
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { selectVariant } from "../../../util";
import check from "@/vendors/check";
import { nonEmptyTrimmed } from "@/lib/utils";
import { imports, radixComponentDoc, setup } from "../_utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
export function AspectRatioDemo() {
  return (
    <AspectRatio ratio={16 / 9} className="bg-muted">
      <Image
        src="https://images.unsplash.com/photo-1588345921523-c2dcdb7f1dcd?w=800&dpr=2&q=80"
        alt="Photo by Drew Beamer"
        fill
        className="rounded-md object-cover"
      />
    </AspectRatio>
  )
}
*/

// NOTE: What's the difference with using Tailwind aspect-* classes? Any better?
//       If not, we may need to remove this one

const VARIANTS = [
  'video',
  'cinema',
  'square',
  'cinema-portrait',
  'video-portrait',
];
const variantsValueMap : { [key:string]: any }= {
  'video': 16 / 9,
  'cinema': 21 / 9,
  'square': 1,
  'cinema-portrait': 9 / 21,
  'video-portrait': 9 / 16,
};

function parseRatio(ratio: any) {
    if (check.number(ratio)) {
        return ratio;
    }
    if (check.nonEmptyString(ratio) && nonEmptyTrimmed(ratio)) {
        if (/[0-9]+\s*\/\s*[0-9]+/g.test(ratio.trim())) {
            const [a, b] = ratio.split('/').map(Number);
            return a / b;
        } else if (VARIANTS.includes(ratio.trim().toLowerCase())) {
            return variantsValueMap[selectVariant(VARIANTS, ratio)];
        } else if (!isNaN(Number(ratio))) {
            return Number(ratio);
        }
    }

    return undefined;
}

const AspectRatioComponentParser : ComponentParser = {
  name: 'Aspect Ratio',
  description: 'Displays content within a desired ratio',
  refImplementation: `
/div w-1/4
    /aspect-ratio %video
        /image size-full object-cover rounded-md Night full of stars
`.trim().replace('  ', '\t'),
  tags: ['aspect-ratio'],
  parseTree: (c: ComponentTreeNode) => {
      const { variant, text } = c;

      let ratio = 4 / 3;

      // If a variant is defined, use it to set the ratio
      if (check.nonEmptyString(variant) && VARIANTS.includes(variant.trim().toLowerCase())) {   
          ratio = variantsValueMap[selectVariant(VARIANTS, variant)];
      }

      // If text is defined, override the ratio with the text value
      if (check.nonEmptyString(text)) {
        ratio = parseRatio(text) || ratio;
      }

      if (c.props?.ratio) {
          ratio = parseRatio(c.props.ratio) || ratio;
      }

      return {
          ...c,
          component: AspectRatio,
          htmlComponent: 'AspectRatio',
          props: { ratio },
          variant: null,
      }
  },
  imports: imports(['AspectRatio'], 'aspect-ratio'),
  setup: setup('aspect-ratio'),
  variants: VARIANTS,
  doc: {
     props: radixComponentDoc('AspectRatio.Root').props,
     variants: {
        'video': '16/9 aspect ratio',
        'cinema': '21/9 aspect ratio',
        'square': '1/1 aspect ratio',
        'cinema-portrait': '9/21 aspect ratio',
        'video-portrait': '9/16 aspect ratio',
     },
  }
};

registerShadcnComponent({
        AspectRatio
    },
    'aspect-ratio',
);

export default AspectRatioComponentParser;
