"use client";

import Chat from "./chat";
import { useCallback, useMemo, useRef, useState } from "react";
import { editor } from "monaco-editor";
import { Monaco } from "@monaco-editor/react";
import { createEditorFunctionsHandler, insertTextAtCursor } from "./editor-handlers";
import check from "@/vendors/check";

export const dynamic = "force-dynamic";


export function ChatPanel({
  editorInstance,
}: {
  editorInstance: { editor: editor.IStandaloneCodeEditor, monaco: Monaco } | null
}) {
  const [editorModeActive, setEditorModeActive] = useState(false);
  const editsRef = useRef<{ running: boolean, queue:string[] }>({ running: false, queue: [] });

  const runLoop = useCallback(() => {
    if (!editorInstance?.editor || !editorInstance?.monaco || editsRef.current.running || (!check.nonEmptyArray(editsRef.current.queue))) {
      return;
    }
    editsRef.current.running = true;

    const run = () => {
      if (editsRef.current.queue.length > 0) {
        const message = editsRef.current.queue.shift();
        if (message) {
          insertTextAtCursor({ editor: editorInstance?.editor, monaco: editorInstance?.monaco }, { text: message });
        }
        setTimeout(run, 100);
      } else {
        editsRef.current.running = false;
      }
    };
    
    setTimeout(run, 100);

  }, [editorInstance?.editor, editorInstance?.monaco ]);


  const onCaughtStreamContent = useCallback((message: string) => {
    // Append value to the editor at the caret position, move caret to the end of the inserted text
    // Don't use keyoard events as we want auto-indent to be disabled while we insert the text
    
    // insertTextAtCursor(editorInstance, { text: message });
    editsRef.current.queue.push(message);
    runLoop();
  }, [runLoop]);

  const functionCallHandler = useMemo(() => createEditorFunctionsHandler({
    editorInstance,
    editorModeActive,
    setEditorModeActive,
  }), [editorInstance, editorModeActive, setEditorModeActive]); 

  return (
    <div className="h-full w-full" >
      <Chat 
        functionCallHandler={functionCallHandler} 
        onCaughtStreamContent={onCaughtStreamContent}
        catchStream={editorModeActive}
      />
    </div> 
  );
}
