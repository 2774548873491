import { Slider } from "@/components/shadcn/ui/slider"
import { ComponentTreeNode } from "../../component.type";
import { getSingleVarNameFromText, selectVariant } from "../../../util";
import check from "@/vendors/check";
import { imports, radixComponentDoc, setup } from "../_utils";
import { cn } from "@/lib/utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { DataInput } from "@/vendors/data/data-v2";

/*
import { cn } from "@/lib/utils"
import { Slider } from "@/components/ui/slider"
 
type SliderProps = React.ComponentProps<typeof Slider>
 
export function SliderDemo({ className, ...props }: SliderProps) {
  return (
    <Slider
      defaultValue={[50]}
      max={100}
      step={1}
      className={cn("w-[60%]", className)}
      {...props}
    />
  )
}
*/

const parseNumber = (val: string | number) =>  {
    let number = 0;
    if (check.number(val)) {
        number = val;
    } else {
        number = parseInt(val + '', 10);
    }
    if (isNaN(number)) { 
        number = 0;
    }

    return number;
};

const minMax = (val: number, min: number, max: number) => {
    return Math.max(Math.min(val, max), min);
}

const VARIANTS = [
    'horizontal',
    'vertical',
];

const SliderComponentParser = {
    name: 'Slider',
    description: 'An input where the user selects a value from within a given range.',
    tags: ['slider'],
    refImplementation: `
    /slider 30 w-[60%]
    `.trim(),
    parseTree: (c: ComponentTreeNode) : ComponentTreeNode => {
        const { props = {}, text } = c;

        let txt = text;
        let textVar : string | null = null;

        if (check.nonEmptyString(text)) {
            textVar = getSingleVarNameFromText(text);
            if (textVar) {
                txt = text.replace(`${textVar}`, '').trim();
            }
        }

        const max = Math.abs(parseNumber(c.props.max || 100));
        const min = minMax(parseNumber(c.props.min || 0), 0, max);
        const step = minMax(parseNumber(c.props.step || 1), 1, max - min);
        const value = minMax(parseNumber(c.props.value || c.props.defaultValue || txt || 50), min, max);

        let component : React.ComponentType = Slider;
        let htmlComponent = 'Slider';

        let wrap : (c: ComponentTreeNode) => ComponentTreeNode = (c: ComponentTreeNode) => c;
        
        if (textVar) {
            wrap = (c: ComponentTreeNode) => ({
                component: DataInput as React.ComponentType,
                htmlComponent: 'DataInput',
                props: {
                    bind: textVar,
                    type: 'number',
                },
                classes: [],
                variant: null,
                children: [c],
            });
        } else {
            props.defaultValue = value;
        }

        const classes =  cn(c.classes || [], 'no-transition').split(' ').filter(check.nonEmptyString);

        return wrap({ 
            ...c, 
            component, 
            htmlComponent,
            classes,
            props: {
                ...props,
                min,
                max,
                step,
                orientation: selectVariant(VARIANTS, c.variant),
                className: check.nonEmptyArray(classes) ? classes.join(' ') : undefined,
            },
            children: null,
            variant: null,
        });
    },
    imports: imports(['Slider'], 'slider'),
    setup: setup('slider'),
    variants: VARIANTS,
    doc: {
        props: radixComponentDoc('Slider.Root').props,
    }
};

registerShadcnComponent({
        Slider
    },
    'slider',
);

export default SliderComponentParser;