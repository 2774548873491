import check from "@/vendors/check";
import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
import { getTailwindColorValue, isTailwindColor } from "./tailwind/tailwindClasses";
import tinycolor from "tinycolor2";
import { bulletProofJSONParse } from "@/vendors/bulletproof-json";
import * as acorn from 'acorn';
import escodegen from 'escodegen';
import { extractVarNamesFromText, isUrlOrDataUrl } from "./parser/util";
import { dataProxy } from "@/vendors/data/data-engine";
import arrayUnique from "array-unique";
import uniqid from "uniqid";


// Enable users to remove the beginning of a class chain by adding 'reset' in it
// eg "text-color-500 reset text-center" => "text-center"
function handleReset(className: string) : string {
  if (!check.string(className)) {
    return className;
  }
  const parts = className.split(' ');
  const resetIndex = parts.lastIndexOf('reset');
  if (resetIndex === -1) {
    return className;
  }

  return parts.slice(resetIndex).join(' ')
}

// FIXME: Also merge when tailwind prefixes are used
// eg lg:w-4 lg:h-4 --> lg:size-4
function mergeSizeAndWidthHeightClasses(className: string) {
  if (!check.nonEmptyString(className) || (
      !className.includes('size-')
      && (!className.includes('w-') || !className.includes('h-'))
  )) {
    return className;
  }

  const parts = className.split(' ');
  const newParts = parts.map((part) => {
    if (part.startsWith('size-')) {
      return part.replace('size-', 'w-') + ' ' + part.replace('size-', 'h-');
    }
    return part;
  }).join(' ').split(' ');

  let merged = twMerge(...newParts);
  let finalW: string | undefined;
  let finalH: string | undefined;

  const afterMergeParts = merged.split(' ');
  afterMergeParts.forEach((part: string) => {
    if (part.startsWith('w-')) {
      finalW = part;
    }
    if (part.startsWith('h-')) {
      finalH = part;
    }
  });

  if (finalW && finalH && finalW.substring(2) === finalH.substring(2)) {
    const finalSize = finalW.replace('w-', 'size-');
    merged = merged.replace(finalW, finalSize).replace(finalH, '');
  }

  return merged.split(' ').filter(check.nonEmptyString).join(' ');
}
 
export function cn(...inputs: ClassValue[]) {
  return mergeSizeAndWidthHeightClasses(twMerge(handleReset(clsx(inputs))));
}

export function nonEmptyTrimmed(str: any) : str is string {
  return check.nonEmptyString(str) && check.nonEmptyString(str.trim());
}

export function onlyUnique(value: any, index: number, array: any[]) {
  return array.indexOf(value) === index;
}

export function isVariantTag(word: string) {
  return check.nonEmptyString(word) && word.startsWith('%') && /^%[a-zA-Z0-9_./-]*$/g.test(word);
}

export function isNumericValue(value: string) {
  if (!check.nonEmptyString(value)) {
    return false;
  }

  if (!/^[0-9]+.{0,1}[0-9]*[a-zA-Z%]*$/.test(value)) {
    return false;
  }

  return true;
}

export function increaseOrDecreaseNumericValue(value: string, diff: number) {
  if (!isNumericValue(value)) {
    return value;
  }

  const [number, unit] = value.split(/([a-zA-Z%]+)/);

  const newValue = parseFloat(number);
  if (isNaN(newValue)) {
    return value;
  }
  // Diff is an integer. If the number is an integer, add diff to it. If it's a float, add diff to the decimals
  if (check.integer(newValue) || (Math.floor(newValue) === newValue)) {
    return `${newValue + diff}${unit}`;
  }

  const decimal = newValue - Math.floor(newValue);
  const nDecimals = decimal.toString().split('.')[1].length;
  const diffDecimals = diff / Math.pow(10, nDecimals);
  return `${newValue + diffDecimals}${unit}`;
}

export function isHslColor(value: string) {
  return /^[0-9]+(\.[0-9]+)?\s[0-9]+(\.[0-9]+)?%\s[0-9]+(\.[0-9]+)?%$/g.test(value);
}

export function parseColorToHex(color: string, knownColors : { [colorName: string]: string }  = {}) : string | null {
  if (!check.nonEmptyString(color)) {
    return null;
  }

  // If color is part of the input dictionary, return its parsed value
  if (check.nonEmptyString(knownColors[color])) {
    return parseColorToHex(knownColors[color]);
  }

  // Handle default Tailwind colors
  if (/^[a-z]+-[0-9]{2,3}$/g.test(color) && isTailwindColor(color)) {
    return tinycolor(getTailwindColorValue(color)).toHex8String();
  }

  // Handle tailwind HSL-formatted colors
  if (isHslColor(color)) {
    return tinycolor(`hsl(${color.replaceAll(/ {2,}/g, ' ').split(' ').join(',')})`).toHex8String();
  }

  // Else, attempt parsing the color with tinyColor
  const parsedColor = tinycolor(color);
  if (parsedColor.isValid()) {
    return parsedColor.toHex8String();
  }

  // We return null if we cannot parse the color
  return null;
}

export function isColor(value: string) {
  return !!parseColorToHex(value);
}

export function isCustomClassName(value: string) {
  return check.nonEmptyString(value) && /^\.[_a-zA-Z]+[_a-zA-Z0-9-]*$/g.test(value.trim());
}

function isFunctionCode(inputString: string) {
  // Discard obvious mismatches
  if (!check.nonEmptyString(inputString) || (!inputString.includes('function') && !inputString.includes('=>'))) {
      return false;
  }

  // Attempt to parse function-looking strings
  try {
      const ast = acorn.parse(inputString, {ecmaVersion: 2022, sourceType: "module"});
      
      // Check if the entire AST represents exactly one function (async or non-async)
      if (ast.body.length === 1) {
          const node = ast.body[0];
          // Check for a direct function declaration
          if (node.type === 'FunctionDeclaration') {
              return true;
          }
          // Check for a variable declaration that initializes a function or an arrow function expression
          if (node.type === 'VariableDeclaration' && node.declarations.length === 1 && node.declarations[0].init &&
              (node.declarations[0].init.type === 'FunctionExpression' || node.declarations[0].init.type === 'ArrowFunctionExpression')) {
              return true;
          }
          // Check for an arrow function or function expression
          if (node.type === 'ExpressionStatement' &&
              (node.expression.type === 'ArrowFunctionExpression' || node.expression.type === 'FunctionExpression')) {
              return true;
          }
      }

      return false; // Not a single function or not valid JavaScript code
  } catch (e) {
      return false; // Parsing failed, input is not valid JavaScript code
  }
}

function extractParamNames(param: acorn.Pattern) : string {
  switch (param.type) {
      case 'Identifier':
          return param.name;
      case 'ObjectPattern':
          return `{${param.properties.map(prop => {
              if (prop.type === 'Property') {
                  // Check if key is a Literal or Identifier, handle accordingly
                  const keyName = prop.key.type === 'Identifier' ? prop.key.name : 
                                  prop.key.type === 'Literal' ? prop.key.value : 
                                  'unknownKey';
                  // The value could be a nested pattern, handle it recursively
                  const valueName = extractParamNames(prop.value);
                  return `${keyName}: ${valueName}`;
              } else if (prop.type === 'RestElement') {
                  return `...${extractParamNames(prop.argument)}`;
              }
          }).join(', ')}}`;
      case 'ArrayPattern':
          return `[${param.elements.map(element => element ? extractParamNames(element) : '').join(', ')}]`;
      case 'RestElement':
          return `...${extractParamNames(param.argument)}`;
      // Add more cases as necessary
      default:
          return '';
  }
}

function generateSourceCodeFromValidAST(ast: acorn.Program) {
  return escodegen.generate(ast);
}

// This function assumes that `inputString` is a valid JavaScript function string
// as validated by your `isValidSingleFunctionCode` function
function createFunctionFromValidAST(ast: acorn.Program) {
  // Extract parameters and body for the Function constructor
  let params: string[] = [];
  let body = '';

  if (ast.body[0].type === 'FunctionDeclaration') {
    // FunctionDeclaration handling
    params = ast.body[0].params.map(extractParamNames);
    body = escodegen.generate(ast.body[0].body);
  } else if (ast.body[0].type === 'ExpressionStatement' &&
             ast.body[0].expression.type === 'ArrowFunctionExpression') {
    // ArrowFunctionExpression handling
    params = ast.body[0].expression.params.map(extractParamNames);
    body = escodegen.generate(ast.body[0].expression.body);
  }

  // Removing the outer braces from the function body
  body = body.replace(/^\{|\}$/g, '');

  // Create and return the new function
  // eslint-disable-next-line no-new-func
  return new Function(params.join(','), body);
}

const cachedFunctions = new Map<string, Function>();

export type SerializedFunction = {
  _objType: 'function',
  code: string,
};

export function serializedFunctionFromString(inputString: string) : SerializedFunction | null {
  if (!isFunctionCode(inputString)) {
    return null;
  }

  // Attempt to parse function-looking strings
  try {
    let functionBody = inputString;
    // Replace variable names with a replacement value
    const variables = arrayUnique(extractVarNamesFromText(functionBody));
    variables.forEach((variable) => {
      const baseName = variable.split('.')[0].split('[')[0];
      functionBody = functionBody.replaceAll(baseName, `window.dataProxy['${baseName}']`);
    });

    return {
      _objType: 'function',
      code: functionBody,
    };
  } catch (e) {
    return null; // Parsing failed, input is not valid JavaScript code
  }
}

export function isSerializedFunction(obj: any) : obj is SerializedFunction {
  return check.nonEmptyObject(obj) && obj._objType === 'function' && check.nonEmptyString(obj.code) && isFunctionCode(obj.code);
}

export function unserializeFunction(serializedFunction: SerializedFunction) : Function | null {
  // Make sure we're dealing with a serialized function
  if (!isSerializedFunction(serializedFunction)) {
    return null;
  }

  // Return from cache is available
  if (cachedFunctions.has(serializedFunction.code)) {
    return cachedFunctions.get(serializedFunction.code)!;
  }

  // Add the dataProxy to the window object in case it wasn't added
  if (typeof window !== 'undefined') {
    (window as any).dataProxy = dataProxy;
  }

  // Attempt to parse function code
  try {
      const ast = acorn.parse(serializedFunction.code, {ecmaVersion: 2022, sourceType: "module"});
      const func = createFunctionFromValidAST(ast);
      
      if (!check.function(func)) {
        return null;
      }

      cachedFunctions.set(serializedFunction.code, func);
      return func;
  } catch (e) {
    return null; // Parsing failed, input is not valid JavaScript code
  }
}

export function parseFunctionString(inputString: string) {
  if (cachedFunctions.has(inputString)) {
    return cachedFunctions.get(inputString);
  }

  if (!isFunctionCode(inputString)) {
    return null;
  }

  // Attempt to parse function-looking strings
  try {

    let functionBody = inputString;
    // Replace variable names with a replacement value
    const variables = arrayUnique(extractVarNamesFromText(functionBody));
    variables.forEach((variable) => {
      const baseName = variable.split('.')[0].split('[')[0];
      functionBody = functionBody.replaceAll(baseName, `window.dataProxy['${baseName}']`);
    });

    // Add the dataProxy to the window object in case it wasn't added
    if (typeof window !== 'undefined') {
        (window as any).dataProxy = dataProxy;
    }

    const ast = acorn.parse(functionBody, {ecmaVersion: 2022, sourceType: "module"});
    const func = createFunctionFromValidAST(ast);
    
    if (!check.function(func)) {
      return null;
    }

    cachedFunctions.set(inputString, func);
    return func;
  } catch (e) {
    return null; // Parsing failed, input is not valid JavaScript code
  }
}

export function rewriteFunctionString(inputString: string) {
  if (!isFunctionCode(inputString)) {
    return null;
  }

  // Attempt to parse function-looking strings
  try {
    const ast = acorn.parse(inputString, {ecmaVersion: 2022, sourceType: "module"});
    return generateSourceCodeFromValidAST(ast);
  } catch (e) {
    return null; // Parsing failed
  }
}

export function parsePropValue(value: string, knownColors : { [colorName: string]: string } = {}) : any {
  console.log('Parsing prop value', {
    value,
  })
  
  if (!check.nonEmptyString(value)) {
    return null;
  }

  // Remove {} if any around the value, except if value is an object
  if (value.startsWith('{') && value.endsWith('}')) {
    const obj = bulletProofJSONParse(value);
    if (check.object(obj)) {
      return obj;
    }
    return parsePropValue(value.slice(1, -1), knownColors);
  }

  // Parse fractional values
  if (/^[0-9]+\.{0,1}[0-9]*\s*\/\s*[0-9]+\.{0,1}[0-9]*$/g.test(value.trim())) {
    const [a, b] = value.split('/').map(Number);
    if (check.number(a) && check.number(b) && b > 0) {
      return a / b;
    }
  }

  // Parse number values
  if (/^[0-9]+$/g.test(value.trim())) {
    return parseInt(value.trim());
  }
  if(/^[0-9]+\.[0-9]+$/g.test(value.trim())) {
    return parseFloat(value.trim());
  }

  // Parse URLs
  if (isUrlOrDataUrl(value)) {
    return value;
  }

  // Parse color values
  if (isColor(value)) {
    let out = parseColorToHex(value, knownColors);    
    return out;
  }

  // Parse functions 
  if (isFunctionCode(value)) {
    try {
      return serializedFunctionFromString(value);
    } catch(err) {
      console.error('Error parsing function '+value+ ' --> ', err);
      return null;
    }
  }

  // TODO: parse expressions like 1 + 2, etc

  // TODO: parse expressions including $variables

  // Else, attempt parsing the value as JSON
  const parsed = bulletProofJSONParse(value);
  return parsed === undefined ? value : parsed;
}

export function parseRadiusValue(value: any) : string {
  // Value is a number: return it as a string with 'px' appended
  if (check.number(value)) {
    return `${value}px`;
  }

  if (!check.nonEmptyString(value)) {
    return '0px';
  }

  // Value is pure numeric without any unit: add the 'px' unit
  if (/^[0-9]+\.{0,1}[0-9]*$/g.test(value)) {
    return `${value}px`;
  }

  // Value is a numeric value with a unit: return it as is
  if (isNumericValue(value)) {
    return value;
  }

  // Value is a Tailwind value: return the corresponding radius value
  const tailwindRadiusValues : { [key: string]: string } = {
    'none': '0px',
    'sm': '0.125rem',
    'base': '0.25rem',
    'md': '0.375rem',
    'lg': '0.5rem',
    'xl': '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    'full': '9999px',
  };
  
  if (check.nonEmptyString(tailwindRadiusValues[value])) {
    return tailwindRadiusValues[value];
  }

  // Value not recognized: return '0px'
  return '0px';
}

export function getNewProjectUrl() {
  return '/' + uniqid() + '/edit';
}