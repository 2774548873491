
  import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
  } from "@/components/shadcn/ui/dropdown-menu"
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { cn, nonEmptyTrimmed } from "@/lib/utils";
import check from "@/vendors/check";
import { parseInputOrCommandText, parseShortcutText } from "../../layouts-lang";
import { ComponentAliasConfig, childrenParsers, imports, isTextComponent, mergeIntoSingleTextComponent, mergeManyIntoSingleTextComponent, normalizeTextComponent, radixComponentDoc, removeOneOccurenceOfText, resolvesToTextComponent, setup } from "../_utils";
import { getIconComponent, isIconComponent } from "../../base/images";
import { multiSteptreeParsingPipeline, parseTextComponents, removeChildren, transformComponentsOfTypes } from "../../parsing-pipeline";
import { mergeClasses, mergeProps } from "../../../util";
import { ComponentParserConfig } from "@/lib/parser/parser-config";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
  
/*
  export function DropdownMenuDemo() {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">Open</Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>My Account</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem>
              <User className="mr-2 size-4" />
              <span>Profile</span>
              <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <CreditCard className="mr-2 size-4" />
              <span>Billing</span>
              <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Settings className="mr-2 size-4" />
              <span>Settings</span>
              <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Keyboard className="mr-2 size-4" />
              <span>Keyboard shortcuts</span>
              <DropdownMenuShortcut>⌘K</DropdownMenuShortcut>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem>
              <Users className="mr-2 size-4" />
              <span>Team</span>
            </DropdownMenuItem>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <UserPlus className="mr-2 size-4" />
                <span>Invite users</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <DropdownMenuItem>
                    <Mail className="mr-2 size-4" />
                    <span>Email</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <MessageSquare className="mr-2 size-4" />
                    <span>Message</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>
                    <PlusCircle className="mr-2 size-4" />
                    <span>More...</span>
                  </DropdownMenuItem>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
            <DropdownMenuItem>
              <Plus className="mr-2 size-4" />
              <span>New Team</span>
              <DropdownMenuShortcut>⌘+T</DropdownMenuShortcut>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem>
            <Github className="mr-2 size-4" />
            <span>GitHub</span>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <LifeBuoy className="mr-2 size-4" />
            <span>Support</span>
          </DropdownMenuItem>
          <DropdownMenuItem disabled>
            <Cloud className="mr-2 size-4" />
            <span>API</span>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem>
            <LogOut className="mr-2 size-4" />
            <span>Log out</span>
            <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  }
  */

  const TAG = 'dropdown-menu';
  const ALIASES : ComponentAliasConfig[] = [
    ['menu', DropdownMenu, 'DropdownMenu', radixComponentDoc('DropdownMenu.Root')],
    ['content', DropdownMenuContent, 'DropdownMenuContent', radixComponentDoc('DropdownMenu.Content')],
    ['item', DropdownMenuItem, 'DropdownMenuItem', radixComponentDoc('DropdownMenu.Item')],
    ['label', DropdownMenuLabel, 'DropdownMenuLabel', radixComponentDoc('DropdownMenu.Label')],
    ['separator', DropdownMenuSeparator, 'DropdownMenuSeparator', radixComponentDoc('DropdownMenu.Separator')],
    ['shortcut', DropdownMenuShortcut, 'DropdownMenuShortcut', radixComponentDoc('DropdownMenu.Shortcut')],
    ['sub', DropdownMenuSub, 'DropdownMenuSub', radixComponentDoc('DropdownMenu.Sub')],
    ['sub-content', DropdownMenuSubContent, 'DropdownMenuSubContent', radixComponentDoc('DropdownMenu.SubContent')],
    ['sub-trigger', DropdownMenuSubTrigger, 'DropdownMenuSubTrigger', radixComponentDoc('DropdownMenu.SubTrigger')],
    ['trigger', DropdownMenuTrigger, 'DropdownMenuTrigger', radixComponentDoc('DropdownMenu.Trigger')],
    ['portal', DropdownMenuPortal, 'DropdownMenuPortal', radixComponentDoc('DropdownMenu.Portal')],
    ['group', DropdownMenuGroup, 'DropdownMenuGroup', radixComponentDoc('DropdownMenu.Group')],
  ];

// //////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////////
function parseShortcut(c: ComponentTreeNode | string) : ComponentTreeNode | null {
  if (check.nonEmptyString(c)) {
    return parseShortcut({
      component: DropdownMenuShortcut,
      htmlComponent: 'DropdownMenuShortcut',
      classes: [],
      props: {},
      children: [],
      text: c,
    });
  }

  if (!check.nonEmptyObject(c)) {
    return null;
  }

  return {
    ...c,
    component: DropdownMenuShortcut,
    htmlComponent: 'DropdownMenuShortcut',
    text: null,
    children: [[
        c.text,
        ...(c.children || []),
      ].filter(isTextComponent)
        .map(child => normalizeTextComponent(child)?.text)
        .filter(check.nonEmptyString)
        .map(parseShortcutText)
        .join(' '),
    ]
  };
}

// //////////////////////////////////////////////////////////////////

function isShorcutComponent(c: ComponentTreeNode) {
  return check.nonEmptyObject(c) && c.htmlComponent === 'DropdownMenuShortcut';
}

function isIconOrShortcutComponent(c: ComponentTreeNode) {
  return isIconComponent(c) || isShorcutComponent(c);
}

function hasNotIconOrShortcutChildren(c: ComponentTreeNode | null | string) {
  if (!check.nonEmptyObject(c)) {
    return false;
  }
  return (c?.children?.filter(c => !isIconOrShortcutComponent(c)).length || 0) > 0;
}

function parseText(c: ComponentTreeNode, config: ComponentParserConfig) : ComponentTreeNode | null {
    if (check.nonEmptyString(c)) {
      return textToMenuItem(c, config);
    }
    if (!isTextComponent(c)) {
        return c;
    }

    // Extract whatever we can from the text itself
    let component = textToMenuItem(c.text || '', config);
    if (!component) {
        return c;
    }

    let mergedComp : ComponentTreeNode = {
      ...component,
      classes: mergeClasses(c.classes, component.classes),
      props: mergeProps(c.props, component.props),
    };

    if (!check.nonEmptyArray(c.children) || mergedComp.component !== DropdownMenuItem) {
        return mergedComp;
    }

    // If the component has icons or shortut items subitems, override them
    const icons = c.children.filter(isIconComponent);
    const shortcuts = c.children.filter(isShorcutComponent);
    const rest = c.children.filter((child: ComponentTreeNode) => !isIconOrShortcutComponent(child));

    let newChildren = component.children || [];
    if (check.nonEmptyArray(icons)) {
        newChildren = [icons[0], ...newChildren.filter(c => !isIconComponent(c))];
    }
    if (check.nonEmptyArray(shortcuts)) {
        newChildren = [...newChildren, ...shortcuts];
    }

    mergedComp = {
      ...mergedComp,
      children: newChildren,
    }

    // If there are other children than icon or shortcut, then the item is a submenu
    if (check.nonEmptyArray(rest)) {
        return wrapComponentInMenu(mergedComp, rest.map(c => parseText(c, config)), 1);
    }

    // Else return the merged component
    return mergedComp;
}

function textToMenuItem(text: string, config: ComponentParserConfig) : ComponentTreeNode | null {
  if (!check.nonEmptyString(text) || !nonEmptyTrimmed(text)) {
    return null;
  }

  // By default, component is a simple item
  let component = DropdownMenuItem;
  let htmlComponent = 'DropdownMenuItem';
  let props = {};

  const { type, label, shortcut  } = parseInputOrCommandText(text, {
    shortcut: true,
    separator: true,
    title: true,
  });

  // If the text is a separator, we return a separator component
  if (type === 'separator') {
    component = DropdownMenuSeparator as any;
    return {
      component,
      htmlComponent: 'DropdownMenuSeparator',
      classes: [],
      props,
      children: [],
    };
  }

  // If the text is a title, we return a label component
  if (type === 'title') {
      return {
          component: DropdownMenuLabel,
          htmlComponent: 'DropdownMenuLabel',
          classes: [],
          props: {},
          children: [label],
      };
  }

  // If the text includes a shortcut pattern, we add a shortcut component
  let compChildren : (string | ComponentTreeNode)[] = [label];
  if (shortcut) {
    compChildren.push({
      classes: [],
      component: DropdownMenuShortcut,
      htmlComponent: 'DropdownMenuShortcut',
      props: {},
      children: [shortcut],
    });
  }

  const item = {
    component,
    htmlComponent,
    classes: [],
    props,
    children: compChildren || [],
  };

  // If the item has no icon, create one with the item's label
  if (!componentHasIcon(item)) {
    const iconComponent = getIconComponent({
      props: {        
        id: 'icon::' + label,
        key: 'icon::' + label,
      },
      variant: 'lucide',
      classes: ['size-4'],
      children: [],
      text: label,
    }, config);
    item.children = [
      ...(iconComponent ? [iconComponent] : []),
      ...(item.children || []),
    ];
  }

  return item;
}

function wrapComponentInMenu(component: ComponentTreeNode, children: any[], level = 0) {
  if (level <= 0) {
    return {
      component: DropdownMenuContent,
      htmlComponent: 'DropdownMenuContent',
      classes: [],
      props: {},
      children,
    }
  } else {
    const { disabled, ...otherProps } = component.props || {};

    return {
      component: DropdownMenuSub,
      htmlComponent: 'DropdownMenuSub',
      classes: [],
      props: {},
      children: [
        { ...component, component: DropdownMenuSubTrigger, htmlComponent: 'DropdownMenuSubTrigger' },
        {
          /*
          component: DropdownMenuPortal,
          htmlComponent: 'DropdownMenuPortal',
          props: {},
          classes: [],
          children: [
            { */
              ...component,
              classes: cn( component.classes || []).split(' '),
              component: DropdownMenuSubContent,
              htmlComponent: 'DropdownMenuSubContent',
              children, 
              props: otherProps,
              variant: null,
              /*
            }
          ] */
        }
      ]
    }
  }
}


function shouldBeSubMenu(c: ComponentTreeNode | string | null) {
  if (!check.nonEmptyObject(c)) {
    return false;
  }

  if (c.component === DropdownMenuItem) {
    return c.children?.some(child => (
      child.component === DropdownMenuItem
      || (
        isTextComponent(child)
        && hasNotIconOrShortcutChildren((normalizeTextComponent(child))
      )
    )));
  }

  if (isTextComponent(c)) {
    return hasNotIconOrShortcutChildren(normalizeTextComponent(c));
  }

  return false;
}

// Make sure the menu item is shaped in a standard way: icon, text, shortcut
function cleanMenuItem(c: ComponentTreeNode | string | null, config: ComponentParserConfig) : ComponentTreeNode | string | null {
  if (!check.nonEmptyObject(c) || c.component !== DropdownMenuItem) {
    return c;
  }

  // If item should be a submenu, convert it
  if (shouldBeSubMenu(c)) {
    const inTrigger = (c: any) => {
      return isIconOrShortcutComponent(c) || (
        isTextComponent(c) && !check.nonEmptyArray(normalizeTextComponent(c)?.children)
      );
    }

    return wrapComponentInMenu(
        cleanMenuItem({ ...c, children: (c.children || []).filter(inTrigger) }, config) as ComponentTreeNode,
        (c.children || []).filter(c => !inTrigger(c)),
        1
    );
  }

  const shortcuts: ComponentTreeNode[] = [];
  const labels: ComponentTreeNode[] = [];
  const icons: ComponentTreeNode[] = [];

  const rest : ComponentTreeNode[] = [];

  // Extract children
  (c.children || []).forEach((child: ComponentTreeNode) => {

      if (check.nonEmptyString(child)) {
        labels.push(child);
      }

      // Isolate sub items
      if (check.nonEmptyObject(child)) {
        if (isTextComponent(child)) {
          const normalized = normalizeTextComponent(child);
          if (!normalized) {
            return;
          }

          if (!check.nonEmptyArray(normalized?.children)) {
            labels.push(normalized);
            return;
          }

          // Text components with items are converted into sub-items
          rest.push(
            wrapComponentInMenu(
              normalized,
              normalized.children.map(c => cleanMenuItem(parseText(c, config), config)),
              1
            )
          );
          return;
        }

        if (child.component === DropdownMenuItem) {
          rest.push(
            wrapComponentInMenu(
              child,
              (child.children || []).map(c => cleanMenuItem(parseText(c, config), config)),
              1
            )
          );
          return;
        }
        if (child.component === DropdownMenuSeparator) {
          // Ignore
          // rest.push(child);
          return;
        }
        if (child.component === DropdownMenuLabel) {
          // Ignore
          // labels.push(child);
          return;
        }
        if (child.component === DropdownMenuShortcut) {
          shortcuts.push(child);
          return;
        }
        if (isIconComponent(child)) {
          icons.push(child);
          return;
        }
        if (resolvesToTextComponent(child)) {
          const merged = mergeIntoSingleTextComponent(child);
          if (merged) {
              labels.push(merged);
          }
          return;
        }

        // DONT Ignore anything that doesn't match
        rest.push(child);
      }
  });

  return {
    component: DropdownMenuItem,
    htmlComponent: 'DropdownMenuItem',
    props: mergeProps({ className: 'gap-2'}, c.props),
    classes: mergeClasses(['gap-2'], c.classes),
    text: undefined,
    children: [
      icons[0] || null, 
      labels.length > 0 ? mergeManyIntoSingleTextComponent(...labels) : null,
      ...rest,
      ...shortcuts.map(parseShortcut),
    ].filter(c => !!c),
  };
}

const componentHasIcon = (c: ComponentTreeNode) => (c?.children || []).some(isIconComponent);

const DropdownMenuComponentParser : ComponentParser = {
  name: 'Dropdown Menu',
  description: 'Displays a menu to the user — such as a set of actions or functions — triggered by a button.',
  tags: [TAG],

  refImplementation: `
/button Open menu
	/dropdown-menu w-56
		# My account
		---
		Profile <Shift + Cmd + P>
			/icon Single user
		Billing <Cmd + B>
			/icon Credit card
		Settings <Cmd + S>
		Keyboard shortcuts <Cmd + K>
		---
		Team
			/icon Multiple users
		Invite users text-blue-800
			/icon Add a user
			Email
			Message 
				/icon Chat bubble
			---
			More...
				/icon Plus in circle
		New Team <Cmd + T>
			/icon Plus
		---
		GitHub
		Support 
			/icon life saving
		API @disabled
			/icon Cloud
		---
		Log out <Shift + Cmd + Q>
`.trim().replaceAll(/ {2}/g, '\t'),

  childrenParsers: childrenParsers(ALIASES, TAG),

  wrapParent: (parent: ComponentTreeNode, config: ComponentParserConfig) : ComponentTreeNode => {

    const pipeline0 = [
      // Transform text components into items or sub-menus
      parseTextComponents((textComponent, parents) => {
        // Below items, only parse text components to find separators, labels or shortcuts
        if (parents.some(p => p === DropdownMenuItem) && !check.nonEmptyArray(textComponent.children)) {
          const parsed = parseText(textComponent, config);
          if (!check.nonEmptyObject(parsed)) { 
            return parsed;
          }
          if (parsed.component !== DropdownMenuItem) {
            return parsed;
          }

          return (parsed.children || []).filter(child => !isIconComponent(child));
        }

        // Don't parse text components below a DropdownMenuLabel or DropdownMenuSubTrigger
        if (parents.some(p => p === DropdownMenuLabel || p === DropdownMenuSubTrigger)) {
          return textComponent;
        }

        // Else, parse the text!
        return parseText(textComponent, config);
      }),
    ];

    const pipeline1 = [
      
      // Parse items recursively
      transformComponentsOfTypes([DropdownMenuItem], (c: any) => {
        const out = cleanMenuItem(c, config);
        return out as any;
      }),

      // Remove children from types which shouldn't have children
      removeChildren([DropdownMenuSeparator]),

      // Add gap on DropdownMenuSubTrigger
      transformComponentsOfTypes(
        [DropdownMenuSubTrigger], 
        (c: ComponentTreeNode) => {
        return {
          ...c,
          props: mergeProps({ className: 'gap-2'}, c.props),
          classes: mergeClasses(['gap-2'], c.classes),
        };
      }),

      // Mark items as leafs
      // markTreeLeafs([DropdownMenuItem, DropdownMenuLabel, DropdownMenuShortcut, DropdownMenuSeparator, DropdownMenuSubTrigger, DropdownMenuTrigger]),
    ];

    const pipeline2 = [
        // If some children have icons, add inset to all other items by default
        transformComponentsOfTypes([], (c: ComponentTreeNode) => {
          if (!check.nonEmptyObject(c) || !check.nonEmptyArray(c.children)) {
            return c;
          }
          
          if (c.children.some(componentHasIcon)) {
              c.children = c.children.map(c => {
                  if (!componentHasIcon(c) && c.component !== DropdownMenuSeparator && c.component !== DropdownMenuLabel  && c.component !== DropdownMenuPortal && c.component !== DropdownMenuSubContent) {
                    // Treat sub menus differently
                    if (c.component === DropdownMenuSub) {
                      if (componentHasIcon(c.children[0])) {
                        return c;
                      }
                      return { ...c, children: (c.children || []).map((child: ComponentTreeNode) => {
                        if (child.component === DropdownMenuSubTrigger) {
                          // We add the inset prop only if it's not already set to false
                          return ({ ...child, props: { ...child.props, inset: child.props.inset === false ? false : true }});
                        }
                        return child;
                      })};
                    }

                    // We add the inset prop only if it's not already set to false
                    return { ...c, props: { ...c.props, inset: c.props.inset === false ? false : true } };
                  }
                  return c;
              });
          }
          return c;
        }, true),
    ];
    
    const contentChildren = (parent.children || []).filter(child => child.component === TAG).map((c => (
      multiSteptreeParsingPipeline([
        pipeline0,
        pipeline1,
        pipeline2,
      ]).run({
        component: 'Root',
        htmlComponent: 'Root',
        classes: [],
        props: {},
        children: [
        {
          ...c,
          component: DropdownMenuContent,
          htmlComponent: 'DropdownMenuContent',
      }]}, []).children?.[0])));

    return {
      _dontParse: true,
      component: DropdownMenu,
      htmlComponent: 'DropdownMenu',
      classes: [],
      props: {},
      children: [
        {
          _dontParse: true,
          component: DropdownMenuTrigger,
          htmlComponent: 'DropdownMenuTrigger',
          props: {
            asChild: true,
          },
          children: [
            {
              ...parent,
              children: (parent.children || []).filter(child => child.component !== TAG),
            }
          ]
        },
        ...contentChildren,
      ]
    }
  },

  imports: imports(['DropdownMenu', 'DropdownMenuContent', 'DropdownMenuGroup', 'DropdownMenuItem', 'DropdownMenuLabel', 'DropdownMenuPortal', 'DropdownMenuSeparator', 'DropdownMenuShortcut', 'DropdownMenuSub', 'DropdownMenuSubContent', 'DropdownMenuSubTrigger', 'DropdownMenuTrigger' ], TAG),
  setup: setup(TAG),
  variants: [],
  doc: {
    props: radixComponentDoc('DropdownMenu.Root').props,
  }
};

registerShadcnComponent({
        DropdownMenu,
        DropdownMenuContent,
        DropdownMenuGroup,
        DropdownMenuItem,
        DropdownMenuLabel,
        DropdownMenuPortal,
        DropdownMenuSeparator,
        DropdownMenuShortcut,
        DropdownMenuSub,
        DropdownMenuSubContent,
        DropdownMenuSubTrigger,
        DropdownMenuTrigger,
    },
    TAG,
);

export default DropdownMenuComponentParser;