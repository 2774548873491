import { BgImageCard } from './BgImageCard';

import { registerComponentVariant } from '../../../lib/components-registry';
import { CARD_BGIMAGE_VARIANT_DESCRIPTION, CARD_BGIMAGE_VARIANT_NAME, CARD_COMPONENT_DESCRIPTION, CARD_COMPONENT_NAME } from '../../../lib/constants';


function DefaultBgCard({ size, ...props }: any) {
    return <BgImageCard size={size} {...props} label={props.buttonLabel || null} />;
}


registerComponentVariant({
    name: CARD_COMPONENT_NAME,
    description: CARD_COMPONENT_DESCRIPTION,
    variantName: CARD_BGIMAGE_VARIANT_NAME,
    variantDescription: CARD_BGIMAGE_VARIANT_DESCRIPTION,
    subVariantName: 'Default',
    subVariantDescription: 'Background image card',
    reactComponent: DefaultBgCard,
    acceptsProps: ['image', 'title', 'description', 'buttonLabel'],
    acceptSizes: ['6xl', '5xl', '4xl', '3xl', '2xl', 'xl', 'lg', 'md', 'sm', 'xs'],
    acceptsAspectRatios: ['9/16', '3/4', 'square', '16/9', '4/3'],
    acceptsRoundness: ['none', 'full', '3xl', '2xl', 'xl', 'lg', 'md', 'sm'],
    acceptOrientations: ['north'],
    acceptsChildren: false,
});