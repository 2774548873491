import { ElementType, Fragment } from "react";
import { setup } from "./components/shadcn/_utils";
import check from "@/vendors/check";
import arrayUnique from "array-unique";
import { PageProvider } from "@/components/layouts-ui/PageProvider";
import { ParserConfigTarget } from "./parser-config";

type ImportsRegistryEntry = {
    componentName: string,
    reactComponent: ElementType,
    importPath: string,
    importAsDefault: boolean,
    importFromOtherName?: string,
    scope: '*' | ParserConfigTarget,
    setupCommands: string[],
}

type ImportLineEntry = {
    default?: string,
    named?: { import: string, as?: string }[],
    path: string,
};

const TOP_IMPORT_LINES = [
    "import React from 'react';",
];

// Registry made to store the map between component names and the way to import them into the file
const importRegistries: { [scope: string]: Map<string, ImportsRegistryEntry> } = {};
const importsRegistryForScope = (scope: string) => {
    if (!importRegistries[scope]) {
        importRegistries[scope] = new Map<string, ImportsRegistryEntry>();
    }

    return importRegistries[scope];
};

export function registerComponent(componentName: string, reactComponent: React.ElementType, importPath: string, config: Partial<ImportsRegistryEntry> = {}) {
    const cfg = config || {};
    const scope = cfg.scope || '*';
    const importsRegistry = importsRegistryForScope(scope);
    if (importsRegistry.has(componentName)) {
        throw new Error(`Component ${componentName} is already registered`);
    }
    importsRegistry.set(componentName, {
        componentName,
        reactComponent,
        importPath,
        importAsDefault: cfg?.importAsDefault || false,
        importFromOtherName: cfg?.importFromOtherName,
        scope,
        setupCommands: cfg?.setupCommands || [],
    });

    return importsRegistry.get(componentName);
}

export function registerShadcnComponent(components: { [name:string]: React.ElementType }, registryName: string) {
   Object.keys(components).forEach(componentName => {
        const reactComponent = components[componentName];
        registerComponent(componentName, reactComponent, `@/components/ui/${registryName}`, {
            setupCommands: setup(registryName),
        });
   });
}

export function sortSetupCommands(commands: string[]) {
    const newCommands : string[] = [];
    let rest = [...commands];

    // NPM installs first 
    const npmInstalls = rest.filter(c => c.startsWith('npm install'));
    rest = rest.filter(c => !c.startsWith('npm install'));
    newCommands.push(...npmInstalls.sort());

    // Shadcn init then
    const shadcnInits = rest.filter(c => c.includes('shadcn-ui') && c.trim().endsWith(' init'));
    rest = rest.filter(c => !c.includes('shadcn-ui') || !c.trim().endsWith(' init'));
    newCommands.push(...shadcnInits);

    // The rest
    newCommands.push(...rest);

    // Remove duplicates
    return arrayUnique(newCommands);
}

export function setupCommandsForComponents(components: string[], scope : '*' | ParserConfigTarget) : string[] {    
    if (!check.nonEmptyArray(components)) {
        return [];
    }

    let allScopesImports : string[] = [];
    if (scope !== '*') {
        allScopesImports = setupCommandsForComponents(components, '*');
    }

    const comps = arrayUnique(components).filter(check.nonEmptyString).sort();
    const importsRegistry = importsRegistryForScope(scope);
    const forCurrentScope = comps.map(c => (importsRegistry.get(c) || null)).filter(c => !!c) as ImportsRegistryEntry[];
    const setupCommands = forCurrentScope.map(c => {
        if (!check.nonEmptyArray(c.setupCommands)) {
            if (c.importPath.startsWith('@/components/ui/')) {
                return setup(c.componentName);
            } else {
                return `npm install --save ${c.importPath}`;
            }
        } else {
            return c.setupCommands;
        }
    }).flat();

    console.log('[DEBUG] Setup commands for ' + JSON.stringify(components)+ '\n--> '+JSON.stringify([...allScopesImports, ...setupCommands]));

    return sortSetupCommands([...allScopesImports, ...setupCommands]);
}

export function importsForComponents(components: string[], scope : '*' | ParserConfigTarget) : ImportsRegistryEntry[] {
    const imports : ImportsRegistryEntry[] = [];

    if (!check.nonEmptyArray(components)) {
        return imports;
    }

    let allScopesImports : ImportsRegistryEntry[] = [];
    if (scope !== '*') {
        allScopesImports = importsForComponents(components, '*');
    }

    const comps = arrayUnique(components).filter(check.nonEmptyString).sort();
    const importsRegistry = importsRegistryForScope(scope);
    const forCurrentScope = comps.map(c => (importsRegistry.get(c) || null)).filter(c => !!c) as ImportsRegistryEntry[];

    return [...allScopesImports, ...forCurrentScope];
}

export function importLinesForComponents(components: string[], scope : '*' | ParserConfigTarget) : string[] {
    const byPath : { [path: string]: {
        default?: string,
        named?: { import: string, as?: string }[],
    }} = {};

    const entries = importsForComponents(components, scope);
    if (!check.nonEmptyArray(entries)) {
        return [] as string[];
    }

    entries.forEach(e => {
        if (!byPath[e.importPath]) {
            byPath[e.importPath] = {};
        }

        const { componentName, importAsDefault, importFromOtherName } = e;
        if (importAsDefault) {
            byPath[e.importPath].default = componentName;
        } else {
            if (!byPath[e.importPath].named) {
                byPath[e.importPath].named = [];
            }

            if (byPath[e.importPath].named?.some(n => n.import === componentName)) {
                return;
            }

            byPath[e.importPath].named!.push({
                import: componentName,
                as: importFromOtherName || undefined,
            });
        }
    });

    const lines : string[] = [...TOP_IMPORT_LINES];
    Object.keys(byPath).sort().forEach(path => {
        const { default: def, named } = byPath[path];
        let line = importEntryToLine({ default: def, named: named || [], path });
        lines.push(line);
    });

    return sortImportLines(lines);
}

export function sortImportLines(lines: string[]) {
    return lines.sort((a, b) => {
        const aFrom = a.split('from')[1].trim().replace(/['";]/g, '').trim();
        const bFrom = b.split('from')[1].trim().replace(/['";]/g, '').trim();

        return aFrom.localeCompare(bFrom);
    });
}

function importEntryToLine(entry: ImportLineEntry) {
    return `import ${entry.default ? entry.default : ''}${entry.default && check.nonEmptyArray(entry.named) ? ', ' : ''}${check.nonEmptyArray(entry.named) ? `{ ${entry.named.sort((a,b) => a.import.localeCompare(b.import)).map(n => n.as ? `${n.import} as ${n.as}` : n.import).join(', ')} }` : ''} from '${entry.path}';`;
}

export function mergeImportLines(lines: string[]) {
    const entries : ImportLineEntry[] = lines.filter(l => check.nonEmptyString(l) && !TOP_IMPORT_LINES.includes(l)).map(l => {
        const parts = l.split('from');
        let importPart = parts[0].trim().substring('import'.length).trim();
        const from = parts[1].trim().replace(/['";]/g, '').trim();

        let defaultImport = undefined;
        let named : { import: string, as?: string }[] = [];
        if (!importPart.includes('{')) {
            defaultImport = importPart.trim();
        } else if (importPart.includes(', {')) {
            defaultImport = importPart.split(', {')[0].trim();
            importPart.split(', {')[1].trim();
        }

        if (importPart.includes('{')) {
            named = importPart.replace(/[{ }]/g, '').split(',').map(n => {
                const [importName, asName] = n.split(' as ');
                return { import: importName.trim(), as: asName ? asName.trim() : undefined };
            });
        }

        return { default: defaultImport, named, path: from };
    });

    const byPath : { [path: string]: ImportLineEntry } = {};
    entries.forEach(e => {
        if (!byPath[e.path]) {
            byPath[e.path] = e;
        } else {
            if (e.default && !byPath[e.path].default) {
                byPath[e.path].default = e.default;
            }

            if (e.named) {
                e.named.forEach(n => {
                    if (!byPath[e.path].named?.some(ne => ne.import === n.import)) {
                        byPath[e.path].named!.push(n);
                    }
                });
            }
        }
    });

    const mergedLines : string[] = [...TOP_IMPORT_LINES];
    Object.keys(byPath).sort().forEach(path => {
        let line = importEntryToLine(byPath[path])
        mergedLines.push(line);
    });

    return mergedLines;
}

// Register common components
registerComponent('PageProvider', PageProvider, '@layouts.dev/utils.nextjs', {
    setupCommands: [
        'npm install --save @layouts.dev/utils',
        'npm install --save @layouts.dev/utils.nextjs',
    ],
});
registerComponent('Fragment', Fragment, 'react');