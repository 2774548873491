import MonacoEditor, { Monaco, OnChange } from "@monaco-editor/react";
import { FORMAT, THEME } from './monaco-config'
import './editor.css';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import check from "@/vendors/check";
import { cn } from "@/lib/utils";
import { isBeginComponentTag, isBeginInternalComponentTag, isBeginPropsTag, isComponentTag, isComponentTagAndOnlyOneBeginComponentTag, isInternalComponentTag, isInternalComponentTagAndOnlyOneBeginInternalComponentTag, isPropsTag } from "@/lib/parser/components";
import reactTagNames from "react-tag-names";
import uniqid from "uniqid";
import { getComponentParserForLine, nIndents } from ".";
import { sleep } from "openai/core";

import * as monaco from 'monaco-editor';

function editorWillMount(editor: monaco.editor.IStandaloneCodeEditor, monaco: typeof import('monaco-editor')) {
    try {
        monaco.languages.register({ id: "autocode" });
        monaco.languages.setMonarchTokensProvider("autocode", FORMAT as monaco.languages.IMonarchLanguage);
        monaco.editor.defineTheme("draco-light", THEME as monaco.editor.IStandaloneThemeData);
        monaco.editor.setTheme("draco-light")
    } catch(err) {
        console.error(err);
    }
}

export function getRangeOfWordAtPosition(lineContent: string, positionColumn: number) {
    const left = Math.max(lineContent.substring(0, positionColumn - 1).lastIndexOf(' '), lineContent.substring(0, positionColumn - 1).lastIndexOf('\t'));
    const right_space = lineContent.indexOf(' ', positionColumn - 1)
    const right_tab = lineContent.indexOf('\t', positionColumn - 1)

    let right = -1;
    if (right_space !== -1 && right_tab !== -1) {
        right = Math.min(right_space, right_tab);
    } else if (right_space !== -1) {
        right = right_space;
    }   else if (right_tab !== -1) {
        right = right_tab;
    }

    const start = left === -1 ? 0 : left + 1;
    const end = right === -1 ? lineContent.length : right;

    return { start, end : end };
};

/*
export function getWordFromPosition(lineContent, positionColumn) {
    const left = lineContent.substring(0, positionColumn - 1).lastIndexOf(' ');
    const right = lineContent.indexOf(' ', positionColumn - 1);

    const start = left === -1 ? 0 : left + 1;
    const end = right === -1 ? lineContent.length : right;

    return lineContent.substring(start, end);
};
*/
// Upgraded to take into accoutn tabs
export function getWordFromPosition(lineContent: string, positionColumn: number) {
    // Use a regex to find all occurrences of spaces or tabs
    const matches = [...lineContent.matchAll(/[ \t]/g)];

    // Find the closest space or tab to the left of positionColumn
    let left = matches.reduce((acc, curr) => curr.index !== undefined && curr.index < positionColumn - 1 ? curr.index : acc, -1);

    // Find the closest space or tab to the right of positionColumn, starting search from positionColumn - 1
    let right = lineContent.length;
    for (let match of matches) {
        if (match.index !== undefined && match.index >= positionColumn - 1) {
            right = match.index;
            break;
        }
    }

    // Adjust start and end positions
    const start = left === -1 ? 0 : left + 1;
    const end = right === lineContent.length ? lineContent.length : right;

    // Return the substring representing the word at the specified position
    return lineContent.substring(start, end);
}

function getCurrentCaretPosition(editor: monaco.editor.IStandaloneCodeEditor, monaco: typeof import('monaco-editor'), caretPosition: monaco.IPosition) {
    let caretX = null;
    let caretY = null;
    let lineHeight = null;

    try {
        const position = caretPosition;
        const editorDomNode = editor.getDomNode();
        lineHeight = editor.getOption(monaco.editor.EditorOption.lineHeight);
        if (editorDomNode) {
            const contentWidgetPosition = editor.getScrolledVisiblePosition(position);
            if (contentWidgetPosition) {
              const editorRect = editorDomNode.getBoundingClientRect();
        
              caretX = editorRect.left + contentWidgetPosition.left;
              caretY = editorRect.top + contentWidgetPosition.top;
            }
        }
    } catch(err) {
        console.error('[Monaco] Could not get caret position: ', err);
    }
    

    return { caretX, caretY, lineHeight };
}

export interface KeyBinding {
    mode?: keyof typeof monaco.KeyMod;
    code: keyof typeof monaco.KeyCode;
    listener: Function,
    condition: string | undefined,
}

function registerKeyboardListener(keys: KeyBinding[], editor: monaco.editor.IStandaloneCodeEditor, monaco: typeof import('monaco-editor'), parentComponentsRef: React.RefObject<string[]>) {
    if (!check.nonEmptyArray(keys)) {
        return;
    }

    keys.forEach((key) => {
        const { mode: keyMode, code: keyCode, listener, condition } = key;
        let keyVal: any = null;
    
        if (keyMode && keyCode) {
            const mKMode = monaco.KeyMod[keyMode]
            const mKCode = monaco.KeyCode[keyCode]
            if (typeof mKMode === 'number' && typeof mKCode === 'number') {
                keyVal = mKMode | mKCode;
            }
        } else if (keyMode) {
            keyVal = monaco.KeyMod[keyMode];
        } else if (keyCode) {
            keyVal = monaco.KeyCode[keyCode];
        }

        if (!keyVal) {
            return;
        }
        editor.addCommand(keyVal, () => listener(editor, monaco, parentComponentsRef), condition);
    });
}

function registerContentListeners(listeners: Function[], editor: monaco.editor.IStandaloneCodeEditor, monaco: typeof import('monaco-editor'), parentComponentsRef: React.RefObject<string[]>) {
    if (!check.nonEmptyArray(listeners)) {
        return;
    }

    listeners.forEach((listener) => {
        editor.onDidChangeModelContent((e) => {
            const model = editor.getModel();
            if (!model) {
                return;
            }

            const content = model.getValue();
            listener(editor, monaco, content, parentComponentsRef.current);
        });
    });
}

function registerCaretListener(editor: monaco.editor.IStandaloneCodeEditor, monaco: typeof import('monaco-editor'), callback: (info: any) => void, contextKeys: { [key: string]: monaco.editor.IContextKey<boolean> }, parentComponentsRef: React.RefObject<string[]>) {
    try {
        let wordUnderCaret: string = '';
        let caretLastPosition: monaco.IPosition | null = null;
        let lastCaretEventData: any = {};

        const updateCaretInfo = (model: monaco.editor.ITextModel, caretPos: monaco.IPosition, onlyIfWordChanged = false) => {
            if (!model || !caretPos) {
                return;
            }

            try {
                const position = caretPos;
                const lineContent = model.getLineContent(position.lineNumber);
                const word = getWordFromPosition(lineContent, position.column);

                // Avoid race conditions if word under caret did not change
                if (onlyIfWordChanged && word === wordUnderCaret) {
                    return;
                }

                const { start, end } = getRangeOfWordAtPosition(lineContent, position.column);
    
                // Get the current position
                const caretPosition = getCurrentCaretPosition(editor, monaco, position);
    
                const eventData = {
                    line: position.lineNumber,
                    column: position.column,
                    lineContent,
                    word: word,
                    startColumn: start,
                    endColumn: end,
                    ...caretPosition,
                    monaco, 
                    editor,
                    model,
                };
                lastCaretEventData = {...lastCaretEventData, ...eventData};
                
                if (true /* word !== wordUnderCaret */ ) {
                    callback(lastCaretEventData);
                    wordUnderCaret = word;
                }

                // Update context keys
                // TODO: Modularize this in some way

                const trimmed_line = lineContent.trimStart();
                const { start: trimmed_start, end: trimmed_end } = getRangeOfWordAtPosition(trimmed_line, position.column - (lineContent.length - trimmed_line.length));
                const notFirstWord: boolean = word && trimmed_start !== 0 ? true : false;
                
                let caretOverComponent: boolean = word && !notFirstWord && /^\/[a-zA-Z_0-9.-]*$/g.test(word) && isBeginComponentTag(word) && (position.column > (start + 1)) ? true : false;
                let caretOverInternalComponent: boolean = word && !notFirstWord && /^\/[a-zA-Z_0-9.-]*$/g.test(word) && isBeginInternalComponentTag(word, parentComponentsRef.current || []) && (position.column > (start + 1)) ? true : false;
                const caretOverProps: boolean = word &&  /^@[a-zA-Z_0-9.-]*$/g.test(word) && isBeginPropsTag(word, parentComponentsRef.current || [], lineContent) && (position.column > (start + 1) && ((!isPropsTag(word, parentComponentsRef.current || [], lineContent))) /* || position.column < end + 1 */) ? true : false;
                const wordOverVariant: boolean = word && /^%[a-zA-Z_0-9.-]*$/g.test(word) && (position.column > (start + 1) && (!isComponentTagAndOnlyOneBeginComponentTag(word, parentComponentsRef.current || []) || position.column < end)) ? true: false;

                if (isComponentTagAndOnlyOneBeginComponentTag(word, parentComponentsRef.current || []) || isInternalComponentTagAndOnlyOneBeginInternalComponentTag(word, parentComponentsRef.current || [])) {
                    caretOverComponent = false;
                    caretOverInternalComponent = false;
                }

                contextKeys.caretOverComponent?.set(caretOverComponent);
                contextKeys.caretOverInternalComponent?.set(caretOverInternalComponent);
                contextKeys.caretOverProps?.set(caretOverProps);
                contextKeys.wordOverVariant?.set(wordOverVariant);

                // console.log('Caret over component: ', caretOverComponent);
                // console.log('Caret over variant: ', wordOverVariant);
            } catch(err) {
                // Do nothing
                console.error(err)
            }   
        }

        // Define the event listener
        const cursorChangeListener = (e : monaco.editor.ICursorPositionChangedEvent) => {
            const position = e.position;
            caretLastPosition = position; // To be used by the onScroll event listener

            const model: monaco.editor.ITextModel | null = editor.getModel();
            if (model) {
                updateCaretInfo(model, position);
            }
        };

        // Listen on scroll to update caret {x,y} position
        const scrollChangeListener = () => {
            if (!caretLastPosition) {
                return;
            }

            let newData = getCurrentCaretPosition(editor, monaco, caretLastPosition);
            lastCaretEventData = {...lastCaretEventData, ...newData};
            // console.log('Caret update beacuse scroll change: ', lastCaretEventData);
            callback(lastCaretEventData);
        };

        // Listen on text update
        // Note: necessary to update word when updating tw variants or other text without moving the caret
        //       can cause race conditions as we're using the caret last position
        const textUpdateListener = () => {
            const model = editor.getModel();
            const newCaretPos = editor.getPosition();
            if (model && newCaretPos) {
                updateCaretInfo(model, newCaretPos, true);
            }
        };
    
        // Add event listener
        editor.onDidChangeCursorPosition(cursorChangeListener);
        editor.onDidScrollChange(scrollChangeListener);
        editor.onDidChangeModelContent(textUpdateListener);
    } catch(err) {
        console.error('[registerCaretListener] Error while mounting: ', err);
    }  
}

function itemTypeToExtension(type: string) {
    switch(type) {
        case 'image/png':
            return 'png';
        case 'image/jpeg':
            return 'jpeg';
        case 'image/jpg':
            return 'jpg';
        case 'image/svg+xml':
            return 'svg';
        case 'image/gif':
            return 'gif';
        case 'image/webp':
            return 'webp';
        case 'image/bmp':
            return 'bmp';
        case 'image/tiff':
            return 'tiff';
        default:
            if (type.split('/').length === 2) {
                return type.split('/')[1];
            }
            return 'png';
    }
}

function insertTextIntoMonaco(monaco: typeof import('monaco-editor'), editor: monaco.editor.IStandaloneCodeEditor, text: string) {
    let toInsert = text;
    const selection = editor.getSelection();
    const model = editor.getModel();
    if (!model || !selection) {
        return;
    }

    // Check if the current line starts with /image, /logo, /illustration, /bg-image or /icon
    // If it does not, prefix text with '/image ' 
    const lineContent: string = model.getLineContent(selection.startLineNumber);
    const lineStart = lineContent.trim().toLowerCase().split(' ')[0];
    const prefix = ['/image', '/logo', '/illustration', '/bg-image', '/icon'];
    if (!prefix.includes(lineStart)) {
        toInsert = '/image ' + text.trim();
    }

    console.log('Inserting text: \n', toInsert);

    const range = new monaco.Range(selection.startLineNumber, selection.startColumn, selection.endLineNumber, selection.endColumn);
    editor.executeEdits("paste-handler", [{ range, text: toInsert, forceMoveMarkers: true }]);
}

async function uploadItemToServer(item: any, monaco: typeof import('monaco-editor'), editor: monaco.editor.IStandaloneCodeEditor) {
    // TODO
    console.warn('Inimplemented: Upload image', item);
}

function onPaste(event: ClipboardEvent, editor: monaco.editor.IStandaloneCodeEditor, monaco: typeof import('monaco-editor')) {
    console.log('Paste event detected:', event.clipboardData);

    let shouldPropagate = true;
    const items = (event.clipboardData || (event as any).originalEvent.clipboardData).items;
    for (const item of items) {
        if (item.type.startsWith('image')) {
            const text = './assets/images/image_' + uniqid() + '.' + itemTypeToExtension(item.type);
            insertTextIntoMonaco(monaco, editor, text);
            uploadItemToServer(item.getAsFile(), monaco, editor);

            shouldPropagate = false;
        } else if (item.type === 'text/plain') {
            if (!event.clipboardData) {
                return;
            }
            const str = event.clipboardData.getData('text/plain');
            if (!check.nonEmptyString(str)) {
                return;
            }
            if (str.toLowerCase().trim().startsWith('<svg') && str.toLowerCase().trim().endsWith('</svg>')) {
                const text = './assets/images/image_' + uniqid() + '.svg';
                insertTextIntoMonaco(monaco, editor, text);
                uploadItemToServer(str, monaco, editor);
                shouldPropagate = false;
            }
        }
    }

    // On all other cases, propagate the event
    if (!shouldPropagate) {
        event.preventDefault();
        event.stopPropagation();
    }
}

const showChevronsOnEmptyValidComponents = (editor: monaco.editor.IStandaloneCodeEditor, monaco: typeof import('monaco-editor'), setDecorations: Function, setDecoText: Function, isLoadingDecorationRef: React.MutableRefObject<boolean>, parentComponentsRef: React.MutableRefObject<string[]>) => {
    const model = editor.getModel();
    if (!model) {
        return;
    }

    const addDecorations = (lineNumber: number) => {
        const newDecorations = [];
        const lineContent = model.getLineContent(lineNumber);

        const component = getComponentParserForLine(lineContent, parentComponentsRef.current || []);

        if (component !== undefined) {
            setDecoText("Type \`>>>\` to get an example")

            const range = new monaco.Range(
                lineNumber,
                lineContent.length + 1,
                lineNumber,
                lineContent.length + 1
            );

            const newDeco = {
            range: range,
            options: {
                afterContentClassName: "myInlineDecoration"
            }
            }
            newDecorations.push(newDeco);
        }

        setDecorations(newDecorations);
    };

    const handleCursorChange = async (e: monaco.editor.ICursorPositionChangedEvent | monaco.editor.IModelContentChangedEvent) => {
        await sleep(10);
        const position = editor.getPosition();
        if (position) {
            const lineNumber = position.lineNumber;
            const column = position.column;
            const lineContent = model.getLineContent(lineNumber);

            if (column === lineContent.length + 1) {
                addDecorations(lineNumber);
            } else {
                setDecorations([]);
            }
        }
    };

    editor.onDidChangeCursorPosition(handleCursorChange);
    editor.onDidChangeModelContent(handleCursorChange);

    const initialPosition: monaco.IPosition | null = editor.getPosition();
    if (initialPosition && initialPosition.column === model.getLineContent(initialPosition.lineNumber).length + 1) {
      addDecorations(initialPosition.lineNumber);
    }
  };

const getParentComponentsForProps = (editor: monaco.editor.IStandaloneCodeEditor, setParentComponents: Function, parentComponentsRef: React.MutableRefObject<string[]>) => {

    const getParentComponents = (model: monaco.editor.ITextModel, position: monaco.IPosition) => {
        const lineNumber = position.lineNumber;
        const columnNumber = position.column;
        const lines = model.getLinesContent().slice(0, lineNumber);
        const parents = [];
        let currentIndentLevel = -1;

/*         const currentLine = lines[lineNumber - 1];
        if (currentLine) {
          const currentLineBeforeCursor = currentLine.slice(0, columnNumber - 1).trim();
          const currentComponent = currentLineBeforeCursor.split(' ')[0];
          if (currentComponent.startsWith('/') && currentComponent.length > 1 && currentComponent !== currentLineBeforeCursor) {
            parents.unshift(currentComponent);
          }
        } */

        for (let i = lineNumber - 1; i >= 0; i--) {
            const line = lines[i];
            if (line.trim() === '') continue;
            const indentLevel = nIndents(line);
            if (currentIndentLevel === -1) {
                currentIndentLevel = indentLevel;
            }

            if (indentLevel < currentIndentLevel) {
                const component = line.trim().split(' ')[0];
                if (component.startsWith('/')) {
                    parents.unshift(component);
                    currentIndentLevel = indentLevel;
                    }
            }
        }

        return parents;
    };

    const handleCursorChange = (e: monaco.editor.ICursorPositionChangedEvent) => {
        const position = e.position;
        const model = editor.getModel();
        if (!model || !position) {
            return;
        }
        const parents = getParentComponents(model, position);
        parentComponentsRef.current = parents;
        setParentComponents(parents);
    };

    editor.onDidChangeCursorPosition(handleCursorChange);
}

export default function InnerEditor({
    value, 
    onChange, 
    readOnly = false, 
    onCaretUpdate = () => {}, 
    dropdownElement = null,
    keyboardListeners = [],
    contentListeners = [],
    setEditorInstance = () => {},
    parentComponents = [],
    setParentComponents= () => {},
} : { value: string,
    onChange: OnChange,
    readOnly?: boolean, 
    onCaretUpdate?: (info: any) => void, 
    dropdownElement?: JSX.Element | null, 
    keyboardListeners?: KeyBinding[], 
    contentListeners?: Function[], 
    setEditorInstance?: Function, 
    parentComponents?: string[], 
    setParentComponents?: Function }) {

    const currentInstance: React.MutableRefObject<{ editor: monaco.editor.IStandaloneCodeEditor, monaco: typeof import('monaco-editor') } | null> = useRef(null);
    const [contextKeys, setContextKeys] = useState({});
    const [caretInfo, setLocalCaretInfo] = useState<{caretX: number, caretY: number, lineHeight: number, word: string}>({caretX: 0, caretY: 0, lineHeight: 0, word: ""});
    const { caretX, caretY, lineHeight } = caretInfo;
    const showDropdown = check.greaterOrEqual(caretX, 0) && check.greaterOrEqual(caretY, 0) && !!dropdownElement;
    const setCaretInfo = useCallback((info : any) => {
        setLocalCaretInfo(info);
        if (check.function(onCaretUpdate)) {
            onCaretUpdate(info);
        } 
    }, [onCaretUpdate]);

    const decoRef: React.MutableRefObject<undefined | string[]> = useRef([]);
    const [decoText, setDecoText] = useState("");
    const parentComponentsRef = useRef([]);
    const isLoadingDecorationRef = useRef(false);

    const setDecorations = useCallback((next: monaco.editor.IModelDeltaDecoration[]) => {
      const prev = decoRef.current || [];
      decoRef.current = currentInstance.current?.editor.deltaDecorations(prev, next);
    }, [decoRef])

    // Add effect to handle paste events 
    useEffect(() => {
        if (!currentInstance?.current?.editor || !currentInstance?.current?.monaco) {
            return;
        }

        const editor = currentInstance.current.editor;
        const monaco = currentInstance.current.monaco;
        const listener = (e: ClipboardEvent) => onPaste(e, editor, monaco);

        console.log('Setting up paste event listener');
        document.getElementById('editor-root')?.addEventListener('paste', listener, true);

        return () => {
            document.getElementById('editor-root')?.removeEventListener('paste', listener, true);
        }
    }, [currentInstance?.current?.editor, currentInstance?.current?.monaco]);

    return (
        <>
            <MonacoEditor
                key='monaco-editor'
                // height={`100%`}
                language="autocode"
                value={value}
                onChange={onChange}
                theme="draco-light"
                loading={null}
                options={{
                    readOnly: readOnly,
                    fontFamily: '"Roboto Mono", Monaco, "Courier New", monospace',
                    fontSize: 12,
                    minimap: {
                        enabled: false
                    },
                    wordWrap: 'on',
                    quickSuggestions: false, // Preventing autocomplete display
                    tabSize: 4,
                    insertSpaces: false,
                }}
                onMount={(editor, monaco) => {
                    // TODO: Modularize this
                    interface ContextKeys {
                        [key: string]: monaco.editor.IContextKey<boolean>;
                    }

                    const contextKeys = [
                        'caretOverComponent',
                        'caretOverInternalComponent',
                        'caretOverProps',
                        'contextKeys',
                        'caretOverVariant',
                    ].reduce((acc, key) => {
                        acc[key] = editor.createContextKey(key, false);
                        return acc;
                    }, {} as ContextKeys);
                    showChevronsOnEmptyValidComponents(editor, monaco, setDecorations, setDecoText, isLoadingDecorationRef, parentComponentsRef)
                    getParentComponentsForProps(editor, setParentComponents, parentComponentsRef)

                    setContextKeys(contextKeys);
                    editorWillMount(editor, monaco);
                    registerCaretListener(editor, monaco, setCaretInfo, contextKeys, parentComponentsRef);
                    registerKeyboardListener(keyboardListeners, editor, monaco, parentComponentsRef);
                    registerContentListeners(contentListeners, editor, monaco, parentComponentsRef);

                    // Add paste listener
                    // editor.onDidPaste((e) => onPaste(e, editor, monaco));

                    // Add editor and monaco to the ref for external access
                    currentInstance.current = { editor, monaco };

                    // Setting the editor instance
                    setEditorInstance({ editor, monaco });
                }}
            />
            <style>
                {`
                .myInlineDecoration::after {
                    content: "${decoText}";
                    color: gray;
                    opacity: 0.5;
                    font-weight: bold;
                    padding-left: 8px;
                }
                `}
            </style>
            <CaretDropdown caretInfo={caretInfo} lineHeight={lineHeight} showDropdown={showDropdown} dropdownElement={dropdownElement} />
        </>
    )
};

interface CaretDropdownProps {
    caretInfo: { caretX: number, caretY: number, word: string },
    lineHeight: number,
    showDropdown: boolean,
    dropdownElement: JSX.Element | null,
}

function CaretDropdown({caretInfo, lineHeight, showDropdown, dropdownElement} : CaretDropdownProps) {
    const editorRoot = document.getElementById('editor-root');

    // Check whether the dropdown will display outside the viewport (editor-root container)
    // If it will, adjust the horizontal position of the dropdown
    useLayoutEffect(() => {
        const dropdown = document.getElementById('caret-dropdown');
        if (!dropdown || !caretInfo || !editorRoot) {
            return;
        }

        const editorRect = editorRoot.getBoundingClientRect();

        const dropdownWidth = Math.min(editorRect.width > 350 + 32 ? 350 : editorRect.width - 32);
        const dropdownHeight = Math.min(editorRect.height > 420 + 64 ? 420 : editorRect.height - 64);

        
        if (caretInfo.caretX + dropdownWidth + 32 > editorRect.right) {
            dropdown.style.left = editorRect.right - dropdownWidth - 32 + 'px';
        }
        if (caretInfo.caretY + dropdownHeight + 16 > editorRect.bottom) {
            dropdown.style.top = editorRect.bottom - dropdownHeight - 64 + 'px';
        }

    }, [caretInfo]);

    if (!editorRoot) {
        return null;
    }
    const { top: t, left: l } = editorRoot.getBoundingClientRect();
    
    return (
        <div 
            id="caret-dropdown" 
            key={"caret-dropdown/"+showDropdown+ "/" + caretInfo?.word /* Triggers a new animation when the word under the caret changes by resetting the component altogether */}
            className={cn(
                `duration-100 ease-in-out`,
                `absolute`,
                'z-50'
            )} 
            style={showDropdown ?{
                top: caretInfo.caretY - t + lineHeight + 5, 
                left: caretInfo.caretX - l,
            } : {}}
        >
            <AnimatedDropdown key={'animate-dropdown/'+showDropdown} visible={showDropdown}>
                {dropdownElement}
            </AnimatedDropdown>
        </div>
    );
}


interface AnimatedDropdownProps {
    visible: boolean,
    children: JSX.Element | null,
}

function AnimatedDropdown({visible, children} : AnimatedDropdownProps) {
    const [show, setShow] = useState(false);

    // Use effect to change show-variable post-render
    useEffect(() => {
        setShow(!!visible && !!children);
    }, [visible, children]);

    return (
        <div key="animated-dropdown" 
            className={cn(
                `duration-100 ease-in-out origin-top-left`,
                show ? 'opacity-100' : 'opacity-0',
                show ? 'scale-100' : 'scale-50',
            )}
        >
            {children}
        </div>
    );
}