import React, { ComponentType, useMemo } from 'react';
import uniqid from 'uniqid';
import check from '@/vendors/check';
import { CascadingDataProvider, DataListChangeEvent, useAttachedListChangeListener, useCascadingDataVar } from './data-engine';
import { ArrayValueType, normalizeValue } from '@/lib/type-casts';

/*
type DataContextType = {
    dataVarsMap: { [key: string]: any },
    setDataVarsMap: (dataVarsMap: { [key: string]: any }) => void
};

const DataContext = React.createContext<DataContextType>({
    dataVarsMap: {}, 
    setDataVarsMap: () => {}
});
*/

/*
const DataProvider = ({ data, children } : { data: { [key: string]: any }, children: any }) => {
    const { dataVarsMap: parentDataVarsMap } = React.useContext(DataContext);
    const [ dataVarsMap, setDataVarsMap ] = React.useState<{ [key: string]: any }>({});

    const initialData : { [key:string]: any } = {};
    if (check.nonEmptyObject(data)) {
        Object.keys(data).forEach((key) => {
            initialData[key] = new DataVar(key, data[key]);
        });
    }

    return (
        <DataContext.Provider value={{ dataVarsMap: { 
            ...(parentDataVarsMap || {}), 
            ...(initialData || {}), 
            ...(dataVarsMap || {}) 
        }, setDataVarsMap }}>
            { children }
        </DataContext.Provider>
    );
}
*/

// Using new API
const DataProvider = CascadingDataProvider;
const useDataVar = useCascadingDataVar;

/*
const updatePaginationIfNecessary = (prevValue: any, nextValue: any) => {
    try {
        let prevPage = prevValue?.$?.pagination?.currentPage || 0;
        let nextPage = nextValue?.$?.pagination?.currentPage || 0;
    
        if (prevPage !== nextPage) {

            // console.log('Updating page to '+nextPage + '...')
            if (check.function(nextValue?.$?.pagination?.setPage)) {
                nextValue?.$?.pagination?.setPage(nextPage);
                // console.log('Updating page to '+nextPage + '...OK');
            } else {
                // console.log('Updating page to '+nextPage + '...NOK');
            }
        }
    } catch(err) {
        console.error('Pagination update error:', err);
    }
}

class DataVar extends EventEmitter {
    name: string;
    value: any;

    constructor(name: string, initialValue?: any) {
        super();
        this.name = name;
        this.value = initialValue;
    }

    set(value: any) {
        updatePaginationIfNecessary(this.value, value);
        this.value = value;
        this.emit('change', this.value);
    }

    updatePath(path: string, value: any) {
        const prev = this.value;
        this.value = setProperty(this.value || {}, path, value);
        this.emit('change', this.value);

        updatePaginationIfNecessary(prev, this.value);
    }

    get(path: string | null = null): any {
        if (!path) {
            return this.value;
        }
        if (!check.nonEmptyObject(this.value)) {
            return undefined;
        }
        return getProperty(this.value, path);
    }
}

function shallowCopy(value: any) {
    if (check.array(value)) {
        return [...value];
    }
    if (check.object(value)) {
        return { ...value };
    }
    return value;
}
*/
/*
function useDataVar(fullName: string, initialValue?: any, debugInstanceName?: string) {
    const name = check.nonEmptyString(fullName) ? fullName.trim().split('.')[0].split('[')[0].trim() : '';
    let path : string | null = null;
    if (check.nonEmptyString(fullName) && fullName.trim() !== name) {
        path = fullName.trim().substring(name.length).trim();
        if (path.startsWith('.')) {
            path = path.substring(1).trim();
        }
    }

    const { dataVarsMap, setDataVarsMap } = React.useContext(DataContext);
    const dataRef = check.nonEmptyString(name) ? dataVarsMap[name] : null;
    const [ value, setValueRaw ] = React.useState(dataRef ? dataRef.get(path) : initialValue);

    const setValue = React.useCallback((value: any) => {
        setValueRaw(shallowCopy(value));
    }, []);

    const set = React.useCallback((value: any) => {
        if (dataRef) {
            if (path) {
                dataRef.updatePath(path, value);
            } else {
                dataRef.set(value);
            }
            setValue(dataRef.get(path));
        }
    }, [dataRef, path, setValue]);

    React.useEffect(() => {
        if (check.nonEmptyString(name) && !dataVarsMap[name]) {
            const fullInitialValue = path ? setProperty({}, path, initialValue) : initialValue;
            const newDataVar = new DataVar(name, fullInitialValue);
            setDataVarsMap({ ...dataVarsMap, [name]: newDataVar });
            setValue(newDataVar.get(path));
        }
    }, [dataVarsMap, initialValue, name, path, setDataVarsMap, setValue]);

    React.useEffect(() => {
        if (!check.nonEmptyString(name) || !dataRef) {
            return;
        }

        const listener = (value: any) => {
            console.log('Data ref change received ('+(debugInstanceName || 'unnamed')+'):', value)
            if (path) {
                setValue(getProperty(value, path));
            } else {
                setValue(value);
            }
        }
        dataRef.on('change', listener);
        return () => {
            dataRef.off('change', listener);
        }
    }, [dataRef, name, path, setValue]);

    // Update initial value on change when no variable is bound
    React.useEffect(() => {
        if (!check.nonEmptyString(name) && JSON.stringify(initialValue) !==  JSON.stringify(value)) {
            setValue(initialValue);
        }
    }, [initialValue, name, setValue, value]);
    

    console.log('Running useDataVar('+(debugInstanceName || 'unnamed')+') for '+name+' with value:', value, 'and path:', path)

    return [value, set];
}
*/

export function VariableValue({ name }: { name: string }) {
    const [ value ] = useDataVar(name);
    return check.string(value) ? value : JSON.stringify(value);
}

export function DataBoundInput(
    as: ComponentType, 
    propName : string | ((v:any) => any) = 'value', 
    onChangePropName = 'onChange', 
    onChangeValueParser = (e: any) => e?.target?.value,
    valueToPropValue = (v: any) => v,
) {
    return function DataBoundInputInstance({ bind, onChange: onChangeProp, ...props }: { bind: string, onChange: any, onChangeProp?: string }) {
        const [value, setValue] = useDataVar(bind);
        const onChange = React.useCallback((e: any) => {
            const newValue = onChangeValueParser(e);

            if (check.function(onChangeProp)) {
                onChangeProp(newValue);
            }
            setValue(newValue);
        }, [onChangeProp, setValue]);

        let valueProps : {[key: string]: string} = {};
        if (check.nonEmptyString(propName)) {
            valueProps[propName] = valueToPropValue(value);
        } else if (check.function(propName)) {
            valueProps = propName(value);
        }
        const outputProps : { [key:string]: any } = { [onChangePropName]: onChange, ...props, ...valueProps };

        try {
            return React.createElement(as, outputProps);
        } catch(err) {
            console.error('DataBoundInput error:', err);
            return null;
        }
        
    }
}

export function DataBoundDisplayComponent(as: ComponentType, propName : string | ((val: any) => {[propName:string]:any}) = 'value') {
    return function DataBoundComponentInstance({ bind, ...props } : { bind: string, [key:string]: any }) {
        const [value] = useDataVar(bind);

        let overrideProps : { [key:string]: any } = {};
        if (check.nonEmptyString(bind)) {
            if (check.function(propName)) {
                overrideProps = propName(value);
            } else {
                overrideProps[propName] = value;
            }
        }

        try {
            return React.createElement(as, { ...props, ...overrideProps });
        } catch(err) {
            console.error('DataBoundDisplayComponent error:', err);
            return null;
        }
        
    }
};

// Component to bind a data value to a data variable
// Useful from within a source component
export function DataSourceBinding({ bind, value, onChange } : { bind: string, value: any, onChange: (value: DataListChangeEvent) => void }) {
    const [, setValue] = useDataVar(bind);
    useAttachedListChangeListener(bind, onChange);
    React.useEffect(() => {
        setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(value), setValue]);
    return null;
}

// Simple list
// TODO: add pagination data / handlers, etc.

function ListChild({ list, item, index, childrenComp }:{ list: any[], item: any, index: number, childrenComp: any }) {
    return (
        <DataProvider 
            data={{ $item: item, $itemIndex: index, $itemId: item.id || item._id || index, $list: list }} 
        >
            {childrenComp}
        </DataProvider>
    );
}

export function StaticList({ bind, as, children, filter, ...props }: { bind: string, as: ComponentType, children: any, filter: any, [key:string]: any }) {
    const id = useMemo(() => uniqid(), []);

    // Get the list from the data variable.
    const list = bind;

    const [filterValue] = useDataVar(filter);
    
    if (!check.nonEmptyArray(list)) {
        return null;
    }

    const Component = as || React.Fragment;

    // TODO: add a prop to define which property(ies) to filter on
    const filteredList = check.nonEmptyString(filterValue) ? list.filter((item) => !!item && JSON.stringify(item).toLowerCase().includes(filterValue.toLowerCase())) : list;

    try {
        return React.createElement(Component, Component === React.Fragment ? {} : props, filteredList.map((item, index) => (
            <ListChild 
                key={'list_'+id+(check.nonEmptyString(filterValue) ? `(${filterValue})` : '') + '::' + index}
                list={list}
                item={item}
                index={index}
                childrenComp={children}
            />
        )));
    } catch(err) {
        console.error('List error:', err);
        return null;
    }
}

export function List({ bind, as, children, filter, type, ...props }: { bind: string, as: ComponentType, children: any, filter: any, type?: ArrayValueType, [key:string]: any }) {
    // Allow list to receive an array as bind instead of a variable name
    let initialValue = check.array(bind) ? bind : undefined;
    const boundVar = check.nonEmptyString(bind) ? bind : '';

    // Get the list from the data variable. useDataVar will return initialValue if bind is not a valid variable name
    const [list] = useDataVar(boundVar, initialValue);
    const normalizedList = normalizeValue(type || 'array', list);

    return (
        <StaticList
            bind={normalizedList}
            as={as}
            children={children}
            filter={filter}
            {...props}
        />
    );
}

export { DataProvider, useDataVar }