import { useContext } from "react";
import { ThemeContext } from "./LayoutsThemeProvider";
import { cva } from "class-variance-authority";
import check from "@/vendors/check";

export function useComponentCvaTheme(componentName: string) : (props: Record<string, any>) => string {
    const { classesPerComponent } = useContext(ThemeContext);

    let classes = classesPerComponent[componentName] || {};
    if (!check.nonEmptyArray(classes) || classes.length !== 2) {
        return () => '';
    }

    return cva(...classes);
}