import check from "@/vendors/check";
import { withTextAsFirstChild } from "../../util";
import { ComponentParser, ComponentTreeNode } from "../component.type";
import { childrenParsers, ComponentAliasConfig, isTextComponent, normalizeTextComponent } from "../shadcn/_utils";
import GenericComponent from "./GenericComponent";
import { getImageComponent } from "../base/images";
import { getParserConfig } from "../../parser-config";
import { cn } from "@/lib/utils";

const htmlComponentToRole : Record<string, string> = {
    'h1': 'title',
    'h2': 'title',
    'p': 'description',
    'img': 'image',
    'IconOf': 'icon',
    'Button': 'button',
    'Avatar': 'avatar',
}

const VARIANTS = `
BottomBar-default-default
Button-default-default
Card-BgImage-Centered
Card-BgImage-Default
Card-BgImage-MultipleButtons
Card-Card-buttonImgCard
Card-Card-default
Card-Card-landscapeImgCard
Card-Card-portraitImgCard
Card-Card-squareImgCard
Card-ListItem-ListItemImage
Card-OutlineCard-Default
Card-buttonCard-default
`.trim().split('\n');

function childrenToText(children: ComponentTreeNode[]) {
    return children.map((child) => {
        if (isTextComponent(child)) {
            return normalizeTextComponent(child)?.text;
        }
        return null;
    }).filter(c => !!c).join(' ');
}

// Note: temporary
// All components should implement a composable API
function childrenWithRolesToProps(children: ComponentTreeNode[]) {
    const props : Record<string, string> = {};
    children.forEach((child) => {
        const role = child?.props?.role;
        if (!check.nonEmptyString(role)) {
            return;
        }
        if (['title', 'description', 'tagline'].includes(role)) {
            props[role] = childrenToText(child.children || []);
        }
        if (['icon', 'image', 'illustration', 'logo'].includes(role)) {
            props[role] = child?.props?.src || '';
        }
        if (['button'].includes(role)) {
            props['buttonLabel'] = childrenToText(child.children || []);
        }
    });

    return props;
}

const twClassToProps : Record<string, Record<string, string>>= {
    'text-xs': { size: 'xs' },
    'text-sm': { size: 'sm' },
    'text-base': { size: 'md' },
    'text-lg': { size: 'lg' },
    'text-xl': { size: 'xl' },
    'text-2xl': { size: '2xl' },
    'text-3xl': { size: '3xl' },
    'text-4xl': { size: '4xl' },
    'text-5xl': { size: '5xl' },
    'text-6xl': { size: '6xl' },

    'rounded-none': { rounded: 'none' },
    'rounded-sm': { rounded: 'sm' },
    'rounded-md': { rounded: 'md' },
    'rounded-lg': { rounded: 'lg' },
    'rounded-xl': { rounded: 'xl' },
    'rounded-2xl': { rounded: '2xl' },
    'rounded-3xl': { rounded: '3xl' },
    'rounded-full': { rounded: 'full' },
}

function extractPropsFromClasses(...classes: any[]) {
    const allClasses = cn(...classes);
    const props : Record<string, string> = {};
    const finalClasses : string[] = [];

    allClasses.split(' ').forEach((className) => {
        if (twClassToProps[className]) {
            Object.assign(props, twClassToProps[className]);
        } else {
            finalClasses.push(className);
        }
    });

    return { props, classes: finalClasses };
}

const TAG = 'template';
const ALIASES : ComponentAliasConfig[] = [
    ['title', 'h1' as any, 'h1', undefined, (c : ComponentTreeNode) => {
        return withTextAsFirstChild({
            ...c,
            component: 'h1',
            htmlComponent: 'h1',
            classes: [],
            props: { role: 'title' },
        });
    }],
    ['description', 'p' as any, 'p', undefined, (c : ComponentTreeNode) => {
        return withTextAsFirstChild({
            ...c,
            component: 'p',
            htmlComponent: 'p',
            classes: [],
            props: { role: 'description' },
        })
    }],
    ['tagLine', 'span' as any, 'span', undefined, (c : ComponentTreeNode) => {
        return withTextAsFirstChild({
            ...c,
            component: 'span',
            htmlComponent: 'span',
            classes: [],
            props: { role: 'tagline' },
        });
    }],
    ['image', 'img' as any, 'img', undefined, (c : ComponentTreeNode) => {
        return getImageComponent({
            ...c,
            props: {
                ...c.props,
                role: 'image',
            }
        },
        // Change image output type to make sure we get a src
        getParserConfig('html')
        );
    }],
];

const MoleculeComponentParser : ComponentParser = {
    name: 'Template',
    description: 'Enables you to instanciate templated UI patterns',
    tags: [TAG],
    refImplementation: undefined, // TODO
    dontList: true,

    childrenParsers: childrenParsers(ALIASES, TAG),

    parseTree: (c: ComponentTreeNode) => {
        const { children } = c;

        // Add roles to children
        const childrenWithRoles = (children || []).map((child) => {
            const role = htmlComponentToRole[child?.htmlComponent || ''];
            return {
                ...child,
                props: {
                    ...(child?.props || {}),
                    role: child?.props?.role || role || undefined,
                }
            }
        });

        // Temporary: recreate props from composable API for legacy compatibility
        const composableProps = childrenWithRolesToProps(childrenWithRoles);

        // Convert rounded && text-{size} classNames to props
        const { props: propsFromTwClasses, classes } = extractPropsFromClasses(c.classes, composableProps.classes);

        // Return a generic component
        return {
            ...c,
            classes,
            component: GenericComponent,
            htmlComponent: 'GenericComponent',
            children: childrenWithRoles,
            props: {
                ...propsFromTwClasses,
                ...composableProps,
                ...c.props,
                className: classes.join(' '),
            }
        };
    },
    imports: [], // TODO
    setup: [], // TODO
    variants: VARIANTS,
}

export default MoleculeComponentParser;