import check from "@/vendors/check";
import { JsonXNode } from "./parser-v2";
import { isSerializedFunction, nonEmptyTrimmed } from "../utils";

const indentsString = (n: number) => '    '.repeat(n);
function indent(txt: string, nIndents: number) {
    return txt.split('\n').map(l => indentsString(nIndents) + l).join('\n');
}

// Reverse parse know ratios from numbers to a string like "16/9"
export function getKnownRatioFromNumber(value: number) : string | null {
    const DELTA_MAX = 0.00001;
    if (value === 1) {
        // We only work with ratios that are not 1:1, to avoid replacing integers by ratios
        return null;
    }
    for (let i = 0; i <= 16; i++) {
        for (let j = 1; j <= 16; j++) {
            if (i === j) {
                continue;
            }
            if (check.integer(i / j)) {
                continue;
            }
            if (Math.abs((i / j) - value) < DELTA_MAX) {
                return `${i}/${j}`;
            }
        }
    }

    return null;
}

export function outputJSX(node: JsonXNode | string | JsonXNode[], nIndents = 0) : string {
    if (check.nonEmptyArray(node)) {
        return `${indentsString(nIndents)}<>\n${node.map(n => outputJSX(n, nIndents + 1)).join('\n')}\n${indentsString(nIndents)}</>`;
    }

    if (check.array(node)) {
        return '';
    }

    if (!check.nonEmptyObject(node)) {
        return indentsString(nIndents) + node; 
    }

    const {component, props, children, comments} = node;

    let prefix = '';
    if (check.nonEmptyArray(comments)) {
        prefix = comments.map(line => (indentsString(nIndents) + '{/* ' + line.trim().replaceAll('*/', '') + ' */}')).join('\n') + '\n';
    }

    if (component === 'text') {
        if (check.nonEmptyObject(props)) {
            return outputJSX({
                component: 'span',
                props: props,
                children: children,
            }, nIndents);
        }
        let prefix = '';
        if (check.nonEmptyArray(comments)) {
            prefix = comments.map(line => (indentsString(nIndents) + '// ' + line.trim())).join('\n') + '\n';
        }
        return `${prefix}${indentsString(nIndents)}${(children || []).join('')}`;
    }

    const propsString = Object.entries(props || {}).map(([key, value]) => {
        if (key === 'children') {
            return '';
        }

        if (check.array(value)) {
            return `${key}={${JSON.stringify(value)}}`;
        }

        if (value === null || value === undefined) {
            return '';
        }

        if (check.function(value)) {
            return `${key}={\n${indent(value.toString(), nIndents + 2)}\n${indent('', nIndents+1)}}`;
        }

        // Handle serialized functions
        // Add ts-ignore to make typescript ignore the function (as it is written in JS)
        if (isSerializedFunction(value)) {
            return `${key}={\n${indent(`
// @ts-ignore
${indent(value.code, 1)}              
`.trim(), nIndents + 2)}\n${indent('', nIndents+1)}}`;
        }

        if (check.boolean(value)) {
            if (value) {
                return `${key}`;
            } else {
                return `${key}={false}`;
            }
        }

        if (check.number(value)) {
            // Check if number is a known ratio
            const ratioString = getKnownRatioFromNumber(value);
            if (ratioString) {
                return `${key}={${ratioString}}`;
            }
            return `${key}={${value}}`;
        }

        if (check.object(value)) {
            return `${key}={${JSON.stringify(value)}}`;
        }

        if (key === 'className') {
            if (!nonEmptyTrimmed(value)) {
                return '';
            }
            // TODO: Sort tailwind classes here
            return `${key}="${(value)}"`;
        }

        if (!check.string(value)) {
            return `${key}={${JSON.stringify(value)}}`;
        }

        // Return value as an scaped string (escape ")
        return `${key}="${value.replaceAll('"', '\\"')}"`;
    }).map(l => l.trim()).filter(check.nonEmptyString).join(' ');

    if (!check.nonEmptyArray(children)) {
        return `${prefix}${indentsString(nIndents)}<${component}${check.nonEmptyString(propsString) ? ` ${propsString} `: ' '}/>`;
    }

    return `${prefix}${indentsString(nIndents)}<${component}${check.nonEmptyString(propsString) ? ` ${propsString} `: ''}>\n${children.map(n => outputJSX(n, nIndents + 1)).join('\n')}\n${indentsString(nIndents)}</${component}>`;
}