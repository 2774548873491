import check from "./check";

export function getImageExtension(mimeType: string) {
    if (!check.nonEmptyString(mimeType)) {
        return null;
    }

    const mimeToExtMap : Record<string, string> = {
        'image/jpeg': 'jpg',
        'image/pjpeg': 'jpg',
        'image/png': 'png',
        'image/gif': 'gif',
        'image/bmp': 'bmp',
        'image/webp': 'webp',
        'image/tiff': 'tiff',
        'image/svg+xml': 'svg',
        'image/x-icon': 'ico',
        'image/vnd.microsoft.icon': 'ico',
        'image/heif': 'heif',
        'image/heic': 'heic',
        'image/avif': 'avif',
        'image/vnd.wap.wbmp': 'wbmp',
        'image/x-canon-cr2': 'cr2',
        'image/x-nikon-nef': 'nef',
        'image/x-sony-arw': 'arw',
        'image/x-panasonic-rw2': 'rw2',
        'image/x-olympus-orf': 'orf',
        'image/x-fujifilm-raf': 'raf',
        'image/x-pentax-pef': 'pef',
        'image/x-samsung-srw': 'srw',
        'image/x-adobe-dng': 'dng'
    };

    return mimeToExtMap[mimeType.trim().toLowerCase()] || null;
}