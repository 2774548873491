import {
    ContextMenu,
    ContextMenuCheckboxItem,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuLabel,
    ContextMenuRadioGroup,
    ContextMenuRadioItem,
    ContextMenuSeparator,
    ContextMenuShortcut,
    ContextMenuSub,
    ContextMenuSubContent,
    ContextMenuSubTrigger,
    ContextMenuTrigger,
  } from "@/components/shadcn/ui/context-menu"
import { ComponentAliasConfig, childrenParsers, imports, isTextComponent, mergeComponents, mergeShortcutComponents, normalizeTextComponent, radixComponentDoc, removeOneOccurenceOfText, setup } from "../_utils";
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { ComponentType } from "react";
import check from "@/vendors/check";
import { cn, nonEmptyTrimmed } from "@/lib/utils";
import { parseInputOrCommandText, parseShortcutText } from "../../layouts-lang";
import { child } from "firebase/database";
import { Component } from "lucide-react";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
import { mergeClasses } from "@/lib/parser/util";
import { mergeAllComponentsOfType } from "../../parsing-pipeline";
  
/*
  export function ContextMenuDemo() {
    return (
      <ContextMenu>
        <ContextMenuTrigger className="flex h-[150px] w-[300px] items-center justify-center rounded-md border border-dashed text-sm">
          Right click here
        </ContextMenuTrigger>
        <ContextMenuContent className="w-64">
          <ContextMenuItem inset>
            Back
            <ContextMenuShortcut>⌘[</ContextMenuShortcut>
          </ContextMenuItem>
          <ContextMenuItem inset disabled>
            Forward
            <ContextMenuShortcut>⌘]</ContextMenuShortcut>
          </ContextMenuItem>
          <ContextMenuItem inset>
            Reload
            <ContextMenuShortcut>⌘R</ContextMenuShortcut>
          </ContextMenuItem>
          <ContextMenuSub>
            <ContextMenuSubTrigger inset>More Tools</ContextMenuSubTrigger>
            <ContextMenuSubContent className="w-48">
              <ContextMenuItem>
                Save Page As...
                <ContextMenuShortcut>⇧⌘S</ContextMenuShortcut>
              </ContextMenuItem>
              <ContextMenuItem>Create Shortcut...</ContextMenuItem>
              <ContextMenuItem>Name Window...</ContextMenuItem>
              <ContextMenuSeparator />
              <ContextMenuItem>Developer Tools</ContextMenuItem>
            </ContextMenuSubContent>
          </ContextMenuSub>
          <ContextMenuSeparator />
          <ContextMenuCheckboxItem checked>
            Show Bookmarks Bar
            <ContextMenuShortcut>⌘⇧B</ContextMenuShortcut>
          </ContextMenuCheckboxItem>
          <ContextMenuCheckboxItem>Show Full URLs</ContextMenuCheckboxItem>
          <ContextMenuSeparator />
          <ContextMenuRadioGroup value="pedro">
            <ContextMenuLabel inset>People</ContextMenuLabel>
            <ContextMenuSeparator />
            <ContextMenuRadioItem value="pedro">
              Pedro Duarte
            </ContextMenuRadioItem>
            <ContextMenuRadioItem value="colm">Colm Tuite</ContextMenuRadioItem>
          </ContextMenuRadioGroup>
        </ContextMenuContent>
      </ContextMenu>
    )
  }
  */

  const TAG = 'context-menu';
  const ALIASES : ComponentAliasConfig[] = [
    ['menu', ContextMenu, 'ContextMenu', radixComponentDoc('ContextMenu.Root')],
    ['checkbox', ContextMenuCheckboxItem, 'ContextMenuCheckboxItem', radixComponentDoc('ContextMenu.CheckboxItem')],
    ['checkbox-item', ContextMenuCheckboxItem, 'ContextMenuCheckboxItem', radixComponentDoc('ContextMenu.CheckboxItem')],
    ['content', ContextMenuContent, 'ContextMenuContent', radixComponentDoc('ContextMenu.Content')],
    ['item', ContextMenuItem, 'ContextMenuItem', radixComponentDoc('ContextMenu.Item')],
    ['label', ContextMenuLabel, 'ContextMenuLabel', radixComponentDoc('ContextMenu.Label')],
    ['radio-group', ContextMenuRadioGroup, 'ContextMenuRadioGroup', radixComponentDoc('ContextMenu.RadioGroup')],
    ['radio-item', ContextMenuRadioItem as any, 'ContextMenuRadioItem', radixComponentDoc('ContextMenu.RadioItem')],
    ['separator', ContextMenuSeparator, 'ContextMenuSeparator', radixComponentDoc('ContextMenu.Separator')],
    ['shortcut', ContextMenuShortcut, 'ContextMenuShortcut', radixComponentDoc('ContextMenu.Shortcut')],
    ['sub', ContextMenuSub, 'ContextMenuSub', radixComponentDoc('ContextMenu.Sub')],
    ['sub-content', ContextMenuSubContent, 'ContextMenuSubContent', radixComponentDoc('ContextMenu.SubContent')],
    ['sub-trigger', ContextMenuSubTrigger, 'ContextMenuSubTrigger', radixComponentDoc('ContextMenu.SubTrigger')],
    ['trigger', ContextMenuTrigger, 'ContextMenuTrigger', radixComponentDoc('ContextMenu.Trigger')],
  ];

  const componentAliases : { [componentAlias:string]: ComponentType } = ALIASES.reduce((acc, [alias, component]) => {
    return { ...acc, [alias as string]: component, [TAG + '-' + alias]: component };
  }, {});
  
const isItemComponent = (c: ComponentTreeNode) => {
  return [ContextMenuItem, ContextMenuCheckboxItem, ContextMenuRadioItem, ContextMenuLabel].includes(c.component as any);
}

const canComponentHaveChildren = (c: ComponentTreeNode) => {
  return ![ContextMenuSeparator].includes(c.component as any);
}

function textToMenuItem(text: string) {
  if (!check.nonEmptyString(text) || !nonEmptyTrimmed(text)) {
    return null;
  }

  // By default, component is a simple item
  let component = ContextMenuItem;
  let htmlComponent = 'ContextMenuItem';
  let props = {};

  const { type, label, shortcut, checked  } = parseInputOrCommandText(text, {
    shortcut: true,
    radio: true,
    checkbox: true,
    separator: true,
    title: true,
  });

  // If the text is a separator, we return a separator component
  if (type === 'separator') {
    component = ContextMenuSeparator as any;
    return {
      component,
      htmlComponent: 'ContextMenuSeparator',
      classes: [],
      props,
      children: [],
    };
  }

  // If the text starts with a radio pattern, it's a radio item
  if (type === 'radio') {
    component = ContextMenuRadioItem as any;
    htmlComponent = 'ContextMenuRadioItem';
    props = { 
      value: label,
      checked,
    };
  }

  // If the text starts with a checkbox pattern, it's a checkbox item
  if (type === 'checkbox') {
    component = ContextMenuCheckboxItem;
    htmlComponent = 'ContextMenuCheckboxItem';
    props = { 
      checked,
    };
  }

  // If the text is a title, we return a label component
  if (type === 'title') {
    component = ContextMenuLabel as any;
    htmlComponent = 'ContextMenuLabel';
  }

  // If the text includes a shortcut pattern, we add a shortcut component
  let compChildren : (string | ComponentTreeNode)[] = [label];
  if (shortcut) {
    compChildren.push({
      classes: [],
      component: ContextMenuShortcut,
      htmlComponent: 'ContextMenuShortcut',
      props: {},
      children: [shortcut],
    });
  }

  return {
    component,
    htmlComponent,
    classes: [],
    props,
    children: compChildren || [],
  };
}

function wrapComponentInMenu(component: ComponentTreeNode, children: any[], level = 0) {
  if (level <= 0) {
    return {
      component: ContextMenuContent,
      htmlComponent: 'ContextMenuContent',
      classes: [],
      props: {},
      children,
    }
  } else {
    return {
      component: ContextMenuSub,
      htmlComponent: 'ContextMenuSub',
      classes: [],
      props: {},
      children: [
        { ...component, classes: [], props: {}, component: ContextMenuSubTrigger, htmlComponent: 'ContextMenuSubTrigger' },
        {
          ...component,
          classes: cn('w-48', component.classes || []).split(' '),
          component: ContextMenuSubContent,
          htmlComponent: 'ContextMenuSubContent',
          children,
        }
      ]
    }
  }
}

function parseTextComponent(c: ComponentTreeNode, level = 0) {
  if (!check.nonEmptyObject(c) || c.component !== 'text') {
    return null;
  }

  let parsed = null;
  const { text, children: rawChildren } = c;
  const children = removeOneOccurenceOfText(rawChildren || [], text || '');

  // Create a new component from the text
  const textMenuItem = textToMenuItem(text || '');
  if (!textMenuItem) {
    return null;
  }

  // Merge it with the classes and props of the current component
  parsed = {
    ...textMenuItem, 
    classes: [...(c.classes || []), ...(textMenuItem?.classes || [])],
    props: { ...(c.props || {}), ...(textMenuItem?.props || {}) },
  };

  // If our component has children, wrap them in a menu
  if (check.nonEmptyArray(children) && (children.length > 1 || children[0] !== text)) {
    return wrapComponentInMenu(parsed, children, level);
  }
  
  // Else return the component as is
  return parsed;
}

function parseMenuItem(
  c: ComponentTreeNode | string | null, 
  level = 0,
  isWithinItem = false,
): ComponentTreeNode | ComponentTreeNode[] | string | null {
  if (!c) {
    return null;
  }

  let comp : ComponentTreeNode | string | null = c;
  
  // Parse string components only if we are within an item
  if (check.string(comp)) {
    if (isWithinItem) {
      return comp;
    }

    comp = textToMenuItem(comp);
  }

  if (!comp || !check.nonEmptyObject(comp)) {
    return null;
  }

  // If component is a text component, pre-parse it
  if (comp.component === 'text') {
    if (isWithinItem) {
      return comp;
    }
    comp = parseTextComponent(comp, level);
    if (!comp) {
      return null;
    }
  }

  const { component, children } = comp as ComponentTreeNode;
  let parsed : ComponentTreeNode = {
    ...comp,
  };

  // Alias component names to the real React component
  if (check.nonEmptyString(component) && !!componentAliases[component.trim().toLowerCase()]) {
    parsed.component = componentAliases[component.trim().toLowerCase()];
  }

  // If component cannot have children, return it
  if (!canComponentHaveChildren(parsed)) {
    return { ...parsed, children: [] };
  }

  // Detect if the current component is an item 
  const commponentIsItem = isItemComponent(parsed) || [ContextMenuSubTrigger, ContextMenuTrigger, ContextMenuShortcut].includes(parsed.component as any);
 

  // Parse the children one by one
  let parsedChildren : any[] = (children || [])
    .map(c => parseMenuItem(c, level + 1, commponentIsItem))
    .filter(c => !!c);

  // Make sure shortcut components don't have themselves as children
  // Else, merge them
  if (parsed.component === ContextMenuShortcut) {
      let shortcutChildren = parsedChildren.filter(c => c.component === parsed.component);
      parsedChildren = parsedChildren.filter(c => c.component !== parsed.component);
      if (check.nonEmptyArray(shortcutChildren)) {
        const toMerge = [
          { ...parsed, children: parsedChildren }, 
          ...shortcutChildren
        ];
        const merged = mergeShortcutComponents(toMerge);
        parsed = merged;
        parsedChildren = merged.children || [];
      }
  }
  // Make sure item, icon components don't have themselves as children. 
  // Else, drop the children
  if ([ContextMenuItem, ContextMenuLabel].some(t => parsed.component === t)) {
    parsedChildren = parsedChildren.filter(pc => pc.component !== parsed.component);
  }

  // If the children contain a radiogroup or checkbox, add inset to all other items by default
  const insetCausingComps = [ContextMenuCheckboxItem, ContextMenuRadioItem, ContextMenuRadioGroup];
  const componentsWithNoInsetProp = [ContextMenuSeparator];
  if (parsedChildren.some(c => insetCausingComps.includes(c.component as any))) {
    parsedChildren = parsedChildren.map(c => {
      if (!insetCausingComps.includes(c.component as any) && !componentsWithNoInsetProp.includes(c.component as any)) {
        // Treat sub menus differently
        if (c.component === ContextMenuSub) {
          return { ...c, children: (c.children || []).map((child: ComponentTreeNode) => {
            if (child.component === ContextMenuSubTrigger) {
              // We add the inset prop only if it's not already set to false
              return ({ ...child, props: { ...child.props, inset: child.props.inset === false ? false : true }});
            }
            return child;
          })};
        }

        // We add the inset prop only if it's not already set to false
        return { ...c, props: { ...c.props, inset: c.props.inset === false ? false : true } };
      }
      return c;
    });
  }

  // If the children contain radio items without a group, we create the group
  if (parsed.component !== ContextMenuRadioGroup && parsedChildren.some(c => c.component === ContextMenuRadioItem)) {
    let newChildren : any[] = [];
    let currentGroup : { value: string | null, children: any[] } = {
      value: null,
      children: [],
    } 

    const appendGroup = () => {
      if (!check.nonEmptyArray(currentGroup.children)) {
        return;
      }

      newChildren.push({
        component: ContextMenuRadioGroup,
        htmlComponent: 'ContextMenuRadioGroup',
        classes: [],
        props: { value: currentGroup.value || currentGroup.children[0]?.props?.value },
        children: currentGroup.children,
      });
      currentGroup.children = [];
      currentGroup.value = null;
    }

    // Group radio items which follow each other together
    parsedChildren.forEach((c) => {
      if (c.component === ContextMenuRadioItem) {
        if (c.props.checked) {
          currentGroup.value = c.props.value || (c.children || []).filter(check.nonEmptyString).join('');
        }
        
        currentGroup.children.push({
          ...c,
          // Clean props
          props: { 
            value: c.props.value || (c.children || []).filter(check.nonEmptyString).join(''), 
            checked: null, 
          },
        });
      } else {
        // Append last group
        appendGroup();

        // Append current item
        newChildren.push(c);
      }
    });
    // Append last group and replace the children
    appendGroup();
    parsedChildren = newChildren;
  }

  // If component is a ContextMenuSub, make sure it only has one trigger and one content child
  if (parsed.component === ContextMenuSub) {
    let trigger : ComponentTreeNode | null = parsedChildren.find(c => c.component === ContextMenuSubTrigger) || parsedChildren.find(c => c.component === ContextMenuTrigger) || null;
    let content : ComponentTreeNode | null = parsedChildren.find(c => c.component === ContextMenuSubContent) || parsedChildren.find(c => c.component === ContextMenuContent) || null;
    let rest : ComponentTreeNode[] = parsedChildren.filter(c => ![ContextMenuSubTrigger, ContextMenuSubContent].includes(c.component as any));
    
    if (!trigger) {
      return null;
    }
    if (!content) {
      content = {
        component: ContextMenuSubContent,
        htmlComponent: 'ContextMenuSubContent',
        classes: [],
        props: {},
        children: rest,
      }
    }

    parsedChildren = [
      {
        ...trigger, 
        component: ContextMenuSubTrigger,
        htmlComponent: 'ContextMenuSubTrigger',
      }, 
      {
        ...content,
        component: ContextMenuSubContent,
        htmlComponent: 'ContextMenuSubContent',
      },
    ];
  }

  // Wrap item components in a submenu if it has children items
  if (commponentIsItem && check.nonEmptyArray(parsedChildren) && parsedChildren.some(isItemComponent)) {
    return wrapComponentInMenu({ ...parsed, children:[] }, parsedChildren, level);
  }

  // Return the parsed component with its children
  return {
    ...parsed,
    children: (parsedChildren || []).filter(c => !!c),
  };
}



const ContextMenuComponentParser : ComponentParser = {
  name: 'Context Menu',
  description: 'Displays a menu to the user — such as a set of actions or functions — triggered by a button.',
  tags: [TAG],

  refImplementation: `
/div Right click here flex h-[150px] w-[300px] items-center justify-center rounded-md border border-dashed text-sm
	/context-menu w-64 
		Back <Cmd + [>
		Forward <Cmd + ]>
		Reload <Cmd + R>
		More Tools w-48
			Save Page As... <Shift + Cmd + S>
			Create Shortcut...
			Name Window...
			---
			Developer Tools
		---
		[x] Show Bookmarks Bar <Cmd + Shift + B>
		Show Full URLs
		---
		# People
		---
		(x) Pedro Duarte
		() Colm Tuite
`.trim().replaceAll(/ {2}/g, '\t'),

  childrenParsers: childrenParsers(ALIASES, TAG),

  wrapParent: (parent: ComponentTreeNode) : ComponentTreeNode => {
    const popoverContentChildren = (parent.children || []).filter(child => child.component === TAG).map((c => ({
      ...c,
      component: ContextMenuContent,
      htmlComponent: 'ContextMenuContent',
      children: (parseMenuItem(c, 0, false) as ComponentTreeNode)?.children || [],
      _dontParse: true,
    })));

    return {
      _dontParse: true,
      component: ContextMenu,
      htmlComponent: 'ContextMenu',
      classes: [],
      props: {},
      children: [
        {
          _dontParse: true,
          component: ContextMenuTrigger,
          htmlComponent: 'ContextMenuTrigger',
          props: {
            asChild: true, // Not working
          },
          children: [
            {
              ...parent,
              children: (parent.children || []).filter(child => child.component !== TAG),
            }
          ]
        },
        ...popoverContentChildren,
      ]
    }
  },

  imports: imports(['ContextMenu', 'ContextMenuCheckboxItem', 'ContextMenuContent', 'ContextMenuItem', 'ContextMenuLabel', 'ContextMenuRadioGroup', 'ContextMenuRadioItem', 'ContextMenuSeparator', 'ContextMenuShortcut', 'ContextMenuSub', 'ContextMenuSubContent', 'ContextMenuSubTrigger', 'ContextMenuTrigger'], TAG),
  setup: setup(TAG),
  variants: [],
  doc: {
    props: radixComponentDoc('ContextMenu.Root').props,
  }
};

registerShadcnComponent({
        ContextMenu,
        ContextMenuCheckboxItem,
        ContextMenuContent,
        ContextMenuItem,
        ContextMenuLabel,
        ContextMenuRadioGroup,
        ContextMenuRadioItem,
        ContextMenuSeparator,
        ContextMenuShortcut,
        ContextMenuSub,
        ContextMenuSubContent,
        ContextMenuSubTrigger,
        ContextMenuTrigger,
    },
    TAG,
);

export default ContextMenuComponentParser;