import { Button } from '@/components/shadcn/ui/button';
import React from 'react';

interface BgImageCardProps {
  image: string;
  title: string;
  description: string;
  label: string
  label2: string
}

/* export default function BgImageCard({ image, title, description, label, label2 }: BgImageCardProps) {
  return (
    <div className="w-[960px] h-[512px] p-4 flex-col justify-start items-start inline-flex">
        <div className="w-[928px] h-[480px] relative bg-gradient-to-r from-black to-black rounded-xl" style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover' }}>
            <div className="w-[928px] h-[480px] px-10 pb-10 left-[16px] top-[16px] absolute flex-col justify-end items-start gap-8 inline-flex">
            <div className="w-[848px] h-[140px] flex-col justify-start items-start flex">
                <div className="w-[848px] h-[140px] flex-col justify-start items-start gap-2 flex">
                <div className="w-[848px] h-[60px] flex-col justify-start items-start flex">
                    <div className="self-stretch h-[60px] flex-col justify-start items-start flex">
                    <div className="text-white text-5xl font-black font-display leading-[60px]">{title}</div>
                    </div>
                </div>
                <div className="w-[848px] h-[72px] flex-col justify-start items-start flex">
                    <div className="self-stretch h-6 flex-col justify-start items-start flex">
                    <div className="text-white text-base font-normal font-display leading-normal">{description}</div>
                    </div>
                </div>
                </div>
            </div>
            <div className="w-[848px] h-12 relative">
                <div className="w-[149.02px] h-12 px-5 left-0 top-0 absolute bg-sky-500 rounded-3xl justify-center items-center inline-flex">
                <div className="w-[109.02px] h-6 bg-sky-500 flex-col justify-start items-start inline-flex">
                    <div className="self-stretch flex-col justify-start items-start flex">
                    <div className="text-neutral-900 text-base font-bold font-display leading-normal tracking-tight">{label}</div>
                    </div>
                </div>
                </div>
                <div className="w-[123.22px] h-12 px-5 left-[161px] top-0 absolute bg-gray-100 rounded-3xl justify-center items-center inline-flex">
                <div className="w-[83.22px] h-6 bg-gray-100 flex-col justify-start items-start inline-flex">
                    <div className="self-stretch flex-col justify-start items-start flex">
                    <div className="text-neutral-900 text-base font-bold font-display leading-normal tracking-tight">{label2}</div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    );
} */

export default function BgImageCard({ image, title, description, label }: BgImageCardProps) {
    return (
        <div className="justify-start items-start flex-col flex w-full min-h-[calc(100vw_*_0.5172413793)] relative" >
			<div className="items-start flex-col flex flex-1 z-10 justify-end p-[40px] gap-[32px]" >
				<div className="justify-start items-start flex-col flex gap-[8px] text-white" >
					<h1 className="text-semibold font-black text-[48px] font-display" >
						{title}
					</h1>
					<p className="font-body text-[16px]" >
                        {description}
					</p>
				</div>
                <div className='flex flex-row gap-4'>
                    <Button className="gap-2 rounded-full bg-sky-500 font-black text-black font-display" variant="default" >
                        {label}
                    </Button>
                    <Button className="gap- rounded-full bg-white font-black text-black font-display" variant="default" >
                        Other button
                    </Button>
                </div>
            </div>
			<img
                alt="coffee"
                className="absolute inset-0 h-full w-full object-cover z-0 overflow-hidden"
                sizes="(max-width: 200px) 200px, (max-width: 400px) 400px, (max-width: 800px) 800px, 4480px"
                src={image}
            />
		</div>
    );
}