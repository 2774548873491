import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/shadcn/ui/card"
import { ComponentParser, ComponentTreeNode } from "../../component.type"
import { childrenParsers, ComponentAliasConfig, imports, parseCardLikeComponent, setup } from "../_utils"
import { withTextAsChildOfType, withTextAsFirstChild } from "@/lib/parser/util";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";

/*
export function CardWithForm() {
  return (
    <Card className="w-[350px]">
      <CardHeader>
        <CardTitle>Create project</CardTitle>
        <CardDescription>Deploy your new project in one-click.</CardDescription>
      </CardHeader>
      <CardContent>
        <form>
          <div className="grid w-full items-center gap-4">
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="name">Name</Label>
              <Input id="name" placeholder="Name of your project" />
            </div>
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="framework">Framework</Label>
              <Select>
                <SelectTrigger id="framework">
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent position="popper">
                  <SelectItem value="next">Next.js</SelectItem>
                  <SelectItem value="sveltekit">SvelteKit</SelectItem>
                  <SelectItem value="astro">Astro</SelectItem>
                  <SelectItem value="nuxt">Nuxt.js</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </form>
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button variant="outline">Cancel</Button>
        <Button>Deploy</Button>
      </CardFooter>
    </Card>
  )
}
*/


const TAG = 'card';
const ALIASES : ComponentAliasConfig[] = [
  ['header', CardHeader, 'CardHeader', undefined, (c: ComponentTreeNode) => {
    return withTextAsChildOfType(CardTitle, 'CardTitle')({
      ...c,
      component: CardHeader,
      htmlComponent: 'CardHeader',
    });
  }],
  ['footer', CardFooter, 'CardFooter'],
  ['content', CardContent, 'CardContent'],
  ['title', CardTitle, 'CardTitle'],
  ['description', CardDescription, 'CardDescription'],
];

const CardComponentParser : ComponentParser = {
  name: 'Card',
  description: 'Displays a card with header, content, and footer.',
  tags: [TAG],

  refImplementation: `
/card w-[350px]
    /header
        /title Create project
        /description Deploy your new project in one-click.
    /content
        /form
            /div grid w-full items-center gap-4
                /flex flex-col space-y-1.5
                    /label Name
                    /input Name of your project
                /flex flex-col space-y-1.5
                    /label Framework
                    /select Select
                        /item @value="next" Next.js
                        /item @value="sveltekit" SvelteKit
                        /item @value="astro" Astro
                        /item @value="nuxt" Nuxt.js
    /footer justify-between
        /button %outline Cancel
        /button Deploy
  `.trim(),

  childrenParsers: childrenParsers(ALIASES, TAG),

  parseTree: (c: ComponentTreeNode) : ComponentTreeNode => parseCardLikeComponent(c, {
    component: [Card, 'Card'],
    header: [CardHeader, 'CardHeader'],
    content: [CardContent, 'CardContent'],
    footer: [CardFooter, 'CardFooter'],
    title: [CardTitle, 'CardTitle'],
    description: [CardDescription, 'CardDescription'],
  }),
  
  imports: imports(['Card', 'CardContent', 'CardHeader', 'CardTitle', 'CardDescription', 'CardFooter'], TAG),
  setup: setup(TAG),
  variants: [],
};

registerShadcnComponent({
        Card,
        CardContent,
        CardDescription,
        CardFooter,
        CardHeader,
        CardTitle,
    },
    TAG,
);

export default CardComponentParser;
