import itsADate from 'its-a-date';
import check from '@/vendors/check';
import { bulletProofJSONParse } from '@/vendors/bulletproof-json';

export function toString(value: any) : string | null {
    if (check.string(value)) {
        return value;
    }
    if (value === null || value === undefined || Number.isNaN(value)) {
        return null;
    }

    if(value?.toString()) {
        return value.toString();
    }
    
    return null;
}

export function toNumber(value: any) : number | null {
    if (check.number(value)) {
        return value;
    }
    if (check.nonEmptyString(value)) {
        const num = parseFloat(value);
        if (!isNaN(num)) {
            return num;
        }
    }
    return null;
}

export function toInteger(value: any) : number | null {
    const num = toNumber(value);
    if (num === null) {
        return null;
    }
    return Math.round(num);
}

export function toDate(value: any) : Date | null {
    if (!value) {
        return null;
    }

    if (value instanceof Date) {
        return value;
    }
    
    if (!check.nonEmptyString(value)) {
        const d = new Date(value);
        if (d.toString() !== "Invalid Date") {
            return d;
        }
        return null;
    }

    let out = new Date(itsADate.parse(value));
    if (out.toString() === "Invalid Date") {
        return null;
    }
    return out;
}

function dateToDay(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function dateToMonth(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}`;
}

export function toDay(value: any) : string | null {
    if (check.nonEmptyString(value) && /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value)) {
        return value;
    }
    const date = toDate(value);
    if (!date) {
        return null;
    }

    return dateToDay(date);
}

export function toMonth(value: any) : string | null {
    if (check.nonEmptyString(value) && /^[0-9]{4}-[0-9]{2}$/.test(value)) {
        return value;
    }
    const date = toDate(value);
    if (!date) {
        return null;
    }

    return dateToMonth(date);
}

export function toBoolean(value: any) : boolean | null {
    if (check.boolean(value)) {
        return value;
    }
    if (check.number(value)) {
        if (value === 1) {
            return true;
        }
        if (value === 0) {
            return false;
        }
    }
    if (check.nonEmptyString(value)) {
        const truish = ['on', 'true', '1', 'yes'];
        const falsish = ['off', 'false', '0', 'no'];

        if ([
            ...truish,
            ...falsish,
        ].includes(value.toLowerCase().trim())) {
            return truish.includes(value.toLowerCase().trim());
        }
    }
    return null;
}

export function toArray(value: any) : any[] | null {
    if (check.array(value)) {
        return value;
    }
    if (check.nonEmptyString(value)) {
        let val = bulletProofJSONParse(value);

        console.log('Normalizing array:', {
            bulletProof: {
                input: value,
                output: val,
            }
        });
        
        if (check.array(val)) {
            return val;
        }
    }
    return null;
}

export function toObject(value: any) : {[key: string]: any} | null {
    if (check.object(value)) {
        return value;
    }

    if (check.nonEmptyString(value)) {
        let val = bulletProofJSONParse(value);
        if (check.object(val)) {
            return val;
        }
    }

    return null;
}

export type ArrayValueType = 'array' | 'string[]' | 'number[]' | 'integer[]' | 'date[]' | 'day[]' | 'boolean[]' | 'object[]';
export type ValueType = 'string' | 'number' | 'integer' | 'date' | 'day' | 'boolean' | 'object' | ArrayValueType;

export function getNormalizer(type: any) : (val: any) => any | null {
    if (!check.nonEmptyString(type)) {
        return (v: any) => v;
    }

    if (type === 'text' || type === 'string') {
        return toString;
    }
    if (type === 'number') {
        return toNumber;
    }
    if (type === 'integer') {
        return toInteger;
    }
    if (type === 'date') {
        return toDate;
    }
    if (type === 'day') {
        return toDay;
    }
    if (type === 'boolean') {
        return toBoolean;
    }
    if (type === 'object') {
        return toObject;
    }
    if (type === 'array') {
        return toArray;
    }
    
    if (type.endsWith('[]')) {
        const itemType = type.substr(0, type.length - 2);
        const normalizer = getNormalizer(itemType);
        return (v: any) => {
            const arr = toArray(v);
            console.log('Normalizing array:', {
                input: v,
                output: arr,
            });
            if (!arr) {
                return null;
            }
            return arr.map(normalizer).filter((val: any) => val !== null);
        };
    }

    return (v: any) => v;
}

export function normalizeValue(
    type: any,
    value: any,
) : any {
    return getNormalizer(type)(value);
};