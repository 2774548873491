import { OutlineCard } from './OutlineCard';

import { registerComponentVariant } from '../../../lib/components-registry';
import { CARD_COMPONENT_DESCRIPTION, CARD_COMPONENT_NAME, CARD_OUTLINE_VARIANT_DESCRIPTION, CARD_OUTLINE_VARIANT_NAME } from '../../../lib/constants';
import { ComponentType } from 'react';

function DefaultOutlineCard({ size, ...props }: any) {
    let ReactComponent = OutlineCard

    return <ReactComponent size={size} {...props} label={props.buttonLabel || null} />;
}

registerComponentVariant({
    name: CARD_COMPONENT_NAME,
    description: CARD_COMPONENT_DESCRIPTION,
    variantName: CARD_OUTLINE_VARIANT_NAME,
    variantDescription: CARD_OUTLINE_VARIANT_DESCRIPTION,
    subVariantName: 'Default',
    subVariantDescription: 'Outline card',
    reactComponent: DefaultOutlineCard,
    acceptsProps: ['image', 'title', 'description', 'buttonLabel'],
    acceptSizes: ['6xl', '5xl', '4xl', '3xl', '2xl', 'xl', 'lg', 'md', 'sm', 'xs'],
    acceptsRoundness: ['none', 'full', '3xl', '2xl', 'xl', 'lg', 'md', 'sm', 'none'],
    acceptsAspectRatios: ['9/16', '16/9', '3/4', '4/3', 'square'],
    acceptOrientations: ['north'],
    acceptsChildren: false,
});