import { Button } from "@/components/shadcn/ui/button"
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "@/components/shadcn/ui/tooltip"
import { Link, Paperclip } from "lucide-react";
import { useCallback, useState } from "react";

export function ShareButton() {
    const [ shared, setShared ] = useState(false);

    const onShare = useCallback(() => {
        navigator.clipboard.writeText(window.location.href.replace('edit', ''));
        setShared(true);
        setTimeout(() => {
            setShared(false);
        }, 3000)
    }, []);

	return (
		<TooltipProvider>
			<div className="no-transition" >
				<Tooltip>
					<TooltipTrigger asChild >
						<Button 
                            onClick={onShare}
                            className="gap-2 border bg-white/90 shadow-sm w-fit py-1.5 px-3 h-min text-[#5b5e66] hover:bg-white hover:text-[#1e1f22] rounded-lg" variant="default" >
                            <span className="text-sm font-normal" >
                                {shared ? 'Link copied!' : 'Share'}
                            </span>
						</Button>
					</TooltipTrigger>
					<TooltipContent className="bg-[#1e1f22] border-none h-min rounded-lg flex flex-row items-center gap-2" side="bottom" >
						<span className="text-xs text-white" >
							Share URL
						</span>
						<Link
                            className="text-white size-2.5" 
                        />
					</TooltipContent>
				</Tooltip>
			</div>
		</TooltipProvider>
	);
}