import * as React from "react"
import { Button } from "../../../lib/button";
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "../../../lib/utils";
import { useComposableApi } from "../../../lib/composable";

const CardVariantsBox = cva("",{
    variants: {
        size: {
            "xs": "min-h-8",
            "sm": "min-h-9",
            "md": "min-h-10",
            "lg": "min-h-12",
            "xl": "min-h-14",
            "2xl": "min-h-16",
            "3xl": "min-h-20",
            "4xl": "min-h-24",
            "5xl": "min-h-28",
            "6xl": "min-h-32",
        },
        verticalAlign: {
            "top": "",
            "center": "",
            "bottom": "",
        }
    },
    defaultVariants: {
        size:'md',
        verticalAlign: "top",
    },
});

const CardVariantsInnerBox = cva("",{ 
    variants: {
        size : {
            "xs": "gap-2 px-1 py-2",
            "sm": "gap-3 px-2 py-3",
            "md": "gap-3 px-2 py-3",
            "lg": "gap-3 px-2 py-3",
            "xl": "gap-3 px-2 py-3",
            "2xl": "gap-3 px-2 py-3",
            "3xl": "gap-3 px-2 py-3",
            "4xl": "gap-4 px-3 py-4",
            "5xl": "gap-4 px-3 py-4",
            "6xl": "gap-5 px-4 py-5",
        },
        verticalAlign: {
            "top": "items-start",
            "center": "items-center",
            "bottom": "items-end",
        }
    },
    defaultVariants: {
        "verticalAlign": "center",
        'size': 'lg'
    },
});

const CardVariantsOther = cva("",{ 
    variants: {
        size: {
            "xs": "h-6",
            "sm": "h-8",
            "md": "h-10",
            "lg": "h-12",
            "xl": "h-14",
            "2xl": "h-16",
            "3xl": "h-20",
            "4xl": "h-24",
            "5xl": "h-28",
            "6xl": "h-32",
        },
        orientation: {
            "east": "",
            "west": ""
        },
        aspectRatio: {
            "16/9": "aspect-video",
            "square": "aspect-square",
            "9/16": "aspect-[9/16]",
            "3/4": "aspect-[3/4]",
            "4/3": "aspect-[4/3]",
        },
        rounded: {
            'none': "rounded-none",
            "sm": "rounded-sm",
            "md": "rounded",
            "lg": "rounded-lg",
            "xl": "rounded-xl",
            "2xl": "rounded-2xl",
            "3xl": "rounded-3xl",
            'full': "rounded-full",
        },
        verticalAlign: {
            "top": "",
            "center": "",
            "bottom": "",
        }
    },
    defaultVariants: {
        size: "md",
        orientation: 'east',
    },
});

const TextSizeVariants = {
    "6xl": "2xl",
    "5xl": "2xl",
    "4xl": "xl",
    "3xl": "xl",
    "2xl": "lg",
    "xl": "lg",
    "lg": "lg",
    "md": "md",
    "sm": "md",
    "xs": "sm",
    null: 'lg',
}

interface CardProps extends VariantProps<typeof CardVariantsOther> {
    children: React.ReactNode;
    className?: string;
    role?: string;
}

function ListItemImage({
    size = 'md',
    aspectRatio = 'square',
    rounded = "md",
    verticalAlign = 'center',
    className = "",
    children,
} : CardProps ) {
    const { ChildWithRole, HasAtLeastOneChildWithRole } = useComposableApi(children);
    if (size === null) {
        size = 'lg';
    }

    return (
        <Button className={cn(CardVariantsBox({verticalAlign, size}), ` reset h-fit w-full bg-white dark:bg-neutral-800 rounded-sm grid`, className)}>
            <div className={cn(CardVariantsInnerBox({verticalAlign, size}), " m-auto flex flex-row justify-end h-fit w-full ")}>
                <ChildWithRole
                        role="image"
                        className={cn(CardVariantsOther({size, aspectRatio, rounded}),"object-cover")}
                ></ChildWithRole>
                <div className={"min-h-full h-fit flex-1 flex flex-col justify-center items-start"}>
                    <ChildWithRole
                            role="title"
                            className={`typeface-display-${TextSizeVariants[size]} text-neutral-900 dark:text-white align-middle text-left`}
                    ></ChildWithRole>
                    <ChildWithRole
                            role="description"
                            className={`typeface-body-${TextSizeVariants[size]} text-slate-500 dark:text-slate-400 align-middle text-left`}
                    ></ChildWithRole>
                </div>
                {HasAtLeastOneChildWithRole('icon')
                ?   <div className={cn(CardVariantsOther({size}), "flex flex-col mr-2 justify-center")}>
                        <ChildWithRole
                            role="icon"
                            className={"aspect-square"}
                        ></ChildWithRole>
                    </div>
                :   <></>}
            </div>
        </Button>
    )
}

export default ListItemImage;