"use client"

import * as React from "react"

import { cn } from "@/lib/utils"
import { Command, CommandInput } from "@/components/shadcn/ui/command"

function AutoComplete({
  defaultValue,
  value,
  onChange,
  placeholder,
  emptyText,
  children,
  ...props
}: {
  emptyText?: string
  defaultValue?: string
  value?: string
  onChange?: (val: string) => void
  placeholder: string
  children: any
  [key: string]: any
}) {
  const [open, setOpen] = React.useState(false)
  const [commandValue, setCommandValue] = React.useState(
    value || defaultValue || ""
  )
  const [inputVal, setInputVal] = React.useState(value || defaultValue || "")
  const setValue = React.useCallback((val: string) => {
    setCommandValue(val)
  }, [])

  const commitValue = React.useCallback(
    (value: string) => {
      setInputVal(value)
      onChange?.(value)
      setOpen(false)
    },
    [onChange]
  )

  // Update value if it changes
  React.useEffect(() => {
    const newVal = value || defaultValue || "";
    setCommandValue(newVal);
    setInputVal(newVal);
  }, [defaultValue, value])

  return (
    <div className={cn("no-transition w-full", props.className, "relative")}>
      <Command
        value={commandValue}
        onValueChange={setValue}
        className={
          "w-full rounded-lg border-l border-r border-t " +
          (open ? "rounded-b-none" : "border-b")
        }
      >
        <CommandInput
          key="combo-input"
          placeholder={placeholder}
          onFocus={() => {
            setOpen(true)
          }}
          onBlur={() => {
            setTimeout(() => {
              setOpen(false)
            }, 200)
          }}
          onKeyUp={(e) => {
            if (open) {
              // Capture enter
              if (e.key === "Enter") {
                commitValue(commandValue)
              }
            }
          }}
          onClick={() => setOpen(true)}
          value={inputVal}
          onValueChange={(v) => {
            setInputVal(v)
            setOpen(true)
          }}
          className={open ? "rounded-b-none" : ""}
          noBottomBorder={!open}
        />
        <div
          className={
            "duration-300 shadow-2 ease-in-out absolute w-full bottom-0 z-50 rounded-b-md " +
            (open ? "" : " h-0 overflow-hidden")
          }
          onClickCapture={() => {
            commitValue(commandValue)
          }}
        >
          {children}
        </div>
      </Command>
    </div>
  )
}

AutoComplete.displayName = "AutoComplete"

export { AutoComplete }
