"use client";

import React, { useState, useEffect, useRef } from "react";
import styles from "./chat.module.css";
import { AssistantStream } from "openai/lib/AssistantStream";
import Markdown from "react-markdown";
// @ts-expect-error - no types for this yet
import { AssistantStreamEvent } from "openai/resources/beta/assistants/assistants";
import { RequiredActionFunctionToolCall } from "openai/resources/beta/threads/runs/runs";
import { Separator } from "@/components/shadcn/ui/separator";
import { Input } from "@/components/shadcn/ui/input";
import { ReactComponent as ArrowIcon } from './arrow.svg';
import { ArrowUp } from "lucide-react";
import { cn } from "@/lib/utils";
import { Textarea } from "@/components/shadcn/ui/textarea";
import 'isomorphic-fetch';

const API_ROOT = 'https://ai-chat.layouts.dev';

type MessageProps = {
  role: "user" | "assistant" | "code";
  text: string;
};

const UserMessage = ({ text }: { text: string }) => {
  return <div className={styles.userMessage}>{text}</div>;
};

const AssistantMessage = ({ text }: { text: string }) => {
  return (
    <div className={styles.assistantMessage}>
      <Markdown>{text}</Markdown>
    </div>
  );
};

const CodeMessage = ({ text }: { text: string }) => {
  return (
    <div className={styles.codeMessage + ' overflow-y-scroll w-full'}>
      {text.split("\n").map((line, index) => (
        <div key={index}>
          <span>{`${index + 1}. `}</span>
          {line}
        </div>
      ))}
    </div>
  );
};

const Message = ({ role, text }: MessageProps) => {
  switch (role) {
    case "user":
      return <UserMessage text={text} />;
    case "assistant":
      return <AssistantMessage text={text} />;
    case "code":
      return <CodeMessage text={text} />;
    default:
      return null;
  }
};

type ChatProps = {
  functionCallHandler?: (
    toolCall: RequiredActionFunctionToolCall
  ) => Promise<string>;
  catchStream?: boolean;
  onCaughtStreamContent?: (message: string) => void;
};


function AutoGrowTextArea({ minRows = 1, maxRows = 10, value, onChange, ...props }: any) {
  const textareaRef = useRef<any>(null);
  const [rows, setRows] = useState(minRows);

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight();
    }
  }, [value]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const textareaLineHeight = 24;
      textareaRef.current.rows = minRows; // reset number of rows in textarea
      const currentRows = Math.floor(textareaRef.current.scrollHeight / textareaLineHeight);

      if (currentRows >= maxRows) {
        textareaRef.current.rows = maxRows;
        textareaRef.current.style.overflowY = 'scroll';
      } else {
        textareaRef.current.rows = currentRows;
        textareaRef.current.style.overflowY = 'hidden';
      }

      setRows(currentRows < maxRows ? currentRows : maxRows);
    }
  };

  return (
    <Textarea
      rows={rows}
      ref={textareaRef}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
}

const Chat = ({
  functionCallHandler = () => Promise.resolve(""), // default to return empty string
  catchStream = false,
  onCaughtStreamContent = () => {},
}: ChatProps) => {
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState<any>([]);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [threadId, setThreadId] = useState("");
  const catchStreamRef = useRef(catchStream);
  const fullTextRef = useRef("");

  // Handle stream catching
  useEffect(() => {
    catchStreamRef.current = catchStream;
  }, [catchStream]);

  // automatically scroll to bottom of chat
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // create a new threadID when chat component created
  useEffect(() => {
    const createThread = async () => {
      const res = await fetch(`${API_ROOT}/api/assistants/threads`, {
        method: "POST",
      });
      const data = await res.json();
      setThreadId(data.threadId);
    };
    createThread();
  }, []);

  const sendMessage = async (text: string) => {
    const response = await fetch(
      `${API_ROOT}/api/assistants/threads/${threadId}/messages`,
      {
        method: "POST",
        body: JSON.stringify({
          content: text,
        }),
      }
    );
    const stream = (AssistantStream as any).fromReadableStream(response.body!);
    handleReadableStream(stream);
  };

  const submitActionResult = async (runId: any, toolCallOutputs: any) => {
    const response = await fetch(
      `${API_ROOT}/api/assistants/threads/${threadId}/actions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          runId: runId,
          toolCallOutputs: toolCallOutputs,
        }),
      }
    );
    const stream = (AssistantStream as any).fromReadableStream(response.body!);
    handleReadableStream(stream);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!userInput.trim()) return;
    sendMessage(userInput);
    setMessages((prevMessages: any) => [
      ...prevMessages,
      { role: "user", text: userInput },
    ]);
    setUserInput("");
    setInputDisabled(true);
    scrollToBottom();
  };

  /* Stream Event Handlers */

  // textCreated - create new assistant message
  const handleTextCreated = () => {
    appendMessage("assistant", "");
  };

  useEffect(() => {
    setInterval(() => {
      console.log('=== Full text === \n', fullTextRef.current || '<No text>');
    }, 5000);
  }, []);

  // textDelta - append text to last assistant message
  const handleTextDelta = (delta: any) => {
    if (!delta) {
      return;
    }
    console.log('Appending. Catch stream: ', catchStreamRef.current);
    const append = catchStreamRef.current ? onCaughtStreamContent : appendToLastMessage;
    if (delta.value != null) {
      append(delta.value);
      fullTextRef.current = (fullTextRef?.current || '') + delta.value;
    };
    if (delta.annotations != null) {
      append(delta.annotations);
    }
  };

  // imageFileDone - show image in chat
  const handleImageFileDone = (image: any) => {
    appendToLastMessage(`\n![${image.file_id}](${API_ROOT}/api/files/${image.file_id})\n`);
  }

  // toolCallCreated - log new tool call
  const toolCallCreated = (toolCall: any) => {
    if (toolCall.type != "code_interpreter") return;
    appendMessage("code", "");
  };

  // toolCallDelta - log delta and snapshot for the tool call
  const toolCallDelta = (delta: any, snapshot: any) => {
    if (delta.type != "code_interpreter") return;
    if (!delta.code_interpreter.input) return;
    appendToLastMessage(delta.code_interpreter.input);
  };

  // handleRequiresAction - handle function call
  const handleRequiresAction = async (
    event: AssistantStreamEvent.ThreadRunRequiresAction
  ) => {
    const runId = event.data.id;
    const toolCalls = event.data.required_action?.submit_tool_outputs?.tool_calls;
    // loop over tool calls and call function handler
    const toolCallOutputs = await Promise.all(
      (toolCalls || []).map(async (toolCall: any) => {
        const result = await functionCallHandler(toolCall);
        return { output: result, tool_call_id: toolCall.id };
      })
    );
    setInputDisabled(true);
    submitActionResult(runId, toolCallOutputs);
  };

  // handleRunCompleted - re-enable the input form
  const handleRunCompleted = () => {
    setInputDisabled(false);
  };

  const handleReadableStream = (stream: AssistantStream) => {
    // messages
    stream.on("textCreated", handleTextCreated);
    stream.on("textDelta", handleTextDelta);

    // image
    stream.on("imageFileDone", handleImageFileDone);

    // code interpreter
    stream.on("toolCallCreated", toolCallCreated);
    stream.on("toolCallDelta", toolCallDelta);

    // events without helpers yet (e.g. requires_action and run.done)
    stream.on("event", (event) => {
      if (event.event === "thread.run.requires_action")
        handleRequiresAction(event);
      if (event.event === "thread.run.completed") handleRunCompleted();
    });
  };

  /*
    =======================
    === Utility Helpers ===
    =======================
  */

  const appendToLastMessage = (text: any) => {
    setMessages((prevMessages: any) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      const updatedLastMessage = {
        ...lastMessage,
        text: lastMessage.text + text,
      };
      return [...prevMessages.slice(0, -1), updatedLastMessage];
    });
  };

  const appendMessage = (role: string, text: string) => {
    setMessages((prevMessages: any) => [...prevMessages, { role, text }]);
  };

  const annotateLastMessage = (annotations: any) => {
    setMessages((prevMessages: any) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      const updatedLastMessage = {
        ...lastMessage,
      };
      annotations.forEach((annotation: any) => {
        if (annotation.type === 'file_path') {
          updatedLastMessage.text = updatedLastMessage.text.replaceAll(
            annotation.text,
            `${API_ROOT}/api/files/${annotation.file_path.file_id}`
          );
        }
      })
      return [...prevMessages.slice(0, -1), updatedLastMessage];
    });
    
  }

  return (
    <div className={cn(styles.chatContainer, 'w-full')}>
      <div className={styles.messages}>
        {messages.map((msg: any, index: any) => (
          <Message key={index} role={msg.role} text={msg.text} />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="flex flex-col items-center px-4 w-full" >
			<form onSubmit={handleSubmit} className="w-full flex flex-col items-center" >
        <div className="overflow-y-hidden">
            <div className="-mb-3 pb-3 min-h-[62px] max-w-[400px] w-full flex flex-row items-start place-content-between border rounded-t-xl pt-1.5 shadow-lg shadow-stone-500/10 border-[#e4e4e4] backdrop-blur-sm bg-white/50" >
              <div className="flex flex-row gap-1.5 px-3 items-start w-full" >
                  <div className="items-center justify-start flex py-1.5 h-[34px] gap-1" >
                    <img alt="sparkle" className="w-4 h-4 text-[#5b5e66]" sizes="(max-width: 320px) 320px, (max-width: 640px) 640px, (max-width: 960px) 960px, (max-width: 1280px) 1280px, (max-width: 1920px) 1920px, 2560px" src="https://illustrations.dev/cdn-cgi/image/format=auto,fit=scale-down,width=1280/encrypted/img_MzM1QkNEQUQwQzgzQ0MxOTBFNzU2MDg2RDQyN0U5NkVEMzk1NzlCNzI3QzQxOTU5" srcSet="https://illustrations.dev/cdn-cgi/image/format=auto,fit=scale-down,width=320/encrypted/img_MzM1QkNEQUQwQzgzQ0MxOTBFNzU2MDg2RDQyN0U5NkVEMzk1NzlCNzI3QzQxOTU5 320w, https://illustrations.dev/cdn-cgi/image/format=auto,fit=scale-down,width=640/encrypted/img_MzM1QkNEQUQwQzgzQ0MxOTBFNzU2MDg2RDQyN0U5NkVEMzk1NzlCNzI3QzQxOTU5 640w, https://illustrations.dev/cdn-cgi/image/format=auto,fit=scale-down,width=960/encrypted/img_MzM1QkNEQUQwQzgzQ0MxOTBFNzU2MDg2RDQyN0U5NkVEMzk1NzlCNzI3QzQxOTU5 960w, https://illustrations.dev/cdn-cgi/image/format=auto,fit=scale-down,width=1280/encrypted/img_MzM1QkNEQUQwQzgzQ0MxOTBFNzU2MDg2RDQyN0U5NkVEMzk1NzlCNzI3QzQxOTU5 1280w, https://illustrations.dev/cdn-cgi/image/format=auto,fit=scale-down,width=1920/encrypted/img_MzM1QkNEQUQwQzgzQ0MxOTBFNzU2MDg2RDQyN0U5NkVEMzk1NzlCNzI3QzQxOTU5 1920w, https://illustrations.dev/cdn-cgi/image/format=auto,fit=scale-down,width=2560/encrypted/img_MzM1QkNEQUQwQzgzQ0MxOTBFNzU2MDg2RDQyN0U5NkVEMzk1NzlCNzI3QzQxOTU5 2560w" />
                    <span className="text-sm text-[#5b5e66]" >
                      Ask AI
                    </span>
                  </div>
                  <Separator className="ml-1.5" orientation="vertical" />
                  <AutoGrowTextArea className="reset resize-none h-full p-2 pt-1 border-0 flex-1 h-full w-full focus:outline-none focus:ring-0" 
                    value={userInput || ''}
                    onChange={
                      (value: string) => setUserInput(value)
                    } />
                </div>
                <button type="submit" className="h-fit p-2 bg-[#1e1f22] rounded-xl mr-2 border shrink-0 grow-0" >
                  <ArrowUp className="w-4 h-4 text-white" />
                </button>
              </div>
        </div>
     
      </form>
      
      
		</div>
    </div>
  );
};

export default Chat;
