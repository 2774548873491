import { cn } from "@/lib/utils";
import check from "@/vendors/check";

export default function UserAvatar({ userName, className, url }: { userName: string, className?: string, url?: string | null}) {
    return (
        <div 
            className={cn(
                "w-6 h-6 rounded-full shadow-inner border border-orange-600 justify-start items-start gap-2.5 inline-flex bg-gradient-to-b from-stone-100 to-stone-300 ",
                "flex flex-col items-center justify-center",
                "text-neutral-800 text-xs font-medium font-['Inter'] leading-3 px-[8px] py-[6px]",
                className,
            )}

            style={check.nonEmptyString(url) ? {
                backgroundImage: `url(${url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            } : {}}

        >
            {!check.nonEmptyString(url) && (
                <>
                    {check.nonEmptyString(userName) ? userName[0] : '•' }
                </>
            )}
        </div>
    );
}