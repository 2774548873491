import check from '@/vendors/check';

import tailwindDocs from './doc/tailwind-docs.trimmed.json';
import { twPrefixes, twWildcardPrefixes } from './doc/tailwind-prefixes';
import { cn } from '../utils';
import { ADDITIONAL_TW_CLASSES } from './constants';

const docByClass : { [className: string]: { css: string, doc: any } }  = {};
tailwindDocs.forEach(doc => {
    Object.keys(doc.classes).forEach((className: string) => {
        const c = className.split('>')[0].trim(); // Some classes may be suffixed with a '> * + *'. Maybe due to a scrapping error.
        docByClass[c] = {
            css: (doc.classes as unknown as { [name: string]: string })[className],
            doc,
        }
    })
});

const classesWithColor = Object.keys(docByClass).filter(c => c.includes('indigo-100'));
const tailwindColors = Object.keys(docByClass).filter(c => /^bg-[a-z]+-[0-9]{2,3}$/.test(c)).map(c => c.substring(3));
const tailwindColorsSet = new Set(tailwindColors);

const tailwindColorsValues : { [color: string]: string } = tailwindColors.reduce((acc, val) => ({
    ...acc,
    [val]: docByClass['bg-' + val]?.css?.split(':')[1]?.trim().replace(';', ''),
}), {});

const shadcnColorNames = [
    'border',
    'input',
    'ring',
    'background',
    'foreground',
    'primary',
    'primary-foreground',
    'secondary',
    'secondary-foreground',
    'destructive',
    'destructive-foreground',
    'muted',
    'muted-foreground',
    'accent',
    'accent-foreground',
    'popover',
    'popover-foreground',
    'card',
    'card-foreground',
    'variable-collection-grey',
    'variable-collection-indigo',
    'variable-collection-primary-BG',
    'variable-collection-primary-BG-duplicate',
    'variable-collection-secondary-BG',
];
const shadcnColors = shadcnColorNames.map(c => classesWithColor.map(cc => cc.replace('indigo-100', c))).flat();
const shadcnColorShades = shadcnColorNames.filter(c => !c.includes('-')).map(c => classesWithColor.map(cc => ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900', '950'].map(shade => cc.replace('indigo-100', `${c}-${shade}`))).flat()).flat();


const twSet = new Set([...Object.keys(docByClass), ...shadcnColors, ...shadcnColorShades]);
const twPrefixSet = new Set(twPrefixes);
const twBaseClasses = new Set(Array.from(twSet).filter(s => s.includes('-')).map(s => s.split('-').slice(0, -1).join('-')).filter(onlyUnique).sort());

function onlyUnique(value: any, index: number, array: any[]) {
    return array.indexOf(value) === index;
}

// Regex for tailwind classes
const twKeywordsWithSlash = Array.from(twSet).filter(s => s.includes('-')).map(s => s.split('-')[0]).filter(onlyUnique).sort();
const twKeywordsWithoutSlash = [...Array.from(ADDITIONAL_TW_CLASSES), ...Array.from(twSet).filter(s => !s.includes('-'))].filter(onlyUnique).sort();
const twRegex1 = `([A-Za-z0-9.;_\\*\\[\\]/'"\\(\\)%-]*:){0,1}-{0,1}(${twKeywordsWithSlash.join('|')})-[A-Za-z0-9.;_\\*\\[\\]/'"\\(\\)%-]{1,}`;
const twRegex2 = `([A-Za-z0-9.;_\\*\\[\\]/'"\\(\\)-]*:){0,1}-{0,1}${twKeywordsWithoutSlash.join('|')}(\\/[0-9A-Za-z]+)*`;

export { twRegex1, twRegex2 };

export function isTailwindColor(color: string) {
    return tailwindColorsSet.has(color);
}

export function getTailwindColorValue(color: string) {
    return tailwindColorsValues[color];
}

export function isTailwindPrefix(s: string) {
    if (!check.nonEmptyString(s)) {
        return false;
    }
    
    if (twPrefixSet.has(s)) {
        return true;
    }

    if (twWildcardPrefixes.some(p => p().test(s))) {
        return true;
    }

    return false;
}

export function isTailwindClass(s: string) : boolean {
    if (!check.nonEmptyString(s)) {
        return false;
    }

    if (s.trim().includes(':')) {
        return isTailwindPrefix(s.split(':')[0]) && isTailwindClass(s.split(':')[1]);
    }

    if (s.trim().startsWith('-')) {
        return isTailwindClass(s.trim().substring(1));
    }

    // Note: some classes may have a '/' in them, but it's not a suffix separator
    if (twSet.has(s.trim())) {
        return true;
    }

    // Note: some classes are not in docs for some reason
    if (ADDITIONAL_TW_CLASSES.has(s.trim())) {
        return true;
    }

    if (s.trim().includes('/')) {
        return isTailwindClass(s.trim().substring(0, s.trim().lastIndexOf('/')));
    }

    if (s.trim().endsWith(']') && s.split('-[').length === 2) {
        return twBaseClasses.has(s.split('-[')[0].trim());
    }

    return false;
}

function getNextArbitraryValue(s: string, direction : (1 | -1) = 1) {
    if (!check.nonEmptyString(s) || !check.nonEmptyString(s.trim())) {
        return s;
    }

    if (direction !== 1 && direction !== -1) {
        throw new Error('Direction must be 1 or -1');
    }

    // Regular expression to match the numeric part and the unit part
    const regex = /^(-?\d+(\.\d+)?)([a-zA-Z%]+)?$/;
    
    if (!regex.test(s)) {
        // TODO: we should handle more cases here
        return s;
    }
    const matches = s.match(regex);

    if (matches) {
        const numericPart = parseFloat(matches[1]);
        const unitPart = matches[3] || '';

        if (!isNaN(numericPart)) {
            const nDigits = Math.max(0, (numericPart.toString().split('.')[1] || '').length);
            const adjustedValue = (parseInt(numericPart.toString().replace('.', '')) + direction) / Math.pow(10, nDigits);
            return `${adjustedValue}${unitPart}`;
        }
    }

    // Return the original value if it cannot be parsed or adjusted
    return s;
}

export function isTailwindClassWithArbitraryValue(s: string) {
    if (s.trim().endsWith(']') && s.split('-[').length === 2) {
        const base = s.split('-[')[0].trim();
        return twBaseClasses.has(base);
    }
    return false;
}

// Index docs by base class to avoid slow searches later on
const docsByBase = new Map<string, string>();
Object.keys(docByClass).forEach(c => {
    const parts = c.split('-');
    const base = parts.slice(0, -1).join('-');
    if (!docsByBase.has(base)) {
        docsByBase.set(base, c);
    }
});
export function getDocByBase(base: string) {
    const className = docsByBase.get(base);
    if (!className) {
        return null;
    }
    return (docByClass[className] || {}).doc || null;
}

export function getArbitraryClassDoc(s: string) {
    if (!check.nonEmptyString(s)) {
        return null;
    }

    if (s.trim().endsWith(']') && s.split('-[').length === 2) {
        const base = s.split('-[')[0].trim();

        //////////////////////////////////////////////////
        // New: indexed search
        //////////////////////////////////////////////////
        const doc = getDocByBase(base);
        if (!doc) {
            return null;
        }
        const { css: similarCss, url, category, title, subtitle } = doc;

        // Tentatively replace the value in the css
        // FIXME: This is probably wrong in a lot of cases
        let css = null;
        if (
            check.nonEmptyString(similarCss) 
            && similarCss.split('\n').filter(s => check.nonEmptyString(s.trim())).length === 1
        ) {
            css = similarCss.split('/*')[0].split(':')[0] + ': ' + s.split('-[')[1].split(']')[0] + ';';
        }

        return { css, url, category, title, subtitle };
        //////////////////////////////////////////////////
        /*
        if (twBaseClasses.has(base)) {
            const similarClass = Object.keys(docByClass).find(c => c.startsWith(base + '-') && !c.substring(base.length + 1).includes('-'));
            if (similarClass) {
                const doc = getTailwindDoc(similarClass);
                if (!doc) {
                    return null;
                }
                const { css: similarCss, url, category, title, subtitle } = doc;

                // Tentatively replace the value in the css
                // FIXME: This is probably wrong in a lot of cases
                let css = null;
                if (
                    check.nonEmptyString(similarCss) 
                    && similarClass.split('\n').filter(s => check.nonEmptyString(s.trim())).length === 1
                ) {
                    css = similarCss.split('/*')[0].split(':')[0] + ': ' + s.split('-[')[1].split(']')[0] + ';';
                }

                return { css, url, category, title, subtitle };
            }
        }
        */
    }
    return null;
}

export function getNextTailwindClass(s: string, direction : (1 | -1) = 1): string {
    if (!check.nonEmptyString(s)) {
        return s;
    }

    if (s.includes(':')) {
        return s.trim().split(':')[0] + ':' + getNextTailwindClass(s.trim().split(':')[1], direction);
    }
    if (s.startsWith('-')) {
        return '-' + getNextTailwindClass(s.substring(1), (-direction) as (1 | -1));
    }
    if (s.includes('/') && !twSet.has(s)) {
        const base = s.substring(0, s.lastIndexOf('/'));
        const rest = s.substring(s.lastIndexOf('/') + 1);
        return getNextTailwindClass(base, direction) + '/' + rest;
    }

    // Handle arbitrary values
    if (s.trim().endsWith(']') && s.split('-[').length === 2) {
        const base = s.split('-[')[0].trim();
        if (!twBaseClasses.has(base)) {
            return s;
        }
        const value = s.split('-[')[1].trim().split(']')[0];
        return base + '-[' + getNextArbitraryValue(value, direction) + ']';
    }

    if (!docByClass[s]) {
        return s;
    }

    // Get all classes from the same family & beginning with the same prefix
    const familyClasses = Object.keys(docByClass[s].doc.classes);
    let prefix = s.includes('-') ? s.split('-').slice(0, -1).join('-') : s;
    if (familyClasses.some(fc => fc.startsWith(s + '-'))) {
        prefix = s;
    }
    const countDashes = (str: string) => (str.match(/-/g) || []).length;

    let classes = familyClasses.filter((c :string) => (
        c === prefix 
        || (
            c.startsWith(prefix + '-')
            && countDashes(c) <= (countDashes(prefix) + 1)
            && !familyClasses.some(fc => fc.startsWith(c + '-'))
        )
    ));
    if (!check.nonEmptyArray(classes) || classes.length === 1) {
        const familyClassesWithoutDescendants = familyClasses.filter((c :string) => (!c.includes('-') && !familyClasses.some(fc => fc.startsWith(c + '-'))));

        if (!s.includes('-') && (familyClassesWithoutDescendants.length > 1)) {
            // eg: next class after 'absolute' mais be 'fixed'
            classes = familyClassesWithoutDescendants;
        } else {
            return s;
        }   
    }
    
    let currentIndex = classes.indexOf(s);
    if (currentIndex === -1) {
        if (s.endsWith('-')) {
            currentIndex = 0;
        } else {
            return s;
        }
    }

    const out = classes[(currentIndex + direction + classes.length) % classes.length];
    return out;
}

export function getTailwindDoc(s: string): null | { css: string | null, url: string, category: string, title: string, subtitle: string } {
    if (!check.nonEmptyString(s)) {
        return null;
    }

    if (docByClass[s]) {
        const { css, doc: { url, category, title, subtitle } } = docByClass[s];
        return { css, url, category, title, subtitle };
    }

    if (s.includes(':')) {
        return getTailwindDoc(s.trim().split(':')[1]);
    }
    if (s.includes('/')) {
        const base = s.substring(0, s.lastIndexOf('/'));
        return getTailwindDoc(base);
    }

    if (isTailwindClassWithArbitraryValue(s)) {
        return getArbitraryClassDoc(s);
    }

    return null;
}

// Classes which impact the layout inside the component or the organization of the children
const INNER_CLASSES = `
accent
align-text
animate
appearance
aspect
auto-cols
auto-rows
bg
blur
brightness
caption
caret
col
col-end
col-span
col-start
columns
content
contrast
cursor
decoration
delay
diagonal
divide
duration
ease
ease-in
fill
fill-zinc
flex
flex-col
flex-row
flex-wrap
font
forced-color-adjust
from
gap
gap-x
gap-y
grayscale
grid-cols
grid-flow
grid-flow-col
grid-flow-row
grid-rows
hue-rotate
hyphens
indent
inline
invert
isolation
items
justify
justify-items
justify-self
leading
line
line-clamp
lining
list
list-image
max-h
max-w
min-h
min-w
object
object-left
object-right
object-scale
oldstyle
opacity
order
outline
overflow
overflow-x
overflow-y
p
pb
pe
pl
pointer-events
pr
proportional
ps
pt
px
py
resize
ring
ring-offset
rotate
saturate
scale
scale-x
scale-y
select
sepia
shrink
skew-x
skew-y
slashed
space-x
space-y
sr
stacked
stroke
subpixel
tabular
text
tracking
transition
translate-x
translate-y
underline-offset
via
w
whitespace
whitespace-break
whitespace-pre
will-change`.trim().split('\n');

const OUTER_CLASSES = `
align
basis
border
bottom
box
box-decoration
break
break-after
break-after-avoid
break-before
break-before-avoid
break-inside
break-inside-avoid
clear
end
float
grow
h
inset
inset-x
inset-y
left
origin
origin-bottom
origin-top
overflow
overscroll
overscroll-x
overscroll-y
place-content
place-items
place-self
right
rounded
row
row-end
row-span
row-start
self
shadow
size
start
table
table-column
table-footer
table-header
table-row
to
top
touch
touch-pan
touch-pinch
w
z
m
mb
me
ml
mr
ms
mt
mx
my`.trim().split('\n');

const BOTH_CLASSES = `
backdrop
backdrop-blur
backdrop-brightness
backdrop-contrast
backdrop-grayscale
backdrop-hue-rotate
backdrop-invert
backdrop-opacity
backdrop-saturate
backdrop-sepia
drop
drop-shadow
flow
mix-blend
mix-blend-color
mix-blend-hard
mix-blend-plus
mix-blend-soft
no
normal
not
not-sr
scroll
scroll-m
scroll-p
snap
snap-align`.trim().split('\n');

const CLASSIFIED_CLASSES : {[ className: string]: 'inner' | 'outer' | 'both'} = {};
INNER_CLASSES.forEach(c => CLASSIFIED_CLASSES[c] = 'inner');
OUTER_CLASSES.forEach(c => CLASSIFIED_CLASSES[c] = 'outer');
BOTH_CLASSES.forEach(c => CLASSIFIED_CLASSES[c] = 'both');
const allClassifiedClasses = Object.keys(CLASSIFIED_CLASSES).sort((a, b) => b.length - a.length);

export function getTwClassType(s: string) : 'inner' | 'outer' | 'both' | null {
    if (!check.nonEmptyString(s)) {
        return null;
    }

    if (s.includes(':')) {
        return getTwClassType(s.split(':')[1]);
    }
    if (s.includes('/')) {
        return getTwClassType(s.substring(0, s.lastIndexOf('/')));
    }

    const match = allClassifiedClasses.find(c => s.startsWith(c));
    return match ? CLASSIFIED_CLASSES[match] : null;
}

export function getInnerClasses(classes: any, includeBothAndUnknown = false) {
    return cn(classes).split(' ').filter(c => {
        const type = getTwClassType(c);
        if (includeBothAndUnknown) {
            return type === 'inner' || type === 'both' || type === null;
        } else {
            return type === 'inner';
        }
    });
}

export function getOuterClasses(classes: any, includeBothAndUnknown = false) {
    return cn(classes).split(' ').filter(c => {
        const type = getTwClassType(c);
        if (includeBothAndUnknown) {
            return type === 'outer' || type === 'both' || type === null;
        } else {
            return type === 'outer';
        }
    });
}
