import { useEffect, useRef, useState } from "react";
import { getInstantUrlForImageQuery, isImageLoaded, loadImage } from "./get-image-of";
import check from "../check";
import { GRAY_PIXEL } from "../gray-pixel";
import { cn } from "@/lib/utils";
import { useDebounce } from "use-debounce";
import { isUrlOrDataUrl } from "@/lib/parser/util";

function InternalDynamicImageOf({ alt, source: rawSource, query: rawQuery, className, ...props } : { alt: string, source: string, query: string, className?: string, [key: string]: any}) {
    // const currentImage = useRef('');
    const [[source, query]] = useDebounce([rawSource, rawQuery], rawSource === 'unsplash' ? 1000 : 250); // Unsplash API is rate-limited
    const [loaded, setLoaded] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const image = useRef<any>(null);

    if (!image.current) {
        image.current = new Image();
        image.current.onload = () => {
            setLoaded(true);
            setImageUrl(image.current.src);
        }
    }

    useEffect(() => {
        let url = GRAY_PIXEL;

        if (check.nonEmptyString(source) && check.nonEmptyString(query)) {
            const instantUrl = getInstantUrlForImageQuery(source, query);
            if (instantUrl) {
                url = instantUrl;
            }
        }
        setLoaded(false);
        image.current.loaded = false;
        image.current.src = url;
    }, [source, query]);

    if (!loaded || !imageUrl) {
        return <div className={cn('rounded-lg size-20 bg-slate-400 animate-pulse', className)} {...props} />;
    }

    return <img alt={alt || query} src={imageUrl} className={className} {...props} />;


    // const defaultUrl = getInstantUrlForImageQuery(source, query);
    // const [imageLoaded, setImageLoaded] = useState<boolean>(isImageLoaded(source, query));
    // const [imageUrl, setImageUrl] = useState<string | null>(defaultUrl);
    

    /*
    useEffect(() => {
        // Abort if query or source is empty
        if (!check.nonEmptyString(source) || !check.nonEmptyString(query)) {
            setImageUrl(null);
            return;
        }

        currentImage.current = `${source}-${query}`;
        setImageLoaded(isImageLoaded(source, query));

        // Set the default URL
        setImageUrl(getInstantUrlForImageQuery(source, query));

        // Load the image and set it as loaded 
        // const abortController = new AbortController();
        // const abortSignal = abortController.signal;

        loadImage(source, query)
            .then((url) => {
                // Make sure we're still rendering the same image before modifying state
                if (currentImage.current !== `${source}-${query}`) {
                    return;
                }

                // If URL is null, remove isLoaded
                if (!url) {
                    setImageLoaded(false);
                    return;
                }

                // Else, set image URL appropriately
                setImageUrl(url); // At this point, this is the production URL
                setImageLoaded(true);
            })
            .catch((err) => {
                console.log('Error loading image', err);

                // Make sure we're still rendering the same image before modifying state
                if (currentImage.current !== `${source}-${query}`) {
                    return;
                }
                setImageLoaded(false);
            });
    }, [source, query]);
    */

    /*
    if (!imageLoaded || !imageUrl) {
        return <div className={cn('rounded-lg size-20 bg-slate-200 animate-pulse', className)} {...props} />;
    }
    */

    /*
    const url = getInstantUrlForImageQuery(source, query);
    return <img alt={alt || query} src={url || GRAY_PIXEL} className={cn(!url && 'animate-pulse size-20 bg-slate-200' , className)} {...props} />;
    */
}

// FIXME: Merge with InternalDynamicImageOf or ImageOf ?
export function DynamicImageOf({ value: rawValue, alt, queryTemplate, variant, ...props }: { 
    alt?: string,
    value?: any,
    queryTemplate?: string,
    variant?: string,
}) {
    const value = check.nonEmptyString(rawValue) ? rawValue.trim() : null;
    if (!value) {
        return null;
    }
    if (isUrlOrDataUrl(value)) {
        let parsedVal = value;
        // Temp: fix legacy illustrations.dev urls
        if (parsedVal.startsWith('https://🤖🎨.ws/')) {
            parsedVal = parsedVal.replace('https://🤖🎨.ws/', 'https://illustrations.dev/');
        }
        return (
            <ImageOf
                {...{ alt, src: parsedVal }}
                {...props}
            />
        );
    }

    let of : string | null = value || null;
    if(check.nonEmptyString(queryTemplate) && queryTemplate.includes('{{value}}')) {
        of = queryTemplate.replace('{{value}}', value);
    }

    return (
        <ImageOf
            {...{ 
                alt: alt || value, 
                of,
                variant,
            }}
            {...props}
        />
    );
}

export function ImageOf({ alt, src, of, variant, defaultVariant,  ...props }: { alt?: string, of?: string, variant?: string, defaultVariant?: string, src?: string }) {
    const source = variant || defaultVariant || 'lexica';
    const query = of;

    if (check.nonEmptyString(src)) {
        return (<img alt={alt || of} src={src} {...props} />);
    }

    if (!check.nonEmptyString(source) || !check.nonEmptyString(query)) {
        return null;
    }

    const imageLoaded = isImageLoaded(source, query);
    if (!imageLoaded) {
        return InternalDynamicImageOf({ alt: alt || '', source, query, ...props });
    }

    const imageUrl = getInstantUrlForImageQuery(source, query);
    if (!imageUrl) {
        return null;
    }

    return (<img alt={alt || of} src={imageUrl} {...props} />);
}