import { useCallback, useEffect, useState } from "react";
import { DataSourceBinding } from "../data/data";
import { useMongoLiveCollection } from "./hooks/useMongoLiveCollection";
import { DataListChangeEvent } from "../data/data-engine";
import check from "check-types";

const $ = '$'; // Symbol.for('$');

export function MongoDataSource({ bind, source, opts} : { bind: string, source: string, opts?: { filterKey?: string, filter?: any, page?: number, perPage?: number } }) {
    const [internalData, setInternalData] = useState<any[]>([]); 
    const [lastWrite, setLastWrite] = useState<any>(0);
    
    const  {
        filterKey, 
        filter,
        page = 0,
        perPage = 25,
    } = opts || {};

    const { 
        data,
        loading,
        error,
        writeHandlers,
    } = useMongoLiveCollection(source, {
        filterKey, 
        filter,
        page,
        perPage,
    });

    /*
    (data as any)[$] = {
        loading,
        error,
    };
    */
   useEffect(() => { 
    const updateData = () => {
        if (data !== internalData && JSON.stringify(data) !== JSON.stringify(internalData)) {
            if (!check.nonEmptyArray(data) ){
                console.log('RECEIVED EMPTY DATA')
            } else {
                console.log('RECEIVED NON EMPTY DATA')
            }
            setInternalData(data);
        }
    }
    
    // Delay the update if we've just performed a write
    // to avoid race conditions and loops
    if ((Date.now() - lastWrite) < 500) {
        setTimeout(updateData, 500);
    } else {
        updateData();
    }

    
   }, [bind, data, internalData, lastWrite]);

    if (loading) {
        console.log('MongoDataSource: LOADING data for var "'+bind+'": ', data);
    }

    const onChange = useCallback(({ op, items }: DataListChangeEvent) => {
        console.log('MongoDataSource: DATA CHANGE "'+bind+'": ' + JSON.stringify({op, items}, null, 4));

        const { create, update, remove, clear } = writeHandlers || {};

        if (!check.function(create) || !check.function(update) || !check.function(remove) || !check.function(clear)) {
            return;
        }

        let promise : any = Promise.resolve();

        setLastWrite(Date.now());

        if (op === 'push') {
            promise = Promise.all(items.map(item => create(item)));
        } else if (op === 'update') {
            promise = Promise.all(items.map(item => update(item)));
        } else if (op === 'remove') {
            promise = Promise.all(items.map(item => remove(item._id)));
        }
        else if (op === 'clear') {
            promise = clear();
        }

        promise
            .then(() => {
                console.log('MongoDataSource: DATA CHANGE "'+bind+'": SUCCESS');
            })
            .catch((err: any) => {
                console.error('MongoDataSource: DATA CHANGE "'+bind+'": ERROR', err);
            });

        //setOpts(newData);
    }, [bind, writeHandlers]);

    return <DataSourceBinding bind={bind} value={internalData} onChange={onChange} />;
}