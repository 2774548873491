"use client"

import * as React from "react"
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/shadcn/ui/table"
import { nonEmptyTrimmed } from "@/lib/utils"
import check from "@/vendors/check"
import { ComponentParser, ComponentTreeNode } from "../../component.type"
import { ComponentAliasConfig, childrenParsers, imports, isTextComponent, normalizeTextComponent, setup } from "../_utils"
import { groupAdjacentComponentsOfTypes, removeFirstOccurenceOfTextFromChildren } from "../../parsing-pipeline"
import upperlower from "upperlower";
import split from 'string-split-by';
import { bulletProofJSONParse } from "@/vendors/bulletproof-json"
import { getSingleVarNameFromText } from "@/lib/parser/util"
import { registerShadcnComponent } from "@/lib/parser/importsRegistry"
import { DataDisplay } from "@/vendors/data/data-v2"
import { DynamicTable } from "@/components/shadcn/ui/dynamic-table"


const attemptJsonParsing = (line: string) => {
  if (nonEmptyTrimmed(line)) {
    try {
      let val = bulletProofJSONParse(line);
      if (check.nonEmptyObject(val)) {
        return val;
      }
      if (check.nonEmptyArray(val)) {
        return val;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  return line;
}

const parseAllValidJsonLines = (lines: string[]) => {
  return lines.map(attemptJsonParsing);
}

const parseAllValidCSVLines = (lines: any[]) => {
  const validLines = lines.filter(nonEmptyTrimmed);
  const delimiter = findBestDelimiter(validLines);
  const parsedLines = validLines.map(l => split(l, delimiter));

  return parsedLines.filter(l => !!l);
}

const isValidJsonLine = (line: string) => {
  if (!check.nonEmptyString(line)) {
    return false;
  }
  const trimmed = line.trim();
  const couldBeObject = trimmed[0] === '{' && trimmed[trimmed.length - 1] === '}';
  const couldBeArray = trimmed[0] === '[' && trimmed[trimmed.length - 1] === ']';
  if (couldBeObject || couldBeArray) {
    try {
      const out = bulletProofJSONParse(trimmed);
      return Object.values(out).length > 0;
    } catch(error) {
      return false;
    }
  }

  return false;
}

const DELIMITERS = [',', ';', '|', '\t', ' '];

function findBestDelimiter(lines: string[]) {
  let delimiters = DELIMITERS.filter(d => lines.every(l => l.includes(d)));
  if (delimiters.length === 0) {
    delimiters = DELIMITERS.filter(d => lines.some(l => l.includes(d)));
    if (delimiters.length === 0) {
      return '|';
    }
  }

  // For each delimiter, determine the most common number of fields between lines when lines are split by the delimiter
  // Exclude lines with less than 2 fields
  // Return the delimiter with the highest number of lines having the same number of fields
   // Map to store delimiter effectiveness
   let delimiterScores :{[d:string]: number} = {};

   delimiters.forEach(delimiter => {
     let fieldCounts: {[n:number]: number} = {}; // Track counts of the number of fields for this delimiter
 
     lines.forEach(line => {
       const fields = split(line, delimiter).length;
       if (fields > 1) { // Exclude lines with less than 2 fields
         if (!fieldCounts[fields]) {
           fieldCounts[fields] = 1;
         } else {
           fieldCounts[fields]++;
         }
       }
     });
 
     // Find the most common number of fields for this delimiter
     const mostCommonFieldCount = Object.entries(fieldCounts).reduce((acc: any, curr) => {
       return curr[1] > acc[1] ? curr : acc;
     }, [0, 0]);
 
     delimiterScores[delimiter] = mostCommonFieldCount[1]; // Use the count of lines with the most common field count
   });
 
   // Select the delimiter with the highest score
   const bestDelimiter = Object.entries(delimiterScores).reduce((acc, curr) => {
     return curr[1] > acc[1] ? curr : acc;
   }, ['', 0])[0];
 
   return bestDelimiter;
}

// FIXME: Very simple parsing utility. Should add a few things:
// - Auto-detect column types
// - Auto-detect column names if provided
const parseLines = (lines: string[]) => {
  if (!check.nonEmptyArray(lines)) {
    return [];
  }

  if (lines.some(isValidJsonLine)) {
    return parseAllValidJsonLines(lines);
  }
  
  return parseAllValidCSVLines(lines);
}

const lineValuesFromObject = (line: { [key:string]: any } | any[], columns: string[]) : any[] | null => {
  if (!check.nonEmptyObject(line) && !check.nonEmptyArray(line)) {
    return null;
  }
  if (!check.nonEmptyArray(columns)) {
    return null;
  }
  if (!columns.some(c => !!(line as any)[c])) {
    return null;
  }

  return columns.map(column => (line as any)[column] !== undefined ? (line as any)[column] : '');
}

const buildTableLineFromObject = (line: { [key:string]: any }, columns: string[]) : ComponentTreeNode | null => {
  const values = lineValuesFromObject(line, columns);
  if (!check.nonEmptyArray(values)) {
    return null;
  }

  return {
    component: TableRow,
    htmlComponent: 'TableRow',
    classes: [],
    props: {},
    children: values.map(value => ({
      component: TableCell,
      htmlComponent: 'TableCell',
      classes: [],
      props: {},
      children: [value + ''],
    })),
  };
}

const buildTableBodyFromLines = (lines: any[], columns: string[]) : ComponentTreeNode => {
  const tableLines: ComponentTreeNode[] = [];
  lines.forEach((line, index) => {
    if (check.nonEmptyObject(line) || check.nonEmptyArray(line)) {
      const tableLine = buildTableLineFromObject(line, columns);
      if (tableLine) {
        tableLines.push(tableLine);
      }
    }
  });

  return {
    component: TableBody,
    htmlComponent: 'TableBody',
    classes: [],
    props: {},
    children: tableLines,
  };
}

function buildTableHeadFromColumnNames(columns: string[]) : ComponentTreeNode | null {
  if (!check.nonEmptyArray(columns)) {
    return null;
  }
  return {
    component: TableHeader,
    htmlComponent: 'TableHeader',
    classes: [],
    props: {},
    children: [
      {
        component: TableRow,
        htmlComponent: 'TableRow',
        classes: [],
        props: {},
        children: columns.map(column => ({
          component: TableHead,
          htmlComponent: 'TableHead',
          classes: [],
          props: {},
          children: [upperlower(upperlower(column + '', 'kebapcase').replaceAll('-', ' '), 'titlecase')],
        })),
      },
    ],
  };
}

const parseDynamicTable = (c: ComponentTreeNode) : ComponentTreeNode | null => {
  if (!check.nonEmptyString(c.text)) {
    return null;
  }

  const varName = getSingleVarNameFromText(c.text?.trim());
  if (!varName) {
    return null;
  }

  const otherText = c.text.replace(varName, '').trim();
  const columnNames = otherText.split('|').map(col => col.trim()).filter(check.nonEmptyString);
  const otherChildren = (c?.children || []).filter(child => !!child && !isTextComponent(child) && [TableCaption, TableFooter].some((type:any) => child.component === type));

  // FIXME: Handle existing header inside the table
  return  {
    component: DataDisplay as any,
    htmlComponent: 'DataDisplay',
    classes: [],
    props: { bind: varName, type: 'object[]' },
    children: [{
      ...c,
      component: DynamicTable,
      htmlComponent: 'DynamicTable',
      props: check.nonEmptyArray(columnNames) ? { columnKeys: columnNames } : {},
      children: otherChildren,
    }],
  };
}

function groupTableCellsInRows(c: ComponentTreeNode) : ComponentTreeNode {
  if (!check.nonEmptyObject(c)) {
    return c;
  }

  if (c.component === TableRow) {
    return c;
  }

  if (![TableBody, TableHead, TableFooter].some(t => c.component === t)) {
    return {
      ...c, 
      children: (c.children || []).map(groupTableCellsInRows),
    }
  }

  return {
    ...c,
    children: groupAdjacentComponentsOfTypes([TableCell], TableRow, 'TableRow')(c.children || []),
  };
}

const TAG = 'table';

const ALIASES : ComponentAliasConfig[] = [
  ['body', TableBody, 'TableBody'],
  ['caption', TableCaption, 'TableCaption'],
  ['footer', TableFooter, 'TableFooter'],
  ['head', TableHead, 'TableHead'],
  ['header', TableHeader, 'TableHeader'],
  ['row', TableRow, 'TableRow'],
  ['cell', TableCell, 'TableCell'],
];

const TableComponentParser : ComponentParser = {
  name: 'Table',
  description: 'A responsive table component.',
  tags: [TAG],

  refImplementation: `
/table 2020-08 | 2020-09 | 2020-10 | 2020-11 | 2020-12
  GMV	0	39,34	5874,48	6479,9	13462,5
  COGS (tickets cost)	0	-33	-5224	-5751	-11946
  Purchase fee	0	0	0	0	0
  Payment fee	0	-2,92	-296,35	-332,14	-692,94
  Service fee	0	3,42	355,33	397,92	826,57
  /caption "This is a table parsing CSV lines"
  
/table invoice | status | amount
  { invoice: 'INV001', status: 'Paid', method: 'Credit Card', amount: "$250.00" }
  { invoice: 'INV002', status: 'Pending', method: 'PayPal', amount: "$150.00" }
  { invoice: 'INV003', status: 'Unpaid', method: 'Bank Transfer', amount: "$350.00" }
  { invoice: 'INV004', status: 'Paid', method: 'Credit Card', amount: "$450.00" }
  { invoice: 'INV005', status: 'Paid', method: 'PayPal', amount: "$550.00" }
  { invoice: 'INV006', status: 'Pending', method: 'Bank Transfer', amount: "$200.00" }
  { invoice: 'INV007', status: 'Unpaid', method: 'Credit Card', amount: "$300.00" }
  /caption 
    "This is a table parsing JSONL content with selected columns"
  /footer 
    /row 
      /cell @colSpan=3 "Table footer here" w-full text-center
`.trim(),

  childrenParsers: childrenParsers(ALIASES, TAG),

  
  parseTree: (c: ComponentTreeNode) : ComponentTreeNode => {
    // If table is bound to a variable, parse as dynamic table
    if (check.nonEmptyString(c.text) && check.nonEmptyString(getSingleVarNameFromText(c.text))) {
      console.log('<TABLE> Dynamic table detected. Text = ', c.text);
      return parseDynamicTable(c) || { component: React.Fragment, htmlComponent: 'Fragment', children: [], props: {}, classes: [] };
    }

    

    // Parse text lines to get indicaton of columns
    let children = removeFirstOccurenceOfTextFromChildren(c.text)(c.children || []);
    let textLines = children.map(normalizeTextComponent).filter(t => !!t).map((t) => (t as any).text);
    console.log('<TABLE> children', children);
    console.log('<TABLE> textLines', textLines);

    let columnNames = [];
    // Get column names from text
    if (check.nonEmptyString(c.text) && nonEmptyTrimmed(c.text) && c.text?.includes('|')) {
      columnNames = c.text.split('|').map((col:string) => col.trim());
    }
    // Detect --- | --- | --- pattern
    if (textLines.length > 2 && textLines[1].includes('|') && textLines[1].split('|').map((col:string) => col.trim()).every((col:string) => /^:{0,1}-{2,}:{0,1}$/.test(col))) {
      columnNames = textLines[0].split('|').map((col:string) => col.trim());
      textLines = textLines.slice(2);
    }
    // If no columns, parse all lines and check if they are valid JSON
    const parsedLines = parseLines(textLines);
    if (check.nonEmptyArray(parsedLines) && !check.nonEmptyArray(columnNames)) {
      const firstLine = parsedLines[0];
      if (check.nonEmptyObject(firstLine)) {
        columnNames = Object.keys(firstLine);
      } else if (check.nonEmptyArray(firstLine)) {
        columnNames = firstLine.map((_, index) => index);
      }
    }

    // Build table body from parsed lines if any
    let bodyFromText = null;
    if (check.nonEmptyArray(parsedLines)) {
      let columns = [];
      if (check.nonEmptyObject(parsedLines[0])) {
        columns = columnNames;
      }
      if (check.nonEmptyArray(parsedLines[0])) {
        columns = columnNames.map((col: any, index: number) => index);
      }

      bodyFromText = buildTableBodyFromLines(parsedLines, columns);
    }

    const header = c.children?.find(child => child.component === TableHeader) || buildTableHeadFromColumnNames(columnNames) || null;
    let otherChildren = (c?.children || []).filter(child => !!child && !isTextComponent(child) && child?.component !== TableHeader);

    let out : ComponentTreeNode = {
      ...c,
      component: Table,
      htmlComponent: 'Table',
      classes: [],
      props: {},
      children: [
        header,
        bodyFromText || null,
        ...otherChildren,
      ].filter(c => !!c),
    };

    // Make sure TableCells are always wrapped in a TableRow
    out = groupTableCellsInRows(out);
    return out;
  },

  imports: [
    ...imports(['Table', 'TableBody', 'TableCaption', 'TableFooter', 'TableHead', 'TableHeader', 'TableRow', 'TableCell'], TAG),
    ...imports(['DynamicTable'], 'dynamic-table'),
  ],
  setup: [
    ...setup(TAG),
    ...setup('dynamic-table'),
  ],
  variants: [],
};

registerShadcnComponent({
        Table,
        TableBody,
        TableCaption,
        TableCell,
        TableFooter,
        TableHead,
        TableHeader,
        TableRow,
    },
    TAG,
);
registerShadcnComponent({
        DynamicTable,
    },
    'dynamic-table',
);

export default TableComponentParser;