import baseComponents from './base';
import { ComponentParser } from './component.type';
import shadcnComponents from './shadcn';
import { onlyUnique } from '@/lib/utils';
import ucfirst from "ucfirst";
import { iconForTags } from '../doc/icons/icons';
import MoleculeComponentParser from './molecules/MoleculeComponentParser';
import layoutsPatternsComponents from './patterns';
import { htmlComponentParsers } from './html';
import categories from './categories.json';

const components : ComponentParser[] = [
    // HTML components
    ...htmlComponentParsers,

    // Custom components
    ...(baseComponents as ComponentParser[]),
    ...(shadcnComponents.map(c => ({
        ...c,
        description: c.description || `The Shadcn/ui <${c.name || ucfirst(c.tags[0])}> component.`,
    }))),
    ...(layoutsPatternsComponents),

    MoleculeComponentParser, 
].map(c => ({
    ...c,
    icon: iconForTags(c.tags || []),

    // Add category to components which don't have one
    category: c.category || (categories as Record<string, string>)[c.tags[0]] || undefined,
}));

const htmlComponents = htmlComponentParsers.map(c => c.tags[0]);
console.log('HTML COMPONENTS: ', JSON.stringify(htmlComponents, null, 2));

const allComponentParsers = components;

// const sortedComps = components.sort((a, b) => a.tags[0].localeCompare(b.tags[0]));
// const data = sortedComps.map(c => [c.tags[0], nonEmptyTrimmed(c.refImplementation)].join('\t')).join('\n');
// console.log('COMPONENTS: \n' + data);

export function makeReadOnly<T extends object>(name: string, obj: T): Readonly<T> {
    function getStackTrace(): string {
        const stack = new Error().stack || '';
        // Remove the first line (Error message) and join the rest
        return stack.split('\n').slice(1).join('\n');
    }

    return new Proxy(obj, {
      set(target, prop, value) {
        // localStorage.setItem('READONLY_ERR::'+uniqid(), `Cannot set property ${String(prop)} - object <${name}> is read-only. Stack:\n${getStackTrace()}`);
        throw new Error(`Cannot set property ${String(prop)} - object <${name}> is read-only. Stack:\n${getStackTrace()}`);
      },
      deleteProperty(target, prop) {
        // localStorage.setItem('READONLY_ERR::'+uniqid(), `Cannot delete property ${String(prop)} - object <${name}> is read-only. Stack:\n${getStackTrace()}`);
        throw new Error(`Cannot delete property ${String(prop)} - object <${name}> is read-only. Stack:\n${getStackTrace()}`);
      },
      defineProperty(target, prop, descriptor) {
        // localStorage.setItem('READONLY_ERR::'+uniqid(), `Cannot define property ${String(prop)} - object <${name}> is read-only. Stack:\n${getStackTrace()}`);
        throw new Error(`Cannot define property ${String(prop)} - object <${name}> is read-only. Stack:\n${getStackTrace()}`);
      },
    });
}

const makeComponentParserReadOnly = (c: ComponentParser)  : ComponentParser => (
    {
        ...c,
        tags: makeReadOnly('TagsArray', c.tags) as string[],
        childrenParsers: makeReadOnly('ChildrenParsers', (c.childrenParsers || []).map((cp: ComponentParser) => (
            makeComponentParserReadOnly(cp)
        ))) as ComponentParser[],
    }
);

const componentsByTag = makeReadOnly('ComponentsByTag', components.reduce((acc, c) => {
    c.tags.forEach(tag => {
        acc[tag.trim().toLowerCase()] = makeComponentParserReadOnly(c);
    });
    return acc;
}, {} as { [key: string]: ComponentParser }));

let _sortedTags : string[];

function sortedTags() {
    if (!_sortedTags) {
        _sortedTags = Object.keys(componentsByTag).sort();
    }
    return _sortedTags
};

const onlyFirstTags = Object.keys(componentsByTag).filter(key => componentsByTag[key].tags[0] === key).filter(onlyUnique).sort();

console.log('ALL COMPONENTS, no HTML: ', JSON.stringify(onlyFirstTags.filter(tag => componentsByTag[tag]?.category !== 'html'), null, 2));

export {
    allComponentParsers,
    componentsByTag,
    sortedTags,
    onlyFirstTags,
}