import useFirebaseValue from "@/vendors/firebase/useFirebase";
import { pageDocUrl, pagePublishQueueUrl } from "@/lib/dbRoutes";
import check from "@/vendors/check";
import { Navigate, useParams } from "react-router-dom";
import { BookOpenIcon } from "lucide-react";
import { DefaultCheatSheet } from "@/components/doc/DefaultCheatSheet";
import { ComponentDoc } from "@/components/doc/ComponentDoc";
import useClerkAuthedFirebase from "@/vendors/firebase/useClerkAuthedFirebase";

function DocPlaceholder() {
    return (
        <div className="w-screen h-screen bg-slate-100 flex items-center justify-center">
            <BookOpenIcon className="w-20 h-20 text-slate-300" />
        </div>
    );
}

export function DocumentationPage() {
    const { pageId } = useParams();
    const uid = useClerkAuthedFirebase();
    const { value: remoteDocUrl } = useFirebaseValue<string | null>(pageDocUrl(pageId), null, true);
    const { push: pushSnippet } = useFirebaseValue<string | null>(pagePublishQueueUrl(pageId, uid), null);

    if (!check.nonEmptyString(pageId)) {
        return <Navigate to="/" />;
    }

    if (!check.nonEmptyString(remoteDocUrl)) {
        return (<DefaultCheatSheet />);
        /*
        return (
            <DocPlaceholder />
        );
        */
    }

    if (remoteDocUrl.startsWith('local:')) {
        const localPath = remoteDocUrl.substr('local:'.length);
        let Component = null;

        if (localPath.startsWith('/component/')) {
            const componentTag = localPath.substr('/component/'.length);
            Component = () => <ComponentDoc componentTag={componentTag} pushSnippet={pushSnippet} />;
        } else {
            switch(localPath) {
                case '/default':
                    Component = DefaultCheatSheet;
                    break;
    
                default:
                    Component = DocPlaceholder;
                    break;
            }
        }

        return <Component />;
    }

    return (
        <iframe title={pageId} src={remoteDocUrl} className="w-screen h-screen" />
    );
}