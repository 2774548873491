import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "@/lib/parser/components/layouts-ui/alert-dialog";
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { nonEmptyTrimmed } from "@/lib/utils";
import { childrenParsers, ComponentAliasConfig, imports, isTextComponent, parentWrapperForTriggerableComponent, parseCardLikeComponent, radixComponentDoc, setup } from "../_utils";
import { ComponentType } from "react";
import check from "@/vendors/check";
import { withTextAsChildOfType, withTextAsFirstChild } from "@/lib/parser/util";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
  
/*
export function AlertDialogDemo() {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="outline">Show Dialog</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete your
            account and remove your data from our servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction>Continue</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
*/

const InnerContent : ComponentType<any> = ({ children }) => {
  return <>{children}</>;
}

const parserWithAutoAsChildProp = (component: ComponentType<any>, htmlComponent: string) => {
  return (c: ComponentTreeNode) => {
    return withTextAsFirstChild({
      ...c,
      props: {
        asChild: check.nonEmptyArray(c.children) && c.children.some((child: ComponentTreeNode) => !isTextComponent(child)),
        ...c.props,
      },
      component,
      htmlComponent,
    });
  };
}

const TAG = 'alert-dialog';
const TAGS = [TAG];
const ALIASES : ComponentAliasConfig[] = [
    ['header', AlertDialogHeader, 'AlertDialogHeader', undefined, (c: ComponentTreeNode) => {
      return withTextAsChildOfType(AlertDialogTitle, 'AlertDialogTitle')({
        ...c,
        component: AlertDialogHeader,
        htmlComponent: 'AlertDialogHeader',
      });
    }],
    ['content', InnerContent, 'AlertDialogInnerContent'],
    ['footer', AlertDialogFooter, 'AlertDialogFooter'],
    [['close', 'cancel'], AlertDialogCancel, 'AlertDialogCancel', radixComponentDoc('AlertDialog.Cancel'), parserWithAutoAsChildProp(AlertDialogCancel, 'AlertDialogCancel')],
    ['action', AlertDialogAction, 'AlertDialogAction', radixComponentDoc('AlertDialog.Action'), parserWithAutoAsChildProp(AlertDialogAction, 'AlertDialogAction')],
    ['title', AlertDialogTitle, 'AlertDialogTitle', radixComponentDoc('AlertDialog.Title')],
    ['description', AlertDialogDescription, 'AlertDialogDescription', radixComponentDoc('AlertDialog.Description')],
];



const AlertDialogComponentParser : ComponentParser = {
  name: 'Alert Dialog',
  description: 'A modal dialog that interrupts the user with important content and expects a response',
  tags: [TAG],

  childrenParsers: childrenParsers(ALIASES, TAGS),
  parseTree: (c: ComponentTreeNode) : ComponentTreeNode => {
    const parsed = parseCardLikeComponent(c, {
      component: [AlertDialogContent, 'AlertDialogContent'],
      header: [AlertDialogHeader, 'AlertDialogHeader'],
      content: [InnerContent, 'AlertDialogInnerContent'],
      footer: [AlertDialogFooter, 'AlertDialogFooter'],
      title: [AlertDialogTitle, 'AlertDialogTitle'],
      description: [AlertDialogDescription, 'AlertDialogDescription'],
    });

    // Filter out InnerContent from children
    parsed.children = (parsed.children || []).map(child => {
      if (child.component === InnerContent) {
        if (nonEmptyTrimmed(child.props?.className) || (check.nonEmptyArray(child.classes) && child.classes.some(nonEmptyTrimmed))) {
          return {
            ...child,
            component: 'div',
            htmlComponent: 'div',
          };
        }
        
        return child.children || [];
      }
      return child;
    }).flat();

    return parsed;
  },

  wrapParent: parentWrapperForTriggerableComponent(
    [AlertDialog, 'AlertDialog'], 
    [AlertDialogTrigger, 'AlertDialogTrigger'], 
    [AlertDialogContent, 'AlertDialogContent'],
  ),

  imports: imports(['AlertDialog', 'AlertDialogAction', 'AlertDialogCancel', 'AlertDialogContent', 'AlertDialogDescription', 'AlertDialogFooter', 'AlertDialogHeader', 'AlertDialogTitle', 'AlertDialogTrigger'], 'alert-dialog'),
  setup: setup(TAG),
  variants: [
  ],

  doc: {
      props: radixComponentDoc('AlertDialog.Root').props,
  },

  refImplementation: `
/button %outline Show Dialog
  /alert-dialog
    /header
      /title Are you absolutely sure?
      /description This action cannot be undone. This will permanently delete your account and remove your data from our servers.
    /footer
      /cancel Cancel
      /action Continue
    `.trim().replaceAll(/ {2}/g, '\t'),
};

registerShadcnComponent({
        AlertDialog,
        AlertDialogAction,
        AlertDialogCancel,
        AlertDialogContent,
        AlertDialogDescription,
        AlertDialogFooter,
        AlertDialogHeader,
        AlertDialogTitle,
        AlertDialogTrigger,
    },
    TAG,
);

export default AlertDialogComponentParser;