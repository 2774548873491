import check from "@/vendors/check";
import { useCallback, useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import { sha1 } from "object-hash";
import 'isomorphic-fetch';


const apiUrl = 'https://api-mockup.layouts.dev/api';


function getCachedData(apiDescription: string) {
    try {
        const cacheKey = sha1(apiDescription);
        const cachedData = localStorage.getItem('mockapi::' + cacheKey);
        if (check.nonEmptyString(cachedData)) {
            return JSON.parse(cachedData);
        }
    } catch(err: any) {
        console.error(err)
    }

    return null;
}

function setCachedData(apiDescription: string, data: any) {
    try {
        const cacheKey = sha1(apiDescription);
        localStorage.setItem(cacheKey, JSON.stringify(data));
    } catch(err: any) {
        console.error(err)
    }
}

export function useApiMockupQuery(apiDescription: string) {
    const cachedData = getCachedData(apiDescription);
    const [ data, setDataRaw ] = useState(cachedData);
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);
    const [ opts, setOpts ] = useState({ limit: 10, skip: 0, filter: null, filterKey: null });
    const setPage = useMemo(() => (page: number, itemsPerpage?: number) => {
        setOpts((opts) => ({ ...opts, skip: page * (itemsPerpage || opts.limit), limit: itemsPerpage || opts.limit }));
    }, []);

    const setData = useCallback((data: any) => {
        setDataRaw(data);
        setCachedData(apiDescription, data);
    }, [apiDescription]);

    useEffect(() => {
        if(!check.nonEmptyString(apiDescription)) {
            return;
        }

        const cachedData = getCachedData(apiDescription);
        if (cachedData) {
            setData(cachedData);
            setLoading(false);
            setError(null);
        }

        let cancelled = false;
        const fetchData = async () => {
            try {
                const { limit = 10, skip = 0, filter = null, filterKey = null } = opts;
                setLoading(true);
                const response = await fetch(`${apiUrl}/?${
                    queryString.stringify({
                        description: apiDescription,
                        limit,
                        skip,
                        filter: filter || undefined,
                        filterKey: filterKey || undefined,
                    })
                }`);
                const json = await response.json();

                if(cancelled) {
                    return;
                }
                setData(json);
                setError(null);
            } catch (err: any) {
                if(cancelled) {
                    return;
                }
                setError(err.message);
                setData([]);
            } finally {
                if(cancelled) {
                    return;
                }
                setLoading(false);
            }
        };

        fetchData().catch(console.error);

        // Return cancel handler
        return () => {
            cancelled = true;
        };
    }, [apiDescription, opts, setData]);

    return {
        data,
        loading,
        error,
        setOpts,
        setPage,
        page: check.number(opts?.skip) && check.number(opts?.limit) ? Math.floor(opts?.skip / opts?.limit) : 0,
    };
}