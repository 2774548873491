import RADIO_GROUP_CARD from './RadioGroupCard';

import { registerComponentVariant } from '../../../lib/components-registry';
import { RADIO_GROUP_COMPONENT_DESCRIPTION, RADIO_GROUP_COMPONENT_NAME, RADIO_GROUP_CARD_VARIANT_DESCRIPTION, RADIO_GROUP_CARD_VARIANT_NAME } from '../../../lib/constants';

function CardRadioGroup({ size, ...props }: any) {
    let ReactComponent = RADIO_GROUP_CARD

    return <ReactComponent size={size} {...props} label={props.buttonLabel || null} />;
}

registerComponentVariant({
    name: RADIO_GROUP_COMPONENT_NAME,
    description: RADIO_GROUP_COMPONENT_DESCRIPTION,
    variantName: RADIO_GROUP_CARD_VARIANT_NAME,
    variantDescription: RADIO_GROUP_CARD_VARIANT_DESCRIPTION,
    subVariantName: 'Default',
    subVariantDescription: 'RADIO_GROUP_CARD',
    reactComponent: CardRadioGroup,
    acceptsProps: ['title', 'description'],
    acceptSizes: ['6xl', '5xl', '4xl', '3xl', '2xl', 'xl', 'lg', 'md', 'sm', 'xs'],
    acceptsRoundness: ['none', 'full', '3xl', '2xl', 'xl', 'lg', 'md', 'sm', 'none'],
    acceptOrientations: ['north', 'south', 'east', 'west'],
    acceptsChildren: false,
});