// Applies a style to the body of the document

import check from "@/vendors/check";
import { useEffect } from "react";

export function useAppliedBodyStyle(styles: string[]) {
    useEffect(() => {
        if (!check.nonEmptyArray(styles)) {
            return;
        }
        const toApply = [...styles];
        applyBodyStyle(toApply);
        return () => unmountStyles(toApply);
    }, [styles]);
}

// Removes the style when the component is unmounted
export function applyBodyStyle(styles: string[]) {
    const prev = document.body.style.cssText || '';

    const stylesKeys = styles.map((line) => {
        const key = line.split(':')[0].trim().toLowerCase();
        return key;
    });

    const nextBase = prev
        .split(';')
        .map((line) => line.trim())
        .filter((line) => line.length > 0)
        .filter(line => {
            const key = line.split(':')[0].trim().toLowerCase();
            return !stylesKeys.includes(key);
        })
        .join('; ');
    
    document.body.style.cssText = nextBase + '; ' + styles.join('; ');
}

// Cleans up any theme data that was set by us
export function unmountStyles(styles: string[]) {
    if (!check.nonEmptyArray(styles)) {
        return;
    }

   const currentStyle = document.body.style.cssText;
   if (!check.nonEmptyString(currentStyle)) {
       return;
   }

    const toUmount = styles.map((line) =>  line.trim());
    const newStyle = currentStyle.split(';').filter((line) => {
         return !toUmount.includes(line.trim());
    }).join('; ');

    document.body.style.cssText = newStyle;
}