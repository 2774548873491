import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { getDatabase, ref, onValue, off, set, update, remove, push, DatabaseReference, DataSnapshot } from 'firebase/database';
import { firebase } from '@/initializers/firebase';
import check from '@/vendors/check';
import useClerkAuthedFirebase from './useClerkAuthedFirebase';

const useFirebaseValue = <T>(path: string | null, defaultValue: T, allowAnonymous = false) => {
  const uid = useClerkAuthedFirebase();
  const [value, setValue] = useState<T>(defaultValue);
  const [loading, setLoading] = useState(true);
  const valueRef = useRef<any>(defaultValue);
  
  const dbRef = useMemo(() => {
    if (!uid && !allowAnonymous) {
      return null;
    }
    const database = getDatabase(firebase);
    return (check.nonEmptyString(path) ? ref(database, path) as DatabaseReference : null);
  }, [path, uid, allowAnonymous]);

  // console.log('[Firebase] dbRef at path '+path+' :', dbRef);

  useEffect(() => {
    if (!dbRef) {
      setLoading(true);
      return;
    }

    // console.log('[Firebase] Adding listener at path '+ path);

    const onValueChange = (snapshot: DataSnapshot) => {

      console.log('Firebase valeu change');
      // console.log('[Firebase] Value at '+ path + ' changed to:', snapshot.val());
      const snapshotValue = snapshot.val();
      const val = snapshotValue !== null && snapshotValue !== undefined ? snapshotValue : defaultValue;

      if (JSON.stringify(valueRef.current) === JSON.stringify(val)) {
        setLoading(false);
        return;
      }

      valueRef.current = val;
      setValue(val);
      setLoading(false);
    };

    onValue(dbRef, onValueChange);
    return () => off(dbRef, 'value', onValueChange);
  }, [dbRef, defaultValue, path]);

  const setFn = useCallback(async (newValue: T) => {
    if (!dbRef) {
      return;
    }
    // console.log('[Firebase] Setting value at '+ path + ' to:', newValue);
    await set(dbRef, newValue);
  }, [dbRef]);

  const updateFn = useCallback(async (updates: Partial<T>) => {
    if (!dbRef) {
      return;
    }
    // console.log('[Firebase] Updating value at '+ path + ' with payload:', updates);
    await update(dbRef, updates);
  }, [dbRef]);

  const removeFn = useCallback(async () => {
    if (!dbRef) {
      return;
    }
    // console.log('[Firebase] Removing value at '+ path);
    await remove(dbRef);
  }, [dbRef]);

  const pushFn = useCallback(async (newValue: T) => {
    if (!dbRef) {
      return;
    }
    // console.log('[Firebase] Pushing value at '+ path, newValue);
    await push(dbRef, newValue);
  }, [dbRef]);

  return { value, set: setFn, update: updateFn, remove: removeFn, push: pushFn, loading };
};

export default useFirebaseValue;
