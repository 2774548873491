import { Checkbox } from "@/components/shadcn/ui/checkbox"
import { ComponentParser, ComponentTreeNode } from "../../component.type";
import { imports, parseBooleanInputComponent, setup } from "../_utils";
import { registerShadcnComponent } from "@/lib/parser/importsRegistry";
 
/*
import Link from "next/link"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
 
import { Button } from "@/components/shadcn/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/shadcn/ui/form"
import { toast } from "@/components/shadcn/ui/use-toast"

export function CheckboxDemo() {
  return (
    <div className="flex items-center space-x-2">
      <Checkbox id="terms" />
      <label
        htmlFor="terms"
        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        Accept terms and conditions
      </label>
    </div>
  )
}


"use client"
 
const FormSchema = z.object({
  mobile: z.boolean().default(false).optional(),
})
 
export function CheckboxReactHookFormSingle() {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      mobile: true,
    },
  })
 
  function onSubmit(data: z.infer<typeof FormSchema>) {
    toast({
      title: "You submitted the following values:",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    })
  }
 
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="mobile"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>
                  Use different settings for my mobile devices
                </FormLabel>
                <FormDescription>
                  You can manage your mobile notifications in the{" "}
                  <Link href="/examples/forms">mobile settings</Link> page.
                </FormDescription>
              </div>
            </FormItem>
          )}
        />
        <Button type="submit">Submit</Button>
      </form>
    </Form>
  )
}

*/

const CheckboxComponentParser : ComponentParser = {
  name: 'Checkbox',
  description: 'A control that allows the user to toggle between checked and not checked',
  tags: ['checkbox'],
  refImplementation: ` 
/checkbox Receive marketing emails ON
`,
  parseTree: (c: ComponentTreeNode) => {
    return parseBooleanInputComponent(Checkbox, 'Checkbox', c);
  },
  imports: imports(['Checkbox'], 'checkbox'),
  setup: setup('checkbox'),
  variants: []
};

registerShadcnComponent({
        Checkbox
    },
    'checkbox',
);

export default CheckboxComponentParser;
