import { Metrics } from './Metrics';

import { registerComponentVariant } from '../../../lib/components-registry';
import { METRICS_COMPONENT_DESCRIPTION, METRICS_COMPONENT_NAME, METRICS_DEFAULT_VARIANT_DESCRIPTION, METRICS_DEFAULT_VARIANT_NAME } from '../../../lib/constants';
import { ComponentType } from 'react';

function DefaultMetrics({ size, ...props }: any) {
    let ReactComponent = Metrics

    return <ReactComponent size={size} {...props} label={props.buttonLabel || null} />;
}

registerComponentVariant({
    name: METRICS_COMPONENT_NAME,
    description: METRICS_COMPONENT_DESCRIPTION,
    variantName: METRICS_DEFAULT_VARIANT_NAME,
    variantDescription: METRICS_DEFAULT_VARIANT_DESCRIPTION,
    subVariantName: 'Default',
    subVariantDescription: 'Metrics',
    reactComponent: DefaultMetrics,
    acceptsProps: ['title', 'description'],
    acceptSizes: ['6xl', '5xl', '4xl', '3xl', '2xl', 'xl', 'lg', 'md', 'sm', 'xs'],
    acceptOrientations: ['north', 'south'],
    acceptsChildren: false,
});